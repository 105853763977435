<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 125px">
          <span class="text-h5 font-weight-bold" v-text="'Coordinators'"></span>
          <addbutton
            :link="'/teachers/create'"
            :tooltip="`Add a coordinator`"
          ></addbutton>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 mb-2"
                fab
                x-small
                color="primary"
                to="/teachers/upload"
              >
                <v-icon dark> mdi-upload </v-icon>
              </v-btn>
            </template>
            <span>Bulk Upload</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                x-small
                class="mx-2 mb-2"
                color="error"
                @click="getSentMailWarning()"
                :loading="sending_mail"
                :disabled="sending_mail"
              >
                <v-icon dark> mdi-email-fast </v-icon>
              </v-btn>
            </template>
            <span>Send Registration Mail to all Coordinators</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3" md="6" lg="3" style="min-width: 145px">
          <v-autocomplete
            ref="standard"
            v-model="form.standard_id"
            :items="standardItems"
            hide-details
            label="Location"
            @change="getStandardSections"
            prepend-icon="mdi-alarm-bell"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col sm="3" md="6" lg="3" style="min-width: 145px">
          <v-autocomplete
            ref="section"
            v-model="form.section_id"
            @change="getSectionClasscodes"
            :items="sectionItems"
            hide-details
            label="Department"
            prepend-icon="mdi-account-school"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col sm="3" md="6" lg="3" style="min-width: 145px">
          <v-autocomplete
            ref="classcode"
            v-model="form.classcode_id"
            :items="classcodeItems"
            hide-details
            label="Designation"
            prepend-icon="mdi-application-edit"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col sm="1" class="mt-3" style="min-width: 91px">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
        <v-col sm="2" class="mt-3" align="right" style="min-width: fit-content">
          <v-btn
            fab
            x-small
            :color="is_list == false ? 'primary' : ''"
            @click="is_list = false"
          >
            <v-icon dark> mdi-grid </v-icon>
          </v-btn>
          |
          <v-btn
            fab
            x-small
            :color="is_list == true ? 'primary' : ''"
            @click="is_list = true"
          >
            <v-icon> mdi-format-list-numbered </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <div v-else>
        <!-- Grid View -->
        <div v-if="is_list == false">
          <v-row>
            <v-col
              sm="6"
              md="3"
              lg="3"
              v-for="teacher in teachers"
              :key="teacher.name"
            >
              <v-card elevation="6" shaped>
                <a
                  @click="ShowDetails(teacher.id)"
                  style="text-decoration: none; color: black"
                >
                  <template v-if="teacher.image_path">
                    <v-img
                      shaped
                      max-height="210"
                      contain
                      :src="`${mediaUrl + teacher.image_path}`"
                    ></v-img>
                  </template>
                  <template v-else>
                    <v-img
                      v-if="teacher.gender == true"
                      height="200"
                      contain
                      :src="require('@/assets/imgs/teacher-male.png')"
                    ></v-img>
                    <v-img
                      v-if="teacher.gender == false"
                      height="200"
                      contain
                      :src="require('@/assets/imgs/teacher-female.png')"
                    ></v-img>
                  </template>

                  <v-card-title>
                    <div class="wrap">
                      {{ teacher.name }}
                    </div>
                  </v-card-title>

                  <v-card-text>
                    <div class="wrap">{{ teacher.email }}</div>
                  </v-card-text>
                </a>
                <v-divider class="mx-4"></v-divider>

                <v-card-text>
                  <v-chip-group
                    active-class="deep-purple accent-4 white--text"
                    column
                  >
                    <div v-if="teacher.gender == true">
                      <v-chip dark color="blue lighten-2">
                        <v-icon> mdi-gender-male </v-icon>
                        MALE</v-chip
                      >
                    </div>
                    <div v-else>
                      <v-chip dark color="red lighten-2">
                        <v-icon> mdi-gender-female </v-icon>
                        FEMALE</v-chip
                      >
                    </div>
                    <v-tooltip bottom v-if="teacher.is_mail_sent == 0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          fab
                          x-small
                          class="my-auto"
                          color="error"
                          @click="getSentMailWarning(teacher.id)"
                          :loading="sending_mail"
                          :disabled="sending_mail"
                        >
                          <v-icon dark> mdi-email-fast </v-icon>
                        </v-btn>
                      </template>
                      <span>No Registration Mail Sent Yet !!</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="my-auto"
                          fab
                          x-small
                          color="success"
                          @click="getSentMailWarning(teacher.id)"
                          :loading="sending_mail"
                          :disabled="sending_mail"
                        >
                          <v-icon dark> mdi-email-open </v-icon>
                        </v-btn>
                      </template>
                      <span>Registration Mail Already Sent. </span>
                    </v-tooltip>
                    <v-btn
                      class="ml-2 my-auto"
                      fab
                      x-small
                      color="primary"
                      :to="`/teachers/${teacher.id}`"
                    >
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          dark
                          :color="`${teacher.active ? 'green' : 'red'}`"
                        >
                          mdi-circle
                        </v-icon>
                      </template>
                      <span>{{ teacher.active ? "Active" : "Inactive" }}</span>
                    </v-tooltip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <!-- List View -->
        <v-col md="12" v-else>
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 56px">Sr No</th>
                    <th class="text-center" style="min-width: 65px">Name</th>
                    <th class="text-center" style="min-width: 65px">Email</th>
                    <th class="text-center" style="min-width: 80px">Gender</th>
                    <th class="text-center" style="min-width: 80px">
                      is Active
                    </th>
                    <th class="text-center" style="min-width: 85px">
                      is Mail Sent
                    </th>
                    <th class="text-right" style="min-width: 70px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(teacher, i) in teachers" :key="teacher.name">
                    <td>{{ i + 1 }}</td>
                    <td
                      class="text-center"
                      style="min-width: 105px"
                      @click="ShowDetails(teacher.id)"
                    >
                      {{ teacher.name }}
                    </td>
                    <td class="text-center" style="min-width: 150px">
                      {{ teacher.email }}
                    </td>
                    <td class="text-center">
                      {{ teacher.gender == true ? "MALE" : "FEMALE" }}
                    </td>
                    <td class="text-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            :color="`${teacher.active ? 'green' : 'red'}`"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>{{
                          teacher.active ? "Active" : "Inactive"
                        }}</span>
                      </v-tooltip>
                    </td>
                    <td class="text-center">
                      <v-tooltip bottom v-if="teacher.is_mail_sent == 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            fab
                            x-small
                            class="my-auto"
                            color="error"
                            @click="getSentMailWarning(teacher.id)"
                            :loading="sending_mail"
                            :disabled="sending_mail"
                          >
                            <v-icon dark> mdi-email-fast </v-icon>
                          </v-btn>
                        </template>
                        <span>No Registration Mail Sent Yet !!</span>
                      </v-tooltip>
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="my-auto"
                            fab
                            x-small
                            color="success"
                            @click="getSentMailWarning(teacher.id)"
                            :loading="sending_mail"
                            :disabled="sending_mail"
                          >
                            <v-icon dark> mdi-email-open </v-icon>
                          </v-btn>
                        </template>
                        <span>Registration Mail Already Sent. </span>
                      </v-tooltip>
                    </td>

                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/teachers/${teacher.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <!-- Mail Sent Warning Dialog Box -->
        <v-dialog v-model="is_Send_Mail_Warning" width="450">
          <v-card shaped>
            <v-container>
              <v-row align="center">
                <v-col sm="3" align="center">
                  <v-icon
                    large
                    style="
                      color: green;
                      font-size: 90px;
                      width: 150px !important;
                    "
                  >
                    mdi-email-alert-outline
                  </v-icon>
                </v-col>
                <v-col sm="9" align="center">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <h3>Do you want to send the registration email?</h3>
                        <br />
                        <v-btn
                          dark
                          color="green"
                          @click.stop="SendMail(user_id)"
                        >
                          <v-icon size="20"> mdi-email-fast-outline </v-icon>
                          &nbsp; Yes
                        </v-btn>
                        &nbsp;
                        <v-btn
                          dark
                          color="red"
                          @click.stop="is_Send_Mail_Warning = false"
                        >
                          <v-icon size="20"> mdi-email-off-outline </v-icon>
                          &nbsp; No
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <!-- Mail Sent Success Dialog Box -->
        <v-dialog v-model="is_Mail_Sent" width="575">
          <v-card shaped>
            <v-container>
              <v-row align="center">
                <v-col sm="3" align="center">
                  <v-icon
                    large
                    style="
                      color: green;
                      font-size: 90px;
                      width: 170px !important;
                    "
                  >
                    mdi-cloud-upload-outline
                  </v-icon>
                </v-col>
                <v-col sm="9" align="center">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <h1 style="color: green">Hurray!</h1>
                        <br />
                        <h3>
                          The registration mail has been sent successfully. Now
                          you can ask them to check their mail.
                        </h3>
                        <br />
                        <v-btn color="success" @click="is_Mail_Sent = false">
                          OK
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <!-- User Details Dialog Box -->
        <v-dialog
          v-model="show_details"
          fullscreen
          transition="dialog-bottom-transition"
          hide-overlay
        >
          <v-card style="background: rgb(252, 239, 239)">
            <v-toolbar color="white">
              <v-btn icon @click="(show_details = false), (user_details = [])">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Teacher Info</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container fluid>
              <v-card class="mt-4 mb-4">
                <v-row align="center">
                  <div style="flex: 1 1 auto"></div>
                  <v-col sm="12">
                    <v-card-text>
                      <v-row>
                        <v-col md="8">
                          Name :
                          <h1>
                            <v-icon> mdi-human-male-board </v-icon>
                            {{ user_details.name }}
                          </h1>
                        </v-col>
                        <v-col md="4">
                          Teacher Id :
                          <h3>
                            <v-icon>mdi-card-account-details </v-icon>
                            {{ user_details.id_given_by_school }}
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="8">
                          Email :
                          <h1>
                            <v-icon> mdi-email </v-icon>
                            {{ user_details.email }}
                          </h1>
                        </v-col>
                        <v-col md="4">
                          Registration Mail Sent :
                          <h3>
                            <v-tooltip
                              bottom
                              v-if="user_details.is_mail_sent == 0"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  fab
                                  x-small
                                  class="my-auto"
                                  color="error"
                                  @click="getSentMailWarning(user_details.id)"
                                  :loading="sending_mail"
                                  :disabled="sending_mail"
                                >
                                  <v-icon dark> mdi-email-fast </v-icon>
                                </v-btn>
                              </template>
                              <span>No Registration Mail Sent Yet !!</span>
                            </v-tooltip>
                            <v-tooltip bottom v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  class="my-auto"
                                  fab
                                  x-small
                                  color="success"
                                  @click="getSentMailWarning(user_details.id)"
                                  :loading="sending_mail"
                                  :disabled="sending_mail"
                                >
                                  <v-icon dark> mdi-email-open </v-icon>
                                </v-btn>
                              </template>
                              <span>Registration Mail Already Sent. </span>
                            </v-tooltip>
                            {{ user_details.is_mail_sent ? "Yes" : "NO" }}
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="8">
                          Gender :
                          <div v-if="user_details.gender == true">
                            <h1>
                              <v-icon color="blue lighten-2">
                                mdi-gender-male
                              </v-icon>
                              MALE
                            </h1>
                          </div>
                          <div v-else>
                            <h1>
                              <v-icon color="red lighten-2">
                                mdi-gender-female
                              </v-icon>
                              FEMALE
                            </h1>
                          </div>
                        </v-col>
                        <v-col md="4">
                          Contact No :
                          <h3>
                            <v-icon> mdi-phone </v-icon>
                            {{ user_details.contact_number }}
                          </h3>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="mt-9 mb-4">
                <v-card-title> Classes </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      sm="3"
                      v-for="(classcode, c) in user_details.classcodes"
                      :key="`classcode_${c}`"
                    >
                      <router-link
                        :to="`${
                          IsTeacherView()
                            ? '/standards/' +
                              classcode.standard_id +
                              '/sections/' +
                              classcode.section_id +
                              '/classes/' +
                              classcode.id +
                              '/view-students'
                            : '/class_dashboard/' +
                              classcode.id +
                              '?teacherId=' +
                              user_details.id
                        }`"
                        style="text-decoration: none"
                      >
                        <v-card shaped elevation="8">
                          <v-col>
                            <v-img
                              height="210"
                              :src="require('@/assets/imgs/classroom.png')"
                            ></v-img>
                          </v-col>

                          <v-card-title class="justify-center"
                            >{{ classcode.subject_name }}
                          </v-card-title>

                          <v-card-text>
                            <v-row>
                              <v-col style="text-align: center">
                                <classcode-element
                                  :classcode="classcode.classcode"
                                  :type="
                                    roleName == 'STUDENT'
                                      ? 'SUBJECT'
                                      : roleName == 'TEACHER'
                                      ? 'STANDARD_SUBJECT'
                                      : ''
                                  "
                                ></classcode-element>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider class="mx-4"></v-divider>
                          <v-card-text>
                            <v-chip-group
                              active-class="deep-purple accent-4 white--text"
                              column
                            >
                              <v-chip>{{
                                classcode.is_optional ? "Optional" : "Mandatory"
                              }}</v-chip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    dark
                                    :color="`${
                                      classcode.is_active ? 'green' : 'red'
                                    }`"
                                  >
                                    mdi-circle
                                  </v-icon>
                                </template>
                                <span>{{
                                  classcode.is_active ? "Active" : "Inactive"
                                }}</span>
                              </v-tooltip>
                            </v-chip-group>
                          </v-card-text>
                        </v-card>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";
// import ClasscodeElement from "@/components/ClasscodeElement.vue";

export default {
  components: { addbutton, Loader },
  data() {
    return {
      form: {
        section_id: "",
        standard_id: "",
        classcode_id: "",
      },
      isLoading: false,
      sending_mail: false,
      is_Send_Mail_Warning: false,
      is_Mail_Sent: false,
      show_details: false,
      is_list: false,
      user_details: [],
      user_id: "",

      teachers: [],
      standards: [],
      standardItems: [],
      sections: [],
      sectionItems: [],
      classcodes: [],
      classcodeItems: [],
    };
  },
  mounted() {
    this.form.classcode_id = this.$route.params.classcodeId;
    this.form.standard_id = parseInt(
      this.$route.query.standard_id
        ? this.$route.query.standard_id
        : this.$route.params.standardId
    );
    this.form.section_id = parseInt(
      this.$route.query.section_id
        ? this.$route.query.section_id
        : this.$route.params.sectionId
    );
    this.getMasters();
  },
  methods: {
    IsTeacherView() {
      let response = false;
      if (
        this.roleName === "TEACHER" ||
        (this.roleName == "ADMIN" && this.form.teacher_id) ||
        (this.roleName == "SUPER ADMIN" &&
          this.company.id != null &&
          this.form.teacher_id)
      ) {
        response = true;
      }
      return response;
    },
    async getMasters() {
      this.isLoading = true;
      try {
        let masters = await axios.get("users/masters");
        masters = masters.data;
        this.standards = masters.standards;
        this.sections = masters.sections;
        this.classcodes = masters.classcodes;
        // Standard
        this.standards.forEach((standard) => {
          if (this.$route.query.standard_id == standard.id) {
            this.standardItems.push({
              id: standard.id,
              text: standard.name,
              value: this.$route.query.standard_id,
            });
          }
          if (this.$route.query.standard_id != standard.id) {
            this.standardItems.push({
              id: standard.id,
              text: standard.name,
              value: standard.id,
            });
          }
        });
        // Section
        this.sections.forEach((section) => {
          this.sectionItems.push({
            id: section.id,
            text: section.name,
            value: section.id,
          });
        });
        // ClassCodes
        this.classcodes.forEach((classcode) => {
          if (this.$route.params.classcodeId == classcode.id) {
            this.classcodeItems.push({
              id: classcode.id,
              text: classcode.subject_name,
              value: this.$route.params.classcodeId,
            });
          }
          if (this.$route.params.classcodeId != classcode.id) {
            this.classcodeItems.push({
              id: classcode.id,
              text: classcode.subject_name,
              value: classcode.id,
            });
          }
        });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      try {
        this.form.standard_id = this.form.standard_id
          ? this.form.standard_id
          : "";
        this.form.section_id = this.form.section_id ? this.form.section_id : "";
        this.form.classcode_id = this.form.classcode_id
          ? this.form.classcode_id
          : "";
        let teachers = [];
        let query =
          "role_id=3" +
          "&standard_id=" +
          this.form.standard_id +
          "&section_id=" +
          this.form.section_id +
          "&classcode_id=" +
          this.form.classcode_id;
        teachers = await axios.get(`/users?${query}`);
        this.teachers = teachers.data.data;
        this.count = teachers.data.count;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    // async SendMail(id) {
    //   let query = "role_id=3" + "&user_id=" + id;
    //   let is_mail_sent = await axios.get(`/users/send_mail?${query}`);
    //   if (is_mail_sent) {
    //     this.is_Mail_Sent = true;
    //   }
    // },
    getSentMailWarning(id = "") {
      this.user_id = "";
      if (id) this.user_id = id;
      this.is_Send_Mail_Warning = true;
    },
    async SendMail(id) {
      this.is_Send_Mail_Warning = false;
      this.sending_mail = true;
      let is_mail_sent = [];
      let query = "";
      if (id) {
        query = "role_id=3" + "&user_id=" + id;
        is_mail_sent = await axios.get(`/users/send_mail?${query}`);
      } else {
        query = "role_id=3";
        is_mail_sent = await axios.get(`/users/send_mail_all?${query}`);
      }
      if (is_mail_sent) {
        this.sending_mail = false;
        this.is_Mail_Sent = true;
        this.getData();
      }
    },
    async ShowDetails(id) {
      this.user_details = [];
      this.user_details = this.teachers.find((teacher) => teacher.id == id);
      if (this.user_details) {
        this.show_details = true;
      }
      console.log(this.user_details);
    },
    async getStandardSections() {
      this.sectionItems = [];
      this.classcodeItems = [];
      this.form.classcode_id = "";
      let standardsections = this.sections.filter(
        (section) => section.standard_id == this.form.standard_id
      );
      standardsections.forEach((ss) => {
        this.sectionItems.push({
          id: ss.id,
          text: ss.name,
          value: ss.id,
        });
      });
    },
    async getSectionClasscodes() {
      this.classcodeItems = [];
      let sectionclasscodes = this.classcodes.filter(
        (classcode) => classcode.section_id == this.form.section_id
      );
      sectionclasscodes.forEach((sc) => {
        this.classcodeItems.push({
          id: sc.id,
          text: sc.subject_name,
          value: sc.id,
        });
      });
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
