<template>
  <section>
    <v-card-actions class="mb-1">
      <v-row style="overflow: auto">
        <v-col sm="6" md="7" lg="8" style="min-width: 155px" class="pr-0">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Performance Metrics'"
          ></span>
        </v-col>
        <v-col sm="6" md="5" lg="4" align="right">
          <v-btn
            color="success"
            @click="createPDF()"
            v-if="assignmentOverview != ''"
          >
            Download Pdf
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <div id="mydiv">
      <v-simple-table fixed-header height="285px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left sr_no_class">Sr No</th>
              <th class="text-center" style="min-width: 90px">
                {{ roleName == "STUDENT" ? "Subject" : "Classcode" }}
              </th>
              <th class="text-center" style="min-width: 110px">Coordinator</th>
              <th class="text-center" style="min-width: 85px">Score</th>
              <th class="text-center" style="min-width: 80px">Class Average</th>
              <th class="text-center" style="min-width: 130px">
                Submission Rate
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(classs, i) in assignmentOverview.classcodeWiseAssignments"
              :key="`classs${i}`"
            >
              <td class="text-left">{{ i + 1 }}</td>
              <td class="text-center" style="min-width: 85px">
                <ClasscodeStudent
                  :classs="classs"
                  :student_id="classs.student_id"
                ></ClasscodeStudent>
              </td>
              <td class="text-center" style="min-width: 110px">
                <div
                  v-for="(teacher, t) in classs.teachers"
                  :key="`teacher${t}`"
                >
                  {{ teacher.first_name + " " + teacher.last_name }}
                </div>
              </td>
              <td class="text-center" style="min-width: 90px">
                {{ classs.myAveragePercent | twoDec }} %
              </td>
              <td class="text-center" style="min-width: 130px">
                {{ classs.classcodeAveragePercent | twoDec }} %
              </td>
              <td class="text-center" style="min-width: 130px">
                <template v-if="classs.count != 0">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        >{{
                          ((classs.total_attempted / classs.count) * 100)
                            | twoDec
                        }}
                        %</span
                      >
                    </template>
                    <span>[ Total Attempted / Total Assignment ] </span>
                  </v-tooltip>
                </template>
                <template v-else> 0 % </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-row v-if="source == 'REPORTS'" class="mt-5">
        <v-col md="6" sm="12" v-if="SubjectPercentData.length">
          <v-card elevation="6">
            <v-card-title style="min-width: 150px">
              Student VS Class Average
              <v-spacer></v-spacer>
              <v-card-actions>
                <view-fullscreen
                  :title="`Student VS Class Average`"
                  :type="`ColumnChart`"
                  :Data="SubjectPercentData"
                  :Options="StudentClassColumnchartOptions"
                ></view-fullscreen>
              </v-card-actions>
            </v-card-title>
            <v-card-text style="overflow: auto">
              <GChart
                type="ColumnChart"
                :data="SubjectPercentData"
                :options="StudentClassColumnchartOptions"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="6" v-if="SubjectAttemptData.length">
          <v-card elevation="6">
            <v-card-title style="min-width: 150px">
              Subject Submission Rate
              <v-spacer></v-spacer>
              <v-card-actions>
                <view-fullscreen
                  :title="`Subject Submission Rate`"
                  :type="`ColumnChart`"
                  :Data="SubjectAttemptData"
                  :Options="columnchartOptions"
                ></view-fullscreen>
              </v-card-actions>
            </v-card-title>
            <v-card-text style="overflow: auto">
              <GChart
                type="ColumnChart"
                :data="SubjectAttemptData"
                :options="columnchartOptions"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="subjectItems.length && source == 'REPORTS'">
        <v-col>
          <v-card elevation="6">
            <v-card-title style="min-width: 215px"
              >Assignment Wise Score Comparison for
              <v-autocomplete
                class="ml-5"
                ref="subject"
                v-model="subject_id"
                :items="subjectItems"
                @change="getSubjectAssignments"
                label="Subject"
                prepend-icon="mdi-account-school"
              ></v-autocomplete>
              <v-spacer></v-spacer>
              <v-card-actions v-if="AssignmentWiseData.length">
                <view-fullscreen
                  :title="`Assignment Wise Score Comparison for Class : ${subject_id}`"
                  :type="`AreaChart`"
                  :Data="AssignmentWiseData"
                  :Options="assignmentColumnChartOptions"
                ></view-fullscreen> </v-card-actions
            ></v-card-title>
            <v-card-title>
              <v-row>
                <v-col md="10">
                  <v-autocomplete
                    ref="subject"
                    v-model="assignment_ids"
                    :items="subjectAssignments"
                    label="Assignments"
                    multiple
                    chips
                    clearable
                    @input="validateSelectedItem"
                    @click:clear="AssignmentWiseData = []"
                    :error-messages="validationErrors"
                    prepend-icon="mdi-application-edit"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    :disabled="validationErrors.length ? true : false"
                    @click="getAssignmentWiseScoreData"
                  >
                    FILTER
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row v-if="AssignmentWiseData.length">
                <v-col style="overflow: auto">
                  <GChart
                    type="AreaChart"
                    :data="AssignmentWiseData"
                    :options="assignmentColumnChartOptions"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
import ClasscodeStudent from "./classcodeStudent.vue";

export default {
  data() {
    return {
      SubjectBasedOn: "1",
      AssignmentBasedOn: "1",
      SubjectPercentData: [],
      SubjectAttemptData: [],
      AssignmentWiseData: [],
      subjectItems: [],
      subjectAssignments: [],
      assignment_ids: [],
      validationErrors: [],
      subject_id: "",
      StudentClassColumnchartOptions: {
        width: "0",
        height: 350,
        colors: ["#007ACC", "#FFA500"],
        chartArea: { left: 50, right: 50 },
        bar: { groupWidth: "30%" },
        annotations: {
          textStyle: {
            fontSize: 10,
          },
        },
        vAxis: {
          minValue: 0,
          maxValue: 100,
          title: "In Percentage",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 75 },
          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          title: "Classcodes",
          titleTextStyle: {
            bold: true,
          },
          gridlines: {
            color: "transparent",
          },
        },
        legend: { position: "top", alignment: "left" },
      },
      columnchartOptions: {
        width: "100%",
        height: 350,
        chartArea: { left: 50, right: 50 },

        colors: ["#007ACC", "#FFA500"],
        bar: { groupWidth: "30%" },
        annotations: {
          textStyle: {
            fontSize: 10,
          },
        },
        animation: {
          duration: 500,
          easing: "out",
        },
        vAxis: {
          minValue: 0,
          maxValue: 100,
          title: "In Percentage",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 75 },
          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          title: "Classcodes",
          titleTextStyle: {
            bold: true,
          },
          gridlines: {
            color: "transparent",
          },
        },
        legend: { position: "top", alignment: "left" },
      },
      assignmentColumnChartOptions: {
        width: "100%",
        height: 500,
        chartArea: { left: 100, right: 50 },
        colors: ["#007ACC", "#FFA500"],
        bar: { groupWidth: "45%" },
        annotations: {
          textStyle: {
            fontSize: 10,
          },
        },
        animation: {
          duration: 500,
          easing: "out",
        },
        vAxis: {
          minValue: 0,
          maxValue: 100,
          title: "In Percentage",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 75 },
          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          // slantedText: false,
          slantedTextAngle: 90,
          // maxAlternation: 5,
          // maxTextLines: 5,
          title: "Assignments",
          titleTextStyle: {
            bold: true,
          },
          gridlines: {
            color: "transparent",
          },
        },
        legend: { position: "top", alignment: "left" },
      },
    };
  },
  components: {
    ClasscodeStudent,
  },
  props: ["assignmentOverview", "source"],
  mounted() {
    this.getChart();
    this.validateSelectedItem();
  },
  methods: {
    getChart() {
      try {
        this.assignmentOverview.classcodeWiseAssignments.forEach((data) => {
          this.subjectItems.push({
            id: data.name,
            text: data.name,
            value: data.name,
          });
        });
        // Subject Wise Percentage [Score / Class]
        let SubjectPercentData = [
          [this.SubjectBasedOn, "Score [%]", "Class Average [%]"],
        ];
        this.assignmentOverview.classcodeWiseAssignments.forEach((data) => {
          let myPercent = parseFloat(
            data.myAveragePercent != 0 ? data.myAveragePercent.toFixed(2) : 0
          );
          let classPercent = parseFloat(
            data.classcodeAveragePercent != 0
              ? data.classcodeAveragePercent.toFixed(2)
              : 0
          );
          // Trimming Classcode On the Basis of Role
          let classcode = data.name;
          let originalClasscode = classcode;
          let classcode_array = classcode.split("/");
          let standard_section = classcode_array[1];
          let subject_section = classcode_array[2];
          let trimmed_classcode = "";
          if (this.roleName == "STUDENT") {
            trimmed_classcode = subject_section;
          } else if (this.roleName == "TEACHER") {
            trimmed_classcode = standard_section + "/" + subject_section;
          } else {
            trimmed_classcode = originalClasscode;
          }
          SubjectPercentData.push([trimmed_classcode, myPercent, classPercent]);
        });
        this.SubjectPercentData = SubjectPercentData;
        this.StudentClassColumnchartOptions.width = 0;
        this.StudentClassColumnchartOptions.bar.groupWidth = "30%";

        // Subject Wise Attempt Rate
        let SubjectAttemptData = [
          [this.SubjectBasedOn, "Assignment Submission Rate [%]"],
        ];
        this.assignmentOverview.classcodeWiseAssignments.forEach((data) => {
          let submission_rate = parseFloat(
            (
              (parseInt(data.total_attempted) / parseInt(data.count)) *
              100
            ).toFixed(2)
          );
          // Trimming Classcode On the Basis of Role
          let classcode = data.name;
          let originalClasscode = classcode;
          let classcode_array = classcode.split("/");
          let standard_section = classcode_array[1];
          let subject_section = classcode_array[2];
          let trimmed_classcode = "";
          if (this.roleName == "STUDENT") {
            trimmed_classcode = subject_section;
          } else if (this.roleName == "TEACHER") {
            trimmed_classcode = standard_section + "/" + subject_section;
          } else {
            trimmed_classcode = originalClasscode;
          }
          SubjectAttemptData.push([trimmed_classcode, submission_rate]);
        });
        this.SubjectAttemptData = SubjectAttemptData;
      } catch (error) {
        console.log(error);
      }
    },
    getAssignmentWiseScoreData() {
      let subject = this.assignmentOverview.classcodeWiseAssignments.find(
        (subject) => subject.name == this.subject_id
      );
      // Assignment Wise
      this.AssignmentWiseData = [
        [this.AssignmentBasedOn, "Score [%]", "Class Average [%]"],
      ];
      this.assignment_ids = this.assignment_ids ? this.assignment_ids : "";
      subject.values.forEach((value) => {
        let assignment_id = value.assignment_id;
        let is_applicable = this.assignment_ids.find(
          (item) => item === assignment_id
        );
        if (this.assignment_ids.length) {
          if (is_applicable) {
            let myPercent = parseFloat(
              value.percent != 0 ? value.percent.toFixed(2) : 0
            );
            let classPercent = parseFloat(
              value.classAveragePercent != 0
                ? value.classAveragePercent.toFixed(2)
                : 0
            );
            this.AssignmentWiseData.push([
              value.assignment_title,
              myPercent,
              classPercent,
            ]);
          }
        } else {
          let myPercent = parseFloat(
            value.percent != 0 ? value.percent.toFixed(2) : 0
          );
          let classPercent = parseFloat(
            value.classAveragePercent != 0
              ? value.classAveragePercent.toFixed(2)
              : 0
          );
          this.AssignmentWiseData.push([
            value.assignment_title,
            myPercent,
            classPercent,
          ]);
        }

        if (this.AssignmentWiseData.length > 8) {
          let startIndex = this.AssignmentWiseData.length - 8 - 1;
          this.AssignmentWiseData.splice(1, startIndex);
        }
      });
    },
    createPDF() {
      var baseUrl = window.location.origin;
      var element =
        '<img src="' +
        baseUrl +
        '/img/logo.73a58166.png" width="130" ><br><br>';
      element +=
        "<h2>" +
        this.assignmentOverview.classcodeWiseAssignments[0].student_name +
        "'s Performance Metrics</h2><br>";
      element += document.getElementById("mydiv").outerHTML;
      var opt = {
        margin: 25,
        filename: "Student Wise Performace Report.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [450, 500],
          orientation: "portrait",
        },
      };

      html2pdf().set(opt).from(element).save();
    },
    validateSelectedItem() {
      if (this.assignment_ids && this.assignment_ids.length >= 8) {
        this.validationErrors = [
          "Selected value exceeds the maximum allowed value.",
        ];
      } else if (this.assignment_ids.length == 0) {
        this.validationErrors = ["This field is required"];
      } else {
        this.validationErrors = [];
      }
    },
    getSubjectAssignments() {
      this.subjectAssignments = [];
      this.assignment_ids = [];
      let subject = this.assignmentOverview.classcodeWiseAssignments.find(
        (subject) => subject.name == this.subject_id
      );
      subject.values.forEach((value) => {
        this.subjectAssignments.push({
          id: value.assignment_id,
          text: value.assignment_title,
          value: value.assignment_id,
        });
      });
    },
  },
};
</script>
<style scoped>
table,
td,
th {
  /* border: 1px solid #777; */
  padding: 0.5rem;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}
</style>
