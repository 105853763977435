<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 135px">
          <span class="text-h5 font-weight-bold" v-text="`Browse`"></span>
        </v-col>
      </v-row>
      <v-card elevation="6" shaped class="rounded-tl-xl my-5 pa-5" style="min-width: fit-content">
        <v-row>
          <v-col sm="12" md="6" lg="3" style="min-width: 140">
            <v-autocomplete
              class="pb-5"
              ref="subjects"
              v-model="form.subjects"
              :items="allsubjectItems"
              label="Subjects"
              multiple
              small-chips
              clearable
              hide-details
              @click:clear="(form.subjects = null), getSubjectStandard()"
              @input="getSubjectStandard()"
              prepend-inner-icon="mdi-application-edit"
            ></v-autocomplete>
          </v-col>
          <v-col sm="12" md="6" lg="2" style="min-width: 140">
            <v-autocomplete
              ref="Standards"
              v-model="form.standards"
              multiple
              hide-details
              small-chips
              :loading="isfetchingStandard"
              clearable
              @click:clear="(form.standards = null), search_chapters()"
              @input="search_chapters()"
              :items="standardItems"
              label="Departments"
              prepend-inner-icon="mdi-alarm-bell"
            ></v-autocomplete>
          </v-col>
          <v-col sm="12" md="6" lg="2" style="min-width: 140">
            <v-autocomplete
              ref="chapter"
              multiple
              small-chips
              :loading="isfetchingChapter"
              clearable
              @click:clear="form.chapters = null"
              v-model="form.chapters"
              :items="chapterItems"
              label="Chapters"
              hide-details
              prepend-inner-icon="mdi-clippy"
            ></v-autocomplete>
          </v-col>
          <v-col sm="12" md="6" lg="3" style="min-width: max-content">
            <v-text-field
              class="pt-2"
              v-model="form.search_keyword"
              label="Search ... "
              prepend-inner-icon="mdi-magnify"
              @keydown.enter="getData"
              clearable
              @click:clear="form.search_keyword = null"
              @change="page = 1"
            ></v-text-field>
          </v-col>
          <v-col
            sm="12"
            md="1"
            lg="1"
            style="min-width: max-content"
            align="right"
          >
            <v-btn class="mt-3" color="primary" @click="getData"> SEARCH</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <loader v-if="isLoading"></loader>
      <template v-else>
        <!-- Single type -->
        <template v-if="form.type">
          <!-- Single Category :Current Scenario 3-->
          <div v-if="form.category_id">
            <v-row>
              <template v-for="(article, a) in articles">
                <v-col
                  sm="3"
                  :key="`article${a}`"
                  v-if="article.content_descriptions.length != 0"
                >
                  <Article
                    :article="article"
                    :type="article.content_type"
                  ></Article>
                </v-col>
              </template>
            </v-row>
          </div>
          <!-- Category Wise :Current Scenario 2 -->
          <template v-else>
            <div
              v-for="(category, c) in categories"
              :key="`category${c}`"
              class="mb-5"
            >
              <template class="ma-0">
                <v-row>
                  <v-col sm="6" md="6" lg="8">
                    <h2
                      class="grey--text text--darken-1"
                      style="min-width: max-content"
                    >
                      {{ category.category }}
                      <!-- [ {{ category.count }} ] -->
                    </h2>
                  </v-col>
                  <v-col sm="6" md="6" lg="4" align="right">
                    <v-btn
                      v-if="category.count > 4"
                      color="primary"
                      @click="view_category_article(category.id)"
                    >
                      View More</v-btn
                    >
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <template v-for="(article, a) in category.values">
                  <v-col
                    :key="`article${a}`"
                    sm="3"
                    v-if="article.content_descriptions.length != 0"
                  >
                    <Article
                      :article="article"
                      :type="article.content_type"
                    ></Article>
                  </v-col>
                </template>
              </v-row>
            </div>
          </template>
        </template>
        <!-- Content Type Wise :Current Scenario 1 -->
        <template v-else>
          <div
            v-for="(type, c) in content_types"
            :key="`type${c}`"
            class="mb-5"
          >
            <template v-if="type.count > 0">
              <v-row>
                <v-col sm="6" md="6" lg="8">
                  <h2
                    class="grey--text text--darken-1"
                    style="min-width: max-content"
                  >
                    {{ type.name }}
                  </h2>
                </v-col>
                <v-col sm="6" md="6" lg="4" align="right">
                  <v-btn
                    v-if="type.count > 4"
                    color="primary"
                    @click="(form.type = type.name), getData()"
                  >
                    View More</v-btn
                  >
                </v-col>
              </v-row>
            </template>
            <v-row>
              <template v-for="(article, a) in type.values">
                <v-col
                  :key="`article${a}`"
                  sm="3"
                  v-if="article.content_descriptions.length != 0"
                >
                  <Article
                    :article="article"
                    :type="article.content_type"
                  ></Article>
                </v-col>
              </template>
            </v-row>
          </div>
        </template>
        <!-- Collections -->
        <template v-if="collections.length">
          <v-row>
            <v-col sm="5">
              <h2 class="grey--text text--darken-1" style="min-width: 150px">
                COLLECTION
              </h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col sm="1" class="mr-5" v-if="actual_collections_count">
              <v-btn
                v-if="
                  is_included_searched_keyword == true &&
                  actual_collections_count > 4 &&
                  current_collections_count < actual_collections_count
                "
                color="primary"
                @click="(form.collection_limit = ''), getCollections()"
              >
                View More</v-btn
              >
              <v-btn
                v-if="current_collections_count > 4"
                color="primary"
                @click="(form.collection_limit = 4), getCollections()"
              >
                View Less</v-btn
              >
            </v-col>
          </v-row>
          <loader v-if="isCollectionLoading"></loader>
          <template v-else>
            <v-row>
              <v-col
                sm="3"
                v-for="(collection, a) in collections"
                :key="`collection${a}`"
              >
                <v-card elevation="6" shaped>
                  <router-link
                    class="routerLink"
                    :to="`/library/collections/${collection.id}`"
                  >
                    <!-- IF Feature Image Found -->
                    <div v-if="collection.featured_image_path">
                      <v-img
                        max-height="210"
                        cover
                        class="rounded-tl-xl feature"
                        :src="`${mediaUrl + collection.featured_image_path}`"
                      ></v-img>
                    </div>
                    <v-img
                      v-else
                      :src="require('@/assets/imgs/collection.png')"
                      height="180"
                    ></v-img>
                    <v-card-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <h3
                            v-if="collection.collection_name"
                            v-bind="attrs"
                            v-on="on"
                            class="wrap"
                            v-html="collection.collection_name"
                          ></h3>
                        </template>
                        <span v-html="collection.collection_name"> </span>
                      </v-tooltip>
                    </v-card-title>
                  </router-link>
                  <v-divider
                    v-if="roleName != 'STUDENT'"
                    class="mx-4"
                  ></v-divider>

                  <v-card-actions v-if="roleName != 'STUDENT'" class="pt-0">
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-1"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          fab
                          x-small
                          color="primary"
                          @click="ShowColleciton(collection)"
                        >
                          <v-icon>mdi-clipboard-text</v-icon>
                        </v-btn>
                      </template>
                      <span> Create Assignment </span>
                    </v-tooltip>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </template>
      </template>
    </v-container>
    <!-- Collection Assignment Dialog -->
    <v-dialog v-model="isCollectionAssigmentDialog" width="380">
      <v-card class="rounded-tl-xl rounded-br-xl">
        <v-card-title class="justify-center primary accent-1 white--text"
          >What would you like to do?
        </v-card-title>
        <v-card-text>
          <v-list rounded>
            <v-list-item-group color="primary">
              <v-list-item
                :to="`/assignments/create?collectionId=${collection_details.id}`"
              >
                <v-list-item-icon>
                  <v-icon large color="green darken-2">
                    mdi-clipboard-file
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-bold">Create New Assignment</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :to="`/collections/${collection_details.id}/assignments`"
              >
                <v-list-item-icon>
                  <v-icon large color="blue darken-2">
                    mdi-file-document-minus-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-bold"
                      >Choose Existing Assignment</span
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Collection Assignment Dialog -->
  </v-main>
</template>

<script>
import axios from "axios";
import Article from "@/components/article.vue";
import Loader from "@/components/loader.vue";

export default {
  data() {
    return {
      form: {
        subjects: "",
        standards: "",
        chapters: "",
        filtered_chapters: "",
        search_keyword: "",
        type: "",
        type_array: ["ARTICLE", "INFOGRAPHIC", "VIDEO"],
        category_wise_limit_4: true,
        collection_limit: 4,
      },
      current_scenario: "1",
      articles: [],
      standardItems: [],
      allstandardItems: [],
      subjectItems: [],
      allsubjectItems: [],
      chapterItems: [],
      type_id: "",
      content_types: [],
      collections: [],
      categories: [],
      collection_details: {},
      isLoading: false,
      isCollectionLoading: false,
      isCollectionAssigmentDialog: false,
      isfetchingStandard: false,
      isfetchingChapter: false,
      actual_collections_count: 0,
      current_collections_count: 0,
      is_included_searched_keyword: false,
    };
  },
  components: {
    Article,
    Loader,
  },
  mounted() {
    // this.$tours["myTour"].start();
    this.form.type = this.$route.query.type ? this.$route.query.type : "";
    window.addEventListener("popstate", this.handleBackButton);
    this.getData();
    this.getMasters();
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  methods: {
    handleBackButton() {
      // Your event handling code here
      // This code will be executed when the user clicks the browser's back button
      console.log(
        "Browser back button clicked from scenario :" + this.current_scenario
      );
      if (this.current_scenario == "2") {
        console.log("Go back to Content Type Wise i.e scenario 1");
        this.form.type = "";
        this.getData();
      } else if (this.current_scenario == "3") {
        console.log("Go back to Category Type Wise i.e scenario 2");
        this.form.category_id = "";
        this.getData();
      } else {
        this.form.category_id = "";
      }
      // You can perform any actions or logic you need here
    },
    // Get All Masters
    async getMasters() {
      let query = "company_id=" + this.company.id;
      let masters = await axios.get(`/contents/masters?${query}`);
      masters = masters.data;
      masters.grades.forEach((standard) => {
        this.standardItems.push({
          id: standard.id,
          text: standard.name,
          value: standard.name,
        });
        this.allstandardItems.push({
          id: standard.id,
          text: standard.name,
          value: standard.name,
        });
      });
      masters.subjects.forEach((subject) => {
        this.subjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.name,
        });
        this.allsubjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.name,
        });
      });
    },
    // Get Contents
    async getData() {
      try {
        window.history.pushState(null, null, location.href);
        this.form.filtered_chapters = "";
        this.isLoading = true;
        this.actual_collections_count = 0;
        this.is_included_searched_keyword = false;
        this.form.search_keyword = this.form.search_keyword
          ? this.form.search_keyword
          : "";
        this.form.standards = this.form.standards ? this.form.standards : "";
        this.form.subjects = this.form.subjects ? this.form.subjects : "";
        this.form.chapters = this.form.chapters ? this.form.chapters : "";
        if (this.form.chapters.length) {
          this.form.filtered_chapters = this.form.chapters.join("|");
        }
        this.form.category_id = this.form.category_id
          ? this.form.category_id
          : "";
        if (this.form.type) {
          this.form.type_array = [];
        } else {
          this.form.type_array = ["ARTICLE", "INFOGRAPHIC", "VIDEO"];
        }
        switch (true) {
          case this.form.type_array.length > 0:
            console.log("Content Type Wise");
            this.current_scenario = "1";
            break;
          case this.form.category_id != "":
            console.log("Single Category Wise");
            this.current_scenario = "3";
            break;
          case this.form.type.length > 0 && this.form.category_id.length == 0:
            console.log("Category Type Wise");
            this.current_scenario = "2";
            break;
          default:
            console.log("Default case");
            break;
        }
        console.log("current_scenario :" + this.current_scenario);

        let response = [];
        let query =
          "type=" +
          this.form.type +
          "&type_array=" +
          this.form.type_array +
          "&search_keyword=" +
          this.form.search_keyword +
          "&grades=" +
          this.form.standards +
          "&subjects=" +
          this.form.subjects +
          "&chapters=" +
          this.form.filtered_chapters +
          "&category_id=" +
          this.form.category_id +
          "&category_wise_limit_4=" +
          this.form.category_wise_limit_4 +
          "&roleName=" +
          this.roleName +
          "&user_id=" +
          this.user.id;
        response = await axios.get(`/search_contents?${query}`);
        this.content_types = response.data.content_types;

        if (this.form.category_id) {
          this.articles = response.data.data;
        } else {
          this.categories = response.data.CategoryWiseContent;
        }
        this.collections = response.data.ContentCollections;
        if (this.form.search_keyword) this.getCollections();
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async getCollections() {
      this.isCollectionLoading = true;
      this.is_included_searched_keyword = true;
      let collection_query =
        "all_collection_by_AT_IT=1&search_keyword=" +
        this.form.search_keyword +
        "&limit=" +
        this.form.collection_limit;
      let collections = await axios.get(`/collections?${collection_query}`);
      this.collections = collections.data.data;
      this.actual_collections_count = collections.data.actual_count;
      this.current_collections_count = collections.data.count;
      this.isCollectionLoading = false;
      this.bookmarMSG = "";
    },
    // Get Standard Wise Subjects
    // async getStandardSubject() {
    //   this.isfetchingSubject = true;
    //   this.form.subject = null;
    //   this.form.chapter = null;
    //   try {
    //     this.subjectItems = [];
    //     let query = "grades=" + this.form.standards;
    //     let subjects = await axios.get(`/search_standard_subjects?${query}`);
    //     subjects = subjects.data.data;
    //     subjects.forEach((subject) => {
    //       this.subjectItems.push({
    //         id: subject,
    //         text: subject,
    //         value: subject,
    //       });
    //     });
    //     this.isfetchingSubject = false;
    //     this.search_chapters();
    //   } catch (error) {
    //     this.isfetchingSubject = false;
    //   }
    // },

    // Get Subject Wise Standards
    async getSubjectStandard() {
      this.isfetchingStandard = true;
      this.form.standards = null;
      this.form.chapters = null;
      this.standardItems = [];
      this.chapterItems = [];
      try {
        if (this.form.subjects != "" && this.form.subjects != null) {
          let query = "subjects=" + this.form.subjects;
          let standards = await axios.get(`/search_standard_subjects?${query}`);
          standards = standards.data.data;
          standards.forEach((standard) => {
            this.standardItems.push({
              id: standard,
              text: standard,
              value: standard,
            });
          });
        } else {
          this.standardItems = this.allstandardItems;
        }
        this.standardItems.sort((a, b) => {
          return a.id - b.id;
        });
        this.isfetchingStandard = false;
      } catch (error) {
        console.log(error);
        this.isfetchingStandard = false;
      }
    },
    // Get Subject Wise & Standard Wise Chapters
    async search_chapters() {
      this.isfetchingChapter = true;
      this.form.chapters = null;
      this.form.filtered_chapters = null;
      this.chapterItems = [];
      try {
        this.form.standards = this.form.standards ? this.form.standards : "";
        this.form.subjects = this.form.subjects ? this.form.subjects : "";
        if (
          this.form.standards != "" &&
          this.form.subjects != "" &&
          this.form.standards != null &&
          this.form.subjects != null
        ) {
          let query =
            "grades=" + this.form.standards + "&subjects=" + this.form.subjects;
          let chapters = await axios.get(`/search_chapters?${query}`);
          chapters = chapters.data.data;
          chapters.forEach((chapter) => {
            this.chapterItems.push({
              id: chapter,
              text: chapter,
              value: chapter,
            });
          });
        }
        this.isfetchingChapter = false;
        this.form.chapter = null;
      } catch (error) {
        this.isfetchingChapter = false;
      }
    },
    view_category_article(category_id) {
      this.form.category_wise_limit_4 = false;
      this.form.category_id = category_id;
      this.getData();
    },
    ShowColleciton(collection) {
      this.isCollectionAssigmentDialog = true;
      this.collection_details = collection;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
