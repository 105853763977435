<template>
  <v-card color="primary" dark style="overflow: auto">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-h5">
          {{ assignmentOverview.classcodeWiseAssignments.length }}</v-card-title
        >
        <v-card-subtitle>Total Subjects</v-card-subtitle>
        <v-card-actions>
          <v-dialog v-model="isDialog" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                v-bind="attrs"
                v-on="on"
              >
                View List
              </v-btn>
            </template>

            <v-card elevation="10">
              <v-container>
                <v-row>
                  <v-card-title>Subjects </v-card-title>
                  <v-spacer></v-spacer>
                  <v-card-actions>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="isDialog = false"
                          icon
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Close</span>
                    </v-tooltip>
                  </v-card-actions>
                </v-row>
                <v-card-text class="pa-0">
                  <v-simple-table fixed-header height="285px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center sr_no_class">Sr No</th>
                          <th class="text-center" style="min-width: 75px">
                            Subject
                          </th>
                          <th class="text-center" style="min-width: 80px">
                            Teacher
                          </th>
                          <th class="text-center" style="min-width: 85px">
                            Score
                          </th>
                          <th class="text-center" style="min-width: 95px">
                            Class Average
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            classs, i
                          ) in assignmentOverview.classcodeWiseAssignments"
                          :key="`classs${i}`"
                        >
                          <td class="text-center">{{ i + 1 }}</td>
                          <td class="text-center" style="min-width: 85px">
                            <classcode-element
                              :classcode="classs.name"
                              :type="
                                roleName == 'STUDENT'
                                  ? 'SUBJECT'
                                  : roleName == 'TEACHER'
                                  ? 'STANDARD_SUBJECT'
                                  : ''
                              "
                            ></classcode-element>
                          </td>
                          <td class="text-center" style="min-width: 105px">
                            <div
                              v-for="(teacher, t) in classs.teachers"
                              :key="`teacher${t}`"
                            >
                              {{ teacher.first_name + " " + teacher.last_name }}
                            </div>
                          </td>
                          <td class="text-center">
                            {{ classs.myAveragePercent | twoDec }} %
                          </td>
                          <td class="text-center">
                            {{ classs.classcodeAveragePercent | twoDec }} %
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-container>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </div>

      <v-avatar class="ma-3" size="125" tile>
        <v-icon class="imgStyle" large dark>mdi-account-school</v-icon>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isDialog: false,
    };
  },

  props: ["assignmentOverview"],
};
</script>
