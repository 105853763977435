<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 130px">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Contact Requests'"
          ></span>
          <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            to="/contact-requests/create"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="search_keyword"
            label="Search ... "
            prepend-inner-icon="mdi-magnify"
            @keydown.enter="getData"
            @change="page = 1"
          ></v-text-field>
        </v-col>
        <v-col md="6">
          <v-pagination v-model="page" :length="paginationLength">
          </v-pagination>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left sr_no_class">Sr No</th>
                    <th class="text-left" style="min-width: 65px">Date</th>
                    <th class="text-left" style="min-width: 65px">Name</th>
                    <th class="text-left" style="min-width: 65px">Email</th>
                    <th class="text-left" style="min-width: 130px">
                      School Name
                    </th>
                    <th class="text-left" style="min-width: 57px">Role</th>
                    <th class="text-left" style="min-width: 57px">City</th>
                    <th class="text-left" style="min-width: 61px">State</th>
                    <th class="text-left" style="min-width: 76px">Pincode</th>
                    <th class="text-left" style="min-width: 95px">
                      Description
                    </th>
                    <th class="text-left" style="min-width: 67px">Status</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(contactRequest, i) in contactRequests"
                    :key="contactRequest.name"
                  >
                    <td>{{ i + 1 }}</td>
                    <td style="min-width: 105px">
                      {{ contactRequest.created_at }}
                    </td>
                    <td style="min-width: 105px">{{ contactRequest.name }}</td>
                    <td style="min-width: 255px">{{ contactRequest.email }}</td>
                    <td
                      style="min-width: 76px"
                      v-html="contactRequest.school_name"
                    ></td>
                    <td style="min-width: 90px">{{ contactRequest.role }}</td>
                    <td style="min-width: 105px">{{ contactRequest.city }}</td>
                    <td style="min-width: 150px">{{ contactRequest.state }}</td>
                    <td style="min-width: 105px">
                      {{ contactRequest.pincode }}
                    </td>
                    <td style="min-width: 105px">
                      {{ contactRequest.description }}
                    </td>
                    <td style="min-width: 105px">
                      {{ contactRequest.status }}
                    </td>
                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/contact-requests/${contactRequest.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  name: "ContactRequestsPage",
  data() {
    return {
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      contactRequests: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let contactRequests = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword;
      contactRequests = await axios.get(`contact_requests?${query}`);
      this.contactRequests = contactRequests.data.data;
      this.isLoading = false;
    },
  },
};
</script>
