<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 155px">
          <span
            class="text-h5 font-weight-bold"
            v-text="`User Timestamp Dashboard`"
          ></span>
        </v-col>
      </v-row>
      <v-card elevation="6" shaped class="rounded-tl-xl my-5">
        <v-row class="pa-5">
          <v-col sm="2">
            <v-menu
              v-model="date_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.date_filter"
                  label="Date"
                  class="mt-0"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @click:clear="form.date_filter = null"
                ></v-text-field>
              </template>
              <v-date-picker
                hide-details
                v-model="form.date_filter"
                @change="
                  (form.month_year_filter = null),
                    (form.month_year_filter_formatted = null),
                    (date_menu = false)
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col sm="2">
            <v-menu
              ref="menu"
              v-model="month_year_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.month_year_filter_formatted"
                  label="Year - Month"
                  class="mt-0"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @click:clear="form.month_year_filter = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.month_year_filter"
                type="month"
                @change="
                  (form.date_filter = null),
                    (month_year_menu = false),
                    customMonthFormat()
                "
                no-title
                scrollable
              >
                <!-- :show-adjacent-months="false" -->
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            sm="2"
            v-if="
              roleName == 'ACADEMIC TEAM' ||
              (roleName == 'SUPER ADMIN' && company.id == null)
            "
          >
            <v-autocomplete
              ref="School"
              small-chips
              clearable
              @input="getUsers"
              :items="schoolItems"
              @click:clear="form.company_id = null"
              v-model="form.company_id"
              label="School"
              prepend-inner-icon="mdi-chair-school"
              :error-messages="errors.company_id"
            ></v-autocomplete>
          </v-col>
          <v-col sm="2">
            <v-autocomplete
              ref="Role"
              small-chips
              clearable
              @click:clear="form.role_id = null"
              v-model="form.role_id"
              @input="getUsers"
              :items="roleItems"
              label="Role"
              hide-details
              prepend-icon="mdi-domain"
            ></v-autocomplete>
          </v-col>
          <v-col md="2">
            <v-autocomplete
              ref="User"
              small-chips
              clearable
              :loading="isUsersLoading"
              @click:clear="form.user_id = null"
              v-model="form.user_id"
              :items="userItems"
              label="User"
              hide-details
              prepend-icon="mdi-domain"
              no-data-text="No User Found"
            ></v-autocomplete>
          </v-col>
          <v-spacer></v-spacer>
          <v-col sm="1" class="mt-3">
            <v-btn color="primary" data-v-step="5" @click="getData">
              SEARCH</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
      <MyLoader v-if="isLoading"></MyLoader>
      <template v-else>
        <v-card elevation="6">
          <v-card-actions>
            <v-spacer></v-spacer>
            <download-excel
              v-show="report_excel.length"
              title="User Timestamp Report"
              :data="report_excel"
              :fields="report_excel_fields"
              worksheet="User Timestamp Report"
              name="User-Timestamp-Report.xls"
            >
              <v-btn color="primary">
                <v-icon size="20">mdi-microsoft-excel</v-icon> &nbsp;Download
              </v-btn>
            </download-excel>
            <download-excel
              class="ml-5"
              v-show="log_excel.length"
              title="User Timestamp Log"
              :data="log_excel"
              :fields="log_excel_fields"
              worksheet="User Timestamp Log"
              name="User-Timestamp-Log.xls"
            >
              <v-btn color="primary">
                <v-icon size="20">mdi-microsoft-excel</v-icon> &nbsp;Download
                Log
              </v-btn>
            </download-excel>
          </v-card-actions>
          <v-card-text>
            <v-simple-table fixed-header fixed-footer height="287px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left sr_no_class">Sr No</th>
                    <th class="text-center" style="min-width: 100px">
                      School Name
                    </th>
                    <th class="text-center" style="min-width: 100px">Role</th>
                    <th class="text-center" style="min-width: 200px">Email</th>
                    <th class="text-center" style="min-width: 100px">Count</th>
                    <th class="text-center" style="min-width: 100px">
                      Time Spent
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(user_timestamp, uT) in users_timestamps"
                    :key="`user${uT}`"
                  >
                    <td
                      @click="openLinkInNewTab(user_timestamp.user_id)"
                      class="routerLink te"
                    >
                      {{ uT + 1 }}
                    </td>
                    <td
                      @click="openLinkInNewTab(user_timestamp.user_id)"
                      class="routerLink text-center"
                    >
                      {{ user_timestamp.company.name }}
                    </td>
                    <td
                      @click="openLinkInNewTab(user_timestamp.user_id)"
                      class="routerLink text-center"
                    >
                      {{ user_timestamp.user.roles[0].name }}
                    </td>
                    <td
                      class="text-center routerLink primary--text"
                      @click="showURLReport(user_timestamp.user_id)"
                    >
                      {{ user_timestamp.user.email }}
                    </td>
                    <td
                      @click="openLinkInNewTab(user_timestamp.user_id)"
                      class="routerLink text-center"
                    >
                      {{ user_timestamp.total }}
                    </td>
                    <td
                      @click="openLinkInNewTab(user_timestamp.user_id)"
                      class="routerLink text-center"
                    >
                      {{ user_timestamp.overall_time_spent | secToHhMm }}
                    </td>
                  </tr>
                </tbody>
                <tfoot
                  style="
                    position: sticky !important;
                    bottom: 0 !important;
                    background: #fff !important;
                  "
                >
                  <tr>
                    <td class="text-center" colspan="4">
                      <strong>Total</strong>
                    </td>
                    <td class="text-center">
                      <strong>{{ total_count }}</strong>
                    </td>
                    <td class="text-center">
                      <strong>{{ total_timespent | secToHhMm }}</strong>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </template>
    </v-container>
    <!-- Show URL Wise Dialog Box -->
    <v-dialog v-model="ShowURLWiseReport" max-width="850px">
      <v-card elevation="2" class="rounded-tl-xl rounded-br-xl no-select">
        <v-container>
          <v-row>
            <v-card-title> URL Wise Report </v-card-title>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="ShowURLWiseReport = false"
                    icon
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Close</span>
              </v-tooltip>
            </v-card-actions>
          </v-row>
          <v-card-text>
            <v-row>
              <v-col>
                <v-simple-table fixed-header height="575">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center sr_no_class">Sr No</th>
                        <th class="text-center">URL</th>
                        <th class="text-center" style="min-width: 100px">
                          Count
                        </th>
                        <th class="text-center" style="min-width: 100px">
                          Time
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(url, uUT) in url_wise_users_timestamps"
                        :key="`url_${uUT}`"
                      >
                        <td class="text-center">{{ uUT + 1 }}</td>
                        <td class="text-center">{{ url.url }}</td>
                        <td class="text-center">{{ url.total }}</td>
                        <td class="text-center">
                          {{ url.overall_time_spent | secToHhMm }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot
                      style="
                        position: sticky !important;
                        bottom: 0 !important;
                        background: #fff !important;
                      "
                    >
                      <tr>
                        <td class="text-center" colspan="2">
                          <strong>Total</strong>
                        </td>
                        <td class="text-center">
                          <strong>{{ url_total_count }}</strong>
                        </td>
                        <td class="text-center">
                          <strong>{{ url_total_timespent | secToHhMm }}</strong>
                        </td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- /Help Dialog Box -->
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      form: {
        date_filter: new Date().toISOString().slice(0, 10),
        month_year_filter: "",
      },
      schoolItems: [],
      roleItems: [],
      userItems: [],
      users_timestamps: [],
      url_wise_users_timestamps: [],
      report_excel_fields: {
        "Sr No": "sr_no",
        "School Name": "school_name",
        Role: "role",
        Email: "email",
        Count: "count",
        "Time Spent": "time_spent",
      },
      report_excel: [],
      log_excel_fields: {
        "Sr No": "sr_no",
        "School Name": "school_name",
        Role: "role",
        Email: "email",
        Month: "month",
        Year: "year",
        Date: "date",
        Time: "time",
        "Time Spent": "time_spent",
        URL: "url",
        Name: "name",
      },
      log_excel: [],
      isLoading: false,
      isUsersLoading: false,
      isURLReportLoading: false,
      ShowURLWiseReport: false,
      date_menu: false,
      month_year_menu: false,
      total_count: "",
      total_timespent: "",
      url_total_count: "",
      url_total_timespent: "",
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
    };
  },
  watch: {
    company: "getData",
  },
  mounted() {
    this.form.role_id = parseInt(
      this.$route.query.role_id ? this.$route.query.role_id : ""
    );
    if (this.company.id) {
      this.form.company_id = this.company.id ? this.company.id : "";
      this.getUsers();
      this.getData();
    }
    this.getMasters();
  },
  methods: {
    customMonthFormat() {
      let date = this.form.month_year_filter;
      let year = date.split("-")[0];
      let month = moment(date.split("-")[1], "M").format("MMM");
      this.form.month_year_filter_formatted = `${year}-${month}`;
    },
    openLinkInNewTab(user_id) {
      const link = `user-timestamp/logs?company_id=${this.form.company_id}&date_filter=${this.form.date_filter}&role_id=${this.form.role_id}&user_id=${user_id}&month_year=${this.form.month_year_filter}`;
      window.open(link, "_blank");
    },
    async getMasters() {
      let masters = await axios.get(`user_timestamps/masters`);
      masters = masters.data;
      console.log(masters);
      // Schools
      masters.schools.forEach((school) => {
        this.schoolItems.push({
          id: school.id,
          text: school.name,
          value: school.id,
        });
      });
      // Roles
      masters.roles.forEach((role) => {
        if (role.name != "INFAKT TEACHER" && role.name != "ACADEMIC TEAM") {
          this.roleItems.push({
            id: role.id,
            text: role.name,
            value: role.id,
          });
        }
      });
    },
    async getData() {
      this.isLoading = true;
      try {
        this.form.type = "BASE_REPORT";
        this.form.role_id = this.form.role_id ? this.form.role_id : "";
        this.form.company_id = this.form.company_id ? this.form.company_id : "";
        switch (true) {
          case this.form.date_filter != null:
            this.form.date_filter = this.form.date_filter
              ? this.form.date_filter
              : "";
            break;
          case this.form.month_year_filter != null:
            this.form.month_year_filter = this.form.month_year_filter
              ? this.form.month_year_filter
              : "";
            break;
          case this.form.month_year_filter == null &&
            this.form.date_filter == null:
            this.form.month_year_filter = "";
            this.form.month_year_filter_formatted = "";
            this.form.date_filter = "";
            break;

          default:
            break;
        }
        this.form.user_id = this.form.user_id ? this.form.user_id : "";
        let query =
          "type=" +
          this.form.type +
          "&date_filter=" +
          this.form.date_filter +
          "&month_year=" +
          this.form.month_year_filter +
          "&company_id=" +
          this.form.company_id +
          "&role_id=" +
          this.form.role_id +
          "&user_id=" +
          this.form.user_id;
        let users_timestamps = await axios.get(
          `/user_timestamps/report?${query}`
        );
        this.users_timestamps = users_timestamps.data.data;
        this.total_timespent = users_timestamps.data.final_timespent;
        this.total_count = users_timestamps.data.final_count;
        this.exportToUserTimestampExcel();
        await this.exportToUserTimestampLogExcel();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async showURLReport(user_id) {
      this.isURLReportLoading = true;
      this.ShowURLWiseReport = false;
      try {
        this.form.type = "URL_REPORT";
        this.form.role_id = this.form.role_id ? this.form.role_id : "";
        switch (true) {
          case this.form.date_filter != null:
            this.form.date_filter = this.form.date_filter
              ? this.form.date_filter
              : "";
            break;
          case this.form.month_year_filter != null:
            this.form.month_year_filter = this.form.month_year_filter
              ? this.form.month_year_filter
              : "";
            break;
          case this.form.month_year_filter == null &&
            this.form.date_filter == null:
            this.form.month_year_filter = "";
            this.form.date_filter = "";
            break;

          default:
            break;
        }
        let query =
          "type=" +
          this.form.type +
          "&company_id=" +
          this.form.company_id +
          "&date_filter=" +
          this.form.date_filter +
          "&month_year=" +
          this.form.month_year_filter +
          "&role_id=" +
          this.form.role_id +
          "&user_id=" +
          user_id;
        let users_timestamps = await axios.get(
          `/user_timestamps/report?${query}`
        );
        this.url_wise_users_timestamps = users_timestamps.data.data;
        this.url_total_timespent = users_timestamps.data.final_timespent;
        this.url_total_count = users_timestamps.data.final_count;
        // if (this.url_wise_users_timestamps.lenght) {
        this.ShowURLWiseReport = true;
        console.log(this.ShowURLWiseReport);
        // }
        this.isURLReportLoading = false;
      } catch (error) {
        this.isURLReportLoading = false;
      }
    },
    async getUsers() {
      this.isUsersLoading = true;
      try {
        this.userItems = [];
        this.form.role_id = this.form.role_id ? this.form.role_id : "";
        let query =
          "company_id=" +
          this.form.company_id +
          "&role_id=" +
          this.form.role_id;
        let users = await axios.get(`/users?${query}`);
        users = users.data.data;
        users.forEach((user) => {
          this.userItems.push({
            id: user.id,
            text: user.email,
            value: user.id,
          });
        });
        this.isUsersLoading = false;
      } catch (error) {
        console.log(error);
        this.isUsersLoading = false;
      }
    },
    exportToUserTimestampExcel() {
      try {
        this.report_excel = [];
        let sr_no = 1;
        this.users_timestamps.forEach((user_timestamp) => {
          let user_timestamp_report = {
            sr_no: sr_no,
            school_name: user_timestamp.company.name
              ? user_timestamp.company.name
              : "",
            role: user_timestamp.user.roles[0].name
              ? user_timestamp.user.roles[0].name
              : "",
            email: user_timestamp.user.email ? user_timestamp.user.email : "",
            count: user_timestamp.total ? user_timestamp.total : "",
            time_spent: user_timestamp.overall_time_spent
              ? this.formatTime(user_timestamp.overall_time_spent)
              : "",
          };
          this.report_excel.push(user_timestamp_report);
          sr_no++;
        });
      } catch (error) {
        console.log(error);
      }
    },
    async exportToUserTimestampLogExcel() {
      try {
        this.log_excel = [];
        let sr_no = 1;
        this.form.type = "LOG_REPORT";
        let query =
          "type=" +
          this.form.type +
          "&date_filter=" +
          this.form.date_filter +
          "&month_year=" +
          this.form.month_year_filter +
          "&company_id=" +
          this.form.company_id +
          "&role_id=" +
          this.form.role_id +
          "&user_id=" +
          this.form.user_id;
        let users_timestamps = await axios.get(
          `/user_timestamps/report?${query}`
        );
        this.users_timestamp_logs = users_timestamps.data.data;
        this.users_timestamp_logs.forEach((user_timestamp) => {
          let user_timestamp_log = {
            sr_no: sr_no,
            school_name: user_timestamp.company.name
              ? user_timestamp.company.name
              : "",
            role: user_timestamp.user.roles[0].name
              ? user_timestamp.user.roles[0].name
              : "",
            email: user_timestamp.user.email ? user_timestamp.user.email : "",
            month: user_timestamp.created_at
              ? moment(user_timestamp.created_at).format("MMMM")
              : "",
            year: user_timestamp.created_at
              ? moment(user_timestamp.created_at).format("YYYY")
              : "",
            date: user_timestamp.created_at
              ? moment(user_timestamp.created_at).format("DD-MM-YYYY")
              : "",
            time:
              user_timestamp.timestamp && user_timestamp.total_time_spent
                ? this.SubtractTimeStamp(
                    user_timestamp.timestamp,
                    user_timestamp.total_time_spent
                  )
                : "",
            time_spent: user_timestamp.total_time_spent
              ? this.formatTime(user_timestamp.total_time_spent)
              : "",
            url: user_timestamp.url ? user_timestamp.url : "",
            name: user_timestamp.name ? user_timestamp.name : "",
          };
          this.log_excel.push(user_timestamp_log);
          sr_no++;
        });
      } catch (error) {
        console.log(error);
      }
    },
    formatTime(value) {
      if (value == "" || value == null) return "00:00:00";
      var sec_num = parseInt(value, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? (v == 0 ? "00" : "0" + v) : v))
        .join(":");
    },
    SubtractTimeStamp(time, seconds) {
      return moment(time).subtract(seconds, "seconds").format("hh:mm:ss");
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
