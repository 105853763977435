<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Exceeded Devices'"
          ></span>
          <v-btn class="mx-2 mb-2" x-small color="primary" @click="ResetMac()">
            Reset All
          </v-btn>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 56px">Sr No</th>
                    <th class="text-left" style="min-width: 65px">Date</th>
                    <th class="text-left" style="min-width: 65px">Name</th>
                    <th class="text-left" style="min-width: 65px">Email</th>
                    <th class="text-left" style="min-width: 115px">
                      Current Mac
                    </th>
                    <th class="text-left" style="min-width: 57px">Role</th>
                    <th class="text-left" style="min-width: 57px">Status</th>
                    <th class="text-right" style="min-width: 70px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(exceeded_device_log, i) in exceeded_device_logs"
                    :key="exceeded_device_log.name"
                  >
                    <td>{{ i + 1 }}</td>
                    <td style="min-width: 150px">
                      {{ exceeded_device_log.created_at | in_days }}
                      {{ exceeded_device_log.created_at | HM }}
                    </td>
                    <td style="min-width: 85px">
                      {{ exceeded_device_log.user.first_name }}
                      {{ exceeded_device_log.user.last_name }}
                    </td>
                    <td style="min-width: 150px">
                      {{ exceeded_device_log.user.email }}
                    </td>
                    <td>{{ exceeded_device_log.current_mac }}</td>
                    <td style="min-width: 105px">
                      {{ exceeded_device_log.user.roles[0].name }}
                    </td>
                    <td style="min-width: 105px">
                      {{ exceeded_device_log.status == 0 ? "PENDING" : "DONE" }}
                    </td>
                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        x-small
                        color="primary"
                        @click="ResetMac(exceeded_device_log.user.id)"
                      >
                        Reset
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  name: "UsersPage",
  data() {
    return {
      exceeded_device_logs: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let exceeded_device_logs = await axios.get(`exceeded_device_logs`);
      this.exceeded_device_logs = exceeded_device_logs.data.data;
      this.isLoading = false;
    },
    async ResetMac(user_id = "") {
      this.isReseting = true;
      let query = "";
      if (user_id) {
        query = "user_id=" + user_id;
      }
      let is_resetted = await axios.post(`/users/reset_mac?${query}`);
      if (is_resetted) {
        this.isReseting = false;
        this.getData();
      }
    },
  },
};
</script>
