<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 165px">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Teacher Performance Report'"
          ></span>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="classcode"
            v-model="form.subject_name"
            :items="classcodeItems"
            clearable
            :error-messages="errors.subject_name"
            label="Subject"
            prepend-icon="mdi-application-edit"
          ></v-autocomplete>
        </v-col>
        <v-col></v-col>
        <v-col class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <!--/ Filters Section -->
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col>
          <v-card elevation="6">
            <v-card-text>
              <v-simple-table fixed-header height="285px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left max pr-0 pl-3"
                        style="min-width: 56px"
                      >
                        Sr No
                      </th>
                      <th class="text-center" style="min-width: 100px">
                        Teacher
                      </th>
                      <th class="text-center" style="min-width: 100px">
                        Classcodes
                      </th>
                      <th class="text-center" style="min-width: 100px">
                        Assignments Posted
                      </th>
                      <th class="text-center" style="min-width: 100px">
                        Total Students
                      </th>
                      <th class="text-center" style="min-width: 100px">
                        Submission Rate
                      </th>
                      <th class="text-center" style="min-width: 100px">
                        Overall Average
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(teacher, i) in teacherPerformance"
                      :key="`teacher${i}`"
                    >
                      <td>{{ i + 1 }}</td>
                      <td class="text-center">{{ teacher.name }}</td>
                      <td class="text-center">{{ teacher.total_classcode }}</td>
                      <td class="text-center">
                        {{ teacher.total_assignment_assigned }}
                      </td>
                      <td class="text-center">{{ teacher.total_students }}</td>
                      <td class="text-center">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"
                              >{{
                                teacher.totalSubmissionsPercent | twoDec
                              }}
                              %</span
                            >
                          </template>
                          <span> [ Total Attempted / Total Assignment ] </span>
                        </v-tooltip>
                      </td>
                      <td class="text-center">
                        {{ teacher.totalAveragePercent | twoDec }} %
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="12" v-if="TeacherPerformanceChart.length > 1">
          <v-card elevation="6">
            <v-card-title>
              Submission Rate VS Class Average Score
              <v-spacer></v-spacer>
              <view-fullscreen
                :title="`Submission Rate VS Class Average Score `"
                :type="`ColumnChart`"
                :Data="TeacherPerformanceChart"
                :Options="columnchartOptions"
              ></view-fullscreen>
            </v-card-title>
            <v-card-text>
              <GChart
                type="ColumnChart"
                :data="TeacherPerformanceChart"
                :options="columnchartOptions"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";

export default {
  name: "TeacherPerformanceReport",
  components: { loader },
  data() {
    return {
      form: {},
      isLoading: false,
      classcodes: [],
      classcodeItems: [],
      teacherPerformance: [],
      selectedStudentId: "",
      schoolId: "",
      // Chart Components
      TeacherBasedOn: "1",
      TeacherPerformanceChart: [],
      columnchartOptions: {
        theme: "material",
        colors: ["#007ACC", "#FFA500"],
        chartArea: { left: 50, right: 50 },
        bar: { groupWidth: "30%" },
        annotations: {
          textStyle: {
            fontSize: 10,
          },
        },
        vAxis: {
          minValue: 0,
          maxValue: 100,
          title: "In Percentage",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 40 },

          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          title: "Teachers",
          titleTextStyle: {
            bold: true,
          },
          gridlines: {
            color: "transparent",
          },
        },
        legend: { position: "top", alignment: "center" },
      },
    };
  },
  mounted() {
    this.schoolId = this.company.id;
    this.getMasters();
  },

  methods: {
    async getMasters() {
      let masters = await axios.get(`users/masters?board_id=${this.board.id}`);
      masters = masters.data;
      this.classcodes = masters.classcodes;
      // ClassCodes
      this.classcodes = [...new Set(this.classcodes)];
      this.classcodes.forEach((classcode) => {
        this.classcodeItems.push({
          id: classcode.id,
          text: classcode.subject_name,
          value: this.subject_name,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      this.teacherPerformance = [];
      this.TeacherPerformanceChart = [];
      this.form.subject_name = this.form.subject_name
        ? this.form.subject_name
        : "";
      try {
        let response;
        let query =
          "board_id=" +
          this.board.id +
          "&subject_name=" +
          this.form.subject_name;
        response = await axios.get(`/teacher_performance_report?${query}`);
        this.teacherPerformance = response.data.data;
        if (this.teacherPerformance) {
          console.log(this.teacherPerformance);
          // Submission Rate VS Clase Average Score
          let TeacherPerformanceChart = [
            [this.TeacherBasedOn, "Submisssion Rate [%]", "Class Average [%]"],
          ];
          this.teacherPerformance.forEach((data) => {
            let Submission = parseFloat(
              data.totalSubmissionsPercent != 0
                ? data.totalSubmissionsPercent.toFixed(2)
                : 0
            );
            let classPercent = parseFloat(
              data.totalAveragePercent != 0
                ? data.totalAveragePercent.toFixed(2)
                : 0
            );
            TeacherPerformanceChart.push([data.name, Submission, classPercent]);
          });
          this.TeacherPerformanceChart = TeacherPerformanceChart;
          console.log(this.TeacherPerformanceChart);
        }
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async getStandardSubjects() {
      this.classcodeItems = [];
      this.form.subject_name = "";
      let standardsubjects = this.classcodes.filter(
        (subject) => subject.standard_id == this.form.standard_id
      );
      standardsubjects = [...new Set(standardsubjects)];
      standardsubjects.forEach((ss) => {
        this.classcodeItems.push({
          id: ss.id,
          text: ss.subject_name,
          value: ss.subject_name,
        });
      });
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
