<template>
  <section class="no-select">
    <p class="font-weight-bold">Lock a Reading Level to a Classcode</p>
    <v-btn small color="primary" dark @click="addEmptyContentLockClasscode">
      Add Row
    </v-btn>
    <br /><br />
    <v-row
      v-for="(
        content_lock_classcode, cLC
      ) in this.article.content_lock_classcodes?.filter(
        (cLC) => cLC.created_by_id == this.user.id
      )"
      :key="`content_lock_classcode${cLC}`"
    >
      <v-col sm="5">
        <v-autocomplete
          ref="content_lock_classcode.classcode_id"
          v-model="content_lock_classcode.classcode_id"
          :items="classcodes"
          label="Select classcode"
          prepend-icon="mdi-application-edit"
        ></v-autocomplete>
      </v-col>
      <v-col sm="3">
        <v-autocomplete
          ref="content_lock_classcode.level"
          v-model="content_lock_classcode.level"
          :items="levels"
          label="Select level"
          prepend-icon="mdi-application-edit"
        ></v-autocomplete>
      </v-col>
      <v-col sm="1">
        <v-dialog v-model="isDeleteClasscodeDialogs[cLC]" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small dark color="red">
              <v-icon dark v-bind="attrs" v-on="on"> mdi-close-thick </v-icon>
            </v-btn>
          </template>

          <v-card elevation="10">
            <v-card-title>
              <v-icon large color="primary"> mdi-information-outline </v-icon>
              &nbsp; Do you want to remove the classcode?
            </v-card-title>
            <v-card-text>
              <v-btn
                color="primary"
                @click.stop="deleteClasscode(content_lock_classcode)"
              >
                Yes
              </v-btn>
              &nbsp;
              <v-btn color="red" dark @click.stop="closeClasscodeDialogs">
                No
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isDeleteClasscodeDialogs: [],
    };
  },
  props: ["article", "classcodes", "levels"],
  watch: {
    article: "onArticleChanged",
  },
  mounted() {},
  methods: {
    onArticleChanged() {},
    addEmptyContentLockClasscode() {
      let content_lock_classcode = {
        content_id: this.article.id,
        classcode_id: "",
        created_by_id: this.user.id,
        level: "",
      };
      this.article.content_lock_classcodes.push(content_lock_classcode);
      console.log(this.article);
    },
    deleteClasscode(content_lock_classcode) {
      this.article.content_lock_classcodes.splice(
        this.article.content_lock_classcodes.indexOf(content_lock_classcode),
        1
      );
      this.closeClasscodeDialogs();
    },
    closeClasscodeDialogs() {
      this.isDeleteClasscodeDialogs = [];
    },
  },
};
</script>
<style scoped>
.no-select {
  user-select: none;
}
</style>
