<template>
  <v-main>
    <v-container fluid>
      <v-card-title>
        <backbutton></backbutton>
        {{ articles[0].epaper_collection.collection_name }}
      </v-card-title>
      <v-row justify="start">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Epapers'"></span>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Sr No</th>
                  <th class="text-left">Category</th>
                  <th class="text-left">Edition Name</th>
                  <th class="text-left">Story Date</th>
                  <th class="text-left">Headline</th>
                  <th class="text-left">Word Count</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(etArticle, at) in articles.epapers"
                  :key="`etArticle.name${at}`"
                >
                  <td>{{ at + 1 }}</td>
                  <td>{{ etArticle.category }}</td>
                  <td>{{ etArticle.edition_name }}</td>
                  <td>{{ etArticle.story_date | MMddyyyy }}</td>
                  <td>{{ etArticle.headline }}</td>
                  <td>{{ etArticle.word_count }}</td>
                  <td>
                    <v-btn
                      target="_blank"
                      class="mx-2"
                      x-small
                      color="primary"
                      :to="`/epaper/et/${etArticle.id}`"
                    >
                      View
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import Backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";

export default {
  data() {
    return {
      isLoading: false,
      articles: [],
    };
  },
  components: {
    Backbutton,
    Loader,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;

        let articles = await axios.get(
          `/collection_epapers?epaper_collection_id=${this.$route.params.epaperCollectionId}`
        );
        this.articles = articles.data.data;

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
