var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(_vm.myTimer != 0)?_c('v-card',{attrs:{"rounded":"","id":"thing_to_stick","align":"center","color":"blue lighten-5"},model:{value:(_vm.display_clock),callback:function ($$v) {_vm.display_clock=$$v},expression:"display_clock"}},[_c('v-card-text',[_c('span',{staticClass:"smallScreen"},[_c('v-row',[_c('v-col',[_c('v-layout',{attrs:{"justify-center":"","align-center":"","row":"","wrap":""}},[_c('h1',{style:(`${
                  _vm.myTimer <= 0.2 * _vm.plannedTimeInSeconds ? 'color: red;' : ''
                }font-size: small`)},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-timer-alert")])],1)]}}],null,false,2747390891)},[_c('span',[_vm._v("This is a timed assignment.")])])],1)])],1)],1),_c('v-row',[_c('v-col',[_c('v-layout',{attrs:{"justify-center":"","align-center":"","row":"","wrap":""}},[_c('h1',{style:(` ${
                  _vm.myTimer <= 0.2 * _vm.plannedTimeInSeconds ? 'color: red; ' : ''
                } font-size: small`)},[_vm._v(" "+_vm._s(_vm._f("secToHhMm")(_vm.myTimer))+" ")])])],1)],1),(_vm.myTimer <= 0.2 * _vm.plannedTimeInSeconds)?_c('v-row',[_c('v-col',[_c('h3',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" Only "+_vm._s(_vm._f("secToHhMm")(_vm.myTimer))+" minutes left ")])])],1):_vm._e()],1),_c('span',{staticClass:"midScreen"},[_c('v-row',[_c('v-col',[_c('v-layout',{attrs:{"justify-center":"","align-center":"","row":"","wrap":""}},[_c('h1',{style:(` ${
                  _vm.myTimer <= 0.2 * _vm.plannedTimeInSeconds ? 'color: red; ' : ''
                } font-size: medium`)},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-timer-alert")])],1)]}}],null,false,2960453100)},[_c('span',[_vm._v("This is a timed assignment.")])])],1)])],1)],1),_c('v-row',[_c('v-col',[_c('v-layout',{attrs:{"justify-center":"","align-center":"","row":"","wrap":""}},[_c('h1',{style:(` ${
                  _vm.myTimer <= 0.2 * _vm.plannedTimeInSeconds ? 'color: red; ' : ''
                } font-size: medium`)},[_vm._v(" "+_vm._s(_vm._f("secToHhMm")(_vm.myTimer))+" ")])])],1)],1),(_vm.myTimer <= 0.2 * _vm.plannedTimeInSeconds)?_c('v-row',[_c('v-col',[_c('h3',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" Only "+_vm._s(_vm._f("secToHhMm")(_vm.myTimer))+" minutes left ")])])],1):_vm._e()],1),_c('span',{staticClass:"largeScreen"},[_c('v-row',[_c('v-col',[_c('v-layout',{attrs:{"justify-center":"","align-center":"","row":"","wrap":""}},[_c('h1',{style:(_vm.myTimer <= 0.2 * _vm.plannedTimeInSeconds
                    ? 'color: red; font-size: 50px'
                    : 'font-size: 50px')},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"40"}},[_vm._v("mdi-timer-alert")])],1)]}}],null,false,1012508619)},[_c('span',[_vm._v("This is a timed assignment.")])])],1)])],1)],1),_c('v-row',[_c('v-col',[_c('v-layout',{staticStyle:{"height":"45"},attrs:{"justify-center":"","align-center":"","row":"","wrap":""}},[_c('h1',{style:(_vm.myTimer <= 0.2 * _vm.plannedTimeInSeconds
                    ? 'color: red; font-size: 30px'
                    : 'font-size: 30px')},[_vm._v(" "+_vm._s(_vm._f("secToHhMm")(_vm.myTimer))+" ")])])],1)],1),(_vm.myTimer <= 0.2 * _vm.plannedTimeInSeconds)?_c('v-row',[_c('v-col',[_c('h3',{staticStyle:{"color":"red"}},[_vm._v(" Only "+_vm._s(_vm._f("secToHhMm")(_vm.myTimer))+" minutes left ")])])],1):_vm._e()],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }