<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Add a {{ title }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.subject_name"
                outlined
                label="Subject Name"
                prepend-inner-icon="mdi-application-edit"
                :error-messages="errors.subject_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-switch
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-switch
                v-model="form.is_optional"
                inset
                :label="`Is Optional: ${form.is_optional ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save"> Save Class </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { backbutton, Loader },
  data() {
    return {
      form: {
        name: "",
        is_active: true,
        is_optional: false,
        is_deleted: false,
      },
      title: "",
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let section = await axios.get(`sections/${this.$route.params.sectionId}`);
      this.section = section.data.data;
      this.standard = this.section.standard;

      this.form.section_id = this.section.id;
      this.form.standard_id = this.standard.id;
      this.form.board_id = this.standard.board_id;
      this.title = "Class of " + this.standard.name + " - " + this.section.name;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        let classcode = await axios.post(
          `/sections/${this.$route.params.sectionId}/classcodes`,
          this.form
        );
        this.classcode = classcode.data.data;
        this.isLoading = false;

        this.$router.push(
          `/standards/${this.$route.params.standardId}/sections/${this.$route.params.sectionId}/classes`
        );
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
