<template>
  <loader v-if="is_Loading"></loader>
  <section v-else>
    <v-row>
      <!-- Assignments -->
      <v-col sm="4">
        <AssignmentsCount
          :assignmentOverview="assignmentOverview"
          :student_id="currentSubType"
        ></AssignmentsCount>
      </v-col>
      <!-- Contents -->
      <v-col sm="4">
        <ContentWatchCount
          :contentWiseOverview="contentWiseOverview"
          :student_id="currentSubType"
        ></ContentWatchCount>
      </v-col>
      <!-- Classcodes -->
      <v-col sm="4">
        <ClasscodeCount
          :assignmentOverview="assignmentOverview"
        ></ClasscodeCount>
      </v-col>
    </v-row>
    <br />
    <v-row>
      <!-- Pending Assignments -->
      <v-col sm="7">
        <PendingAssignments
          :assignmentOverview="assignmentOverview"
        ></PendingAssignments>
      </v-col>
      <v-col sm="5">
        <TopClasscodes :assignmentOverview="assignmentOverview"></TopClasscodes>
      </v-col>
    </v-row>
    <br />
    <MetadataTypeOverView
      :metadataOverview="metadataOverview"
    ></MetadataTypeOverView>
  </section>
</template>

<script>
import axios from "axios";
import AssignmentsCount from "./assignmentsCount.vue";
import ContentWatchCount from "./contentWatchCount.vue";
import ClasscodeCount from "./classcodeCount.vue";
import PendingAssignments from "./pendingAssignments.vue";
import TopClasscodes from "./topClasscode.vue";
import MetadataTypeOverView from "./metadataTypeOverView.vue";
import Loader from "@/components/loader.vue";

export default {
  name: "SaStudentSchool",
  data() {
    return {
      is_Loading: false,
      assignmentOverview: {
        classcodeWiseAssignments: [],
        statusWiseAssignments: [],
      },
      metadataOverview: [],
      contentWiseOverview: [],
      form: {},
    };
  },
  components: {
    AssignmentsCount,
    ContentWatchCount,
    ClasscodeCount,
    PendingAssignments,
    TopClasscodes,
    MetadataTypeOverView,
    Loader,
  },
  props: ["schoolId", "currentType", "currentSubType"],
  watch: {
    currentSubType: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.is_Loading = true;
      let response;
      response = await axios.get(
        `/student_wise_overview?companyId=${this.schoolId}&studentId=${this.currentSubType}&source=DASHBOARD`
      );
      response = response.data;
      this.assignmentOverview = response.assignmentOverview;
      this.metadataOverview = response.metadataOverview;
      this.contentWiseOverview = response.contentWiseOverview;
      console.log(this.contentWiseOverview);
      this.is_Loading = false;
    },
  },
};
</script>
