import Vue from "vue";
import moment from "moment";

Vue.filter("ddmmyyyy", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("DD-MM-YYYY");
});
Vue.filter("MMddyyyy", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("MMMM DD, YYYY");
});
Vue.filter("mm", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("MMMM");
});
Vue.filter("Y", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("YYYY");
});

Vue.filter("hhmm", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("hh:mm:ss A");
});
Vue.filter("HM", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("hh:mm A");
});

Vue.filter("wordCount", function (value) {
  if (!value) return "";
  value = value.toString().replace(/(<([^>]+)>)/gi, "");
  return value.split(" ").length;
});

Vue.filter("twoDec", function (value) {
  if (value == "0") return 0;
  if (!value) return "";
  // value = value.toString();
  return parseFloat(value).toFixed(2);
});

Vue.filter("limit", function (string = "") {
  if (string?.length > 40) return string.substring(0, 40) + "...";
  return string;
});
Vue.filter("limit3", function (string = "") {
  if (string?.length > 3) return string.substring(0, 3);
  return string;
});

Vue.filter("limit30", function (string = "") {
  if (string?.length > 30) return string.substring(0, 30) + "...";
  return string;
});

Vue.filter("limit45", function (string = "") {
  if (string?.length > 45) return string.substring(0, 45) + "...";
  return string;
});
Vue.filter("limit25", function (string = "") {
  if (string?.length > 25) return string.substring(0, 25) + "...";
  return string;
});

Vue.filter("capitalize", function (string = "") {
  // if (string.length > 25) return string.substring(0, 25) + "...";
  if (string) {
    let capitalized_string =
      string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    return capitalized_string;
  }
});

var filter = function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter("truncate", filter);

Vue.filter("in_days", function (value) {
  if (!value) return "";
  let date = moment(value);
  if (moment().diff(date, "days") >= 2) {
    return date.fromNow(); // '2 days ago' etc.
  }
  console.log(date.calendar());
  return date.calendar().split("at")[0];
});

Vue.filter("Sec_HR", function (value) {
  if (value == "" || value == null) return "0 sec";
  var sec_num = parseInt(value, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  let time = [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");

  time.split(":");
  let formattedTime = "";

  if (parseInt(hours) > 0) {
    formattedTime += `${parseInt(hours)} hr `;
  }

  if (parseInt(minutes) > 0) {
    formattedTime += `${parseInt(minutes)} mins `;
  }

  if (parseInt(seconds) > 0) {
    formattedTime += `${parseInt(seconds)} secs`;
  }

  return formattedTime.trim();
});

Vue.filter("secToHhMm", function (value) {
  if (value == "" || value == null) return "00:00:00";
  var sec_num = parseInt(value, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? (v == 0 ? "00" : "0" + v) : v))
    .join(":");
});

Vue.filter("trim_classcode", function (classcode = "") {
  if (classcode) {
    let originalClasscode = classcode;
    let classcode_array = classcode.split("/");
    let standard_section = classcode_array[1];
    let subject_section = classcode_array[2];
    let trimmed_classcode = "";
    if (this.roleName == "STUDENT") {
      trimmed_classcode = subject_section;
    } else if (this.roleName == "TEACHER") {
      trimmed_classcode = standard_section + "/" + subject_section;
    } else {
      trimmed_classcode = originalClasscode;
    }
    return trimmed_classcode;
  }
});

Vue.filter("getExtension", function (path = "") {
  if (path) {
    let path_array = path.split("/");
    let image_name = path_array[3];
    let extension = image_name?.split(".")[1].toUpperCase();
    return extension;
  }
});
Vue.filter("humanizeCount", function (count = "") {
  if (count) {
    const abbreviations = ["", "K", "M", "B", "T"];
    const tier = (Math.log10(Math.abs(count)) / 3) | 0;

    if (tier === 0) return count;

    const suffix = abbreviations[tier];
    const scale = Math.pow(10, tier * 3);

    const scaledCount = count / scale;
    const formattedCount = scaledCount.toFixed(1).replace(/\.0$/, ""); // Remove '.0' for whole numbers

    return formattedCount + suffix;
  }
});
