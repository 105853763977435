<template>
  <div>
    <TextHighligher @highlighted="addHighlighted">
      <div class="fr-view" ref="htmlContainer" v-html="mainText"></div>
    </TextHighligher>
    <v-dialog v-model="isSelectColorDialog" max-width="500px" class="no-select">
      <v-tabs fixed-tabs background-color="primary" dark v-model="tab">
        <v-tab href="#tab-1"> Annotation / Highlight Text </v-tab>
        <!-- <v-tab href="#tab-2"> Dictionary </v-tab> -->
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card elevation="2">
            <v-container>
              <v-card-text>
                <v-text-field
                  v-model="highlightedText"
                  outlined
                  label="Selected Text"
                  prepend-inner-icon="mdi-text"
                  :error-messages="errors.first_name"
                ></v-text-field>
                <!-- <v-row>
                  <v-col class="mx-3">
                    <VueTextToSpeech
                      v-if="content"
                      :description="highlightedText"
                    ></VueTextToSpeech>
                  </v-col>
                </v-row> -->
                <v-btn
                  color="blue"
                  text
                  v-if="selectedTextDefinitions.length == 0"
                  @click="getTextDefinition"
                >
                  Click here to get the meaning
                </v-btn>
                <span v-else class="no-select">
                  <b><i>Part of Speech</i></b>
                  <li>
                    <b>{{ selectedTextDefinitionsPartOfSpeech }}</b>
                  </li>
                  <b><i>Meaning</i></b>
                  <li
                    v-for="(definition, d) in selectedTextDefinitions"
                    :key="`definition${d}`"
                  >
                    <b>{{ definition.definition }}</b>
                  </li>
                </span>

                <br /><br />

                <v-text-field
                  outlined
                  v-model="annotation"
                  label="Annotation"
                  prepend-inner-icon="mdi-marker"
                ></v-text-field>
                <!-- Select Color -->
                <div class="font-weight-bold no-select">Select Color</div>
                <v-radio-group v-model="selectedColorClass">
                  <v-radio value="color1Class">
                    <template v-slot:label>
                      <v-avatar class="color1Class" size="20"> </v-avatar>
                    </template>
                  </v-radio>
                  <v-radio value="color2Class">
                    <template v-slot:label>
                      <v-avatar class="color2Class" size="20"> </v-avatar>
                    </template>
                  </v-radio>
                  <v-radio value="color3Class">
                    <template v-slot:label>
                      <v-avatar class="color3Class" size="20"> </v-avatar>
                    </template>
                  </v-radio>
                  <v-radio value="color4Class">
                    <template v-slot:label>
                      <v-avatar class="color4Class" size="20"> </v-avatar>
                    </template>
                  </v-radio>
                </v-radio-group>
                <!-- Add Classcode -->
                <div v-if="roleName != 'STUDENT'">
                  <div class="font-weight-bold no-select">
                    Select Classcodes
                  </div>
                  <v-autocomplete
                    ref="classcode"
                    v-model="selectedClasscodes"
                    :items="classcodes"
                    label="Classcode"
                    prepend-icon="mdi-database-search"
                    multiple
                    chips
                  ></v-autocomplete>
                </div>
                <v-btn
                  :loading="isLoading"
                  :disabled="isLoading"
                  color="primary"
                  @click.stop="saveSelectColorDialog('ANNOTATION')"
                >
                  Save
                </v-btn>
                &nbsp;
                <v-btn color="red" dark @click.stop="closeSelectColorDialog">
                  Close
                </v-btn>
              </v-card-text>
            </v-container>
          </v-card>
        </v-tab-item>
        <!-- <v-tab-item value="tab-2">
          <v-card>
            <v-card-title>{{ highlightedText }}</v-card-title>
            <v-card-text>
              <li
                v-for="(definition, d) in selectedTextDefinitions"
                :key="`definition${d}`"
              >
                {{ definition.definition }}
              </li>
              <br />
              <v-btn color="primary" @click.stop="closeSelectColorDialog">
                Close
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item> -->
      </v-tabs-items>
    </v-dialog>
    <v-dialog v-model="isShowDialog" max-width="400px" persistent>
      <v-card elevation="2">
        <v-card-title>Annotation</v-card-title>
        <v-card-text>
          Selected Text: {{ highlightedText }}
          <br />
          <br />
          <v-btn color="primary" @click.stop="closeShowDialog"> Close </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_delete_message" max-width="400px" persistent>
      <v-card elevation="2">
        <v-card-title
          class="no-select justify-center primary accent-1 white--text py-1"
        >
          Warning
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col class="no-select">
              Do you want to delete this
              {{ content_metadata_detail.metadata_type }} :
              {{ content_metadata_detail.selected_text }}?
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="2">
              <v-btn
                :loading="is_deleting"
                :disabled="is_deleting"
                small
                color="primary"
                @click.stop="deleteContentMetadata(content_metadata_detail.id)"
              >
                Yes
              </v-btn></v-col
            >
            <v-col md="2"
              ><v-btn
                color="error"
                small
                @click.stop="
                  (is_delete_message = false), (content_metadata_detail = {})
                "
              >
                No
              </v-btn></v-col
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextHighligher from "./TextHighlighter.vue";
import VueTextToSpeech from "./VueTextToSpeech.vue";
import axios from "axios";
import { el } from "vuetify/lib/locale";
export default {
  data() {
    return {
      selectedTextJson: {
        content_id: "",
        content_description_id: "",
        metadata_type: "",
        color_class: "",
        selected_text: "",
        annotation: "",
        user_id: "",
      },
      selected_lexile_id: "",
      selectedClasscodes: [],
      selectedColorClass: "color1Class",
      isSelectColorDialog: false,
      isShowDialog: false,
      highlightedText: "",
      beforeSelectedText: "",
      afterSelectedText: "",
      content_metadata_detail: {},
      isLoading: false,
      is_deleting: false,
      mainText: "",
      content: "",
      annotation: "",
      tab: "tab-1",
      is_delete_message: false,
      selectedTextDefinitions: [],
      selectedTextDefinitionsPartOfSpeech: "",
      // synth: window.speechSynthesis,
      // sInstance: new SpeechSynthesisUtterance(this.userInput),
    };
  },
  props: ["article", "classcodes", "selectedLevel"],
  components: {
    TextHighligher,
    VueTextToSpeech,
  },
  watch: {
    article: "previouslyHighlighted",
    selectedLevel: "previouslyHighlighted",
  },
  mounted() {
    // window.speechSynthesis.getVoices();
    // window.speechSynthesis;
    // this.synth.cancel(this.sInstance);
  },
  methods: {
    checkIfAlreadyHighlighted() {},
    previouslyHighlighted() {
      let content = "";
      if (this.selectedLevel == "MAX") {
        content = this.article.original_content;
      } else {
        let contentDescription = [];
        if (this.article.content_descriptions.length > 0) {
          contentDescription = this.article.content_descriptions.find(
            (cd) => cd.level == this.selectedLevel
          );
        }
        if (contentDescription) {
          content = contentDescription.description;
          this.selected_lexile_id = contentDescription.id;
        }
      }
      this.content = content;
      let contentMetadatas = this.article.content_metadatas;
      if (this.roleName == "STUDENT")
        contentMetadatas = [
          ...new Map(
            this.article.content_metadatas.map((cm) => [
              cm["selected_text"],
              cm,
            ])
          ).values(),
        ];
      contentMetadatas
        .filter(
          (cm) =>
            cm.metadata_type != "DICTIONARY" &&
            cm.selected_level == this.selectedLevel
        )
        .forEach((cm) => {
          var selectedText = cm.selected_text;
          if (selectedText)
            selectedText = selectedText
              .replace(/'/g, "&#39;")
              .replace(/’/g, "&rsquo;")
              .replace(/“/g, "&ldquo;")
              .replace(/”/g, "&rdquo;");
          var beforeSelectedText = cm.before_selected_text;
          if (beforeSelectedText)
            beforeSelectedText = beforeSelectedText
              .replace(/'/g, "&#39;")
              .replace(/’/g, "&rsquo;")
              .replace(/“/g, "&ldquo;")
              .replace(/”/g, "&rdquo;");
          var afterSelectedText = cm.after_selected_text;

          if (afterSelectedText)
            afterSelectedText = afterSelectedText
              .replace(/'/g, "&#39;")
              .replace(/’/g, "&rsquo;")
              .replace(/“/g, "&ldquo;")
              .replace(/”/g, "&rdquo;");
          // console.log(selectedText);
          // console.log(beforeSelectedText);
          // console.log(afterSelectedText + "a");
          // console.log(content);

          if (cm.user_id == this.user.id) {
            var searchContent = `${beforeSelectedText ?? ""}${selectedText}${
              afterSelectedText ?? ""
            }`;
            // console.log(content);
            content = content.replace(`${searchContent}`, (match) => {
              // console.log("Connected");
              return `${cm.before_selected_text ?? ""}
              <a id="${cm.selected_text}" class="not-selectable tooltip ${
                cm.color_class
              }" @click="deleteContentMetadata">
                  ${
                    cm.metadata_type == "ANNOTATION"
                      ? "<span class='mdi mdi-comment'></span>"
                      : ""
                  }${cm.selected_text} 
                <span class="${cm.annotation ? "tooltiptext" : ""}">
                  ${cm.annotation || ""}
                </span>
              </a>
              <button type="button" data-type="${cm.metadata_type}" data-id="${
                cm.id
              }" class="my-button v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--x-small primary"><i data-v-164e849b="" aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--dark" style="font-size:15"></i></button>
              ${cm.after_selected_text ?? ""}`;
            });
          } else {
            let contentMetadataClasscodes = cm.content_metadata_classcodes.map(
              (cmc) => cmc.classcode_id
            );
            let studentClasscodes = this.user.user_classcodes.map(
              (uc) => uc.classcode_id
            );
            let check = 0;
            studentClasscodes.forEach((sc) => {
              if (contentMetadataClasscodes.includes(sc)) check = 1;
            });
            if (check == 1) {
              content = content.replace(`${cm.selected_text}`, (match) => {
                return `
            <a id="${cm.selected_text}" class="tooltip ${cm.color_class}">
              ${
                cm.metadata_type == "ANNOTATION"
                  ? "<span class='mdi mdi-comment-account'></span>"
                  : ""
              }${match}
              <span class="${cm.annotation ? "tooltiptext" : ""}">
                ${cm.annotation || ""}
              </span>
            </a>`;
              });
            }
          }
        });
      this.mainText = content;
      this.$nextTick(() => {
        this.attachClickEvent();
      });
    },
    attachClickEvent() {
      const buttons = document.querySelectorAll(".my-button");
      console.log(buttons);
      if (buttons) {
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const dataId = button.getAttribute("data-id");
            const dataType = button.getAttribute("data-type");
            this.show_delete_warning_message(dataId, dataType);
            console.log(dataId);
            // Add your logic for the button click event here
          });
        });
      }
    },
    trimHtml(value) {
      const div = document.createElement("div");
      div.innerHTML = value;
      const text = div.textContent || div.innerText || "";
      return text;
    },
    show_delete_warning_message(id, type) {
      this.is_delete_message = true;
      this.content_metadata_detail = this.article.content_metadatas.find(
        (cm) => cm.id == id
      );
    },
    addHighlighted(data) {
      this.selectedTextDefinitions = [];
      this.highlightedText = data.selected;
      this.beforeSelectedText = data.beforeSelectedText;
      this.afterSelectedText = data.afterSelectedText;
      if (data.selected.length) this.isSelectColorDialog = true;
    },
    async getTextDefinition() {
      // Merriam-Webster Dictionary
      let response = await axios.get(
        `/dictionary_results?highlightedText=${this.highlightedText}`
      );
      var isDefinitionFound = false;
      if (response.data.length > 0) {
        this.selectedTextDefinitionsPartOfSpeech = response.data[0].fl;
        this.selectedTextDefinitions = [];
        response.data[0].shortdef.forEach((def) =>
          this.selectedTextDefinitions.push({
            definition: def,
          })
        );
        isDefinitionFound = true;
      }
      if (!isDefinitionFound) {
        this.selectedTextDefinitions = [
          {
            definition: "No Meaning Found.",
          },
        ];
      }
      // this.selectedTextDefinitions = response.data.definitions;
      if (this.selectedTextDefinitions != null) {
        this.selectedTextJson = {
          company_id: this.company.id,
          content_id: this.article.id,
          content_description_id: this.selected_lexile_id,
          metadata_type: "DICTIONARY",
          selected_text: this.highlightedText,
          annotation: "",
          user_id: this.user.id,
          content_metadata_classcodes: [],
          meaning: this.selectedTextDefinitions
            .map((sTD) => sTD.definition)
            .join(", "),
          selected_level: this.selectedLevel,
        };
        this.selectedClasscodes.forEach((classcode_id) => {
          let content_metadata_classcodes = {
            classcode_id: classcode_id,
          };
          this.selectedTextJson.content_metadata_classcodes.push(
            content_metadata_classcodes
          );
        });
        await axios.post("content_metadatas", this.selectedTextJson);
      }
    },
    async saveSelectColorDialog(metadataType) {
      this.selectedTextJson = {
        company_id: this.company.id,
        content_id: this.article.id,
        content_description_id: this.selected_lexile_id,
        metadata_type: this.annotation != "" ? metadataType : "HIGHLIGHT",
        color_class: this.selectedColorClass,
        selected_text: this.highlightedText,
        before_selected_text: this.beforeSelectedText,
        after_selected_text: this.afterSelectedText,
        annotation: this.annotation,
        user_id: this.user.id,
        content_metadata_classcodes: [],
        selected_level: this.selectedLevel,
      };
      this.selectedClasscodes.forEach((classcode_id) => {
        let content_metadata_classcodes = {
          classcode_id: classcode_id,
        };
        this.selectedTextJson.content_metadata_classcodes.push(
          content_metadata_classcodes
        );
      });
      await axios.post("content_metadatas", this.selectedTextJson);
      this.closeSelectColorDialog();
      // this.synth.cancel(this.sInstance);
      this.$emit("onSave");
      this.annotation = "";
    },
    closeSelectColorDialog() {
      this.isSelectColorDialog = false;
      // this.synth.cancel(this.sInstance);
    },
    closeShowDialog() {
      this.isShowDialog = false;
      // this.synth.cancel(this.sInstance);
    },
    deleteAnnotation() {
      alert(1);
    },
    async deleteContentMetadata(id) {
      this.is_deleting = true;
      let is_deleted = await axios.delete(`/content_metadatas/${id}`);
      if (is_deleted) {
        this.is_deleted_content_metadata = true;
        this.is_deleting = false;
        this.is_delete_message = false;
        this.$emit("onSave");
      }
    },
  },
};
</script>

<style>
.no-select {
  user-select: none;
}
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #fcefef;
  color: black;
  text-align: justify;
  padding: 10px;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  border: 1px solid black;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
