<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Epaper Library'"
          ></span>
        </v-col>
      </v-row>
      <br />
      <loader v-if="isLoading"></loader>
      <v-tabs fixed-tabs background-color="primary" dark v-model="tab">
        <v-tab href="#tab-1"> Saved ET Paper </v-tab>
        <v-tab href="#tab-2"> Saved TOI Paper </v-tab>
        <v-tab href="#tab-3"> My Epaper Collections </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
          <v-card flat>
            <v-card-text>
              <template>
                <v-col sm="12" v-if="i == 1">
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left sr_no_class">Sr No</th>
                          <th class="text-left" style="min-width: 82px">
                            Category
                          </th>
                          <th class="text-left" style="min-width: 75px">
                            Edition Name
                          </th>
                          <th class="text-left created_on_date">Story Date</th>
                          <th class="text-left" style="min-width: 82px">
                            Headline
                          </th>
                          <th class="text-left" style="min-width: 75px">
                            Word Count
                          </th>
                          <th class="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(etArticle, at) in etArticles"
                          :key="`etArticle.name${at}`"
                        >
                          <td>{{ at + 1 }}</td>
                          <td style="min-width: 150px">
                            {{ etArticle.category }}
                          </td>
                          <td style="min-width: 110px">
                            {{ etArticle.edition_name }}
                          </td>
                          <td>{{ etArticle.story_date | MMddyyyy }}</td>
                          <td style="min-width: 300px">
                            {{ etArticle.headline }}
                          </td>
                          <td>{{ etArticle.word_count }}</td>
                          <td style="min-width: 130px" class="text-right">
                            <v-btn
                              target="_blank"
                              class="mx-2"
                              x-small
                              color="primary"
                              :to="`/epaper/et/${etArticle.id}`"
                            >
                              View
                            </v-btn>
                            <epaper-bookmark-dialog
                              :epaper="etArticle"
                              :epaper_type="'ET'"
                            ></epaper-bookmark-dialog>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col sm="12" v-if="i == 2">
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left sr_no_class">Sr No</th>
                          <th class="text-left" style="min-width: 82px">
                            Category
                          </th>
                          <th class="text-left" style="min-width: 75px">
                            Edition Name
                          </th>
                          <th class="text-left created_on_date">Story Date</th>
                          <th class="text-left" style="min-width: 82px">
                            Headline
                          </th>
                          <th class="text-left" style="min-width: 75px">
                            Word Count
                          </th>
                          <th class="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(toArticle, at) in toiArticles"
                          :key="`toArticle.name${at}`"
                        >
                          <td>{{ at + 1 }}</td>
                          <td style="min-width: 150px">
                            {{ toArticle.category }}
                          </td>
                          <td style="min-width: 110px">
                            {{ toArticle.edition_name }}
                          </td>
                          <td>{{ toArticle.story_date | MMddyyyy }}</td>
                          <td style="min-width: 300px">
                            {{ toArticle.headline }}
                          </td>
                          <td>{{ toArticle.word_count }}</td>
                          <td style="min-width: 130px" class="text-right">
                            <v-btn
                              target="_blank"
                              class="mx-2"
                              x-small
                              color="primary"
                              :to="`/epaper/toi/${toArticle.id}`"
                            >
                              View
                            </v-btn>
                            <epaper-bookmark-dialog
                              :epaper="toArticle"
                              :epaper_type="'TOI'"
                            ></epaper-bookmark-dialog>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col sm="12" v-if="i == 3">
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Sr No</th>
                          <th class="text-left">Collection Name</th>
                          <th class="text-left">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(collection, at) in collections"
                          :key="`collection.name${at}`"
                        >
                          <td>{{ at + 1 }}</td>
                          <td>{{ collection.collection_name }}</td>
                          <td>
                            <v-btn
                              class="mx-2"
                              x-small
                              color="primary"
                              :to="`/collection-epapers/${collection.id}`"
                            >
                              View
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import EpaperBookmarkDialog from "@/components/EpaperBookmarkDialog.vue";
import Loader from "@/components/loader.vue";

export default {
  name: "AssignmentsPage",
  data() {
    return {
      // form: {},
      isLoading: false,
      tab: "tab-1",
      bookmarks: [],
      collections: [],
      etArticles: [],
      toiArticles: [],
    };
  },
  components: {
    EpaperBookmarkDialog,
    Loader,
  },
  mounted() {
    this.getData();
    this.getMaster();
  },
  methods: {
    async getMaster() {
      // Classcodes
      this.user.user_classcodes.forEach((userClasscode) => {
        this.classcodeItems.push({
          id: userClasscode.classcode.id,
          text: userClasscode.classcode.classcode,
          value: userClasscode.classcode.id,
        });
      });
      // console.log(this.classcodes);
    },
    async getData() {
      try {
        this.isLoading = true;
        let bookmarks = await axios.get(
          `epaper_bookmarks?user_id=${this.user.id}`
        );
        this.bookmarks = bookmarks.data.data;

        this.bookmarks.forEach((bookmark) => {
          if (bookmark.et_article_id != "" && bookmark.et_article_id != null) {
            this.etArticles.push(bookmark.et_article);
          }
          if (
            bookmark.toi_article_id != "" &&
            bookmark.toi_article_id != null
          ) {
            this.toiArticles.push(bookmark.toi_article);
          }
        });

        let collections = await axios.get(
          `epaper_collections?user_id=${this.user.id}`
        );
        this.collections = collections.data.data;
        console.log(this.collections);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
  // async myCollections() {
  //   let collections = await axios.get(
  //     `collection_epapers?user_id=${this.user.id}`
  //   );
  //   this.collections = collections.data.data;
  // },
};
</script>
