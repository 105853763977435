<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col sm="2">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Approval Lists'"
          ></span>
          <!-- <addbutton :link="'/contents/create'"></addbutton> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model="search_keyword"
            label="Search ... "
            prepend-inner-icon="mdi-magnify"
            @keydown.enter="getData"
            @change="page = 1"
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-menu
            v-model="date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_filter"
                label="Date Search"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_filter"
              @input="getData"
              @change="date_menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col sm="4">
          <v-autocomplete
            ref="subject"
            v-model="form.active_id"
            :items="activeStatus"
            label="Select Active Status"
            prepend-icon="mdi-toggle-switch"
            @change="getData()"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="8">
          <v-autocomplete
            ref="subject"
            v-model="form.user_id"
            :items="users"
            label="Select User"
            prepend-icon="mdi-account"
            @change="getData()"
          ></v-autocomplete>
        </v-col>
        <v-col md="4">
          <v-pagination v-model="page" :length="paginationLength">
          </v-pagination>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">ID</th>
                    <th class="text-left">Creted By</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Title</th>
                    <!-- <th class="text-left">Description</th> -->
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(content, i) in contents" :key="content.name">
                    <td>{{ i + 1 }}</td>
                    <td>{{ content.id }}</td>
                    <td>{{ content.created_by.name }}</td>
                    <td>{{ content.content_type }}</td>
                    <td>{{ content.content_name }}</td>
                    <!-- <td v-html="content.original_content" width="750"></td> -->

                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/academic-team/contents/${content.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import Loader from "@/components/loader.vue";
export default {
  components: { Loader },
  name: "ContentsPage",
  data() {
    return {
      form: {
        user_id: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      date_filter: "",
      contents: [],
      content_types: [],
      assignments: [],
      isLoading: false,
      date_menu: false,
      users: [],

      activeStatus: [
        { id: "ACTIVE", text: "ACTIVE", value: "ACTIVE" },
        { id: "INACTIVE", text: "INACTIVE", value: "INACTIVE" },
      ],
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.image_url = URL.createObjectURL(file);
    },
    async getMasters() {
      let masters = await axios.get("/contents/masters");
      masters = masters.data;
      masters.users.forEach((user) => {
        this.users.push({
          id: user.id,
          text: user.name,
          value: user.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let contents = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&user_id=" +
        this.form.user_id +
        "&academic_team_approval=academic_team_approval" +
        "&active_id=" +
        this.form.active_id +
        "&date_filter=" +
        this.date_filter;
      contents = await axios.get(`contents?${query}`);
      this.contents = contents.data.data;
      this.content_types = contents.data.content_types;
      this.assignments = contents.data.assignments;
      this.isLoading = false;
    },
  },
};
</script>
