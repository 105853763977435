<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <BackButton></BackButton>
          Edit Location
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-switch
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save">
            Update Location
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import BackButton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";

export default {
  name: "BoardEditPage",
  data() {
    return {
      form: {
        name: "",
        is_active: true,
      },
      isLoading: false,
    };
  },
  components: {
    BackButton,
    Loader,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/boards/${this.$route.params.boardId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.patch(`/boards/${this.$route.params.boardId}`, this.form);
        this.isLoading = false;
        this.$router.push("/boards");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
