<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <v-btn class="mx-2" fab small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add an Admin of School Id: {{ $route.params.schoolId }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="subject"
                v-model="form.role_id"
                :items="roleItems"
                label="Select role"
                prepend-icon="mdi-domain"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.first_name"
                outlined
                label="First Name"
                prepend-inner-icon="mdi-account"
                :error-messages="errors.first_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.last_name"
                outlined
                label="Last Name"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.last_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.phone"
                outlined
                label="Phone"
                prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-switch
                v-model="form.active"
                inset
                :label="`Is Active: ${form.active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save"> Save Admin </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "AdminCreatePage",
  data() {
    return {
      form: {
        name: "",
        role_id: 2,
        active: true,
        is_mail_sent: 0,
      },
      roleItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.form.company_id = this.$route.params.schoolId;
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/users/masters");
      masters = masters.data;
      masters.roles.forEach((role) => {
        if (role.name == "ADMIN")
          this.roleItems.push({
            id: role.id,
            text: role.name,
            value: role.id,
          });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/users", this.form);
        this.isLoading = false;
        this.$router.push("/schools");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
