<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 135px">
          <span class="text-h5 font-weight-bold" v-text="`Browse`"></span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="9">
          <v-autocomplete
            chips
            label="Search By"
            v-model="filterItems"
            :items="filterItems"
            deletable-chips
            multiple
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.item"
                close
                @click:close="removeItem(data.item)"
              >
                {{ data.item }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3">
          <v-card class="rounded-r-xl" color="white">
            <v-container fluid>
              <v-checkbox
                v-model="form.includeArticle"
                hide-details
                @click="getData"
                :label="`Article`"
              ></v-checkbox>
              <v-checkbox
                v-model="form.includeInfographic"
                hide-details
                @click="getData"
                :label="`Infographic`"
              ></v-checkbox>
              <v-checkbox
                v-model="form.includeVideo"
                @click="getData"
                hide-details
                :label="`Video`"
              ></v-checkbox>
              <span v-if="isTypeValidation == false" class="error--text mt-5"
                >Kindly Select Atleast One</span
              >
            </v-container>
            <v-card-title> Search By </v-card-title>
            <v-card-text>
              <template name="Search by Keyword">
                <v-text-field
                  v-model="form.search_keyword"
                  label="Keyword ... "
                  solo
                  dense
                  rounded
                  background-color="primary"
                  dark
                  clearable
                  @click:clear="(form.search_keyword = null), getData()"
                  @keydown.enter="getData"
                  @change="page = 1"
                  hide-details
                >
                  <v-icon slot="append" color="white"> mdi-magnify </v-icon>
                </v-text-field>
                <v-checkbox
                  v-model="form.includeCollection"
                  hide-details
                  @click="getData"
                  :label="`Include Collections`"
                ></v-checkbox>
              </template>
              <v-divider></v-divider>
              <template name="Search by Standard, Subject, Chapter">
                <v-expansion-panels accordion v-model="panel" multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="primary--text h3 font-weight-bold"
                    >
                      <span>
                        STANDARD
                        <hr class="mt-2" />
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-radio-group
                        v-model="form.standard"
                        class="ma-0"
                        @change="
                          getData(), getStandardSubject(), search_chapters()
                        "
                      >
                        <v-radio
                          v-for="(standard, s) in standardItems"
                          :key="`standard${s}`"
                          :label="standard.text"
                          :value="standard.value"
                        ></v-radio>
                      </v-radio-group>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="primary--text h3 font-weight-bold"
                    >
                      <span>
                        SUBJECT
                        <hr class="mt-2" />
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <loader v-if="isfetchingSubject"></loader>
                      <v-radio-group
                        v-else
                        v-model="form.subject"
                        class="ma-0"
                        @change="getData(), search_chapters()"
                      >
                        <v-radio
                          v-for="(subject, s) in subjectItems"
                          :key="`subject${s}`"
                          :label="subject.text"
                          :value="subject.text"
                        ></v-radio>
                      </v-radio-group>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="primary--text h3 font-weight-bold"
                    >
                      <span>
                        CHAPTER
                        <hr class="mt-2" />
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <loader v-if="isfetchingChapter"></loader>
                      <v-radio-group
                        v-else
                        v-model="form.chapter"
                        class="ma-0"
                        @change="getData()"
                      >
                        <v-radio
                          v-for="(chapter, c) in chapterItems"
                          :key="`chapter${c}`"
                          :label="chapter.text"
                          :value="chapter.text"
                        ></v-radio>
                      </v-radio-group>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
              <!-- <v-divider></v-divider>
              <template name="Search by Category">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="primary--text h3 font-weight-bold"
                    >
                      <span>
                        CATEGORY
                        <hr class="mt-2" />
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-radio-group
                        v-model="form.category_id"
                        mandatory
                        class="ma-0"
                      >
                        <v-radio
                          v-for="(category, s) in categoryItems"
                          :key="`category${s}`"
                          :label="category.text"
                          :value="category.value"
                        ></v-radio>
                      </v-radio-group>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template> -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="9" style="height: 745px; overflow-y: auto">
          <loader v-if="isLoading"></loader>
          <template v-else>
            <!-- Grid View -->
            <template v-if="is_list == false">
              <div
                v-for="(type, ct) in content_types"
                :key="`type${ct}`"
                class="mb-5"
              >
                <v-container class="ma-0" v-if="type.values.length">
                  <v-divider v-if="ct != 0"></v-divider>
                  <v-row>
                    <v-col sm="5">
                      <h2
                        class="grey--text text--darken-1"
                        style="min-width: 150px"
                      >
                        {{ type.name }} [{{ type.values.length }}]
                      </h2>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row>
                  <template v-for="(article, a) in type.values">
                    <v-col
                      :key="`article${a}`"
                      sm="3"
                      v-if="article.content_descriptions.length != 0"
                    >
                      <Article
                        :article="article"
                        :type="article.content_type"
                      ></Article>
                    </v-col>
                  </template>
                </v-row>
              </div>
              <template v-if="collections.length">
                <v-container class="ma-0">
                  <v-divider v-if="content_types.length"></v-divider>
                  <v-row>
                    <v-col sm="5">
                      <h2
                        class="grey--text text--darken-1"
                        style="min-width: 150px"
                      >
                        Collections [{{ collections.length }}]
                      </h2>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row>
                  <v-col
                    sm="3"
                    v-for="(collection, a) in collections"
                    :key="`collection${a}`"
                  >
                    <v-card elevation="6" shaped>
                      <router-link
                        class="routerLink"
                        :to="`/library/collections/${collection.id}`"
                      >
                        <v-col>
                          <v-img
                            class="rounded-tl-xl"
                            v-if="collection.featured_image_path"
                            :src="`${
                              mediaUrl + collection.featured_image_path
                            }`"
                            height="180"
                          ></v-img>
                          <v-img
                            v-else
                            :src="require('@/assets/imgs/collection.png')"
                            height="180"
                          ></v-img>
                        </v-col>
                        <!-- src="https://cdn.vuetifyjs.com/images/cards/cooking.png" -->

                        <v-card-title>
                          {{ collection.collection_name }}
                        </v-card-title>
                      </router-link>
                      <v-divider
                        v-if="roleName != 'STUDENT'"
                        class="mx-4"
                      ></v-divider>

                      <v-card-actions v-if="roleName != 'STUDENT'" class="pt-0">
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mx-1"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              fab
                              x-small
                              color="primary"
                              :to="`/assignments/create?collectionId=${collection.id}`"
                            >
                              <v-icon>mdi-clipboard-text</v-icon>
                            </v-btn>
                          </template>
                          <span> Create Assignment </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="mx-1"
                              dark
                              fab
                              x-small
                              color="primary"
                              @click="
                                shareCollection(
                                  collection.id,
                                  (isShared = true)
                                )
                              "
                            >
                              <v-icon> mdi-share-variant-outline </v-icon>
                            </v-btn>
                          </template>
                          <span> Share Collection </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              v-bind="attrs"
                              v-on="on"
                              class="mx-1"
                              x-small
                              color="primary"
                              @click="
                                getCollection(collection.id, (isEdit = true))
                              "
                            >
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span> Edit Collection </span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </template>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <v-tour name="myTour" :steps="steps"></v-tour>
  </v-main>
</template>

<script>
import axios from "axios";
import Article from "@/components/article.vue";
import Loader from "@/components/loader.vue";

export default {
  name: "ArticleSearchPage",
  data() {
    return {
      form: {
        includeArticle: 1,
        includeInfographic: 1,
        includeVideo: 1,
        includeCollection: 0,
        search_keyword: "",
        standard: null,
        subject: null,
        chapter: null,
        category_id: null,
      },
      is_list: false,
      category_wise_limit_4: true,
      articles: [],
      category: [],
      standardItems: [],
      subjectItems: [],
      allsubjectItems: [],
      chapterItems: [],
      categoryItems: [],
      content_types: [],
      categories: [],
      collections: [],
      boardContents: [],
      filterItems: [],
      panel: [0],
      isTypeValidation: false,
      isLoading: false,
      isfetchingSubject: false,
      isfetchingChapter: false,
      // Tour Steps Definition
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          header: {
            title: "Get Started with browsing articles",
          },
          content: `You can search Any article with any keyword`,
          params: {
            placement: "bottom", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false,
          },
        },
        {
          target: '[data-v-step="1"]',
          content: "You can also search by any category",
          params: {
            placement: "bottom", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false,
          },
        },
        {
          target: '[data-v-step="2"]',
          content: "Now click <strong>SEARCH</strong>.",
          params: {
            placement: "bottom", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false,
          },
        },
      ],
    };
  },
  components: {
    Article,
    Loader,
  },
  mounted() {
    // this.$tours["myTour"].start();
    this.getMasters();
    this.form.standard = null;
    this.form.subject = null;
    this.form.chapter = null;
    this.getData();
  },
  methods: {
    // Get All Masters
    async getMasters() {
      let masters = await axios.get("/contents/masters");
      masters = masters.data;
      masters.grades.forEach((standard) => {
        this.standardItems.push({
          id: standard.id,
          text: standard.name,
          value: standard.name,
        });
      });
      masters.subjects.forEach((subject) => {
        this.subjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.name,
        });
        this.allsubjectItems.push({
          id: subject,
          text: subject.name,
          value: subject.name,
        });
      });
    },
    async getData() {
      this.filterItems = [];
      this.collections = [];
      this.form.type_array = [];
      try {
        this.isLoading = true;
        if (this.form.includeArticle) {
          this.form.type_array.push("ARTICLE");
          this.pushFilterItems("Type:ARTICLE");
        }
        if (this.form.includeInfographic) {
          this.form.type_array.push("INFOGRAPHIC");
          this.pushFilterItems("Type:INFOGRAPHIC");
        }
        if (this.form.includeVideo) {
          this.form.type_array.push("VIDEO");
          this.pushFilterItems("Type:VIDEO");
        }
        if (this.form.type_array.length > 0) {
          this.isTypeValidation = true;
          this.form.search_keyword = this.form.search_keyword
            ? this.form.search_keyword
            : "";
          if (this.form.search_keyword) {
            this.pushFilterItems("Keyword:" + this.form.search_keyword);
          }
          this.form.standard = this.form.standard ? this.form.standard : "";
          if (this.form.standard) {
            this.pushFilterItems("Standard:" + this.form.standard);
          }
          this.form.subject = this.form.subject ? this.form.subject : "";
          if (this.form.subject) {
            this.pushFilterItems("Subject:" + this.form.subject);
          }
          this.form.chapter = this.form.chapter ? this.form.chapter : "";
          if (this.form.chapter) {
            this.pushFilterItems("Chapter:" + this.form.chapter);
          }
          this.form.category_id = this.form.category_id
            ? this.form.category_id
            : "";
          let response = [];
          let query =
            "category_id=" +
            this.form.category_id +
            "&type_array=" +
            this.form.type_array +
            "&search_keyword=" +
            this.form.search_keyword +
            "&grade=" +
            this.form.standard +
            "&subject=" +
            this.form.subject +
            "&chapter=" +
            this.form.chapter +
            "&roleName=" +
            this.roleName +
            "&user_id=" +
            this.user.id;
          response = await axios.get(`/search_contents?${query}`);
          this.bookmarMSG = "";
          this.content_types = [];

          let content_types = response.data.content_types;
          content_types.forEach((ct) => {
            if (ct.values.length) {
              this.content_types.push(ct);
            }
          });
        } else {
          this.isTypeValidation = false;
        }
        if (this.form.includeCollection) {
          this.pushFilterItems("Type:COLLECTION");
          let collection_query =
            "all_collection_by_AT_IT=1&search_keyword=" +
            this.form.search_keyword;
          let collections = await axios.get(`/collections?${collection_query}`);
          this.collections = collections.data.data;
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    pushFilterItems(item) {
      let isExisting = this.filterItems.filter((i) => {
        return i === item;
      });
      console.log(isExisting.length);
      if (isExisting.length == 0) {
        this.filterItems.push(item);
      }
    },
    view_category_article(category_id) {
      this.category_wise_limit_4 = false;
      this.form.category_id = category_id;
      console.log(category_id);
      console.log(this.form);
      this.getData();
    },
    async getStandardSubject() {
      this.isfetchingSubject = true;
      this.form.subject = null;
      this.form.chapter = null;
      try {
        this.subjectItems = [];
        let query = "grade=" + this.form.standard;
        let subjects = await axios.get(`/search_standard_subjects?${query}`);
        subjects = subjects.data.data;
        subjects.forEach((subject) => {
          this.subjectItems.push({
            id: subject,
            text: subject,
            value: subject,
          });
        });
        this.isfetchingSubject = false;
        this.search_chapters();
      } catch (error) {
        this.isfetchingSubject = false;
      }
    },
    async search_chapters() {
      this.isfetchingChapter = true;
      try {
        this.chapterItems = [];
        let query =
          "grade=" + this.form.standard + "&subject=" + this.form.subject;
        let chapters = await axios.get(`/search_chapters?${query}`);
        chapters = chapters.data.data;
        chapters.forEach((chapter) => {
          this.chapterItems.push({
            id: chapter,
            text: chapter,
            value: chapter,
          });
        });
        this.isfetchingChapter = false;
        this.form.chapter = null;
      } catch (error) {
        this.isfetchingChapter = false;
      }
    },
    removeItem(item) {
      let item_array = item.split(":");
      let key = item_array[0];
      let value = item_array[1];
      switch (key) {
        case "Type":
          if (value == "ARTICLE") {
            this.form.includeArticle = false;
          }
          if (value == "INFOGRAPHIC") {
            this.form.includeInfographic = false;
          }
          if (value == "VIDEO") {
            this.form.includeVideo = false;
          }
          if (value == "COLLECTION") {
            this.form.includeCollection = false;
          }
          break;
        case "Keyword":
          this.form.search_keyword = "";
          console.log("Keyword:" + this.form.search_keyword);
          break;
        case "Standard":
          this.form.standard = null;
          this.subjectItems = this.allsubjectItems;
          console.log(this.subjectItems);
          console.log("Standard:" + this.form.standard);
          break;
        case "Subject":
          this.form.subject = null;
          // this.search_chapters();
          console.log("Subject:" + this.form.subject);
          break;
        case "Chapter":
          this.form.chapter = null;
          console.log("Chapter:" + this.form.chapter);
          break;

        default:
          break;
      }
      const index = this.filterItems.indexOf(item);
      if (index >= 0) this.filterItems.splice(index, 1);
      console.log(this.filterItems);
      this.getData();
    },
  },
};
</script>
<style scoped>
.v-divider {
  border-width: 1px !important;
  border-color: black !important;
  margin-bottom: 10px;
}
</style>
