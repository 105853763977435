<template>
  <span>
    <v-dialog
      v-model="isDialog"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn outlined rounded small v-bind="attrs" v-on="on" @click="getData">
          <!-- {{ classs.name }} -->
          <classcode-element
            :classcode="classs.name"
            :type="
              roleName == 'STUDENT'
                ? 'SUBJECT'
                : roleName == 'TEACHER'
                ? 'STANDARD_SUBJECT'
                : ''
            "
          ></classcode-element>
        </v-btn>
      </template>
      <v-card elevation="10" id="mydiv1">
        <v-toolbar color="white" class="fixed-bar">
          <v-btn icon @click="isDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <classcode-element
              :classcode="classs.name"
              :type="
                roleName == 'STUDENT'
                  ? 'SUBJECT'
                  : roleName == 'TEACHER'
                  ? 'STANDARD_SUBJECT'
                  : ''
              "
            ></classcode-element>
            &nbsp; &nbsp;
            <MyLoader v-if="isLoading"></MyLoader>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="createPDF2()"> Download Pdf </v-btn>
        </v-toolbar>
        <v-row>
          <v-col>
            <v-card-title style="min-width: 135px"> Averages </v-card-title>
            <v-card-text>
              <v-row>
                <v-col sm="3">
                  <SubCard
                    :text="'Assignments Posted'"
                    :value="classs.values.length"
                  ></SubCard>
                </v-col>
                <v-col sm="3">
                  <SubCard
                    :text="'Assignments Submitted'"
                    :value="classs.values.filter((v) => v.isSubmitted).length"
                  ></SubCard>
                </v-col>
                <v-col sm="3">
                  <SubCard
                    :text="'Average Percentage'"
                    :value="classs.myAveragePercent"
                  ></SubCard>
                </v-col>
                <v-col sm="3">
                  <SubCard
                    :text="'Class Average Percentage'"
                    :value="classs.classcodeAveragePercent"
                  ></SubCard>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card-title style="min-width: 170px">Assignments</v-card-title>
            <v-card-text>
              <v-simple-table fixed-header height="285px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center sr_no_class">Sr No</th>
                      <th class="text-center" style="min-width: 100px">
                        Assignment Title
                      </th>
                      <th class="text-center" style="min-width: 100px">
                        Assignment Type
                      </th>
                      <th class="text-center" style="min-width: 70px">
                        Status
                      </th>
                      <th class="text-center" style="min-width: 105px">
                        Posted On
                      </th>
                      <th class="text-center" style="min-width: 105px">
                        Attempted On
                      </th>
                      <th class="text-center" style="min-width: 105px">
                        Result Declared
                      </th>
                      <th class="text-center" style="min-width: 70px">Score</th>
                      <th class="text-center" style="min-width: 85px">
                        Class Average
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(uAssignment, uA) in classs.values"
                      :key="`assignment${uA}`"
                    >
                      <td class="text-center">{{ uA + 1 }}</td>
                      <td class="text-left" style="min-width: 200px">
                        <router-link
                          v-if="
                            uAssignment.status == 'COMPLETED' &&
                            uAssignment.result_declared
                          "
                          target="_blank"
                          :to="`/assignments/${uAssignment.assignment_id}/check-result/${uAssignment.my_results[0].id}?source=PERFORMANCE_METRIC`"
                        >
                          {{ uAssignment.assignment_title }}
                        </router-link>
                        <template v-else-if="uAssignment.student.id == user.id">
                          <router-link
                            v-if="uAssignment.status == 'IN PROGRESS'"
                            target="_blank"
                            :to="`/assignments?tab=tab-1`"
                          >
                            {{ uAssignment.assignment_title | limit }}
                          </router-link>
                          <router-link
                            v-else-if="uAssignment.status == 'UPCOMING'"
                            target="_blank"
                            :to="`/assignments?tab=tab-4`"
                          >
                            {{ uAssignment.assignment_title | limit }}
                          </router-link>
                          <router-link
                            v-else
                            target="_blank"
                            :to="`/assignments?tab=tab-2`"
                          >
                            {{ uAssignment.assignment_title | limit }}
                          </router-link>
                        </template>
                        <template v-else>
                          {{ uAssignment.assignment_title | limit }}
                        </template>
                      </td>
                      <td class="text-center" style="min-width: 115px">
                        {{ uAssignment.assignment_type }}
                      </td>
                      <td class="text-center" style="min-width: 115px">
                        {{ uAssignment.status }}
                      </td>
                      <td class="text-center" style="min-width: 115px">
                        {{ uAssignment.created_at | ddmmyyyy }}
                      </td>
                      <td class="text-center" style="min-width: 115px">
                        <template v-if="uAssignment.status != 'OVERDUE'">
                          {{ uAssignment.created_at | ddmmyyyy }}
                        </template>
                      </td>
                      <td class="text-center" style="min-width: 115px">
                        <template v-if="uAssignment.result_declared">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" icon>
                                <v-icon color="green"
                                  >mdi-receipt-text-check</v-icon
                                >
                              </v-btn>
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="openCertificate"
                              >
                                <v-icon color="green">mdi-certificate</v-icon>
                              </v-btn>
                            </template>
                            <span>Result has been declared </span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" icon>
                                <v-icon color="red"
                                  >mdi-receipt-text-clock</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Result has not yet declared </span>
                          </v-tooltip>
                        </template>
                      </td>
                      <td class="text-center" style="min-width: 100px">
                        <template v-if="uAssignment.status != 'OVERDUE'">
                          {{ uAssignment.percent | twoDec }} %
                        </template>
                        <template v-else>N/A</template>
                      </td>
                      <td class="text-center" style="min-width: 100px">
                        {{ uAssignment.classAveragePercent | twoDec }} %
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card elevation="6" class="ma-5">
              <v-card-title style="min-width: 170px"
                >Student Vs Class Average Comparision
                <v-switch
                  v-if="chartData.length > 2"
                  class="ml-5"
                  v-model="graphType"
                  inset
                  :label="`Graph Type: ${graphType ? 'Line' : 'Bar'}`"
                ></v-switch>
              </v-card-title>
              <v-card-title>
                <v-row>
                  <v-col md="11">
                    <v-autocomplete
                      ref="scholl"
                      v-model="assignment_ids"
                      :items="assignmentItems"
                      multiple
                      chips
                      clearable
                      @click:clear="chartData = []"
                      maxValue="4"
                      @input="validateSelectedItem"
                      :error-messages="validationErrors"
                      label="Assignment"
                      prepend-icon="mdi-application-edit"
                    ></v-autocomplete>
                  </v-col>
                  <v-col>
                    <v-btn
                      color="primary"
                      :disabled="validationErrors.length ? true : false"
                      @click="PopulateChart"
                    >
                      FILTER
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text style="overflow: auto" v-if="chartData.length">
                <!-- If ChartData lenght is greater than 2 then Show toggle Option  -->
                <template v-if="chartData.length > 2">
                  <template v-if="graphType">
                    <GChart
                      type="LineChart"
                      :data="chartData"
                      :options="columnchartOptions"
                    />
                  </template>
                  <template v-else>
                    <GChart
                      type="ColumnChart"
                      :data="chartData"
                      :options="columnchartOptions"
                    />
                  </template>
                </template>
                <!-- Else Show only ColumnChart -->
                <template v-else>
                  <GChart
                    type="ColumnChart"
                    :data="chartData"
                    :options="columnchartOptions"
                  />
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!--Dialog to open the certificate -->
        <v-dialog v-model="is_open_certificate" max-width="800px">
          <v-card>
            <v-container>
              <v-row align="center">
                <div
                  style="
                    width: 800px;
                    height: 700px;
                    padding: 20px;
                    text-align: center;
                    border: 10px solid #787878;
                  "
                >
                  <div
                    style="
                      width: 750px;
                      height: 650px;
                      padding: 20px;
                      text-align: center;
                      border: 5px solid #787878;
                      align=center;
                    "
                  >
                    <v-img
                      alt="Vuetify Name"
                      class="shrink mt-1"
                      contain
                      min-width="70"
                      :src="require('../../../../assets/logo.png')"
                      width="70"
                    />
                    <span style="font-size: 50px; font-weight: bold"
                      >Certificate of Completion</span
                    >
                    <br /><br />
                    <span style="font-size: 25px"
                      ><i>This is to certify that</i></span
                    >
                    <br /><br />
                    <span style="font-size: 30px"
                      ><b>{{ user.name }}</b></span
                    ><br /><br />
                    <span style="font-size: 25px"
                      ><i>has completed the assignment</i></span
                    >
                    <br /><br />
                    <span style="font-size: 30px">FIFO Quiz 2</span>
                    <br /><br />
                    <span style="font-size: 20px"
                      >with score of <b>76%</b> dated
                    </span>
                    <span style="font-size: 30px">21-03-2024</span>
                    <br />
                    <br />
                    <br />
                    <br />

                    <div align="right">
                      <span style="font-size: 20px text-align: right;">
                        ----------------------------------------
                        <br />
                        Authorized Signatory</span
                      >
                      <br />
                    </div>
                  </div>
                </div>
              </v-row> </v-container
          ></v-card>
        </v-dialog>
        <!-- End Dialog to open the certificate-->
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import axios from "axios";
import html2pdf from "html2pdf.js";
import SubCard from "./sub_card.vue";
export default {
  data() {
    return {
      chartData: [],
      classVsMe: [],
      assignmentItems: [],
      assignment_ids: [],
      graphType: false,
      isDialog: false,
      isLoading: false,
      validationErrors: [],
      assignmentTypesTab: "",
      columnchartOptions: {
        theme: "material",
        // backgroundColor: { fill: "007ACC" },
        chartArea: { left: 100, right: 50 },
        bar: { groupWidth: "75" },
        focusTarget: "category",
        height: 500,
        colors: ["#007ACC", "#FFA500"],
        annotations: {
          textStyle: { fontSize: 10 },
        },
        vAxis: {
          minValue: 0,
          maxValue: 100,
          title: "In Percentage",
          titleTextStyle: { bold: true },
          gridlines: { color: "#333", minSpacing: 75 },
          textStyle: { fontSize: 10 },
        },
        hAxis: {
          slantedText: false,
          maxTextLines: 1,
          showTextEvery: 1,
          title: "Assignments",
          titleTextStyle: { bold: true },
          explorer: { axis: "horizontal" },
          gridlines: { color: "#333", minSpacing: "25%" },
        },
        legend: { position: "top", alignment: "left" },
      },
      is_open_certificate: false,
    };
  },
  components: {
    SubCard,
  },
  props: ["classs", "classcode_id", "student_id"],
  watch: { classs: "getData" },
  mounted() {
    history.pushState(null, null, location.href);
  },
  methods: {
    validateSelectedItem() {
      if (this.assignment_ids && this.assignment_ids.length > 8) {
        this.validationErrors = [
          "Selected value exceeds the maximum allowed value.",
        ];
      } else {
        this.validationErrors = [];
      }
    },
    async getData() {
      this.isLoading = true;
      if (this.classcode_id) {
        this.classs.values = [];
        let response;
        let query =
          "student_id=" + this.classs.id + "&classcode_id=" + this.classcode_id;
        response = await axios.get(`/student_wise_assignments?${query}`);
        response = response.data;
        this.classs.values = response.data;
      }
      if (this.student_id) {
        this.classs.values = [];
        let response;
        let query =
          "student_id=" + this.student_id + "&classcode_id=" + this.classs.id;
        response = await axios.get(`/student_wise_assignments?${query}`);
        response = response.data;
        this.classs.values = response.data;
      }
      this.classs.values.forEach((value) => {
        let assignment = {
          text: value.assignment_title,
          id: value.assignment_id,
          value: value.assignment_id,
        };
        this.assignmentItems.push(assignment);
      });
      this.isLoading = false;
    },
    PopulateChart() {
      this.chartData = [["Assignment", "Score [%]", "Class Average [%]"]];
      this.assignment_ids = this.assignment_ids ? this.assignment_ids : "";
      this.classs.values.forEach((value) => {
        let assignment_id = value.assignment_id;
        let is_applicable = this.assignment_ids.find(
          (item) => item === assignment_id
        );
        if (this.assignment_ids.length) {
          if (is_applicable) {
            let myPercent = parseFloat(
              value.percent != 0 ? value.percent.toFixed(2) : 0
            );
            let classPercent = parseFloat(
              value.classAveragePercent != 0
                ? value.classAveragePercent.toFixed(2)
                : 0
            );
            this.chartData.push([
              value.assignment_title,
              myPercent,
              classPercent,
            ]);
          }
        } else {
          let myPercent = parseFloat(
            value.percent != 0 ? value.percent.toFixed(2) : 0
          );
          let classPercent = parseFloat(
            value.classAveragePercent != 0
              ? value.classAveragePercent.toFixed(2)
              : 0
          );
          this.chartData.push([
            value.assignment_title,
            myPercent,
            classPercent,
          ]);
        }
      });
      if (this.chartData.length > 8) {
        let startIndex = this.chartData.length - 8 - 1;
        this.chartData.splice(1, startIndex);
      }

      this.columnchartOptions.width =
        parseInt(this.chartData.length - 1) > 6
          ? parseInt(this.chartData.length - 1) * 200
          : "100%";
      this.columnchartOptions.bar.groupWidth =
        parseInt(this.chartData.length - 1) > 6 ? "30%" : "65";
    },
    createPDF2() {
      var element = document.getElementById("mydiv1");
      var opt = {
        margin: 25,
        filename: "Student Wise Performace Report-2.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [450, 500],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
    openCertificate() {
      this.is_open_certificate = true;
    },
  },
};
</script>
<style scoped>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  z-index: 2;
}
</style>
