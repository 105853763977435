<template>
  <v-main>
    <v-container>
      <loader v-if="isLoading"></loader>
      <!-- Article Card -->
      <v-card v-if="form.content_id" elevation="6">
        <v-card-title>
          <v-row>
            <v-col>Article Details</v-col>
            <v-spacer></v-spacer>
            <v-col align="right" md="1" v-if="form.content_type == 'ARTICLE'">
              <v-btn icon color="primary" @click="isHelpDialog = true">
                <v-icon size="20">mdi-help</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text> {{ form.content_name }} </v-card-text>
      </v-card>
      <!-- Link Content Card -->
      <v-card v-if="!form.content_id" elevation="6">
        <v-card-title>
          <v-btn small dark color="primary" @click="isLinkArticleDialog = true">
            Link Content
          </v-btn>
          &nbsp;
          <span v-if="errors.content_id" style="color: red"
            >An article must be linked</span
          >
        </v-card-title>
        <v-dialog v-model="isLinkArticleDialog" max-width="1000px" persistent>
          <v-card elevation="2">
            <v-card-title>
              Articles
              <loader v-if="isSearching" size="25" class="ml-3"></loader
            ></v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="search_article_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="searchArticles"
                  ></v-text-field>
                </v-col>
              </v-row>
              <br />
              <p class="font-weight-bold">Articles List</p>
              <br />
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(article, a) in articles"
                  :key="`article${a}`"
                >
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col sm="9">
                        {{ article.content_name }}
                      </v-col>
                      <v-col sm="3">
                        <v-btn
                          small
                          dark
                          color="primary"
                          @click="linkArticle(article)"
                        >
                          Link This Article
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-html="article.easy_content"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-dialog
                v-model="isViewArticleDialog"
                max-width="400px"
                persistent
              >
                <v-card elevation="2" align="center">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Data Saved Successfully
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-btn color="primary" @click.stop="closeViewArticleDialog"
                      >Close</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-dialog>
              <br />
              <v-btn color="primary" @click.stop="closeLinkArticleDialog">
                Close
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
      <br />
      <v-card elevation="6">
        <v-card-title> Create a Personalized Assignment </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="6">
              <v-autocomplete
                solo
                disabled
                ref="classcode_id"
                v-model="form.classcode_id"
                :items="classcodes"
                label="Select classcode"
                clearable
                append-icon="mdi-application-edit"
                :error-messages="errors.classcode_id"
                @change="getStudents()"
              ></v-autocomplete>
            </v-col>
            <v-col sm="3">
              <v-menu
                v-model="form.start_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    disabled
                    v-model="form.start_date"
                    ref="start_date"
                    label="Start Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    :error-messages="errors.start_date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :id="`start_date`"
                  v-model="form.start_date"
                  @change="(form.start_date_menu = false), (form.end_date = '')"
                  :min="nowDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="3">
              <v-menu
                v-model="form.end_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    disabled
                    ref="end_date"
                    v-model="form.end_date"
                    label="End Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    :error-messages="errors.end_date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :id="`end_date`"
                  :disabled="form.start_date == null"
                  v-model="form.end_date"
                  @change="form.end_date_menu = false"
                  :min="form.start_date"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row class="mt-4" v-if="form.variants.length">
        <v-col
          v-for="(variant, cD) in form.variants.filter(
            (description) => description.infakt_assignments.length > 0
          )"
          :key="`article${cD}`"
        >
          <v-card elevation="6" height="440">
            <v-card-title
              class="justify-center primary accent-1 white--text pa-1"
            >
              {{ variant.level }}
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                disabled
                solo
                class="mt-4 mb-2"
                hide-details
                v-model="variant.selected_assignment_id"
                @input="setSelectedVariantAssignment(variant)"
                :items="variant.variant_assignments"
                label="Select Assignment"
                clearable
                append-icon="mdi-clipboard-text"
              >
                <template
                  v-slot:append-outer
                  v-if="variant.selected_assignment_id"
                >
                  <v-slide-x-reverse-transition mode="out-in">
                    <v-icon
                      @click="ShowDetails(variant.selected_assignment_id)"
                    >
                      mdi-eye</v-icon
                    >
                  </v-slide-x-reverse-transition>
                </template></v-autocomplete
              >
              <template v-if="variant.selected_assignment_id">
                <!-- @input="setSelectedVariantAssignment(variant)" -->
                <v-autocomplete
                  class="mt-4 mb-2"
                  v-model="search_keyword"
                  @change="searchStudent(search_keyword, variant)"
                  hide-details
                  :items="variant.selected_assignment.allStudents"
                  label="Search Student"
                  clearable
                  append-icon="mdi-user"
                  item-text="name"
                  item-value="name"
                >
                  <template v-slot:selection="data">
                    {{ data.item.name }}
                  </template></v-autocomplete
                ></template
              >
              <section style="overflow: auto; height: 250px">
                <template v-if="variant.selected_assignment_id">
                  {{ variant.selected_assignment.selected_students }}
                  <v-checkbox
                    v-for="(student, s) in variant.selected_assignment
                      .allStudents"
                    :key="`student_${cD}_${s}`"
                    :label="student.name"
                    :value="
                      variant.selected_assignment.selected_students.some(
                        (itemB) => itemB == student.id
                      )
                        ? true
                        : false
                    "
                    hide-details
                    @change="
                      handleCheckboxChange(student, variant.selected_assignment)
                    "
                    :disabled="isStudentDisabled(student, variant)"
                  >
                  </v-checkbox>
                </template>
                <template v-else>
                  <v-checkbox
                    v-for="(student, sd) in students"
                    :key="`student_${sd}_${cD}`"
                    disabled
                    hide-details
                    :label="student.name"
                  ></v-checkbox>
                </template>
              </section>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3">
          <v-btn
            :loading="isSaving"
            :disabled="isSaving"
            color="primary"
            dark
            x-large
            @click="save()"
          >
            Create Assignment
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!-- Assignment Details Dialog Box -->
    <v-dialog
      v-model="show_details"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card style="background: rgb(252, 239, 239)">
        <v-toolbar color="white">
          <v-btn icon @click="show_details = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Assignment Preview</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid>
          <v-card class="mt-4 mb-4">
            <v-row align="center">
              <div style="flex: 1 1 auto"></div>
              <v-col sm="12">
                <v-card-text>
                  <v-row>
                    <v-col md="8">
                      Title :
                      <h1>
                        {{ assignment_details.assignment_title }}
                      </h1>
                    </v-col>
                    <v-col md="4">
                      Type :
                      <h3>
                        {{ assignment_details.assignment_type }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="assignment_details.assignment_type == 'DOCUMENT'"
                  >
                    <v-col md="4">
                      Uploaded Docs
                      <h3>
                        <v-tooltip right v-if="assignment_details.documentpath">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              class="mt-2"
                              target="_blank"
                              :href="`${mediaUrl}${assignment_details.documentpath}`"
                              >Link of Uploaded Document
                            </v-btn>
                          </template>
                          <span>Link to preview Uploaded Document</span>
                        </v-tooltip>
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row v-if="assignment_details.student_instructions">
                    <v-col md="12">
                      Student Instructions :
                      <h3 v-html="assignment_details.student_instructions"></h3>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="mt-9 mb-4" color="grey lighten-3">
            <v-card-title> Questions </v-card-title>
            <v-card-text>
              <v-card
                v-for="(
                  assignment_question, aQ
                ) in assignment_details.assignment_questions"
                :key="`assignment_question_${aQ}`"
                elevation="6"
                class="pa-5 mb-5"
              >
                <v-row>
                  <v-col md="12">
                    <v-row>
                      <v-col md="1"
                        ><span>
                          <b>Q{{ aQ + 1 }}: </b>
                        </span>
                      </v-col>
                      <v-col md="2">
                        <span>
                          <b> Marks:</b> {{ assignment_question.marks }}</span
                        >
                      </v-col>
                      <v-col>
                        <span>
                          <b>Question Type: </b>
                          {{ assignment_question.question_type }}</span
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span> <b>Question:</b> </span>
                        <span v-html="assignment_question.description"> </span>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        assignment_details.assignment_type == 'OBJECTIVE' ||
                        (assignment_details.assignment_type == 'CUMULATIVE' &&
                          assignment_question.question_type == 'OBJECTIVE')
                      "
                    >
                      <v-col md="6">
                        <b>Option 1:</b> &nbsp;
                        <span>{{ assignment_question.option1 }}</span>
                      </v-col>
                      <v-col md="6">
                        <b>Option 2:</b> &nbsp;
                        <span>{{ assignment_question.option2 }}</span>
                      </v-col>
                      <v-col md="6" v-if="assignment_question.option3">
                        <b>Option 3:</b> &nbsp;
                        <span>{{ assignment_question.option3 }}</span>
                      </v-col>
                      <v-col md="6" v-if="assignment_question.option4">
                        <b>Option 4:</b> &nbsp;
                        <span>{{ assignment_question.option4 }}</span>
                      </v-col>
                      <v-col md="6" v-if="assignment_question.option5">
                        <b>Option 5:</b> &nbsp;
                        <span>{{ assignment_question.option5 }}</span>
                      </v-col>
                      <v-col md="6" v-if="assignment_question.option6">
                        <b>Option 6:</b> &nbsp;
                        <span>{{ assignment_question.option6 }}</span>
                      </v-col>
                    </v-row>
                    <v-row v-if="assignment_question.model_answer">
                      <v-col>
                        <span> <b>Modal Answer: </b> </span>
                        <span v-html="assignment_question.model_answer"> </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Help Dialog Box -->
    <v-dialog v-model="isHelpDialog" max-width="470px">
      <v-card elevation="2" class="rounded-tl-xl rounded-br-xl no-select">
        <v-container>
          <v-card-title>
            <v-icon large color="green darken-2"> mdi-help </v-icon>
            Standard Lexile Measurement Chart
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="success--text text-center">Grade</th>
                      <th class="success--text text-center">Basic</th>
                      <th class="success--text text-center">Proficient</th>
                      <th class="success--text text-center">Advanced</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">6</td>
                      <td class="text-center">500L - 799L</td>
                      <td class="text-center">800L - 1050L</td>
                      <td class="text-center">above 1050L</td>
                    </tr>
                    <tr>
                      <td class="text-center">7</td>
                      <td class="text-center">550L - 849L</td>
                      <td class="text-center">850L - 1100L</td>
                      <td class="text-center">above 1100L</td>
                    </tr>
                    <tr>
                      <td class="text-center">8</td>
                      <td class="text-center">600L - 899L</td>
                      <td class="text-center">900L - 1150L</td>
                      <td class="text-center">above 1150L</td>
                    </tr>
                    <tr>
                      <td class="text-center">9</td>
                      <td class="text-center">650L - 999L</td>
                      <td class="text-center">1000L - 1200L</td>
                      <td class="text-center">above 1200L</td>
                    </tr>
                    <tr>
                      <td class="text-center">10</td>
                      <td class="text-center">700L - 1024L</td>
                      <td class="text-center">1025L - 1250L</td>
                      <td class="text-center">above 1250L</td>
                    </tr>
                    <tr>
                      <td class="text-center">11 & 12</td>
                      <td class="text-center">800L - 1049L</td>
                      <td class="text-center">1050L - 1300L</td>
                      <td class="text-center">above 1300L</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="ma-5"
                @click.stop="isHelpDialog = false"
                >Close</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- /Help Dialog Box -->
    <!-- Remaining Students  Dialog Box -->
    <v-dialog v-model="isRemainingStudentWarning" max-width="460px">
      <v-card elevation="2" class="rounded-tl-xl rounded-br-xl no-select">
        <v-container>
          <v-row align="center">
            <v-col sm="3" justify="center">
              <v-icon size="100" color="red">mdi-alert-circle-outline</v-icon>
            </v-col>
            <v-col sm="9">
              <v-card-title>
                Opps !! It appears there are still some students left to assign.
              </v-card-title>
              <v-card-actions>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    class="ma-5"
                    @click.stop="isRemainingStudentWarning = false"
                    >Close</v-btn
                  >
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- /Help Dialog Box -->
  </v-main>
</template>
<script>
import axios from "axios";
import Loader from "@/components/loader.vue";
export default {
  name: "PersonalizedAssignmentCreatePage",
  data() {
    return {
      nowDate: new Date().toISOString().slice(0, 10),
      form: {
        classcode_id: "",
        start_date: "",
        end_date: "",
        category: "PERSONALIZED ASSIGNMENT",
        content_id: "",
        variants: {},
      },
      personalizedAssignment: {},
      search_keyword: "",
      assignments_library: [],
      assignment_details: {},
      classcodes: [],
      articles: [],
      final: [],
      students: [],
      remainingStudents: [],
      search_article_keyword: "",
      isSaving: false,
      isLoading: false,
      isDataSaved: false,
      show_details: false,
      isLinkArticleDialog: false,
      isSearching: false,
      isViewArticleDialog: false,
      isHelpDialog: false,
      isStudentsLoading: false,
      isRemainingStudentWarning: false,
      selectedStudents: {},
    };
  },
  components: {
    Loader,
  },
  mounted() {
    this.form.article;
    this.getData();
    if (this.roleName == "TEACHER") {
      this.form.company_id = this.company.id;
    }
  },
  methods: {
    async getData() {
      this.isLoading = true;
      // Classcodes
      let classcodes = await axios.get("users/masters");
      classcodes = classcodes.data.classcodes;
      classcodes?.forEach((classcode) => {
        this.classcodes.push({
          id: classcode.id,
          text: classcode.classcode,
          value: classcode.id,
        });
      });
      let assignmentId = this.$route.params.assignmentId;
      // fetch Current Assignment
      let personalizedAssignment = await axios.get(
        `/assignments/${assignmentId}`
      );
      this.personalizedAssignment = personalizedAssignment.data.data;
      // fetch Linked Assignments using the unique string
      let unique_string = encodeURIComponent(
        this.personalizedAssignment.unique_string.split("!")[1]
      );
      let query = "unique_string=" + unique_string;
      let linked_assignments = await axios.get(`/assignments?${query}`);
      this.linked_assignments = linked_assignments.data.data;

      let assignment_classcode =
        this.personalizedAssignment.assignment_classcodes[0];
      this.form.classcode_id = assignment_classcode.classcode_id;
      this.getStudents();

      this.form.start_date = assignment_classcode.start_date;
      this.form.end_date = assignment_classcode.end_date;
      // If Article Id is passed
      this.form.content_id = this.personalizedAssignment.content_id;
      this.form.content_type = this.personalizedAssignment.content.content_type;
      this.form.content_name = this.personalizedAssignment.content.content_name;
      this.form.variants =
        this.personalizedAssignment.content.content_descriptions;
      console.log(this.form.variants);
      this.form.variants.forEach((variant) => {
        variant.variant_assignments = [];
        variant.infakt_assignments.forEach((assignment) => {
          let dummy_assignment = {
            id: assignment.id,
            text: assignment.assignment_title,
            value: assignment.id,
            selected_students: [],
            allStudents: [],
          };
          if (
            linked_assignments.find(
              (assignment) =>
                assignment.copied_from_assignment_id == assignment.id
            )
          ) {
            variant.selected_assignment_id = assignment.id;
            console.log(variant.selected_assignment_id);
            variant.selected_assignment = dummy_assignment;
          }

          variant.variant_assignments.push(dummy_assignment);
        });
      });
      // console.log(this.form);

      this.isLoading = false;
    },
    async save() {
      try {
        this.isSaving = true;
        this.isRemainingStudentWarning = false;
        if (this.remainingStudents.length > 0) {
          this.isRemainingStudentWarning = true;
        } else {
          this.form.created_by_id = this.user.id;
          let assignment = await axios.post(
            `/personalized-assignments`,
            this.form
          );
          this.form = assignment.data.data;
          this.$router.push(`/assignments/new-index`);
          this.isDataSaved = true;
        }
        this.isSaving = false;
      } catch (e) {
        console.log(e);
        let errors = e.response.data.errors;
        this.focusDiv(errors);
        this.isSaving = false;
      }
    },
    async getStudents() {
      this.isLoading = true;
      this.students = [];
      if (this.form.classcode_id) {
        let students = [];
        let query = "role_id=5" + "&classcode_id=" + this.form.classcode_id;
        students = await axios.get(`/users?${query}`);
        let sortedStudents = students.data.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        this.students = sortedStudents;
        this.remainingStudents = sortedStudents;
        this.student_count = students.data.count;
        this.form.variants.forEach((variant) => {
          variant.variant_assignments.forEach((vA) => {
            vA.allStudents = this.students;
          });
        });
      }
      this.isLoading = false;
    },
    async ShowDetails(id) {
      this.assignment_details = {};
      let assignment = await axios.get(`/assignments/${id}`);
      this.assignment_details = assignment.data.data;
      if (this.assignment_details) {
        this.show_details = true;
      }
    },
    setSelectedVariantAssignment(variant) {
      let selected_assignment = variant.variant_assignments.find(
        (vA) => vA.id == variant.selected_assignment_id
      );
      variant.selected_assignment = selected_assignment;
    },
    focusDiv(errors) {
      console.log("Focus");
      Object.keys(errors).forEach((e) => {
        switch (e) {
          case "classcode_id":
            this.$refs.classcode_id.focus();
            break;
          case "start_date":
            this.$refs.start_date.focus();
            break;
          case "end_date":
            this.$refs.end_date.focus();
            break;

          default:
            break;
        }
      });
    },
    isStudentDisabled(student, variant) {
      let isAvailableStudent = this.remainingStudents.find(
        (vSelectedStudent) => vSelectedStudent.id == student.id // Check for already selected student in any other variants
      );
      let isMySelectedStudent = false;
      if (variant.selected_assignment) {
        isMySelectedStudent =
          variant?.selected_assignment.selected_students.some(
            (vSelectedStudent) => vSelectedStudent == student.id // Check for already selected student in any other variants
          );
      }
      console.log(
        "Student " +
          student.id +
          " is present in variant " +
          variant.level +
          " And hasn't performed the Assignment yet " +
          variant.level +
          " = " +
          isMySelectedStudent +
          " || Is in Avaible= " +
          (isAvailableStudent ? "true" : "false")
      );
      if (isAvailableStudent || isMySelectedStudent) {
        return false;
      } else {
        return true;
      }
    },
    handleCheckboxChange(selected_student, selected_assignment) {
      let existing = selected_assignment.selected_students.some(
        (itemB) => itemB === selected_student.id
      );
      if (!existing) {
        // Push the student in Selected Students List
        selected_assignment.selected_students.push(selected_student.id);
        // Remove the student from Remaining Students List
        this.remainingStudents = this.remainingStudents.filter((itemA) => {
          return !selected_assignment.selected_students.some(
            (itemB) => itemB === itemA.id
          );
        });
      } else {
        // Remove the student from Selected Students List
        selected_assignment.selected_students =
          selected_assignment.selected_students.filter((itemA) => {
            return itemA != selected_student.id;
          });
        // Add the Student Again in the Remaining Students List
        this.remainingStudents.push(selected_student);
      }
    },
    searchStudent(value, variant) {
      // let abc = variant.selected_assignment.allStudents;
      if (value) {
        // Search Students from Remaining Students using string
        variant.selected_assignment.allStudents =
          variant.selected_assignment.allStudents.filter((itemA) => {
            return itemA.name == value;
          });
      } else {
        variant.selected_assignment.allStudents = this.students;
      }
    },
    isSelected(selected_student, selected_assignment) {
      console.log(
        selected_assignment.selected_students.some(
          (itemB) => itemB === selected_student.id
        )
      );
      let is_selected = selected_assignment.selected_students.some(
        (itemB) => itemB === selected_student.id
      );
      return is_selected;
      // return selected_assignment.selected_students.some(
      //   (itemB) => itemB === selected_student.id
      // );
    },
  },
};
</script>
<style scoped>
.required label::after {
  content: "*";
}
</style>
