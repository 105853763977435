<template>
  <div>
    <p class="font-weight-bold">Department</p>
    <v-row>
      <v-col sm="12">
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in form.sections.length" :key="i">
            <v-expansion-panel-header
              :color="
                i % 2 == 0 ? 'blue-grey lighten-4' : 'light-blue lighten-5'
              "
            >
              <v-row>
                <v-col sm="6">
                  <v-text-field
                    v-model="form.sections[i].name"
                    label="Name *"
                    @change="checkSectionUnique()"
                    prepend-inner-icon="mdi-alarm-bell"
                    :error-messages="checkSectionUniqueError[i].name"
                  ></v-text-field>
                </v-col>
                <v-col sm="2">
                  <v-btn
                    class="mt-5"
                    small
                    dark
                    color="primary"
                    @click="duplicateSection(form.sections[i])"
                    >Duplicate</v-btn
                  >
                </v-col>
                <v-col sm="2">
                  <v-dialog
                    v-model="isDeleteSectionDialogs[i]"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab x-small dark color="red" class="mt-4">
                        <v-icon dark v-bind="attrs" v-on="on">
                          mdi-close-thick
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-card elevation="10">
                      <v-card-title>
                        <v-icon large color="primary">
                          mdi-information-outline
                        </v-icon>
                        &nbsp; Do you want to remove the department?
                      </v-card-title>
                      <v-card-text>
                        <v-btn
                          color="primary"
                          @click.stop="deleteSection(form.sections[i])"
                        >
                          Yes
                        </v-btn>
                        &nbsp;
                        <v-btn
                          color="red"
                          dark
                          @click.stop="closeSectionDialogs"
                        >
                          No
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Classcodes :form="form" :section="form.sections[i]"></Classcodes>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <br />
    <v-btn class="mx-2" x-small color="primary" @click="addEmptySection">
      <v-icon dark> mdi-plus </v-icon> Add Department
    </v-btn>
    <br /><br />
  </div>
</template>

<script>
import Classcodes from "./classcodes.vue";
export default {
  props: ["form", "checkSectionUniqueError"],
  data() {
    return {
      count: "",
      isDeleteSectionDialogs: [],
    };
  },
  components: {
    Classcodes,
  },
  methods: {
    addEmptySection() {
      let section = {
        name: "",
        classcodes: [],
      };
      this.form.sections.push(section);
      let checkSectionUniqueError = {
        name: "",
      };
      this.checkSectionUniqueError.push(checkSectionUniqueError);
    },
    deleteSection(section) {
      this.form.sections.splice(this.form.sections.indexOf(section), 1);
      this.checkSectionUniqueError.splice(
        this.checkSectionUniqueError.indexOf(section),
        1
      );
      this.closeSectionDialogs();
    },
    closeSectionDialogs() {
      this.isDeleteSectionDialogs = [];
    },
    duplicateSection(section) {
      let newSection = Object.assign({}, section);
      newSection.name = section.name + " copy";
      this.form.sections.push(newSection);
      let checkSectionUniqueError = {
        name: "",
      };
      this.checkSectionUniqueError.push(checkSectionUniqueError);
    },
    checkSectionUnique() {
      let section_names = [];
      this.form.sections.forEach((section, i) => {
        section_names.push(section.name);
        let sections = this.form.sections.filter((s) => {
          return s.name === section.name;
        });
        if (sections.length > 1) {
          section_names.splice(section_names.indexOf(section), 1);
          this.checkSectionUniqueError[i].name = "The name is already taken";
        } else {
          this.checkSectionUniqueError[i].name = "";
        }
      });
      if (this.form.sections.length != section_names.length) {
        this.$emit("changeStatus", true);
      } else {
        this.$emit("changeStatus", false);
      }
    },
  },
};
</script>
