<template>
  <section>
    <v-card>
      <v-card-title> Top Schools </v-card-title>
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left sr_no_class">Sr No</th>
              <th class="text-center" style="min-width: 105px">School Name</th>
              <th class="text-center" style="min-width: 105px">Assignments</th>
              <th class="text-center" style="min-width: 82px">Teachers</th>
              <th class="text-center" style="min-width: 82px">Students</th>
              <th class="text-center" style="min-width: 105px">
                Avg. Submissions
              </th>
              <th class="text-center" style="min-width: 65px">Score</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(school, s) in data.total_schools" :key="`school${s}`">
              <td>{{ s + 1 }}</td>
              <td class="text-center">{{ school.name }}</td>
              <td class="text-center">{{ school.assignment_count }}</td>
              <td class="text-center">{{ school.teacher_count }}</td>
              <td class="text-center">{{ school.student_count }}</td>
              <td class="text-center">
                {{ school.submission_average | twoDec }}
              </td>
              <td class="text-center">{{ school.total_score }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "SATopSchools",
  data() {
    return {
      form: {},
      data: [],
    };
  },
  props: ["month", "year"],
  watch: {
    month: "getData",
    year: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.form.month = this.month ? this.month : "";
      this.form.year = this.year ? this.year : "";
      let response = await axios.get(
        `top_school_based_on_assignments?month=${this.form.month}&year=${this.form.year}`
      );
      let data = response.data.data;
      this.data = data;
      console.log(data);
    },
  },
};
</script>
