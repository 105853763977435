<template>
  <section>
    <!-- Teachers -->
    <v-responsive class="title font-weight-light text-justify">
      <h3 class="font-weight-bold">FOR TEACHERS</h3>
      <br />
    </v-responsive>

    <v-row id="teachers">
      <v-col md="4">
        <v-card>
          <v-img
            height="300"
            :src="require('../../assets/teachers.jpeg')"
          ></v-img>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-responsive
          class="mx-auto title font-weight-light mb-8 text-justify"
          max-width="720"
        >
          <v-slide-x-transition>
            <v-list-item-group color="primary" v-show="teachersTrigger">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="mx-auto title font-weight-light text-justify">
                      Curated Content
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="mx-auto title font-weight-light text-justify">
                      Assignments readily available
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="mx-auto title font-weight-light text-justify">
                      Ease of lesson planning
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="mx-auto title font-weight-light text-justify">
                      Track student progress
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-slide-x-transition>

          <br />

          <v-btn
            color="grey"
            href="https://vuetifyjs.com"
            outlined
            large
            v-show="teachersTrigger"
          >
            <span class="grey--text text--darken-1 font-weight-bold">
              View More
            </span>
          </v-btn>
        </v-responsive>
      </v-col>
      <v-col md="4">
        <v-responsive
          class="mx-auto title font-weight-light mb-8 text-justify"
          max-width="720"
        >
          <v-slide-x-transition>
            <v-list-item-group color="primary" v-show="teachersTrigger">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="mx-auto title font-weight-light text-justify">
                      New material is updated frequently
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="mx-auto title font-weight-light text-justify">
                      Aligned to the new education policy
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="mx-auto title font-weight-light text-justify">
                      Organized accessibility to your students and their data
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-slide-x-transition>
        </v-responsive>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  props: ["teachersTrigger"],
};
</script>