<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Learning Outcome Report'"
          ></span>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="board_id"
            v-model="form.board_id"
            :items="boardItems"
            label="Board"
            clearable
            prepend-icon="mdi-cast-education"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="grade"
            v-model="form.grade"
            :items="gradeItems"
            label="Grade"
            clearable
            prepend-icon="mdi-star"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="subject"
            v-model="form.subject"
            :items="subjectItems"
            label="Subject"
            clearable
            prepend-icon="mdi-application-edit"
          ></v-autocomplete>
        </v-col>
        <v-col class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <!--/ Filters Section -->
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col>
          <v-card elevation="6">
            <v-card-text>
              <v-simple-table fixed-header height="500px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="min-width: 50px">Sr No</th>
                      <th class="text-center" style="min-width: 70px">
                        Article No
                      </th>
                      <th class="text-left" style="min-width: 57px">Title</th>
                      <th class="text-center" style="min-width: 65px">Board</th>
                      <th class="text-center" style="min-width: 75px">
                        Subject
                      </th>
                      <th class="text-center" style="min-width: 65px">Grade</th>
                      <th class="text-left" style="min-width: 82px">
                        Learning Outcome
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <router-link
                      v-for="(learning_outcome, i) in LearningOutcomeReport"
                      :key="`learning_outcome${i}`"
                      class="routerLink"
                      :to="`/articles/${learning_outcome.content.id}`"
                      tag="tr"
                    >
                      <td>{{ i + 1 }}</td>
                      <td class="text-center">
                        {{ learning_outcome.content_id }}
                      </td>
                      <td
                        class="text-left primary--text"
                        style="min-width: 120px"
                      >
                        {{ learning_outcome.content?.content_name }}
                      </td>
                      <td class="text-center" style="min-width: 80px">
                        {{ learning_outcome.board.name }}
                      </td>
                      <td class="text-center" style="min-width: 105px">
                        {{
                          learning_outcome.subjects
                            ?.map((subject) => subject)
                            .join(", ")
                        }}
                      </td>
                      <td class="text-center" style="min-width: 90px">
                        {{
                          learning_outcome.grades
                            ?.map((grade) => grade)
                            .join(", ")
                        }}
                      </td>
                      <td class="text-left" style="min-width: 150px">
                        <div v-html="learning_outcome.learning_outcome"></div>
                      </td>
                    </router-link>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      form: {},
      isLoading: false,
      subjectItems: [],
      gradeItems: [],
      boardItems: [],
      LearningOutcomeReport: [],
    };
  },
  mounted() {
    // this.getData();
    this.getMasters();
  },

  methods: {
    async getMasters() {
      let masters = await axios.get("/contents/masters");
      masters = masters.data;
      masters.subjects.forEach((subject) => {
        this.subjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.name,
        });
      });
      masters.boards.forEach((board) => {
        this.boardItems.push({
          id: board.id,
          text: board.name,
          value: board.id,
          learning_outcome: "",
          grades: [],
          subjects: [],
        });
      });
      masters.grades.forEach((grade) => {
        this.gradeItems.push({
          id: grade.id,
          text: grade.name,
          value: grade.name,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let response;
      this.form.board_id = this.form.board_id ? this.form.board_id : "";
      this.form.grade = this.form.grade ? this.form.grade : "";
      this.form.subject = this.form.subject ? this.form.subject : "";
      let query =
        "board_id=" +
        this.form.board_id +
        "&grade=" +
        this.form.grade +
        "&subject=" +
        this.form.subject;
      response = await axios.get(`/learning_outcome_report?${query}`);
      this.LearningOutcomeReport = response.data.data;
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
