<template>
  <v-main>
    <v-container
      ><!-- Recently Onboarded Standards -->
      <br />
      <v-row justify="start">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            style="min-width: 135px"
            v-text="'Designations'"
          ></span>
          <addbutton :link="'standards/create'"></addbutton>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="standards"
            v-model="form.standard_id"
            :items="standardItems"
            label="Designation"
            prepend-icon="mdi-alarm-bell"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3"> </v-col>
        <v-col sm="3"> </v-col>
        <v-col sm="1" class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
        <v-col sm="2" class="mt-3">
          <v-btn
            class="ml-9 my-auto"
            fab
            x-small
            :color="is_grid == false ? 'primary' : ''"
            @click="is_grid = false"
          >
            <v-icon dark> mdi-grid </v-icon>
          </v-btn>
          |
          <v-btn
            class="my-auto"
            fab
            x-small
            :color="is_grid == true ? 'primary' : ''"
            @click="is_grid = true"
          >
            <v-icon> mdi-format-list-numbered </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <div v-else>
        <div v-if="is_grid == false">
          <v-row>
            <v-col sm="3" v-for="standard in standards" :key="standard.name">
              <v-card elevation="6" shaped>
                <v-col>
                  <v-img
                    height="150"
                    contain
                    :src="require('@/assets/imgs/standard.png')"
                  ></v-img>
                </v-col>
                <!-- <v-img
                  height="150"
                  :src="require('@/assets/imgs/standard_default_photo.jpeg')"
                ></v-img> -->

                <v-card-title>
                  <div class="wrap">Location: {{ standard.name }}</div>
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col md="6">
                      <router-link
                        :to="`teachers?standard_id=${standard.id}`"
                        style="text-decoration: none"
                        class="wrap"
                        >Coordinator: {{ standard.teacher_count }}</router-link
                      >
                    </v-col>
                    <v-col md="6">
                      <router-link
                        :to="`students?standard_id=${standard.id}`"
                        style="text-decoration: none"
                        class="wrap"
                        >TSIs: {{ standard.student_count }}</router-link
                      >
                    </v-col>
                    <v-col md="6" class="wrap">
                      Departments: {{ standard.sections.length }}
                    </v-col>
                    <v-col md="6" class="wrap">
                      Designations: {{ standard.classcodes.length }}
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-text>
                  <v-chip-group
                    active-class="deep-purple accent-4 white--text"
                    column
                  >
                    <!-- <v-chip>{{
                      standard.is_active ? "Active" : "Inactive"
                    }}</v-chip> -->
                    <v-btn
                      class="ml-2 my-auto"
                      small
                      color="primary"
                      :to="`/standards/${standard.id}/sections`"
                      >departments
                    </v-btn>
                    <v-btn
                      class="ml-5 my-auto"
                      fab
                      x-small
                      color="primary"
                      :to="`/standards/${standard.id}`"
                    >
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2 my-auto"
                          fab
                          small
                          dark
                          color="teal"
                          :to="`/standards/create?duplicateFromStandardId=${standard.id}`"
                        >
                          <v-icon dark> mdi-content-duplicate</v-icon>
                        </v-btn>
                      </template>
                      <span>Click here to duplicate the standard</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          dark
                          :color="`${standard.is_active ? 'green' : 'red'}`"
                        >
                          mdi-circle
                        </v-icon>
                      </template>
                      <span>{{
                        standard.is_active ? "Active" : "Inactive"
                      }}</span>
                    </v-tooltip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-row v-else>
          <v-col md="12">
            <v-card elevation="6">
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="min-width: 56px">Sr No</th>
                      <th class="text-center" style="min-width: 85px">
                        Standard Name
                      </th>
                      <th class="text-center" style="min-width: 85px">
                        Teachers
                      </th>
                      <th class="text-center" style="min-width: 85px">
                        Students
                      </th>
                      <th class="text-center" style="min-width: 80px">
                        Sections
                      </th>
                      <th class="text-center" style="min-width: 80px">
                        Subjects
                      </th>
                      <th class="text-center" style="min-width: 80px">
                        is Active
                      </th>
                      <th class="text-right" style="min-width: 70px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(standard, i) in standards" :key="standard.name">
                      <td>{{ i + 1 }}</td>
                      <td class="text-center">{{ standard.name }}</td>
                      <td class="text-center">
                        <router-link
                          :to="`teachers?standard_id=${standard.id}`"
                          style="text-decoration: none"
                          >{{ standard.teacher_count }}</router-link
                        >
                      </td>
                      <td class="text-center">
                        <router-link
                          :to="`students?standard_id=${standard.id}`"
                          style="text-decoration: none"
                        >
                          {{ standard.student_count }}</router-link
                        >
                      </td>
                      <td class="text-center">
                        {{ standard.sections.length }}
                      </td>
                      <td class="text-center">
                        {{ standard.classcodes.length }}
                      </td>
                      <td class="text-center">
                        {{ standard.is_active ? "YES" : "NO" }}
                      </td>
                      <td class="text-right">
                        <v-btn
                          small
                          color="primary"
                          :to="`/standards/${standard.id}/sections`"
                          >sections
                        </v-btn>
                        <v-btn
                          class="mx-2"
                          fab
                          x-small
                          color="primary"
                          :to="`/standards/${standard.id}`"
                        >
                          <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="ml-2 my-auto"
                              fab
                              small
                              dark
                              color="teal"
                              :to="`/standards/create?duplicateFromStandardId=${standard.id}`"
                            >
                              <v-icon dark> mdi-content-duplicate</v-icon>
                            </v-btn>
                          </template>
                          <span>Click here to duplicate the standard</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { addbutton, Loader },
  data() {
    return {
      form: {
        standard_id: "",
        board_id: "",
      },
      standards: [],
      standardItems: [
        {
          id: 0,
          text: "Select Standard",
          value: 0,
        },
      ],
      isLoading: false,
      is_grid: false,
    };
  },
  mounted() {
    this.getData();
    console.log(this.board);
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.form.standard_id = this.form.standard_id
        ? this.form.standard_id
        : "";
      this.form.board_id = this.board ? this.board.value : "";
      let standards = [];
      let query =
        "standard_id=" +
        this.form.standard_id +
        "&board_id=" +
        this.form.board_id;
      standards = await axios.get(`standards?${query}`);
      this.standards = standards.data.data;
      this.standards.forEach((ss) => {
        this.standardItems.push({
          id: ss.id,
          text: ss.name,
          value: ss.id,
        });
      });
      this.count = standards.data.count;
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
