<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Publication Report'"
          ></span>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row v-if="current_scenario == 1">
        <v-col sm="3">
          <v-autocomplete
            ref="board_id"
            v-model="form.publication_name"
            :items="PublicationReport"
            label="Publication"
            clearable
            item-text="publication"
            item-value="publication"
            prepend-icon="mdi-earth"
          >
            <template v-slot:selection="data">
              {{ data.item.publication }}
            </template></v-autocomplete
          >
        </v-col>
        <v-col class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <!--/ Filters Section -->
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <template v-if="current_scenario == 1">
          <v-col>
            <v-card elevation="6">
              <v-card-text>
                <v-simple-table fixed-header height="500px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 50px">Sr No</th>
                        <th class="text-center" style="min-width: 70px">
                          Publication Name
                        </th>
                        <th class="text-center" style="min-width: 57px">
                          Count
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(publication, p) in PublicationReport"
                        :key="`publication${p}`"
                      >
                        <td>{{ p + 1 }}</td>
                        <td class="text-center">
                          {{ publication.publication }}
                        </td>
                        <td
                          class="text-center routerLink primary--text"
                          @click="
                            (form.publication_name = publication.publication),
                              getData()
                          "
                        >
                          {{ publication.count }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="6">
              <v-card-title style="min-width: 215px"
                >Publication Wise Comparison
                <v-spacer></v-spacer>
                <v-card-actions v-if="PublicationWiseData.length">
                  <view-fullscreen
                    :title="`Publication Wise Comparison`"
                    :type="`ColumnChart`"
                    :Data="PublicationWiseData"
                    :Options="publicationColumnChartOptions"
                  ></view-fullscreen> </v-card-actions
              ></v-card-title>
              <v-card-text>
                <v-row v-if="PublicationWiseData.length">
                  <v-col style="overflow: auto">
                    <GChart
                      type="ColumnChart"
                      :data="PublicationWiseData"
                      :options="publicationColumnChartOptions"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        <template v-if="current_scenario == 2">
          <v-col>
            <v-card elevation="6">
              <v-card-title>
                {{ form.publication_name }}
              </v-card-title>
              <v-card-text>
                <v-simple-table fixed-header height="500px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 50px">Sr No</th>
                        <th class="text-center" style="min-width: 70px">
                          Month-Year
                        </th>
                        <th class="text-center" style="min-width: 57px">
                          Count
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(publication, p) in PublicationReport"
                        :key="`publication${p}`"
                      >
                        <td>{{ p + 1 }}</td>
                        <td class="text-center">
                          {{ publication.month | mm }}-
                          {{ publication.year }}
                        </td>
                        <td
                          class="text-center routerLink primary--text"
                          @click="
                            (form.month = publication.month),
                              (form.year = publication.year),
                              getData()
                          "
                        >
                          {{ publication.count }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        <template v-if="current_scenario == 3">
          <v-col>
            <v-card elevation="6">
              <v-card-title>
                {{ form.publication_name }} {{ form.month | mm }} -
                {{ form.year }}
              </v-card-title>
              <v-card-text>
                <v-simple-table fixed-header height="500px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 50px">Sr No</th>
                        <th class="text-center" style="min-width: 70px">
                          Date Published
                        </th>
                        <th class="text-center" style="min-width: 70px">
                          Article Title
                        </th>
                        <th class="text-center" style="min-width: 70px">
                          Article type
                        </th>
                        <th class="text-center" style="min-width: 70px">
                          Written By
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <router-link
                        v-for="(publication, p) in PublicationReport"
                        :key="`publication${p}`"
                        class="routerLink"
                        :to="`/articles/${publication.id}`"
                        tag="tr"
                      >
                        <td>{{ p + 1 }}</td>
                        <td class="text-center">
                          {{ publication.created_at | ddmmyyyy }}
                        </td>
                        <td class="text-center primary--text">
                          {{ publication.content_name }}
                        </td>
                        <td class="text-center">
                          {{ publication.content_type }}
                        </td>
                        <td class="text-center">
                          {{ publication.written_by_name }}
                        </td>
                      </router-link>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      form: {
        publication_name: "",
        month: "",
        year: "",
      },
      current_scenario: "1",
      isLoading: false,
      subjectItems: [],
      gradeItems: [],
      boardItems: [],
      PublicationReport: [],
      PublicationWiseData: [],
      publicationColumnChartOptions: {
        width: "100%",
        height: 430,
        chartArea: { left: 100, right: 50 },
        colors: ["#007ACC"],
        bar: { groupWidth: "45%" },
        annotations: {
          textStyle: {
            fontSize: 30,
          },
        },
        animation: {
          duration: 500,
          easing: "out",
        },
        vAxis: {
          minValue: 0,
          maxValue: 30,
          title: "In Count",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 75 },
          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          slantedTextAngle: 90,
          title: "Publications",
          titleTextStyle: {
            bold: true,
          },
          gridlines: {
            color: "transparent",
          },
        },
        legend: { position: "top", alignment: "left" },
      },
    };
  },
  mounted() {
    // window.history.pushState(null, null, location.href);
    window.addEventListener("popstate", this.handleBackButton);
    this.getData();
  },

  methods: {
    handleBackButton() {
      // Your event handling code here
      // This code will be executed when the user clicks the browser's back button
      console.log(
        "Browser back button clicked from scenario :" + this.current_scenario
      );
      if (this.current_scenario == "3") {
        console.log("Go back to Month Year Wise i.e scenario 2");
        this.form.month = "";
        this.form.year = "";
        this.getData();
      } else if (this.current_scenario == "2") {
        console.log("Go back to Publication Wise i.e scenario 1");
        this.form.publication_name = "";
        this.getData();
      } else {
        console.log("Default case");
      }
    },
    async getData() {
      window.history.pushState(null, null, location.href);
      this.isLoading = true;
      let response;
      this.form.publication_name = this.form.publication_name
        ? this.form.publication_name
        : "";
      this.form.month = this.form.month ? this.form.month : "";
      this.form.year = this.form.year ? this.form.year : "";
      switch (true) {
        case this.form.publication_name == "":
          // Publication Wise
          console.log("Publication Wise");
          this.current_scenario = "1";
          break;
        case this.form.month == "" && this.form.year == "":
          // Month Year Wise
          console.log("Month Wise");
          this.current_scenario = "2";
          break;
        case this.form.publication_name != "" &&
          // Publication Month Year Wise
          this.form.month != "" &&
          this.form.year != "":
          console.log("Publication Month Year Wise");
          this.current_scenario = "3";
          break;
        default:
          console.log("Default case");
          break;
      }
      let query =
        "publication_name=" +
        this.form.publication_name +
        "&scenario=" +
        this.current_scenario +
        "&month=" +
        this.form.month +
        "&year=" +
        this.form.year;
      response = await axios.get(`/publication_report?${query}`);
      this.PublicationReport = response.data.data;
      console.log("current_scenario :" + this.current_scenario);
      if (this.current_scenario == 1) {
        this.PublicationWiseData = [["1", "Count"]];
        this.PublicationReport.forEach((value) => {
          this.PublicationWiseData.push([value.publication, value.count]);
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
