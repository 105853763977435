<template>
  <div class="textToSpeatch mb-9">
    <v-row>
      <!-- <v-container> -->
      <v-card v-if="is_Speaking == false" class="rounded-pill" color="primary">
        <!-- initialling Not Speaking. Start Option -->
        <v-btn
          color="white"
          id="play"
          dark
          class="pa-3"
          right
          icon
          large
          @click="speak()"
        >
          <v-icon>mdi-volume-high </v-icon>
        </v-btn>
      </v-card>
      <!-- :style="`width:${is_Speaking == true ? '400px' : '95px'} `" -->
      <v-expansion-panels
        v-else
        elevation="6"
        dark
        class="rounded-pill"
        color="primary"
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="pa-0 rounded-pill" color="primary">
            <!-- Buttons  -->
            <v-col>
              <section>
                <v-row class="pa-0">
                  <v-col class="my-auto" xl="8" lg="8" md="6" sm="10">
                    <v-progress-linear
                      color="white"
                      buffer-value="0"
                      :value="stream_percentage"
                      :stream="
                        is_Speaking == true && is_Paused == false ? true : false
                      "
                    ></v-progress-linear>
                  </v-col>
                  <!-- Pause / Resume -->
                  <v-col xl="2" lg="1" md="1" sm="1">
                    <v-btn
                      v-if="is_Speaking == true && is_Paused == false"
                      color="white"
                      id="pause"
                      icon
                      x-small
                      class="pa-1"
                      @click="pause()"
                    >
                      <v-icon size="35">mdi-pause </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="is_Paused == true"
                      color="white"
                      id="resume"
                      @click="resume()"
                      icon
                      x-small
                    >
                      <v-icon size="35">mdi-play-pause </v-icon>
                    </v-btn>
                  </v-col>
                  <!-- / Pause / Resume -->
                  <!-- Stop -->
                  <v-col xl="2" lg="1" md="1" sm="1">
                    <v-btn
                      color="white"
                      icon
                      x-small
                      class="ml-5"
                      id="stop"
                      @click="stop()"
                    >
                      <v-icon size="35">mdi-stop </v-icon>
                    </v-btn>
                  </v-col>
                  <!-- / Stop -->
                </v-row>
              </section>
            </v-col>
            <!-- / Buttons -->
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="is_Speaking == true">
            <v-row>
              <!-- Rate -->
              <v-col>
                <div class="label"><b>Rate : </b> &nbsp;&nbsp;{{ rate }}</div>
                <v-slider
                  class="rate"
                  @change="speak()"
                  min="0.5"
                  max="3"
                  value="1"
                  step="0.1"
                  v-model="rate"
                ></v-slider>
              </v-col>
              <!-- / Rate -->
              <!-- Pitch -->
              <v-col>
                <label for="Pitch"> <b>Pitch : </b>{{ pitch }}</label>
                <v-slider
                  class="rate"
                  @change="speak()"
                  min="0"
                  max="3"
                  value="1"
                  step="0.1"
                  v-model="pitch"
                ></v-slider>
              </v-col>
              <!-- / Pitch -->
            </v-row>
            <v-row>
              <!-- Voice List -->
              <v-col elevation="6">
                <v-autocomplete
                  ref="subject"
                  v-model="selected_voice"
                  @input="changeVoice(selected_voice)"
                  :items="voiceItems"
                  chips
                  label="Select Voice"
                  prepend-icon="mdi-account-voice"
                ></v-autocomplete>
              </v-col>
              <!-- / Voice List -->
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- </v-container> -->
    </v-row>
  </div>
</template>

<script>
export default {
  name: "textToSpeatch",
  data() {
    return {
      userInput: "",
      pitch: 1,
      rate: 0.8,
      synth: window.speechSynthesis,
      sInstance: new SpeechSynthesisUtterance(this.userInput),
      voiceItems: [],
      voicelist: [],
      minWidth: "100px",
      selected_voice: "Microsoft David - English (United States)",
      validation: false,
      is_Speaking: false,
      is_Paused: false,
      stream_percentage: 0,
    };
  },
  props: ["description"],
  computed: {
    strippedContent() {
      let regex = /(<([^>]+)>)/gi;
      return this.description.replace(regex, "");
    },
  },
  async mounted() {
    // console.log("Mounted");
    var _this = this;
    _this.is_Speaking = false;
    this.voicelist = await this.synth.getVoices();
    // console.log(this.voicelist);
    let allowed_voices = [
      "Microsoft David - English (United States)",
      "Microsoft Ravi - English (India)",
      "Microsoft Heera - English (India)",
      "Microsoft Mark - English (United States)",
      "Microsoft Zira - English (United States)",
      "Rishi",
      "Eddy (English (US))",
    ];
    // allowed_voices.forEach((av) => {
    //   let voice = this.voicelist.find((vl) => vl.name == av);
    //   // if (voice) {
    //   let v = {
    //     text: voice["name"],
    //     value: voice["name"],
    //   };
    //   this.voiceItems.push(v);
    //   // }
    // });

    this.voicelist.forEach((voice) => {
      let v = {
        text: voice["name"],
        value: voice["name"],
      };
      this.voiceItems.push(v);
    });

    // console.log(this.voiceItems);

    // this.voicelist.forEach((voice) => {
    //   let v = {
    //     text: voice["name"],
    //     value: voice["name"],
    //   };
    //   this.voiceItems.push(v);
    // });

    // window.onpopstate = function (event) {
    //   console.log("Stop")
    //   this.stop();
    // };
  },
  methods: {
    speak() {
      this.userInput = this.description
        .replace(/&nbsp;/g, " ")
        .replace(/&rsquo;/g, "'");
      // console.log(this.userInput);
      // console.log("Speaking is started");
      this.userInput = this.userInput.toString().replace(/(<([^>]+)>)/gi, " ");
      this.userInput = this.userInput.replace(/&#39;/g, "'");
      this.userInput = this.userInput.replace(/&quot;/g, '"');
      this.userInput = this.userInput.replace(/&ndash;/g, ": ");
      let total_words = this.userInput.split(" ").length;
      // console.log(this.userInput);
      let current_word = 0;

      if (this.userInput !== "") {
        var _this = this;
        _this.is_Speaking = true;
        _this.is_Paused = false;
        this.validation = false;
        this.sInstance.text = this.userInput;
        // let sInstance = new SpeechSynthesisUtterance(this.userInput);
        this.sInstance.voice =
          this.voice == undefined ? this.getVoiceByLanguage() : this.voice;

        this.sInstance.onstart = function (event) {
          console.log(`Speech stop after ${event.timeStamp / 180} mins.`);
        };
        this.sInstance.onboundary = function () {
          // On Each Word
          current_word++;
          _this.stream_percentage = (current_word / total_words) * 100;
          console.log(current_word);
        };
        this.sInstance.onpause = function (event) {
          // If Pause
          console.log(event);
          console.log(`Speech paused after ${event.elapsedTime / 180} mins.`);
        };
        this.sInstance.onend = function () {
          // If Ended
          _this.is_Speaking = false;
          _this.is_Paused = false;
          console.log(this.is_Speaking);
          console.log("Speaking is ended");
        };
        this.sInstance.onerror = function (event) {
          // If Error Occured
          console.log(event);
          _this.is_Speaking = false;
        };
        this.sInstance.pitch = this.pitch;
        this.sInstance.rate = this.rate;
        this.synth.speak(this.sInstance);
      } else {
        this.validation = true;
      }
      console.log(this.is_Speaking);
    },
    getVoiceByLanguage() {
      var self = this;
      for (var key in this.voicelist) {
        if (this.voicelist[key].lang == self.lang) {
          return this.voicelist[key];
        }
      }
      return this.voicelist[0];
    },
    changeVoice(selected_voice) {
      this.synth.cancel();
      for (var key in this.voicelist) {
        if (this.voicelist[key].name == selected_voice) {
          console.log(this.voicelist[key]);

          return (this.voice = this.voicelist[key]);
        }
      }
    },
    stop() {
      this.is_Speaking = false;
      this.is_Paused = false;
      this.synth.cancel(this.sInstance);
      console.log("Speaking is Stoped");
    },
    pause() {
      this.is_Paused = true;
      this.synth.pause(this.sInstance);
      console.log("Speaking is Paused");
    },
    resume() {
      this.is_Speaking = true;
      this.is_Paused = false;
      this.synth.resume(this.sInstance);
      console.log("Speaking is resumed");
    },
    switchContentSize() {
      let size = "";
      if (size == "400px") size = "100px";
      else size = "400px";
      this.minWidth = size;
      console.log(this.minWidth);
    },
  },
};
</script>
<style scoped>
.v-expansion-panel--active {
  border-radius: 50px !important;
  background-color: #0023f5 !important;
}
.v-expansion-panel-content {
  border-radius: 50px !important;
  background-color: #0023f5 !important;
}
</style>
