<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Edit Blog
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="6">
              <v-text-field
                v-model="form.title"
                outlined
                label="Title *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.title"
              ></v-text-field>
            </v-col>
            <v-col sm="6">
              <input type="file" id="file" name="file" :ref="`file`" />
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col sm="12">
              <p class="font-weight-bold">Description</p>
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="form.description"
              ></froala>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save"> Update Blog </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { backbutton, Loader },
  name: "BlogEditPage",
  data() {
    return {
      form: {
        title: "",
        description: "",
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/blogs/${this.$route.params.blogId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.patch(`/blogs/${this.$route.params.blogId}`, this.form);
        this.isLoading = false;
        this.$router.push("/blogs");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
