<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 105px">
          <span
            class="text-h5 font-weight-bold"
            v-text="`Lesson Plans of ${course.title}`"
          ></span>
          <addbutton
            v-if="roleName == 'INFAKT TEACHER'"
            :link="`/courses/${course.id}/lps/create`"
          ></addbutton>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 56px">Sr No</th>
                    <th
                      class="text-left"
                      style="min-width: 80px"
                      v-if="roleName == 'ACADEMIC TEAM'"
                    >
                      Created By
                    </th>
                    <th class="text-left" style="min-width: 100px">Title</th>
                    <th class="text-left" style="min-width: 80px">
                      Description
                    </th>
                    <th class="text-left" style="min-width: 80px">
                      No Of Days
                    </th>
                    <th class="text-left" style="min-width: 80px">Status</th>
                    <th class="text-left" style="min-width: 150px">Remark</th>
                    <th class="text-right" style="min-width: 80px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(lp, i) in lps" :key="`lp_${i}`">
                    <td>{{ i + 1 }}</td>
                    <td
                      v-if="roleName == 'ACADEMIC TEAM'"
                      class="pl-0 pr-2"
                      style="min-width: 30px"
                    >
                      {{ lp.created_by ? lp.created_by.name : "" }}
                    </td>
                    <td>{{ lp.title }}</td>
                    <td v-html="lp.description"></td>
                    <td>{{ lp.no_of_days }}</td>
                    <td class="text-left" style="min-width: 30px">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="content.is_draft == true"
                            color="blue"
                            id="Is Draft"
                          >
                            mdi-file
                          </v-icon>
                        </template>
                        <span>Is Draft</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="lp.status == false"
                            id="Pending for Approval"
                            color="yellow"
                          >
                            mdi-progress-clock
                          </v-icon>
                        </template>
                        <span>Pending for Approval</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="lp.status == true"
                            id="Approved"
                            color="green"
                          >
                            mdi-check-decagram
                          </v-icon>
                        </template>
                        <span>Approved</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                            id="Rejected"
                            v-if="lp.status == 2"
                          >
                            mdi-close-octagon
                          </v-icon>
                        </template>
                        <span>Rejected</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="lp.status == 3"
                            color="blue"
                            id="Is Draft"
                          >
                            mdi-file > mdi-close-octagon
                          </v-icon>
                        </template>
                        <span>Draft</span>
                      </v-tooltip>
                    </td>
                    <td>
                      <span v-if="lp.status == 2" v-html="lp.remark"></span>
                    </td>
                    <td class="text-right">
                      <v-btn
                        v-if="
                          roleName == `ACADEMIC TEAM` || //If role is Academic team then they can only edit pending for Approval Lesson Plan
                          lp.status == 2 || //Rest can only edit rejected or Drafted Lesson Plan
                          lp.status == 3
                        "
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/courses/${course.id}/lps/${lp.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "LessonPlansPage",
  data() {
    return {
      lps: [],
      course: {},
      isLoading: false,
    };
  },
  components: {
    addbutton,
    Loader,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let course = await axios.get(`/courses/${this.$route.params.courseId}`);
      this.course = course.data.data;
      let query = "course_id=" + this.course.id;
      let lps = await axios.get(`lps?${query}`);
      this.lps = lps.data.data;
      this.isLoading = false;
    },
  },
};
</script>
