<template>
  <v-main v-if="!isauthenticated">Not Authenticated</v-main>
  <v-main class="white" v-else>
    <!-- <v-main class="white"> -->
    <v-row
      justify="center"
      :class="{
        'py-16': $vuetify.breakpoint.mdAndUp,
        'py-5': $vuetify.breakpoint.smAndDown,
      }"
    >
      <!-- <v-row justify="center" class="py-16"> -->
      <v-col md="8" align="center" class="mt-4">
        <span
          :class="{
            'text-h2 font-weight-bold myFont': $vuetify.breakpoint.mdAndUp,
            'display-1 font-weight-bold myFont': $vuetify.breakpoint.smAndDown,
          }"
        >
          Contact us
        </span>
        <!-- <span class="text-h2 font-weight-bold myFont"> Contact us </span> -->
        <br /><br />
        <span
          :class="{
            'text-h5 font-weight-bold myFont': $vuetify.breakpoint.mdAndUp,
            'text-h6 font-weight-bold myFont': $vuetify.breakpoint.smAndDown,
          }"
          >Please fill out the form below for any queries and
          opportunities</span
        >
        <!-- <span class="text-h5 font-weight-bold myFont"
          >Please fill out the form below for any queries and
          opportunities</span
        > -->
      </v-col>
    </v-row>
    <div>
      <v-row justify="center">
        <v-col
          md="10"
          :class="{
            'px-6 ml-2': $vuetify.breakpoint.smAndDown,
          }"
        >
          <!-- <v-col md="10"> -->
          <!-- <v-container> -->
          <v-card
            :class="{
              'rounded-xl px-14': $vuetify.breakpoint.mdAndUp,
              'rounded-xl px-1': $vuetify.breakpoint.smAndDown,
            }"
            :style="'border: 1px solid blue'"
          >
            <!-- <v-card class="rounded-xl px-14" :style="'border: 1px solid blue'"> -->
            <v-card-title
              :class="{
                'text-h2 font-weight-bold pt-14 myFont':
                  $vuetify.breakpoint.mdAndUp,
                'text-h5 font-weight-bold pt-6 myFont':
                  $vuetify.breakpoint.smAndDown,
              }"
            >
              <!-- <v-card-title class="text-h2 font-weight-bold pt-14 myFont"> -->
              <span>How can we help you?</span>
            </v-card-title>

            <v-row v-if="$vuetify.breakpoint.mdAndDown">
              <v-col>
                <!-- <v-row> -->
                <v-col md="4" style="margin-bottom: -30px">
                  <v-text-field
                    v-model="form.name"
                    outlined
                    label="Enter Your Name"
                    :error-messages="errors.name"
                  ></v-text-field>
                </v-col>
                <v-col md="4" style="margin-bottom: -30px">
                  <v-text-field
                    outlined
                    v-model="form.email"
                    label="Enter Your Email"
                    :error-messages="errors.email"
                  ></v-text-field>
                </v-col>
                <v-col md="4" style="margin-bottom: -30px">
                  <v-text-field
                    outlined
                    v-model="form.phone_no"
                    label="Enter Your Phone No."
                    :error-messages="errors.phone_no"
                  ></v-text-field>
                </v-col>
                <!-- </v-row> -->
                <!-- <v-row> -->
                <v-col md="4" style="margin-bottom: -30px">
                  <v-text-field
                    v-model="form.school_name"
                    outlined
                    label="Enter Your School Name"
                    :error-messages="errors.school_name"
                  ></v-text-field>
                </v-col>
                <v-col md="4" style="margin-bottom: -30px">
                  <v-autocomplete
                    ref="role"
                    outlined
                    v-model="form.role"
                    :items="roleItems"
                    label="Role"
                    :error-messages="errors.role"
                  ></v-autocomplete>
                </v-col>
                <!-- </v-row> -->
                <!-- <v-row> -->
                <v-col md="4" style="margin-bottom: -30px">
                  <v-text-field
                    outlined
                    v-model="form.city"
                    label="Enter Your City"
                    :error-messages="errors.city"
                  ></v-text-field>
                </v-col>
                <v-col md="4" style="margin-bottom: -30px">
                  <v-text-field
                    outlined
                    v-model="form.state"
                    label="Enter Your State"
                    :error-messages="errors.state"
                  ></v-text-field>
                </v-col>
                <v-col md="4" style="margin-bottom: -30px">
                  <v-text-field
                    outlined
                    v-model="form.pincode"
                    label="Enter Your Pincode"
                    :error-messages="errors.pincode"
                  ></v-text-field>
                </v-col>
                <!-- </v-row> -->
                <!-- <v-row> -->
                <v-col style="margin-bottom: -30px" align="center">
                  <v-textarea
                    v-model="form.description"
                    outlined
                    label="Additional Details"
                  ></v-textarea>
                  <v-btn
                    color="primary"
                    dark
                    :loading="isSaving"
                    :disabled="isSaving"
                    @click="save"
                  >
                    Send
                  </v-btn>
                  <br />
                  <br />
                </v-col>
              </v-col>
              <!-- </v-row> -->
            </v-row>
            <v-row
              v-if="$vuetify.breakpoint.mdAndDown"
              style="margin-top: -30px"
            >
              <v-col md="5">
                <!-- <v-col> -->
                <v-card-title
                  class="font-weight-bold"
                  style="margin-bottom: -15px"
                >
                  <v-icon size="25" color="primary" class="mr-2">
                    mdi-map-marker
                  </v-icon>
                  <span class="text-h6 myFont font-weight-bold">Address</span>
                </v-card-title>
                <p class="ml-12 myFont body-1">
                  WARDEN EDUCATION TECHNOLOGIES PVT. LTD.
                </p>
                <p class="ml-12 myFont body-1">
                  Warden House, 340 J. J. Road, Byculla,
                </p>
                <p class="ml-12 myFont body-1">Mumbai 400008, India.</p>
                <!-- </v-col>
                <v-col> -->
                <v-card-title
                  class="font-weight-bold"
                  style="margin-bottom: -15px"
                >
                  <v-icon size="25" color="primary" class="mr-2">
                    mdi-phone
                  </v-icon>
                  <span class="text-h6 myFont font-weight-bold">Phone</span>
                </v-card-title>
                <p class="ml-12">+91 - 93249 61199</p>
                <!-- </v-col> -->

                <!-- <v-col> -->
                <v-card-title
                  class="font-weight-bold"
                  style="margin-bottom: -15px"
                >
                  <v-icon size="25" color="primary" class="mr-2">
                    mdi-email
                  </v-icon>
                  <span class="text-h6 myFont font-weight-bold">Email</span>
                </v-card-title>
                <p class="ml-12">onboardme@infakt.co.in</p>
                <!-- </v-col> -->
              </v-col>
            </v-row>
            <v-row v-else class="pt-10">
              <v-col md="5">
                <v-col>
                  <v-card-title class="font-weight-bold">
                    <v-icon size="25" color="primary" class="mr-2">
                      mdi-map-marker
                    </v-icon>
                    <span class="text-h5 myFont font-weight-bold">Address</span>
                  </v-card-title>
                  <p class="ml-10 myFont">
                    WARDEN EDUCATION TECHNOLOGIES PVT. LTD.
                  </p>
                  <p class="ml-10 myFont">
                    Warden House, 340 J. J. Road, Byculla,
                  </p>
                  <p class="ml-10 myFont">Mumbai 400008, India.</p>
                </v-col>
                <v-col>
                  <v-card-title class="font-weight-bold">
                    <v-icon size="25" color="primary" class="mr-2">
                      mdi-phone
                    </v-icon>
                    <span class="text-h5 myFont font-weight-bold">Phone</span>
                  </v-card-title>
                  <p class="ml-10">+91 - 93249 61199</p>
                </v-col>

                <v-col>
                  <v-card-title class="font-weight-bold">
                    <v-icon size="25" color="primary" class="mr-2">
                      mdi-email
                    </v-icon>
                    <span class="text-h5 myFont font-weight-bold">Email</span>
                  </v-card-title>
                  <p class="ml-10">onboardme@infakt.co.in</p>
                </v-col>
              </v-col>
              <v-col md="7" style="padding-right: 125px">
                <v-row>
                  <v-col md="4">
                    <v-text-field
                      v-model="form.name"
                      outlined
                      label="Enter Your Name"
                      :error-messages="errors.name"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      outlined
                      v-model="form.email"
                      label="Enter Your Email"
                      :error-messages="errors.email"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      outlined
                      v-model="form.phone_no"
                      label="Enter Your Phone No."
                      :error-messages="errors.phone_no"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="8">
                    <v-text-field
                      v-model="form.school_name"
                      outlined
                      label="Enter Your School Name"
                      :error-messages="errors.school_name"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4">
                    <v-autocomplete
                      ref="role"
                      outlined
                      v-model="form.role"
                      :items="roleItems"
                      label="Role"
                      :error-messages="errors.role"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="4">
                    <v-text-field
                      outlined
                      v-model="form.city"
                      label="Enter Your City"
                      :error-messages="errors.city"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      outlined
                      v-model="form.state"
                      label="Enter Your State"
                      :error-messages="errors.state"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      outlined
                      v-model="form.pincode"
                      label="Enter Your Pincode"
                      :error-messages="errors.pincode"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="form.description"
                      outlined
                      label="Additional Details"
                    ></v-textarea>
                    <v-btn
                      color="primary"
                      dark
                      :loading="isSaving"
                      :disabled="isSaving"
                      @click="save"
                    >
                      Send
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <br />
          </v-card>
        </v-col>
      </v-row>
      <br />
      <!-- Success Dialog Box -->
      <v-dialog v-model="success" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-check-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Thank You!</h1>
                      <br />
                      <h3>We will get back to you as soon as possible.</h3>
                      <br />
                      <v-btn
                        color="success"
                        @click="(success = false), $router.go(0)"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <!-- Footer -->
    <!-- <v-footer dark padless>
      <v-card class="flex" flat style="background-color: #e88774 !important">
        <v-card-title class="teal" style="background-color: blue !important">
          <strong class="subheading"
            >Get connected with us on social networks!</strong
          >

          <v-spacer></v-spacer>

          <v-btn v-for="icon in icons" :key="icon" class="mx-4" dark icon>
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>Infakt</strong>
        </v-card-text>
      </v-card>
    </v-footer> -->
    <br /><br />
    <Foot></Foot>
  </v-main>
</template>
<script>
import axios from "axios";
import Foot from "../views/home/foot.vue";

export default {
  components: {
    Foot,
  },
  data() {
    return {
      isauthenticated: true,
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
      form: {
        name: "",
        email: "",
        phone_no: "",
        school_name: "",
        role: "",
        city: "",
        state: "",
        pincode: "",
        description: "",
        status: "PENDING",
      },
      success: false,
      roleItems: [
        {
          id: 1,
          text: "Admin",
          value: "Admin",
        },
        {
          id: 2,
          text: "Teacher",
          value: "Teacher",
        },
        {
          id: 3,
          text: "Student",
          value: "Student",
        },
        {
          id: 4,
          text: "Parent",
          value: "Parent",
        },
      ],
      isSaving: false,
    };
  },
  mounted() {
    // let password = prompt("Enter passcode");
    // if (password == "Infakt2023") {
    //   this.isauthenticated = true;
    // } else {
    //   alert("Wrong Password");
    // }
  },
  methods: {
    async save() {
      try {
        this.isSaving = true;
        console.log(this.isSaving);
        let contact_request = await axios.post(`/contact_requests`, this.form);
        this.contact_request = contact_request.data.data;
        if (this.contact_request) {
          this.success = true;
        }
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
      }
    },
  },
};
</script>
<style scoped>
.myFont {
  font-family: "SF PRO" !important;
}
</style>
