<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Edit {{ form.content_name }}
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                target="_blank"
                size="25"
                v-bind="attrs"
                v-on="on"
                icon
                color="primary"
                :to="`/articles/${form.id}`"
              >
                <v-icon> mdi-eye-outline </v-icon>
              </v-btn>
            </template>
            <span>View Article Preview</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="subject"
                v-model="form.content_type"
                :items="contentTypes"
                chips
                label="Select Content Type"
                prepend-icon="mdi-domain"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="category"
                v-model="form.categories"
                :items="categoryItems"
                label="Category *"
                multiple
                chips
                prepend-icon="mdi-shape"
                :error-messages="errors.content_categories"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                v-model="form.content_name"
                outlined
                label="Title *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.content_name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.reading_time"
                outlined
                label="Reading time (in mins)"
                prepend-inner-icon="mdi-clock-outline"
                :error-messages="errors.reading_time"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.written_by_name"
                outlined
                label="Written By"
                prepend-inner-icon="mdi-notebook-edit-outline"
                :error-messages="errors.written_by_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.publication"
                outlined
                label="Publication"
                prepend-inner-icon="mdi-typewriter"
                :error-messages="errors.publication"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                v-model="form.keywords"
                outlined
                label="Keywords"
                @keyup="form.keywords = form.keywords.toUpperCase()"
                messages="Please mention the tags in comma seperated value e.g English, Evs, Maths. No Special character allowed other than (,) & (-)"
                prepend-inner-icon="mdi-tag-search"
                :error-messages="errors.keywords"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Info Box Section -->
          <v-expansion-panels class="mb-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                Info Box
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <template>
                  <v-tabs
                    v-model="tab"
                    fixed-tabs
                    class="mt-5"
                    background-color="blue-grey lighten-4"
                    color="primary"
                  >
                    <!-- <v-tab v-for="n in 6" :key="n"> Item {{ n }} </v-tab> -->
                    <v-tab
                      v-for="(board, b) in form.content_info_boards"
                      :key="`board${b}`"
                    >
                      {{ board.text }}
                    </v-tab>
                  </v-tabs>
                </template>
                <v-tabs-items v-model="tab">
                  <!-- Update remarks Tab -->
                  <v-tab-item
                    v-for="(contentInfoBoard, b) in form.content_info_boards"
                    :key="`board${b}`"
                  >
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col sm="6">
                            <v-autocomplete
                              ref="grade"
                              v-model="contentInfoBoard.grades"
                              :items="gradeItems"
                              label="Grade"
                              multiple
                              chips
                              prepend-icon="mdi-star"
                            ></v-autocomplete>
                          </v-col>
                          <v-col sm="6">
                            <v-autocomplete
                              ref="subject"
                              v-model="contentInfoBoard.subjects"
                              :items="subjectItems"
                              label="Subject"
                              prepend-icon="mdi-application-edit"
                              multiple
                              chips
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col sm="12">
                            <p class="font-weight-bold">Learning Outcome</p>
                            <froala
                              :tag="'textarea'"
                              :config="config"
                              v-model="contentInfoBoard.learning_outcome"
                            ></froala>
                          </v-col>
                        </v-row>
                        <!-- Start for Info Board Details -->
                        <div
                          v-for="(
                            content_info_board_detail, cibd
                          ) in contentInfoBoard.content_info_board_details"
                          :key="`content_info_board_detail${cibd}`"
                          class="mt-5"
                        >
                          <v-row>
                            <v-col sm="11">
                              <b>Detail {{ cibd + 1 }}</b>
                            </v-col>
                            <v-col sm="1">
                              <v-dialog
                                v-model="isDeleteDetailDialogs[cibd]"
                                max-width="600px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    fab
                                    class="ml-9"
                                    small
                                    dark
                                    color="red"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon dark> mdi-close-thick </v-icon>
                                  </v-btn>
                                </template>
                                <v-card elevation="10">
                                  <v-card-title>
                                    <v-icon large color="primary">
                                      mdi-information-outline
                                    </v-icon>
                                    &nbsp; Do you want to remove the Detail?
                                  </v-card-title>
                                  <v-card-text>
                                    <v-btn
                                      color="primary"
                                      @click.stop="
                                        deleteDetail(
                                          contentInfoBoard,
                                          content_info_board_detail
                                        )
                                      "
                                    >
                                      Yes
                                    </v-btn>
                                    &nbsp;
                                    <v-btn
                                      color="red"
                                      dark
                                      @click.stop="closeDetailDialogs"
                                    >
                                      No
                                    </v-btn>
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col sm="4">
                              <v-autocomplete
                                ref="grade"
                                v-model="content_info_board_detail.grade"
                                :items="contentInfoBoard.grades"
                                label="Grade"
                                chips
                                prepend-icon="mdi-star"
                              ></v-autocomplete>
                            </v-col>
                            <v-col sm="4">
                              <v-autocomplete
                                ref="subject"
                                v-model="content_info_board_detail.subject"
                                :items="contentInfoBoard.subjects"
                                label="Subject"
                                prepend-icon="mdi-application-edit"
                                chips
                              ></v-autocomplete>
                            </v-col>
                            <v-col sm="4">
                              <v-text-field
                                label="Chapter"
                                v-model="content_info_board_detail.chapter"
                                outlined
                                prepend-inner-icon="mdi-alphabetical-variant"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col sm="12">
                              <p class="font-weight-bold">Learning Outcome</p>
                              <span
                                style="color: red"
                                v-if="
                                  errors[
                                    `content_info_board_detail.${cibd}.learning_outcome`
                                  ]
                                "
                                >This description is required</span
                              >
                              <froala
                                :tag="'textarea'"
                                :config="config"
                                v-model="
                                  content_info_board_detail.learning_outcome
                                "
                              ></froala>
                            </v-col>
                          </v-row>
                          <v-divider class="my-5"></v-divider>
                        </div>
                        <v-row>
                          <v-col sm="12" class="mt-5">
                            <v-btn
                              small
                              color="primary"
                              @click="Add_Empty_Detail(contentInfoBoard)"
                            >
                              Add Detail
                              <v-icon dark> mdi-plus </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <!-- End of Info Board Details -->
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Info Box Section -->
          <!-- Intended For Section -->
          <v-expansion-panels class="my-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                Intended For
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col sm="3">
                    <v-autocomplete
                      ref="board_id"
                      v-model="form.boards"
                      :items="boardItems"
                      label="Location"
                      multiple
                      chips
                      prepend-icon="mdi-cast-education"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="3">
                    <v-autocomplete
                      ref="school_type"
                      v-model="form.for_school_type"
                      :items="schoolTypeItems"
                      label="School Type"
                      chips
                      disabled
                      prepend-icon="mdi-currency-usd-off"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="3">
                    <v-autocomplete
                      ref="specific_to"
                      v-model="form.specific_to"
                      :items="specificItems"
                      label="Specific To"
                      chips
                      disabled
                      prepend-icon="mdi-electron-framework"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="3">
                    <v-autocomplete
                      ref="school_id"
                      v-model="form.schools"
                      :items="schoolItems"
                      label="School"
                      multiple
                      chips
                      disabled
                      prepend-icon="mdi-bus-school"
                      :error-messages="errors.content_schools"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Intended For Section -->
          <!-- Original Content Section -->
          <v-expansion-panels class="my-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                Original Content
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col sm="12" class="mt-5">
                    <p class="font-weight-bold">
                      Original Content &nbsp;
                      <v-dialog v-model="dialog">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            color="red lighten-2"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            Preview
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title
                            class="justify-center primary accent-1 white--text"
                          >
                            Preview
                          </v-card-title>
                          <br />
                          <v-card-text>
                            <div v-html="form.original_content"></div>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </p>
                    <froala
                      :tag="'textarea'"
                      :config="config"
                      v-model="form.original_content"
                    ></froala>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Original Content Section -->
          <!-- Variants Section -->
          <v-expansion-panels class="my-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                Variants
                <b
                  class="mx-10"
                  style="color: red"
                  v-if="
                    errors.content_descriptions ||
                    !form.content_descriptions.length
                  "
                  >Atleast one variant is required</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(content_description, uc) in form.content_descriptions"
                  :key="`content_description${uc}`"
                  class="mt-5"
                >
                  <v-row>
                    <v-col sm="11">
                      <p>Variant {{ uc + 1 }}</p>
                    </v-col>
                    <v-col sm="1">
                      <v-dialog
                        v-model="isDeleteDescriptionDialogs[uc]"
                        max-width="600px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            class="ml-9"
                            small
                            dark
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon dark> mdi-close-thick </v-icon>
                          </v-btn>
                        </template>
                        <v-card elevation="10">
                          <v-card-title>
                            <v-icon large color="primary">
                              mdi-information-outline
                            </v-icon>
                            &nbsp; Do you want to remove the variant?
                          </v-card-title>
                          <v-card-text>
                            <v-btn
                              color="primary"
                              @click.stop="
                                deleteDescription(content_description)
                              "
                            >
                              Yes
                            </v-btn>
                            &nbsp;
                            <v-btn
                              color="red"
                              dark
                              @click.stop="closeDescriptionDialogs"
                            >
                              No
                            </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3">
                      <v-text-field
                        v-model="content_description.level"
                        outlined
                        label="Lexile"
                        type="number"
                        prepend-inner-icon="mdi-sort-alphabetical-variant"
                        :error-messages="errors.level"
                      ></v-text-field
                    ></v-col>
                    <v-col md="9">
                      <v-text-field
                        v-model="content_description.title"
                        outlined
                        label="Title"
                        prepend-inner-icon="mdi-alphabetical-variant"
                        :error-messages="errors.title"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <p class="font-weight-bold">Description</p>
                      <span
                        style="color: red"
                        v-if="errors[`content_descriptions.${uc}.description`]"
                        >This description is required</span
                      >
                      <froala
                        :tag="'textarea'"
                        :config="config"
                        v-model="content_description.description"
                      ></froala>
                    </v-col>
                  </v-row>
                  <v-divider class="my-5"></v-divider>
                </div>
                <v-row>
                  <v-col sm="12" class="mt-5">
                    <v-btn small color="primary" @click="Add_Empty_Description">
                      Add Variant
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Variants Section -->
          <v-row class="my-5">
            <v-col sm="4">
              <input
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="image/*"
                @change="onFileChange"
              />
              <br />
              <p style="color: red">
                {{ imageErrors }}
              </p>
              <br />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="
                  ($refs.file.value = null),
                    (image_url = null),
                    (form.featured_image_path = null)
                "
                >Remove</v-btn
              >
              <div id="preview" class="mt-5">
                <label for="">Card Image Preview</label>
                <br />
                <img
                  v-if="image_url"
                  :src="image_url"
                  style="height: 200px; !important"
                />
                <img
                  v-if="form.featured_image_path"
                  alt="featured_image"
                  :src="`${mediaUrl + form.featured_image_path}`"
                  style="height: 200px; !important"
                />
              </div>
            </v-col>
            <v-col sm="4" v-if="roleName == 'ACADEMIC TEAM'">
              <!-- <v-switch
                v-model="form.is_approved"
                inset
                :label="`Is APPROVED: ${form.is_approved ? 'TRUE' : 'FALSE'}`"
              ></v-switch> -->
              <v-autocomplete
                ref="Status"
                v-model="form.is_approved"
                :items="statusItems"
                label="Select Status"
              ></v-autocomplete>
            </v-col>
            <!-- v-if="roleName == 'ACADEMIC TEAM' && form.is_active != true" -->
            <v-col sm="4">
              <v-switch
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row v-if="roleName == 'ACADEMIC TEAM' && form.is_approved == 2">
            <v-col>
              <p class="font-weight-bold">Remark</p>
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="form.remarks"
              ></froala>
            </v-col>
          </v-row>
          <!-- <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-row v-else> -->
          <v-row
            v-if="
              roleName == 'ACADEMIC TEAM' ||
              (roleName == 'INFAKT TEACHER' && form.is_approved == false) ||
              (roleName == 'INFAKT TEACHER' && form.is_approved == 2)
            "
          >
            <v-col>
              <v-btn
                :loading="isSaving"
                :disabled="isSaving"
                color="primary"
                class="mr-5"
                v-if="roleName == 'INFAKT TEACHER'"
                @click="save((type = 'draft'))"
              >
                Update in draft
              </v-btn>
              <v-btn
                :loading="isSaving"
                :disabled="isSaving"
                color="primary"
                @click="save((type = 'approval'))"
              >
                Update Content
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <!-- Image Warning popups -->
    <v-dialog v-model="warningImageLoader" width="600">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: red; font-size: 90px; width: 200px !important"
              >
                mdi-file-image-remove
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">OOPS!</h1>
                    <br />
                    <h3>
                      Image dimension is not matching with our standard
                      dimension. The image dimension should be multiple of 690 x
                      390
                    </h3>
                    <br />
                    <v-btn color="success" @click="warningImageLoader = false">
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Image Warning popups -->
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
export default {
  components: { backbutton },
  name: "ContentEditPage",
  data() {
    return {
      image: {
        size: "",
        height: "",
        width: "",
      },
      imageLoader: false,
      warningImageLoader: false,
      imageErrors: "",
      config: {
        // toolbarButtons: [
        //   "bold",
        //   "italic",
        //   "underline",
        //   "|",
        //   "align",
        //   "orderList",
        //   "|",
        //   "insertLink",
        //   "insertImage",
        //   "insertVideo",
        //   "insertTable",
        //   "|",
        //   "clearFormatting",
        // ],
        events: {
          initialized: function () {
            console.log("initialized");
          },
          "image.uploaded": function (response) {
            console.log(response);
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      form: {
        keywords: "",
        name: "",
        remark: "",
        subjects: [],
        content_subjects: [],
        content_medias: [],
        content_descriptions: [],
        categories: [],
        content_categories: [],
        content_info_boards: [],
      },
      contentTypes: ["ARTICLE", "INFOGRAPHIC", "VIDEO"],
      schoolTypeItems: ["FREE", "PAID"],
      specificItems: ["PUBLIC", "SCHOOL"],
      subjects: [],
      categoryItems: [],
      subjectItems: [],
      gradeItems: [],
      boardItems: [],
      schoolItems: [],
      isDeleteDescriptionDialogs: [],
      isDeleteDetailDialogs: [],
      statusItems: [
        { id: "PENDING", text: "PENDING", value: 0 },
        { id: "APPROVED", text: "APPROVED", value: 1 },
        { id: "REJECTED", text: "REJECTED", value: 2 },
      ],
      isLoading: false,
      isSaving: false,
      dialog: false,
      image_url: "",
      tab: "tab-1",
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    onFileChange(e) {
      this.warningImageLoader = false;
      this.file = this.$refs.file?.files[0];
      this.image.size = this.file.size;

      let reader = new FileReader();

      reader.readAsDataURL(this.file);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          let divWidth = Math.round(this.image.width / 690);
          let divHeight = Math.round(this.image.height / 390);

          let intoWidth = 690 * divWidth;
          let intoHeight = 390 * divHeight;

          if (
            intoWidth != this.image.width &&
            intoHeight != this.image.height
          ) {
            this.imageErrors =
              "Image dimension ( " +
              this.image.width +
              " x " +
              this.image.height +
              ") which is not matching with our standard dimension. The image dimension should be multiple of 690 x 390.";
            this.imageLoader = true;
            this.warningImageLoader = true;
          } else {
            this.imageLoader = false;
            this.image_url = URL.createObjectURL(this.file);
            this.imageErrors = "";
          }

          if (this.errors.length === 0) {
            this.success = "Image validated successfully";
          }
          // alert(img.width + " " + img.height);
        };
        img.src = evt.target.result;
      };

      reader.onerror = (evt) => {
        console.error(evt);
      };
      // this.image_url = URL.createObjectURL(this.file);
    },
    async getMasters() {
      let masters = await axios.get("/contents/masters");
      masters = masters.data;
      masters.schools.forEach((school) => {
        this.schoolItems.push({
          id: school.id,
          text: school.name,
          value: school.id,
        });
      });
      masters.categories.forEach((category) => {
        this.categoryItems.push({
          id: category.id,
          text: category.name,
          value: category.id,
        });
      });
      masters.subjects.forEach((subject) => {
        this.subjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.name,
        });
      });
      masters.boards.forEach((board) => {
        this.boardItems.push({
          id: board.id,
          text: board.name,
          value: board.id,
          learning_outcome: "",
          grades: [],
          subjects: [],
        });
      });
      masters.grades.forEach((grade) => {
        this.gradeItems.push({
          id: grade.id,
          text: grade.name,
          value: grade.name,
        });
      });
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/contents/${this.$route.params.contentId}`);
      this.form = form.data.data;
      // Keywords

      this.form.keywords = this.form.content_keywords
        ?.map((keyword) => keyword.keyword)
        .join(", ");
      console.log(this.form.keywords);
      // Subjects
      let subjects = [];
      this.form.content_subjects.forEach((subject) => {
        subjects.push(subject.subject_id);
      });
      this.form.subjects = [...new Set(subjects)];
      // Categories
      let categories = [];
      this.form.content_categories.forEach((category) => {
        categories.push(category.category_id);
      });
      this.form.categories = [...new Set(categories)];

      // Grades
      let grades = [];
      this.form.content_grades.forEach((grade) => {
        grades.push(grade.grade_id);
      });
      this.form.grades = [...new Set(grades)];

      // Boards
      let boards = [];
      this.form.content_boards.forEach((board) => {
        boards.push(board.board_id);
      });
      this.form.boards = [...new Set(boards)];
      this.boardItems.forEach((board) => {
        console.log(board.text);
        let ifb = this.form.content_info_boards.find(
          (cib) => cib.board_id == board.id
        );
        if (ifb) {
          ifb.text = board.text;
        } else {
          this.form.content_info_boards.push({
            board_id: board.id,
            text: board.text,
            content_info_board_details: [],
          });
        }
      });
      console.log(this.form.content_info_boards);
      // // Info Boards
      // let info_boards = [];
      // this.form.content_info_boards.forEach((info_board) => {
      //   let ifb = this.boardItems.find((g) => g.id == info_board.board_id);
      //   ifb.learning_outcome = info_board.learning_outcome;
      //   info_board.content_info_board_grades.forEach((grade) => {
      //     ifb.grades.push(grade.grade_id);
      //   });
      //   info_board.content_info_board_subjects.forEach((subject) => {
      //     ifb.subjects.push(subject.subject_id);
      //   });
      //   // info_boards.push(info_board.board_id);
      // });
      // console.log(this.boardItems);
      // this.form.info_boards = info_boards;

      // Schools
      let schools = [];
      this.form.content_schools.forEach((school) => {
        schools.push(school.company_id);
      });
      this.form.schools = [...new Set(schools)];

      this.isLoading = false;
    },
    async save(type) {
      try {
        this.isSaving = true;
        if (this.imageLoader == false) {
          // Content Categories
          let content_categories = [];
          this.form.categories.forEach((category_id) => {
            let contentCategory = this.form.content_categories.find(
              (g) => g.category_id == category_id
            );
            if (contentCategory) {
              content_categories.push(contentCategory);
            } else {
              let content_category = {
                category_id: category_id,
              };
              content_categories.push(content_category);
            }
          });
          this.form.content_categories = content_categories;
          // Content Subjects
          let content_subjects = [];
          this.form.subjects.forEach((subject_id) => {
            let contentSubject = this.form.content_subjects.find(
              (g) => g.subject_id == subject_id
            );
            if (contentSubject) {
              content_subjects.push(contentSubject);
            } else {
              let content_subject = {
                subject_id: subject_id,
              };
              content_subjects.push(content_subject);
            }
          });
          this.form.content_subjects = content_subjects;
          // Content Grades
          let content_grades = [];
          this.form.grades.forEach((grade_id) => {
            let contentGrade = this.form.content_grades.find(
              (g) => g.grade_id == grade_id
            );
            if (contentGrade) {
              content_grades.push(contentGrade);
            } else {
              let content_grade = {
                grade_id: grade_id,
              };
              content_grades.push(content_grade);
            }
          });
          this.form.content_grades = content_grades;

          // Content Boards
          let content_boards = [];
          this.form.boards.forEach((board_id) => {
            let contentBoard = this.form.content_boards.find(
              (b) => b.board_id == board_id
            );
            if (contentBoard) {
              content_boards.push(contentBoard);
            } else {
              let content_board = {
                board_id: board_id,
              };
              content_boards.push(content_board);
            }
          });
          this.form.content_boards = content_boards;
          // Content Info Boards
          // let content_info_boards = [];
          // this.form.info_boards.forEach((info_board_id) => {
          //   let contentInfoBoard = this.form.content_info_boards.find(
          //     (ib) => ib.info_board_id == info_board_id
          //   );
          //   if (contentInfoBoard) {
          //     content_info_boards.push(contentInfoBoard);
          //   } else {
          //     let content_info_board = {
          //       board_id: info_board_id,
          //     };
          //     content_info_boards.push(content_info_board);
          //   }
          // });
          // this.form.content_info_boards = content_info_boards;

          // Content Info Boards
          let content_schools = [];
          this.form.schools.forEach((school_id) => {
            let contentSchool = this.form.content_schools.find(
              (b) => b.school_id == school_id
            );
            if (contentSchool) {
              content_schools.push(contentSchool);
            } else {
              let content_school = {
                company_id: school_id,
              };
              content_schools.push(content_school);
            }
          });

          this.form.content_schools = content_schools;
          if (type == "draft") {
            this.form.is_draft = true;
          }
          if (type == "approval") {
            this.form.is_draft = false;
          }
          this.form.content_categories = [
            ...new Set(this.form.content_categories),
          ];
          this.form.content_boards = [...new Set(this.form.content_boards)];
          this.form.content_schools = [...new Set(this.form.content_schools)];
          this.form.content_subjects = [...new Set(this.form.content_subjects)];
          await axios.post(`/contents`, this.form);
          this.handleFileUpload();

          this.isSaving = false;
          this.$router.push("/contents");
        } else {
          this.warningImageLoader = true;
          this.isSaving = false;
        }
      } catch (e) {
        this.isSaving = false;
      }
    },
    Add_Empty_Description() {
      let content_description = {
        level: "",
        title: "",
        description: "",
      };
      this.form.content_descriptions.push(content_description);
    },
    deleteDescription(content_description) {
      this.form.content_descriptions.splice(
        this.form.content_descriptions.indexOf(content_description),
        1
      );
      this.closeDescriptionDialogs();
    },
    closeDescriptionDialogs() {
      this.isDeleteDescriptionDialogs = [];
    },
    Add_Empty_Detail(parent) {
      let content_info_board_detail = {
        grade_id: "",
        subject_id: "",
        chapter: "",
        learning_outcome: "",
      };
      parent.content_info_board_details.push(content_info_board_detail);
    },
    deleteDetail(parent, content_info_board_detail) {
      parent.content_info_board_details.splice(
        parent.content_info_board_details.indexOf(content_info_board_detail),
        1
      );
      this.closeDetailDialogs();
    },
    closeDetailDialogs() {
      this.isDeleteDetailDialogs = [];
    },
    async handleFileUpload() {
      this.isSaving = true;
      let attachment = this.$refs.file?.files[0];
      const contentid = this.form.id;
      let formData = new FormData();
      formData.append("contentid", contentid);
      formData.append("featuredimagepath", attachment);
      await axios
        .post("upload_content_featured_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      if (this.career_request) {
        this.success = true;
        this.form = [];
      }
      this.isSaving = false;
    },
  },
};
</script>
