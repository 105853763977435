<template>
  <v-main>
    <v-container
      ><!-- Recently Onboarded Classes -->
      <br />
      <v-row justify="start">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Classes'"></span>
          <addbutton
            v-if="roleName == 'TEACHER'"
            :link="`/teachers/${user.id}`"
          ></addbutton>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="standard"
            v-model="form.standard_id"
            :items="standardItems"
            label="Standard"
            @change="getStandardSections"
            prepend-icon="mdi-alarm-bell"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="section"
            v-model="form.section_id"
            @change="getSectionClasscodes"
            :items="sectionItems"
            label="Section"
            prepend-icon="mdi-account-school"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="classcode"
            v-model="form.classcode_id"
            :items="classcodeItems"
            label="Subject"
            prepend-icon="mdi-application-edit"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col sm="3" v-for="uc in user_classcodes" :key="uc.name">
          <template v-if="roleName == 'STUDENT'">
            <v-card elevation="6" shaped>
              <v-col>
                <v-img
                  height="200"
                  :src="require('@/assets/imgs/classroom.png')"
                ></v-img>
              </v-col>
              <!-- :src="require('@/assets/imgs/class_default_photo.jpeg')" -->

              <v-card-title class="justify-center">
                {{ uc.classcode.subject_name }}</v-card-title
              >
              <!-- <v-divider class="mx-4"></v-divider> -->
              <v-card-text>
                <v-row>
                  <v-col style="text-align: center">
                    <classcode-element
                      :classcode="uc.classcode.classcode"
                      :type="
                        roleName == 'STUDENT'
                          ? 'SUBJECT'
                          : roleName == 'TEACHER'
                          ? 'STANDARD_SUBJECT'
                          : ''
                      "
                    ></classcode-element>
                  </v-col>
                </v-row>
              </v-card-text>

              <!-- <v-divider class="mx-4"></v-divider>
                <v-card-text></v-card-text> -->
            </v-card>
          </template>
          <template v-else>
            <router-link
              class="routerLink"
              :to="`/class_dashboard/${uc.classcode_id}?teacherId=${teacher_id}`"
            >
              <v-card elevation="6" shaped>
                <v-col>
                  <v-img
                    height="200"
                    :src="require('@/assets/imgs/classroom.png')"
                  ></v-img>
                </v-col>
                <!-- :src="require('@/assets/imgs/class_default_photo.jpeg')" -->

                <v-card-title class="justify-center">
                  {{ uc.classcode.subject_name }}</v-card-title
                >
                <!-- <v-divider class="mx-4"></v-divider> -->
                <v-card-text>
                  <v-row>
                    <v-col style="text-align: center">
                      <classcode-element
                        :classcode="uc.classcode.classcode"
                        :type="
                          roleName == 'STUDENT'
                            ? 'SUBJECT'
                            : roleName == 'TEACHER'
                            ? 'STANDARD_SUBJECT'
                            : ''
                        "
                      ></classcode-element>
                    </v-col>
                  </v-row>
                </v-card-text>

                <!-- <v-divider class="mx-4"></v-divider>
                <v-card-text></v-card-text> -->
              </v-card>
            </router-link>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";

export default {
  data() {
    return {
      form: {
        section_id: "",
        standard_id: "",
        classcode_id: "",
      },
      teacher_id: "",
      isLoading: false,
      user_classcodes: [],
      standards: [],
      standardItems: [],
      sections: [],
      sectionItems: [],
      classcodes: [],
      classcodeItems: [],
    };
  },
  components: { addbutton, Loader },
  mounted() {
    if (this.roleName == "TEACHER") {
      this.teacher_id = this.user.id;
    } else {
      this.teacher_id = this.$route.params.teacherId;
    }
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      this.isLoading = true;
      let masters = await axios.get("users/masters");
      masters = masters.data;
      this.standards = masters.standards;
      this.sections = masters.sections;
      this.classcodes = masters.classcodes;
      // Standard
      this.standards.forEach((standard) => {
        this.standardItems.push({
          id: standard.id,
          text: standard.name,
          value: standard.id,
        });
      });
      // Section
      this.sections.forEach((section) => {
        this.sectionItems.push({
          id: section.id,
          text: section.name,
          value: section.id,
        });
      });
      // ClassCodes
      this.classcodes.forEach((classcode) => {
        this.classcodeItems.push({
          id: classcode.id,
          text: classcode.subject_name,
          value: classcode.id,
        });
      });
      this.isLoading = false;
    },
    async getData() {
      this.isLoading = true;
      this.form.standard_id = this.form.standard_id
        ? this.form.standard_id
        : "";
      this.form.section_id = this.form.section_id ? this.form.section_id : "";
      this.form.classcode_id = this.form.classcode_id
        ? this.form.classcode_id
        : "";
      let user_classcodes = [];
      let query =
        "user_id=" +
        this.teacher_id +
        "&standard_id=" +
        this.form.standard_id +
        "&section_id=" +
        this.form.section_id +
        "&classcode_id=" +
        this.form.classcode_id;
      user_classcodes = await axios.get(`/user_classcodes?${query}`);
      this.user_classcodes = user_classcodes.data.data;
      this.count = user_classcodes.data.count;
      this.isLoading = false;
    },
    async getStandardSections() {
      this.isLoading = true;
      if (this.form.standard_id) {
        this.sectionItems = [];
        this.classcodeItems = [];
        this.form.section_id = "";
        this.form.classcode_id = "";
        let standardsections = this.sections.filter(
          (section) => section.standard_id == this.form.standard_id
        );
        standardsections.forEach((ss) => {
          this.sectionItems.push({
            id: ss.id,
            text: ss.name,
            value: ss.id,
          });
        });
      }
      this.isLoading = false;
    },
    async getSectionClasscodes() {
      this.isLoading = true;
      if (this.form.section_id) {
        this.classcodeItems = [];
        this.form.classcode_id = "";
        let sectionclasscodes = this.classcodes.filter(
          (classcode) => classcode.section_id == this.form.section_id
        );
        sectionclasscodes.forEach((sc) => {
          this.classcodeItems.push({
            id: sc.id,
            text: sc.subject_name,
            value: sc.id,
          });
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
