<template>
  <v-autocomplete
    ref="subject"
    v-model="selectedBoardId"
    :items="boards"
    label="Select Location"
    prepend-icon="mdi-domain"
    @input="changeBoard"
  ></v-autocomplete>
</template>
<script type="text/javascript">
import store from "@/store";
export default {
  data() {
    return {
      isLoading: false,
      boards: [],
      selectedBoardId: null,
    };
  },
  mounted() {
    this.boards = [];
    this.company.company_boards.forEach((companyBoard) => {
      this.boards.push({
        id: companyBoard.board.id,
        value: companyBoard.board.id,
        text: companyBoard.board.name,
      });
    });
    if (this.board != null && this.board.id != null) {
      this.selectedBoardId = this.board.id;
    } else if (this.selectedBoardId == null && this.boards.length > 0) {
      store.dispatch("auth/setBoard", this.boards[0]);
      this.selectedBoardId = this.boards[0].id;
    }
    console.log("Selected board component:" + this.selectedBoardId);
    // console.log(this.boards[0]);
  },
  methods: {
    changeBoard() {
      let board = this.boards.find((b) => b.id == this.selectedBoardId);
      store.dispatch("auth/setBoard", board);
      location.reload();
    },
  },
};
</script>