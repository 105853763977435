<template>
  <v-main style="font-family: Montserrat">
    <v-container fluid class="pa-0">
      <v-stepper
        v-model="current_step"
        vertical
        style="padding-bottom: 15px !important"
      >
        <v-row class="pt-4 pl-2" style="border-bottom: inset 1px">
          <v-col class="pa-3"
            ><h2>
              <v-btn icon large> <v-icon>mdi-chevron-left</v-icon> </v-btn> Add
              Content
            </h2></v-col
          >
        </v-row>
        <v-row>
          <v-col
            md="2"
            style="border-right: 1px #eaecf0 solid; min-height: 629px"
          >
            <div
              style="
                /* width: 304px; */
                min-height: 600px;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 24px;
                padding-bottom: 24px;

                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                display: inline-flex;
              "
            >
              <div
                @click="current_step = 1"
                style="
                  align-self: stretch;
                  height: 80px;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 16px;
                  display: inline-flex;
                "
              >
                <template v-if="current_step == 1">
                  <!-- Current Step is 1 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f2f5fa;
                        box-shadow: 0px 0px 0px 4px #f4ebff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          background: #f2f5fa;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #0739a6;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #e1e1e1;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step > 1">
                  <!-- Greater Than 1 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f9f5ff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <v-icon color="#0739a6">mdi-check</v-icon>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #0739a6;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <div
                  style="
                    flex: 1 1 0;
                    padding-top: 4px;
                    padding-bottom: 32px;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 2px;
                    display: inline-flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      color: #344054;
                      font-size: 16px;
                      font-family: Montserrat;
                      font-weight: 500;
                      line-height: 24px;
                      word-wrap: break-word;
                    "
                  >
                    Content details
                  </div>
                </div>
              </div>
              <div
                @click="current_step = 2"
                style="
                  align-self: stretch;
                  height: 80px;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 16px;
                  display: inline-flex;
                "
              >
                <template v-if="current_step == 2">
                  <!-- Current Step is 2 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f2f5fa;
                        box-shadow: 0px 0px 0px 4px #f4ebff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          background: #f2f5fa;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #0739a6;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #e1e1e1;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step > 2">
                  <!-- Greater Than 2 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f9f5ff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <v-icon color="#0739a6">mdi-check</v-icon>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #0739a6;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step < 2">
                  <!-- Less Than 2 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: white;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #eaecf0 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #eaecf0;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #eaecf0;
                        border-radius: 2px;
                      "
                    ></div>
                  </div>
                </template>
                <div
                  style="
                    flex: 1 1 0;
                    padding-top: 4px;
                    padding-bottom: 32px;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 2px;
                    display: inline-flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      color: #344054;
                      font-size: 16px;
                      font-family: Montserrat;
                      font-weight: 500;
                      line-height: 24px;
                      word-wrap: break-word;
                    "
                  >
                    Learning Outcome
                  </div>
                </div>
              </div>
              <div
                @click="current_step = 3"
                style="
                  align-self: stretch;
                  height: 80px;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 16px;
                  display: inline-flex;
                "
              >
                <template v-if="current_step == 3">
                  <!-- Current Step is 3 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f2f5fa;
                        box-shadow: 0px 0px 0px 4px #f4ebff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          background: #f2f5fa;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #0739a6;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #e1e1e1;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step > 3">
                  <!-- Greater Than 3 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f9f5ff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <v-icon color="#0739a6">mdi-check</v-icon>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #0739a6;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step < 3">
                  <!-- Less Than 3 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: white;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #eaecf0 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #eaecf0;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #eaecf0;
                        border-radius: 2px;
                      "
                    ></div>
                  </div>
                </template>
                <div
                  style="
                    flex: 1 1 0;
                    padding-top: 4px;
                    padding-bottom: 32px;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 2px;
                    display: inline-flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      color: #333333;
                      font-size: 16px;
                      font-family: Montserrat;
                      font-weight: 500;
                      line-height: 24px;
                      word-wrap: break-word;
                    "
                  >
                    Info box
                  </div>
                </div>
              </div>
              <div
                @click="current_step = 4"
                style="
                  align-self: stretch;
                  height: 80px;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 16px;
                  display: inline-flex;
                "
              >
                <template v-if="current_step == 4">
                  <!-- Current Step is 4 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f2f5fa;
                        box-shadow: 0px 0px 0px 4px #f4ebff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          background: #f2f5fa;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #0739a6;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #e1e1e1;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step > 4">
                  <!-- Greater Than 4 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f9f5ff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <v-icon color="#0739a6">mdi-check</v-icon>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #0739a6;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step < 4">
                  <!-- Less Than 4 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: white;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #eaecf0 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #eaecf0;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #eaecf0;
                        border-radius: 2px;
                      "
                    ></div>
                  </div>
                </template>
                <div
                  style="
                    flex: 1 1 0;
                    padding-top: 4px;
                    padding-bottom: 32px;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 2px;
                    display: inline-flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      color: #344054;
                      font-size: 16px;
                      font-family: Montserrat;
                      font-weight: 500;
                      line-height: 24px;
                      word-wrap: break-word;
                    "
                  >
                    Upload images
                  </div>
                </div>
              </div>
              <div
                @click="current_step = 5"
                style="
                  align-self: stretch;
                  height: 80px;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 16px;
                  display: inline-flex;
                "
              >
                <template v-if="current_step == 5">
                  <!-- Current Step is 5 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f2f5fa;
                        box-shadow: 0px 0px 0px 4px #f4ebff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          background: #f2f5fa;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #0739a6;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #e1e1e1;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step > 5">
                  <!-- Greater Than 5 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f9f5ff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <v-icon color="#0739a6">mdi-check</v-icon>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #0739a6;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step < 5">
                  <!-- Less Than 5 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: white;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #eaecf0 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #eaecf0;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #eaecf0;
                        border-radius: 2px;
                      "
                    ></div>
                  </div>
                </template>
                <div
                  style="
                    flex: 1 1 0;
                    padding-top: 4px;
                    padding-bottom: 32px;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 2px;
                    display: inline-flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      color: #344054;
                      font-size: 16px;
                      font-family: Montserrat;
                      font-weight: 500;
                      line-height: 24px;
                      word-wrap: break-word;
                    "
                  >
                    Original containt
                  </div>
                </div>
              </div>
              <div
                @click="current_step = 6"
                style="
                  align-self: stretch;
                  height: 80px;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 16px;
                  display: inline-flex;
                "
              >
                <template v-if="current_step == 6">
                  <!-- Current Step is 6 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f2f5fa;
                        box-shadow: 0px 0px 0px 4px #f4ebff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          background: #f2f5fa;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #0739a6;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #e1e1e1;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step > 6">
                  <!-- Greater Than 6 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f9f5ff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <v-icon color="#0739a6">mdi-check</v-icon>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #0739a6;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step < 6">
                  <!-- Less Than 6 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: white;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #eaecf0 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #eaecf0;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #eaecf0;
                        border-radius: 2px;
                      "
                    ></div>
                  </div>
                </template>
                <div
                  style="
                    flex: 1 1 0;
                    padding-top: 4px;
                    padding-bottom: 32px;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 2px;
                    display: inline-flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      color: #344054;
                      font-size: 16px;
                      font-family: Montserrat;
                      font-weight: 500;
                      line-height: 24px;
                      word-wrap: break-word;
                    "
                  >
                    Content variant
                  </div>
                </div>
              </div>
              <div
                style="
                  align-self: stretch;
                  height: 80px;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 16px;
                  display: inline-flex;
                "
              >
                <template v-if="current_step == 7">
                  <!-- Current Step is 7 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f2f5fa;
                        box-shadow: 0px 0px 0px 4px #f4ebff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          background: #f2f5fa;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #0739a6;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div></div
                ></template>
                <template v-if="current_step > 7">
                  <!-- Greater Than 7 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: #f9f5ff;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #0739a6 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <v-icon color="#0739a6">mdi-check</v-icon>
                      </div>
                    </div>
                    <div
                      style="
                        width: 2px;
                        flex: 1 1 0;
                        background: #0739a6;
                        border-radius: 2px;
                      "
                    ></div></div
                ></template>
                <template v-if="current_step < 7">
                  <!-- Less Than 7 -->
                  <div
                    style="
                      align-self: stretch;
                      padding-bottom: 4px;
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        width: 32px;
                        height: 32px;
                        background: white;
                        border-radius: 16px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          width: 32px;
                          height: 32px;
                          position: relative;
                          border-radius: 16px;
                          border: 2px #eaecf0 solid;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          display: flex;
                        "
                      >
                        <div
                          style="
                            width: 10px;
                            height: 10px;
                            background: #eaecf0;
                            border-radius: 9999px;
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </template>
                <div
                  style="
                    flex: 1 1 0;
                    padding-top: 4px;
                    padding-bottom: 32px;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 2px;
                    display: inline-flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      color: #344054;
                      font-size: 16px;
                      font-family: Montserrat;
                      font-weight: 500;
                      line-height: 24px;
                      word-wrap: break-word;
                    "
                  >
                    Content preview
                  </div>
                </div>
              </div>
            </div>
            <!-- <v-stepper-step
              step="1"
              edit-icon="mdi-circle-small"
              :complete="current_step > 1"
              editable
              icon
            >
              <v-row> Content details </v-row>
              <v-row>
                <div
                  style="
                    width: 1px;
                    flex: 1 1 0;
                    background: #e1e1e1;
                    border-radius: 2px;
                  "
                ></div>
                <v-divider inset class="vD" vertical></v-divider>
              </v-row>
            </v-stepper-step>
            <v-stepper-step
              edit-icon="mdi-check"
              :complete="current_step > 2"
              step="2"
              editable
              style="
                width: 1px;
                background: #e1e1e1;
                border-radius: 2px;
              "
            >
              Learning Outcome
            </v-stepper-step>
            <v-divider inset class="vD" vertical></v-divider>
            <v-stepper-step
              edit-icon="mdi-check"
              :complete="current_step > 3"
              step="3"
              editable
            >
              Info Box
            </v-stepper-step>
            <v-divider inset class="vD" vertical></v-divider>
            <v-stepper-step
              edit-icon="mdi-check"
              :complete="current_step > 4"
              comp
              step="4"
              editable
            >
              Upload Images
            </v-stepper-step>
            <v-divider inset class="vD" style="" vertical></v-divider>
            <v-stepper-step
              step="5"
              edit-icon="mdi-check"
              :complete="current_step > 5"
              editable
            >
              Original Content
            </v-stepper-step>
            <v-divider inset class="vD" vertical></v-divider>
            <v-stepper-step
              edit-icon="mdi-check"
              :complete="current_step > 6"
              step="6"
              editable
            >
              Content Variant
            </v-stepper-step>
            <v-divider inset class="vD" vertical></v-divider>
            <v-stepper-step edit-icon="mdi-check" step="7">
              Content Preview
            </v-stepper-step> -->
          </v-col>
          <v-col
            md="10"
            class="px-10"
            style="font-family: Montserrat !important"
          >
            <v-stepper-content step="1">
              <v-row style="margin-top: 25px !important">
                <v-col>
                  <h2
                    class="text-h5"
                    style="font-family: Montserrat !important"
                  >
                    <b>Content details</b>
                  </h2></v-col
                >
              </v-row>
              <v-row style="gap: inherit">
                <v-col>
                  <p class="text-subtitle-2 label">Article ID</p>
                  <v-text-field
                    v-model="form.id"
                    outlined
                    dense
                    filled
                    hide-details
                    shaped
                    placeholder=""
                    disabled
                  ></v-text-field
                ></v-col>
                <v-col>
                  <p class="text-subtitle-2 label">Content type</p>
                  <v-autocomplete
                    ref="subject"
                    outlined
                    dense
                    v-model="form.content_type"
                    :items="contentTypes"
                    small-chips
                    hide-details
                    placeholder="Select Content Type"
                    prepend-inner-icon="mdi-domain"
                    :error-messages="errors.content_type"
                  ></v-autocomplete
                ></v-col>
                <v-col>
                  <p class="text-subtitle-2 label">Category</p>
                  <v-autocomplete
                    ref="category"
                    outlined
                    dense
                    v-model="form.categories"
                    :items="categoryItems"
                    multiple
                    hide-details
                    placeholder="Select Category"
                    small-chips
                    prepend-inner-icon="mdi-shape-plus-outline"
                    :error-messages="errors.content_categories"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row style="gap: 32px">
                <v-col>
                  <p class="text-subtitle-2 label">Article Link</p>
                  <v-text-field
                    v-model="form.id"
                    outlined
                    dense
                    hide-details
                    placeholder="Enter the link"
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p class="text-subtitle-2 label">Title</p>
                  <v-text-field
                    v-model="form.content_name"
                    outlined
                    hide-details
                    dense
                    placeholder="Enter title"
                    :error-messages="errors.content_name"
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col sm="4">
                  <p class="text-subtitle-2 label">Reading time (mints)</p>
                  <v-text-field
                    v-model="form.reading_time"
                    hide-details
                    outlined
                    dense
                    placeholder="Enter time in minutes"
                    :error-messages="errors.reading_time"
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <p class="text-subtitle-2 label">Publication</p>
                  <v-text-field
                    v-model="form.publication"
                    outlined
                    hide-details
                    dense
                    placeholder="Enter Publication"
                    :error-messages="errors.publication"
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <p class="text-subtitle-2 label">Written By</p>
                  <v-text-field
                    v-model="form.written_by_name"
                    hide-details
                    outlined
                    dense
                    placeholder="Enter author name"
                    :error-messages="errors.written_by_name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p class="text-subtitle-2 label">Key words</p>
                  <v-combobox
                    :items="KeywordItems"
                    :search-input.sync="search"
                    hide-selected
                    v-model="form.keywords"
                    @keyup="
                      (form.keywords = form.keywords.toUpperCase()),
                        getKeyword()
                    "
                    multiple
                    dense
                    outlined
                    persistent-hint
                    small-chips
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ search }}</strong
                            >". Press <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ attrs, item }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        subtitle-2
                        label
                        small
                      >
                        <span class="pr-2">
                          {{ item }}
                        </span>
                        <v-icon small @click="deleteKeyword(item)">
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
              <!-- <v-row class="pb-1" style="border-top: inset 1px">
                  <v-col align="right">
                    <v-btn text outlined> Cancel </v-btn>
                    <v-btn
                      color="black"
                      class="white--text ml-2"
                      @click="current_step = 2"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row> -->
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-row style="margin-top: 27px !important">
                <v-col> <h2>Learning Outcome</h2></v-col>
              </v-row>
              <v-row>
                <v-col sm="12">
                  <p class="text-subtitle-2 label">Outcome</p>
                  <froala
                    :tag="'textarea'"
                    :config="config"
                    v-model="form.original_content"
                  ></froala>
                </v-col>
              </v-row>
              <v-row
                ><v-col class="pt-2">
                  <v-btn class="newButton" width="75" color="#0739A6" dark>
                    Add
                  </v-btn></v-col
                >
              </v-row>
              <v-row style="border-top: inset 1px" class="mt-6">
                <v-col>
                  <v-card
                    style="border-radius: 16px; border: 1px #cccccc solid"
                    class="my-5 overflow-auto"
                    color="#F2F5FA"
                    elevation="0"
                  >
                    <v-card-text>
                      <template>
                        <!-- Show in Card -->
                        <v-row class="d-flex">
                          <v-col
                            id="lo_section mr-auto order-sm-1"
                            style="min-width: 424px; margin: auto"
                            sm="11"
                            md="11"
                            lg="11"
                          >
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </v-col>
                          <v-col
                            sm="1"
                            md="1"
                            lg="1"
                            class="d-flex justify-lg-end justify-md-end order-sm-0"
                          >
                            <v-btn icon
                              ><v-icon size="15">mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn icon v-bind="attrs" v-on="on"
                              ><v-icon size="15">mdi-trash-can-outline</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-row style="margin-top: 32px !important">
                <v-col> <h2>Info Box</h2></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p class="text-subtitle-2 label">Board</p>
                  <v-autocomplete
                    ref="board_id"
                    v-model="form.boards"
                    :items="boardItems"
                    dense
                    hide-details
                    placeholder="Select Board"
                    outlined
                    prepend-inner-icon="mdi-cast-education"
                  ></v-autocomplete
                ></v-col>
                <v-col>
                  <p class="text-subtitle-2 label">Grade</p>
                  <v-autocomplete
                    ref="grade"
                    v-model="form.grade"
                    :items="gradeItems"
                    placeholder="Select Grade"
                    dense
                    hide-details
                    outlined
                    prepend-inner-icon="mdi-medal"
                  ></v-autocomplete
                ></v-col>
                <v-col>
                  <p class="text-subtitle-2 label">Subject</p>
                  <v-autocomplete
                    ref="category"
                    outlined
                    dense
                    v-model="form.categories"
                    hide-details
                    :items="categoryItems"
                    placeholder="Select Subject"
                    prepend-inner-icon="mdi-book-open-variant"
                    :error-messages="errors.content_categories"
                  ></v-autocomplete
                ></v-col>
                <v-col>
                  <p class="text-subtitle-2 label">Chapter</p>
                  <v-text-field
                    v-model="form.title"
                    hide-details
                    outlined
                    placeholder="Enter Chapter"
                    dense
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row>
                <v-col sm="12" class="pb-0">
                  <p class="text-subtitle-2 label">Learning Outcomes</p>
                  <v-autocomplete
                    ref="category"
                    outlined
                    dense
                    v-model="form.categories"
                    :items="categoryItems"
                    placeholder="Select Learning Outcome"
                    :error-messages="errors.content_categories"
                  ></v-autocomplete
                ></v-col>
              </v-row>
              <v-row
                ><v-col class="pt-0 pb-6">
                  <v-btn color="#0739A6" class="newButton" dark width="75">
                    Add
                  </v-btn></v-col
                >
              </v-row>
              <v-row style="border-top: inset 1px">
                <v-col class="mx-1">
                  <v-card
                    style="border-radius: 16px; border: 1px #cccccc solid"
                    class="my-5 overflow-auto"
                    color="#F2F5FA"
                    elevation="0"
                  >
                    <v-card-text>
                      <v-row style="height: 45">
                        <v-col
                          id="Board_section"
                          md="1"
                          align="left"
                          style="
                            min-width: fit-content;
                            border-right: 0.5px solid gray;
                            height: 20;
                            padding: revert;
                            margin: 12px 0px 0px 12px;
                          "
                        >
                          ICSC
                        </v-col>
                        <v-col
                          id="grade_section"
                          md="1"
                          align="center"
                          style="
                            min-width: fit-content;
                            border-right: 0.5px solid gray;
                            height: 20;
                            padding: revert;
                            margin: 12px 6px 0px 6px;
                          "
                          >7th</v-col
                        >
                        <v-col
                          id="subject_section"
                          md="1"
                          align="center"
                          style="
                            min-width: fit-content;
                            border-right: 0.5px solid gray;
                            height: 20;
                            padding: revert;
                            margin: 12px 6px 0px 6px;
                          "
                          >Science</v-col
                        >
                        <v-col
                          id="chapter_section"
                          md="1"
                          class="mx-2"
                          align="center"
                          style="
                            min-width: fit-content;
                            height: 20;
                            padding: revert;
                            margin: 12px 6px 0px 6px;
                          "
                          >2th Chapter</v-col
                        >
                        <v-col align="right">
                          <v-btn icon
                            ><v-icon size="15">mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn icon
                            ><v-icon size="15">mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col id="lo_section"
                          >Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row style="border-top: inset 1px">
                <v-col class="mx-1">
                  <v-card
                    style="border-radius: 16px; border: 1px #cccccc solid"
                    class="my-5 overflow-auto"
                    color="#F2F5FA"
                    elevation="0"
                  >
                    <v-card-text>
                      <v-row style="height: 45">
                        <v-col
                          id="Board_section"
                          md="1"
                          align="left"
                          style="
                            min-width: fit-content;
                            border-right: 0.5px solid gray;
                            height: 20;
                            padding: revert;
                            margin: 12px 0px 0px 12px;
                          "
                        >
                          ICSC
                        </v-col>
                        <v-col
                          id="grade_section"
                          md="1"
                          align="center"
                          style="
                            min-width: fit-content;
                            border-right: 0.5px solid gray;
                            height: 20;
                            padding: revert;
                            margin: 12px 6px 0px 6px;
                          "
                          >7th</v-col
                        >
                        <v-col
                          id="subject_section"
                          md="1"
                          align="center"
                          style="
                            min-width: fit-content;
                            border-right: 0.5px solid gray;
                            height: 20;
                            padding: revert;
                            margin: 12px 6px 0px 6px;
                          "
                          >Science</v-col
                        >
                        <v-col
                          id="chapter_section"
                          md="1"
                          class="mx-2"
                          align="center"
                          style="
                            min-width: fit-content;
                            height: 20;
                            padding: revert;
                            margin: 12px 6px 0px 6px;
                          "
                          >2th Chapter</v-col
                        >
                        <v-col align="right">
                          <v-btn icon
                            ><v-icon size="15">mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn icon
                            ><v-icon size="15">mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col id="lo_section"
                          >Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-row style="margin-top: 37px !important">
                <v-col> <h2>Upload Image</h2></v-col>
              </v-row>
              <v-row>
                <v-col
                  ><v-file-input
                    subtitle-2
                    label="File input"
                    filled
                    prepend-inner-icon="mdi-camera"
                  ></v-file-input
                ></v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="5">
              <v-row style="margin-top: 42px !important">
                <v-col> <h2>Original Content</h2></v-col>
              </v-row>
              <v-row>
                <v-col sm="12">
                  <p class="text-subtitle-2 label">Description</p>
                  <froala
                    :tag="'textarea'"
                    :config="config"
                    v-model="form.original_content"
                  ></froala>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="6">
              <v-container>
                <v-row style="margin-top: 35px !important">
                  <v-col> <h2>Content Variants</h2></v-col>
                </v-row>
                <v-row style="border-bottom: inset 1px">
                  <v-col md="9">
                    <p class="text-subtitle-2 label">Title</p>
                    <v-text-field outlined dense></v-text-field>
                  </v-col>
                  <v-col md="3">
                    <p class="text-subtitle-2 label">Number of variants</p>
                    <v-text-field
                      outlined
                      dense
                      v-model="form.no_of_variants"
                      @keyup="CreateVariants"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <template
                  v-for="(content_description, uc) in form.content_descriptions"
                >
                  <v-row :key="`content_description_1${uc}`">
                    <v-col md="12">
                      <p class="text-subtitle-2 label">Lexile</p>
                      <v-text-field
                        v-model="content_description.level"
                        outlined
                        dense
                        hide-details
                        placeholder="Enter lexile"
                        type="number"
                        :error-messages="
                          errors[`content_descriptions.${uc}.level`]
                            ? `The level field is required.`
                            : ``
                        "
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row :key="`content_description_2${uc}`">
                    <v-col sm="12">
                      <p class="text-subtitle-2 label">Description</p>
                      <span
                        style="color: red"
                        v-if="errors[`content_descriptions.${uc}.description`]"
                        >This description is required</span
                      >
                      <froala
                        :tag="'textarea'"
                        :config="config"
                        v-model="content_description.description"
                      ></froala>
                    </v-col>
                  </v-row>
                </template>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="7">
              <v-row style="margin-top: 55px !important">
                <v-col> <h2>Content Preview</h2></v-col>
              </v-row>
              <v-row style="border-top: 1px inset">
                <v-col sm="12">
                  <v-parallax
                    dark
                    class="rounded-xl"
                    height="350"
                    :src="`https://az2-medias.s3.ap-south-1.amazonaws.com/infakt/contents/featured-images/111/photo1687420509.jpg`"
                  >
                    <v-row justify="end">
                      <v-col>
                        <v-card-actions>
                          <VueTextToSpeech1
                            id="dont-print"
                            class="no-select"
                            :audioPath="contentAudio"
                            :is_greater_than_5000="isGreaterThan5000"
                          ></VueTextToSpeech1>
                        </v-card-actions>
                      </v-col> </v-row
                  ></v-parallax>
                  <!-- <v-parallax
                    dark
                    src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
                  >
                    <v-row align="center" justify="center">
                      <v-col class="text-center" cols="12">
                        <h1 class="text-h4 font-weight-thin mb-4">Vuetify</h1>
                        <h4 class="subheading">
                          Build your application today!
                        </h4>
                      </v-col>
                    </v-row>
                  </v-parallax> -->
                  <!-- <v-img
                    max-height="210"
                    cover
                    class="rounded-xl feature"
                    :src="`https://az2-medias.s3.ap-south-1.amazonaws.com/infakt/contents/featured-images/111/photo1687420509.jpg`"
                  ></v-img> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Recycling the dead</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4">
                  <span class="grey--text">Subjects Covered</span>
                  <br />
                  <span class="black--text">Civics</span>
                </v-col>
                <v-col md="4">
                  <span class="grey--text">Reading</span>
                  <br />
                  <span class="black--text">8 mins</span>
                </v-col>
                <v-col md="4">
                  <span class="grey--text">Written by</span>
                  <br />
                  <span class="black--text">Kathiann Kowalski</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4">
                  <span class="grey--text">Published</span>
                  <br />
                  <span class="black--text">19-10-2023</span>
                </v-col>
                <v-col md="4">
                  <span class="grey--text">Word Count</span>
                  <br />
                  <span class="black--text">1009</span>
                </v-col>
                <v-col md="4">
                  <span class="grey--text">Article</span>
                  <br />
                  <span class="black--text">111</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p>
                    All living things die. All those dead things will eventually
                    rot. But that’s not the end of it. What rots will wind up
                    becoming part of something else. Death marks the end of an
                    old life. But the decomposition provides material for new
                    life. When any organism dies, fungi and bacteria break it
                    down. In other words, they decompose things. Some
                    decomposers live in leaves. Some hang out in the guts of
                    dead animals. Soil contains thousands of types of fungi and
                    bacteria that decompose things. Mushrooms and other
                    multi-celled fungi can also help in decay.
                  </p>
                  <br />
                  <p>
                    So can insects, and worms. Rotting can be yucky and
                    disgusting. Still, it is extremely important. It benefits
                    farmers. It preserves forest health. It even helps make
                    biofuels.
                  </p>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-col>
        </v-row>
        <v-row class="px-4" style="border-top: inset 1px">
          <v-col sm="2" v-if="current_step > 1">
            <v-btn
              text
              outlined
              @click="current_step = parseInt(current_step) - 1"
            >
              Back
            </v-btn>
          </v-col>
          <v-col align="right" class="px-9">
            <template v-if="current_step < 7">
              <v-btn text class="mr-4"> Cancel </v-btn>
              <v-btn outlined> Save in Draft </v-btn>
              <v-btn
                color="#0739A6"
                class="white--text ml-4"
                @click="current_step = parseInt(current_step) + 1"
              >
                Next
              </v-btn>
            </template>
            <template v-else>
              <v-btn text outlined> Save in Draft </v-btn>
              <v-btn
                color="black"
                class="white--text ml-2"
                @click="current_step = 7"
              >
                Save & Send to approve
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-stepper>
    </v-container>
    <!-- Image Warning popups -->
    <v-dialog v-model="warningImageLoader" width="600">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: red; font-size: 90px; width: 200px !important"
              >
                mdi-file-image-remove
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">OOPS!</h1>
                    <br />
                    <h3>
                      Image dimension is not matching with our standard
                      dimension. The image dimension should be multiple of 690 x
                      390
                    </h3>
                    <br />
                    <v-btn color="success" @click="warningImageLoader = false">
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Image Warning popups -->
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
import VueTextToSpeech1 from "@/components/VueTextToSpeech1.vue";

export default {
  components: { backbutton, Loader, VueTextToSpeech1 },
  name: "ContentCreateV3Page",
  data() {
    return {
      current_step: 3,
      image: {
        size: "",
        height: "",
        width: "",
      },
      KeywordItems: [],
      imageLoader: false,
      warningImageLoader: false,
      imageErrors: "",
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      form: {
        name: "",
        created_by_id: "",
        toi_article_id: "",
        et_article_id: "",
        space_article_id: "",
        live_science_article_id: "",
        is_active: true,
        specific_to: "PUBLIC",
        keywords: "",
        subjects: [],
        content_subjects: [],
        content_medias: [],
        content_descriptions: [],
        categories: [],
        content_categories: [],
        grades: [],
        content_grades: [],
        boards: [],
        content_boards: [],
        info_boards: [],
        content_info_boards: [],
        schools: [],
        content_schools: [],
      },
      contentTypes: ["ARTICLE", "INFOGRAPHIC", "VIDEO"],
      schoolTypeItems: ["FREE", "PAID"],
      specificItems: ["PUBLIC", "SCHOOL"],
      subjects: [],
      subjectItems: [],
      categoryItems: [],
      gradeItems: [],
      boardItems: [],
      schoolItems: [],
      article: [],
      isDeleteDescriptionDialogs: [],
      isDeleteDetailDialogs: [],
      isLoading: false,
      isSearching: false,
      isLinkEpaperDialog: false,
      isViewEpaperDialog: false,
      search_article_keyword: "",
      articles: [],
      tab: "tab-1",
      tabs: [],
      image_url: "",
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {
            displaySize: true,
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
      },
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    getKeyword() {
      console.log(this.form.keywords);
    },
    onFileChange() {
      this.warningImageLoader = false;
      this.file = this.$refs.file?.files[0];
      this.image.size = this.file.size;
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          let divWidth = Math.round(this.image.width / 690);
          let divHeight = Math.round(this.image.height / 390);

          let intoWidth = 690 * divWidth;
          let intoHeight = 390 * divHeight;

          if (
            intoWidth != this.image.width &&
            intoHeight != this.image.height
          ) {
            this.imageErrors =
              "Image dimension ( " +
              this.image.width +
              " x " +
              this.image.height +
              ") which is not matching with our standard dimension. The image dimension should be multiple of 690 x 390.";
            this.imageLoader = true;
            this.warningImageLoader = true;
          } else {
            this.imageLoader = false;
            this.image_url = URL.createObjectURL(this.file);
            this.imageErrors = "";
          }

          if (this.errors.length === 0) {
            this.success = "Image validated successfully";
          }
          // alert(img.width + " " + img.height);
        };
        img.src = evt.target.result;
      };

      reader.onerror = (evt) => {
        console.error(evt);
      };
      // this.image_url = URL.createObjectURL(this.file);
    },
    async getMasters() {
      if (this.$route.query.toiEpaperId) {
        let toiEpaperId = this.$route.query.toiEpaperId;
        let toi_article = await axios.get(`/toi_articles/${toiEpaperId}`);
        this.article = toi_article.data.data;
        this.form.toi_article_id = toiEpaperId;
      }
      if (this.$route.query.etEpaperId) {
        let etEpaperId = this.$route.query.etEpaperId;
        let et_article = await axios.get(`/et_articles/${etEpaperId}`);
        this.article = et_article.data.data;
        this.form.et_article_id = etEpaperId;
      }
      if (this.$route.query.spaceEpaperId) {
        let spaceEpaperId = this.$route.query.spaceEpaperId;
        let space_article = await axios.get(`/space_articles/${spaceEpaperId}`);
        this.article = space_article.data.data;
        this.form.space_article_id = spaceEpaperId;
      }
      if (this.$route.query.live_scienceEpaperId) {
        let live_scienceEpaperId = this.$route.query.live_scienceEpaperId;
        let live_science_article = await axios.get(
          `/live_science_articles/${live_scienceEpaperId}`
        );
        this.article = live_science_article.data.data;
        this.form.live_science_article_id = live_scienceEpaperId;
      }
      if (this.$route.query.etEpaperId) {
        let etEpaperId = this.$route.query.etEpaperId;
        let et_article = await axios.get(`/et_articles/${etEpaperId}`);
        this.article = et_article.data.data;
        this.form.et_article_id = etEpaperId;
      }
      if (this.article) {
        this.form.content_name = this.article.headline
          ? this.article.headline
          : this.article.title;
        this.form.original_content = this.article.content;
      }
      let masters = await axios.get("/contents/masters");
      masters = masters.data;
      masters.schools.forEach((school) => {
        this.schoolItems.push({
          id: school.id,
          text: school.name,
          value: school.id,
        });
      });
      masters.categories.forEach((category) => {
        this.categoryItems.push({
          id: category.id,
          text: category.name,
          value: category.id,
        });
      });
      masters.subjects.forEach((subject) => {
        this.subjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.name,
        });
      });
      masters.boards.forEach((board) => {
        this.boardItems.push({
          id: board.id,
          text: board.name,
          value: board.id,
          learning_outcome: "",
          grades: [],
          subjects: [],
        });
        this.form.content_info_boards.push({
          board_id: board.id,
          text: board.name,
          content_info_board_details: [],
        });
      });
      masters.grades.forEach((grade) => {
        this.gradeItems.push({
          id: grade.id,
          text: grade.name,
          value: grade.name,
        });
      });
    },
    async save(type) {
      try {
        this.isLoading = true;
        if (this.imageLoader == false) {
          this.form.created_by_id = this.user.id;
          this.form.categories.forEach((category_id) => {
            let content_category = {
              category_id: category_id,
            };
            this.form.content_categories.push(content_category);
          });

          this.form.boards.forEach((board_id) => {
            let content_board = {
              board_id: board_id,
            };
            this.form.content_boards.push(content_board);
          });
          this.form.schools.forEach((company_id) => {
            let content_school = {
              company_id: company_id,
            };
            this.form.content_schools.push(content_school);
          });
          this.form.subjects.forEach((subject_id) => {
            let content_subject = {
              subject_id: subject_id,
            };
            this.form.content_subjects.push(content_subject);
          });
          if (type == "draft") {
            this.form.is_draft = true;
          }
          if (type == "approval") {
            this.form.is_draft = false;
          }
          this.form.content_categories = [
            ...new Set(this.form.content_categories),
          ];
          this.form.content_boards = [...new Set(this.form.content_boards)];
          this.form.content_schools = [...new Set(this.form.content_schools)];
          this.form.content_subjects = [...new Set(this.form.content_subjects)];

          let content = await axios.post(`/contents`, this.form);
          this.content = content.data.data;
          this.handleFileUpload();
          this.isLoading = false;
          this.$router.push("/contents");
        } else {
          this.warningImageLoader = true;
          this.isLoading = false;
        }
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },

    CreateVariants() {
      // Create Questions row
      this.form.content_descriptions = [];
      for (let index = 0; index < this.form.no_of_variants; index++) {
        let content_description = {
          level: "",
          title: "",
          description: "",
        };
        this.form.content_descriptions.push(content_description);
      }
    },

    Add_Empty_Description() {
      let content_description = {
        level: "",
        title: "",
        description: "",
      };
      this.form.content_descriptions.push(content_description);
    },
    deleteDescription(content_description) {
      this.form.content_descriptions.splice(
        this.form.content_descriptions.indexOf(content_description),
        1
      );
      this.closeDescriptionDialogs();
    },
    closeDescriptionDialogs() {
      this.isDeleteDescriptionDialogs = [];
    },
    Add_Empty_Detail(parent) {
      let content_info_board_detail = {
        grade_id: "",
        subject_id: "",
        chapter: "",
        learning_outcome: "",
      };
      parent.content_info_board_details.push(content_info_board_detail);
    },
    deleteDetail(parent, content_info_board_detail) {
      parent.content_info_board_details.splice(
        parent.content_info_board_details.indexOf(content_info_board_detail),
        1
      );
      this.closeDetailDialogs();
    },
    closeDetailDialogs() {
      this.isDeleteDetailDialogs = [];
    },
    async handleFileUpload() {
      this.isLoading = true;
      let attachment = this.$refs.file?.files[0];
      const contentid = this.content.id;
      let formData = new FormData();
      formData.append("contentid", contentid);
      formData.append("featuredimagepath", attachment);
      await axios
        .post("upload_content_featured_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      if (this.career_request) {
        this.success = true;
        this.form = [];
      }
      this.isLoading = false;
      // if (this.career_request) {
      //   this.success = true;
      //   this.form = [];
      // }
      // this.getData();
    },
    async searchEpapers() {
      this.isSearching = true;
      let query = "search_keyword=" + this.search_article_keyword;
      let articles = await axios.get(
        `/contents/search_mother_articles?${query}`
      );
      this.articles = articles.data.data;
      this.isSearching = false;
    },
    async linkEpaper(a) {
      if (a.toi_xml_id) {
        //TOI
        this.form.toi_article_id = a.id;
      } else if (a.space_xml_id) {
        //Space
        this.form.space_article_id = a.id;
      } else if (a.live_science_xml_id) {
        //Live Science
        this.form.live_science_article_id = a.id;
      } else {
        //ET
        this.form.et_article_id = a.id;
      }
      this.article = this.articles.find((article) => article.id == a.id);
      this.form.content_name = this.article.headline
        ? this.article.headline
        : this.article.title;
      this.form.original_content = this.article.content;
      this.closeLinkEpaperDialog();
      this.closeViewEpaperDialog();
    },
    closeLinkEpaperDialog() {
      this.isLinkEpaperDialog = false;
    },
    closeViewEpaperDialog() {
      this.isViewEpaperDialog = false;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.v-parallax__image {
  transform: translate(-50%, 130px) !important;
}
</style>
<style scoped>
.v-text-field {
  border-radius: 8px 8px 8px 8px !important;
}
.label {
  font-family: Montserrat !important;
}
@font-face {
  font-family: "Montserrat";
}
.custom-selected-item-class {
  color: white !important;
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: -21px 5px -16px 0px !important;
  padding: 0px 10px 16px 0px !important;
  border-left: none !important;
}
.vD {
  margin-left: 34px !important;
  height: 17px !important;
  border-width: 0.1em thin !important;
  display: unset !important;
}
.newButton {
  text-transform: initial !important;
  border-radius: 8px;
}
</style>
