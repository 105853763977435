<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Add a Section
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name"
                prepend-inner-icon="mdi-account-school"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-switch
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save">
            Save Section
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { backbutton, Loader },
  data() {
    return {
      form: {
        name: "",
        is_active: true,
        is_deleted: false,
      },
      isLoading: false,
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        let standard = await axios.get(
          `standards/${this.$route.params.standardId}`
        );
        this.standard = standard.data.data;
        this.form.standard_id = this.standard.id;
        this.form.board_id = this.standard.board_id;
        let section = await axios.post(
          `/standards/${this.$route.params.standardId}/sections`,
          this.form
        );
        this.section = section.data.data;
        this.isLoading = false;
        this.$router.push(
          `/standards/${this.$route.params.standardId}/sections`
        );
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
