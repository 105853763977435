<template>
  <v-main>
    <v-container fluid>
      <SaSingleSchoolsData :schoolId="company.id"></SaSingleSchoolsData>
      <br />
      <!-- Latest Onboarded Teachers -->
      <v-row v-if="teachers.length || isTeacherLoading == true">
        <v-col sm="6" md="6" lg="8">
          <div
            class="text-h5 font-weight-bold"
            style="min-width: 120px"
            v-text="'Latest Onboarded Teachers'"
          ></div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col sm="6" md="6" lg="4" align="right">
          <v-btn color="primary" to="/teachers"> View More</v-btn>
        </v-col>
      </v-row>
      <loader v-if="isTeacherLoading"></loader>
      <template v-else>
        <v-row>
          <v-col sm="3" v-for="teacher in teachers" :key="teacher.name">
            <v-card elevation="6" shaped>
              <v-col>
                <v-img
                  v-if="teacher.gender == true"
                  height="200"
                  contain
                  :src="require('@/assets/imgs/teacher-male.png')"
                ></v-img>
                <v-img
                  v-if="teacher.gender == false"
                  height="200"
                  contain
                  :src="require('@/assets/imgs/teacher-female.png')"
                ></v-img>
              </v-col>
              <!-- <v-img
                height="150"
                src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
              ></v-img> -->

              <v-card-title>
                <div class="wrap">{{ teacher.name }}</div>
              </v-card-title>

              <v-card-text>
                <div class="wrap">{{ teacher.email }}</div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                <v-chip-group
                  active-class="deep-purple accent-4 white--text"
                  column
                >
                  <div v-if="teacher.gender == true">
                    <v-chip dark color="blue lighten-2">
                      <v-icon> mdi-gender-male </v-icon>
                      MALE</v-chip
                    >
                  </div>
                  <div v-else>
                    <v-chip dark color="red lighten-2">
                      <v-icon> mdi-gender-female </v-icon>
                      FEMALE</v-chip
                    >
                  </div>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </template>
      <!-- Latest Onboarded Students -->
      <br />
      <v-row v-if="students.length || isStudentLoading == true">
        <v-col sm="6" md="6" lg="8">
          <div
            class="text-h5 font-weight-bold"
            style="min-width: 120px"
            v-text="'Latest Onboarded Students'"
          ></div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col sm="6" md="6" lg="4" align="right">
          <v-btn color="primary" to="/students"> View More</v-btn>
        </v-col>
      </v-row>
      <loader v-if="isStudentLoading"></loader>
      <v-row>
        <v-col sm="3" v-for="student in students" :key="student.name">
          <v-card elevation="6" shaped>
            <v-col>
              <v-img
                v-if="student.gender == true"
                height="200"
                contain
                :src="require('@/assets/imgs/student-boy.png')"
              ></v-img>
              <v-img
                v-if="student.gender == false"
                height="200"
                contain
                :src="require('@/assets/imgs/student-girl.png')"
              ></v-img>
            </v-col>
            <!-- <v-img
              height="150"
              src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
            ></v-img> -->

            <v-card-title>
              <div class="wrap">
                {{ student.name }}
              </div></v-card-title
            >

            <v-card-text>
              <div class="wrap">{{ student.email }}</div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
              <v-chip-group
                active-class="deep-purple accent-4 white--text"
                column
              >
                <div v-if="student.gender == true">
                  <v-chip dark color="blue lighten-2">
                    <v-icon> mdi-gender-male </v-icon>
                    MALE</v-chip
                  >
                </div>
                <div v-else>
                  <v-chip dark color="red lighten-2">
                    <v-icon> mdi-gender-female </v-icon>
                    FEMALE</v-chip
                  >
                </div>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import SaSingleSchoolsData from "./superadmin-dashboard/single_school_data";
import Loader from "@/components/loader.vue";

export default {
  data() {
    return {
      isTeacherLoading: false,
      isStudentLoading: false,
      form: {},
      teachers: [],
      students: [],
    };
  },
  components: {
    SaSingleSchoolsData,
    Loader,
  },
  watch: {
    company: "getData",
  },

  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isTeacherLoading = true;
      this.isStudentLoading = true;
      this.teachers = [];
      this.students = [];
      let teachers = await axios.get(`/users?role_id=3&limit=4`);
      this.teachers = teachers.data.data;
      this.isTeacherLoading = false;
      let students = await axios.get(`/users?role_id=5&limit=4`);
      this.students = students.data.data;
      this.isStudentLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
