<template>
  <v-main class="white">
    <v-row
      :class="{
        'py-12': $vuetify.breakpoint.mdAndUp,
        'py-5': $vuetify.breakpoint.smAndDown,
      }"
    >
      <!-- <v-row class="py-12"> -->
      <v-col
        align="center"
        :class="{
          'mt-4': $vuetify.breakpoint.mdAndUp,
          'mt-2': $vuetify.breakpoint.smAndDown,
        }"
      >
        <!-- <v-col align="center" class="mt-4"> -->
        <span class="text-h3 font-weight-bold myFont"> Our Advantages </span>
      </v-col>
    </v-row>

    <v-col align="center" v-if="$vuetify.breakpoint.mdAndDown">
      <v-btn
        color="primary"
        :class="{
          'mx-11 myFont font-weight-bold': $vuetify.breakpoint.mdAndUp,
          'mx-2 myFont font-weight-bold': $vuetify.breakpoint.smAndDown,
        }"
        rounded
        dark
        >For Student</v-btn
      >
      <v-btn
        color="primary"
        :class="{
          'mx-11 myFont font-weight-bold': $vuetify.breakpoint.mdAndUp,
          'mx-5 myFont font-weight-bold': $vuetify.breakpoint.smAndDown,
        }"
        rounded
        dark
        >For Teacher</v-btn
      >
    </v-col>
    <v-col align="center" v-else>
      <v-btn
        color="primary"
        class="mx-11 myFont font-weight-bold"
        rounded
        large
        dark
        >For Student</v-btn
      >
      <v-btn
        color="primary"
        class="mx-11 myFont font-weight-bold"
        rounded
        large
        dark
        >For Teacher</v-btn
      >
    </v-col>
    <section>
      <v-row
        :class="{
          'py-16 pl-5 ml-16': $vuetify.breakpoint.mdAndUp,
          'py-6 pl-2 ml-2': $vuetify.breakpoint.smAndDown,
        }"
      >
        <!-- <v-row class="py-16 pl-5 ml-16"> -->
        <!-- <v-col></v-col> -->
        <v-col
          md="6"
          :class="{
            'pl-9 my-auto grey lighten-4': $vuetify.breakpoint.mdAndUp,
            'pl-3 my-auto grey lighten-4': $vuetify.breakpoint.smAndDown,
          }"
        >
          <!-- <v-col md="6" class="pl-9 my-auto grey lighten-4"> -->
          <h1
            :class="{
              'text-h4 font-weight-bold myFont mt-15':
                $vuetify.breakpoint.mdAndUp,
              'text-h5 font-weight-bold myFont mt-5':
                $vuetify.breakpoint.smAndDown,
            }"
          >
            <!-- <h1 class="text-h4 font-weight-bold myFont mt-15"> -->
            Our goal is to supercharge
          </h1>
          <h1
            :class="{
              'text-h4 font-weight-bold myFont': $vuetify.breakpoint.mdAndUp,
              'text-h5 font-weight-bold myFont': $vuetify.breakpoint.smAndDown,
            }"
          >
            <!-- <h1 class="text-h4 font-weight-bold myFont"> -->
            cognitive development for our
          </h1>
          <h1
            :class="{
              'text-h4 font-weight-bold myFont mb-2':
                $vuetify.breakpoint.mdAndUp,
              'text-h5 font-weight-bold myFont mb-2':
                $vuetify.breakpoint.smAndDown,
            }"
          >
            fellow students
          </h1>
          <!-- <h1 class="text-h4 font-weight-bold myFont mb-2">fellow students</h1> -->
          <hr />
          <p
            v-if="$vuetify.breakpoint.mdAndDown"
            class="mt-7 myFont"
            style="direction: ltl; font-size: 16px; color: black"
          >
            They will develop the skills that will better prepare them for tests
            and exams. Content will be engaging and interactive thus creating a
            natural drive for students to become readers. Students will be
            abreast with topics of local and global importance. And along the
            way, not only are their learning outcomes met, but they also
            discover topics of interest that resonate with them.
          </p>
          <p
            v-else
            class="mt-7 myFont"
            style="direction: ltl; font-size: 19px; color: black"
          >
            They will develop the skills that will better prepare them for tests
            and exams. Content will be engaging and interactive thus creating a
            natural drive for students to become readers. Students will be
            abreast with topics of local and global importance. And along the
            way, not only are their learning outcomes met, but they also
            discover topics of interest that resonate with them.
          </p>
          <p
            v-if="$vuetify.breakpoint.mdAndDown"
            class="mt-5 myFont"
            style="direction: ltl; font-size: 16px"
          >
            Learning is interdisciplinary in nature and will connect to multiple
            subjects across different themes. A foundation of clear
            understanding will be built through background knowledge which is
            key to increasing reading comprehension and filling any learning
            gaps. Academics will become fun when we spark student curiosity and
            gamify their learning experience. We offer content that can be
            consumed efficiently and quickly because we know that in this
            digital age, the average student's attention span is limited to only
            a few minutes.
          </p>
          <p v-else class="mt-5 myFont" style="direction: ltl; font-size: 19px">
            Learning is interdisciplinary in nature and will connect to multiple
            subjects across different themes. A foundation of clear
            understanding will be built through background knowledge which is
            key to increasing reading comprehension and filling any learning
            gaps. Academics will become fun when we spark student curiosity and
            gamify their learning experience. We offer content that can be
            consumed efficiently and quickly because we know that in this
            digital age, the average student's attention span is limited to only
            a few minutes.
          </p>
        </v-col>

        <v-col
          md="6"
          align="center"
          :class="{
            'pr-12 grey lighten-4': $vuetify.breakpoint.mdAndUp,
            'pr-15 grey lighten-4': $vuetify.breakpoint.smAndDown,
          }"
        >
          <!-- <v-col md="6" align="center" class="pr-12 grey lighten-4"> -->
          <v-col
            :class="{
              'mt-16 pt-16': $vuetify.breakpoint.mdAndUp,
              'mt-1 pt-1': $vuetify.breakpoint.smAndDown,
            }"
          >
            <!-- <v-col class="mt-16 pt-16"> -->
            <v-img
              v-if="$vuetify.breakpoint.mdAndDown"
              height="280"
              width="272"
              src="../assets/advantage-1.png"
            ></v-img>
            <v-img
              v-else
              height="371"
              width="339"
              src="../assets/advantage-1.png"
            ></v-img>
          </v-col>
          <br />
          <br />
        </v-col>
      </v-row>
    </section>

    <section v-if="$vuetify.breakpoint.mdAndDown">
      <v-row>
        <v-col md="5" class="pr-8 mt-4 ml-5">
          <h1
            :class="{
              'text-h4 font-weight-bold myFont': $vuetify.breakpoint.mdAndUp,
              'text-h5 font-weight-bold myFont': $vuetify.breakpoint.smAndDown,
            }"
          >
            <!-- <h1 class="text-h4 font-weight-bold myFont"> -->
            We understand that being a teacher
          </h1>
          <h1
            :class="{
              'text-h4 font-weight-bold myFont mb-4':
                $vuetify.breakpoint.mdAndUp,
              'text-h5 font-weight-bold myFont mb-4':
                $vuetify.breakpoint.smAndDown,
            }"
          >
            <!-- <h1 class="text-h4 font-weight-bold myFont mb-4"> -->
            can be fulfilling yet tiring
          </h1>

          <hr />
          <p
            v-if="$vuetify.breakpoint.mdAndDown"
            class="mt-4 myFont"
            style="font-size: 16px"
          >
            We intend to reduce the burdens that come with planning teaching
            activities but still provide you with the flexibility to tailor-make
            plans when needed. We frequently add new material to our platform,
            which has been specially curated around the curriculum being taught
            to students. You can expect to find a wide range of assessments
            designed around the content that will make for a more knowledgeable
            class of students. You can use our student tasks just the way they
            are or modify them to fit your teaching needs!
          </p>
          <p v-else class="mt-4 myFont" style="font-size: 19px">
            We intend to reduce the burdens that come with planning teaching
            activities but still provide you with the flexibility to tailor-make
            plans when needed. We frequently add new material to our platform,
            which has been specially curated around the curriculum being taught
            to students. You can expect to find a wide range of assessments
            designed around the content that will make for a more knowledgeable
            class of students. You can use our student tasks just the way they
            are or modify them to fit your teaching needs!
          </p>
          <p
            v-if="$vuetify.breakpoint.mdAndDown"
            class="mt-4 myFont"
            style="font-size: 16px"
          >
            Teachers can access their roster of students and their respective
            data in an organised manner enabling easy analysis of students'
            growth. We have ensured that we align our product to the new
            education policy and provide an ed-tech platform that is ready for
            the needs of today but, more importantly, the future.
          </p>
          <p v-else class="mt-4 myFont" style="font-size: 19px">
            Teachers can access their roster of students and their respective
            data in an organised manner enabling easy analysis of students'
            growth. We have ensured that we align our product to the new
            education policy and provide an ed-tech platform that is ready for
            the needs of today but, more importantly, the future.
          </p>
        </v-col>
        <v-col
          md="5"
          align="center"
          :class="{
            'ml-16 pl-16 pr-12 pt-16': $vuetify.breakpoint.mdAndUp,
            'ml-6 pl-6 pr-3 ': $vuetify.breakpoint.smAndDown,
          }"
        >
          <!-- <v-col md="5" align="center" class="ml-16 pl-16 pr-12 pt-16"> -->
          <v-col
            :class="{
              'pt-10': $vuetify.breakpoint.mdAndUp,
              'pt-5': $vuetify.breakpoint.smAndDown,
            }"
          >
            <!-- <v-col class="pt-10"> -->
            <v-img
              v-if="$vuetify.breakpoint.mdAndDown"
              height="245"
              width="290"
              src="../assets/advantage-2.png"
            ></v-img>
            <v-img
              v-else
              height="313"
              width="384"
              src="../assets/advantage-2.png"
            ></v-img>
          </v-col>
        </v-col>
        <v-col class="px-3"></v-col>
      </v-row>
      <br />
    </section>
    <section v-else>
      <v-row>
        <v-col md="5" align="center" class="ml-16 pl-16 pr-12 pt-16">
          <v-col class="pt-10">
            <v-img
              height="313"
              width="384"
              src="../assets/advantage-2.png"
            ></v-img>
          </v-col>
        </v-col>
        <v-col md="5" class="pr-1 mt-4 ml-5">
          <h1 class="text-h4 font-weight-bold myFont">
            We understand that being a teacher
          </h1>
          <h1 class="text-h4 font-weight-bold myFont mb-4">
            can be fulfilling yet tiring
          </h1>

          <hr />
          <p class="mt-4 myFont" style="font-size: 19px">
            We intend to reduce the burdens that come with planning teaching
            activities but still provide you with the flexibility to tailor-make
            plans when needed. We frequently add new material to our platform,
            which has been specially curated around the curriculum being taught
            to students. You can expect to find a wide range of assessments
            designed around the content that will make for a more knowledgeable
            class of students. You can use our student tasks just the way they
            are or modify them to fit your teaching needs!
          </p>
          <p class="mt-4 myFont" style="font-size: 19px">
            Teachers can access their roster of students and their respective
            data in an organised manner enabling easy analysis of students'
            growth. We have ensured that we align our product to the new
            education policy and provide an ed-tech platform that is ready for
            the needs of today but, more importantly, the future.
          </p>
        </v-col>
        <v-col class="px-3"></v-col>
      </v-row>
      <br />
    </section>

    <!-- <section>
      <v-container>
        <v-col>
          <v-row>
            <v-col md="6">
              <center>
                <v-img src="../assets/advantage-2.png"></v-img></center
            ></v-col>
            <v-col md="6" class="my-auto">
              <h1 class="text-h4 font-weight-bold">
                We understand that being a teacher can be fulfilling yet tiring
              </h1>
              <hr />
              &nbsp;
              <p style="font-size: 20px">
                We intend to reduce the burdens that come with planning teaching
                activities but still provide you with the flexibility to
                tailor-make plans when needed. We frequently add new material to
                our platform, which has been specially curated around the
                curriculum being taught to students. You can expect to find a
                wide range of assessments designed around the content that will
                make for a more knowledgeable class of students. You can use our
                student tasks just the way they are or modify them to fit your
                teaching needs!
              </p>
              &nbsp;
              <p style="font-size: 20px">
                Teachers can access their roster of students and their
                respective data in an organised manner enabling easy analysis of
                students' growth. We have ensured that we align our product to
                the new education policy and provide an ed-tech platform that is
                ready for the needs of today but, more importantly, the future.
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </section> -->
    <!-- <section style="background: white" class="mt-3">
      <v-container>
        <v-row>
          <v-tabs v-model="tab" grow background-color="indigo" dark>
            <v-tab> FOR STUDENT </v-tab>
            <v-tab> FOR TEACHER </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <v-row>
                  <v-col md="6">
                    <center>
                      <v-img src="../assets/images/aboutus/new-student-1.jpeg">
                      </v-img>
                    </center>
                  </v-col>
                  <v-col md="6">
                    <v-card-text>
                      <h2 style="text-align: justify">
                        Our goal is to supercharge cognitive development for our
                        fellow students.
                      </h2>
                      <br />
                      <p style="font-size: 20px; text-align: justify">
                        Overtime, students will have enhanced analytical and
                        critical thinking skills. They will develop the skills
                        that will better prepare them for tests and exams.
                        Content will be engaging and interactive thus creating a
                        natural drive for students to become readers,
                        consequently boosting their vocabulary. Students will
                        not only stay abreast with topics of global importance
                        all while learning their curriculum but also discover
                        new interests and passions that resonate with them.
                      </p>
                      <br />
                      <p style="font-size: 20px; text-align: justify">
                        Learning will connect to multiple subjects across
                        different themes benefiting students in achieving
                        cross-curricular success. A foundation of clear
                        understanding will be built through background knowledge
                        which is key to increasing reading comprehension and
                        filling any learning gaps. Learning will become fun when
                        content is engaging and a student’s experience with the
                        platform is gamified. Short attention spans in this
                        digital age allow us to offer content that can be
                        consumed in the most efficient manner.
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-row>
                  <v-col md="6">
                    <v-card-text
                      ><h2 style="text-align: justify">
                        We understand that being a teacher can be fulfilling yet
                        tiring.
                      </h2>
                      <br />

                      <p style="font-size: 20px; text-align: justify">
                        We intend to reduce the burdens that come with lesson
                        planning but still provide you with the flexibility to
                        tailor-make plans when needed. We frequently add new
                        material to our platform, which has been specially
                        curated around the curriculum being taught to students.
                        You can expect to find a wide range of assessments
                        designed around the content that will make for a more
                        knowledgeable class of students.
                      </p>
                      <br />
                      <p style="font-size: 20px; text-align: justify">
                        Teachers can access their roster of students and their
                        respective data in an organized manner enabling easy
                        dashboard viewing of all students’ growth progress. We
                        have ensured that we align our product to the new
                        education policy and provide an ed-tech platform that is
                        ready for the needs of today but, more importantly, the
                        future.
                      </p>
                    </v-card-text>
                  </v-col>
                  <v-col md="6">
                    <center>
                      <v-img
                        height="500"
                        width="500"
                        src="../assets/images/aboutus/teacher.jpeg"
                      >
                      </v-img>
                    </center>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-container>
    </section> -->
    <br /><br />
    <Foot></Foot>
  </v-main>
</template>
<script>
import Foot from "../views/home/foot.vue";

export default {
  name: "Aboutus",
  components: {
    Foot,
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>
<style scoped>
.myFont {
  font-family: "SF PRO" !important;
  color: black;
}
</style>
