<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add a new career request
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                prepend-inner-icon="mdi-account"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.description"
                outlined
                label="Description *"
                prepend-inner-icon="mdi-order-bool-descending"
                :error-messages="errors.description"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.remarks"
                outlined
                label="Remarks"
                prepend-inner-icon="mdi-order-bool-descending-variant"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="subject"
                v-model="form.status"
                :items="statuses"
                label="Select status"
                prepend-icon="mdi-list-status"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save">
            Save New Career Request
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  name: "CareerRequestCreatePage",
  data() {
    return {
      form: {
        name: "",
        status: "PENDING",
        is_active: true,
      },
      statuses: ["PENDING", "SETTLED"],
      isLoading: false,
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/career_requests", this.form);
        this.isLoading = false;
        this.$router.push("/career-requests");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
