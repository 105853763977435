<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Submissions'"></span>
        </v-col>
      </v-row>
      <br />
      <loader v-if="isLoading"></loader>
      <!-- Assignment MetaData Card -->
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          <v-row>
            <v-col>
              {{ assignment.assignment_title }}
              &nbsp;
              <span v-if="assignment.duration">
                ({{ assignment.duration ? assignment.duration + " mins" : "" }})
              </span>
            </v-col>
            <v-col align="right">
              <span v-if="assignment.content_description_id"
                >{{ assignment.content_description.level }}L</span
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <p class="font-weight-bold"><u>Type : </u></p>
              {{ assignment.assignment_type }}
            </v-col>
            <v-col md="3">
              <p class="font-weight-bold"><u> Start Date </u></p>
              <!-- For Multiple Class -->
              <template v-if="IsMultipleDate(assignment, 'START_DATE') > 1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <b class="primary--text">Varies</b>
                    </span>
                  </template>
                  <span>
                    <template
                      v-for="(
                        assignmentClasscode, aaC
                      ) in assignment.assignment_classcodes"
                    >
                      <span :key="`assignmentClasscode_s${aaC}`">
                        {{
                          trim_classcode(
                            assignmentClasscode.classcode.classcode
                          )
                        }}</span
                      >
                      :
                      {{ assignmentClasscode.start_date | ddmmyyyy }}
                      <br :key="`assignmentClasscode${aaC}`" />
                    </template>
                  </span>
                </v-tooltip>
              </template>
              <!-- For Single Class -->
              <template v-else>
                {{ assignment.assignment_classcodes[0].start_date | ddmmyyyy }}
              </template>
            </v-col>
            <v-col md="3">
              <p class="font-weight-bold"><u> Due Date </u></p>
              <!-- For Multiple Class -->
              <template v-if="IsMultipleDate(assignment, 'END_DATE') > 1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <b class="primary--text">Varies</b>
                    </span>
                  </template>
                  <span>
                    <template
                      v-for="(
                        assignmentClasscode, aaC
                      ) in assignment.assignment_classcodes"
                    >
                      <span :key="`assignmentClasscode_s${aaC}`">
                        {{
                          trim_classcode(
                            assignmentClasscode.classcode.classcode
                          )
                        }}</span
                      >
                      :
                      {{ assignmentClasscode.end_date | ddmmyyyy }}
                      <br :key="`assignmentClasscode${aaC}`" />
                    </template>
                  </span>
                </v-tooltip>
              </template>
              <!-- For Single Class -->
              <template v-else>
                {{ assignment.assignment_classcodes[0].end_date | ddmmyyyy }}
              </template>
            </v-col>
            <v-col align="right">
              <p class="font-weight-bold">
                {{ assignment.maximum_marks }} Marks
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="font-weight-bold mb-0"><u>Student Instructions:</u></p>
              <p v-html="assignment.student_instructions"></p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <br />
      <!-- Category Type Assignemt -->
      <section
        v-if="
          assignment.category == 'ASSIGNMENT' ||
          assignment.category == 'PERSONALIZED ASSIGNMENT'
        "
      >
        <template v-if="assignment.assignment_classcodes">
          <v-tabs fixed-tabs background-color="primary" dark v-model="tab">
            <v-tab
              v-for="assignmentClasscode in assignment.assignment_classcodes"
              :href="`#tab-${assignmentClasscode.id}`"
              :key="`tab-${assignmentClasscode.id}`"
            >
              {{ trim_classcode(assignmentClasscode.classcode.classcode) }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="assignmentClasscode in assignment.assignment_classcodes"
              :value="`tab-${assignmentClasscode.id}`"
              :key="`tab-${assignmentClasscode.id}`"
            >
              <v-card flat>
                <v-card-text>
                  <!-- Inner Tabs -->
                  <v-tabs v-model="innerTab[assignmentClasscode.id]">
                    <v-tab :href="`#Submitted-${assignmentClasscode.id}`">
                      Submitted
                    </v-tab>
                    <v-tab :href="`#Pending-${assignmentClasscode.id}`">
                      <template v-if="isAssignmentOld()"> Overdue </template>
                      <template v-else> Pending </template>
                    </v-tab>
                    <v-tab :href="`#Extension-${assignmentClasscode.id}`">
                      Extension Request
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="innerTab[assignmentClasscode.id]">
                    <!-- Submitted -->
                    <v-tab-item
                      :key="`Submitted-${assignmentClasscode.id}`"
                      :value="`Submitted-${assignmentClasscode.id}`"
                    >
                      <v-simple-table fixed-header>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left sr_no_class">Sr No</th>
                              <th class="text-left">Name of Student</th>
                              <th class="text-left">Submission Date</th>
                              <th class="text-left">Is Result Generated</th>
                              <th class="text-left">Score</th>
                              <th class="text-left">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                userAssignment, uA
                              ) in assignmentClasscode.submitted_students"
                              :key="`userAssignment${uA}`"
                            >
                              <td>{{ uA + 1 }}</td>
                              <td>
                                {{ userAssignment.user.first_name }}
                                {{ userAssignment.user.last_name }}
                              </td>
                              <td>{{ userAssignment.submission_date }}</td>
                              <td>
                                {{
                                  userAssignment.result_declared ? "YES" : "NO"
                                }}
                              </td>
                              <td>
                                {{ userAssignment.score }} /
                                {{ assignment.maximum_marks }}
                              </td>
                              <td>
                                <v-btn
                                  small
                                  color="primary"
                                  dark
                                  v-if="!userAssignment.result_declared"
                                  :to="`/assignments/${assignment.id}/check-result/${userAssignment.id}?type=access`"
                                >
                                  ASSESS
                                </v-btn>
                                &nbsp;
                                <v-btn
                                  fab
                                  small
                                  color="primary"
                                  dark
                                  v-if="userAssignment.result_declared"
                                  :to="`/assignments/${assignment.id}/check-result/${userAssignment.id}`"
                                >
                                  <v-icon>mdi-arrow-right-thin</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-tab-item>
                    <!-- End Submitted -->
                    <!-- Pending -->
                    <v-tab-item
                      :key="`Pending-${assignmentClasscode.id}`"
                      :value="`Pending-${assignmentClasscode.id}`"
                    >
                      <v-simple-table fixed-header>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left sr_no_class">Sr No</th>
                              <th class="text-left">Name of Student</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                pendingStudent, pS
                              ) in assignmentClasscode.pending_students"
                              :key="`pendingStudent${pS}`"
                            >
                              <td>{{ pS + 1 }}</td>
                              <td>
                                {{ pendingStudent.first_name }}
                                {{ pendingStudent.last_name }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-tab-item>
                    <!-- End Pending -->
                    <!-- Extensions -->
                    <v-tab-item
                      :key="`Extension-${assignmentClasscode.id}`"
                      :value="`Extension-${assignmentClasscode.id}`"
                    >
                      <v-simple-table fixed-header>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left sr_no_class">Sr No</th>
                              <th class="text-left" style="min-width: 85px">
                                Name of Student
                              </th>
                              <th class="text-left" style="min-width: 115px">
                                Requested Extension Date
                              </th>
                              <th class="text-left" style="min-width: 130px">
                                Requested Extension Reason
                              </th>
                              <th class="text-left" style="min-width: 110px">
                                Status
                              </th>
                              <th class="text-left" style="min-width: 115px">
                                Approved Extension Date
                              </th>
                              <th class="text-left">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                assignmentExtension, aE
                              ) in assignmentClasscode.extension_students"
                              :key="`assignmentExtension${aE}`"
                            >
                              <td>{{ aE + 1 }}</td>
                              <td>
                                {{ assignmentExtension.user.first_name }}
                                {{ assignmentExtension.user.last_name }}
                              </td>
                              <td>
                                {{
                                  assignmentExtension.expected_extension_date
                                }}
                              </td>
                              <td>
                                {{ assignmentExtension.extension_reason }}
                              </td>
                              <td>
                                {{
                                  assignmentExtension.is_approved != 0
                                    ? assignmentExtension.is_approved == 1
                                      ? "APPROVED"
                                      : "DENIED"
                                    : "Pending"
                                }}
                              </td>
                              <td>
                                {{
                                  assignmentExtension.approved_extension_date
                                }}
                              </td>

                              <td>
                                <template
                                  v-if="assignmentExtension.is_approved == 0"
                                >
                                  <!-- Approve Dialog -->
                                  <v-btn
                                    small
                                    color="green"
                                    class="mr-5"
                                    dark
                                    @click="
                                      ApproveExtensionDialog(
                                        assignmentExtension
                                      )
                                    "
                                    >APPROVE</v-btn
                                  >
                                  <v-btn
                                    small
                                    color="red"
                                    dark
                                    @click="
                                      DeniedExtensionDialog(assignmentExtension)
                                    "
                                    >DENY</v-btn
                                  >
                                  <!-- Deny Dialog -->
                                </template>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-tab-item>
                    <!-- End Extensions -->
                  </v-tabs-items>
                  <!-- End Inner Tabs -->
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-if="assignment.user_id">
          <v-card flat>
            <v-card-text>
              <!-- Inner Tabs -->
              <v-tabs v-model="innerTab">
                <v-tab :href="`#Submitted-${assignment.id}`"> Submitted </v-tab>
                <v-tab :href="`#Pending-${assignment.id}`">
                  <template v-if="isAssignmentOld()"> Overdue </template>
                  <template v-else> Pending </template>
                </v-tab>
                <v-tab :href="`#Extension-${assignment.id}`">
                  Extension Request
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="innerTab">
                <!-- Submitted -->
                <v-tab-item
                  :key="`Submitted-${assignment.id}`"
                  :value="`Submitted-${assignment.id}`"
                >
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left sr_no_class">Sr No</th>
                          <th class="text-left">Name of Student</th>
                          <th class="text-left">Submission Date</th>
                          <th class="text-left">Is Result Generated</th>
                          <th class="text-left">Score</th>
                          <th class="text-left">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            userAssignment, uA
                          ) in assignment.user_students"
                          :key="`userAssignment${uA}`"
                        >
                          <td>{{ uA + 1 }}</td>
                          <td>
                            {{ userAssignment.user.first_name }}
                            {{ userAssignment.user.last_name }}
                          </td>
                          <td>{{ userAssignment.submission_date }}</td>
                          <td>
                            {{ userAssignment.result_declared ? "YES" : "NO" }}
                          </td>
                          <td>
                            {{ userAssignment.score }} /
                            {{ assignment.maximum_marks }}
                          </td>
                          <td>
                            <v-btn
                              small
                              color="primary"
                              dark
                              v-if="!userAssignment.result_declared"
                              :to="`/assignments/${assignment.id}/check-result/${userAssignment.id}?type=access`"
                            >
                              ASSESS
                            </v-btn>
                            &nbsp;
                            <v-btn
                              fab
                              small
                              color="primary"
                              dark
                              v-if="userAssignment.result_declared"
                              :to="`/assignments/${assignment.id}/check-result/${userAssignment.id}`"
                            >
                              <v-icon>mdi-arrow-right-thin</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
                <!-- End Submitted -->
                <!-- Pending -->
                <v-tab-item
                  :key="`Pending-${assignment.id}`"
                  :value="`Pending-${assignment.id}`"
                >
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left sr_no_class">Sr No</th>
                          <th class="text-left">Name of Student</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            pendingStudent, pS
                          ) in assignment.pending_students"
                          :key="`pendingStudent${pS}`"
                        >
                          <td>{{ pS + 1 }}</td>
                          <td>
                            {{ pendingStudent.first_name }}
                            {{ pendingStudent.last_name }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
                <!-- End Pending -->
                <!-- Extensions -->
                <v-tab-item
                  :key="`Extension-${assignment.id}`"
                  :value="`Extension-${assignment.id}`"
                >
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left sr_no_class">Sr No</th>
                          <th class="text-left" style="min-width: 85px">
                            Name of TSI
                          </th>
                          <th class="text-left" style="min-width: 115px">
                            Requested Extension Date
                          </th>
                          <th class="text-left" style="min-width: 130px">
                            Requested Extension Reason
                          </th>
                          <th class="text-left" style="min-width: 110px">
                            Status
                          </th>
                          <th class="text-left" style="min-width: 115px">
                            Approved Extension Date
                          </th>
                          <th class="text-left">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            assignmentExtension, aE
                          ) in assignment.extension_students"
                          :key="`assignmentExtension${aE}`"
                        >
                          <td>{{ aE + 1 }}</td>
                          <td>
                            {{ assignmentExtension.user.first_name }}
                            {{ assignmentExtension.user.last_name }}
                          </td>
                          <td>
                            {{ assignmentExtension.expected_extension_date }}
                          </td>
                          <td>
                            {{ assignmentExtension.extension_reason }}
                          </td>
                          <td>
                            {{
                              assignmentExtension.is_approved != 0
                                ? assignmentExtension.is_approved == 1
                                  ? "APPROVED"
                                  : "DENIED"
                                : "Pending"
                            }}
                          </td>
                          <td>
                            {{ assignmentExtension.approved_extension_date }}
                          </td>

                          <td>
                            <template
                              v-if="assignmentExtension.is_approved == 0"
                            >
                              <!-- Approve Dialog -->
                              <v-btn
                                small
                                color="green"
                                class="mr-5"
                                dark
                                @click="
                                  ApproveExtensionDialog(assignmentExtension)
                                "
                                >APPROVE</v-btn
                              >
                              <v-btn
                                small
                                color="red"
                                dark
                                @click="
                                  DeniedExtensionDialog(assignmentExtension)
                                "
                                >DENY</v-btn
                              >
                              <!-- Deny Dialog -->
                            </template>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
                <!-- End Extensions -->
              </v-tabs-items>
              <!-- End Inner Tabs -->
            </v-card-text>
          </v-card>
        </template>
      </section>
      <!--/ Category Type Assignment -->
      <!-- Category Type Competition -->
      <section v-else>
        <v-tabs fixed-tabs background-color="primary" dark v-model="tab">
          <v-tab> LeaderBoard </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Rank</th>
                        <th class="text-left">Name of Student</th>
                        <th class="text-left">Submission Date</th>
                        <th class="text-left">Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(student, s) in assignment.total_students"
                        :key="`student${s}`"
                        :style="RankColor(s)"
                      >
                        <td>
                          {{ s + 1 }}
                          <v-icon v-if="s == 0" large>mdi-police-badge</v-icon>
                          <v-icon v-if="s == 1">mdi-police-badge</v-icon>
                          <v-icon v-if="s == 2" small>mdi-police-badge</v-icon>
                        </td>
                        <td>
                          {{ student.first_name }}
                          {{ student.last_name }}
                        </td>
                        <td>
                          {{ student.submission_date | ddmmyyyy }}
                          {{ student.submission_date | HM }}
                        </td>
                        <td>
                          {{ student.score }} /
                          {{ assignment.maximum_marks }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </section>
    </v-container>
    <v-dialog v-model="isApproveDialog" max-width="700px">
      <v-card elevation="10">
        <v-card-title> Approve the extension request </v-card-title>
        <v-card-text>
          <v-menu
            v-model="approveExtensionDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="assignmentExtension.approved_extension_date"
                label="Approved Extension Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :error-messages="errors.approved_extension_date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="assignmentExtension.approved_extension_date"
              @change="approveExtensionDateMenu = false"
              :min="nowDate"
            ></v-date-picker>
          </v-menu>
          <loader v-if="isReplyExtensionLoading"></loader>
          <v-btn
            v-else
            color="primary"
            @click="closeReplyDialog(assignmentExtension, 'APPROVED')"
          >
            Approve
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Denied request Dialog -->
    <v-dialog v-model="isDenyDialog" max-width="700px">
      <v-card elevation="10">
        <v-card-title>
          Are you sure you want to deny the extension request?
        </v-card-title>
        <v-card-text>
          <loader v-if="isReplyExtensionLoading"></loader>
          <v-btn
            v-else
            color="primary"
            @click="closeReplyDialog(assignmentExtension, 'DENY')"
          >
            Deny
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End for Denied request Dialog -->
  </v-main>
</template>

<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
import moment from "moment";

export default {
  components: { backbutton, Loader },
  name: "AssignmentSubmissionsPage",
  data() {
    return {
      tab: "",
      innerTab: [],
      isApproveDialog: false,
      isDenyDialog: false,
      approveExtensionDateMenu: false,
      denyExtensionDateMenu: false,
      isReplyExtensionLoading: false,
      isLoading: false,
      assignmentExtension: {},
      assignment: {
        assignment_classcodes: [],
      },
      nowDate: new Date().toISOString().slice(0, 10),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let assignment = await axios.get(
        `/assignments/${this.$route.params.assignmentId}`
      );
      assignment = assignment.data.data;
      this.assignment = assignment;
      this.assignment.assignment_classcodes.forEach((aC) => {
        this.innerTab.push(aC.id);
      });
      console.log(this.innerTab);
      this.isLoading = false;
    },
    ApproveExtensionDialog(assignmentExtension) {
      this.assignmentExtension = assignmentExtension;
      this.isApproveDialog = true;
    },
    DeniedExtensionDialog(assignmentExtension) {
      this.assignmentExtension = assignmentExtension;
      this.isDenyDialog = true;
    },
    async closeReplyDialog(assignmentExtension, status) {
      try {
        this.isReplyExtensionLoading = true;
        let description = "";
        let type = "";
        if (status == "APPROVED") {
          description =
            "Your Assignment Extention request for Assignment [ <b>" +
            this.assignment.assignment_title +
            " </b>] has been <b>APPROVED</b> by " +
            this.user.name +
            " till <b>" +
            moment(assignmentExtension.approved_extension_date).format(
              "DD-MM-YYYY"
            ) +
            "</b>.";
          type = "EXTENSION APPROVED";
          assignmentExtension.is_approved = true;
        }
        if (status == "DENY") {
          description =
            "Your Assignment Extention request for Assignment [ <b>" +
            this.assignment.assignment_title +
            " </b>] has been <b>DENIED</b> by " +
            this.user.name +
            ".";
          type = "EXTENSION DENIED";
          assignmentExtension.is_approved = 2;
          assignmentExtension.approved_extension_date = "";
        }
        // Save Extension
        await axios
          .patch(
            `/assignment_extensions/${assignmentExtension.id}`,
            assignmentExtension
          )
          .then(async () => {
            this.isApproveDialog = false;
            this.isDenyDialog = false;
            // Save Notification Log
            let notification_data = {
              user_id: assignmentExtension.user_id,
              description: description,
              type: type,
              redirect_to: "assignments?tab=tab-2",
            };
            await axios.post("notifications", notification_data);
            this.isReplyExtensionLoading = false;
          })
          .catch(() => {
            this.isReplyExtensionLoading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    RankColor(rank) {
      let color = "background-color:";
      switch (rank) {
        case 0:
          color += "#f7c602";
          break;
        case 1:
          color += "#ffe578";
          break;
        case 2:
          color += "#fff6a6";
          break;

        default:
          break;
      }
      return color;
    },
    isAssignmentOld() {
      var response = false;
      let assignment = this.assignment;
      var currentDate = moment().format("YYYY-MM-DD");
      if (assignment.assignment_classcodes) {
        assignment.assignment_classcodes.forEach((aC) => {
          let endDate = moment(aC.end_date).format("YYYY-MM-DD");
          if (endDate < currentDate) response = true;
        });
      }
      return response;
    },
    trim_classcode(classcode) {
      let originalClasscode = classcode;
      let classcode_array = classcode.split("/");
      let standard_section = classcode_array[1];
      let subject_section = classcode_array[2];
      let trimmed_classcode = "";
      if (this.roleName == "STUDENT") {
        trimmed_classcode = subject_section;
      } else if (this.roleName == "TEACHER") {
        trimmed_classcode = standard_section + "/" + subject_section;
      } else {
        trimmed_classcode = originalClasscode;
      }
      return trimmed_classcode;
    },
    IsMultipleDate(assignment, type) {
      let start_date_array = [];
      let end_date_array = [];
      let classcodes = this.isMultipleClasscode(assignment);
      classcodes.forEach((ac) => {
        if (!start_date_array.includes(ac.start_date))
          start_date_array.push(ac.start_date);
        if (!end_date_array.includes(ac.end_date))
          end_date_array.push(ac.end_date);
      });
      if (type == "START_DATE") {
        return start_date_array.length;
      } else {
        return end_date_array.length;
      }
    },
    isMultipleClasscode(assignment) {
      let response = assignment.assignment_classcodes;
      if (
        assignment.assignment_classcodes.length > 1 &&
        this.roleName == "STUDENT"
      ) {
        let visibleClasscode = assignment.assignment_classcodes.filter((aC) => {
          return this.user.user_classcodes.some(
            (uC) => uC.classcode_id === aC.classcode_id
          );
        });
        response = visibleClasscode;
      }
      return response;
    },
  },
};
</script>
