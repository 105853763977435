<template>
  <section id="hero" class="white">
    <!-- Text  -->
    <!-- <iframe style="max-width:100%" src="https://wordwall.net/embed/play/17719/564/83634" width="500" height="380" frameborder="0" allowfullscreen></iframe> -->
    <!-- Text 2 -->

    <v-row no-gutters>
      <!-- <v-col > -->
      <!-- <v-row
        :class="{
          'mt-16': $vuetify.breakpoint.smAndDown,
          'mt-16': $vuetify.breakpoint.mdAndUp,

          'mt-8': $vuetify.breakpoint.mdAndDown,
        }"
        xs12
        sm6
      > -->
      <!-- <v-row class="mt-16"> -->
      <v-col
        :class="{
          'mt-10 mx-6': $vuetify.breakpoint.mdAndUp,
          'ml-1': $vuetify.breakpoint.smAndDown,
        }"
      >
        <v-row
          :class="{
            'ml-2': $vuetify.breakpoint.smAndDown,
            'mt-10 mx-6': $vuetify.breakpoint.mdAndUp,
            'ml-3': $vuetify.breakpoint.mdAndDown,
          }"
          no-gutters
        >
          <!-- <v-row class="mt-10 mx-6"> -->
          <v-col v-if="$vuetify.breakpoint.mdAndUp"> Photo</v-col>
          <v-col
            md="5"
            :class="{
              'my-16': $vuetify.breakpoint.smAndDown,
              'my-16': $vuetify.breakpoint.mdAndUp,
              'mt-14': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <!-- <v-col md="5" class="my-16"> -->
            <!-- <h1
              :class="{
                'text-h3 myFont font-weight-bold mb-3':
                  $vuetify.breakpoint.smAndDown,
                'text-h3 myFont font-weight-bold mb-3':
                  $vuetify.breakpoint.mdAndUp,
                'text-h3 myFont font-weight-bold mb-3':
                  $vuetify.breakpoint.mdAndDown,
              }"
              xs12
              sm6
            > -->
            <h1
              :class="{
                'text-h5 myFont font-weight-bold':
                  $vuetify.breakpoint.smAndDown,
                'text-h4 myFont font-weight-bold mb-3':
                  $vuetify.breakpoint.mdAndUp,
              }"
            >
              Learning Management System to Smoothly CREATE, MANAGE & GROW Your
              Business
            </h1>
            <!-- <h1 class="text-h3 myFont font-weight-bold mb-3">
              Exciting Content
            </h1> -->
            <h1
              :class="{
                'text-h4 myFont font-weight-bold':
                  $vuetify.breakpoint.smAndDown,
                'text-h3 myFont font-weight-bold mb-3':
                  $vuetify.breakpoint.mdAndUp,
              }"
            ></h1>
            <!-- <h1 class="text-h3 myFont font-weight-bold">
              Designed For Students
            </h1> -->
            <p
              :class="{
                'text-h6 myFont font-weight-bold text-darken-1 mt-5':
                  $vuetify.breakpoint.smAndDown,
                'text-h6 myFont font-weight-bold text-darken-1 mt-5':
                  $vuetify.breakpoint.mdAndUp,
                'subtitle-2 myFont font-weight-bold  mt-3':
                  $vuetify.breakpoint.mdAndDown,
              }"
            >
              Your Corporate Training Just Got More Comfortable With the Pousse
              LMS platform, your employees can now learn anywhere, anytime, and
              on any device
            </p>
            <!-- <p class="text-h6 myFont font-weight-bold text-darken-1">
              your school curriculum.
            </p> -->
            <v-btn
              v-if="$vuetify.breakpoint.mdAndDown"
              color="primary"
              class="rounded-xl mt-4"
              dark
              to="/about-us"
              v-show="heroTrigger"
            >
              <span
                class="font-weight-bold myFont"
                style="color: white !important"
              >
                View More
              </span>
            </v-btn>
            <v-btn
              v-else
              color="primary"
              class="rounded-xl mt-8"
              dark
              large
              to="/about-us"
              v-show="heroTrigger"
            >
              <span
                class="font-weight-bold myFont"
                style="color: white !important"
              >
                View More
              </span>
            </v-btn>
          </v-col>
          <!-- <v-col
            md="6"
            :class="{
              'mt-9': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <center v-if="$vuetify.breakpoint.mdAndDown">
              <iframe
                height="201"
                width="330"
                src="https://www.youtube.com/embed/Ff8Xt1yt1us?controls=0&modestbranding=1&rel=0"
                title="Infakt Introduction Video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </center>
            <iframe
              v-else
              height="366"
              width="600"
              src="https://www.youtube.com/embed/Ff8Xt1yt1us?controls=0&modestbranding=1&rel=0"
              title="Infakt Introduction Video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-col> -->
        </v-row>
      </v-col>
      <!-- <v-img
        height="800"
        width="100%"
        :src="require('../../assets/landing4.png')"
      >
        <v-theme-provider dark>
          <v-container fill-height fluid>
            <v-slide-y-transition>
              <v-row class="white--text mx-auto">
                <v-col md="1"></v-col>
                <v-col class="white--text" md="6" tag="h1">
                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2',
                    ]"
                    class="font-weight-bold myFont"
                    v-show="heroTrigger"
                  >
                    Exciting Content.
                    <br />
                    Designed For Students.
                  </span>

                  <br />
                  <br />

                  <span class="font-weight-light myFont" v-show="heroTrigger">
                    Authentic, engaging, real world content, aligned to your
                    school curriculum.
                  </span>

                  <br />
                  <br />

                  <v-btn
                    outlined
                    large
                    @click="$vuetify.goTo('#about-me')"
                    v-show="heroTrigger"
                  >
                    <span class="text--darken-1 font-weight-bold myFont">
                      View More
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-slide-y-transition>
          </v-container>
        </v-theme-provider>
      </v-img> -->
    </v-row>
    <br v-if="$vuetify.breakpoint.mdAndUp" />
    <br v-if="$vuetify.breakpoint.mdAndUp" />
    <br />
    <br />
    <br />
  </section>
</template>

<script>
export default {
  data() {
    return {
      heroTrigger: true,
    };
  },
  created() {
    document.getElementsByClassName("ytp-watermark")[0].style.display = "none";
  },
};
</script>

<style scoped>
.myFont {
  font-family: "SF PRO" !important;
  color: black;
}
</style>

<style>
#player.html5-video-player .ytp-watermark {
  display: "none !important";
}
</style>
