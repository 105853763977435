<template>
  <v-main>
    <v-container>
      <br />
      <v-row>
        <v-col sm="2" md="2" lg="2" class="pr-0" v-if="article.content_type == 'ARTICLE'">
          <v-autocomplete
            @input="onLevelChange"
            v-model="selectedLevel"
            :items="levels"
            label="Select Lexile"
            prepend-icon="mdi-calendar-text-outline"
          ></v-autocomplete>
        </v-col>
        <v-col sm="1" md="1" lg="1" class="pl-1" v-if="article.content_type == 'ARTICLE'">
          <v-btn class="mt-3" icon color="primary" @click="isHelpDialog = true">
            <v-icon size="20">mdi-help</v-icon>
          </v-btn>
        </v-col>
        <v-col sm="6" md="6" lg="6" v-if="roleName == 'TEACHER' || roleName == 'ADMIN'">
          <v-autocomplete
            ref="classcode"
            id="classcode"
            v-model="selectedHiddenClasscodes"
            :items="classcodes"
            label="Hide From Classcodes"
            prepend-icon="mdi-database-search"
            multiple
            hide-details
            small-chips
          ></v-autocomplete>
        </v-col>
        <v-col sm="3" md="3" lg="3" class="my-5">
          <v-btn
            class="mr-2"
            icon
            color="purple"
            @click="printPage"
            v-if="article.content_type != 'VIDEO'"
          >
            <v-icon size="34">mdi-printer</v-icon>
          </v-btn>
          <v-btn
            class="mr-2"
            icon
            color="primary"
            @click="isInfoBoxDialog = true"
          >
            <v-img
              height="34"
              width="10"
              :src="require('../../assets/logo-i.png')"
              contain
            ></v-img>
          </v-btn>
          <v-dialog v-model="isInfoBoxDialog" max-width="400px">
            <v-card elevation="2" class="rounded-tl-xl rounded-br-xl no-select">
              <v-card-title class="justify-center primary accent-1 white--text">
                Info Box
              </v-card-title>
              <v-card-text>
                <v-row v-if="isInfoBoardPresent()">
                  <v-tabs
                    background-color="blue-grey lighten-4"
                    class="mt-5"
                    color="primary"
                    fixed-tabs
                    height="35"
                    v-model="boardTab"
                  >
                    <!-- Board Tabs -->
                    <v-tab
                      v-for="info_board in article.content_info_boards?.filter(
                        (cib) => cib.content_info_board_details.length
                      )"
                      :key="`info_board_${info_board.id}`"
                    >
                      {{ info_board.board.name }}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="boardTab">
                    <!-- Board Tab Content -->
                    <v-tab-item
                      v-for="info_board in article.content_info_boards?.filter(
                        (cib) => cib.content_info_board_details.length
                      )"
                      :key="`info_board_${info_board.id}`"
                    >
                      <v-card flat>
                        <v-card-text>
                          <v-tabs
                            center-active
                            color="primary"
                            height="25"
                            next-icon="mdi-arrow-right-bold-box-outline"
                            prev-icon="mdi-arrow-left-bold-box-outline"
                            fixed-tabs
                          >
                            <!-- Grade Tabs -->
                            <v-tab
                              v-for="grade in info_board.grade_details.filter(
                                (g) => g.subjects.length
                              )"
                              :key="`grade_${info_board.id}_${grade.grade}`"
                            >
                              {{ grade.grade }}
                            </v-tab>
                            <!-- Grade Tab Content -->
                            <v-tab-item
                              v-for="grade in info_board.grade_details.filter(
                                (g) => g.subjects.length
                              )"
                              :key="`grade_${info_board.id}_${grade.grade}`"
                            >
                              <v-card flat style="min-width: 345px">
                                <v-card-text>
                                  <v-tabs
                                    background-color="blue-grey lighten-5"
                                    center-active
                                    height="35"
                                    next-icon="mdi-arrow-right-bold-box-outline"
                                    prev-icon="mdi-arrow-left-bold-box-outline"
                                    fixed-tabs
                                  >
                                    <!-- Subject Tabs -->
                                    <v-tab
                                      v-for="subject in grade.subjects"
                                      :key="`subject_${grade.grade}_${subject.id}`"
                                      style="min-width: 170"
                                    >
                                      {{ subject.subject }}
                                      <v-icon class="ml-1">{{
                                        subjectIcon(subject.subject)
                                      }}</v-icon>
                                    </v-tab>
                                    <!-- Subject Tab Content -->
                                    <v-tab-item
                                      v-for="subject in grade.subjects"
                                      :key="`subject_${grade.grade}_${subject.id}`"
                                      class="mt-3"
                                    >
                                      <v-row v-if="subject.chapter">
                                        <v-col>
                                          <b>Chapter:</b>
                                          <div v-html="subject.chapter"></div>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col>
                                          <b>Learning Outcome:</b>
                                          <div
                                            v-html="subject.learning_outcome"
                                          ></div>
                                        </v-col>
                                      </v-row>
                                    </v-tab-item>
                                  </v-tabs>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                          </v-tabs>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-row>
                <v-row v-else>
                  <v-col class="mt-5">
                    <h1 style="color: red">Oops!</h1>
                    <br />
                    <h3>
                      Sorry, Looks like there is no info added for this Article.
                    </h3>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
          <bookmark-dialog
            v-if="
              article.is_approved == 1 &&
              roleName != 'SUPER ADMIN' &&
              article.is_Enable_bookmark != false
            "
            :article="article"
          ></bookmark-dialog>
          <v-btn
            small
            class="ml-5"
            color="primary"
            dark
            @click="CheckHiddenClasscode"
            v-if="roleName == 'TEACHER' || roleName == 'ADMIN'"
            >UPDATE</v-btn
          >
        </v-col>
      </v-row>
      <!-- Success Dialog Box -->
      <v-dialog v-model="success" width="700">
        <v-card class="rounded-tl-xl rounded-br-xl no-select">
          <v-container>
            <v-row align="center">
              <v-col sm="3">
                <v-img
                  :src="require('../../assets/imgs/success.png')"
                  width="200"
                ></v-img>
              </v-col>
              <v-col sm="9">
                <v-card-title style="color: red"> Success !</v-card-title
                ><v-card-text>
                  <v-row>
                    <v-col>
                      <h3>
                        This article has been successfully added in your
                        library.
                      </h3>
                      <br />
                      <v-btn color="danger"> OK </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- /Success Dialog Box -->
      <!-- Data Saved Successfully  -->
      <v-dialog
        elevation="0"
        :scrim="false"
        fullscreen
        width="auto"
        v-model="isDataSaved"
      >
        <v-card
          elevation="0"
          tabindex="0"
          align="center"
          height="auto"
          class="make_transparent"
        >
          <v-container class="fill-height d-flex justify-center align-center">
            <v-card-text class="d-flex justify-center no-select">
              <v-img
                v-if="is_Show_loading"
                :src="require('../../assets/blue-loader.gif')"
                max-width="86"
              ></v-img>
              <v-img
                v-else
                :src="require('../../assets/success.png')"
                max-width="86"
              ></v-img>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Data Saved Successfully  -->
      <!-- Linked Copied Dialog Box -->
      <v-dialog v-model="isLinkCopiedDialog" max-width="400px" persistent>
        <v-card elevation="2" class="rounded-tl-xl rounded-br-xl no-select">
          <v-card-title>
            <v-icon large color="green darken-2"> mdi-check </v-icon>&nbsp; Link
            Copied Successfully
          </v-card-title>
          <v-card-text>
            <v-btn color="primary" @click.stop="closeLinkCopiedDialog"
              >Close</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- /Linked Copied Dialog Box -->
      <!-- Help Dialog Box -->
      <v-dialog v-model="isHelpDialog" max-width="470px">
        <v-card elevation="2" class="rounded-tl-xl rounded-br-xl no-select">
          <v-container>
            <v-card-title>
              <v-icon large color="green darken-2"> mdi-help </v-icon>
              Standard Lexile Measurement Chart
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="success--text text-center">Grade</th>
                        <th class="success--text text-center">Basic</th>
                        <th class="success--text text-center">Proficient</th>
                        <th class="success--text text-center">Advanced</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">6</td>
                        <td class="text-center">500L - 799L</td>
                        <td class="text-center">800L - 1050L</td>
                        <td class="text-center">above 1050L</td>
                      </tr>
                      <tr>
                        <td class="text-center">7</td>
                        <td class="text-center">550L - 849L</td>
                        <td class="text-center">850L - 1100L</td>
                        <td class="text-center">above 1100L</td>
                      </tr>
                      <tr>
                        <td class="text-center">8</td>
                        <td class="text-center">600L - 899L</td>
                        <td class="text-center">900L - 1150L</td>
                        <td class="text-center">above 1150L</td>
                      </tr>
                      <tr>
                        <td class="text-center">9</td>
                        <td class="text-center">650L - 999L</td>
                        <td class="text-center">1000L - 1200L</td>
                        <td class="text-center">above 1200L</td>
                      </tr>
                      <tr>
                        <td class="text-center">10</td>
                        <td class="text-center">700L - 1024L</td>
                        <td class="text-center">1025L - 1250L</td>
                        <td class="text-center">above 1250L</td>
                      </tr>
                      <tr>
                        <td class="text-center">11 & 12</td>
                        <td class="text-center">800L - 1049L</td>
                        <td class="text-center">1050L - 1300L</td>
                        <td class="text-center">above 1300L</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
            </v-card-text>
            <v-card-action>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="ma-5"
                  @click.stop="isHelpDialog = false"
                  >Close</v-btn
                >
              </v-row>
            </v-card-action>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- /Help Dialog Box -->
      <!-- Variant Blocked Dialog Box -->
      <v-dialog v-model="isVariantBlockedDialog" max-width="400px" persistent>
        <v-card elevation="2" class="rounded-tl-xl rounded-br-xl no-select">
          <v-container>
            <v-row align="center">
              <v-col sm="3" justify="center">
                <v-icon size="80" color="red">mdi-eye-off-outline</v-icon>
              </v-col>
              <v-col sm="9">
                <v-card-title>
                  Opps !! Looks like this variant is hidden for you.
                </v-card-title>
                <v-card-text>
                  <v-btn color="primary" @click.stop="closeTab">Close</v-btn>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- /Variant Blocked Dialog Box -->
      <!-- Lock Classcode -->
      <div
        v-if="
          (roleName == 'TEACHER' || roleName == 'ADMIN') &&
          article.content_type == 'ARTICLE'
        "
      >
        <v-divider></v-divider>
        <br />

        <LockLevelClasscode
          :article="article"
          :classcodes="classcodes"
          :levels="levels"
        ></LockLevelClasscode>
        <br />
        <v-divider></v-divider>
        <br />
      </div>
      <v-card color="white" id="section-to-print" elevation="6">
        <div class="pa-5 main-section">
          <div class="mb-5">
            <span class="text-h5 font-weight-bold" v-text="contentName"></span>
          </div>
          <v-row class="mb-5">
            <v-col
              md="6"
              v-if="
                article.content_type != 'INFOGRAPHIC' &&
                article.content_type != 'VIDEO' &&
                article.featured_image_path
              "
            >
              <v-card max-width="850" color="white" elevation="10">
                <v-img
                  :src="`${mediaUrl + article.featured_image_path}`"
                ></v-img>
              </v-card>
            </v-col>
            <v-col md="6" class="no-select">
              <p class="font-weight-bold">
                <!-- Subject Covered section -->
                <template v-if="subjects_covered.length">
                  <div class="my-3">
                    Subjects Covered:
                    <span class="primary--text">
                      {{
                        subjects_covered?.length
                          ? subjects_covered.join(", ")
                          : " -- "
                      }}
                    </span>
                  </div>
                  <hr />
                </template>
                <!-- End Subject Covered section -->
                <!-- Reading/Viewing Time section -->
                <template v-if="article.reading_time">
                  <div class="my-3">
                    <span>
                      {{ article.reading_time }} mins
                      {{
                        article.content_type != "VIDEO"
                          ? "read"
                          : "viewing time"
                      }}
                    </span>
                  </div>
                  <hr />
                </template>
                <!-- End Reading/Viewing Time section -->
                <!-- By Publication/Infakt Team section -->
                <template v-if="selectedLevel == 'MAX'">
                  <!-- IF MAX Variant -->
                  <div class="my-3">
                    By
                    <span class="primary--text"
                      >{{ article.written_by_name }},
                      {{ article.publication }}
                    </span>
                  </div>
                  <hr />
                </template>
                <template v-else>
                  <!-- IF Other Variant -->
                  <div class="my-3">
                    By
                    <span class="primary--text"
                      >{{ article.publication }}, Adapted by Pousse Team
                    </span>
                  </div>
                  <hr />
                </template>
                <!-- End of By Publication/Infakt Team section -->
                <!-- Published section -->
                <template>
                  <div class="my-3">
                    Published :
                    <span class="primary--text">
                      {{ article.created_at | ddmmyyyy }}
                    </span>

                    <span
                      v-if="article.content_type == 'ARTICLE'"
                      style="border-left: 2.5px solid gray"
                      class="mx-5"
                    ></span>
                    <template v-if="article.content_type == 'ARTICLE'">
                      Word Count:
                      <span class="primary--text">{{ current_wordCount }}</span>
                    </template>
                  </div>
                </template>
                <!-- End of Published Section -->
                <br />
                {{ article.content_type | capitalize }} : {{ article.id }}
                <br />
                <br />
                <VueTextToSpeech1
                  id="dont-print"
                  class="no-select"
                  v-if="contentAudio && article.content_type == 'ARTICLE'"
                  :audioPath="contentAudio"
                  :is_greater_than_5000="isGreaterThan5000"
                ></VueTextToSpeech1>
              </p>
            </v-col>
          </v-row>
          <VueTextAnnotation1
            :selectedLevel="selectedLevel != 'N/A' ? selectedLevel : 0"
            :article="article"
            :classcodes="classcodes"
            @onSave="refreshData()"
          ></VueTextAnnotation1>
          <loader v-if="isLoading"></loader>
          <br />
          <div
            id="dont-print"
            class="text-center"
            v-if="
              roleName != 'STUDENT' &&
              roleName != 'SUPER ADMIN' &&
              roleName != 'ADMIN' &&
              article.is_approved == 1
            "
          >
            <!-- <v-btn color="primary" @click="isWholeClasscAssignment = true"> -->
            <v-btn color="primary" @click="is1AddAssignmentDailog = true">
              Add Assignment
            </v-btn>
          </div>
        </div>
        <div class="watermark">
          <img
            src="https://www.infakt.co.in/img/logo-i.932d58d0.png"
            alt="Watermark"
          />
        </div>
      </v-card>
      <!-- Similar Article Section -->
      <div
        class="text-h5 font-weight-bold my-4 no-select"
        v-text="`Similar Content`"
      ></div>
      <v-row class="no-select">
        <v-slide-group show-arrows v-model="model">
          <template v-for="(sa, a) in similar_articles">
            <v-slide-item :key="`sa${a}`">
              <v-container
                style="max-width: 360px"
                v-if="sa.content_descriptions.length != 0"
              >
                <Article :article="sa" :type="sa.content_type"></Article>
              </v-container>
            </v-slide-item>
          </template>
        </v-slide-group>
      </v-row>
      <!--/ View Similar Article Section -->
    </v-container>
    <!-- Add Assignment 1st  Dialog -->
    <v-dialog
      v-model="is1AddAssignmentDailog"
      max-width="400px"
      class="no-select"
    >
      <v-card elevation="10" class="mx-auto" shaped>
        <v-card-title class="justify-center primary accent-1 white--text pa-1"
          >What would you like to do?
        </v-card-title>
        <v-card-text>
          <v-list rounded>
            <v-list-item-group color="primary">
              <v-list-item
                @click="
                  (isWholeClasscAssignment = true),
                    (is1AddAssignmentDailog = false)
                "
              >
                <v-list-item-icon>
                  <v-icon large color="green darken-2">
                    mdi-account-group-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-bold">Whole Class Assignment</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :to="`/articles/${$route.params.articleId}/personalized-assignments`"
              >
                <v-list-item-icon>
                  <v-icon large color="blue darken-2">
                    mdi-account-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-bold"
                      >Personalized Assignment</span
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--End of Add Assignment 1st  Dialog -->
    <!-- Whole Class Assignment Dialog -->
    <v-dialog v-model="isWholeClasscAssignment" max-width="375">
      <v-card class="no-select" shaped>
        <v-card-title class="justify-center primary accent-1 white--text pa-1"
          >What would you like to do?
        </v-card-title>
        <v-card-text>
          <v-list rounded>
            <v-list-item-group color="primary">
              <v-list-item
                :to="`/assignments/create?articleId=${$route.params.articleId}`"
              >
                <v-list-item-icon>
                  <v-icon large color="green darken-2">
                    mdi-clipboard-file
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-bold">Create New Assignment</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :to="`/articles/${$route.params.articleId}/assignments`"
              >
                <v-list-item-icon>
                  <v-icon large color="blue darken-2">
                    mdi-file-document-minus-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-bold"
                      >Choose Existing Assignment</span
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-dialog v-model="isAssignAricleToReadDialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-icon>
                      <v-icon large color="orange darken-2">
                        mdi-file-document-check
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-bold"
                          >Assign Article To Read</span
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-card elevation="10" class="rounded-tl-xl rounded-br-xl">
                  <v-card-title>
                    <div>Select Classcodes</div>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col sm="6">
                        <v-autocomplete
                          v-model="selectedToReadClasscodes"
                          :items="classcodes"
                          label="Select Classcodes"
                          prepend-icon="mdi-database-search"
                          multiple
                          chips
                          :error-messages="
                            selectedToReadClasscodes.length == 0
                              ? 'Atleast one classcode needs to be selected'
                              : ''
                          "
                        ></v-autocomplete>
                      </v-col>
                      <v-col sm="6" class="mt-2">
                        <v-menu
                          v-model="due_date_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="due_date"
                              label="Due Date *"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="
                                due_date == '' || due_date == null
                                  ? 'Due Date field is reqiuired'
                                  : ''
                              "
                              clearable
                              @click:clear="due_date = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :id="`due_date`"
                            v-model="due_date"
                            @change="due_date_menu = false"
                            :min="nowDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <div class="font-weight-bold" v-if="isAssigning">
                      Assigning article to read...
                    </div>
                    <div
                      class="font-weight-bold"
                      v-if="isAssigned && errors.length == 0"
                    >
                      Article assigned
                    </div>
                    <br />
                    <v-btn
                      color="primary"
                      :disabled="validationErrors.length ? true : false"
                      @click="assignAricleToReadDialog"
                    >
                      Assign
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--End of Whole Class Assignment Dialog -->
    <!-- If Article hidden from any Classcode -->
    <v-dialog
      v-model="show_hidden_classcode_message"
      max-width="410px"
      persistent
    >
      <v-card elevation="10" align="center">
        <v-card-title class="justify-center primary accent-1 white--text">
          Looks like there are some already posted assignment for this
          classcode. Please select some other classcode
        </v-card-title>
        <br />
        <v-card-text>
          <v-btn
            dark
            color="primary"
            @click.stop="
              scrollToSection('#classcode'),
                (show_hidden_classcode_message = false)
            "
          >
            Ok
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- If Article Lock from any Classcode -->
    <v-dialog
      v-model="show_lock_classcode_message"
      max-width="410px"
      persistent
    >
      <v-card elevation="10" align="center">
        <v-card-title class="justify-center primary accent-1 white--text">
          Looks like there are some already posted assignment with different
          lexile for this classcode. Please select some other classcode
        </v-card-title>
        <br />
        <v-card-text>
          <v-btn
            dark
            color="primary"
            @click.stop="
              scrollToSection('#classcode'),
                (show_lock_classcode_message = false)
            "
          >
            Ok
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>
<script>
window.addEventListener("afterprint", function () {
  var watermark = document.querySelector(".watermark");
  if (watermark) {
    watermark.parentNode.removeChild(watermark);
  }
});
import axios from "axios";
import VueTextAnnotation1 from "@/components/VueTextAnnotation1.vue";
import LockLevelClasscode from "./lock_level_classcode.vue";
// import Backbutton from "@/components/backbutton.vue";
import BookmarkDialog from "@/components/BookmarkDialog.vue";
// import VueTextToSpeech from "@/components/VueTextToSpeech.vue";
import VueTextToSpeech1 from "@/components/VueTextToSpeech1.vue";
import Article from "@/components/article.vue";
import Loader from "@/components/loader.vue";

export default {
  name: "SingleArticleView",
  data() {
    return {
      dialog: false,
      isAssignAricleToReadDialog: false,
      is1AddAssignmentDailog: false,
      isWholeClasscAssignment: false,
      isAssigning: false,
      isAssigned: false,
      model: "",
      form: {
        user_id: "",
        content_id: "",
      },
      due_date_menu: false,
      due_date: "",
      isLoading: false,
      article: {},
      classcodes: [],
      current_wordCount: 0,
      formToRead: {
        content_id: "",
        classcode_id: "",
        created_by_id: "",
      },
      text: "My Long Annotation Text",
      info_boards: [],
      annotations: [],
      isLinkCopiedDialog: false,
      contentName: "",
      selectedHiddenClasscodes: [],
      selectedToReadClasscodes: [],
      selectedLevel: "",
      levels: [],
      subjects_covered: [],
      is_Show_loading: false,
      isDataSaved: false,
      isInfoBoxDialog: false,
      isHelpDialog: false,
      isVariantBlockedDialog: false,
      show_hidden_classcode_message: false,
      show_lock_classcode_message: false,
      boardTab: "",
      content: "",
      contentAudio: "",
      isGreaterThan5000: 0,
      tabs: [],
      // dialog: false,
      msg: "",
      similar_articles: [],
      validationErrors: [],
      success: false,
      nowDate: new Date().toISOString().slice(0, 10),
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  components: {
    Article,
    VueTextAnnotation1,
    LockLevelClasscode,
    // Backbutton,
    BookmarkDialog,
    // VueTextToSpeech,
    VueTextToSpeech1,
    Loader,
  },
  mounted() {
    this.getData();
  },
  methods: {
    CheckHiddenClasscode() {
      let is_classcode_hiding_invalid = false;
      this.selectedHiddenClasscodes.forEach((sHC) => {
        if (
          this.article.my_assignment_classcodes.some(
            (mAC) => mAC.classcode_id === sHC
          )
        ) {
          this.show_hidden_classcode_message = true;
          is_classcode_hiding_invalid = true;
        }
      });
      if (!is_classcode_hiding_invalid) {
        this.CheckLockClasscode();
      }
    },
    CheckLockClasscode() {
      let is_classcode_lock_invalid = false;
      this.article.content_lock_classcodes.forEach((clc) => {
        if (
          this.article.my_assignment_classcodes.some(
            (mAC) =>
              mAC.classcode_id === clc.classcode_id &&
              mAC.assignment.content_description.level != clc.level
          )
        ) {
          this.show_lock_classcode_message = true;
          is_classcode_lock_invalid = true;
        }
      });
      if (!is_classcode_lock_invalid) {
        this.saveArticle();
      }
    },
    refreshData() {
      let contentDescription = this.article.content_descriptions.find(
        (cd) => cd.level == this.selectedLevel
      );
      if (contentDescription) {
        this.content = contentDescription.description;
        this.contentAudio = contentDescription.tts_audio_path;
        this.isGreaterThan5000 = contentDescription.is_greater_than_5000;
        this.$router.push(
          `/articles/${this.$route.params.articleId}/${contentDescription.id}`
        );
      }
      this.getData();
    },
    scrollToSection(selector) {
      // Example: smooth scroll to the specific section
      const element = document.querySelector(selector);
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo({
        top: middle,
        behavior: "smooth",
      });
    },
    async getData() {
      try {
        this.isLoading = true;
        let is_max_valid = false;

        let article = await axios.get(
          `/contents/${this.$route.params.articleId}`
        );
        this.article = article.data.data;
        this.article.keywords = this.article.content_keywords
          ?.map((keyword) => keyword.keyword)
          .join(", ");
        this.info_boards = this.article.content_info_boards.filter(
          (cib) => cib.content_info_board_details.length
        );
        if (this.article.original_content) {
          // If Original Content Exist then AND only then MAX can be added in Levels[]
          is_max_valid = true;
        }
        if (this.roleName != "STUDENT" && is_max_valid != false) {
          // If Role is not Student & Is max Valid flag is True
          is_max_valid = true;
        }
        if (this.article.content_descriptions.length > 0) {
          // If Article has Variants then Add levels of each variant in Levels[]
          this.article.content_descriptions.forEach((description) => {
            let level = {
              text: description.level ? description.level + "L" : "N/A",
              value: description.level ? description.level.toString() : "N/A",
            };
            this.levels.push(level);
          });
        }
        if (is_max_valid == true)
          this.levels.unshift({
            text: "MAX",
            value: "MAX",
          });
        if (this.levels) {
          // If level
          if (this.$route.params.variantId) {
            let article_variant = this.article.content_descriptions.find(
              (cd) => cd.id == this.$route.params.variantId
            );
            if (article_variant) {
              // Variant is Available to Watch
              this.selectedLevel = this.article.content_descriptions
                .find((cd) => cd.id == this.$route.params.variantId)
                .level.toString();
            } else {
              // Variant is Locked for Any reason
              this.isVariantBlockedDialog = true;
            }
          } else {
            this.selectedLevel = this.levels[0].value;
          }
        }
        if (this.$route.params.variantId) {
          let article_variant = this.article.content_descriptions.find(
            (cd) => cd.id == this.$route.params.variantId
          );
          if (article_variant) {
            this.contentName = this.article.content_descriptions.find(
              (cd) => cd.id == this.$route.params.variantId
            ).title;
          }
        } else {
          this.contentName = this.article.content_name;
        }
        // Content Assign to Text to Speech
        let content;
        let contentAudio;
        let isGreaterThan5000;
        if (this.selectedLevel == "MAX") {
          content = this.article.original_content;
          contentAudio = this.article.tts_audio_path;
          isGreaterThan5000 = this.article.is_greater_than_5000;
        } else {
          let contentDescription = [];
          if (this.article.content_descriptions.length > 0) {
            contentDescription = this.article.content_descriptions.find(
              (cd) => cd.level == this.selectedLevel
            );
          }
          if (contentDescription) {
            content = contentDescription.description;
            contentAudio = contentDescription.tts_audio_path;
            isGreaterThan5000 = contentDescription.is_greater_than_5000;
          }
        }
        this.content = content;
        this.contentAudio = contentAudio;
        this.isGreaterThan5000 = isGreaterThan5000;
        // End of Content Assign to Text to Speech
        this.current_wordCount = content
          .toString()
          .replace(/(<([^>]+)>)/gi, "")
          .split(" ").length;
        // Assign to read
        this.article.content_assign_to_reads.forEach((cATR) => {
          if (cATR.created_by_id == this.user.id) {
            this.due_date = cATR.due_date;
            this.selectedToReadClasscodes.push(cATR.classcode_id);
          }
        });
        // Content Hidden Classcodes
        this.selectedHiddenClasscodes = [];
        this.article.content_hidden_classcodes.forEach((cHC) => {
          if (cHC.created_by_id == this.user.id)
            this.selectedHiddenClasscodes.push(cHC.classcode_id);
        });
        // Classcodes
        this.user.user_classcodes.forEach((userClasscode) => {
          this.classcodes.push({
            id: userClasscode.classcode.id,
            text: userClasscode.classcode.classcode,
            value: userClasscode.classcode.id,
          });
        });
        if (this.classcodes.length == 0) {
          let response = await axios.get("/classcodes");
          response = response.data.data;
          response.forEach((classcode) => {
            this.classcodes.push({
              id: classcode.id,
              text: classcode.classcode,
              value: classcode.id,
            });
          });
        }

        this.article.content_info_boards.forEach((info_board) => {
          if (info_board.subjects?.length) {
            info_board.subjects.forEach((subject_name) => {
              let subject = this.subjects_covered.find(
                (s) => s == subject_name
              );
              if (!subject) this.subjects_covered.push(subject_name);
            });
          }
        });
        if (
          this.roleName == "ADMIN" ||
          this.roleName == "TEACHER" ||
          this.roleName == "STUDENT"
          // this.roleName != "ACADEMIC TEAM" &&
          // this.roleName != "INFAKT TEACHER"
        ) {
          await this.createLog();
        }
        console.log(this.article);
        this.isLoading = false;
        this.getSimilar();
      } catch (e) {
        this.isLoading = false;
      }
    },
    async createLog() {
      this.form.user_id = this.user.id;
      this.form.content_id = this.article.id;
      let content_read = await axios.post(`/content_reads`, this.form);
      this.content_read = content_read.data.data;
    },
    assignAricleToReadDialog() {
      if (this.selectedToReadClasscodes.length == 0) {
        this.validationErrors["classcode"] = "This field is required";
      }
      if (this.due_date == "") {
        this.validationErrors["due_date"] = "This field is required";
      }
      if (this.selectedToReadClasscodes.length && this.due_date) {
        this.validationErrors = [];
        this.isAssigning = true;
        this.isAssigned = false;
        // let content_assign_to_reads = [];
        this.selectedToReadClasscodes.forEach((cc) => {
          let aTR = this.article.content_assign_to_reads.find(
            (AcATR) =>
              AcATR.classcode_id == cc && AcATR.created_by_id == this.user.id
          );
          if (!aTR) {
            this.article.content_assign_to_reads.push({
              company_id: this.company.id,
              content_id: this.article.id,
              collection_id: "",
              due_date: this.due_date,
              classcode_id: cc,
              created_by_id: this.user.id,
            });
          } else {
            aTR.due_date = this.due_date;
          }
        });
        this.saveArticle();

        this.isAssigning = false;
        this.isAssigned = true;
        this.closeAssignAricleToReadDialog();
      }
    },
    closeAssignAricleToReadDialog() {
      this.isAssignAricleToReadDialog = false;
      this.dialog = false;
    },
    printPage() {
      var watermark = document.createElement("body");
      watermark.classList.add("watermark");
      document.body.appendChild(watermark);
      window.print();
    },
    closeTab() {
      window.close();
    },
    copyLink() {
      var input = document.createElement("input");
      input.setAttribute("value", window.location.href);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.isLinkCopiedDialog = true;
    },
    closeLinkCopiedDialog() {
      this.isLinkCopiedDialog = false;
    },
    onLevelChange() {
      let contentDescription = [];
      let selectedLevel = this.selectedLevel != "N/A" ? this.selectedLevel : 0;
      if (this.article.content_descriptions.length > 0) {
        contentDescription = this.article.content_descriptions.find(
          (cd) => cd.level == selectedLevel
        );
      }
      if (contentDescription && contentDescription.title) {
        this.contentName = contentDescription.title;
      } else {
        this.contentName = this.article.content_name;
      }
      // Content Assign to Text to Speech
      let content;
      let contentAudio;
      let isGreaterThan5000;
      if (this.selectedLevel == "MAX") {
        content = this.article.original_content;
        contentAudio = this.article.tts_audio_path;
        isGreaterThan5000 = this.article.is_greater_than_5000;
      } else {
        let contentDescription = [];
        if (this.article.content_descriptions.length > 0) {
          contentDescription = this.article.content_descriptions.find(
            (cd) => cd.level == this.selectedLevel
          );
        }
        if (contentDescription) {
          content = contentDescription.description;
          contentAudio = contentDescription.tts_audio_path;
          isGreaterThan5000 = contentDescription.is_greater_than_5000;
        }
      }
      this.content = content;
      this.contentAudio = contentAudio;
      this.isGreaterThan5000 = isGreaterThan5000;
      // End of Content Assign to Text to Speech
      this.current_wordCount = content
        .toString()
        .replace(/(<([^>]+)>)/gi, "")
        .split(" ").length;
    },
    async saveArticle() {
      this.is_Show_loading = true;
      let contentHiddenClasscodes = [];
      this.selectedHiddenClasscodes.forEach((sHC) => {
        let hiddenClasscode = this.article.content_hidden_classcodes.find(
          (cHC) => cHC.classcode_id == sHC && cHC.created_by_id == this.user.id
        );
        if (hiddenClasscode) {
          contentHiddenClasscodes.push(hiddenClasscode);
        } else {
          contentHiddenClasscodes.push({
            content_id: this.article.id,
            classcode_id: sHC,
            created_by_id: this.user.id,
          });
        }
      });
      let allContentHiddenClasscodes = [];
      this.article.content_hidden_classcodes.forEach((cHC) => {
        if (cHC.created_by_id != this.user.id)
          allContentHiddenClasscodes.push(cHC);
      });
      allContentHiddenClasscodes = [
        ...allContentHiddenClasscodes,
        ...contentHiddenClasscodes,
      ];
      this.article.content_hidden_classcodes = allContentHiddenClasscodes;
      await axios.post("contents", this.article);
      this.isDataSaved = true;
      var _this = this;
      // Simulate a is_Show_loading delay of 2 seconds
      setTimeout(() => {
        // After 2 seconds, change the is_Show_loading state to false
        _this.is_Show_loading = false;
      }, 800);
      // Simulate a redirect delay of 3 seconds
      setTimeout(() => {
        // After 3 seconds, Close Dialog
        this.isDataSaved = false;
      }, 1800);
    },
    closeInfoBoxDialog() {
      this.isInfoBoxDialog = false;
    },
    isInfoBoardPresent() {
      let info_boards = this.article.content_info_boards?.filter(
        (cib) => cib.content_info_board_details.length > 0
      );
      return info_boards?.length;
    },
    async getSimilar() {
      try {
        this.isLoading = true;
        let response = [];
        let keyword_strings = this.article.content_keywords
          ?.map((k) => k.keyword)
          .join(", ");
        let query =
          "type=" +
          this.article.content_type +
          "&keyword_strings=" +
          keyword_strings +
          "&exclude_id=" +
          this.article.id +
          "&roleName=" +
          this.roleName +
          "&source=SIMILAR&user_id=" +
          this.user.id;
        response = await axios.get(`/user_contents?${query}`);
        // response = await axios.get(`/search_contents?${query}`);
        this.similar_articles = response.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    subjectIcon(subject) {
      let icon = "";
      switch (subject) {
        case "English":
          icon = "mdi-book-alphabet";
          break;
        case "History":
          icon = "mdi-pillar";
          break;
        case "Geography":
          icon = "mdi-globe-model";
          break;
        case "Science":
          icon = "mdi-flask-outline";
          break;
        case "Civics":
          icon = "mdi-scale-balance";
          break;
        case "Biology":
          icon = "mdi-bacteria-outline";
          break;
        case "Physics":
          icon = "mdi-rocket-launch-outline";
          break;
        case "Chemistry":
          icon = "mdi-test-tube";
          break;
        case "Economics":
          icon = "mdi-cash-100";
          break;
        case "Humanities":
          icon = "mdi-meditation";
          break;
        case "Hindi":
          icon = "mdi-abugida-devanagari";
          break;
        case "Social Studies":
          icon = "mdi-handshake-outline";
          break;
        case "Commerce":
          icon = "mdi-cash-sync";
          break;

        default:
          break;
      }
      return icon;
    },
  },
};
</script>

<style scoped>
div img {
  width: 100% !important;
}
.make_transparent {
  background-color: rgba(41, 40, 40, 0);
}
.no-select {
  user-select: none;
}
.watermark {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  z-index: 9999;
}
@media print {
  .watermark {
    display: block !important;
  }

  body * {
    visibility: hidden;
    /* background: url('data:image/svg+xml;utf8,<svg style="transform:rotate(-45deg)" hre="https://www.infakt.co.in/img/logo-i.932d58d0.png"  viewBox="0 0 50 60"><text x="0" y="25" fill="%23000">Lorem </text></svg>')
      0 0/100% 100vh; */
    /* background: url("https://www.infakt.co.in/img/logo-i.932d58d0.png"); */
    /* background-image: url('https://www.infakt.co.in/img/logo-i.932d58d0.png');
    background-repeat: repeat;
    background-position: center;
    background-size: 50%;
    opacity: 0.3; */
  }
  .fr-view {
    /* background: url("https://www.infakt.co.in/img/logo-i.932d58d0.png"); */
    /* no-repeat center center fixed !important; */
    /* background-size: contain; */
    /* background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: overlay;
    background-position-x: center;
    background-size: contain; */
  }
  #dont-print,
  #dont-print * {
    display: none;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
    -webkit-print-color-adjust: exact;
    /* background-attachment: fixed; */
  }
  #section-to-print {
    overflow: visible;
    position: absolute !important;
    top: 0;
    left: 0;
  }
}
</style>
