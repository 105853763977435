<template>
  <span>
    <!-- Bookmark Pop UP Dialog -->
    <v-dialog max-width="350" v-model="isBookmarkDialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          v-on="on"
          v-if="roleName != 'ADMIN'"
        >
          <v-icon size="34">mdi-bookmark-box-multiple</v-icon>
        </v-btn>
      </template>
      <v-card class="rounded-tl-xl rounded-br-xl">
        <v-card>
          <v-card-title
            class="justify-center primary accent-1 white--text no-select"
            >What would you like to do?
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item-group color="primary">
                <!-- Add Collection -->
                <v-dialog v-model="dialog" width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      class="mx-auto my-auto"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="dialog = true"
                    >
                      <v-list-item-icon>
                        <v-icon large color="red darken-2 mx-2">
                          mdi-plus-box-multiple
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="font-weight-bold"
                            >Create New Collection</span
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-card class="rounded-tl-xl rounded-br-xl">
                    <v-container>
                      <v-row align="center">
                        <v-col sm="3">
                          <v-img
                            :src="require('../assets/imgs/add_files.png')"
                            width="200"
                          ></v-img>
                        </v-col>
                        <v-col sm="9">
                          <v-card-text>
                            <v-row>
                              <v-col>
                                <h3 align="center">
                                  Please name this collection
                                </h3>
                                <br />
                                <v-text-field
                                  v-model="form.collection_name"
                                  outlined
                                  label="Collection Name"
                                  prepend-inner-icon="mdi-plus-box-multiple"
                                  :error-messages="errors.collection_name"
                                ></v-text-field>
                                <p v-if="msg" style="color: red">
                                  {{ msg }}
                                </p>
                                <template
                                  v-if="
                                    roleName == 'INFAKT TEACHER' ||
                                    roleName == 'ACADEMIC TEAM'
                                  "
                                >
                                  <input
                                  class="mb-5"
                                  type="file"
                                  id="file"
                                    name="file"
                                    ref="file"
                                    accept="image/*"
                                    @change="onFileChange"
                                    />
                                  <p v-if="imageErrors" style="color: red">
                                    {{ imageErrors }}
                                  </p>
                                </template>
                                <loader v-if="isLoading"></loader>
                                <v-btn
                                  v-else
                                  color="primary"
                                  dark
                                  @click="saveCollection(article.id)"
                                >
                                  <!-- @click="$emit('saveCollection', article.id)" -->
                                  Save Collection
                                </v-btn>
                                &nbsp;
                                <v-btn color="danger" @click="dialog = false">
                                  Cancel
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
                <!-- Added in Existing Collection -->

                <v-dialog v-model="existingDialog" width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      class="mx-auto my-auto"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="openAddExistingCollectionDialog"
                    >
                      <v-list-item-icon>
                        <v-icon large color="blue darken-2">
                          mdi-animation
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="justify-center mx-auto my-auto"
                        >
                          <span class="font-weight-bold"
                            >Add to an existing collection</span
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <v-card class="rounded-tl-xl rounded-br-xl">
                    <v-container>
                      <v-row align="center">
                        <v-col sm="3">
                          <v-img
                            :src="require('../assets/imgs/add_existing.png')"
                            width="200"
                          ></v-img>
                        </v-col>
                        <v-col sm="9">
                          <v-card-title style="color: red">
                            Attention ! </v-card-title
                          ><v-card-text>
                            <v-row>
                              <v-col>
                                <h3 align="center">
                                  Please choose an existing collection
                                </h3>
                                <br />
                                <v-autocomplete
                                  ref="collection"
                                  v-model="collection"
                                  :items="collections"
                                  label="Collections"
                                  prepend-icon="mdi-animation"
                                ></v-autocomplete>
                                <p v-if="existMSG" style="color: red">
                                  {{ existMSG }}
                                </p>
                                <loader v-if="isLoading"></loader>
                                <v-btn
                                  v-else
                                  color="primary"
                                  dark
                                  @click="saveExistingCollection(article.id)"
                                >
                                  <!-- @click="
                                  $emit('saveExistingCollection', article.id)
                                " -->
                                  Save Collection
                                </v-btn>

                                &nbsp;
                                <v-btn
                                  color="danger"
                                  @click="existingDialog = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>

                <!-- Bookmark -->
                <v-list-item
                  class="mx-auto my-auto"
                  @click="saveToBookmark(article.id)"
                >
                  <v-list-item-icon>
                    <v-icon large color="green darken-2">
                      mdi-book-open-page-variant
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      icon
                      class="justify-center mx-auto my-auto"
                      color="primary"
                    >
                      <span class="font-weight-bold"
                        >Add To My
                        {{
                          roleName == "ACADEMIC TEAM" ||
                          roleName == "INFAKT TEACHER"
                            ? "Archive"
                            : "Library"
                        }}</span
                      >
                    </v-list-item-title>
                    <p v-if="bookmarMSG" style="color: red">
                      {{ bookmarMSG }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- Success Dialog Box -->
    <v-dialog v-model="success" width="600">
      <v-card class="rounded-tl-xl rounded-br-xl">
        <v-container>
          <v-row align="center">
            <v-col sm="3">
              <v-img
                :src="require('../assets/imgs/success.png')"
                width="200"
              ></v-img>
            </v-col>
            <v-col sm="9">
              <v-card-title style="color: green"> Success !</v-card-title
              ><v-card-text>
                <v-row>
                  <v-col>
                    <h3>
                      This article has been successfully added in your library.
                    </h3>
                    <br />
                    <v-btn color="danger" @click="closedModal"> OK </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Image Warning popups -->
    <v-dialog v-model="warningImageLoader" width="600">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: red; font-size: 90px; width: 200px !important"
              >
                mdi-file-image-remove
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">OOPS!</h1>
                    <br />
                    <h3>
                      Image dimension is not matching with our standard
                      dimension. The image dimension should be multiple of 690 x
                      390
                    </h3>
                    <br />
                    <v-btn color="success" @click="warningImageLoader = false">
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Image Warning popups -->
  </span>
</template>

<script>
import axios from "axios";
import loader from "./loader.vue";

export default {
  components: { loader },
  data() {
    return {
      form: {
        subject_id: "",
        collection_name: "",
      },
      image: {
        size: "",
        height: "",
        width: "",
      },
      imageLoader: false,
      warningImageLoader: false,
      imageErrors: "",

      dialog: false,
      isBookmarkDialog: false,
      msg: "",
      isLoading: false,
      existingDialog: false,
      collection: "",
      existMSG: "",
      bookmarMSG: "",
      collections: [],
      success: false,
      isInfoBoxDialog: false,
    };
  },
  mounted() {
    // this.getMasters();
  },
  props: ["article"],
  methods: {
    async getMasters() {
      let masters = await axios.get(`collections?user_id=${this.user.id}`);
      masters = masters.data.data;
      // this.subjects = masters.subjects;
      masters.forEach((collection) => {
        this.collections.push({
          id: collection.id,
          text: collection.collection_name,
          value: collection.id,
        });
      });
    },
    openAddExistingCollectionDialog() {
      this.existingDialog = true;
      this.getMasters();
    },
    onFileChange(e) {
      this.warningImageLoader = false;
      this.file = this.$refs.file?.files[0];
      this.image.size = this.file.size;
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          let divWidth = Math.round(this.image.width / 690);
          let divHeight = Math.round(this.image.height / 390);

          let intoWidth = 690 * divWidth;
          let intoHeight = 390 * divHeight;

          if (
            intoWidth != this.image.width &&
            intoHeight != this.image.height
          ) {
            this.imageErrors =
              "Image dimension ( " +
              this.image.width +
              " x " +
              this.image.height +
              ") which is not matching with our standard dimension. The image dimension should be multiple of 690 x 390.";
            this.imageLoader = true;
            this.warningImageLoader = true;
          } else {
            this.imageLoader = false;
            this.image_url = URL.createObjectURL(this.file);
            this.imageErrors = "";
          }

          if (this.errors.length === 0) {
            this.success = "Image validated successfully";
          }
          // alert(img.width + " " + img.height);
        };
        img.src = evt.target.result;
      };

      reader.onerror = (evt) => {
        console.error(evt);
      };
      // this.image_url = URL.createObjectURL(this.file);
    },
    // Added New Collection
    async saveCollection(content_id) {
      this.isLoading = true;
      try {
        if (this.imageLoader == false) {
          this.msg = "";
          this.dialog = true;
          this.form.user_id = this.user.id;
          this.form.content_id = content_id;
          let collection = await axios.post("/collections", this.form);
          this.existingCollection = collection.data.data;
          this.msg = collection.data.msg;
          // if (collection.data.success == true) {
          //   let data = {
          //     collection_id: this.existingCollection.id,
          //     content_id: content_id,
          //   };

          //   let collection_content = await axios.post(
          //     "/collection_contents",
          //     data
          //   );
          //   this.collection_content = collection_content.data.data;
          //   if (this.collection_content) {
          //     this.success = true;
          //   }
          // }
          if (this.msg == "") this.handleFileUpload();
          this.success = true;
          this.form.collection_name = "";
          this.dialog = false;
        } else {
          this.warningImageLoader = true;
          this.isLoading = false;
        }
      } catch (e) {
        this.dialog = true;
      }
      this.isLoading = false;
    },
    // Added Existing Collection
    async saveExistingCollection(content_id) {
      this.isLoading = true;

      try {
        this.existingdialog = true;
        this.existMSG = "";
        let Cdata = {
          collection_id: this.collection,
          content_id: content_id,
        };
        let collection_content = await axios.post(
          "/collection_contents",
          Cdata
        );
        this.collection_content = collection_content.data.data;

        this.existMSG = collection_content.data.msg;

        if (this.existMSG == "") {
          this.success = true;
        }
        this.existingdialog = false;
      } catch (e) {
        this.existingdialog = true;
      }
      this.isLoading = false;
    },
    // Added Bookmarks
    async saveToBookmark(content_id) {
      try {
        this.bookmarMSG = "";
        let data = {
          company_id: this.company.id,
          user_id: this.user.id,
          content_id: content_id,
        };
        let bookmark = await axios.post("/bookmarks", data);
        this.bookmark = bookmark.data.data;
        this.bookmarMSG = bookmark.data.msg;
        if (this.bookmarMSG == "") {
          this.success = true;
        }
        this.form.collection_name = "";
      } catch (e) {
        this.success = false;
      }
    },
    closedModal() {
      this.success = false;
      this.dialog = false;
      this.existingDialog = false;
      this.isBookmarkDialog = false;
    },
    async handleFileUpload() {
      let attachment = this.$refs.file?.files[0];
      const collection_id = this.existingCollection.id;
      let formData = new FormData();
      formData.append("collection_id", collection_id);
      formData.append("featuredimagepath", attachment);
      await axios
        .post("upload_collection_featured_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>

<style scoped>
.no-select {
  user-select: none;
}
</style>
