<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Get Started'"></span>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>

      <div v-else>
        <!-- List in Expandable View -->
        <v-col md="12">
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(manual, m) in manuals"
              :key="`manual_${m}`"
              class="mb-5"
            >
              <v-expansion-panel-header color="blue-grey lighten-4">
                <b>
                  {{ manual.title }}
                </b>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <span v-html="manual.description" class="mx-5"></span>
                <!-- Manual Detail List -->
                <v-card
                  elevation="6"
                  class="mb-3"
                  v-for="(manual_detail, md) in manual.manual_details"
                  :key="`manual_detail_${md}`"
                  @click="getDetail(manual_detail)"
                >
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <b>
                          {{ manual_detail.detail_title }}
                        </b>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col md="1">
                        <v-btn x-small color="primary" class="ml-5">
                          view
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <!-- / Manual Detail List -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </div>
      <!-- Manual Detail Dialog Box -->
      <v-dialog v-model="show_details" width="750">
        <v-card class="rounded-tl-xl rounded-br-xl">
          <v-container>
            <v-row justify="center">
              <v-col md="11" align="center">
                <v-card-title class="mx-auto" style="color: green">
                  <v-row>
                    <v-col>
                      <h3>
                        {{ manual_detail.detail_title }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h3 v-html="manual_detail.detail_description"></h3>
                      <br />
                      <v-btn
                        color="danger"
                        @click="(show_details = false), (manual_detail = {})"
                      >
                        Close
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      manuals: [],
      manual_detail: {},
      count: null,
      isLoading: false,
      isFetchingDetails: false,
      show_details: false,
      roleItems: [
        {
          text: "ADMIN",
          value: "ADMIN",
        },
        {
          text: "TEACHER",
          value: "TEACHER",
        },
        {
          text: "STUDENT",
          value: "STUDENT",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let manuals = await axios.get(`/manuals?role=${this.roleName}`);
      this.manuals = manuals.data.data;
      this.count = manuals.data.count;
      this.isLoading = false;
    },
    async getDetail(manual_detail) {
      manual_detail.isFetchingDetails = true;
      this.manual_detail = manual_detail;
      if (this.manual_detail) {
        this.show_details = true;
      }
      manual_detail.isFetchingDetails = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
