<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Add a Blog Detail
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="6">
              <v-text-field
                v-model="form.title"
                outlined
                label="Title *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.title"
              ></v-text-field>
            </v-col>
            <v-col sm="6">
              <input type="file" id="file" name="file" :ref="`file`" />
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col sm="12">
              <p class="font-weight-bold">Description</p>
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="form.description"
              ></froala>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save">
            Save Blog Detail
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { backbutton, Loader },
  name: "BlogDetailCreatePage",
  data() {
    return {
      blog_detail: {},
      form: {
        title: "",
        description: "",
      },
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      isLoading: false,
    };
  },
  mounted() {
    this.form.blog_id = this.$route.params.blogId;
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        let blog_detail = await axios.post("/blog_details", this.form);
        this.blog_detail = blog_detail.data.data;
        this.isLoading = false;
        this.handleFileUpload();
        this.$router.push(`/blogs/${this.$route.params.blogId}/blog-details`);
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      if (this.$refs.file != null) {
        let attachment = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("blogDetailId", this.blog_detail.id);
        formData.append("imagepath", attachment);
        await axios
          .post("upload_blog_detail_imagepath", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
      }
    },
  },
};
</script>
