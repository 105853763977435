<template>
  <span>
    <v-card
      rounded
      id="thing_to_stick"
      v-model="display_clock"
      align="center"
      color="blue lighten-5"
      v-if="myTimer != 0"
    >
      <v-card-text>
        <span class="smallScreen"
          ><v-row>
            <v-col>
              <v-layout justify-center align-center row wrap>
                <h1
                  :style="`${
                    myTimer <= 0.2 * plannedTimeInSeconds ? 'color: red;' : ''
                  }font-size: small`"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="primary" v-bind="attrs" v-on="on">
                        <v-icon size="15">mdi-timer-alert</v-icon>
                      </v-btn>
                    </template>
                    <span>This is a timed assignment.</span>
                  </v-tooltip>
                </h1>
              </v-layout>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-layout justify-center align-center row wrap>
                <h1
                  :style="` ${
                    myTimer <= 0.2 * plannedTimeInSeconds ? 'color: red; ' : ''
                  } font-size: small`"
                >
                  {{ myTimer | secToHhMm }}
                </h1>
              </v-layout>
            </v-col>
          </v-row>
          <v-row v-if="myTimer <= 0.2 * plannedTimeInSeconds">
            <v-col>
              <h3 style="color: red; font-size: small">
                Only {{ myTimer | secToHhMm }} minutes left
              </h3>
            </v-col>
          </v-row>
        </span>
        <span class="midScreen">
          <v-row>
            <v-col>
              <v-layout justify-center align-center row wrap>
                <h1
                  :style="` ${
                    myTimer <= 0.2 * plannedTimeInSeconds ? 'color: red; ' : ''
                  } font-size: medium`"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="primary" v-bind="attrs" v-on="on">
                        <v-icon size="30">mdi-timer-alert</v-icon>
                      </v-btn>
                    </template>
                    <span>This is a timed assignment.</span>
                  </v-tooltip>
                </h1>
              </v-layout>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-layout justify-center align-center row wrap>
                <h1
                  :style="` ${
                    myTimer <= 0.2 * plannedTimeInSeconds ? 'color: red; ' : ''
                  } font-size: medium`"
                >
                  {{ myTimer | secToHhMm }}
                </h1>
              </v-layout>
            </v-col>
          </v-row>
          <v-row v-if="myTimer <= 0.2 * plannedTimeInSeconds">
            <v-col>
              <h3 style="color: red; font-size: small">
                Only {{ myTimer | secToHhMm }} minutes left
              </h3>
            </v-col>
          </v-row>
        </span>
        <span class="largeScreen">
          <v-row>
            <v-col>
              <v-layout justify-center align-center row wrap>
                <h1
                  :style="
                    myTimer <= 0.2 * plannedTimeInSeconds
                      ? 'color: red; font-size: 50px'
                      : 'font-size: 50px'
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="primary" v-bind="attrs" v-on="on">
                        <v-icon size="40">mdi-timer-alert</v-icon>
                      </v-btn>
                    </template>
                    <span>This is a timed assignment.</span>
                  </v-tooltip>
                </h1>
              </v-layout>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-layout justify-center align-center row wrap style="height: 45">
                <h1
                  :style="
                    myTimer <= 0.2 * plannedTimeInSeconds
                      ? 'color: red; font-size: 30px'
                      : 'font-size: 30px'
                  "
                >
                  {{ myTimer | secToHhMm }}
                </h1>
              </v-layout>
            </v-col>
          </v-row>
          <v-row v-if="myTimer <= 0.2 * plannedTimeInSeconds">
            <v-col>
              <h3 style="color: red">
                Only {{ myTimer | secToHhMm }} minutes left
              </h3>
            </v-col>
          </v-row></span
        >
      </v-card-text>
    </v-card>
  </span>
</template>

<script type="text/javascript">
import moment from "moment";

export default {
  data: () => ({
    myTimer: "",
    timeLeft: "",
    display_clock: true,
    plannedTimeInSeconds: "",
    isCompleted: 0,
  }),
  props: ["startTime", "plannedTime"],
  watch: {
    startTime: "updateTimer",
  },
  methods: {
    updateTimer() {
      this.myTimer = this.startTime; // It is in seconds
      this.plannedTimeInSeconds = parseInt(this.plannedTime) * 60;

      setInterval(() => {
        if (this.myTimer != 0) this.myTimer -= 1;
        if (this.isCompleted == 0) {
          // if (this.myTimer % 5 == 0) this.$emit("savePartial");
          if (this.myTimer == 0) {
            this.$emit("finishTest");
            this.isCompleted = 1;
          }
        }

        this.timeLeft = this.plannedTimeInSeconds - this.myTimer;
        if (this.myTimer == this.plannedTimeInSeconds) this.$emit("finishTest");
      }, 1000);
    },
  },
};
</script>

<style>
#thing_to_stick {
  position: fixed;
  top: 100px;
  right: 30px;
  z-index: 2;
}
</style>
