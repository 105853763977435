<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Edit {{ title }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.subject_name"
                outlined
                label="Subject Name"
                prepend-inner-icon="mdi-application-edit"
                :error-messages="errors.subject_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-switch
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-switch
                v-model="form.is_optional"
                inset
                :label="`Is Optional: ${form.is_optional ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="update">
            Update Section
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { backbutton, Loader },
  data() {
    return {
      form: {},
      title: "",
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `classcodes/${this.$route.params.classcodeId}`
      );
      this.form = form.data.data;
      this.section = this.form.section;
      this.standard = this.form.standard;
      this.title = "Class of " + this.standard.name + " - " + this.section.name;
      this.isLoading = false;
    },
    async update() {
      try {
        this.isLoading = true;
        await axios.patch(
          `classcodes/${this.$route.params.classcodeId}`,
          this.form
        );
        this.isLoading = false;
        this.$router.push(
          `/standards/${this.$route.params.standardId}/sections/${this.$route.params.sectionId}/classes`
        );
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
