import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import router from "./router";
import store from "./store";

import VueGoogleCharts from "vue-google-charts/legacy";
Vue.use(VueGoogleCharts);

import VueTextToSpeech from "vue-text-to-speech";
Vue.use(VueTextToSpeech);

import ClasscodeElement from "../src/components/ClasscodeElement.vue";
Vue.component("ClasscodeElement", ClasscodeElement);

import VueDocPreview from "vue-doc-preview";
Vue.component("VueDocPreview", VueDocPreview);

import ViewFullscreen from "../src/components/ViewFullscreen.vue";
Vue.component("ViewFullscreen", ViewFullscreen);

import loader from "../src/components/loader.vue";
Vue.component("MyLoader", loader);

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");
Vue.use(VueTour);

require("@/store/subscriber");

require("./mixin.js");
require("./axios.js");
// require('./directives.js')
// require('./components.js')
require("./filters.js");

// import Vue2Editor from "vue2-editor";
// Vue.use(Vue2Editor);

//Import Froala Editor
import "froala-editor/js/plugins.pkgd.min.js";
//Import third party plugins
import "froala-editor/js/third_party/embedly.min";
import "froala-editor/js/third_party/font_awesome.min";
import "froala-editor/js/third_party/spell_checker.min";
import "froala-editor/js/third_party/image_tui.min";
// Import Froala Editor css files.
import "froala-editor/css/froala_editor.pkgd.min.css";

// Import and use Vue Froala lib.
import VueFroala from "vue-froala-wysiwyg";
Vue.use(VueFroala);

Vue.config.productionTip = false;

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
