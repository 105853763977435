<template>
  <v-main>
    <v-container fluid>
      <!-- <span class="smallScreen"> Small < 800 </span>
      <span class="midScreen"> 800 < MID > 1250 </span>
      <span class="largeScreen"> 1300 < Larger </span> -->
      <v-card color="grey darken-4" dark style="overflow: auto">
        <v-card-text>
          <v-row>
            <v-col class="ml-3" sm="6" md="9" lg="6">
              <v-card-title>
                <span class="smallScreen text-h4" style="min-width: 240"
                  >Welcome back, {{ user.name }}</span
                >
                <span class="midScreen text-h3"
                  >Welcome back, {{ user.name }}
                </span>
                <span class="largeScreen text-h3"
                  >Welcome back, {{ user.name }}
                </span>
              </v-card-title>
            </v-col>
            <v-col
              align="right"
              class="mt-5 mr-3"
              v-if="roleName == `INFAKT TEACHER`"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    class="black--text"
                    :to="`/contents/create`"
                  >
                    <v-icon>mdi-plus</v-icon>
                    Add content
                  </v-btn>
                </template>
                <span>Create a new Content</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="ml-3 pb-2">
            <v-col
              style="border-right-style: inset"
              class="routerLink"
              v-for="(content_type, ct) in content_types"
              :key="`content_type_${ct}`"
              @click="getSelectedType(content_type.name)"
            >
              <v-row justify="center">
                <v-col sm="1" md="1" lg="1"
                  ><v-icon size="18" class="mx-auto" dark>{{
                    content_type.icon
                  }}</v-icon></v-col
                >
                <v-col
                  sm="9"
                  md="9"
                  lg="9"
                  style="min-width: 96px"
                  class="text-left"
                  ><span>{{ content_type.name | capitalize }}</span></v-col
                >
                <v-col
                  sm="10"
                  md="10"
                  lg="10"
                  class="text-left text-h4 pt-0"
                  style="min-width: fit-content"
                  >{{ content_type.count }}</v-col
                >
              </v-row>
            </v-col>
            <v-col class="routerLink" style="border-right-style: inset">
              <router-link class="routerLink" to="/assignments">
                <v-row justify="center">
                  <v-col sm="1" md="1" lg="1"
                    ><v-icon size="18" class="mx-auto" dark
                      >mdi-clipboard-text</v-icon
                    ></v-col
                  >
                  <v-col
                    sm="9"
                    md="9"
                    lg="9"
                    style="min-width: 100px; color: #bdbdbd"
                    class="text-left"
                    ><span>Content Based Assignment</span></v-col
                  >
                  <v-col
                    sm="10"
                    md="10"
                    lg="10"
                    class="text-left text-h4 pt-0"
                    style="min-width: fit-content; color: #bdbdbd"
                    >{{
                      assignments.filter(
                        (a) =>
                          a.company_id == null &&
                          a.content_id != null &&
                          (a.status != 3 || a.created_by_id == user.id)
                      ).length
                    }}</v-col
                  >
                </v-row>
              </router-link>
            </v-col>
            <v-col class="routerLink">
              <router-link class="routerLink" to="/assignments">
                <v-row justify="center">
                  <v-col sm="1" md="1" lg="1"
                    ><v-icon size="18" class="mx-auto" dark
                      >mdi-clipboard-text</v-icon
                    ></v-col
                  >
                  <v-col
                    sm="9"
                    md="9"
                    lg="9"
                    style="min-width: 100px; color: #bdbdbd"
                    class="text-left"
                    ><span>Collection Based Assignment</span></v-col
                  >
                  <v-col
                    sm="10"
                    md="10"
                    lg="10"
                    class="text-left text-h4 pt-0"
                    style="min-width: fit-content; color: #bdbdbd"
                    >{{
                      assignments.filter(
                        (a) =>
                          a.company_id == null &&
                          a.collection_id != null &&
                          (a.status != 3 || a.created_by_id == user.id)
                      ).length
                    }}</v-col
                  >
                </v-row>
              </router-link>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row justify="start" class="mt-5">
        <v-col sm="2" style="min-width: 125px">
          <span class="text-h5 font-weight-bold" v-text="'Contents'"></span>
        </v-col>
      </v-row>
      <!-- Filters -->
      <v-row>
        <v-col>
          <v-btn-toggle mandatory v-model="form.selected_type">
            <v-btn
              small
              style="text-transform: initial !important"
              value="View all"
              @click="(form.selected_type = null), (page = 1), getData()"
            >
              View all
            </v-btn>
            <v-btn
              v-for="(content_type, ct) in content_types"
              :key="`content_type_${ct}`"
              @click="getSelectedType(content_type.name)"
              style="text-transform: initial !important"
              small
              :value="content_type.name"
              >{{ content_type.name | capitalize }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col md="3">
          <v-text-field
            solo
            dense
            v-model="form.search_keyword"
            label="Search"
            prepend-inner-icon="mdi-magnify"
            clearable
            @click:clear="(form.search_keyword = null), (page = 1), getData()"
            @keydown.enter="(page = 1), getData()"
            @change="page = 1"
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-menu
            v-model="form.date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.date_filter"
                label="Search dates"
                prepend-inner-icon="mdi-calendar-blank-outline"
                readonly
                v-bind="attrs"
                solo
                dense
                v-on="on"
                clearable
                @click:clear="form.date_filter = null"
                @input="(page = 1), getData()"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.date_filter"
              @change="(form.date_menu = false), (page = 1), getData()"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="1" style="min-width: fit-content">
          <template>
            <div class="text-center">
              <v-menu
                v-model="filter_menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="white"
                    class="text--secondary"
                    v-bind="attrs"
                    v-on="on"
                    style="text-transform: initial !important"
                  >
                    <v-icon>mdi-filter-variant</v-icon>
                    Filter
                  </v-btn>
                </template>
                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-autocomplete
                        ref="Status"
                        v-model="form.is_approved"
                        :items="statusItems"
                        label="Select Status"
                        prepend-inner-icon="mdi-lan-pending"
                        clearable
                        @click:clear="form.is_approved = null"
                        @change="(page = 1), getData()"
                      ></v-autocomplete>
                    </v-list-item>
                    <template v-if="roleName == 'ACADEMIC TEAM'">
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-autocomplete
                          ref="User"
                          v-model="form.created_by_id"
                          :items="users"
                          label="Select User"
                          prepend-inner-icon="mdi-account"
                          clearable
                          @click:clear="
                            (form.created_by_id = null), (page = 1), getData()
                          "
                          @change="(page = 1), getData()"
                        ></v-autocomplete>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-autocomplete
                          ref="subject"
                          v-model="form.is_active"
                          :items="activeStatus"
                          label="Select Active Status"
                          :prepend-inner-icon="`${
                            form.is_active != '1'
                              ? 'mdi-eye-closed'
                              : 'mdi-eye-outline'
                          }`"
                          clearable
                          @click:clear="
                            (form.is_active = null), (page = 1), getData()
                          "
                          @change="(page = 1), getData()"
                        ></v-autocomplete>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          </template>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <template v-else>
        <v-row class="mt-0">
          <v-col md="12">
            <v-card elevation="6">
              <v-simple-table fixed-header height="525">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left px-2"
                        style="min-width: 85px; border-top-left-radius: 7px"
                      >
                        Sr No
                      </th>
                      <th class="text-left pl-0 pr-2" style="min-width: 45px">
                        ID
                      </th>
                      <th
                        class="text-left pl-0 pr-2"
                        style="min-width: 80px"
                        v-if="roleName == 'ACADEMIC TEAM'"
                      >
                        Created By
                      </th>

                      <th class="text-center pl-0 pr-2">Type</th>
                      <th class="text-left pl-0 pr-2" style="min-width: 45px">
                        Title
                      </th>
                      <th
                        class="text-left pl-0 pr-2"
                        style="min-width: 85px"
                        v-if="roleName != 'ACADEMIC TEAM'"
                      >
                        Reading Time
                      </th>
                      <th class="text-left pl-0 pr-2" style="min-width: 70px">
                        Written By
                      </th>
                      <th class="text-center pl-0 pr-2" style="min-width: 45px">
                        Status
                      </th>
                      <th class="text-left pl-0 pr-2" style="min-width: 45px">
                        TTS
                      </th>
                      <th class="text-left pl-0 pr-2" style="min-width: 10px">
                        V/A
                      </th>
                      <th
                        style="border-top-right-radius: 7px; min-width: 100px"
                        class="text-right"
                        v-if="
                          roleName == 'ACADEMIC TEAM' ||
                          roleName == 'INFAKT TEACHER'
                        "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(content, i) in contents" :key="content.name">
                      <td>
                        <v-tooltip bottom v-if="content.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              dark
                              color="green"
                            >
                              mdi-circle
                            </v-icon>
                          </template>
                          <span>Content is Active</span>
                        </v-tooltip>
                        &nbsp;{{ serialNoStarting + i + 1 }}
                      </td>
                      <td class="pl-0 pr-2" style="min-width: 15px">
                        {{ content.id }}
                      </td>
                      <td
                        v-if="roleName == 'ACADEMIC TEAM'"
                        class="pl-0 pr-2"
                        style="min-width: 30px"
                      >
                        {{ content.created_by ? content.created_by.name : "" }}
                      </td>
                      <td class="text-center pa-0" style="min-width: 93px">
                        <v-chip class="ma-2" small>
                          {{ content.content_type }}
                        </v-chip>
                      </td>
                      <router-link
                        class="routerLink pl-0 pr-2"
                        style="min-width: 90px"
                        :to="`/articles/${content.id}`"
                        tag="td"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{
                              content.content_name
                            }}</span>
                          </template>
                          <span>View {{ content.content_type }} Preview</span>
                        </v-tooltip>
                      </router-link>
                      <td
                        class="pl-0 pr-2"
                        style="min-width: 15x"
                        v-if="roleName != 'ACADEMIC TEAM'"
                      >
                        {{
                          content.reading_time
                            ? content.reading_time + " mins"
                            : ""
                        }}
                      </td>
                      <td class="pl-0 pr-2">
                        {{ content.written_by_name }}
                      </td>
                      <td class="text-center pl-0 pr-2" style="min-width: 30px">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="ma-2"
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              v-if="content.is_draft == true"
                              color="blue"
                              id="Is Draft"
                            >
                              Is Draft
                            </v-chip>
                          </template>
                          <span>Is Draft</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="ma-2"
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              v-if="
                                content.is_draft == false &&
                                content.is_approved == false
                              "
                              id="Pending for Approval"
                              color="yellow"
                            >
                              Pending
                            </v-chip>
                          </template>
                          <span>Pending for Approval</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="ma-2"
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              v-if="content.is_approved == true"
                              id="Approved"
                              color="green"
                            >
                              Approved
                            </v-chip>
                          </template>
                          <span>Approved</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="ma-2"
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              color="red"
                              id="Rejected"
                              v-if="content.is_approved == 2"
                            >
                              Rejected
                            </v-chip>
                          </template>
                          <span v-html="content.remarks"></span>
                        </v-tooltip>
                      </td>
                      <td class="pl-0 pr-2">
                        <v-icon
                          v-if="
                            content.tts_audio_path == null ||
                            content.tts_audio_path == ''
                          "
                          color="red"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                        <v-icon v-else color="green">
                          mdi-check-circle-outline
                        </v-icon>
                      </td>
                      <td class="text-left pl-0 pr-2" style="min-width: 65px">
                        {{ content.content_descriptions.length }} /
                        {{
                          roleName == "ACADEMIC TEAM"
                            ? content.assignments.filter(
                                (a) => a.company_id == null
                              ).length
                            : content.my_assignments.length
                        }}
                      </td>
                      <td
                        class="text-right pl-0"
                        v-if="
                          roleName == 'ACADEMIC TEAM' ||
                          roleName == 'INFAKT TEACHER'
                        "
                      >
                        <v-btn
                          icon
                          large
                          color="primary"
                          :to="`/assignments/create?articleId=${content.id}`"
                        >
                          <v-icon dark> mdi-plus-box </v-icon>
                        </v-btn>
                        <v-menu bottom left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item>
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="primary"
                                    :to="`/articles/${content.id}/assignments`"
                                  >
                                    <v-icon dark size="35">
                                      mdi-clipboard-text
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>View Assignments</span>
                              </v-tooltip>
                            </v-list-item>
                            <v-list-item
                              v-if="
                                roleName == `ACADEMIC TEAM` || //If role is Academic team then they can only edit pending for Approval Content
                                content.is_draft == true || //Rest can only edit rejected or Drafted Content
                                content.is_approved == 2
                              "
                            >
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="primary"
                                    :to="`/contents/${content.id}`"
                                  >
                                    <v-icon dark size="35"> mdi-pencil </v-icon>
                                  </v-btn>
                                </template>
                                <span>Edit</span>
                              </v-tooltip>
                            </v-list-item>
                            <v-list-item
                              v-if="
                                content.is_approved == true //Rest can only create collection on approved Content
                              "
                            >
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <bookmark-dialog
                                    v-bind="attrs"
                                    v-on="on"
                                    :article="content"
                                  ></bookmark-dialog>
                                </template>
                                <span>Add Bookmark</span>
                              </v-tooltip>
                            </v-list-item>
                            <v-list-item>
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="primary"
                                    @click="generateAudio(content)"
                                  >
                                    <v-icon dark size="35"
                                      >mdi-playlist-music</v-icon
                                    >
                                  </v-btn>
                                </template>
                                <span>Generate Audio</span>
                              </v-tooltip>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                      <!-- roleName == `ACADEMIC TEAM` || //If role is Academic team then they can create collection on all Content -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <v-pagination v-model="page" :length="paginationLength">
            </v-pagination>
          </v-col>
        </v-row>
      </template>
    </v-container>
    <v-dialog v-model="is_audio_generating" width="600" persistent>
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: orange; font-size: 90px; width: 200px !important"
              >
                mdi-cloud-upload-outline
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: orange">Uploading...</h1>
                    <br />
                    <h3>The Audio is gettings generated. Please wait...</h3>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_audio_generated" width="600" persistent>
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: green; font-size: 90px; width: 200px !important"
              >
                mdi-cloud-upload-outline
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">Hurray!</h1>
                    <br />
                    <h3>Audio Generated</h3>
                    <br />
                    <v-btn color="success" @click="is_audio_generated = false">
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import BookmarkDialog from "@/components/BookmarkDialog.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { BookmarkDialog, Loader },
  name: "ContentsPage",
  data() {
    return {
      form: {
        search_keyword: "",
        date_filter: "",
        is_approved: "",
        created_by_id: "",
        is_active: "",
        selected_type: "",
        date_menu: false,
      },
      filter_menu: false,
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      RowsPerPage: 20,
      count: 0,
      contents: [],
      content_types: [],
      assignments: [],
      users: [],
      statusItems: [
        { id: "PENDING", text: "PENDING", value: "0" },
        { id: "APPROVED", text: "APPROVED", value: "1" },
        { id: "REJECTED", text: "REJECTED", value: "2" },
      ],
      contentTypeItems: ["ARTICLE", "INFOGRAPHIC", "VIDEO"],
      activeStatus: [
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      isLoading: false,
      date_menu: false,
      is_audio_generating: false,
      is_audio_generated: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    if (this.roleName == "INFAKT TEACHER") {
      this.statusItems.unshift({ id: "DRAFT", text: "DRAFT", value: "3" });
    }
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/contents/masters");
      masters = masters.data;
      masters.users.forEach((user) => {
        this.users.push({
          id: user.id,
          text: user.name,
          value: user.id,
        });
      });
    },
    getSelectedType(type) {
      this.form.selected_type = type;
      this.page = 1;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      this.form.selected_type =
        this.form.selected_type && this.form.selected_type != "View all"
          ? this.form.selected_type
          : "";
      this.form.search_keyword = this.form.search_keyword
        ? this.form.search_keyword
        : "";
      this.form.date_filter = this.form.date_filter
        ? this.form.date_filter
        : "";
      this.form.is_approved = this.form.is_approved
        ? this.form.is_approved
        : "";
      this.form.created_by_id = this.form.created_by_id
        ? this.form.created_by_id
        : "";
      this.form.is_active = this.form.is_active ? this.form.is_active : "";
      let contents = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.RowsPerPage +
        "&type=" +
        this.form.selected_type +
        "&is_active=" +
        this.form.is_active +
        "&created_by_id=" +
        this.form.created_by_id +
        "&is_approved=" +
        this.form.is_approved +
        "&search_keyword=" +
        this.form.search_keyword +
        "&date_filter=" +
        this.form.date_filter;
      contents = await axios.get(`infakt_contents?${query}`);
      this.contents = contents.data.data;
      this.count = contents.data.count;
      this.serialNoStarting = (this.page - 1) * this.RowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.RowsPerPage);
      this.content_types = contents.data.content_types;

      let assignments = await axios.get(`/assignments?${query}`);
      this.assignments = assignments.data.data;
      this.isLoading = false;
    },
    async generateAudio(content) {
      console.log(content.tts_audio_path);
      if (content.tts_audio_path != null && content.tts_audio_path != "") {
        let r = confirm(
          "The audio is already generated. Do you want to generate again"
        );
        if (r) {
          this.is_audio_generating = true;
          this.is_audio_generated = false;
          await axios.get(
            `/convertMaxDescriptionTextToSpeech?content_id=${content.id}&force=true`
          );
          this.is_audio_generating = false;
          this.is_audio_generated = true;
          this.getData();
        }
      } else {
        this.is_audio_generating = true;
        this.is_audio_generated = false;
        await axios.get(
          `/convertMaxDescriptionTextToSpeech?content_id=${content.id}&force=true`
        );
        this.is_audio_generating = false;
        this.is_audio_generated = true;
        this.getData();
      }
    },
  },
};
</script>
