<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <v-btn class="mx-2" fab small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          {{ form.headline }}
          <v-spacer></v-spacer>
          <epaper-bookmark-dialog
            :epaper="form"
            :epaper_type="'ET'"
          ></epaper-bookmark-dialog>
        </v-card-title>
        <v-card-text>
          {{ form.story_date }}
          <br />
          Category: {{ form.category }}
          <br />
          By {{ form.byline }}
        </v-card-text>
        <v-card-text>
          <div v-html="form.content"></div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import { consoleError } from "vuetify/lib/util/console";
import EpaperBookmarkDialog from "@/components/EpaperBookmarkDialog.vue";
export default {
  components: { EpaperBookmarkDialog },
  name: "TOIArticleViewPage",
  data() {
    return {
      form: {
        name: "",
        is_active: true,
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/et_articles/${this.$route.params.etId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
  },
};
</script>
