<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 150px">
          <span
            class="text-h5 font-weight-bold"
            v-text="`User Timestamp Logs`"
          ></span>
        </v-col>
      </v-row>
      <!-- <v-card elevation="6" shaped class="rounded-tl-xl my-5">
          <v-row class="pa-5">
            <v-col sm="2">
              <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date_filter"
                    label="Date"
                    class="mt-0"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.date_filter = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  hide-details
                  v-model="form.date_filter"
                  @change="date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="2">
              <v-menu
                ref="menu"
                v-model="month_year_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.month_year_filter"
                    label="Year - Month"
                    class="mt-0"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.month_year_filter"
                  type="month"
                  @change="month_year_menu = false"
                  no-title
                  scrollable
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              sm="2"
              v-if="
                roleName == 'SUPER ADMIN' ||
                roleName == 'ACADEMIC TEAM' ||
                (roleName == 'SUPER ADMIN' && company.id == null)
              "
            >
              <v-autocomplete
                ref="School"
                small-chips
                clearable
                :items="schoolItems"
                @click:clear="form.company_id = null"
                v-model="form.company_id"
                label="Schools"
                prepend-inner-icon="mdi-chair-school"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col sm="2">
              <v-autocomplete
                ref="Role"
                small-chips
                clearable
                @click:clear="form.role_id = null"
                v-model="form.role_id"
                :items="roleItems"
                label="Role"
                hide-details
                prepend-icon="mdi-domain"
              ></v-autocomplete>
            </v-col>
            <v-col md="2" class="pa-2">
              <v-text-field
                data-v-step="4"
                v-model="form.search_keyword"
                label="Search ... "
                prepend-inner-icon="mdi-magnify"
                @keydown.enter="getData"
                clearable
                @click:clear="form.search_keyword = null"
                @change="page = 1"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col sm="1" class="mt-3">
              <v-btn color="primary" data-v-step="5" @click="getData">
                SEARCH</v-btn
              >
            </v-col>
          </v-row>
        </v-card> -->
      <MyLoader v-if="isLoading"></MyLoader>
      <template v-else>
        <v-row>
          <v-col class="min-width:max-content"
            >Total Time Spent: {{ total_timespent | secToHhMm }}
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="5">
            <v-pagination v-model="page" :length="paginationLength">
            </v-pagination>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-card elevation="6">
              <v-card-actions>
                <v-spacer></v-spacer>
                <download-excel
                  class="ml-5"
                  v-show="log_excel.length"
                  title="User Timestamp Log"
                  :data="log_excel"
                  :fields="log_excel_fields"
                  worksheet="User Timestamp Log"
                  name="User-Timestamp-Log.xls"
                >
                  <v-btn color="primary">
                    <v-icon size="20">mdi-microsoft-excel</v-icon>
                    &nbsp;Download Log
                  </v-btn>
                </download-excel>
              </v-card-actions>
              <v-card-text>
                <v-simple-table fixed-header height="380px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left sr_no_class">Sr No</th>
                        <th class="text-center" style="min-width: 100px">
                          School Name
                        </th>
                        <th class="text-center" style="min-width: 100px">
                          Role
                        </th>
                        <th class="text-center" style="min-width: 200px">
                          Email
                        </th>
                        <th class="text-center" style="min-width: 100px">
                          Month
                        </th>
                        <th class="text-center" style="min-width: 100px">
                          Year
                        </th>
                        <th class="text-center" style="min-width: 115px">
                          Date
                        </th>
                        <th class="text-center" style="min-width: 100px">
                          Time
                        </th>
                        <th class="text-center" style="min-width: 100px">
                          Time Spent
                        </th>
                        <th class="text-center" style="min-width: 385px">
                          URL
                        </th>
                        <th class="text-center" style="min-width: 200px">
                          Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(user_timestamp, uT) in users_timestamps"
                        :key="`user${uT}`"
                      >
                        <td class="text-left">{{ uT + 1 }}</td>
                        <td class="text-center">
                          {{ user_timestamp.company.name }}
                        </td>
                        <td class="text-center">
                          {{ user_timestamp.user.roles[0].name }}
                        </td>
                        <td class="text-center">
                          {{ user_timestamp.user.email }}
                        </td>
                        <td class="text-center">
                          {{ user_timestamp.created_at | mm }}
                        </td>
                        <td class="text-center">
                          {{ user_timestamp.created_at | Y }}
                        </td>
                        <td class="text-center">
                          {{ user_timestamp.created_at | ddmmyyyy }}
                        </td>
                        <td class="text-center">
                          {{
                            SubtractTimeStamp(
                              user_timestamp.timestamp,
                              user_timestamp.total_time_spent
                            )
                          }}
                        </td>
                        <td class="text-center">
                          {{ user_timestamp.total_time_spent | secToHhMm }}
                        </td>
                        <td class="text-center">
                          {{ user_timestamp.url }}
                        </td>
                        <td class="text-center">
                          {{ user_timestamp.name }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      form: {
        date_filter: new Date().toISOString().slice(0, 10),
        month_year_filter: "",
      },
      schoolItems: [],
      roleItems: [],
      users_timestamps: [],
      log_excel_fields: {
        "Sr No": "sr_no",
        "School Name": "school_name",
        Role: "role",
        Email: "email",
        Month: "month",
        Year: "year",
        Date: "date",
        Time: "time",
        "Time Spent": "time_spent",
        URL: "url",
        Name: "name",
      },
      log_excel: [],
      total_count: "",
      total_timespent: "",
      isLoading: false,
      date_menu: false,
      month_year_menu: false,
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
    };
  },
  watch: {
    company: "getData",
    page: "getData",
  },
  mounted() {
    this.form.company_id = this.$route.query.company_id
      ? this.$route.query.company_id
      : "";
    this.form.date_filter = this.$route.query.date_filter
      ? this.$route.query.date_filter
      : "";
    this.form.month_year_filter = this.$route.query.month_year
      ? this.$route.query.month_year
      : "";
    this.form.user_id = this.$route.query.user_id
      ? this.$route.query.user_id
      : "";
    this.form.role_id = this.$route.query.role_id
      ? this.$route.query.role_id
      : "";

    this.getData();
    // this.getMasters();
  },
  methods: {
    SubtractTimeStamp(time, seconds) {
      return moment(time).subtract(seconds, "seconds").format("hh:mm:ss");
    },
    async getMasters() {
      let masters = await axios.get(`user_timestamps/masters`);
      masters = masters.data;
      console.log(masters);
      // Schools
      masters.schools.forEach((school) => {
        this.schoolItems.push({
          id: school.id,
          text: school.name,
          value: school.id,
        });
      });
      // Roles
      masters.roles.forEach((role) => {
        console.log(role);
        this.roleItems.push({
          id: role.id,
          text: role.name,
          value: role.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      this.form.type = "LOG_REPORT";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&type=" +
        this.form.type +
        "&date_filter=" +
        this.form.date_filter +
        "&month_year=" +
        this.form.month_year_filter +
        "&company_id=" +
        this.form.company_id +
        "&role_id=" +
        this.form.role_id +
        "&user_id=" +
        this.form.user_id;
      let users_timestamps = await axios.get(
        `/user_timestamps/report?${query}`
      );
      this.users_timestamps = users_timestamps.data.data;
      this.count = users_timestamps.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.total_timespent = users_timestamps.data.final_timespent;
      this.total_count = users_timestamps.data.final_count;
      await this.exportToUserTimestampLogExcel();
      this.isLoading = false;
    },
    async exportToUserTimestampLogExcel() {
      try {
        this.log_excel = [];
        let sr_no = 1;
        this.form.type = "LOG_REPORT";
        let query =
          "type=" +
          this.form.type +
          "&date_filter=" +
          this.form.date_filter +
          "&month_year=" +
          this.form.month_year_filter +
          "&company_id=" +
          this.form.company_id +
          "&role_id=" +
          this.form.role_id +
          "&user_id=" +
          this.form.user_id;
        let users_timestamps = await axios.get(
          `/user_timestamps/report?${query}`
        );
        this.users_timestamp_logs = users_timestamps.data.data;
        this.users_timestamp_logs.forEach((user_timestamp) => {
          let user_timestamp_log = {
            sr_no: sr_no,
            school_name: user_timestamp.company.name
              ? user_timestamp.company.name
              : "",
            role: user_timestamp.user.roles[0].name
              ? user_timestamp.user.roles[0].name
              : "",
            email: user_timestamp.user.email ? user_timestamp.user.email : "",
            month: user_timestamp.created_at
              ? moment(user_timestamp.created_at).format("MMMM")
              : "",
            year: user_timestamp.created_at
              ? moment(user_timestamp.created_at).format("YYYY")
              : "",
            date: user_timestamp.created_at
              ? moment(user_timestamp.created_at).format("DD-MM-YYYY")
              : "",
            time:
              user_timestamp.timestamp && user_timestamp.total_time_spent
                ? this.SubtractTimeStamp(
                    user_timestamp.timestamp,
                    user_timestamp.total_time_spent
                  )
                : "",
            time_spent: user_timestamp.total_time_spent
              ? this.formatTime(user_timestamp.total_time_spent)
              : "",
            url: user_timestamp.url ? user_timestamp.url : "",
            name: user_timestamp.name ? user_timestamp.name : "",
          };
          this.log_excel.push(user_timestamp_log);
          sr_no++;
        });
      } catch (error) {
        console.log(error);
      }
    },
    formatTime(value) {
      if (value == "" || value == null) return "00:00:00";

      var sec_num = parseInt(value, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? (v == 0 ? "00" : "0" + v) : v))
        .join(":");
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
