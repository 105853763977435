<template>
  <section>
    <!-- Assignment Details Dialog Box -->
    <v-dialog
      v-model="show_details"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card style="background: rgb(252, 239, 239)">
        <v-toolbar color="white">
          <v-btn icon @click="closeDialog(), (show_details = false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Assignment Preview</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid>
          <v-card class="mt-4 mb-4">
            <v-row align="center">
              <div style="flex: 1 1 auto"></div>
              <v-col sm="12">
                <v-card-text>
                  <v-row>
                    <v-col md="8">
                      Title :
                      <h1>
                        {{ assignment_details.assignment_title }}
                      </h1>
                    </v-col>
                    <v-col md="4">
                      Type :
                      <h3>
                        {{ assignment_details.assignment_type }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="assignment_details.assignment_type == 'DOCUMENT'"
                  >
                    <v-col md="4">
                      Uploaded Docs
                      <h3>
                        <v-tooltip right v-if="assignment_details.documentpath">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              class="mt-2"
                              target="_blank"
                              :href="`${mediaUrl}${assignment_details.documentpath}`"
                              >Link of Uploaded Document
                            </v-btn>
                          </template>
                          <span>Link to preview Uploaded Document</span>
                        </v-tooltip>
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row v-if="assignment_details.student_instructions">
                    <v-col md="12">
                      TSI Instructions :
                      <h3 v-html="assignment_details.student_instructions"></h3>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="mt-9 mb-4" color="grey lighten-3">
            <v-card-title> Questions </v-card-title>
            <v-card-text>
              <v-card
                v-for="(
                  assignment_question, aQ
                ) in assignment_details.assignment_questions"
                :key="`assignment_question_${aQ}`"
                elevation="6"
                class="pa-5 mb-5"
              >
                <v-row>
                  <v-col md="12">
                    <v-row>
                      <v-col md="1"
                        ><span>
                          <b>Q{{ aQ + 1 }}: </b>
                        </span>
                      </v-col>
                      <v-col md="2">
                        <span>
                          <b
                            >{{
                              assignment_question.marks == 1 ? `Mark` : `Marks`
                            }}:</b
                          >
                          {{ assignment_question.marks }}</span
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span> <b>Question:</b> </span>
                        <span v-html="assignment_question.description"> </span>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        assignment_details.assignment_type == 'OBJECTIVE' ||
                        (assignment_details.assignment_type == 'CUMULATIVE' &&
                          assignment_question.question_type == 'OBJECTIVE')
                      "
                    >
                      <v-col md="6">
                        <b>Option 1:</b> &nbsp;
                        <span
                          :class="
                            assignment_question.correct_options.includes(1)
                              ? 'success--text'
                              : 'error--text'
                          "
                          >{{ assignment_question.option1 }}</span
                        >
                      </v-col>
                      <v-col md="6">
                        <b>Option 2:</b> &nbsp;
                        <span
                          :class="
                            assignment_question.correct_options.includes(2)
                              ? 'success--text'
                              : 'error--text'
                          "
                          >{{ assignment_question.option2 }}</span
                        >
                      </v-col>
                      <v-col md="6" v-if="assignment_question.option3">
                        <b>Option 3:</b> &nbsp;
                        <span
                          :class="
                            assignment_question.correct_options.includes(3)
                              ? 'success--text'
                              : 'error--text'
                          "
                          >{{ assignment_question.option3 }}</span
                        >
                      </v-col>
                      <v-col md="6" v-if="assignment_question.option4">
                        <b>Option 4:</b> &nbsp;
                        <span
                          :class="
                            assignment_question.correct_options.includes(4)
                              ? 'success--text'
                              : 'error--text'
                          "
                          >{{ assignment_question.option4 }}</span
                        >
                      </v-col>
                      <v-col md="6" v-if="assignment_question.option5">
                        <b>Option 5:</b> &nbsp;
                        <span
                          :class="
                            assignment_question.correct_options.includes(5)
                              ? 'success--text'
                              : 'error--text'
                          "
                          >{{ assignment_question.option5 }}</span
                        >
                      </v-col>
                      <v-col md="6" v-if="assignment_question.option6">
                        <b>Option 6:</b> &nbsp;
                        <span
                          :class="
                            assignment_question.correct_options.includes(6)
                              ? 'success--text'
                              : 'error--text'
                          "
                          >{{ assignment_question.option6 }}</span
                        >
                      </v-col>
                    </v-row>
                    <v-row v-if="assignment_question.model_answer">
                      <v-col>
                        <span> <b>Modal Answer: </b> </span>
                        <span v-html="assignment_question.model_answer"> </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
export default {
  name: "Assignment-Preview",
  data() {
    return {
      //   assignment_details: {},
      //   show_details: false,
    };
  },
  props: ["assignment_details", "show_details"],
  mounted() {
    // this.assignment_details = this.assignment;
    // console.log(this.assignment);
    // this.show_details = true;
  },
  methods: {
    closeDialog() {
      //   this.show_details = false;
      this.$emit("changeStatus", false);
    },
  },
};
</script>
