<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 175px">
          <span
            class="text-h5 font-weight-bold"
            v-text="`Locked Classcodes`"
          ></span>
        </v-col>
        <v-col md="3" align="right">
          <v-pagination v-model="page" :length="paginationLength">
          </v-pagination>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left px-2" style="min-width: 70px">
                      Sr No
                    </th>
                    <th class="text-center" style="min-width: 80px">ID</th>
                    <th class="text-center" style="min-width: 90px">
                      Classcode
                    </th>
                    <th class="text-center" style="min-width: 115px">Title</th>
                    <th class="text-center" style="min-width: 75px">
                      Locked Lexile
                    </th>
                    <th class="text-center" style="min-width: 120px">
                      Created Date
                    </th>
                    <th class="text-center pl-0 pr-2" style="min-width: 140px">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(lockClasscode, i) in lock_classcodes"
                    :key="`lockClasscode_${i}`"
                  >
                    <td>{{ serialNoStarting + i + 1 }}</td>
                    <td class="text-center" style="min-width: 80px">
                      {{ lockClasscode.content_id }}
                    </td>
                    <td class="text-center" style="min-width: 115px">
                      <classcode-element
                        :classcode="lockClasscode.classcode.classcode"
                        :type="
                          roleName == 'STUDENT'
                            ? 'SUBJECT'
                            : roleName == 'TEACHER'
                            ? 'STANDARD_SUBJECT'
                            : ''
                        "
                      ></classcode-element>
                    </td>
                    <td class="text-center" style="min-width: 215px">
                      {{ lockClasscode.content.content_descriptions[0].title }}
                    </td>
                    <td class="text-center" style="min-width: 85px">
                      {{ lockClasscode.level }}
                    </td>
                    <td class="text-center" style="min-width: 150px">
                      {{ lockClasscode.created_at | MMddyyyy }}
                    </td>
                    <td class="text-center" style="min-width: 140px">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            color="error"
                            @click="
                              (isDeleteWarning = true),
                                (lock_classcode_id = lockClasscode.id)
                            "
                          >
                            <v-icon> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            target="_blank"
                            size="25"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            color="primary"
                            :to="PathToArticle(lockClasscode)"
                          >
                            <v-icon> mdi-eye-outline </v-icon>
                          </v-btn>
                        </template>
                        <span> View </span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Warning for Timed Assignment -->
    <v-dialog v-model="isDeleteWarning" max-width="300px" persistent>
      <v-card elevation="10" align="center">
        <v-card-title class="justify-center primary accent-1 white--text">
          Do you want to delete Locked classcode
        </v-card-title>
        <br />
        <v-card-text>
          <v-btn color="primary" @click.stop="DeleteIt(lock_classcode_id)">
            Yes
          </v-btn>
          &nbsp;
          <v-btn dark color="red" @click.stop="isDeleteWarning = false">
            No
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import Loader from "@/components/loader.vue";
export default {
  components: { Loader },
  name: "TeacherLockedLexilesPage",
  data() {
    return {
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      RowsPerPage: 20,
      count: 0,
      lock_classcodes: [],
      isLoading: false,
      isDeleteWarning: false,
      lock_classcode_id: "",
    };
  },
  mounted() {
    if (this.roleName == "TEACHER") {
      this.teacher_id = this.user.id;
    } else {
      this.teacher_id = this.$route.params.teacherId;
    }
    this.getData();
  },
  watch: {
    page: "getData",
  },
  methods: {
    PathToArticle(lockClasscode) {
      let response = "/articles/" + lockClasscode.content_id;
      if (lockClasscode.level != "MAX") {
        let selected_lexile_id =
          lockClasscode.content.content_descriptions.find(
            (cd) => cd.level == lockClasscode.level
          ).id;
        if (selected_lexile_id) {
          response += "/" + selected_lexile_id;
        }
      }
      return response;
    },
    async getData() {
      this.isLoading = true;
      let lock_classcodes = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.RowsPerPage +
        "&teacherId=" +
        this.teacher_id;
      lock_classcodes = await axios.get(`content_lock_classcodes?${query}`);
      this.lock_classcodes = lock_classcodes.data.data;
      this.count = lock_classcodes.data.count;
      this.serialNoStarting = (this.page - 1) * this.RowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.RowsPerPage);
      this.isLoading = false;
    },
    async DeleteIt(id) {
      let is_deleted = await axios.delete(`/content_lock_classcodes/${id}`);
      if (is_deleted) {
        this.isDeleteWarning = false;
      }
      this.page = 1;
      this.getData();
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: lock;
  white-space: nowrap;
}
.v-pagination__item {
  min-width: none;
}
</style>
