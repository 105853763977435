<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-hover
            v-for="(day, lpD) in lp.lp_days"
            :key="`day_${lpD}`"
            v-slot="{ hover }"
          >
            <v-card
              elevation="12"
              class="mb-5"
              :color="`${day.id == lpDayId ? 'secondary' : ''}`"
            >
              <v-card-title
                class="black--text justify-space-between"
                @click="(lpDayId = day.id), getData()"
              >
                <v-icon color="primary"> mdi-calendar-month-outline </v-icon>
                {{ day.title }}
                <section>
                  <v-icon
                    color="green"
                    v-if="CheckExecuteStatus(day, 'lp_day')"
                  >
                    mdi-check-circle
                  </v-icon>
                  <div v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                </section>
              </v-card-title>
              <v-expand-transition
                v-for="(element, lpDM) in day.lp_day_modules"
                :key="`element_${lpDM}`"
              >
                <v-container v-if="hover">
                  <v-row>
                    <v-col md="1">
                      <v-icon>mdi-arrow-right-bottom </v-icon>
                    </v-col>
                    <v-col>
                      <v-card
                        :elevation="`${IsActive(element) ? '10' : '6'}`"
                        @click="getElement(element)"
                        :color="`${
                          IsActive(element)
                            ? 'blue-grey darken-1' //Active
                            : 'blue-grey lighten-4' //Inactive
                        }`"
                        :dark="IsActive(element)"
                      >
                        <v-card-title class="pa-2">
                          {{ element.title }}
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-col>
        <v-col md="8" class="mr-5">
          <v-row>
            <v-col>
              <v-card elevation="12" class="mb-5">
                <v-card-title>
                  {{ lp.title }}
                  <template>
                    <v-icon>mdi-chevron-double-right</v-icon>
                    {{ lpDay.title }}
                  </template>
                  <template v-if="selectedElement.title">
                    <v-icon>mdi-chevron-double-right</v-icon>
                    {{ selectedElement.title }}
                  </template>
                  <v-spacer></v-spacer>
                  <v-tooltip left v-if="roleName == 'TEACHER'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-switch
                        v-bind="attrs"
                        v-on="on"
                        v-model="is_presentation_mode"
                        inset
                        prepend-icon="mdi-eye"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-switch>
                    </template>
                    <span> View Presention Mode </span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-progress-linear
                    background-color="amber"
                    color="primary"
                    height="10"
                    striped
                    rounded
                    stream
                    :value="Math.round((position / lp.lp_days?.length) * 100)"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <loader v-if="isLoading" indeterminate color="primary"></loader>
          <v-card elevation="12" v-else max-height="920" style="overflow: auto">
            <!-- Single Element View -->
            <template v-if="isElement == true && selectedElement">
              <v-card-title>
                <h1 class="text-h1 ma-4 ml-0">
                  {{ selectedElement.title }}
                </h1>
              </v-card-title>
              <v-card-text>
                <div
                  v-if="
                    selectedElement.is_description_presentable == true &&
                    is_presentation_mode == true
                  "
                  v-html="selectedElement.description"
                ></div>
                <!-- Day Details -->
                <v-row class="mt-5" style="min-width: 865px">
                  <template
                    v-for="(lp_detail, lpDM) in selectedElement.lp_details"
                  >
                    <v-col
                      :key="`col_${lpDM}`"
                      lg="6"
                      md="6"
                      sm="9"
                      xs="8"
                      style="min-width: 400px"
                      v-if="
                        lp_detail.is_presentable == true ||
                        is_presentation_mode == true
                      "
                    >
                      <v-row
                        :key="`detail_${lpDM}`"
                        class="mt-5 justify-space-between"
                      >
                        <v-col md="8" class="text-h6">
                          {{ lp_detail.detail_type }}
                          {{ lp_detail.is_presentable }}
                          <span v-if="lp_detail.document_path">
                            [{{ lp_detail.document_path | getExtension }}]
                          </span>
                          <hr />
                        </v-col>
                        <v-col md="4" class="pl-0">
                          <v-btn
                            :color="
                              CheckExecuteStatus(lp_detail, 'lp_detail')
                                ? 'success'
                                : 'primary'
                            "
                            @click="ShowDetails(lp_detail)"
                          >
                            Execute &nbsp;
                            <v-icon v-if="lp_detail.detail_type == 'Text'"
                              >mdi-text</v-icon
                            >
                            <v-icon v-if="lp_detail.detail_type == 'Article'"
                              >mdi-newspaper-variant-outline</v-icon
                            >
                            <v-icon v-if="lp_detail.detail_type == 'Assignment'"
                              >mdi-clipboard-text</v-icon
                            >
                            <v-icon v-if="lp_detail.detail_type == 'Document'"
                              >mdi-file-document</v-icon
                            >
                            <v-icon v-if="lp_detail.detail_type == 'Video'"
                              >mdi-video</v-icon
                            >
                            <v-icon
                              v-if="
                                lp_detail.detail_type ==
                                'Group Activity In Class'
                              "
                              >mdi-account-group</v-icon
                            >
                            <v-icon
                              v-if="
                                lp_detail.detail_type ==
                                'Group Activity Out of Class'
                              "
                              >mdi-account-group-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row></v-col
                    >
                  </template>
                </v-row>
                <!-- End of Day Details -->
              </v-card-text>
            </template>
            <!-- Single Day View -->
            <template v-else>
              <v-card-title>
                <h1 class="text-h1 ma-4 ml-0">
                  {{ lpDay.title }}
                </h1>
              </v-card-title>
              <v-card-text>
                <div v-html="lpDay.description"></div>
                <!-- Day Details -->
                <template v-for="(lp_detail, lpD) in lpDay.lp_details">
                  <v-row :key="`detail_${lpD}`" class="mt-5">
                    <v-col md="2" class="text-h6">
                      {{ lp_detail.detail_type }}
                      <hr />
                    </v-col>
                    <v-col md="2">
                      <v-btn color="primary" @click="ShowDetails(lp_detail)">
                        Execute &nbsp;
                        <v-icon v-if="lp_detail.detail_type == 'Text'"
                          >mdi-text</v-icon
                        >
                        <v-icon v-if="lp_detail.detail_type == 'Article'"
                          >mdi-newspaper-variant-outline</v-icon
                        >
                        <v-icon v-if="lp_detail.detail_type == 'Assignment'"
                          >mdi-clipboard-text</v-icon
                        >
                        <v-icon v-if="lp_detail.detail_type == 'Document'"
                          >mdi-file-document</v-icon
                        >
                        <v-icon v-if="lp_detail.detail_type == 'Video'"
                          >mdi-video</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <!-- End of Day Details -->
                <!-- Day Modules -->
                <template>
                  <v-expansion-panels class="my-5">
                    <v-expansion-panel
                      v-for="(lp_day_module, lpDM) in lpDay.lp_day_modules"
                      :key="`lpdays${lpDM}`"
                      class="my-2"
                    >
                      <v-expansion-panel-header color="#FFCDD2">
                        <p class="text-h6">
                          <v-icon v-if="lpDM == 0" size="40"
                            >mdi-numeric-1-circle</v-icon
                          >
                          <v-icon v-if="lpDM == 1" size="40"
                            >mdi-numeric-2-circle</v-icon
                          >
                          <v-icon v-if="lpDM == 2" size="40"
                            >mdi-numeric-3-circle</v-icon
                          >
                          {{ lp_day_module.title }}
                          <v-icon
                            :color="
                              CheckExecuteStatus(lp_day_module, 'lp_day_module')
                                ? 'green'
                                : ''
                            "
                          >
                            mdi-check-circle
                          </v-icon>
                        </p>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="mt-5">
                          <template v-if="lp_day_module.method">
                            <v-spacer></v-spacer>
                            <v-col md="2" class="text-h6" align="right"
                              >Method: {{ lp_day_module.method }}
                              <hr
                            /></v-col>
                          </template>
                          <v-col md="12">
                            <div v-html="lp_day_module.description"></div>
                          </v-col>
                        </v-row>
                        <!-- Day Module Details -->
                        <v-row
                          class="mt-5"
                          style="min-width: 465px; overflow: auto"
                        >
                          <template
                            v-for="(
                              lp_detail, lpDM
                            ) in lp_day_module.lp_details"
                          >
                            <v-col
                              :key="`col_${lpDM}`"
                              lg="6"
                              md="6"
                              sm="9"
                              xs="8"
                              style="min-width: 400px"
                              v-if="
                                lp_detail.is_presentable == true ||
                                is_presentation_mode == true
                              "
                            >
                              <v-row
                                :key="`detail_${lpDM}`"
                                class="mt-5 justify-space-between"
                              >
                                <v-col md="8" class="text-h6">
                                  {{ lp_detail.detail_type }}
                                  {{ lp_detail.is_presentable }}
                                  <span v-if="lp_detail.document_path">
                                    [{{
                                      lp_detail.document_path | getExtension
                                    }}]
                                  </span>
                                  <hr />
                                </v-col>
                                <v-col md="4" class="pl-0">
                                  <v-btn
                                    :color="
                                      CheckExecuteStatus(lp_detail, 'lp_detail')
                                        ? 'success'
                                        : 'primary'
                                    "
                                    @click="ShowDetails(lp_detail)"
                                  >
                                    Execute &nbsp;
                                    <v-icon
                                      v-if="lp_detail.detail_type == 'Text'"
                                      >mdi-text</v-icon
                                    >
                                    <v-icon
                                      v-if="lp_detail.detail_type == 'Article'"
                                      >mdi-newspaper-variant-outline</v-icon
                                    >
                                    <v-icon
                                      v-if="
                                        lp_detail.detail_type == 'Assignment'
                                      "
                                      >mdi-clipboard-text</v-icon
                                    >
                                    <v-icon
                                      v-if="lp_detail.detail_type == 'Document'"
                                      >mdi-file-document</v-icon
                                    >
                                    <v-icon
                                      v-if="lp_detail.detail_type == 'Video'"
                                      >mdi-video</v-icon
                                    >
                                    <v-icon
                                      v-if="
                                        lp_detail.detail_type ==
                                        'Group Activity In Class'
                                      "
                                      >mdi-account-group</v-icon
                                    >
                                    <v-icon
                                      v-if="
                                        lp_detail.detail_type ==
                                        'Group Activity Out of Class'
                                      "
                                      >mdi-account-group-outline</v-icon
                                    >
                                  </v-btn>
                                </v-col>
                              </v-row></v-col
                            >
                          </template>
                        </v-row>
                        <!-- End of Day Module Details -->
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
                <!-- End Of Day Modules -->
              </v-card-text>
            </template>
          </v-card>
        </v-col>
        <v-col md="2">
          <v-card elevation="12" class="mb-5 pa-4" color="blue-grey lighten-4">
            {{ classcode.classcode }}
            <h1 class="d-flex flex-row-reverse">
              <classcode-element
                :classcode="classcode.classcode"
                :type="
                  roleName == 'STUDENT'
                    ? 'SUBJECT'
                    : roleName == 'TEACHER'
                    ? 'STANDARD_SUBJECT'
                    : ''
                "
              ></classcode-element>
            </h1>
          </v-card>
          <v-card elevation="12">
            <v-card-title class="d-flex flex-row-reverse">
              <h3 class="secondary--text">Students</h3>
            </v-card-title>
            <hr />
            <v-simple-table fixed-header height="480px">
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(student, s) in students"
                    :key="`student_${s}`"
                    :class="{ 'striped-row': s % 2 !== 0 }"
                  >
                    <td :class="`d-flex justify-space-between`">
                      <v-icon
                        size="40"
                        :color="` ${s % 3 !== 0 ? 'primary' : ''}`"
                        >mdi-account-school</v-icon
                      >
                      <span class="mt-5" style="min-width: 130px">
                        {{ student.name }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-card elevation="12" class="mt-5">
            <v-card-title class="d-flex flex-row-reverse">
              <h3 class="secondary--text">Group</h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="1" class="pb-0">
                  <v-checkbox class="mt-0"> </v-checkbox>
                </v-col>
                <v-col class="pb-0">
                  <p class="text-h6 d-flex flex-row-reverse">Random</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="1">
                  <v-checkbox class="mt-0"> </v-checkbox>
                </v-col>
                <v-col>
                  <p class="text-h6 d-flex flex-row-reverse">
                    Performance Based
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="mt-0" align="right">
                  <v-btn color="secondary" @click="is_show_group_dialog = true"
                    >Form Groups</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Show Details Dialog Box -->
      <v-dialog
        v-model="show_details"
        fullscreen
        transition="dialog-bottom-transition"
        hide-overlay
      >
        <v-card elevation="12" style="background: rgb(252, 239, 239)">
          <v-toolbar color="white">
            <v-btn icon @click="show_details = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Detail</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container fluid>
            <v-card elevation="12" class="mt-4 mb-4">
              <v-row align="center">
                <div style="flex: 1 1 auto"></div>
                <v-col sm="12">
                  <v-card-text>
                    <v-row class="text-h6 pa-5" justify="center">
                      <div
                        v-if="detail.description"
                        v-html="detail.description"
                      ></div>
                      <div
                        v-if="detail.detail_type == 'Document'"
                        style="overflow: auto"
                        align="center"
                      >
                        <VueDocPreview
                          style="width: 1600px; min-height: 650px"
                          :value="`${mediaUrl + detail.document_path}`"
                          type="office"
                        />
                      </div>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Group Setting Dialog Box -->
      <v-dialog v-model="is_show_group_setting" width="750">
        <v-card dark color="blue-grey lighten-4">
          <v-container>
            <v-row align="center">
              <v-col align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1>
                        Group Settings
                        <v-icon size="45" class="mb-2"
                          >mdi-account-group</v-icon
                        >
                      </h1>
                      <br />
                      <v-row>
                        <v-col>
                          <v-autocomplete
                            ref="group_by"
                            v-model="group.group_by"
                            :items="groupByItems"
                            label="Group by"
                            chips
                            :error-messages="errors.group_by"
                            prepend-icon="mdi-star"
                          ></v-autocomplete>
                        </v-col>
                        <v-col>
                          <v-autocomplete
                            ref="name_type"
                            v-model="group.name_type"
                            :items="namingTypeItems"
                            label="Naming Type"
                            chips
                            :error-messages="errors.name_type"
                            prepend-icon="mdi-star"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-col class="mt-0" align="right">
                              <v-btn
                                color="secondary"
                                @click="CreateGroup(detail.no_of_groups)"
                                >Form Groups</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Grouping functionality Dialog Box -->
      <v-dialog v-model="is_show_group_dialog" width="750">
        <v-card dark color="blue-grey lighten-4">
          <v-container>
            <v-row align="center">
              <v-col align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 class="grey--text">
                        Select No. Of Groups
                        <v-icon size="45" class="mb-2"
                          >mdi-account-group</v-icon
                        >
                      </h1>
                      <br />
                      <v-row>
                        <v-col v-for="divisor in divisors" :key="divisor">
                          <v-card
                            color="secondary"
                            min-width="150"
                            @click="
                              (is_show_group_dialog = false),
                                CreateGroup(divisor)
                            "
                          >
                            <v-card-title>
                              <p class="text-h4 grey--text">{{ divisor }}</p>
                            </v-card-title></v-card
                          ></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Group oF Students Dialog Box -->
      <v-dialog v-model="is_show_grouped_students_dialog">
        <v-card min-height="350">
          <v-container fluid>
            <v-row align="center">
              <v-col align="center">
                <v-card-text>
                  <v-row style="overflow: auto" class="justify-center">
                    <!-- Groups -->
                    <template v-for="(group, g) in groups">
                      <v-col :key="`group_${g}`" md="5">
                        <v-card color="blue-grey lighten-4">
                          <v-card-title class="justify-center">
                            <p class="text-h3 black--text">Group {{ g + 1 }}</p>
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <!-- Students Loop -->
                              <v-col
                                v-for="(student, gS) in group"
                                :key="`student_${gS}`"
                              >
                                <v-card
                                  width="250"
                                  class="grey--text"
                                  color="secondary"
                                >
                                  <v-card-title>{{
                                    student.name
                                  }}</v-card-title>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col
                        class="align-self-center pa-0"
                        :key="`icon_${g}`"
                        v-if="groups.length > 1 && g + 1 != groups.length"
                      >
                        <v-icon size="150">mdi-sword-cross</v-icon>
                      </v-col>
                    </template>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  data() {
    return {
      hover: false,
      isLoading: false,
      isElement: false,
      show_details: false,
      is_presentation_mode: true,
      is_show_group_setting: false,
      is_show_group_dialog: false,
      is_show_grouped_students_dialog: false,
      students: [],
      divisors: [],
      groups: [],
      groupByItems: ["Random", "Mixed Performance", "Same Ability"],
      namingTypeItems: [
        {
          text: "Marvel",
          types: [
            "Iron Man",
            "Captain America",
            "Thor",
            "Hulk",
            "Black Widow",
            "Hawkeye",
            "Scarlet Witch",
            "Vision",
            "Black Panther",
            "Ant-Man",
          ],
        },
        {
          text: "Football",
          types: [
            "Barcelona",
            "Real Madrid",
            "Manchester United",
            "Liverpool",
            "Arsenal",
            "Chelsea",
            "Manchester City",
            "Paris Saint-Germain",
            "Bayern Munich",
            "Borussia Dortmund",
          ],
        },
        {
          text: "Color",
          types: [
            "Red",
            "Blue",
            "Green",
            "Yellow",
            "Black",
            "White",
            "Purple",
            "Orange",
            "Pink",
            "Brown",
          ],
        },
      ],
      group: {},
      detail: {},
      lpDay: {},
      classcode: {},
      lp: {},
      selectedElement: {},
      form: {},
      classcode_name: "",
      position: "",
      title: "Class Dashboard",
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.classcodeId = this.$route.query.classID;
    this.lpDayId = this.$route.query.dayID;
    this.getMaster();
    this.getData();
  },
  methods: {
    async getMaster() {
      let classcode = await axios.get(`/classcodes/${this.classcodeId}`);
      this.classcode = classcode.data.data;
      this.classcode_name = this.classcode.classcode.toString();
      let query = "role_id=5" + "&classcode_id=" + this.classcodeId;
      let students = await axios.get(`/users?${query}`);
      this.students = students.data.data;
      this.FindDivisors();
    },
    async getData() {
      this.isLoading = true;
      this.isElement = false;
      this.selectedElement = {};
      let day = await axios.get(`/lp_days/${this.lpDayId}`);
      this.lpDay = day.data.data;
      let lp = await axios.get(`/lps/${this.lpDay.lp_id}`);
      this.lp = lp.data.data;
      this.position = 0;
      this.lp.lp_days.forEach((day) => {
        if (day.id <= this.lpDayId) {
          this.position++;
        }
        day.hover = false;
      });
      this.isLoading = false;
    },
    async ShowDetails(detail) {
      this.detail = [];
      this.is_show_group_setting = false;
      this.detail = detail;
      if (this.detail) {
        if (this.detail.detail_type == "Article") {
          // console.log(this.detail.lp_detail_links[0].content_id);
          window.open(
            `/articles/${this.detail.lp_detail_links[0].content_id}`,
            "_blank" // <- This is what makes it open in a new window.
          );
        } else if (this.detail.detail_type == "Assignment") {
          // console.log(this.detail.lp_detail_links[0].content_id);
          window.open(
            `/assignments/create?copyFromAssignmentId=${this.detail.lp_detail_assignments[0].assignment_id}`,
            "_blank" // <- This is what makes it open in a new window.
          );
        } else if (this.detail.detail_type == "Group Activity In Class") {
          this.is_show_group_setting = true;
        } else {
          this.show_details = true;
        }
        this.createLog();
      }
    },
    getElement(element) {
      this.selectedElement = [];
      this.isElement = true;
      this.selectedElement = element;
      this.lpDayId = this.selectedElement.lp_day_id;
      this.position = 0;
      this.lp.lp_days.forEach((day) => {
        if (day.id <= this.lpDayId) {
          this.lpDay = day;
          this.position++;
        }
        day.hover = false;
      });
    },
    async createLog() {
      this.form.classcode_id = this.classcodeId;
      this.form.lp_detail_id = this.detail.id;
      let lp_detail_classcode = await axios.post(
        `/lp_detail_classcodes`,
        this.form
      );
      this.lp_detail_classcode = lp_detail_classcode.data.data;
      this.detail.lp_detail_classcodes.push(this.lp_detail_classcode);
      // this.lpDay.lp_day_modules.find((lpM) => {
      //   if (lpM.id == this.detail.lp_day_module_id) {
      //     lpM.lp_details.find((lpDetail) => {
      //       if (lpDetail.id == this.detail.id) {
      //         lpDetail.lp_detail_classcodes.push(this.lp_detail_classcode);
      //         console.log(lpDetail);
      //       }
      //     });
      //   }
      // });
      console.log("Created Log");
      console.log(this.detail);
    },
    CheckExecuteStatus(parent, type) {
      let response = false;
      if (type == "lp_detail") {
        parent.lp_detail_classcodes.forEach((ldC) => {
          if (this.classcodeId == ldC.classcode_id) {
            response = true;
          }
        });
      }
      if (type == "lp_day_module") {
        let total_detail_count = parent.lp_details.length;
        let executed_count = 0;
        parent.lp_details.forEach((lp_detail) => {
          lp_detail.lp_detail_classcodes.forEach((ldC) => {
            if (this.classcodeId == ldC.classcode_id) {
              executed_count++;
            }
          });
        });
        if (total_detail_count == executed_count) {
          response = true;
        }
      }
      if (type == "lp_day") {
        console.log("Debug Parent");
        console.log(parent);
        let total_detail_count = 0;
        let executed_count = 0;
        parent.lp_day_modules.forEach((element) => {
          element.lp_details.forEach((lp_detail) => {
            lp_detail.lp_detail_classcodes.forEach((ldC) => {
              if (this.classcodeId == ldC.classcode_id) {
                executed_count++;
              }
            });
            total_detail_count++;
          });
        });
        if (total_detail_count == executed_count) {
          response = true;
        }
        console.log("Day Execution status check");
        console.log(
          total_detail_count + " - " + executed_count + " --" + response
        );
      }
      return response;
    },
    IsActive(element) {
      let response = false;
      if (element.id == this.selectedElement.id) {
        response = true;
      }
      return response;
    },
    FindDivisors() {
      // let studentCount = 7;
      let studentCount = this.students.length;
      if (studentCount === null || studentCount <= 0) {
        this.divisors = [];
        return;
      }
      this.divisors = [];
      for (let i = 1; i <= studentCount; i++) {
        if (studentCount % i === 0) {
          this.divisors.push(i);
        }
      }
      if (this.divisors.length < 3) {
        studentCount = studentCount - 1;
        this.divisors = [];
        for (let i = 1; i <= studentCount; i++) {
          if (studentCount % i === 0) {
            this.divisors.push(i);
          }
        }
      }
    },
    CreateGroup(limit) {
      console.log(this.group.name_type);
      this.groups = [];
      const shuffledStudents = this.shuffleArray(this.students);
      const studentCount = this.students.length;
      const numGroups = limit;
      const groupSize = Math.round(studentCount / limit);
      let studentIndex = 0;
      const studentCopy = [];
      for (let i = 0; i < numGroups; i++) {
        const group = [];
        for (let j = 0; j < groupSize; j++) {
          group.push(shuffledStudents[studentIndex]);
          studentCopy.push(shuffledStudents[studentIndex]);
          studentIndex++;
        }
        this.groups.push(group);
      }
      let remainingStudent = this.students.filter((itemA) => {
        return !studentCopy.some((itemB) => itemB.id === itemA.id);
      });
      if (remainingStudent) {
        remainingStudent.forEach((student) => {
          this.groups[0].push(student);
        });
      }
      console.log(this.groups);
      this.is_show_grouped_students_dialog = true;
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    IsCompleted(parent, type) {
      let response = false;
      parent.lp_details.forEach((pDetail) => {
        /** Check Parent Details */
        if (
          pDetail.lp_detail_classcodes.length &&
          pDetail.lp_detail_classcodes[0].classcode_id == this.classcodeId
        )
          response = true;
      });
      if (type == "lp_days") {
        // IF Parent type is Lp Days
        parent.lp_day_modules.forEach((element) => {
          element.lp_details.forEach((eDetail) => {
            /** Check Element Details */

            if (
              eDetail.lp_detail_classcodes.length &&
              eDetail.lp_detail_classcodes[0].classcode_id == this.classcodeId
            )
              response = true;
            else {
              response = false;
            }
            console.log(response);
          });
        });
      }
      return response;
    },
  },
};
</script>
<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.striped-row {
  background-color: #f2f2f2; /* Set your desired background color */
}
</style>
