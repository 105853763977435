<template>
  <v-card color="primary" dark style="overflow: auto">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-h5">{{ totalReadsCount }}</v-card-title>
        <v-card-subtitle>Content Viewed</v-card-subtitle>
        <v-card-actions>
          <v-dialog v-model="isContentDialog" max-width="1200px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                v-bind="attrs"
                v-on="on"
                @click="getData"
              >
                View List
              </v-btn>
            </template>

            <v-card elevation="10">
              <v-container>
                <v-row>
                  <v-card-title style="min-width: 125px"
                    >Content Viewed</v-card-title
                  >
                  &nbsp; &nbsp;
                  <MyLoader class="mt-3" v-if="isLoading"></MyLoader>
                  <v-spacer></v-spacer>
                  <v-card-actions>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="isContentDialog = false"
                          icon
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Close</span>
                    </v-tooltip>
                  </v-card-actions>
                </v-row>
                <v-row>
                  <v-card-text>
                    <v-row>
                      <v-col
                        sm="4"
                        v-for="(contentType, cT) in contentWiseOverviewData"
                        :key="`overview${cT}`"
                      >
                        <SubCard
                          :text="contentType.name"
                          :value="contentType.count"
                        ></SubCard>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-row>
                <v-row>
                  <v-card-title style="min-width: 125px">Content</v-card-title>
                </v-row>
                <v-row>
                  <v-card-text>
                    <v-tabs
                      fixed-tabs
                      background-color="primary"
                      dark
                      center-active
                      centered
                      next-icon="mdi-arrow-right-bold-box-outline"
                      prev-icon="mdi-arrow-left-bold-box-outline"
                      show-arrows
                      v-model="contentWiseTab"
                    >
                      <v-tab
                        style="min-width: 140px"
                        v-for="(contentWise, cW) in contentWiseOverviewData"
                        :key="`contentWise${cW}`"
                        :href="`#over${cW}`"
                      >
                        {{ contentWise.name }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="contentWiseTab">
                      <v-tab-item
                        v-for="(contentWise, cW) in contentWiseOverviewData"
                        :key="`overview1${cW}`"
                        :value="`over${cW}`"
                      >
                        <v-simple-table fixed-header height="286px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left sr_no_class">Sr No</th>
                                <th class="text-center" style="min-width: 57px">
                                  Title
                                </th>
                                <th
                                  class="text-center"
                                  style="min-width: 105px"
                                >
                                  Content Type
                                </th>
                                <th
                                  class="text-center"
                                  style="min-width: 100px"
                                >
                                  Annotations
                                </th>
                                <th
                                  class="text-center"
                                  style="min-width: 100px"
                                >
                                  Highlights
                                </th>
                                <th
                                  class="text-center"
                                  style="min-width: 100px"
                                >
                                  Dictionary
                                </th>
                                <th
                                  class="text-center"
                                  style="min-width: 110px"
                                >
                                  Read Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value, i) in contentWise.values"
                                :key="`value${i}`"
                              >
                                <td>{{ i + 1 }}</td>
                                <td
                                  class="text-center"
                                  style="min-width: 150px"
                                >
                                  <router-link
                                    target="_blank"
                                    :to="`/articles/${value.content_id}`"
                                  >
                                    {{
                                      value.only_content.content_name | limit
                                    }}
                                  </router-link>
                                </td>
                                <td class="text-center td_content_type">
                                  {{ value.only_content.content_type }}
                                </td>
                                <td class="text-center">
                                  <v-icon
                                    v-if="
                                      value.only_content.content_metadatas.filter(
                                        (cm) => cm.metadata_type == 'ANNOTATION'
                                      ).length > 0
                                    "
                                    color="green"
                                  >
                                    mdi-marker-check
                                  </v-icon>
                                  <v-icon v-else color="red">
                                    mdi-close-octagon
                                  </v-icon>
                                </td>
                                <td class="text-center">
                                  <v-icon
                                    v-if="
                                      value.only_content.content_metadatas.filter(
                                        (cm) => cm.metadata_type == 'HIGHLIGHT'
                                      ).length > 0
                                    "
                                    color="green"
                                  >
                                    mdi-marker-check
                                  </v-icon>
                                  <v-icon v-else color="red">
                                    mdi-close-octagon
                                  </v-icon>
                                </td>
                                <td class="text-center">
                                  <v-icon
                                    v-if="
                                      value.only_content.content_metadatas.filter(
                                        (cm) => cm.metadata_type == 'DICTIONARY'
                                      ).length > 0
                                    "
                                    color="green"
                                  >
                                    mdi-marker-check
                                  </v-icon>
                                  <v-icon v-else color="red">
                                    mdi-close-octagon
                                  </v-icon>
                                </td>
                                <td class="text-center">
                                  {{ value.created_at | ddmmyyyy }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <!-- <v-pagination v-model="page" :length="6"></v-pagination> -->
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </div>

      <v-avatar class="ma-3" size="125" tile>
        <v-icon class="imgStyle" large dark>mdi-google-classroom</v-icon>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import SubCard from "./sub_card.vue";

export default {
  data() {
    return {
      page: "",
      contentWiseTab: "",
      contentWiseOverviewData: [],
      isContentDialog: false,
      isLoading: false,
    };
  },
  props: ["contentWiseOverview", "student_id"],
  watch: {
    page: "pageUpdated",
  },
  components: {
    SubCard,
  },
  computed: {
    totalReadsCount() {
      return this.contentWiseOverview[3].totalReadsCount;
    },
  },
  methods: {
    pageUpdated() {},
    async getData() {
      this.isLoading = true;
      this.contentWiseOverviewData = [];
      let response;
      let query = "student_id=" + this.student_id;
      response = await axios.get(
        `/student_wise_content_watch_overviews?${query}`
      );
      this.contentWiseOverviewData = response.data.data;
      this.isLoading = false;
    },
  },
};
</script>
