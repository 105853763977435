<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 105px">
          <span class="text-h5 font-weight-bold" v-text="'Departments'"></span>
          <addbutton :link="'/grades/create'"></addbutton>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 56px">Sr No</th>
                    <th class="text-left" style="min-width: 57px">Name</th>
                    <th class="text-left" style="min-width: 80px">is Active</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(grade, i) in grades" :key="grade.name">
                    <td>{{ i + 1 }}</td>
                    <td>{{ grade.name }}</td>
                    <td>{{ grade.is_active ? "TRUE" : "FALSE" }}</td>
                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/grades/${grade.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "GradesPage",
  data() {
    return {
      grades: [],
      isLoading: false,
    };
  },
  components: {
    addbutton,
    Loader,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let grades = await axios.get(`grades`);
      this.grades = grades.data.data;
      this.isLoading = false;
    },
  },
};
</script>
