<template>
  <v-main>
    <v-container>
      <br />
      <v-card v-if="collection.id">
        <v-card-title
          >Collection Details
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                target="_blank"
                class="my-auto ml-5"
                color="primary"
                v-bind="attrs"
                v-on="on"
                small
                :to="`/library/collections/${collection.id}`"
              >
                View
              </v-btn>
            </template>
            <span>View linked Collection</span>
          </v-tooltip></v-card-title
        >
        <v-card-text> {{ collection.collection_name }} </v-card-text>
      </v-card>
      <br />
      <v-row justify="start">
        <v-col>
          <v-btn class="mx-2" fab small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Already Created Assignments'"
          ></span>
        </v-col>
      </v-row>
      <br />
      <v-card elevation="6">
        <v-row>
          <v-col
            v-if="roleName == 'ACADEMIC TEAM' || roleName == 'INFAKT TEACHER'"
          >
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 65px">Sr No</th>
                    <th class="text-left" style="min-width: 135px">
                      Assignment Name
                    </th>
                    <th
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                      class="text-left"
                      style="min-width: 100px"
                    >
                      Status
                    </th>
                    <th
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                    >
                      Remarks
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(assignment, a) in assignments"
                    :key="`assignment${a}`"
                  >
                    <td>{{ a + 1 }}</td>
                    <td>{{ assignment.assignment_title }}</td>
                    <td
                      class="text-left pr-2"
                      style="min-width: 30px"
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="assignment.status == false"
                            id="Pending for Approval"
                            color="yellow"
                          >
                            mdi-progress-clock
                          </v-icon>
                        </template>
                        <span>Pending for Approval</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="assignment.status == true"
                            id="Approved"
                            color="green"
                          >
                            mdi-check-decagram
                          </v-icon>
                        </template>
                        <span>Approved</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                            id="Rejected"
                            v-if="assignment.status == 2"
                          >
                            mdi-close-octagon
                          </v-icon>
                        </template>
                        <span>Rejected</span>
                      </v-tooltip>
                    </td>
                    <td
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                      class="text-left pl-0 pr-2"
                      v-html="assignment.remarks"
                    ></td>
                    <td>
                      <v-btn
                        :to="`/assignments/create?copyFromAssignmentId=${assignment.id}`"
                        dark
                        small
                        color="primary"
                      >
                        Copy Assignment
                      </v-btn>
                      <v-btn
                        :to="`/assignments/${assignment.id}`"
                        dark
                        fab
                        x-small
                        class="mx-2"
                        color="primary"
                        v-if="
                          roleName == 'ACADEMIC TEAM' ||
                          roleName == 'INFAKT TEACHER'
                        "
                      >
                        <v-icon dark size="18"> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col v-else class="pt-0">
            <!-- Tab view for Teacher Login -->
            <v-tabs
              fixed-tabs
              background-color="primary"
              dark
              v-model="assignmentsTab"
            >
              <v-tab :href="`#assignmentLibrary`"> Infakt Team </v-tab>
              <v-tab :href="`#myAssignments`">Created By you</v-tab>
            </v-tabs>
            <v-tabs-items v-model="assignmentsTab">
              <v-tab-item :value="`assignmentLibrary`">
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 65px">Sr No</th>
                        <th class="text-left" style="min-width: 135px">
                          Assignment Name
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(assignment, a) in assignments_library"
                        :key="`assignment${a}`"
                      >
                        <td>{{ a + 1 }}</td>
                        <td>{{ assignment.assignment_title }}</td>
                        <td>
                          <v-btn
                            :to="`/assignments/create?copyFromAssignmentId=${assignment.id}`"
                            dark
                            small
                            color="primary"
                          >
                            Copy Assignment
                          </v-btn>
                          <v-btn
                            :to="`/assignments/${assignment.id}`"
                            dark
                            fab
                            x-small
                            class="mx-2"
                            color="primary"
                            v-if="
                              roleName == 'ACADEMIC TEAM' ||
                              roleName == 'INFAKT TEACHER'
                            "
                          >
                            <v-icon dark size="18"> mdi-pencil </v-icon>
                          </v-btn>
                          <v-btn
                            @click="ShowDetails(assignment.id)"
                            dark
                            fab
                            x-small
                            class="mx-2"
                            color="primary"
                            v-else
                          >
                            <v-icon dark size="18"> mdi-eye </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
              <v-tab-item :value="`myAssignments`">
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 65px">Sr No</th>

                        <th class="text-left" style="min-width: 85px">
                          Status
                        </th>
                        <th class="text-left" style="min-width: 135px">
                          Assignment Name
                        </th>
                        <th>Assigned Date</th>
                        <th>Classcode</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(assignment, a) in my_assignments"
                        :key="`assignment${a}`"
                      >
                        <td>{{ a + 1 }}</td>
                        <td>
                          {{
                            assignment.copied_from_assignment_id == null
                              ? "SELF"
                              : assignment.copied_from_assignment_id &&
                                assignment.is_modified
                              ? "MODIFIED"
                              : "COPIED"
                          }}
                        </td>
                        <td>{{ assignment.assignment_title }}</td>
                        <td class="text-left pr-2" style="min-width: 30px">
                          {{ assignment.created_at || ddmmyyyy }}
                        </td>
                        <td class="text-left pl-0 pr-2" style="min-width: 30px">
                          {{
                            assignment.assignment_classcodes
                              ?.map(
                                (classcode) => classcode.classcode.classcode
                              )
                              .join(", ")
                          }}
                        </td>
                        <td>
                          <v-btn
                            :to="`/assignments/create?copyFromAssignmentId=${assignment.id}`"
                            dark
                            small
                            color="primary"
                          >
                            Copy Assignment
                          </v-btn>
                          <v-btn
                            :to="`/assignments/${assignment.id}`"
                            dark
                            fab
                            x-small
                            class="mx-2"
                            color="primary"
                            v-if="
                              roleName == 'ACADEMIC TEAM' ||
                              roleName == 'INFAKT TEACHER'
                            "
                          >
                            <v-icon dark size="18"> mdi-pencil </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
      <!-- Assignment Details Dialog Box -->
      <v-dialog
        v-model="show_details"
        fullscreen
        transition="dialog-bottom-transition"
        hide-overlay
      >
        <v-card style="background: rgb(252, 239, 239)">
          <v-toolbar color="white">
            <v-btn icon @click="show_details = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Assignment Preview</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container fluid>
            <v-card class="mt-4 mb-4">
              <v-row align="center">
                <div style="flex: 1 1 auto"></div>
                <v-col sm="12">
                  <v-card-text>
                    <v-row>
                      <v-col md="8">
                        Title :
                        <h1>
                          {{ assignment_details.assignment_title }}
                        </h1>
                      </v-col>
                      <v-col md="4">
                        Type :
                        <h3>
                          {{ assignment_details.assignment_type }}
                        </h3>
                      </v-col>
                    </v-row>
                    <v-row v-if="assignment_details.student_instructions">
                      <v-col md="12">
                        Student Instructions :
                        <h3
                          v-html="assignment_details.student_instructions"
                        ></h3>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="mt-9 mb-4" color="grey lighten-3">
              <v-card-title> Questions </v-card-title>
              <v-card-text>
                <v-card
                  v-for="(
                    assignment_question, aQ
                  ) in assignment_details.assignment_questions"
                  :key="`assignment_question_${aQ}`"
                  elevation="6"
                  class="pa-5 mb-5"
                >
                  <v-row>
                    <v-col md="12">
                      <v-row>
                        <v-col md="1"
                          ><span>
                            <b>Q{{ aQ + 1 }}: </b>
                          </span>
                        </v-col>
                        <v-col md="2">
                          <span>
                            <b> Marks:</b> {{ assignment_question.marks }}</span
                          >
                        </v-col>
                        <v-col>
                          <span>
                            <b>Question Type: </b>
                            {{ assignment_question.question_type }}</span
                          >
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="
                          assignment_details.assignment_type == 'OBJECTIVE' ||
                          (assignment_details.assignment_type == 'CUMULATIVE' &&
                            assignment_question.question_type == 'OBJECTIVE')
                        "
                      >
                        <v-col md="6">
                          <b>Option 1:</b> &nbsp;
                          <span>{{ assignment_question.option1 }}</span>
                        </v-col>
                        <v-col md="6">
                          <b>Option 2:</b> &nbsp;
                          <span>{{ assignment_question.option2 }}</span>
                        </v-col>
                        <v-col md="6" v-if="assignment_question.option3">
                          <b>Option 3:</b> &nbsp;
                          <span>{{ assignment_question.option3 }}</span>
                        </v-col>
                        <v-col md="6" v-if="assignment_question.option4">
                          <b>Option 4:</b> &nbsp;
                          <span>{{ assignment_question.option4 }}</span>
                        </v-col>
                        <v-col md="6" v-if="assignment_question.option5">
                          <b>Option 5:</b> &nbsp;
                          <span>{{ assignment_question.option5 }}</span>
                        </v-col>
                        <v-col md="6" v-if="assignment_question.option6">
                          <b>Option 6:</b> &nbsp;
                          <span>{{ assignment_question.option6 }}</span>
                        </v-col>
                      </v-row>
                      <v-row v-if="assignment_question.model_answer">
                        <v-col>
                          <span> <b>Modal Answer: </b> </span>
                          <span v-html="assignment_question.model_answer">
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  name: "ArticleAssignments",
  data() {
    return {
      assignmentsTab: "",
      assignments: [],
      assignment_details: {},
      my_assignments: [],
      assignments_library: [],
      collection: {},
      show_details: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let collectionId = this.$route.params.collectionId;
      // If Article Id is passed
      let collection;
      if (collectionId) {
        collection = await axios.get(`/collections/${collectionId}`);
        this.collection = collection.data.data;
      }
      let response = await axios.get(
        `assignments?collectionId=${this.$route.params.collectionId}`
      );
      this.assignments = response.data.data;
      if (this.roleName == "TEACHER") {
        this.my_assignments = [];
        this.assignments_library = [];
        this.assignments.forEach((assignment) => {
          if (assignment.created_by_id == this.user.id) {
            this.my_assignments.push(assignment);
          } else {
            this.assignments_library.push(assignment);
          }
        });
      }
    },
    async ShowDetails(id) {
      this.assignment_details = [];
      let assignment = await axios.get(`/assignments/${id}`);
      this.assignment_details = assignment.data.data;
      if (this.assignment_details) {
        this.show_details = true;
      }
      console.log(this.assignment_details);
    },
  },
};
</script>
