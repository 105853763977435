<template>
  <v-main>
    <v-container>
      <br />
      <v-card v-if="content.id">
        <v-card-title
          >Article Details
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                target="_blank"
                class="my-auto ml-5"
                color="primary"
                v-bind="attrs"
                v-on="on"
                small
                :to="`/articles/${content.id}`"
              >
                View
              </v-btn>
            </template>
            <span>View linked Article</span>
          </v-tooltip></v-card-title
        >
        <v-card-text> {{ content.content_name }} </v-card-text>
      </v-card>
      <br />
      <v-row justify="start">
        <v-col>
          <v-btn class="mx-2" fab small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Already Created Assignments'"
          ></span>
        </v-col>
      </v-row>
      <br />
      <v-card elevation="6">
        <v-row>
          <v-col
            v-if="roleName == 'ACADEMIC TEAM' || roleName == 'INFAKT TEACHER'"
          >
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 65px">Sr No</th>
                    <th class="text-left" style="min-width: 135px">
                      Assignment Name
                    </th>
                    <th class="text-left" style="min-width: 135px">
                      Assignment Type
                    </th>
                    <th class="text-left" style="min-width: 100px">
                      Lexile Level
                    </th>
                    <th
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                      class="text-left"
                      style="min-width: 100px"
                    >
                      Status
                    </th>
                    <th
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                    >
                      Remarks
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(assignment, a) in assignments"
                    :key="`assignment${a}`"
                  >
                    <td>{{ a + 1 }}</td>
                    <td>{{ assignment.assignment_title }}</td>
                    <td>{{ assignment.assignment_type }}</td>
                    <td>
                      {{
                        assignment.content_description
                          ? assignment.content_description.level
                          : ""
                      }}
                    </td>
                    <td
                      class="text-left pr-2"
                      style="min-width: 30px"
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="assignment.status == false"
                            id="Pending for Approval"
                            color="yellow"
                          >
                            mdi-progress-clock
                          </v-icon>
                        </template>
                        <span>Pending for Approval</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="assignment.status == true"
                            id="Approved"
                            color="green"
                          >
                            mdi-check-decagram
                          </v-icon>
                        </template>
                        <span>Approved</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                            id="Rejected"
                            v-if="assignment.status == 2"
                          >
                            mdi-close-octagon
                          </v-icon>
                        </template>
                        <span>Rejected</span>
                      </v-tooltip>
                    </td>
                    <td
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                      class="text-left pl-0 pr-2"
                      v-html="assignment.remarks"
                    ></td>
                    <td>
                      <v-btn
                        :to="`/assignments/create?copyFromAssignmentId=${assignment.id}`"
                        dark
                        small
                        color="primary"
                      >
                        Copy Assignment
                      </v-btn>
                      <v-btn
                        :to="`/assignments/${assignment.id}`"
                        dark
                        fab
                        x-small
                        class="mx-2"
                        color="primary"
                        v-if="
                          roleName == 'ACADEMIC TEAM' ||
                          roleName == 'INFAKT TEACHER'
                        "
                      >
                        <v-icon dark size="18"> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col v-else class="pt-0">
            <!-- Tab view for Teacher Login -->
            <v-tabs
              fixed-tabs
              background-color="primary"
              dark
              v-model="assignmentsTab"
            >
              <v-tab :href="`#assignmentLibrary`"> Infakt Team </v-tab>
              <v-tab :href="`#myAssignments`">Created By you</v-tab>
            </v-tabs>
            <v-tabs-items v-model="assignmentsTab">
              <v-tab-item :value="`assignmentLibrary`">
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 65px">Sr No</th>
                        <th class="text-left" style="min-width: 135px">
                          Assignment Name
                        </th>
                        <th class="text-left" style="min-width: 135px">
                          Assignment Type
                        </th>
                        <th class="text-left" style="min-width: 100px">
                          Lexile Level
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(assignment, a) in assignments_library"
                        :key="`assignment${a}`"
                      >
                        <td>{{ a + 1 }}</td>
                        <td>{{ assignment.assignment_title }}</td>
                        <td>{{ assignment.assignment_type }}</td>
                        <td>
                          {{
                            assignment.content_description
                              ? assignment.content_description.level
                              : ""
                          }}
                        </td>
                        <td>
                          <v-btn
                            :to="`/assignments/create?copyFromAssignmentId=${assignment.id}`"
                            dark
                            small
                            color="primary"
                          >
                            Copy Assignment
                          </v-btn>
                          <v-btn
                            :to="`/assignments/${assignment.id}`"
                            dark
                            fab
                            x-small
                            class="mx-2"
                            color="primary"
                            v-if="
                              roleName == 'ACADEMIC TEAM' ||
                              roleName == 'INFAKT TEACHER'
                            "
                          >
                            <v-icon dark size="18"> mdi-pencil </v-icon>
                          </v-btn>
                          <v-btn
                            @click="ShowDetails(assignment.id)"
                            dark
                            fab
                            x-small
                            class="mx-2"
                            color="primary"
                            v-else
                          >
                            <v-icon dark size="18"> mdi-eye </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
              <v-tab-item :value="`myAssignments`">
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 65px">Sr No</th>

                        <th class="text-left" style="min-width: 85px">
                          Status
                        </th>
                        <th class="text-left" style="min-width: 135px">
                          Assignment Name
                        </th>
                        <th class="text-left" style="min-width: 135px">
                          Assignment Type
                        </th>
                        <th class="text-left" style="min-width: 100px">
                          Lexile Level
                        </th>
                        <th>Assigned Date</th>
                        <th>Classcode</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(assignment, a) in my_assignments"
                        :key="`assignment${a}`"
                      >
                        <td>{{ a + 1 }}</td>
                        <td>
                          {{
                            assignment.copied_from_assignment_id == null
                              ? "SELF"
                              : assignment.copied_from_assignment_id &&
                                assignment.is_modified
                              ? "MODIFIED"
                              : "COPIED"
                          }}
                        </td>
                        <td>{{ assignment.assignment_title }}</td>
                        <td>{{ assignment.assignment_type }}</td>
                        <td>
                          {{
                            assignment.content_description
                              ? assignment.content_description.level
                              : ""
                          }}
                        </td>
                        <td class="text-left pr-2" style="min-width: 30px">
                          {{ assignment.created_at || ddmmyyyy }}
                        </td>
                        <td class="text-left pl-0 pr-2" style="min-width: 30px">
                          {{
                            assignment.assignment_classcodes
                              ?.map(
                                (classcode) => trim_classcode(classcode.classcode.classcode)
                              )
                              .join(", ")
                          }}
                        </td>
                        <td>
                          <v-btn
                            :to="`/assignments/create?copyFromAssignmentId=${assignment.id}`"
                            dark
                            small
                            color="primary"
                          >
                            Copy Assignment
                          </v-btn>
                          <v-btn
                            :to="`/assignments/${assignment.id}`"
                            dark
                            fab
                            x-small
                            class="mx-2"
                            color="primary"
                            v-if="
                              roleName == 'ACADEMIC TEAM' ||
                              roleName == 'INFAKT TEACHER'
                            "
                          >
                            <v-icon dark size="18"> mdi-pencil </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
      <!-- Assignment Details Dialog Box -->
      <assignment-preview
        v-model="show_details"
        @changeStatus="changeStatus"
        :assignment_details="assignment_details"
        :show_details="show_details"
      ></assignment-preview>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import assignmentPreview from "@/components/assignment-preview.vue";

export default {
  components: { assignmentPreview },
  name: "ArticleAssignments",
  data() {
    return {
      assignmentsTab: "",
      assignments: [],
      assignment_details: {},
      my_assignments: [],
      assignments_library: [],
      content: {},
      show_details: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let articleId = this.$route.params.articleId;
      // If Article Id is passed
      let content;
      if (articleId) {
        content = await axios.get(`/contents/${articleId}`);
        this.content = content.data.data;
      }
      let response = await axios.get(
        `assignments?articleId=${this.$route.params.articleId}`
      );
      this.assignments = response.data.data;
      if (this.roleName == "TEACHER") {
        this.my_assignments = [];
        this.assignments_library = [];
        this.assignments.forEach((assignment) => {
          if (assignment.created_by_id == this.user.id) {
            this.my_assignments.push(assignment);
          } else {
            this.assignments_library.push(assignment);
          }
        });
      }
    },
    async ShowDetails(id) {
      this.assignment_details = [];
      let assignment = await axios.get(`/assignments/${id}`);
      this.assignment_details = assignment.data.data;
      if (this.assignment_details) {
        this.show_details = true;
      }
      console.log(this.assignment_details);
    },
    changeStatus() {
      this.show_details = false;
      console.log(this.show_details);
    },
    trim_classcode(classcode) {
      let originalClasscode = classcode;
      let classcode_array = classcode.split("/");
      let standard_section = classcode_array[1];
      let subject_section = classcode_array[2];
      let trimmed_classcode = "";
      if (this.roleName == "STUDENT") {
        trimmed_classcode = subject_section;
      } else if (this.roleName == "TEACHER") {
        trimmed_classcode = standard_section + "/" + subject_section;
      } else {
        trimmed_classcode = originalClasscode;
      }
      return trimmed_classcode;
    },
  },
};
</script>
