<template>
  <v-main>
    <v-container
      ><!-- Recently Onboarded Sections -->
      <br />
      <v-row justify="start">
        <v-col style="min-width: 170px">
          <v-btn class="mx-2 mb-2" fab x-small color="primary" to="/standards">
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          <span class="text-h5 font-weight-bold" v-text="title"></span>
          <addbutton
            :link="`/standards/${standard.id}/sections/create`"
          ></addbutton>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="section"
            v-model="form.section_id"
            :items="sectionItems"
            label="Department"
            prepend-icon="mdi-account-school"
          ></v-autocomplete>
        </v-col>
        <!-- <v-col sm="2"> </v-col> -->
        <v-col sm="6"> </v-col>
        <v-col sm="1" class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
        <v-col sm="2" class="mt-3">
          <v-btn
            class="ml-9 my-auto"
            fab
            x-small
            :color="is_grid == true ? 'primary' : ''"
            @click="is_grid = true"
          >
            <v-icon dark> mdi-grid </v-icon>
          </v-btn>
          |
          <v-btn
            class="my-auto"
            fab
            x-small
            :color="is_grid == false ? 'primary' : ''"
            @click="is_grid = false"
          >
            <v-icon> mdi-format-list-numbered </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <div v-if="is_grid == true">
        <v-row>
          <v-col sm="3" v-for="section in sections" :key="section.name">
            <v-card elevation="6" shaped>
              <v-col>
                <v-img
                  height="150"
                  contain
                  :src="require('@/assets/imgs/section.png')"
                ></v-img>
              </v-col>
              <!-- :src="require('@/assets/imgs/section_default_photo.jpeg')" -->

              <v-card-title class="justify-center wrap">
                Department: {{ section.name }}</v-card-title
              >

              <v-card-text>
                <div class="wrap">
                  Designation Count : {{ section.classcodes.length }}
                </div>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                <v-chip-group
                  active-class="deep-purple accent-4 white--text"
                  column
                >
                  <!-- <v-chip>{{
                    section.is_active ? "Active" : "Inactive"
                  }}</v-chip> -->
                  <v-btn
                    class="ml-2 my-auto"
                    small
                    color="primary"
                    :to="`/standards/${standard.id}/sections/${section.id}/classes`"
                    >designations
                  </v-btn>
                  <v-btn
                    class="ml-3 my-auto"
                    fab
                    x-small
                    color="primary"
                    :to="`/standards/${standard.id}/sections/${section.id}`"
                  >
                    <v-icon dark> mdi-pencil </v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        dark
                        :color="`${section.is_active ? 'green' : 'red'}`"
                      >
                        mdi-circle
                      </v-icon>
                    </template>
                    <span>{{ section.is_active ? "Active" : "Inactive" }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 56px">Sr No</th>
                    <th class="text-center" style="min-width: 85px">
                      Section Name
                    </th>
                    <th class="text-center" style="min-width: 80px">
                      Is Active
                    </th>
                    <th class="text-center" style="min-width: 85px">
                      Class count
                    </th>
                    <th class="text-right" style="min-width: 70px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(section, i) in sections" :key="section.name">
                    <td>{{ i + 1 }}</td>

                    <td class="text-center">{{ section.name }}</td>
                    <td class="text-center">
                      {{ section.is_active ? "YES" : "NO" }}
                    </td>
                    <td class="text-center">{{ section.classcodes.length }}</td>
                    <td class="text-right">
                      <v-btn
                        small
                        color="primary"
                        :to="`/standards/${standard.id}/sections/${section.id}/classes`"
                        >classes
                      </v-btn>
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/standards/${standard.id}/sections/${section.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";

export default {
  components: { addbutton, Loader },
  data() {
    return {
      form: {
        section_id: "",
      },
      sections: [],
      sectionItems: [
        {
          id: 0,
          text: "Select Section",
          value: 0,
        },
      ],
      standard: {},
      title: "",
      isLoading: false,
      is_grid: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.form.section_id = this.form.section_id ? this.form.section_id : "";

      let standard = await axios.get(
        `standards/${this.$route.params.standardId}`
      );
      this.standard = standard.data.data;

      let sections = [];
      let query = "section_id=" + this.form.section_id;
      sections = await axios.get(
        `/standards/${this.$route.params.standardId}/sections?${query}`
      );
      this.sections = sections.data.data;
      this.sections.forEach((section) => {
        this.sectionItems.push({
          id: section.id,
          text: section.name,
          value: section.id,
        });
      });
      this.title = "Sections of " + this.standard.name;
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
