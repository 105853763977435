<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 165px">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Student Wise Report'"
          ></span>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="student"
            v-model="selectedStudentId"
            :items="studentItems"
            clearable
            :error-messages="errors.studentId"
            label="Select Student"
            prepend-icon="mdi-domain"
          ></v-autocomplete>
        </v-col>
        <v-col></v-col>
        <v-col class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <!--/ Filters Section -->

      <!-- Reports Section -->
      <loader v-if="isLoading"></loader>
      <div v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-text>
              <top-classcode
                :source="`REPORTS`"
                v-if="assignmentOverview"
                :assignmentOverview="assignmentOverview"
              ></top-classcode>
            </v-card-text>
          </v-card>
        </v-col>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import TopClasscode from "../dashboards/superadmin-dashboard/student_school_data/topClasscode.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "StudentWiseReport",
  data() {
    return {
      isLoading: false,
      students: [],
      studentItems: [],
      assignmentOverview: [],
      selectedStudentId: "",
      schoolId: "",
    };
  },
  components: {
    TopClasscode,
    Loader,
  },
  mounted() {
    this.schoolId = this.company.id;
    this.currentSubType = "Student";
    this.getMasters();
  },

  methods: {
    async getMasters() {
      this.isLoading = true;
      console.log(this.user.students);
      let students = [];
      if (this.roleName == "TEACHER") {
        let query = "role_id=5" + "&teacher_id=" + this.user.id;
        students = await axios.get(`/users?${query}`);
      } else {
        students = await axios.get(`/users?role_id=5`);
      }
      this.students = students.data.data;
      this.students.forEach((student) => {
        this.studentItems.push({
          id: student.id,
          text: student.name,
          value: student.id,
        });
      });
      this.isLoading = false;
    },
    async getData() {
      this.isLoading = true;
      try {
        let response;
        response = await axios.get(
          `/student_wise_overview?companyId=${this.schoolId}&studentId=${this.selectedStudentId}&source=REPORT`
        );
        response = response.data;
        this.assignmentOverview = response.assignmentOverview;
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    // createPDF() {
    //   var element = document.getElementById("mydiv");

    //   var opt = {
    //     margin: 10,
    //     filename: "Student Wise Performace Report.pdf",
    //     image: { type: "jpeg", quality: 0.98 },
    //     html2canvas: { scale: 4 },
    //     jsPDF: {
    //       unit: "mm",
    //       format: [450, 500],
    //       orientation: "portrait",
    //     },
    //   };

    //   html2pdf().set(opt).from(element).save();
    // },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
