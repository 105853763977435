<template>
  <MyLoader v-if="is_Loading"></MyLoader>
  <section v-else>
    <v-row>
      <!-- Avg. Time Spent By Student -->
      <v-col sm="3">
        <!-- <router-link class="routerLink"
         to="/dashboard/user-timestamp?role_id=5" 
        > -->
        <v-card color="red accent-1" dark style="overflow: auto">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div style="min-width: 150px">
              <v-card-title class="text-h5">
                {{
                  data.counts.totalTimeSpentByStudent | secToHhMm
                }}</v-card-title
              >
              <v-card-subtitle>Total Time Spent By TSIs</v-card-subtitle>
            </div>
            <v-avatar class="ma-3" size="125" tile>
              <v-icon class="imgStyle" large dark
                >mdi-account-clock-outline</v-icon
              >
            </v-avatar>
          </div>
        </v-card>
        <!-- </router-link> -->
      </v-col>
      <!-- End Avg. Time Spent By Student -->
      <!-- Avg. Time Spent By Teacher -->
      <v-col sm="3">
        <!-- <router-link to=""> 
        class="routerLink"
            to="/dashboard/user-timestamp?role_id=3" -->
        <v-card color="red accent-1" dark style="overflow: auto">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div style="min-width: 150px">
              <v-card-title class="text-h5">
                {{
                  data.counts.totalTimeSpentByTeacher | secToHhMm
                }}</v-card-title
              >
              <v-card-subtitle>Total Time Spent By Coordinators</v-card-subtitle>
            </div>
            <v-avatar class="ma-3" size="125" tile>
              <v-icon class="imgStyle" large dark
                >mdi-briefcase-clock-outline</v-icon
              >
            </v-avatar>
          </div>
        </v-card>
        <!-- </router-link> -->
      </v-col>
      <!-- End Avg. Time Spent By Teacher -->
      <!-- Content Read Count -->
      <v-col sm="3">
        <v-card
          @click="getContentRead()"
          color="red accent-1"
          dark
          style="overflow: auto"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div style="min-width: 150px">
              <v-card-title class="text-h5">{{
                data.counts.contentReads
              }}</v-card-title>
              <v-card-subtitle>Content Read Count</v-card-subtitle>
            </div>
            <v-avatar class="ma-3" size="125" tile>
              <v-icon class="imgStyle" large dark>mdi-clipboard-text</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <!-- End Content Read Count -->
      <!-- Total Assignments Posted -->
      <v-col sm="3">
        <v-card
          color="red accent-1"
          dark
          style="overflow: auto"
          @click="getAssigmentPosted()"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div style="min-width: 150px">
              <v-card-title class="text-h5">{{
                data.counts.assignmentsPosted
              }}</v-card-title>
              <v-card-subtitle> Total Assignments Posted</v-card-subtitle>
            </div>
            <v-avatar class="ma-3" size="125" tile>
              <v-icon class="imgStyle" large dark>mdi-book-open</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <!-- End Total Assignments Posted -->
    </v-row>
    <br />
    <v-row>
      <!-- Total Students -->
      <v-col sm="4">
        <v-card color="brown accent-1" dark style="overflow: auto">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5">{{
                data.counts.students
              }}</v-card-title>
              <v-card-subtitle>Total TSIs</v-card-subtitle>
              <v-card-actions>
                <v-btn class="ml-2 mt-5" outlined rounded small to="/students">
                  View List
                </v-btn>
              </v-card-actions>
            </div>
            <v-avatar class="ma-3" size="125" tile>
              <v-icon class="imgStyle" large dark>mdi-account-school</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <!-- End Total Students -->
      <!-- Total Teachers -->
      <v-col sm="4">
        <v-card color="brown accent-1" dark style="overflow: auto">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5">{{
                data.counts.teachers
              }}</v-card-title>
              <v-card-subtitle>Total Coordinators</v-card-subtitle>
              <v-card-actions>
                <v-btn class="ml-2 mt-5" outlined rounded small to="/teachers">
                  View List
                </v-btn>
              </v-card-actions>
            </div>
            <v-avatar class="ma-3" size="125" tile>
              <v-icon class="imgStyle" large dark>mdi-human-male-board</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <!-- End Total Teachers -->
      <!-- Total Classcodes -->
      <v-col sm="4">
        <v-card color="brown accent-1" dark style="overflow: auto">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5">{{
                data.counts.classcodes
              }}</v-card-title>
              <v-card-subtitle>Total Divisions</v-card-subtitle>
              <v-card-actions>
                <v-btn class="ml-2 mt-5" outlined rounded small to="/standards">
                  View List
                </v-btn>
              </v-card-actions>
            </div>
            <v-avatar class="ma-3" size="125" tile>
              <v-icon class="imgStyle" large dark>mdi-google-classroom</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <!-- End Total Classcodes -->
    </v-row>
    <br />
    <!-- <v-card>
      <v-card-text>
        <v-row>
          <v-col sm="6">
            <p class="font-weight-bold" style="min-width: 65px">
              Most Looked At Subjects
            </p>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left sr_no_class">Sr No</th>
                    <th class="text-center" style="min-width: 75px">Subject</th>
                    <th class="text-center" style="min-width: 65px">Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(subject, s) in data.mostLookedSubjects"
                    :key="`subject${s}`"
                  >
                    <td>{{ s + 1 }}</td>
                    <td class="text-center">{{ subject.name }}</td>
                    <td class="text-center">{{ subject.count }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col sm="6">
            <p class="font-weight-bold" style="min-width: 65px">
              Most Looked At Keywords
            </p>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left sr_no_class">Sr No</th>
                    <th class="text-center" style="min-width: 75px">Subject</th>
                    <th class="text-center" style="min-width: 65px">Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(keyword, s) in data.mostLookedKeywords"
                    :key="`keyword${s}`"
                  >
                    <td>{{ s + 1 }}</td>
                    <td class="text-center">{{ keyword.name }}</td>
                    <td class="text-center">{{ keyword.count }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->
    <br />
    <v-divider></v-divider>
    <br />
    <div
      class="text-h5 font-weight-bold"
      style="min-width: 130px"
      v-text="'Coordinator Wise Data'"
    ></div>
    <div class="row">
      <div class="col-md-3">
        <v-autocomplete
          ref="subject"
          v-model="selectedTeacherId"
          :items="teachersList"
          label="Select Coordinator"
          prepend-icon="mdi-domain"
        ></v-autocomplete>
      </div>
    </div>
    <div class="row" v-if="selectedTeacherId">
      <div class="col-md-12">
        <SaTeacherSchoolData
          :teacherId="selectedTeacherId"
          :schoolId="schoolId"
        ></SaTeacherSchoolData>
        <br />
        <v-divider></v-divider>
      </div>
    </div>
    <br />
    <div
      class="text-h5 font-weight-bold"
      style="min-width: 130px"
      v-text="'TSI Wise Data'"
    ></div>
    <div class="row">
      <div class="col-md-3">
        <v-autocomplete
          ref="student"
          v-model="selectedStudentId"
          :items="studentsList"
          label="Select Student"
          prepend-icon="mdi-domain"
        ></v-autocomplete>
      </div>
    </div>
    <SaStudentSchoolsData
      v-if="selectedStudentId"
      :schoolId="company.id"
      :currentType="'Student'"
      :currentSubType="selectedStudentId"
    ></SaStudentSchoolsData>
    <!-- Content Read Detail Pop UP -->
    <v-dialog v-model="isContentReadDialog" max-width="900px">
      <v-card elevation="10">
        <v-container>
          <v-row>
            <v-card-title style="min-width: 170px">
              Content Read ( <MyLoader v-if="isContentReadLoading"></MyLoader>
              <span v-else>
                {{ company_content_read_count }}
              </span>
              )
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="isContentReadDialog = false"
                    icon
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Close</span>
              </v-tooltip>
            </v-card-actions>
          </v-row>
          <v-row>
            <v-card-text>
              <v-simple-table fixed-header height="285px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left sr_no_class">Sr No</th>
                      <th class="text-center" style="min-width: 57px">Title</th>
                      <th class="text-center" style="min-width: 105px">
                        Content Type
                      </th>
                      <th class="text-center" style="min-width: 105px">User</th>
                      <th class="text-center" style="min-width: 105px">Role</th>
                      <th class="text-center" style="min-width: 110px">
                        Read Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, i) in company_content_read"
                      :key="`value${i}`"
                    >
                      <td>{{ i + 1 }}</td>
                      <td class="text-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              v-bind="attrs"
                              v-on="on"
                              class="wrap"
                              style="width: 260px"
                            >
                              {{ value.only_content?.content_name }}
                            </p>
                          </template>
                          <span>{{ value.only_content?.content_name }}</span>
                        </v-tooltip>
                      </td>
                      <td class="text-center td_content_type">
                        {{ value.only_content?.content_type }}
                      </td>
                      <td class="text-center">
                        {{ value.user?.name }}
                      </td>
                      <td class="text-center">
                        {{ value.user?.roles[0].name }}
                      </td>
                      <td class="text-center">
                        {{ value.created_at | ddmmyyyy }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Assignment Posted Detail Pop UP -->
    <v-dialog v-model="isAssignmentPostedDialog" max-width="900px">
      <v-card elevation="10">
        <v-container>
          <v-row>
            <v-card-title style="min-width: 170px">
              Assignment Posted (
              <MyLoader v-if="isAssignmentPostedLoading"></MyLoader>
              <span v-else>
                {{ company_assignment_posted_count }}
              </span>
              )
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="isAssignmentPostedDialog = false"
                    icon
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Close</span>
              </v-tooltip>
            </v-card-actions>
          </v-row>
          <v-row>
            <v-card-text>
              <v-simple-table fixed-header height="285px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left sr_no_class">Sr No</th>
                      <th class="text-left" style="min-width: 100px">
                        Assignment Title
                      </th>
                      <th class="text-left" style="min-width: 100px">
                        Assignment Type
                      </th>
                      <th class="text-left" style="min-width: 85px">
                        Is Modified
                      </th>
                      <th class="text-left" style="min-width: 100px">
                        Created By
                      </th>
                      <th class="text-left created_on_date">Created On</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, i) in company_assignment_posted"
                      :key="`value${i}`"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>
                        {{ value.assignment_title }}
                      </td>
                      <td style="min-width: 120px">
                        {{ value.assignment_type }}
                      </td>
                      <td style="min-width: 30px">
                        <template v-if="value.is_modified">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" icon>
                                <v-icon color="green"
                                  >mdi-tooltip-edit-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Modified Assignment</span>
                          </v-tooltip>
                        </template>
                        <template
                          v-if="
                            value.is_modified == 0 &&
                            value.copied_from_assignment_id
                          "
                        >
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" icon>
                                <v-icon color="primary"
                                  >mdi-content-copy</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Copied Assignment</span>
                          </v-tooltip>
                        </template>
                      </td>
                      <td style="min-width: 100px">
                        {{ value.created_by.name }}
                      </td>
                      <td>{{ value.created_at | ddmmyyyy }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import axios from "axios";
import SaTeacherSchoolData from "../teacher_school_data";
import SaStudentSchoolsData from "../student_school_data";
export default {
  name: "SAAllSchools",
  data() {
    return {
      data: {
        counts: {},
      },
      is_Loading: false,
      isContentReadDialog: false,
      isContentReadLoading: false,
      isAssignmentPostedDialog: false,
      isAssignmentPostedLoading: false,
      teachersList: [],
      selectedTeacherId: "",
      studentsList: [],
      company_content_read: [],
      company_content_read_count: "0",
      company_assignment_posted: [],
      company_assignment_posted_count: "0",
      selectedStudentId: "",
    };
  },
  components: {
    SaTeacherSchoolData,
    SaStudentSchoolsData,
  },
  props: ["schoolId"],
  watch: {
    schoolId: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.is_Loading = true;
      let response = await axios.get(
        `school_wise_overview?companyId=${this.schoolId}`
      );
      this.data = response.data;
      this.getSubTypes();
      this.is_Loading = false;
    },
    async getSubTypes() {
      this.subTypes = [];
      let subTypes = await axios.get(
        `/getSubTypes?schoolId=${this.schoolId}&type=Teacher`
      );
      subTypes = subTypes.data.data;
      this.selectedTeacherId = "";
      this.teachersList = [];
      subTypes.forEach((sT) => {
        this.teachersList.push({
          text: sT.first_name + " " + sT.last_name,
          value: sT.id,
        });
      });
      this.subTypes = [];
      this.board_id = this.board && this.board.value ? this.board.value : "";
      subTypes = await axios.get(
        `/getSubTypes?schoolId=${this.schoolId}&type=Student&board_id=${this.board_id}`
      );
      subTypes = subTypes.data.data;
      this.selectedTeacherId = "";
      this.studentsList = [];
      subTypes.forEach((sT) => {
        this.studentsList.push({
          text: sT.first_name + " " + sT.last_name,
          value: sT.id,
        });
      });
    },
    async getContentRead() {
      this.company_content_read = [];
      this.isContentReadDialog = true;
      this.isContentReadLoading = true;
      let company_content_read = await axios.get(
        `/company_content_read?schoolId=${this.schoolId}`
      );
      this.company_content_read = company_content_read.data.data;
      this.company_content_read_count = company_content_read.data.count;
      this.isContentReadLoading = false;
    },
    async getAssigmentPosted() {
      this.company_assignment_posted = [];
      this.isAssignmentPostedDialog = true;
      this.isAssignmentPostedLoading = true;
      let query = "company_id=" + this.company.id;
      let company_assignment_posted = await axios.get(`assignments?${query}`);
      this.company_assignment_posted = company_assignment_posted.data.data;
      this.company_assignment_posted_count =
        company_assignment_posted.data.count;
      this.isAssignmentPostedLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
