<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add a User
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="subject"
                v-model="form.role_id"
                :items="roleItems"
                label="Select role"
                prepend-icon="mdi-domain"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.first_name"
                outlined
                label="First Name *"
                prepend-inner-icon="mdi-account"
                :error-messages="errors.first_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.last_name"
                outlined
                label="Last Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.last_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.contact_number"
                outlined
                label="Phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-switch
                v-model="form.active"
                inset
                :label="`Is Active: ${form.active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
            <v-col md="12">
              <v-autocomplete
                ref="subject"
                v-model="form.subjects"
                :items="subjectItems"
                label="Subject"
                prepend-icon="mdi-database-search"
                multiple
                chips
              ></v-autocomplete>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save"> Save User </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  name: "UserCreatePage",
  data() {
    return {
      form: {
        name: "",
        role_id: 4,
        active: true,
        is_mail_sent: false,
        subjects: [],
        user_subjects: [],
      },
      roleItems: [],
      subjects: [],
      subjectItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/users/masters");
      masters = masters.data;
      masters.roles.forEach((role) => {
        if (role.name == "INFAKT TEACHER")
          this.roleItems.push({
            id: role.id,
            text: role.name = "DTP",
            value: role.id,
          });
        if (role.name == "ACADEMIC TEAM")
          this.roleItems.push({
            id: role.id,
            text: role.name = "DTP HEAD",
            value: role.id,
          });
      });
      masters.subjects.forEach((subject) => {
        this.subjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.id,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.subjects.forEach((subject_id) => {
          let user_subject = {
            company_id: this.company.id,
            subject_id: subject_id,
          };
          this.form.user_subjects.push(user_subject);
        });
        await axios.post("/users", this.form);
        this.isLoading = false;
        this.$router.push("/admin/users");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
