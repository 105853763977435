<template>
  <div>
    <p class="font-weight-bold">Designation</p>
    <v-row
      v-for="(classcode, cc) in section.classcodes"
      :key="`classcode${cc}`"
    >
      <v-col sm="4">
        <v-text-field
          v-model="classcode.subject_name"
          label="Designation Name *"
          prepend-inner-icon="mdi-application-edit"
        ></v-text-field>
      </v-col>
      <v-col sm="4">
        <v-text-field
          disabled
          v-model="classcode.classcode"
          label="Classcode"
          prepend-inner-icon="mdi-application-edit"
        ></v-text-field>
      </v-col>
      <v-col sm="3" class="pb-0">
        <v-switch
          v-model="classcode.is_optional"
          inset
          :label="`Is Optional: ${classcode.is_optional ? 'YES' : 'NO'}`"
        ></v-switch>
      </v-col>
      <v-col sm="1">
        <v-dialog
          persistent
          v-model="isDeleteClasscodeDialogs[section.id + cc]"
          max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }" v-if="!classcode.id">
            <v-btn fab x-small dark color="red">
              <v-icon dark v-bind="attrs" v-on="on"> mdi-close-thick </v-icon>
            </v-btn>
          </template>

          <v-card elevation="10">
            <v-card-title>
              <v-icon large color="primary"> mdi-information-outline </v-icon>
              &nbsp; Do you want to remove the designation?
            </v-card-title>
            <v-card-text>
              <v-btn  color="primary" @click.stop="deleteClasscode(classcode)">
                Yes
              </v-btn>
              &nbsp;
              <v-btn color="red" dark @click.stop="closeClasscodeDialogs">
                No
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <br />
    <v-btn class="mx-2" x-small color="primary" @click="addEmptyClascode">
      <v-icon dark> mdi-plus </v-icon> Add Designation
    </v-btn>
    <br /><br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDeleteClasscodeDialogs: [],
    };
  },
  props: ["form", "section"],
  methods: {
    addEmptyClascode() {
      let classcode = {
        subject_name: "",
        classcode: "",
        is_optional: false,
      };
      this.section.classcodes.push(classcode);
    },
    deleteClasscode(classcode) {
      this.section.classcodes.splice(
        this.section.classcodes.indexOf(classcode),
        1
      );
      this.closeClasscodeDialogs();
    },
    closeClasscodeDialogs() {
      this.isDeleteClasscodeDialogs = [];
    },
  },
};
</script>
