/* eslint-disable */
import axios from "axios";
import MobileDetect from "mobile-detect";

export default {
  namespaced: true,
  state: {
    token: "",
    user: {},
    company: {},
    roleName: "",
    board: {},
  },
  getters: {
    authenticated(state) {
      return state.token ? true : false;
    },
    user(state) {
      return state.user;
    },
    company(state) {
      return state.company;
    },
    roleName(state) {
      return state.user.roles[0].name;
    },
    // platform() {
    //   let platform = "PC";
    //   if (screen.width <= 760) {
    //     platform = "Mobile";
    //   } else {
    //     platform = "PC";
    //   }
    //   return platform;
    // },
    platform() {
      let md = new MobileDetect(window.navigator.userAgent);
      let platform = md.mobile() !== null ? "MOBILE" : "PC";
      return platform;
    },
    board(state) {
      return state.board;
      // console.log("Board");
      // console.log(JSON.parse(localStorage.getItem("board")));
      // console.log(localStorage.getItem("board"));
      // return JSON.parse(localStorage.getItem("board")) == null ||
      //   JSON.parse(localStorage.getItem("board")).id == null
      //   ? state.board
      //   : JSON.parse(localStorage.getItem("board"));
    },
  },
  mutations: {
    SET_TOKEN(state, data) {
      state.token = data;
    },
    SET_USER(state, data) {
      state.user = data;
      let userCompanies = data ? data.companies : "";
      state.company = userCompanies.length ? userCompanies[0] : {};
    },
    SET_COMPANY(state, company) {
      state.company = company;
      localStorage.setItem("company", JSON.stringify(company)); // This is when the Super Admin Selects a Company from the dropdown
      if (company != null && company.id)
        axios.defaults.headers.common["company-id"] = company.id;
      else axios.defaults.headers.common["company-id"] = null;
    },
    SET_BOARD(state, data) {
      state.board = data;
      localStorage.setItem("board", JSON.stringify(data));
    },
  },
  actions: {
    async logIn({ dispatch }, credentails) {
      const response = await axios.post("/login", credentails);
      await dispatch("attempt", response.data.token);
    },
    async attempt({ state, commit }, token) {
      if (token) commit("SET_TOKEN", token);

      if (!state.token) return;

      try {
        const response = await axios.get("/me");
        commit("SET_USER", response.data.data);
        // This is for the Super Admin if a company is already selected
        var company = JSON.parse(localStorage.getItem("company"));
        console.log("Selected Company");
        console.log(company);
        if (company) commit("SET_COMPANY", company);
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },
    async logOut({ commit }) {
      try {
        const response = await axios.get("/logout");
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        commit("SET_COMPANY", null);
      } catch (error) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        commit("SET_COMPANY", null);
      }
    },
    setCompany({ commit }, company) {
      commit("SET_COMPANY", company);
    },
    setBoard({ commit }, board) {
      commit("SET_BOARD", board);
    },
  },
};
