<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 115px">
          <span class="text-h5 font-weight-bold" v-text="'Courses'"></span>
          <addbutton :link="'/courses/create'"></addbutton>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left sr_no_class">Sr No</th>
                    <th class="text-left" style="min-width: 57px">Title</th>
                    <th class="text-left" style="min-width: 250px">
                      Learning Outcome
                    </th>
                    <th class="text-left" style="min-width: 80px">Board</th>
                    <th class="text-left" style="min-width: 95px">Standard</th>
                    <th class="text-left" style="min-width: 95px">Subject</th>
                    <th class="text-right" style="min-width: 95px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(course, i) in courses" :key="`course_${i}`">
                    <td>{{ i + 1 }}</td>
                    <td>{{ course.title }}</td>
                    <td v-html="course.description"></td>
                    <td>{{ course.board.name }}</td>
                    <td>{{ course.standard }}</td>
                    <td>{{ course.subject }}</td>
                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        x-small
                        color="primary"
                        :to="`/courses/${course.id}/lps`"
                      >
                        Lesson Plans
                      </v-btn>
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/courses/${course.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "CoursesPage",
  data() {
    return {
      courses: [],
      isLoading: false,
    };
  },
  components: {
    addbutton,
    Loader,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let courses = await axios.get(`courses`);
      this.courses = courses.data.data;
      this.isLoading = false;
    },
  },
};
</script>
