<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Add a Designation
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Location *"
                @change="checkUnique('name', form.name), CreateBoardSubject()"
                prepend-inner-icon="mdi-alarm-bell"
                :error-messages="checkUniqueError.name"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                disabled
                ref="board_id"
                v-model="form.board_id"
                :items="boardItems"
                label="Select Location *"
                chips
                :error-messages="errors.board_id"
                prepend-icon="mdi-domain"
              ></v-autocomplete>
            </v-col>
            <!-- <v-col sm="4" class="pb-0">
              <v-switch
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col> -->
          </v-row>
          <Sections
            :form="form"
            :checkSectionUniqueError="checkSectionUniqueError"
            @changeStatus="changeStatus"
          >
          </Sections>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save">
            Save Designation
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import Sections from "./sections.vue";
import Backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  data() {
    return {
      form: {
        name: "",
        is_active: true,
        is_deleted: false,
        sections: [],
      },
      checkUniqueError: {
        name: "",
      },
      checkSectionUniqueError: [],
      boards: [],
      boardItems: [],
      is_section_name_duplicate: false,
      isLoading: false,
    };
  },
  components: {
    Sections,
    Backbutton,
    Loader,
  },
  mounted() {
    this.getData();
    this.getMasters();
    this.form.board_id = this.board.id;
  },
  methods: {
    async getData() {
      let duplicateFromStandardId = this.$route.query.duplicateFromStandardId;
      if (duplicateFromStandardId) {
        var standard = await axios.get(`/standards/${duplicateFromStandardId}`);
        standard = standard.data.data;
        delete standard.id;
        standard.name = "";
        standard.sections.forEach((section) => {
          let checkSectionUniqueError = {
            name: "",
          };
          this.checkSectionUniqueError.push(checkSectionUniqueError);
          delete section.id;
          section.classcodes.forEach((classcode) => {
            delete classcode.id;
            delete classcode.classcode;
          });
        });
        this.form = standard;
      }
    },
    async getMasters() {
      this.isLoading = true;
      let masters = await axios.get("standards/masters");
      masters = masters.data;
      this.boards = masters.boards;
      // Boards
      this.boards.forEach((board) => {
        this.boardItems.push({
          id: board.id,
          text: board.name,
          value: board.id,
        });
      });
      this.isLoading = false;
    },
    async checkUnique(column, text) {
      this.array = { column: column, text: text, board_id: this.form.board_id };
      let response = await axios.post(`/standards/checkUnique`, this.array);
      if (response.data != "No Duplicate Entries") {
        this.checkUniqueError.name = response.data;
      } else {
        this.checkUniqueError.name = "";
      }
    },
    async save() {
      try {
        if (this.form.name == "") {
          this.checkUniqueError.name = "The name field is required.";
        }
        if (
          this.checkUniqueError.name == "" &&
          this.is_section_name_duplicate != true
        ) {
          this.isLoading = true;
          let standard = await axios.post("/standards", this.form);
          this.standard = standard.data.data;
          this.isLoading = false;
          this.$router.push("/standards");
        }
      } catch (e) {
        this.isLoading = false;
      }
    },
    changeStatus(status) {
      this.is_section_name_duplicate = status;
    },
    CreateBoardSubject() {
      this.form.sections = [];
      let CBSE_Subjects = {
        name: "A",
        classcodes: [
          {
            subject_name: "English",
          },
          {
            subject_name: "Social Science",
          },
          {
            subject_name: "Science",
          },
        ],
      };
      let ICSE_Subjects = {
        name: "A",
        classcodes: [
          {
            subject_name: "English",
          },
          {
            subject_name: "History",
          },
          {
            subject_name: "Civics",
          },
          {
            subject_name: "Geography",
          },
          {
            subject_name: "Physics",
          },
          {
            subject_name: "Chemistry",
          },
          {
            subject_name: "Biology",
          },
        ],
      };
      let checkSectionUniqueError = {
        name: "",
      };
      this.checkSectionUniqueError.push(checkSectionUniqueError);
      switch (this.board.text) {
        case "CBSE":
          switch (this.form.name) {
            case "VI":
            case "6":
              this.form.sections.push(CBSE_Subjects);
              break;
            case "VII":
            case "7":
              this.form.sections.push(CBSE_Subjects);
              break;
            case "VIII":
            case "8":
              this.form.sections.push(CBSE_Subjects);
              break;

            default:
              break;
          }
          break;
        case "ICSE":
          switch (this.form.name) {
            case "VIII":
            case "8":
              this.form.sections.push(ICSE_Subjects);
              break;

            default:
              break;
          }
          break;

        default:
          break;
      }
    },
  },
};
</script>
