<template>
  <v-autocomplete
    ref="subject"
    v-model="selectedCompanyId"
    :items="companies"
    label="Select Company"
    prepend-icon="mdi-domain"
    clearable
    dense
    @click:clear="(selectedCompanyId = null), changeCompany()"
    @input="changeCompany"
  ></v-autocomplete>
</template>

<script type="text/javascript">
import axios from "axios";
import store from "@/store";
export default {
  data() {
    return {
      isLoading: false,
      companies: [],
      allCompanies: [],
      selectedCompanyId: "",
    };
  },
  mounted() {
    let company = store.getters["auth/company"];
    if (Object.keys(company).length > 0) {
      this.selectedCompanyId = company.id;
    }
    this.getCompanies();
  },
  methods: {
    async getCompanies() {
      this.isLoading = true;
      let companies = await axios.get("companies");
      this.allCompanies = companies.data.data;
      this.allCompanies.forEach((company) => {
        this.companies.push({
          id: company.id,
          text: company.name,
          value: company.id,
        });
      });
      this.isLoading = false;
    },
    changeCompany() {
      let company = this.allCompanies.find(
        (s) => s.id == this.selectedCompanyId
      );

      
      store.dispatch("auth/setBoard", "");
      if (company) {
        store.dispatch("auth/setCompany", company);
        let firstBoard = {
          id: company.company_boards[0].board.id,
          value: company.company_boards[0].board.id,
          text: company.company_boards[0].board.name,
        };
        console.log("As changing the company");
        console.log(firstBoard);
        store.dispatch("auth/setBoard", firstBoard);
      } else {
        store.dispatch("auth/setCompany", "");
      }
      window.location.href = "/auth/login"
      // this.$router.push("/auth/login");
    },
  },
};
</script>
