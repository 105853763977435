<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Epaper Dashboard'"
          ></span>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row>
        <v-col
          v-for="content_type in EpaperContents"
          :key="content_type.name"
          sm="3"
          @click="getData(content_type.name)"
        >
          <v-card elevation="5" color="primary accent-1" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  style="min-width: 80px"
                  class="text-h6 font-weight-bold pr-0"
                  v-text="content_type.name"
                ></v-card-title>

                <v-card-subtitle
                  class="text-h3 font-weight-bold"
                  v-text="content_type.count"
                ></v-card-subtitle>
              </div>
              <v-avatar class="ma-3" size="125" tile>
                <v-icon class="imgStyle" large dark>{{
                  content_type.icon
                }}</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!--/ Filters Section -->
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-container>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center" style="min-width: 85px">Sr No</th>
                      <th class="text-center" style="min-width: 85px">ID</th>
                      <th class="text-center" style="min-width: 130px">Type</th>
                      <th class="text-center" style="min-width: 100px">
                        Created By
                      </th>
                      <th class="text-center" style="min-width: 20px">Link</th>
                      <th class="text-center" style="min-width: 150px">
                        Title
                      </th>
                      <th class="text-center" style="min-width: 85px">
                        Reading Time
                      </th>
                      <th class="text-center" style="min-width: 85px">
                        Written By
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(content, i) in contents" :key="content.name">
                      <td class="text-center">{{ i + 1 }}</td>
                      <td class="text-center">{{ content.id }}</td>
                      <td class="text-center">{{ content.content_type }}</td>
                      <td class="text-center">
                        {{ content.toi_article_id ? "TOI" : "ET" }}
                      </td>
                      <td class="text-center">
                        <v-tooltip bottom v-if="content.toi_article_id">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              target="_blank"
                              size="25"
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="primary"
                              :to="`/epaper/toi/${content.toi_article_id}`"
                            >
                              <v-icon> mdi-eye-outline </v-icon>
                            </v-btn>
                          </template>
                          <span> View TOI Epaper </span>
                        </v-tooltip>
                        <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              target="_blank"
                              size="25"
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="primary"
                              :to="`/epaper/et/${content.et_article_id}`"
                            >
                              <v-icon dark> mdi-eye-outline </v-icon>
                            </v-btn>
                          </template>
                          <span> View ET Epaper </span>
                        </v-tooltip>
                      </td>
                      <td class="text-center">{{ content.content_name }}</td>
                      <td class="text-center">
                        {{
                          content.reading_time
                            ? content.reading_time + " mins"
                            : ""
                        }}
                      </td>
                      <td class="text-center">
                        {{ content.written_by_name }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <!-- Graph Section -->
      <v-row>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title style="min-width: 120px">
              Type Wise Overview
            </v-card-title>
            <v-card-text>
              <GChart
                v-if="type_overview_data.length > 0"
                type="PieChart"
                :data="type_overview_data"
                :options="pieChartOptions"
              />
              <loader v-else></loader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!--/ Graph Section -->
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      form: {
        type: "",
      },
      isLoading: false,
      type_overview_data: [],
      EpaperContents: [],
      contents: [],
      // Chart Components
      pieChartOptions: {
        is3D: true,
        width: "100%",
        height: 250,
        chartArea: { width: "80%", height: "80%" },
      },
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    async getData(type = "") {
      this.form.type = type ? type : "";
      this.isLoading = true;
      let response;
      let query = "type=" + this.form.type;
      response = await axios.get(`/epaper_dashboard?${query}`);
      this.EpaperContents = response.data;
      this.contents = this.EpaperContents.find(
        (type) => type.name == "TOTAL"
      ).values;
      console.log(this.contents);
      if (this.EpaperContents) {
        this.type_overview_data = [
          ["Type", "Value"],
          [
            "TOI",
            parseFloat(
              this.EpaperContents.find((type) => type.name == "TOI").count
            ),
          ],
          [
            "ET",
            parseFloat(
              this.EpaperContents.find((type) => type.name == "ET").count
            ),
          ],
        ];
      }
      //   console.log(this.type_overview_data);
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
