<template>
  <section style="background-color: #e5e5e5">
    <div class="py-4"></div>

    <div class="text-center">
      <v-fade-transition>
        <div id="benefits">
          <h2
            class="display-2 font-weight-bold mb-3 myFont"
            v-show="benefitsTrigger"
          >
            Our Advantage
          </h2>
        </div>
      </v-fade-transition>

      <StudentsAdvantage></StudentsAdvantage>

      <br v-if="$vuetify.breakpoint.mdAndUp" /><br
        v-if="$vuetify.breakpoint.mdAndUp"
      />
    </div>

    <div class="py-12"></div>
  </section>
</template>

<script>
import StudentsAdvantage from "./studentsAdvantage.vue";
import TeachersAdvantage from "./teachersAdvantage.vue";

export default {
  data: () => ({
    benefitsTrigger: true,
    teachersTrigger: true,
  }),
  components: {
    StudentsAdvantage,
    TeachersAdvantage,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const benefits = document.querySelector("#benefits");
      if (this.isInViewport(benefits)) {
        this.benefitsTrigger = true;
      }
    },
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) - 300 &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
  },
};
</script>

<style scoped>
.myFont {
  font-family: "SF PRO" !important;
  color: black;
}
</style>
