<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Edit {{ form.content_name }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="subject"
                v-model="form.content_type"
                :items="contentTypes"
                chips
                label="Select Content Type"
                prepend-icon="mdi-domain"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="category"
                v-model="form.categories"
                :items="categoryItems"
                label="Category *"
                multiple
                chips
                prepend-icon="mdi-shape"
                :error-messages="errors.content_categories"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                v-model="form.content_name"
                outlined
                label="Title *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.content_name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.reading_time"
                outlined
                label="Reading time (in mins)"
                prepend-inner-icon="mdi-clock-outline"
                :error-messages="errors.reading_time"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.written_by_name"
                outlined
                label="Written By"
                prepend-inner-icon="mdi-notebook-edit-outline"
                :error-messages="errors.written_by_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.publication"
                outlined
                label="Publication"
                prepend-inner-icon="mdi-typewriter"
                :error-messages="errors.publication"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Info Box Section -->
          <v-expansion-panels class="mb-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                Info Box
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col sm="4">
                    <v-autocomplete
                      ref="grade"
                      v-model="form.grades"
                      :items="gradeItems"
                      label="Grade"
                      multiple
                      chips
                      prepend-icon="mdi-star"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="4">
                    <v-autocomplete
                      ref="board_id"
                      v-model="form.info_boards"
                      :items="boardItems"
                      label="Info Board"
                      multiple
                      chips
                      prepend-icon="mdi-cast-education"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="4">
                    <v-autocomplete
                      ref="subject"
                      v-model="form.subjects"
                      :items="subjectItems"
                      label="Subject"
                      prepend-icon="mdi-application-edit"
                      multiple
                      chips
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="8">
                    <p class="font-weight-bold">Learning Outcome</p>
                    <froala
                      :tag="'textarea'"
                      :config="config"
                      v-model="form.learning_outcome"
                    ></froala>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Info Box Section -->
          <!-- Intended For Section -->
          <v-expansion-panels class="my-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                Intended For
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col sm="3">
                    <v-autocomplete
                      ref="board_id"
                      v-model="form.boards"
                      :items="boardItems"
                      label="Board"
                      multiple
                      chips
                      prepend-icon="mdi-cast-education"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="3">
                    <v-autocomplete
                      ref="school_type"
                      v-model="form.for_school_type"
                      :items="schoolTypeItems"
                      label="School Type"
                      chips
                      prepend-icon="mdi-currency-usd-off"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="3">
                    <v-autocomplete
                      ref="specific_to"
                      v-model="form.specific_to"
                      :items="specificItems"
                      label="Specific To"
                      chips
                      prepend-icon="mdi-electron-framework"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="3">
                    <v-autocomplete
                      ref="school_id"
                      v-model="form.schools"
                      :items="schoolItems"
                      label="School"
                      multiple
                      chips
                      prepend-icon="mdi-bus-school"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Intended For Section -->
          <!-- Original Content Section -->
          <v-expansion-panels class="my-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                Original Content
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col sm="12" class="mt-5">
                    <p class="font-weight-bold">
                      Original Content &nbsp;
                      <v-dialog v-model="dialog">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            color="red lighten-2"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            Preview
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title
                            class="justify-center primary accent-1 white--text"
                          >
                            Preview
                          </v-card-title>
                          <br />
                          <v-card-text>
                            <div v-html="form.original_content"></div>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </p>
                    <froala
                      :tag="'textarea'"
                      :config="config"
                      v-model="form.original_content"
                    ></froala>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Original Content Section -->
          <!-- Variants Section -->
          <v-expansion-panels class="my-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                Variants
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(content_description, uc) in form.content_descriptions"
                  :key="`content_description${uc}`"
                  class="mt-5"
                >
                  <v-row>
                    <v-col sm="11">
                      <p>Variant {{ uc + 1 }}</p>
                    </v-col>
                    <v-col sm="1">
                      <v-dialog
                        v-model="isDeleteDescriptionDialogs[uc]"
                        max-width="600px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            class="ml-9"
                            small
                            dark
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon dark> mdi-close-thick </v-icon>
                          </v-btn>
                        </template>
                        <v-card elevation="10">
                          <v-card-title>
                            <v-icon large color="primary">
                              mdi-information-outline
                            </v-icon>
                            &nbsp; Do you want to remove the description?
                          </v-card-title>
                          <v-card-text>
                            <v-btn
                              color="primary"
                              @click.stop="
                                deleteDescription(content_description)
                              "
                            >
                              Yes
                            </v-btn>
                            &nbsp;
                            <v-btn
                              color="red"
                              dark
                              @click.stop="closeDescriptionDialogs"
                            >
                              No
                            </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3">
                      <v-text-field
                        v-model="content_description.level"
                        outlined
                        label="Level"
                        prepend-inner-icon="mdi-sort-alphabetical-variant"
                        :error-messages="errors.level"
                      ></v-text-field
                    ></v-col>
                    <v-col md="9">
                      <v-text-field
                        v-model="content_description.title"
                        outlined
                        label="Title"
                        prepend-inner-icon="mdi-alphabetical-variant"
                        :error-messages="errors.title"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <p class="font-weight-bold">Description</p>
                      <froala
                        :tag="'textarea'"
                        :config="config"
                        v-model="content_description.description"
                      ></froala>
                    </v-col>
                  </v-row>
                  <v-divider class="my-5"></v-divider>
                </div>
                <v-row>
                  <v-col sm="12" class="mt-5">
                    <v-btn small color="primary" @click="Add_Empty_Description">
                      Add Variant
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Variants Section -->
          <v-row class="my-5">
            <v-col sm="4">
              <input
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="image/*"
                @change="onFileChange"
              />
              <br />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.file.value = null), (image_url = null)"
                >Remove</v-btn
              >
            </v-col>

            <v-col sm="4">
              <v-switch
                v-model="form.is_approved"
                inset
                :label="`Is APPROVED: ${form.is_approved ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
            <v-col sm="4">
              <v-switch
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <div id="preview" class="mt-5">
            <label for="">Card Image Preview</label>
            <br />
            <img
              v-if="image_url"
              :src="image_url"
              style="height: 30% !important; width: 30% !important"
            />
            <img
              v-else
              :src="`${mediaUrl + form.featured_image_path}`"
              style="height: 30% !important; width: 30% !important"
            />
          </div>
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-co v-else>
            <v-btn color="primary" dark @click="save"> Update Content </v-btn>
          </v-co>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
export default {
  components: { backbutton },
  name: "ContentEditPage",
  data() {
    return {
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ['style'],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5=="
      },
      form: {
        name: "",
        subjects: [],
        content_subjects: [],
        content_medias: [],
        content_descriptions: [],
        categories: [],
        content_categories: [],
      },
      contentTypes: ["ARTICLE", "INFOGRAPHIC", "VIDEO"],
      schoolTypeItems: ["FREE", "PAID"],
      specificItems: ["PUBLIC", "SCHOOL"],
      subjects: [],
      categoryItems: [],
      subjectItems: [],
      gradeItems: [],
      boardItems: [],
      schoolItems: [],
      isDeleteDescriptionDialogs: [],
      isLoading: false,
      image_url: "",
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.image_url = URL.createObjectURL(file);
    },
    async getMasters() {
      let masters = await axios.get("/contents/masters");
      masters = masters.data;
      masters.schools.forEach((school) => {
        this.schoolItems.push({
          id: school.id,
          text: school.name,
          value: school.id,
        });
      });
      masters.categories.forEach((category) => {
        this.categoryItems.push({
          id: category.id,
          text: category.name,
          value: category.id,
        });
      });
      masters.subjects.forEach((subject) => {
        this.subjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.id,
        });
      });
      masters.boards.forEach((board) => {
        this.boardItems.push({
          id: board.id,
          text: board.name,
          value: board.id,
        });
      });
      masters.grades.forEach((grade) => {
        this.gradeItems.push({
          id: grade.id,
          text: grade.name,
          value: grade.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/contents/${this.$route.params.ATcontantId}`);
      this.form = form.data.data;
      this.isLoading = false;
      // Subjects
      let subjects = [];
      this.form.content_subjects.forEach((subject) => {
        subjects.push(subject.subject_id);
      });
      this.form.subjects = subjects;
      // Categories
      let categories = [];
      this.form.content_categories.forEach((category) => {
        categories.push(category.category_id);
      });
      this.form.categories = categories;
      // Grades
      let grades = [];
      this.form.content_grades.forEach((grade) => {
        grades.push(grade.grade_id);
      });
      this.form.grades = grades;
      // Boards
      let boards = [];
      this.form.content_boards.forEach((board) => {
        boards.push(board.board_id);
      });
      this.form.boards = boards;
      // Info Boards
      let info_boards = [];
      this.form.content_info_boards.forEach((info_board) => {
        info_boards.push(info_board.board_id);
      });
      this.form.info_boards = info_boards;
      // Schools
      let schools = [];
      this.form.content_schools.forEach((school) => {
        schools.push(school.company_id);
      });
      this.form.schools = schools;
    },
    async save() {
      try {
        this.isLoading = true;
        // Content Categories
        let content_categories = [];
        this.form.categories.forEach((category_id) => {
          let contentCategory = this.form.content_categories.find(
            (g) => g.category_id == category_id
          );
          if (contentCategory) {
            content_categories.push(contentCategory);
          } else {
            let content_category = {
              category_id: category_id,
            };
            content_categories.push(content_category);
          }
        });
        this.form.content_categories = content_categories;
        // Content Subjects
        let content_subjects = [];
        this.form.subjects.forEach((subject_id) => {
          let contentSubject = this.form.content_subjects.find(
            (g) => g.subject_id == subject_id
          );
          if (contentSubject) {
            content_subjects.push(contentSubject);
          } else {
            let content_subject = {
              subject_id: subject_id,
            };
            content_subjects.push(content_subject);
          }
        });
        this.form.content_subjects = content_subjects;
        // Content Grades
        let content_grades = [];
        this.form.grades.forEach((grade_id) => {
          let contentGrade = this.form.content_grades.find(
            (g) => g.grade_id == grade_id
          );
          if (contentGrade) {
            content_grades.push(contentGrade);
          } else {
            let content_grade = {
              grade_id: grade_id,
            };
            content_grades.push(content_grade);
          }
        });
        this.form.content_grades = content_grades;

        // Content Boards
        let content_boards = [];
        this.form.boards.forEach((board_id) => {
          let contentBoard = this.form.content_boards.find(
            (b) => b.board_id == board_id
          );
          if (contentBoard) {
            content_boards.push(contentBoard);
          } else {
            let content_board = {
              board_id: board_id,
            };
            content_boards.push(content_board);
          }
        });
        this.form.content_boards = content_boards;
        // Content Info Boards
        let content_info_boards = [];
        this.form.info_boards.forEach((info_board_id) => {
          let contentInfoBoard = this.form.content_info_boards.find(
            (ib) => ib.info_board_id == info_board_id
          );
          if (contentInfoBoard) {
            content_info_boards.push(contentInfoBoard);
          } else {
            let content_info_board = {
              board_id: info_board_id,
            };
            content_info_boards.push(content_info_board);
          }
        });
        this.form.content_info_boards = content_info_boards;
        // Content Info Boards
        let content_schools = [];
        this.form.schools.forEach((school_id) => {
          let contentSchool = this.form.content_schools.find(
            (b) => b.school_id == school_id
          );
          if (contentSchool) {
            content_schools.push(contentSchool);
          } else {
            let content_school = {
              company_id: school_id,
            };
            content_schools.push(content_school);
          }
        });
        this.form.content_schools = content_schools;

        if (this.form.is_approved == false) {
          this.form.is_active = false;
        }
        await axios.post(`/contents`, this.form);
        this.handleFileUpload();

        this.isLoading = false;
        this.$router.push("/academic-team");
      } catch (e) {
        this.isLoading = false;
      }
    },
    Add_Empty_Description() {
      let content_description = {
        level: "",
        title: "",
        description: "",
      };
      this.form.content_descriptions.push(content_description);
    },
    deleteDescription(content_description) {
      this.form.content_descriptions.splice(
        this.form.content_descriptions.indexOf(content_description),
        1
      );
      this.closeDescriptionDialogs();
    },
    closeDescriptionDialogs() {
      this.isDeleteDescriptionDialogs = [];
    },
    async handleFileUpload() {
      this.isLoading = true;
      let attachment = this.$refs.file?.files[0];
      const contentid = this.form.id;
      let formData = new FormData();
      formData.append("contentid", contentid);
      formData.append("featuredimagepath", attachment);
      await axios
        .post("upload_content_featured_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      if (this.career_request) {
        this.success = true;
        this.form = [];
      }
      this.isLoading = false;
    },
  },
};
</script>
