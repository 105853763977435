<template>
  <v-main>
    <v-container>
      <loader v-if="isLoading"></loader>
      <!-- Content Card -->
      <v-card v-if="form.content_id">
        <v-card-title>Article Details</v-card-title>
        <v-card-text> {{ form.content?.content_name }} </v-card-text>
      </v-card>
      <!-- Collection Card -->
      <v-card v-if="form.collection_id">
        <v-card-title>Collection Details</v-card-title>
        <v-card-text> {{ form.collection?.collection_name }} </v-card-text>
      </v-card>
      <!-- Link Content Card -->
      <v-card v-if="!form.collection_id && !form.content_id">
        <v-card-title id="linkContentBtn">
          <v-btn
            small
            ref="linkContentBtn"
            dark
            color="primary"
            @click="isLinkArticleDialog = true"
          >
            Link Content
          </v-btn>
          &nbsp;
          <span v-if="errors.content_id" style="color: red"
            >An article must be linked</span
          >
        </v-card-title>
        <v-dialog v-model="isLinkArticleDialog" max-width="1000px" persistent>
          <v-card elevation="2">
            <v-card-title>
              Articles
              <loader v-if="isSearching" size="25" class="ml-3"></loader
            ></v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="search_article_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="searchArticles"
                  ></v-text-field>
                </v-col>
              </v-row>
              <br />
              <p class="font-weight-bold">Articles List</p>
              <br />
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(article, a) in articles"
                  :key="`article${a}`"
                >
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col sm="9">
                        {{ article.content_name }}
                      </v-col>
                      <v-col sm="3">
                        <v-btn
                          small
                          dark
                          color="primary"
                          @click="linkArticle(article)"
                        >
                          Link This Article
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-html="article.original_content"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-dialog
                v-model="isViewArticleDialog"
                max-width="400px"
                persistent
              >
                <v-card elevation="2" align="center">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Data Saved Successfully
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-btn color="primary" @click.stop="closeViewArticleDialog"
                      >Close</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-dialog>
              <br />
              <v-btn color="primary" @click.stop="closeLinkArticleDialog">
                Close
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
      <!-- End for Link Content Card -->
      <br />
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Update Assignment
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4" class="pb-0">
              <v-autocomplete
                :disabled="form.assignment_type ? true : false"
                ref="assignment_type"
                id="assignment_type"
                v-model="form.assignment_type"
                @change="
                  form.assignment_type != 'DOCUMENT'
                    ? ((form.maximum_marks = ''), (form.duration = ''))
                    : ''
                "
                :items="assignmentTypes"
                label="Assignment Type *"
                prepend-icon="mdi-shape"
                :error-messages="errors.assignment_type"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4" class="pb-0" v-if="form.content_id">
              <v-autocomplete
                ref="content_description_id"
                id="content_description_id"
                v-model="form.content_description_id"
                :items="variants"
                label="Lexile"
                prepend-icon="mdi-shape"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col sm="4" class="pb-0">
              <v-text-field
                outlined
                ref="assignment_title"
                id="assignment_title"
                v-model="form.assignment_title"
                label="Assignment Title"
                prepend-inner-icon="mdi-file-sign"
                :error-messages="errors.assignment_title"
              ></v-text-field>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-text-field
                outlined
                ref="duration"
                id="duration"
                :disabled="form.assignment_type != 'DOCUMENT' ? false : true"
                v-model="form.duration"
                label="Timed assignment (in mins)"
                prepend-inner-icon="mdi-timer-alert"
                messages="This will make it time based assignment"
              ></v-text-field>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-text-field
                disabled
                outlined
                ref="maximum_marks"
                id="maximum_marks"
                v-model="form.maximum_marks"
                label="Maximum marks"
                messages="Calculated on the basis of each question"
                prepend-inner-icon="mdi-chart-box-outline"
              ></v-text-field>
            </v-col>
            <v-col
              sm="4"
              class="pb-0"
              v-if="form.assignment_type == 'DOCUMENT'"
            >
              <input
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="application/ms-excel"
                @change="CheckFile()"
              />
              <br />
              <v-btn
                v-if="form.hasFile"
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.file.value = null), CheckFile()"
                >Remove</v-btn
              >
              <span v-else x-small class="error--text" dark
                >This field is required</span
              >
            </v-col>
            <v-col sm="4" class="pb-2">
              <v-tooltip right v-if="form.documentpath">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="mt-2"
                    target="_blank"
                    :href="`${mediaUrl}${form.documentpath}`"
                    >Link of Uploaded Document
                  </v-btn>
                </template>
                <span>Link to preview Uploaded Document</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="form.assignment_type == 'DOCUMENT'">
            <v-col md="12" class="pb-0">
              <p class="font-weight-bold">Model Answer</p>
            </v-col>
            <v-col>
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="form.model_answer"
              ></froala>
            </v-col>
          </v-row>
          <!-- Classcodes -->
          <AssignmentClasscodes
            v-if="roleName == 'TEACHER'"
            :form="form"
            :classcodes="classcodes"
          ></AssignmentClasscodes>
          <!-- End Classcodes -->
          <v-row v-if="form.assignment_type == 'DOCUMENT'">
            <v-col>
              <v-text-field
                outlined
                id="no_of_questions"
                ref="no_of_questions"
                :error-messages="errors.no_of_questions"
                v-model="form.no_of_questions"
                label="No of Questions"
                messages="The total no of Question present in the document. Click 'ENTER' to create question"
                @keypress.enter="CreateQuestions"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Questions -->
          <AssignmentQuestions
            :form="form"
            v-if="!form.is_game"
            :isModificationEnabled="isModificationEnabled()"
          ></AssignmentQuestions>
          <!-- End Questions -->
          <v-row>
            <v-col sm="12">
              <p class="font-weight-bold">TSI Instructions</p>
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="form.student_instructions"
              ></froala>
            </v-col>
          </v-row>
          <v-row v-if="roleName == 'ACADEMIC TEAM'" class="pb-0">
            <v-col sm="4">
              <v-autocomplete
                v-if="form.status != 3"
                ref="Status"
                v-model="form.status"
                :items="statusItems"
                label="Select Status"
              ></v-autocomplete>
            </v-col>
            <v-col sm="8" v-show="form.status == 2">
              <p class="font-weight-bold">Remark</p>
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="form.remarks"
              ></froala>
            </v-col>
          </v-row>
          <br />
          <loader v-if="isLoading"></loader>
          <v-row v-else justify="center">
            <template v-if="isDataSavedDialog != true">
              <v-col sm="3">
                <v-btn
                  color="primary"
                  x-large
                  dark
                  @click="isClasscodeSelected()"
                >
                  {{ form.status != "3" ? "Update" : "Post" }} Assignment
                </v-btn>
              </v-col>
              <v-col sm="3" v-if="form.created_by_id == user.id">
                <v-btn color="primary" dark x-large @click="save(true)">
                  Save in Draft
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- If no Classcode -->
      <v-dialog
        v-model="show_Missing_classcode_Warning"
        max-width="300px"
        persistent
      >
        <v-card elevation="10" align="center">
          <v-card-title class="justify-center primary accent-1 white--text">
            No classcode has been selected. You must select atleast one
            classcode before creating an assignment.
          </v-card-title>
          <br />
          <v-card-text>
            <v-btn
              color="primary"
              @click="
                focusDiv({ classcode_id: 'requred' }),
                  (show_Missing_classcode_Warning = false)
              "
            >
              OK
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Warning For Existing Classcode -->
      <v-dialog
        v-model="show_Existing_classcode_Warning"
        max-width="300px"
        persistent
      >
        <v-card elevation="10" align="center">
          <v-card-title class="justify-center primary accent-1 white--text">
            An assignment with the same article lexile level has been already
            created for the same classcode. Do you still want to continue ?
          </v-card-title>
          <br />
          <v-card-text>
            <v-btn
              color="primary"
              @click="
                CheckHiddenLexileConfig(),
                  (show_Existing_classcode_Warning = false)
              "
            >
              Yes
            </v-btn>
            &nbsp;
            <v-btn
              dark
              color="red"
              @click.stop="show_Existing_classcode_Warning = false"
            >
              No
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- If Article hidden from any Classcode -->
      <v-dialog
        v-model="show_hidden_classcode_message"
        max-width="410px"
        persistent
      >
        <v-card elevation="10" align="center">
          <v-card-title class="justify-center primary accent-1 white--text">
            The linked content is hidden for some classcode. Kindly select some
            other classcode.
          </v-card-title>
          <br />
          <v-card-text>
            <!-- <v-btn
              color="primary"
              @click="
                CheckLockedLexileConfig(),
                  (show_hidden_classcode_message = false)
              "
            >
              Yes
            </v-btn>
            &nbsp; -->
            <v-btn
              dark
              color="primary"
              @click.stop="
                focusDiv({ classcode_id: 'requred' }),
                  (show_hidden_classcode_message = false)
              "
            >
              Ok
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- If Article Locked with different Lexile for any classcode -->
      <v-dialog
        v-model="show_incorrectly_locked_classcode_message"
        max-width="445px"
        persistent
      >
        <v-card elevation="10" align="center">
          <v-card-title class="justify-center primary accent-1 white--text">
            Some classcode are locked at different lexile levels. Please select
            some other lexile level.
          </v-card-title>
          <br />
          <v-card-text>
            <!-- <v-btn
              color="primary"
              @click="
                warningMessage(
                  (form.created_by_id == user.id &&
                    roleName == `ACADEMIC TEAM`) ||
                    roleName == `TEACHER`
                    ? `1`
                    : roleName != `ACADEMIC TEAM`
                    ? `0`
                    : null
                ),
                  (show_incorrectly_locked_classcode_message = false)
              "
            >
              Yes
            </v-btn>
            &nbsp; -->
            <v-btn
              dark
              color="primary"
              @click.stop="
                focusDiv({ content_description_id: 'requred' }),
                  (show_incorrectly_locked_classcode_message = false)
              "
            >
              OK
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Warning for Timed Assignment -->
      <v-dialog v-model="isWarning" max-width="300px" persistent>
        <v-card elevation="10" align="center">
          <v-card-title class="justify-center primary accent-1 white--text">
            Timed assignment field is empty which makes the Assignment 'Not
            Timed'. Do you still want to continue ?
          </v-card-title>
          <br />
          <v-card-text>
            <v-btn color="primary" @click.stop="save"> Yes </v-btn>
            &nbsp;
            <v-btn dark color="red" @click.stop="isWarning = false"> No </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Assignment Saved Successfully Pop Up -->
      <v-dialog
        elevation="0"
        :scrim="false"
        fullscreen
        width="auto"
        v-model="isDataSavedDialog"
      >
        <v-card
          elevation="0"
          tabindex="0"
          align="center"
          height="auto"
          class="make_transparent"
        >
          <v-container class="fill-height d-flex justify-center align-center">
            <v-card-text class="d-flex justify-center no-select">
              <v-img
                v-if="is_Show_loading"
                :src="require('../../assets/blue-loader.gif')"
                max-width="86"
              ></v-img>
              <v-img
                v-else
                :src="require('../../assets/success.png')"
                max-width="86"
              ></v-img>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import AssignmentClasscodes from "./assignment_classcodes.vue";
import AssignmentQuestions from "./assignment_questions.vue";
import Backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "AssignmentEditPage",
  data() {
    return {
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      isWarning: false,
      isLoading: false,
      isDataSavedDialog: false,
      isLinkArticleDialog: false,
      isSearching: false,
      is_Show_loading: false,
      isViewArticleDialog: false,
      show_Missing_classcode_Warning: false,
      show_Existing_classcode_Warning: false,
      show_incorrectly_locked_classcode_message: false,
      show_hidden_classcode_message: false,
      search_article_keyword: "",
      form: {},
      assignmentTypes: ["SUBJECTIVE", "OBJECTIVE", "DOCUMENT", "CUMULATIVE"],
      statusItems: [
        { id: "PENDING", text: "PENDING", value: 0 },
        { id: "APPROVED", text: "APPROVED", value: 1 },
        { id: "REJECTED", text: "REJECTED", value: 2 },
      ],
      classcodes: [],
      variants: [],
      articles: [],
    };
  },
  mounted() {
    this.getData();
  },
  components: {
    AssignmentClasscodes,
    AssignmentQuestions,
    Backbutton,
    Loader,
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let assignment = await axios.get(
        `/assignments/${this.$route.params.assignmentId}`
      );
      this.form = assignment.data.data;
      this.form.hasFile = this.form.documentpath ? "true" : "";
      if (this.form.content_id) {
        let content = await axios.get(`/contents/${this.form.content.id}`);
        this.content = content.data.data;
        if (this.form.content.content_descriptions.length > 0) {
          this.form.content.content_descriptions.forEach((description) => {
            // If Article has Variants then Add levels of each variant in Variants[]
            this.variants.push({
              text: description.level,
              value: description.id,
            });
          });
        }
      }
      //   classcodes
      let classcodes = await axios.get("users/masters");
      classcodes = classcodes.data.classcodes;
      if (classcodes) {
        classcodes.forEach((classcode) => {
          this.classcodes.push({
            id: classcode.id,
            text: classcode.classcode,
            value: classcode.id,
            standard_id: classcode.standard_id,
            section_id: classcode.section_id,
          });
        });
      }
      this.isLoading = false;
    },
    async searchArticles() {
      this.isSearching = true;
      let query = "subject_id=&search_keyword=" + this.search_article_keyword;
      let articles = await axios.get(`/contents?${query}`);
      this.articles = articles.data.data;
      this.isSearching = false;
    },
    async linkArticle(article) {
      this.form.content_id = article.id;
      let content = await axios.get(`/contents/${article.id}`);
      this.content = content.data.data;
      if (this.content.content_descriptions.length > 0) {
        // If Article has Variants then Add levels of each variant in Variants[]
        this.content.content_descriptions.forEach((description) => {
          this.variants.push({
            text: description.level,
            value: description.id,
          });
        });
      }
      this.closeLinkArticleDialog();
      this.closeViewArticleDialog();
    },
    closeLinkArticleDialog() {
      this.isLinkArticleDialog = false;
    },
    closeViewArticleDialog() {
      this.isViewArticleDialog = false;
    },
    isModificationEnabled() {
      var response = false;
      if (this.roleName == "TEACHER") {
        // For Teacher
        if (this.$route.query.copyFromAssignmentId) {
          if (this.form.is_modified == true) {
            // If Copied & Enabled Modification
            response = true;
          }
        } else {
          // If New Assignment
          response = true;
        }
      } else {
        // For Infakt Teacher
        response = true;
      }
      console.log(response);
      return response;
    },
    CreateQuestions() {
      this.form.assignment_questions = [];
      for (let index = 0; index < this.form.no_of_questions; index++) {
        let assignmentQuestion = {
          sr_no: this.form.assignment_questions.length + 1,
          assignment_type:
            this.form.assignment_type != "CUMULATIVE"
              ? this.form.assignment_type
              : "",
          description: "",
          model_answer: "",
          correct_options: [],
          marks: "",
          negative_marks: "",
          option1: "",
          option2: "",
          option3: "",
          option4: "",
          assignment_question_options: [],
          assignment_question_correct_options: [],
        };
        this.form.assignment_questions.push(assignmentQuestion);
      }
    },
    isClasscodeSelected() {
      if (
        this.roleName == "TEACHER" &&
        this.form.assignment_classcodes.length == 0
      ) {
        /** If Role Is teacher But No Classcode selected then Show Pop Up */
        this.show_Missing_classcode_Warning = true;
        this.focusDiv({ classcode_id: "requred" });
      } else {
        /** Move Forward Check for Existing Assignment */
        this.isQuestionAdded();
      }
    },
    // Check Whether atleast one question is added
    isQuestionAdded() {
      if (this.form.assignment_questions.length == 0) {
        /** If No question selected then Show Pop Up */
        this.focusDiv({ assignment_questions: "required" });
      } else {
        /** Move Forward Check for Existing Assignment */
        this.CheckExistAssignment();
      }
    },
    // Check Whether atleast one question is added
    CheckFile() {
      let check_uploaded_file = false;
      if (this.form.assignment_type == "DOCUMENT") {
        check_uploaded_file = true;
        if (
          this.form.copied_from_assignment_id &&
          this.form.is_modified == false
        ) {
          check_uploaded_file = false;
          this.form.hasFile = "true";
        }
      }
      if (check_uploaded_file && !this.form.documentpath) {
        if (this.$refs.file.files.length > 0) {
          this.form.hasFile = this.$refs.file.files.length > 0;
        } else {
          /** If No File selected then Show Pop Up */
          this.form.hasFile = "";
          this.focusDiv({ hasFile: "required" });
        }
      }
    },
    focusDiv(errors) {
      console.log(errors);
      // Use the ref to access the div element and focus it
      let e =
        Object.keys(errors)[0] == "maximum_marks"
          ? Object.keys(errors)[1]
          : Object.keys(errors)[0];
      console.log(e);
      switch (true) {
        case e == "content_id":
          console.log("Show the Content Linking...");
          this.$refs.linkContentBtn.$el.focus();
          this.scrollToSection("#linkContentBtn");
          break;
        case e == "assignment_type":
          console.log("Show the Assignment Type...");
          this.$refs.assignment_type.focus();
          this.scrollToSection("#assignment_type");
          break;
        case e == "content_description_id":
          console.log("Show the Lexile level...");
          this.$refs.content_description_id.focus();
          this.scrollToSection("#content_description_id");
          break;
        case e == "assignment_title":
          console.log("Show the Title...");
          this.$refs.assignment_title.focus();
          this.scrollToSection("#assignment_title");
          break;
        case e == "duration":
          console.log("Show the Duration...");
          this.$refs.duration.focus();
          this.scrollToSection("#duration");
          break;
        case e == "maximum_marks":
          console.log("Show the Maximium Marks...");
          this.$refs.maximum_marks.focus();
          this.scrollToSection("#maximum_marks");
          break;
        case e == "hasFile":
          console.log("Show the Document Upload option...");
          this.$refs.file.focus();
          this.scrollToSection("#file");
          break;
        case e == "classcode_id":
        case e.includes("assignment_classcodes"):
          console.log("Show the Classcode...");
          if (e.includes("assignment_classcodes")) {
            // this.$refs.assignment_title.focus();
            let srt = e.split(".").join("_");
            console.log("#" + srt);
            this.scrollToSection("#" + srt);
          } else {
            this.scrollToSection("#assignment_classcodes");
          }
          break;
        case e == "assignment_questions":
        case e.includes("assignment_questions"):
          if (e.includes("assignment_questions")) {
            console.log("Show the Marks...");
            let srt = e.split(".").join("_");
            console.log("#" + srt);
            this.scrollToSection("#" + srt);
          } else {
            this.scrollToSection("#assignment_questions");
          }
          break;
        case e == "no_of_questions":
          console.log("Show the no_of_questions...");
          this.$refs.no_of_questions.focus();
          this.scrollToSection("#no_of_questions");
          break;
        default:
          break;
      }
    },
    scrollToSection(selector) {
      // Example: smooth scroll to the specific section
      const element = document.querySelector(selector);
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo({
        top: middle,
        behavior: "smooth",
      });
    },
    async CheckExistAssignment() {
      /** Check if any assignment exist with the same Article & with the Same Variant*/
      let is_applicable = true;
      if (
        this.form.content_id &&
        this.form.content_description_id &&
        this.roleName == "TEACHER"
      ) {
        let query =
          "articleId=" +
          this.form.content_id +
          "&consider_all_classcode_teacher=true&variant_id=" +
          this.form.content_description_id;
        let assignments = await axios.get(`/assignments?${query}`);
        assignments = assignments.data.data;
        let selected_standards = [];
        let selected_sections = [];
        this.form.assignment_classcodes.forEach((aC) => {
          let selected_classcode = this.classcodes.find(
            (c) => c.value == aC.classcode_id
          );
          if (selected_classcode) {
            selected_standards.push(selected_classcode.standard_id);
            selected_sections.push(selected_classcode.section_id);
          }
        });

        let ExistingAssignment = "";
        let ExistingAssignmentStandard = "";
        let ExistingAssignmentSection = "";
        // let ExistingAssignmentClasscode = "";
        if (assignments) {
          assignments.forEach((assignment) => {
            assignment.assignment_classcodes.forEach((ac) => {
              if (ExistingAssignment == "" && ac.id != this.form.id) {
                ExistingAssignmentStandard = selected_standards.find(
                  (ss) => ss == ac.classcode.standard_id
                );
                ExistingAssignmentSection = selected_sections.find(
                  (ss) => ss == ac.classcode.section_id
                );
                // ExistingAssignmentClasscode =
                //   this.form.assignment_classcodes.find(
                //     (c) => c.classcode_id == ac.classcode_id
                //   );
                if (ExistingAssignmentStandard && ExistingAssignmentSection) {
                  ExistingAssignment = assignment;
                }
              }
            });
          });
        }
        if (ExistingAssignment) {
          is_applicable = false;
        }
      }

      if (is_applicable == true) {
        this.CheckHiddenLexileConfig();
      } else {
        this.show_Existing_classcode_Warning = true;
      }
      return is_applicable;
    },
    /** Check if any of the selected classcode is hidden for the linked content */
    CheckHiddenLexileConfig() {
      let is_hidden_classcode = "";
      if (
        this.form.assignment_classcodes.length &&
        this.content.id &&
        this.content?.content_hidden_classcodes.length
      ) {
        this.form.assignment_classcodes.forEach((classcode) => {
          if (is_hidden_classcode == "") {
            let exist = this.content.content_hidden_classcodes.find(
              (chc) => chc.classcode_id == classcode.classcode_id
            );
            is_hidden_classcode = exist ? exist : "";
          }
        });
      }

      if (is_hidden_classcode) {
        // Show Hidden Classcode Warning Message
        console.log("SHOW HIDDEN MESSAGE");
        this.show_hidden_classcode_message = true;
      } else {
        /** Move Forward Check for Locked Lexile Added */
        console.log("finished Hidden Classcode Check");
        console.log("Moved to Locked Lexile check");
        this.CheckLockedLexileConfig();
      }
    },
    /** Check if any of the selected classcode is locked at any other lexile for the linked content */
    CheckLockedLexileConfig() {
      let is_locked_correct_lexile = true;
      if (
        this.form.content_description_id &&
        this.content &&
        this.content.content_lock_classcodes.length &&
        this.form.assignment_classcodes.length
      ) {
        let selected_lexile = this.variants.find(
          (v) => v.value == this.form.content_description_id
        );
        this.form.assignment_classcodes.forEach((classcode) => {
          let is_locks = this.content.content_lock_classcodes.filter(
            (clc) => clc.classcode_id == classcode.classcode_id
          );
          is_locks.forEach((lc) => {
            if (lc.level != selected_lexile.text) {
              is_locked_correct_lexile = false;
            }
          });
        });
      }
      if (is_locked_correct_lexile != true) {
        // Show Lock Classcode Warning Message
        this.show_incorrectly_locked_classcode_message = true;
      } else {
        /** Move Forward Check for Duration */
        console.log("finished Locked lexile Check");
        console.log("Moved to Duration Warning");
        this.warningMessage(
          (this.form.created_by_id == this.user.id &&
            this.roleName == "ACADEMIC TEAM") ||
            this.roleName == "TEACHER"
            ? "1"
            : this.roleName != "ACADEMIC TEAM"
            ? "0"
            : null
        );
      }
    },
    warningMessage(status) {
      console.log(status);
      if (status) this.form.status = status;
      if (
        this.roleName == "TEACHER" &&
        this.form.assignment_type != "DOCUMENT" &&
        (this.form.duration == null || this.form.duration == "")
      ) {
        this.isWarning = true;
      } else {
        this.isWarning = false;
        this.save();
      }
    },
    async save(is_draft = 0) {
      try {
        this.isWarning = false;
        this.is_Show_loading = true;
        this.isLoading = true;
        this.form.maximum_marks = 0;
        this.form.assignment_questions.forEach(
          (aQ) => (this.form.maximum_marks += parseInt(aQ.marks))
        );
        this.CheckFile();
        this.form.assignment_questions.forEach((assignment_question) => {
          // if (assignment_question.correct_options) {
          assignment_question.correct_options.forEach((qco) => {
            let assignment_question_correct_option = {
              option: qco,
            };
            assignment_question.assignment_question_correct_options.push(
              assignment_question_correct_option
            );
          });
          // }
        });
        if (is_draft == true) {
          // Save as Draft
          this.form.status = "3";
        }
        await axios.post(`/assignments`, this.form);
        await this.handleFileUpload();
        this.isLoading = false;
        this.isDataSavedDialog = true;
        var _this = this;
        // Simulate a is_Show_loading delay of 2 seconds
        setTimeout(() => {
          // After 0.8 seconds, change the is_Show_loading state to false
          _this.is_Show_loading = false;
        }, 800);
        // Simulate a redirect delay of 3 seconds
        setTimeout(() => {
          // After 1.8 seconds, Close Dialog
          this.$router.push("/assignments");
        }, 1800);
      } catch (e) {
        this.isLoading = false;
        console.log(e);
        let errors = e.response.data.errors;
        this.focusDiv(errors);
      }
    },
    async handleFileUpload() {
      if (this.$refs.file != null) {
        let attachment = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("assignmentid", this.form.id);
        formData.append("documentpath", attachment);
        await axios
          .post("upload_assignment_document", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
      }
    },
  },
};
</script>
