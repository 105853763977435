<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Users'"></span>
          <!-- <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            to="/admin/users/create"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn> -->
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left sr_no_class">Sr No</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Phone</th>
                    <th class="text-left">Role</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(board, i) in users" :key="board.name">
                    <td>{{ i + 1 }}</td>
                    <td>{{ board.first_name }} {{ board.last_name }}</td>
                    <td>{{ board.email }}</td>
                    <td>{{ board.phone }}</td>
                    <td>{{ board.roles[0].name }}</td>
                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/academic-team/users/${board.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "UsersPage",
  data() {
    return {
      users: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let users = await axios.get(`users?role_id=4`);
      this.users = users.data.data;
      this.isLoading = false;
    },
  },
};
</script>
