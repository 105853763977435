<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 120px">
          <span class="text-h5 font-weight-bold" v-text="'Manuals'"></span>
          <addbutton :link="`/manuals/create?role=${selectedRole}`"></addbutton>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <v-autocomplete
            ref="role"
            v-model="selectedRole"
            :items="roleItems"
            label="Role"
            prepend-icon="mdi-gender-male-female"
            @input="getData"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>

      <div v-else>
        <!-- List View -->
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 56px">Sr No</th>
                    <th class="text-left" style="min-width: 57px">Title</th>
                    <th class="text-right" style="min-width: 70px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(manual, i) in manuals" :key="manual.name">
                    <td>{{ i + 1 }}</td>
                    <td style="min-width: 130px">{{ manual.title }}</td>
                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        x-small
                        color="primary"
                        :to="`/manuals/${manual.id}/manual_details`"
                      >
                        Details
                      </v-btn>
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        dark
                        color="red"
                        @click="DeleteIt(manual.id)"
                      >
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/manuals/${manual.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";

export default {
  components: { addbutton, Loader },
  data() {
    return {
      isLoading: false,
      isDeleting: false,
      manuals: [],
      count: null,
      roleItems: [
        {
          text: "ADMIN",
          value: "ADMIN",
        },
        {
          text: "TEACHER",
          value: "TEACHER",
        },
        {
          text: "STUDENT",
          value: "STUDENT",
        },
      ],
      selectedRole: "ADMIN",
    };
  },
  mounted() {
    this.selectedRole = this.$route.query.role
      ? this.$route.query.role
      : "ADMIN";
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let manuals = await axios.get(`/manuals?role=${this.selectedRole}`);
      this.manuals = manuals.data.data;
      this.count = manuals.data.count;
      this.isLoading = false;
    },
    async DeleteIt(manualId) {
      try {
        this.isDeleting = true;
        await axios.delete(`/manuals/${manualId}`);
        this.isDeleting = false;
        this.getData();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
