<template>
  <v-main v-if="!isauthenticated">Not Authenticated</v-main>
  <v-main class="white" v-else>
    <!-- <v-main class="white"> -->
    <v-row
      :class="{
        'py-16': $vuetify.breakpoint.mdAndUp,
        'py-4': $vuetify.breakpoint.smAndDown,
      }"
    >
      <v-col
        align="center"
        :class="{
          'mt-4': $vuetify.breakpoint.mdAndUp,
          'mt-6': $vuetify.breakpoint.smAndDown,
        }"
      >
        <span
          :class="{
            'text-h2 font-weight-bold myFont': $vuetify.breakpoint.mdAndUp,
            'display-1 font-weight-bold myFont': $vuetify.breakpoint.smAndDown,
          }"
        >
          Product Features</span
        >
        <!-- <span class="text-h2 font-weight-bold myFont"> Product Features</span> -->
      </v-col>
    </v-row>
    <!-- <v-container>
      <br />
      <center>
        <span class="text-h3 font-weight-bold"> Product Features </span>
      </center>
      <br /><br />
    </v-container> -->
    <section>
      <v-container>
        <v-row>
          <template v-for="(tabList, at) in tabLists">
            <v-col md="6" :key="`tablist${at}`">
              <v-row justify="center">
                <v-expansion-panels
                  v-if="$vuetify.breakpoint.mdAndDown"
                  popout
                  focusable
                  :class="{
                    'rounded-lg my-3 lightgrey-2': $vuetify.breakpoint.mdAndUp,
                    'rounded-lg my-2 lightgrey-2 ml-4':
                      $vuetify.breakpoint.smAndDown,
                  }"
                  style="width: 395px"
                >
                  <!-- class="rounded-lg my-2 lightgrey-2" -->
                  <v-expansion-panel class="grey lighten-4">
                    <v-expansion-panel-header
                      :class="{
                        'mt-2 text-h6': $vuetify.breakpoint.mdAndUp,
                        'mt-5': $vuetify.breakpoint.smAndDown,
                      }"
                    >
                      <!-- <v-expansion-panel-header class="mt-5 text-h6"> -->
                      <b>{{ tabList.title }}</b>
                      <template v-slot:actions>
                        <v-icon color="primary">
                          {{ "mdi-" + tabList.icon }}
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      :class="{
                        'text-h6 features_panel myFont':
                          $vuetify.breakpoint.mdAndUp,
                        'body-1 features_panel myFont':
                          $vuetify.breakpoint.smAndDown,
                      }"
                    >
                      <!-- <v-expansion-panel-content class="text-h6 features_panel"> -->
                      <hr />
                      {{ tabList.description }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels
                  v-else
                  popout
                  focusable
                  :class="{
                    'rounded-lg my-3 lightgrey-2': $vuetify.breakpoint.mdAndUp,
                    'rounded-lg my-2 lightgrey-2':
                      $vuetify.breakpoint.smAndDown,
                  }"
                >
                  <!-- class="rounded-lg my-2 lightgrey-2" -->
                  <v-expansion-panel class="grey lighten-4">
                    <v-expansion-panel-header
                      :class="{
                        'mt-2 text-h6': $vuetify.breakpoint.mdAndUp,
                        'mt-5': $vuetify.breakpoint.smAndDown,
                      }"
                    >
                      <!-- <v-expansion-panel-header class="mt-5 text-h6"> -->
                      <b>{{ tabList.title }}</b>
                      <template v-slot:actions>
                        <v-icon color="primary">
                          {{ "mdi-" + tabList.icon }}
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      :class="{
                        'text-h6 features_panel myFont':
                          $vuetify.breakpoint.mdAndUp,
                        'body-1 features_panel myFont':
                          $vuetify.breakpoint.smAndDown,
                      }"
                    >
                      <!-- <v-expansion-panel-content class="text-h6 features_panel"> -->
                      <hr />
                      {{ tabList.description }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </section>
    <br /><br />
    <Foot></Foot>
  </v-main>
</template>
<script>
import Foot from "../views/home/foot.vue";

export default {
  name: "Aboutus",
  components: {
    Foot,
  },
  mounted() {
    // let password = prompt("Enter passcode");
    // if (password == "Infakt2023") {
    //   this.isauthenticated = true;
    // } else {
    //   alert("Wrong Password");
    // }
  },
  data() {
    return {
      tabLists: [
        {
          title: "Data analytics on student progress",
          icon: "format-list-checks",
          description:
            "Monitor student development with the help of our reports. Get a detailed breakdown of subject-wise grading and progress charts.",
        },
        {
          title: "Literary and video-graphic content",
          icon: "video-box",
          description:
            "Complement your curriculum with our library of curated content tailored around classroom learning. Pique student interest and get them excited to learn.",
        },
        {
          title: "Assessment mechanisms",
          icon: "cog-box",
          description:
            "We provide interactive assignments and give teachers the flexibility to provide their own assessments with various parameters in mind.",
        },
        {
          title: "Integrated dictionary",
          icon: "magnify",
          description:
            "Improve student articulation and vocabulary. Power words and our integrated dictionary foster language development for all our students.",
        },
        {
          title: "Annotations",
          icon: "comment-edit",
          description:
            "Infakt allows students and teachers to create, share and save highlights and annotations made by them on the articles itself.",
        },
        {
          title: "Infographic section",
          icon: "information-variant",
          description:
            "Students brush up on foundational concepts and get power-packed information in a condensed format. Visual representations have long served to be a proven mechanism for memory retention.",
        },
        {
          title: "Lexile test and recommended reading level",
          icon: "sort-alphabetical-variant",
          description:
            "Our platform will conduct a Lexile test and recommend reading levels for students, thus improving their skills over time.",
        },
        {
          title: "Multiple topics",
          icon: "content-copy",
          description:
            "Our platform covers a wide range of topics across diverse cultures and opinions. Globalise your pupils with information relevant to their growth.",
        },
        {
          title: "Accessibility for all",
          icon: "access-point-check",
          description:
            "Articles are published at multiple reading levels, thus allowing an entire class to read and discuss the same content, while permitting individual students to access material at their individual reading level.",
        },
        {
          title: "Frequently updated",
          icon: "rocket-launch",
          description:
            "Our in house team is constantly churning out new content across multiple topics to ensure we provide the most up to date material.",
        },
        {
          title: "Gamified offerings",
          icon: "gamepad-circle-outline",
          description:
            "When learning becomes fun, students learn because they want to, not because they have to. Our team is constantly innovating to gamify the learning experience for our users.",
        },
        {
          title: "Parental access",
          icon: "account-group",
          description:
            "Parents can log on to keep track of their child’s development.",
        },
        {
          title: "Student mobile app",
          icon: "cellphone",
          description:
            "Students can access engaging content and their homework even if they don’t have a computer.",
        },
        {
          title: "Syncs with your LMS",
          icon: "book-open-blank-variant",
          description:
            "Sign on easily and register students via the most popular platforms in use today.",
        },
      ],
      isauthenticated: true,
    };
  },
};
</script>

<style>
.features_panel {
  height: 150px !important;
}
</style>
