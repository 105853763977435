var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isauthenticated)?_c('v-main',[_vm._v("Not Authenticated")]):_c('v-main',{staticClass:"white"},[_c('v-row',{class:{
      'py-16': _vm.$vuetify.breakpoint.mdAndUp,
      'py-4': _vm.$vuetify.breakpoint.smAndDown,
    }},[_c('v-col',{class:{
        'mt-4': _vm.$vuetify.breakpoint.mdAndUp,
        'mt-6': _vm.$vuetify.breakpoint.smAndDown,
      },attrs:{"align":"center"}},[_c('span',{class:{
          'text-h2 font-weight-bold myFont': _vm.$vuetify.breakpoint.mdAndUp,
          'display-1 font-weight-bold myFont': _vm.$vuetify.breakpoint.smAndDown,
        }},[_vm._v(" Product Features")])])],1),_c('section',[_c('v-container',[_c('v-row',[_vm._l((_vm.tabLists),function(tabList,at){return [_c('v-col',{key:`tablist${at}`,attrs:{"md":"6"}},[_c('v-row',{attrs:{"justify":"center"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-expansion-panels',{class:{
                  'rounded-lg my-3 lightgrey-2': _vm.$vuetify.breakpoint.mdAndUp,
                  'rounded-lg my-2 lightgrey-2 ml-4':
                    _vm.$vuetify.breakpoint.smAndDown,
                },staticStyle:{"width":"395px"},attrs:{"popout":"","focusable":""}},[_c('v-expansion-panel',{staticClass:"grey lighten-4"},[_c('v-expansion-panel-header',{class:{
                      'mt-2 text-h6': _vm.$vuetify.breakpoint.mdAndUp,
                      'mt-5': _vm.$vuetify.breakpoint.smAndDown,
                    },scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s("mdi-" + tabList.icon)+" ")])]},proxy:true}],null,true)},[_c('b',[_vm._v(_vm._s(tabList.title))])]),_c('v-expansion-panel-content',{class:{
                      'text-h6 features_panel myFont':
                        _vm.$vuetify.breakpoint.mdAndUp,
                      'body-1 features_panel myFont':
                        _vm.$vuetify.breakpoint.smAndDown,
                    }},[_c('hr'),_vm._v(" "+_vm._s(tabList.description)+" ")])],1)],1):_c('v-expansion-panels',{class:{
                  'rounded-lg my-3 lightgrey-2': _vm.$vuetify.breakpoint.mdAndUp,
                  'rounded-lg my-2 lightgrey-2':
                    _vm.$vuetify.breakpoint.smAndDown,
                },attrs:{"popout":"","focusable":""}},[_c('v-expansion-panel',{staticClass:"grey lighten-4"},[_c('v-expansion-panel-header',{class:{
                      'mt-2 text-h6': _vm.$vuetify.breakpoint.mdAndUp,
                      'mt-5': _vm.$vuetify.breakpoint.smAndDown,
                    },scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s("mdi-" + tabList.icon)+" ")])]},proxy:true}],null,true)},[_c('b',[_vm._v(_vm._s(tabList.title))])]),_c('v-expansion-panel-content',{class:{
                      'text-h6 features_panel myFont':
                        _vm.$vuetify.breakpoint.mdAndUp,
                      'body-1 features_panel myFont':
                        _vm.$vuetify.breakpoint.smAndDown,
                    }},[_c('hr'),_vm._v(" "+_vm._s(tabList.description)+" ")])],1)],1)],1)],1)]})],2)],1)],1),_c('br'),_c('br'),_c('Foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }