<template>
  <section>
    <audio ref="audioElm" :src="audioSrc" controls></audio>
  </section>
</template>


<script>
export default {
  data() {
    return {
      audioSrc: "",
    };
  },
  components: {},
  props: ["audioPath", "is_greater_than_5000"],
  watch: {
    audioPath: "onAudioPathChanged",
  },
  mounted() {
    if (this.is_greater_than_5000) this.audioSrc = this.audioPath;
    else
      this.audioSrc = `https://az2-medias.s3.ap-south-1.amazonaws.com/${this.audioPath}`;
    console.log(this.is_greater_than_5000);
    console.log(this.audioSrc);
  },
  methods: {
    onAudioPathChanged() {
      if (this.is_greater_than_5000) this.audioSrc = this.audioPath;
      else
        this.audioSrc = `https://az2-medias.s3.ap-south-1.amazonaws.com/${this.audioPath}`;

      console.log(this.is_greater_than_5000);
      console.log(this.audioSrc);

      // var element = this.$refs.audioElm;
      // console.log(element.src)
      //   setTimeout(function () {
      //     element.src = this.audioSrc
      //     element.currentTime = 0;
      //     // element.crossOrigin = "anonymus";
      //     element.load()
      //   }, 0);
    },
  },
};
</script>