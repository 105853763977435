<template>
  <div>
    <!-- <hr class="mb-5" /> -->
    <v-divider></v-divider>
    <v-row class="my-3">
      <v-col style="min-width: 250" class="py-0" lg="2">
        <h2 id="assignment_classcodes" ref="assignment_classcodes">
          Class Codes
        </h2>
      </v-col>
      <v-col class="py-0" style="min-width: 250">
        <b
          style="color: red"
          v-if="
            errors.assignment_classcodes || !form.assignment_classcodes?.length
          "
        >
          <span class="smallScreen">
            Atleast one classcode is required
          </span>
          <span class="midScreen"> Atleast one classcode is required </span>
          <h2 class="largeScreen">Atleast one classcode is required</h2>
        </b>
      </v-col>
    </v-row>
    <v-btn small class="mb-4" color="primary" @click="addEmptyClascode">
      Add Classcode
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
    <v-row
      v-for="(assignmentClasscode, aC) in form.assignment_classcodes"
      :key="`assignmentClasscode${aC}`"
    >
      <v-col sm="5" md="5" lg="5" style="min-width: 250">
        <v-autocomplete
          :id="`assignment_classcodes_${aC}_classcode_id`"
          :ref="`assignment_classcodes_${aC}_classcode_id`"
          v-model="assignmentClasscode.classcode_id"
          :items="classcodes"
          label="Select classcode"
          prepend-icon="mdi-application-edit"
          :error-messages="errors[`assignment_classcodes.${aC}.classcode_id`]"
        ></v-autocomplete>
      </v-col>
      <v-col sm="3" md="3" lg="3" style="min-width: 140">
        <v-menu
          v-model="assignmentClasscode.start_date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="assignmentClasscode.start_date"
              label="Start Date"
              :id="`assignment_classcodes_${aC}_start_date`"
              :ref="`assignment_classcodes_${aC}_start_date`"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :error-messages="errors[`assignment_classcodes.${aC}.start_date`]"
            ></v-text-field>
          </template>

          <v-date-picker
            :id="`start_date${aC}`"
            v-model="assignmentClasscode.start_date"
            @change="
              (assignmentClasscode.start_date_menu = false),
                (assignmentClasscode.end_date = '')
            "
            :min="nowDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col sm="3" md="3" lg="3" style="min-width: 140">
        <v-menu
          v-model="assignmentClasscode.end_date_menu"
          :close-on-content-click="false"
          :nudge-left="100"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="assignmentClasscode.end_date"
              label="End Date"
              :id="`assignment_classcodes_${aC}_end_date`"
              :ref="`assignment_classcodes_${aC}_end_date`"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :error-messages="errors[`assignment_classcodes.${aC}.end_date`]"
            ></v-text-field>
          </template>
          <v-date-picker
            :id="`end_date${aC}`"
            v-model="assignmentClasscode.end_date"
            @change="assignmentClasscode.end_date_menu = false"
            :min="assignmentClasscode.start_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="px-0" sm="1" md="1" lg="1">
        <v-dialog v-model="isDeleteClasscodeDialogs[aC]" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small dark color="red">
              <v-icon dark v-bind="attrs" v-on="on"> mdi-close-thick </v-icon>
            </v-btn>
          </template>

          <v-card elevation="10" align="right">
            <v-card-title class="justify-center primary accent-1 white--text">
              Do you want to remove the classcode?
            </v-card-title>
            <br />
            <v-card-text>
              <v-btn
                color="primary"
                @click.stop="deleteClasscode(assignmentClasscode)"
              >
                Yes
              </v-btn>
              &nbsp;
              <v-btn color="red" dark @click.stop="closeClasscodeDialogs">
                No
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AssignmentClasscodeComponent",
  data() {
    return {
      isDeleteClasscodeDialogs: [],
      start_date_menu: false,
      end_date_menu: false,
      nowDate: new Date().toISOString().slice(0, 10),
    };
  },
  props: ["form", "classcodes"],
  methods: {
    addEmptyClascode() {
      let assignmentClasscode = {
        company_id: this.company.id,
        classcode_id: "",
        start_date: "",
        start_date_menu: false,
        end_date: "",
        end_date_menu: false,
      };
      this.form.assignment_classcodes.push(assignmentClasscode);
    },
    deleteClasscode(assignmentClasscode) {
      this.form.assignment_classcodes.splice(
        this.form.assignment_classcodes.indexOf(assignmentClasscode),
        1
      );
      this.closeClasscodeDialogs();
    },
    closeClasscodeDialogs() {
      this.isDeleteClasscodeDialogs = [];
    },
  },
};
</script>
