<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Add a Lesson Plan
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="6">
              <v-text-field
                v-model="form.title"
                outlined
                label="Title *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.title"
              ></v-text-field>
            </v-col>
            <v-col sm="6">
              <v-text-field
                v-model="form.no_of_days"
                outlined
                label="No Of Days"
                prepend-inner-icon="mdi-buffet"
                messages="Please mention the total no of days to create Days. "
                @keyup="CreateDays"
                :error-messages="errors.no_of_days"
              ></v-text-field>
              <!-- @keypress.enter="CreateDays" -->
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <p class="font-weight-bold">Learning Outcome</p>
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="form.description"
              ></froala>
            </v-col>
          </v-row>
          <!-- LP Days -->
          <v-row>
            <v-col>
              <v-expansion-panels class="my-5" elevation="6">
                <v-expansion-panel
                  v-for="(lp_day, lp) in form.lp_days"
                  :key="`lpdays${lp}`"
                  class="my-2"
                  elevation="6"
                >
                  <v-expansion-panel-header color="#FFEBEE" dark>
                    Day {{ lp + 1 }}
                    <v-spacer></v-spacer>
                    <v-dialog
                      v-model="isDeleteDetailDialogs[lp]"
                      max-width="600px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          max-width="32"
                          fab
                          x-small
                          dark
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon dark> mdi-close-thick </v-icon>
                        </v-btn>
                      </template>
                      <v-card elevation="10">
                        <v-card-title>
                          <v-icon large color="primary">
                            mdi-information-outline
                          </v-icon>
                          &nbsp; Do you want to remove the detail?
                        </v-card-title>
                        <v-card-text>
                          <v-btn
                            color="primary"
                            class="mr-5"
                            @click.stop="deleteDetail(form.lp_days, lp)"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="red"
                            dark
                            @click.stop="isDeleteDetailDialogs = []"
                          >
                            No
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="mt-5">
                      <v-col sm="6">
                        <v-text-field
                          v-model="lp_day.title"
                          outlined
                          background-color="white"
                          color="#67697C"
                          label="Subtitle *"
                          prepend-inner-icon="mdi-buffet"
                          :error-messages="errors.title"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col sm="12">
                        <p class="font-weight-bold">Learning Outcome</p>
                        <froala
                          :tag="'textarea'"
                          :config="config"
                          v-model="lp_day.description"
                        ></froala>
                      </v-col>
                    </v-row>
                    <!-- LP Day Modules -->
                    <template>
                      <v-expansion-panels class="my-5">
                        <v-expansion-panel
                          v-for="(lp_day_module, ldm) in lp_day.lp_day_modules"
                          :key="`lpdays${ldm}`"
                          class="my-2"
                        >
                          <v-expansion-panel-header color="#FFCDD2">
                            <!-- 
                            class="white--text"
                              color="#253D5B" -->
                            {{ lp_day_module.title }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content color="#FFEBEE">
                            <v-row class="mt-5">
                              <v-col sm="6">
                                <v-text-field
                                  v-model="lp_day_module.title"
                                  outlined
                                  background-color="white"
                                  color="#253D5B"
                                  label="Title *"
                                  prepend-inner-icon="mdi-buffet"
                                  :error-messages="errors.title"
                                ></v-text-field>
                              </v-col>
                              <v-col sm="6">
                                <v-text-field
                                  v-model="lp_day_module.method"
                                  outlined
                                  background-color="white"
                                  color="#253D5B"
                                  label="Method"
                                  prepend-inner-icon="mdi-buffet"
                                  :error-messages="errors.method"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col md="3"
                                ><v-switch
                                  class="mt-1"
                                  v-model="
                                    lp_day_module.is_description_presentable
                                  "
                                  inset
                                  :label="`Is Description Presentable: ${
                                    lp_day_module.is_description_presentable
                                      ? 'TRUE'
                                      : 'FALSE'
                                  }`"
                                ></v-switch
                              ></v-col>
                              <v-col sm="12">
                                <p class="font-weight-bold">Description</p>
                                <froala
                                  :tag="'textarea'"
                                  :config="config"
                                  v-model="lp_day_module.description"
                                ></froala>
                              </v-col>
                            </v-row>
                            <!-- LP Day Module Details -->
                            <template
                              v-for="(
                                lp_detail, lpD
                              ) in lp_day_module.lp_details"
                            >
                              <v-divider
                                :key="`divider_${lpD}`"
                                class="my-5"
                              ></v-divider>
                              <v-row :key="`detail_type_${lpD}`">
                                <v-col md="3"
                                  ><v-autocomplete
                                    v-model="lp_detail.detail_type"
                                    :items="detailTypeItems"
                                    dense
                                    outlined
                                    background-color="white"
                                    hide-details
                                  ></v-autocomplete
                                ></v-col>
                                <v-col md="3"
                                  ><v-switch
                                    class="mt-1"
                                    v-model="lp_detail.is_presentable"
                                    inset
                                    :label="`Is Presentable: ${
                                      lp_detail.is_presentable
                                        ? 'TRUE'
                                        : 'FALSE'
                                    }`"
                                  ></v-switch
                                ></v-col>
                                <v-spacer></v-spacer>
                                <v-col sm="1">
                                  <v-dialog
                                    v-model="isDeleteDetailDialogs[lpD]"
                                    max-width="600px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        fab
                                        x-small
                                        dark
                                        color="red"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon dark> mdi-close-thick </v-icon>
                                      </v-btn>
                                    </template>
                                    <v-card elevation="10">
                                      <v-card-title>
                                        <v-icon large color="primary">
                                          mdi-information-outline
                                        </v-icon>
                                        &nbsp; Do you want to remove the detail?
                                      </v-card-title>
                                      <v-card-text>
                                        <v-btn
                                          color="primary"
                                          @click.stop="
                                            deleteDetail(
                                              lp_day_module.lp_details,
                                              lpD
                                            )
                                          "
                                        >
                                          Yes
                                        </v-btn>
                                        &nbsp;
                                        <v-btn
                                          color="red"
                                          dark
                                          @click.stop="
                                            isDeleteDetailDialogs = []
                                          "
                                        >
                                          No
                                        </v-btn>
                                      </v-card-text>
                                    </v-card>
                                  </v-dialog>
                                </v-col>
                              </v-row>
                              <v-row :key="`description_${lpD}`">
                                <v-col md="12">
                                  <template
                                    v-if="
                                      lp_detail.detail_type == 'Text' ||
                                      lp_detail.detail_type == 'Infographic' ||
                                      lp_detail.detail_type == 'Video' ||
                                      lp_detail.detail_type ==
                                        'General Instruction'
                                    "
                                  >
                                    <p class="font-weight-bold">
                                      {{ lp_detail.detail_type }}
                                    </p>
                                    <froala
                                      :tag="'textarea'"
                                      :config="config"
                                      v-model="lp_detail.description"
                                    ></froala
                                  ></template>
                                  <template
                                    v-else-if="
                                      lp_detail.detail_type == 'Document'
                                    "
                                  >
                                    <input
                                      type="file"
                                      id="file"
                                      name="lp_day_module_file"
                                      :ref="`lp_day_module_file_${lpD}`"
                                      @input="
                                        attachmentAdded(
                                          lp_detail,
                                          `lp_day_module_file_${lpD}`
                                        )
                                      "
                                  /></template>
                                  <template
                                    v-else-if="
                                      lp_detail.detail_type == 'Article'
                                    "
                                  >
                                    <v-card
                                      elevation="2"
                                      v-if="
                                        lp_detail.lp_detail_links.length == 0
                                      "
                                    >
                                      <v-card-title>
                                        Articles
                                        <loader
                                          v-if="isSearching"
                                          size="25"
                                          class="ml-3"
                                        ></loader
                                      ></v-card-title>
                                      <v-card-text>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              v-model="search_article_keyword"
                                              label="Search ... "
                                              prepend-inner-icon="mdi-magnify"
                                              @keydown.enter="searchArticles"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <br />
                                        <p class="font-weight-bold">
                                          Articles List
                                        </p>
                                        <br />
                                        <v-row
                                          v-for="(article, a) in articles"
                                          :key="`article${a}`"
                                        >
                                          <v-col sm="9">
                                            {{ article.content_name }}
                                          </v-col>
                                          <v-col sm="3">
                                            <v-btn
                                              small
                                              dark
                                              color="primary"
                                              @click="
                                                linkContent(lp_detail, article)
                                              "
                                            >
                                              Link This Article
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                    </v-card>
                                    <v-card v-else color="blue lighten-5">
                                      <v-card-title
                                        >Article Details</v-card-title
                                      >
                                      <v-card-text>
                                        {{
                                          lp_detail.lp_detail_links[0]
                                            .content_name
                                        }}
                                      </v-card-text>
                                    </v-card>
                                  </template>
                                  <template
                                    v-else-if="
                                      lp_detail.detail_type == 'Assignment'
                                    "
                                  >
                                    <v-card
                                      elevation="2"
                                      v-if="
                                        lp_detail.lp_detail_assignments
                                          .length == 0
                                      "
                                    >
                                      <v-card-title>
                                        Assignments
                                        <loader
                                          v-if="isSearching"
                                          size="25"
                                          class="ml-3"
                                        ></loader
                                      ></v-card-title>
                                      <v-card-text>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              v-model="
                                                search_assignment_keyword
                                              "
                                              label="Search ... "
                                              prepend-inner-icon="mdi-magnify"
                                              @keydown.enter="searchAssignments"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <br />
                                        <p class="font-weight-bold">
                                          Assignments List
                                        </p>
                                        <br />
                                        <v-row
                                          v-for="(assignment, a) in assignments"
                                          :key="`assignment${a}`"
                                        >
                                          <v-col sm="9">
                                            {{ assignment.assignment_title }}
                                          </v-col>
                                          <v-col sm="3">
                                            <v-btn
                                              small
                                              dark
                                              color="primary"
                                              @click="
                                                linkAssignment(
                                                  lp_detail,
                                                  assignment
                                                )
                                              "
                                            >
                                              Link This Assignment
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                    </v-card>
                                    <v-card v-else color="blue lighten-5">
                                      <v-card-title
                                        >Assignment Details</v-card-title
                                      >
                                      <v-card-text>
                                        {{
                                          lp_detail.lp_detail_assignments[0]
                                            .assignment_title
                                        }}
                                      </v-card-text>
                                    </v-card>
                                  </template>
                                  <template
                                    v-else-if="
                                      lp_detail.detail_type ==
                                      'Group Activity In Class'
                                    "
                                  >
                                    <v-row class="mt-5">
                                      <v-col sm="6">
                                        <v-text-field
                                          v-model="lp_detail.title"
                                          outlined
                                          background-color="white"
                                          color="#253D5B"
                                          label="Title *"
                                          prepend-inner-icon="mdi-buffet"
                                          :error-messages="errors.title"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col sm="6">
                                        <v-text-field
                                          v-model="lp_detail.no_of_groups"
                                          outlined
                                          background-color="white"
                                          label="No Of Groups"
                                          prepend-inner-icon="mdi-buffet"
                                          messages="Please mention the total no of groups to create Groups. "
                                          @keyup="CreateGroups(lp_detail)"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row class="mt-5">
                                      <v-col>
                                        <froala
                                          :tag="'textarea'"
                                          :config="config"
                                          v-model="lp_detail.description"
                                        ></froala>
                                      </v-col>
                                    </v-row>
                                    <!-- Groups -->
                                    <v-row>
                                      <v-col>
                                        <v-expansion-panels
                                          class="my-5"
                                          elevation="6"
                                        >
                                          <v-expansion-panel
                                            v-for="(
                                              group, g
                                            ) in lp_detail.lp_details"
                                            :key="`groups_${g}`"
                                            class="my-2"
                                            elevation="6"
                                          >
                                            <v-expansion-panel-header
                                              color="#FFCDD2"
                                              dark
                                            >
                                              Group {{ g + 1 }}
                                              <v-spacer></v-spacer>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content
                                              color="white"
                                            >
                                              <v-row class="mt-5">
                                                <v-col>
                                                  <p class="font-weight-bold">
                                                    Student Instruction
                                                  </p>
                                                  <froala
                                                    :tag="'textarea'"
                                                    :config="config"
                                                    v-model="group.description"
                                                  ></froala>
                                                </v-col>
                                              </v-row>
                                            </v-expansion-panel-content>
                                          </v-expansion-panel>
                                        </v-expansion-panels>
                                      </v-col>
                                    </v-row>
                                  </template>
                                </v-col>
                              </v-row>
                            </template>
                            <v-row>
                              <v-col>
                                <v-btn
                                  color="primary"
                                  dark
                                  x-small
                                  l
                                  @click="AddDetails(lp_day_module)"
                                >
                                  Add Details
                                </v-btn>
                              </v-col>
                            </v-row>
                            <!-- End for LP Day Module Details -->
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </template>
                    <!-- End for LP Day Modules -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <!-- End for LP Days -->
          <v-btn class="mb-5" small color="primary" dark @click="addEmptyDays">
            Add Days
          </v-btn>
          <br />
          <loader v-if="isLoading"></loader>
          <template v-else>
            <v-btn color="primary" dark @click="(form.status = 3), save()">
              Save in Draft
            </v-btn>
            <v-btn class="ml-5" color="primary" dark @click="save">
              Save Lesson Plan
            </v-btn>
          </template>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { backbutton, Loader },
  name: "LessonPlanCreatePage",
  data() {
    return {
      form: {
        title: "",
        standard: "",
        subject: "",
        board_id: "",
        lp_days: [],
        lp_details: [],
        is_active: true,
      },
      isSearching: false,
      search_article_keyword: "",
      articles: [],
      search_assignment_keyword: "",
      assignments: [],
      isDeleteDetailDialogs: [],
      detailTypeItems: [
        "Text",
        "Infographic",
        "Video",
        "Document",
        "Article",
        "Assignment",
        "Group Activity In Class",
        "Group Activity Out Of Class",
      ],
      config: {
        toolbarButtons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "insertLink",
          "insertImage",
          "insertVideo",
        ],
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      isLoading: false,
    };
  },
  mounted() {
    this.form.course_id = this.$route.params.courseId;
    this.form.created_by_id = this.user.id;
  },
  methods: {
    async searchArticles() {
      this.isSearching = true;
      let query = "subject_id=&search_keyword=" + this.search_article_keyword;
      let articles = await axios.get(`/contents?${query}`);
      this.articles = articles.data.data;
      this.isSearching = false;
    },
    async searchAssignments() {
      this.isSearching = true;
      let query = "search_keyword=" + this.search_assignment_keyword;
      let assignments = await axios.get(`/assignments?${query}`);
      this.assignments = assignments.data.data;
      this.isSearching = false;
    },
    attachmentAdded(parent, Key) {
      parent.document_path = this.$refs[Key][0].files[0];
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.no_of_days = this.form.lp_days.length;
        // await axios.post("/lps", this.form);
        let formTest = {};
        Object.assign(formTest, this.form);
        let formData = this.jsonToFormData(formTest);
        await axios.post("/lps", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.isLoading = false;
        this.$router.push(`/courses/${this.$route.params.courseId}/lps`);
      } catch (e) {
        this.isLoading = false;
      }
    },
    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },
    CreateDays() {
      this.form.lp_days = [];
      for (let index = 0; index < this.form.no_of_days; index++) {
        let lpDays = {
          sr_no: this.form.lp_days.length + 1,
          title: "",
          description: "",
          lp_day_modules: [
            {
              title: "Set Induction",
              is_description_presentable: false,
              lp_details: [],
            },
            {
              title: "Procedure",
              is_description_presentable: false,
              lp_details: [],
            },
            {
              title: "Plenary",
              is_description_presentable: false,
              lp_details: [],
            },
          ],
          lp_details: [],
        };
        this.form.lp_days.push(lpDays);
      }
    },
    CreateGroups(parent) {
      parent.lp_details = [];
      for (let index = 0; index < parent.no_of_groups; index++) {
        let lpGroup = {
          sr_no: index + 1,
          title: "",
          description: "",
        };
        parent.lp_details.push(lpGroup);
      }
    },
    addEmptyDays() {
      let lpDays = {
        sr_no: this.form.lp_days.length + 1,
        title: "",
        description: "",
        lp_day_modules: [
          {
            title: "Set Induction",
            lp_details: [],
          },
          {
            title: "Procedure",
            lp_details: [],
          },
          {
            title: "Plenary",
            lp_details: [],
          },
        ],
        lp_details: [],
      };
      this.form.lp_days.push(lpDays);
    },
    AddDetails(parent) {
      let lp_detail = {
        detail_type: "",
        is_presentable: false,
        description: "",
        lp_details: [],
        lp_detail_links: [],
        lp_detail_assignments: [],
        document_path: "",
      };
      parent.lp_details.push(lp_detail);
    },
    deleteDetail(parent, Key) {
      parent.splice(Key, 1);
      this.isDeleteDetailDialogs = [];
    },
    linkContent(parent, article) {
      let lp_detail_link = {
        content_id: article.id,
        content_name: article.content_name,
      };
      parent.lp_detail_links.push(lp_detail_link);
      this.search_article_keyword = "";
      this.articles = [];
    },
    linkAssignment(parent, assignment) {
      let lp_detail_assignment = {
        assignment_id: assignment.id,
        assignment_title: assignment.assignment_title,
      };
      parent.lp_detail_assignments.push(lp_detail_assignment);
      this.search_assignment_keyword = "";
      this.assignments = [];
    },
  },
};
</script>
