<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col>
        <a href="http://lms.pousse.in/">
          <v-img
            class="mx-auto"
            :src="require('../../assets/logo.png')"
            width="200"
          ></v-img>
        </a>
        <br />
        <br />
        <v-row>
          <v-col lg="4" md="4" sm="4">
            <v-card
              elevation="2"
              class="mx-auto"
              @click="ShowLogin('SCHOOL ADMIN')"
            >
              <v-img
                height="300"
                contain
                :src="require('../../assets/plms/manager.jpg')"
              ></v-img>
              <v-card-title class="justify-center">Manager Login</v-card-title>
            </v-card>
          </v-col>
          <v-col lg="4" md="4" sm="4">
            <v-card elevation="2" class="mx-auto" @click="ShowLogin('STUDENT')">
              <v-img
                height="300"
                contain
                :src="require('../../assets/plms/tsi.jpg')"
              ></v-img>
              <v-card-title class="justify-center">TSI Login</v-card-title>
            </v-card>
          </v-col>
          <v-col lg="4" md="4" sm="4">
            <v-card elevation="2" class="mx-auto" @click="ShowLogin('TEACHER')">
              <v-img
                height="300"
                contain
                :src="require('../../assets/plms/coordinator.jpg')"
              ></v-img>
              <v-card-title class="justify-center"
                >Coordinator Login</v-card-title
              >
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col
        md="3"
        align="center"
        justify="center"
        @click="ShowLogin('LMS TEAM')"
      >
        LMS Team Login
      </v-col>
    </v-row>
    <div class="py-6"></div>
    <!-- Role Login Dialog Box -->
    <v-dialog
      v-model="show_role_login"
      max-width="700"
      class="rounded-tl-xl rounded-br-xl"
    >
      <v-card class="rounded-tl-xl rounded-br-xl">
        <v-container>
          <v-row align="center">
            <v-col sm="4" align="center">
              <v-img
                v-if="
                  loggedInRole == 'SCHOOL ADMIN' ||
                  loggedInRole == 'SUPER ADMIN' ||
                  loggedInRole == 'MANAGER'
                "
                contain
                :src="require('../../assets/plms/manager.jpg')"
              ></v-img>
              <v-img
                contain
                v-if="loggedInRole == 'COORDINATOR'"
                :src="require('../../assets/plms/coordinator.jpg')"
              ></v-img>
              <v-img
                contain
                v-if="loggedInRole == 'TSI'"
                :src="require('../../assets/plms/tsi.jpg')"
              ></v-img>
              <v-img
                v-if="loggedInRole == 'LMS TEAM'"
                contain
                :src="require('../../assets/images/infakt_teacher_login.png')"
              ></v-img>
            </v-col>
            <v-col sm="8" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <div
                      class="text-h4 font-weight-bold text-center"
                      v-text="loggedInRole + ' LOGIN'"
                    ></div>
                    <br />
                    <v-card-text>
                      <v-autocomplete
                        v-if="loggedInRole == 'LMS TEAM'"
                        ref="role"
                        v-model="form.role"
                        :items="roleItems"
                        outlined
                        label="Select role"
                        append-icon="mdi-account-tie-hat-outline"
                      ></v-autocomplete>
                      <v-text-field
                        v-model="form.email"
                        outlined
                        label="Email"
                        prepend-inner-icon="mdi-email"
                        @keypress.enter="login"
                        :error-messages="
                          errors.email ? errors.email : error_msg
                        "
                      ></v-text-field>

                      <!-- <v-text-field
                        v-model="form.password"
                        type="password"
                      ></v-text-field> -->
                      <v-text-field
                        v-model="form.password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        outlined
                        @keypress.enter="login"
                        :error-messages="errors.password"
                        label="Password"
                        prepend-inner-icon="mdi-lock"
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                      <loader v-if="isLoading"></loader>
                      <v-btn v-else color="primary" dark @click="login">
                        Login
                      </v-btn>
                      <br />
                      <br />
                      <a
                        @click="
                          (isForgotPassword = true), (show_role_login = false)
                        "
                        >Forgot Password?</a
                      >
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Forgot Password Dialog -->
    <v-dialog
      v-model="isForgotPassword"
      width="380"
      class="rounded-tl-xl rounded-br-xl"
    >
      <v-card class="rounded-tl-xl rounded-br-xl">
        <v-card-title class="justify-center primary accent-1 white--text"
          >Kindly enter your email
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model="form.email"
                outlined
                hide-details="auto"
                label="Email"
                class="mt-4"
                prepend-inner-icon="mdi-email"
                @keypress.enter="SendForgotPasswordEmail(form.email)"
                :error-messages="userData ? '' : error_msg"
              ></v-text-field>
            </v-col>
            <v-col align="center">
              <v-btn
                color="primary"
                :loading="sending_mail"
                :disabled="sending_mail"
                @click="SendForgotPasswordEmail(form.email)"
              >
                Send Mail</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Forgot Password Dialog -->
    <!-- Mail Sent Success Dialog Box -->
    <v-dialog v-model="is_Mail_Sent" width="600">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: green; font-size: 90px; width: 200px !important"
              >
                mdi-cloud-upload-outline
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">Hurray!</h1>
                    <br />
                    <h3>
                      The mail has been sent successfully. Now you can check
                      your mail. The mail has the Reset Password link.
                    </h3>
                    <br />
                    <v-btn color="success" @click="is_Mail_Sent = false">
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- End Mail Success Dialog -->
    <!-- Warning for Timed Assignment -->
    <v-dialog v-model="ShowAnotherLoginWarning" max-width="500px" persistent>
      <v-card elevation="10" align="center">
        <v-card-title class="justify-center error accent-1 white--text">
          Warning
        </v-card-title>
        <v-card-text>
          <p class="my-5 font-weight-black">
            Looks like you are already logged in from another device. Click
            "Yes" to logout from all other devices.
          </p>
          <v-btn
            color="primary"
            @click.stop="(form.is_clear_api_token = true), login()"
          >
            Yes
          </v-btn>
          &nbsp;
          <v-btn dark color="red" @click.stop="ShowAnotherLoginWarning = false">
            No
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- isResetPasswordLoading Success Dialog Box -->
    <v-dialog v-model="isResetPasswordLoading" width="600" persistent>
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <loader v-if="isResetPasswordLoading"></loader>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h3>Redirecting to reset Password page ...</h3>
                    <br />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- End isResetPasswordLoading Success Dialog -->
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import loader from "@/components/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      showPassword: false,
      show_role_login: false,
      isForgotPassword: false,
      isResetPasswordLoading: false,
      sending_mail: false,
      is_Mail_Sent: false,
      ShowAnotherLoginWarning: false,
      loggedInRole: "",
      form: {
        source: "PC",
        email: "",
        password: "",
        role: "",
      },
      roleItems: [
        { text: "DTP", value: "INFAKT TEACHER" },
        { text: "DTP HEAD", value: "ACADEMIC TEAM" },
        { text: "TICKET ADMINISTRATOR", value: "TICKET ADMINISTRATOR" },
        { text: "IT SUPPORT", value: "IT SUPPORT" },
        { text: "SUPER ADMIN", value: "SUPER ADMIN" },
      ],
      isLoading: false,
      items: [
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
        },
      ],
      userData: {},
      error_msg: "",
    };
  },
  mounted() {
    if (this.$route.query.email) {
      this.isResetPasswordLoading = true;
      this.form.email = this.$route.query.email;
      this.form.isResetPassword = this.$route.query.isResetPassword;
      this.login();
    }
  },
  methods: {
    ...mapActions({
      logIn: "auth/logIn",
    }),
    async ShowLogin(role) {
      if (role) {
        this.loggedInRole = role;
        if (role == "SCHOOL ADMIN") this.loggedInRole = "MANAGER";
        if (role == "STUDENT") this.loggedInRole = "TSI";
        if (role == "TEACHER") this.loggedInRole = "COORDINATOR";
        if (role == "SCHOOL ADMIN") {
          this.form.role = "ADMIN";
        } else {
          this.form.role = role;
        }
        this.show_role_login = true;
      }
    },
    async login() {
      this.form.source = "PC";
      this.ShowAnotherLoginWarning = false;
      this.isLoading = true;
      // await this.logIn(this.form)
      // location.reload();
      // this.isLoading = false;
      this.logIn(this.form)
        .then(() => {
          if (this.authenticated == false) {
            this.ShowAnotherLoginWarning = true;
            this.error_msg =
              "check if you have logged out from all the devices.";
          } else {
            location.reload();
          }
          this.isLoading = false;
        })
        .catch(() => {
          if (this.authenticated == false) {
            this.error_msg = "Please select the correct role";
          }
          this.isLoading = false;
        });
    },
    async SendForgotPasswordEmail(email) {
      let query = "email=" + email;
      this.error_msg = "";
      let userData = await axios.post(`/search_user?${query}`);
      this.userData = userData.data.data;
      this.error_msg = userData.data.message;
      if (this.userData) {
        this.sending_mail = true;
        let is_mail_sent = [];
        if (this.userData.email) {
          let query = "user_id=" + this.userData.id;
          is_mail_sent = await axios.post(
            `/send_forgot_password_mail?${query}`
          );
        }
        if (is_mail_sent) {
          this.sending_mail = false;
          this.is_Mail_Sent = true;
        }
      }
    },
  },
};
</script>
