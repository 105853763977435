<template>
  <v-main>
    <v-container
      ><!-- Recently Onboarded Classes -->
      <br />
      <v-row justify="start">
        <v-col style="min-width: 150px">
          <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            :to="`/standards/${this.$route.params.standardId}/sections`"
          >
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          <span class="text-h5 font-weight-bold" v-text="title"></span>
          <addbutton
            :link="`/standards/${standard.id}/sections/${section.id}/classes/create`"
          ></addbutton>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <v-autocomplete
            ref="classcode"
            v-model="form.classcode_id"
            :items="classcodeItems"
            label="Designation"
            prepend-icon="mdi-application-edit"
          ></v-autocomplete>
        </v-col>
        <!-- <v-col sm="3"> </v-col> -->
        <v-spacer></v-spacer>
        <v-col sm="1" class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
        <v-col sm="2" class="mt-3">
          <v-btn
            class="ml-9 my-auto"
            fab
            x-small
            :color="is_grid == true ? 'primary' : ''"
            @click="is_grid = true"
          >
            <v-icon dark> mdi-grid </v-icon>
          </v-btn>
          |
          <v-btn
            class="my-auto"
            fab
            x-small
            :color="is_grid == false ? 'primary' : ''"
            @click="is_grid = false"
          >
            <v-icon> mdi-format-list-numbered </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <div v-if="is_grid == true">
        <v-row>
          <v-col sm="3" v-for="c in classcodes" :key="c.name">
            <v-card shaped>
              <v-col>
                <v-img
                  height="200"
                  contain
                  :src="require('@/assets/imgs/classroom.png')"
                ></v-img>
              </v-col>

              <!-- :src="require('@/assets/imgs/class_default_photo.jpeg')" -->
              <v-card-title class="justify-center wrap"
                >{{ c.subject_name }}
              </v-card-title>

              <v-card-text>
                <!-- <v-col style="text-align: center" class="wrap">
                  {{ c.classcode }}
                  
                </v-col> -->
                <v-col style="text-align: center" class="wrap">
                  <classcode-element
                    :classcode="c.classcode"
                    :type="
                      roleName == 'STUDENT'
                        ? 'Designation'
                        : roleName == 'TEACHER'
                        ? 'STANDARD_SUBJECT'
                        : ''
                    "
                  ></classcode-element>
                </v-col>
                <v-row>
                  <v-col md="6" class="wrap"
                    ><router-link
                      :to="`/standards/${standard.id}/sections/${section.id}/classes/${c.id}/view-teachers`"
                      style="text-decoration: none"
                      >View Coordinators</router-link
                    ></v-col
                  >
                  <v-col md="6" class="wrap"
                    ><router-link
                      :to="`/standards/${standard.id}/sections/${section.id}/classes/${c.id}/view-students`"
                      style="text-decoration: none"
                      >View TSIs</router-link
                    ></v-col
                  >
                </v-row>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                <v-chip-group
                  active-class="deep-purple accent-4 white--text"
                  column
                >
                  <v-chip>{{
                    c.is_optional ? "Optional" : "Mandatory"
                  }}</v-chip>
                  <v-btn
                    class="my-auto"
                    fab
                    x-small
                    color="primary"
                    :to="`/standards/${standard.id}/sections/${section.id}/classes/${c.id}`"
                  >
                    <v-icon dark> mdi-pencil </v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        dark
                        :color="`${c.is_active ? 'green' : 'red'}`"
                      >
                        mdi-circle
                      </v-icon>
                    </template>
                    <span>{{ c.is_active ? "Active" : "Inactive" }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 56px">Sr No</th>
                    <th class="text-left" style="min-width: 100px">
                      Subject Name
                    </th>
                    <th class="text-left" style="min-width: 80px">Is Active</th>
                    <th class="text-left" style="min-width: 100px">
                      Optional / Mandatory
                    </th>
                    <th class="text-right" style="min-width: 70px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(classcode, i) in classcodes"
                    :key="classcode.subject_name"
                  >
                    <td>{{ i + 1 }}</td>

                    <td>{{ classcode.subject_name }}</td>
                    <td>{{ classcode.is_active ? "YES" : "NO" }}</td>
                    <td>
                      {{ classcode.is_optional ? "Optional" : "Mandatory" }}
                    </td>
                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/standards/${standard.id}/sections/${section.id}/classes/${classcode.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";

export default {
  components: { addbutton, Loader },
  data() {
    return {
      form: {
        classcode_id: "",
      },
      classcodes: [],
      classcodeItems: [
        {
          id: 0,
          text: "Select Class Code",
          value: 0,
        },
      ],
      section: {},
      standard: {},
      title: "",
      isLoading: false,
      is_grid: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    UIClasscode(classcode) {
      // let classcode=classcode;
      let classcode_array = classcode.split("/");
      // let standard_section = classcode_array[1];
      return classcode_array;
    },
    async getData() {
      this.isLoading = true;
      this.form.classcode_id = this.form.classcode_id
        ? this.form.classcode_id
        : "";
      let section = await axios.get(`sections/${this.$route.params.sectionId}`);
      this.section = section.data.data;
      this.standard = this.section.standard;

      let classcodes = [];
      let query = "classcode_id=" + this.form.classcode_id;
      classcodes = await axios.get(
        `/sections/${this.$route.params.sectionId}/classcodes?${query}`
      );
      this.classcodes = classcodes.data.data;
      this.classcodes.forEach((classcode) => {
        this.classcodeItems.push({
          id: classcode.id,
          text: classcode.subject_name,
          value: classcode.id,
        });
      });
      this.count = classcodes.data.count;

      this.title =
        "Designations of " + this.standard.name + " - " + this.section.name;
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
