<template>
  <v-app
    ref="app"
    :style="{ background: $vuetify.theme.themes.light.background }"
  >
    <router-view />
    <template
      v-if="
        UserRole &&
        (UserRole == 'TEACHER' || UserRole == 'STUDENT' || UserRole == 'ADMIN')
      "
    >
      <TicketButton />
    </template>

    <!-- <v-footer app bottom absolute padless class="justify-center" color="#292929" height="100">
      <div
        class="title font-weight-light grey--text text--lighten-1 text-center"
      >
        &copy; {{ new Date().getFullYear() }} — Infakt — Made with 💜 by
        Infakt Team
      </div>
    </v-footer> -->
  </v-app>
</template>

<script>
import TicketButton from "@/components/ticket.vue";

export default {
  name: "App",
  components: {
    TicketButton,
  },
  computed: {
    UserRole() {
      let currentUserRole = false;
      let user = this.$store.getters["auth/user"];
      if (user && user.roles && user.roles.length > 0) {
        // Check the user's role and return true if it matches
        currentUserRole = user.roles[0];
      }
      if (currentUserRole) {
        return currentUserRole.name;
      } else {
        return currentUserRole;
      }
    },
  },
};
</script>

<style>
.make_transparent {
  background-color: rgba(41, 40, 40, 0) !important;
}
.primaryColor {
  color: #0023f5 !important;
}
.secondaryColor {
  color: #e88774;
}
div {
  word-break: break-word !important;
}
.routerLink {
  text-decoration: none;
  cursor: pointer !important ;
}
.sr_no_class {
  min-width: 65px;
}
.created_on_date {
  min-width: 110px;
}
.td_content_type {
  min-width: 130px;
}
.td_assignment_type {
  min-width: 130px;
}
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.wrap_2 {
  height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  white-space: normal;
}
.color1Class {
  background-color: #fec085;
}
.color2Class {
  background-color: #82ffb4;
}
.color3Class {
  background-color: #81acfe;
}
.color4Class {
  background-color: #ffeb81;
}
.v-divider {
  border-width: 2px !important;
  border-color: black !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
@font-face {
  font-family: "Fredoka One";
  src: url("../src/assets/fonts/FredokaOne-Regular.ttf");
}
@font-face {
  font-family: "SF PRO";
  src: url("../src/assets/fonts/SFPRODISPLAYREGULAR.ttf");
}
@media (min-width: 1270px) {
  /* 1270 & Above */
  .midScreen {
    display: none;
  }
  .smallScreen {
    display: none;
  }
}
@media (min-width: 800px) and (max-width: 1270px) {
  /* 800 --  1270*/
  /* 1200 --  1300*/
  .largeScreen {
    display: none;
  }
  .smallScreen {
    display: none;
  }
}
@media (max-width: 800px) {
  /* 800 & less*/
  .largeScreen {
    display: none;
  }
  .midScreen {
    display: none;
  }
}
</style>
