<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="`Manual Details of ${manual.title}`"
          ></span>
          <addbutton
            :link="`/manuals/${$route.params.manualId}/manual_details/create`"
          ></addbutton>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4"> </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>

      <div v-else>
        <!-- List View -->
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 65px">Sr No</th>
                    <th class="text-left" style="min-width: 100px">Title</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(manual_detail, i) in manual_details"
                    :key="manual_detail.name"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>{{ manual_detail.detail_title }}</td>
                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        dark
                        color="red"
                        @click="DeleteIt(manual_detail.id)"
                      >
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/manuals/${manual_detail.manual_id}/manual_details/${manual_detail.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";

export default {
  components: { addbutton, Loader },
  data() {
    return {
      isLoading: false,
      isDeleting: false,
      manual_details: [],
      manual: {},
      count: null,
      selectedRole: "ADMIN",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let manual = await axios.get(`/manuals/${this.$route.params.manualId}`);
      this.manual = manual.data.data;
      let manual_details = await axios.get(
        `/manuals/${this.$route.params.manualId}/manual_details`
      );
      this.manual_details = manual_details.data.data;
      this.count = manual_details.data.count;
      this.isLoading = false;
    },
    async DeleteIt(manualDetailId) {
      try {
        this.isDeleting = true;
        await axios.delete(`/manual_details/${manualDetailId}`);
        this.isDeleting = false;
        this.getData();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
