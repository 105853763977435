<template>
  <v-row>
    <v-col sm="7">
      <div class="text-h5 font-weight-bold pb-5" style="min-width: 130px">
        Power Words
      </div>
      <v-simple-table fixed-header height="277px">
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="text-left sr_no_class"
                style="background-color: yellow"
              >
                Sr No
              </th>
              <th
                class="text-center"
                style="background-color: yellow; max-width: 65px"
              >
                Word
              </th>
              <th
                class="text-center"
                style="
                  background-color: yellow;
                  min-width: 250px;
                  max-width: 250px;
                "
              >
                Meaning
              </th>
              <th
                class="text-center"
                style="background-color: yellow; min-width: 70px"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(word, w) in dictionaries" :key="`word_${w}`">
              <td class="text-left">{{ w + 1 }}</td>
              <td
                class="text-center"
                style="min-width: 130px; max-width: 130px"
              >
                {{ word.name }}
              </td>
              <td
                class="text-center"
                style="min-width: 350px; max-width: 350px"
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ word.meaning }}
                    </span>
                  </template>
                  <span>{{ word.meaning }}</span>
                </v-tooltip>
              </td>
              <td class="text-center" style="max-width: 65px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      target="_blank"
                      size="25"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      :to="`${
                        word.content_description_id
                          ? '/articles/' +
                            word.content_id +
                            '/' +
                            word.content_description_id
                          : '/articles/' + word.content_id
                      }`"
                    >
                      <v-icon> mdi-eye-outline </v-icon>
                    </v-btn>
                  </template>
                  <span> VIEW {{ word?.content_type }} </span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <!-- <v-data-table
        dense
        :headers="headers"
        :items="dictionaries"
        :items-per-page="-1"
        item-key="sr_no"
        class="elevation-10"
        fixed-header
        height="275px"
        hide-default-footer
      >
        <template v-slot:item.meaning="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ item.meaning | limit70 }}
              </span>
            </template>
            <span>{{ item.meaning }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                target="_blank"
                size="25"
                v-bind="attrs"
                v-on="on"
                icon
                color="primary"
                :to="`${
                  item.content_description_id
                    ? '/articles/' +
                      item.content_id +
                      '/' +
                      item.content_description_id
                    : '/articles/' + item.content_id
                }`"
              >
                <v-icon> mdi-eye-outline </v-icon>
              </v-btn>
            </template>
            <span> VIEW {{ item?.content_type }} </span>
          </v-tooltip>
        </template>
      </v-data-table> -->
    </v-col>
    <v-col sm="5">
      <div class="text-h5 font-weight-bold pb-5" style="min-width: 150px">
        Annotations / Highlights
      </div>
      <v-row>
        <div
          v-for="(overview, ae) in metadataOverview.filter(
            (mto) => mto.name != 'DICTIONARY'
          )"
          :key="`overview${ae}`"
          class="col-sm-6"
        >
          <v-card :color="'purple darken-1'" dark style="overflow: auto">
            <div class="flex-no-wrap justify-space-between">
              <v-card-title
                class="text-h5"
                v-text="overview.count"
              ></v-card-title>

              <v-card-subtitle
                style="min-width: 120px"
                v-text="overview.name"
              ></v-card-subtitle>

              <v-card-actions>
                <v-dialog v-model="isDialogs[ae]" max-width="1200px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-2 mt-5"
                      outlined
                      rounded
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      View List
                    </v-btn>
                  </template>

                  <v-card elevation="10">
                    <v-card-title> {{ overview.name }} </v-card-title>
                    <v-card-text>
                      <v-simple-table fixed-header>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                class="text-left pr-0 pl-3"
                                style="min-width: 62px"
                              >
                                Sr no
                              </th>
                              <th class="text-left" style="min-width: 62px">
                                Date
                              </th>
                              <th class="text-left">Title</th>
                              <th class="text-left">Keyword</th>
                              <th class="text-left">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(metaData, mD) in overview.values"
                              :key="`assignment_${mD}`"
                            >
                              <td>{{ mD + 1 }}</td>
                              <td style="min-width: 110px">
                                {{ metaData.created_at | ddmmyyyy }}
                              </td>
                              <td style="min-width: 150px">
                                {{ metaData.content?.content_name | limit }}
                              </td>
                              <td style="min-width: 150px">
                                {{ metaData.selected_text }}
                              </td>
                              <td style="min-width: 140px">
                                <!-- <router-link
                                  target="_blank"
                                  :to="`/articles/${metaData.content_id}`"
                                >
                                  VIEW {{ metaData.content?.content_type }}
                                </router-link> -->
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      target="_blank"
                                      size="25"
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                      color="primary"
                                      :to="`${
                                        metaData.content_description_id
                                          ? '/articles/' +
                                            metaData.content_id +
                                            '/' +
                                            metaData.content_description_id
                                          : '/articles/' + metaData.content_id
                                      }`"
                                    >
                                      <v-icon> mdi-eye-outline </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    View {{ metaData.content?.content_type }}
                                  </span>
                                </v-tooltip>
                                <v-tooltip
                                  bottom
                                  v-if="metaData.postedBy != 'Teacher'"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                      class="my-auto"
                                      color="error"
                                      @click="Delete_this(metaData.id)"
                                    >
                                      <v-icon dark size="25">
                                        mdi-delete
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Delete this {{ overview.name }}</span>
                                </v-tooltip>
                                <!-- Delete Bookmark Success Dialog Box -->
                                <v-dialog
                                  v-model="is_deleted_metadata"
                                  width="600"
                                >
                                  <v-card>
                                    <v-container>
                                      <v-row align="center">
                                        <v-col sm="3" align="center">
                                          <v-icon
                                            large
                                            style="
                                              color: green;
                                              font-size: 90px;
                                              width: 200px !important;
                                            "
                                          >
                                            mdi-delete-empty
                                          </v-icon>
                                        </v-col>
                                        <v-col sm="9" align="center">
                                          <v-card-text>
                                            <v-row>
                                              <v-col>
                                                <h1 style="color: green">
                                                  Hurray!
                                                </h1>
                                                <br />
                                                <h3>
                                                  Removed
                                                  {{ overview.name }}
                                                  successfully.
                                                </h3>
                                                <br />
                                                <v-btn
                                                  color="success"
                                                  @click="
                                                    (is_deleted_metadata = false),
                                                      $router.go(0)
                                                  "
                                                >
                                                  OK
                                                </v-btn>
                                              </v-col>
                                            </v-row>
                                          </v-card-text>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card>
                                </v-dialog>
                                <v-icon
                                  color="green"
                                  v-if="metaData.postedBy == 'Teacher'"
                                >
                                  mdi-human-male-board
                                </v-icon>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-card-actions>

              <v-avatar size="125" tile>
                <v-icon class="imgStyle" large dark>
                  {{
                    overview.name == "HIGHLIGHT"
                      ? "mdi-marker"
                      : "mdi-comment-text-outline"
                  }}</v-icon
                >
              </v-avatar>
            </div>
          </v-card>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      isDialogs: [],
      dictionaries: [],
      headers: [
        {
          text: "Sr No",
          value: "sr_no",
          align: "center",
          width: "40px",
          sortable: false,
          class: "yellow",
        },
        // {
        //   text: "Date",
        //   value: "date",
        //   width: "105px",
        //   align: "center",
        //   class: "yellow",
        // },
        {
          text: "Word",
          align: "center",
          width: "200px",
          sortable: false,
          value: "name",
          class: "yellow",
        },
        {
          text: "Meaning",
          value: "meaning",
          width: "200px",
          align: "center",
          sortable: false,
          class: "yellow",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: "75px",
          sortable: false,
          class: "yellow",
        },
      ],
      delete_metadata: false,
      is_deleted_metadata: false,
    };
  },
  props: ["metadataOverview"],
  watch: {
    metadataOverview: "getDictionaries",
  },
  mounted() {
    this.getDictionaries();
  },
  methods: {
    async Delete_this(id) {
      this.delete_metadata = true;
      let is_deleted = await axios.delete(`/content_metadatas/${id}`);
      if (is_deleted) {
        this.delete_metadata = false;
        this.is_deleted_metadata = true;
      }
    },
    getDictionaries() {
      this.dictionaries = [];
      // if (this.metadataOverview.length) {
      let overview = this.metadataOverview.find(
        (mto) => mto.name == "DICTIONARY"
      ).values;
      overview.forEach((mDO, i) => {
        this.dictionaries.push({
          sr_no: i + 1,
          id: mDO.id ? mDO.id : "",
          content_id: mDO.content_id ? mDO.content_id : "",
          content_type: mDO.content.content_type
            ? mDO.content.content_type
            : "",
          content_description_id: mDO.content_description_id
            ? mDO.content_description_id
            : "",
          date: moment(mDO.created_at).format("DD-MM-YYYY"),
          name: mDO.selected_text ? mDO.selected_text : "",
          meaning: mDO.meaning ? mDO.meaning : "",
        });
      });
    },
    // },
  },
};
</script>
