<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 105px">
          <span class="text-h5 font-weight-bold" v-text="'Blog Details'"></span>
          <addbutton
            :link="`/blogs/${blog.id}/blog-details/create`"
          ></addbutton>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left sr_no_class">Sr No</th>
                    <th class="text-left" style="min-width: 57px">Title</th>
                    <th class="text-left" style="min-width: 80px">
                      Description
                    </th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(blog_detail, i) in blog_details"
                    :key="`blog_detail_${i}`"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>{{ blog_detail.title }}</td>
                    <td v-html="blog_detail.description"></td>
                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/blogs/${blog.id}/blog-details/${blog_detail.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "BlogsPage",
  data() {
    return {
      blog_details: [],
      blog: {},
      isLoading: false,
    };
  },
  components: {
    addbutton,
    Loader,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let blog = await axios.get(`/blogs/${this.$route.params.blogId}`);
      this.blog = blog.data.data;
      let query = "blog_id=" + this.blog.id;
      let blog_details = await axios.get(`blog_details?${query}`);
      this.blog_details = blog_details.data.data;
      this.isLoading = false;
    },
  },
};
</script>
