<template>
  <v-main>
    <v-container
      ><!-- Recently Onboarded Notifications -->
      <br />
      <v-row justify="start">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Notifications'"
          ></span>
          &nbsp;
          <v-tooltip bottom v-if="notifications.length">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                x-small
                class="my-auto ml-2"
                color="error"
                @click="Delete_this()"
                :loading="delete_notification"
                :disabled="delete_notification"
              >
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Clear All Notification</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card :isLoading="isLoading" elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 85px">Sr No</th>
                    <th class="text-left" style="min-width: 250px">
                      Description
                    </th>
                    <th class="text-left" style="min-width: 135px">Date</th>
                    <th class="text-left" style="min-width: 75px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(notification, i) in notifications"
                    :key="notification.name"
                  >
                    <template v-if="notification.redirect_to">
                      <router-link
                        class="routerLink"
                        :to="`${notification.redirect_to}`"
                        tag="td"
                      >
                        <v-tooltip bottom v-if="notification.is_read == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              dark
                              color="green"
                            >
                              mdi-circle
                            </v-icon>
                          </template>
                          <span>Notification already seen.</span>
                        </v-tooltip>
                        &nbsp;
                        {{ i + 1 }}
                      </router-link>
                      <router-link
                        class="routerLink"
                        :to="`${notification.redirect_to}`"
                        tag="td"
                      >
                        <span v-html="notification.description"></span>
                      </router-link>
                      <router-link
                        class="routerLink"
                        :to="`${notification.redirect_to}`"
                        tag="td"
                        >{{ notification.created_at | in_days }}</router-link
                      >
                    </template>
                    <template v-else>
                      <td>
                        <v-tooltip bottom v-if="notification.is_read == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              dark
                              color="green"
                            >
                              mdi-circle
                            </v-icon>
                          </template>
                          <span>Notification already seen.</span>
                        </v-tooltip>
                        &nbsp;
                        {{ i + 1 }}
                      </td>
                      <td>
                        <span v-html="notification.description"></span>
                      </td>
                      <td>
                        {{ notification.created_at | in_days }}
                      </td>
                    </template>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            fab
                            x-small
                            class="my-auto"
                            color="error"
                            @click="Delete_this(notification.id)"
                          >
                            <v-icon dark> mdi-delete </v-icon>
                          </v-btn>
                        </template>
                        <span>Delete this Notification</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_notification" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Notification cleared successfully.</h3>
                      <br />
                      <v-btn
                        color="success"
                        @click="is_deleted_notification = false"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  data() {
    return {
      notifications: [],
      delete_notification: false,
      is_deleted_notification: false,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.UpdateRead();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let notifications = await axios.get(
        `notifications?page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.notifications = notifications.data.data;
      this.count = notifications.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async Delete_this(id) {
      this.delete_notification = true;
      let is_deleted = [];
      if (id) {
        let query = "id=" + id;
        is_deleted = await axios.post(`/notifications/clear?${query}`);
      } else {
        let query = "user_id=" + this.user.id;
        is_deleted = await axios.post(`/notifications/clear_all?${query}`);
      }
      if (is_deleted) {
        this.delete_notification = false;
        this.is_deleted_notification = true;
        this.getData();
      }
    },
    async UpdateRead() {
      let query = "user_id=" + this.user.id;
      await axios.post(`/notifications/mark_all_read?${query}`);
    },
  },
};
</script>
