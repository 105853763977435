<template>
  <v-main style="font-family: Montserrat">
    <v-container fluid>
      <v-row class="pt-4 pl-2" style="border-bottom: inset 1px">
        <v-col class="pa-3"
          ><h2>
            <v-btn icon large> <v-icon>mdi-chevron-left</v-icon> </v-btn> Add
            Content
          </h2></v-col
        >
      </v-row>
      <v-row>
        <loader v-if="isLoading"></loader>
        <v-card elevation="6">
          <v-card-title> Add assignment </v-card-title>
          <v-card-text>
            <section class="smallScreen">
              <v-slide-group
                v-model="form.assignment_type"
                mandatory
                center-active
                class="mb-5"
              >
                <v-slide-item
                  v-slot="{ active, toggle }"
                  v-for="assignmentType in assignmentTypes"
                  :key="assignmentType"
                  :value="assignmentType"
                >
                  <v-card
                    style="border-radius: 20px"
                    :color="active ? '#F2F5FA' : 'white'"
                    class="black--text mx-4"
                    height="102"
                    width="295"
                    @click="toggle"
                  >
                    <v-container>
                      <v-card-text class="pa-0">
                        <v-row>
                          <v-col md="9" class="pb-0"
                            ><h3>{{ assignmentType }}</h3>
                          </v-col>
                          <v-col md="3" class="pb-0" align="right">
                            <v-btn icon dark>
                              <v-icon color="#7F56D9" v-if="active">
                                mdi-radiobox-marked
                              </v-icon>
                              <v-icon color="black" v-else>
                                {{
                                  active
                                    ? "mdi-radiobox-marked"
                                    : "mdi-radiobox-blank"
                                }}
                              </v-icon>
                            </v-btn></v-col
                          >
                        </v-row>
                        <v-row class="mt-0">
                          <v-col class="wrap_2"
                            >Documents and attachments that have been uploaded
                            as part Documents and attachments that have been
                            uploaded as part.</v-col
                          >
                        </v-row>
                      </v-card-text>
                    </v-container>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </section>
            <span class="midScreen">
              <v-item-group v-model="form.assignment_type" mandatory>
                <v-row>
                  <v-col
                    sm="12"
                    md="3"
                    lg="3"
                    v-for="assignmentType in assignmentTypes"
                    :key="assignmentType"
                    :value="assignmentType"
                  >
                    <v-item v-slot="{ active, toggle }" :value="assignmentType">
                      <v-card
                        style="border-radius: 20px"
                        :color="active ? '#F2F5FA' : 'white'"
                        class="black--text"
                        height="102"
                        @click="toggle"
                      >
                        <v-container>
                          <v-card-text class="pa-0">
                            <v-row>
                              <v-col md="9" class="pb-0"
                                ><h3>{{ assignmentType }}</h3>
                              </v-col>
                              <v-col md="3" class="pb-0" align="right">
                                <v-btn icon dark>
                                  <v-icon color="#7F56D9" v-if="active">
                                    mdi-radiobox-marked
                                  </v-icon>
                                  <v-icon color="black" v-else>
                                    {{
                                      active
                                        ? "mdi-radiobox-marked"
                                        : "mdi-radiobox-blank"
                                    }}
                                  </v-icon>
                                </v-btn></v-col
                              >
                            </v-row>
                            <v-row class="mt-0">
                              <v-col class="wrap_2"
                                >Documents and attachments that have been
                                uploaded as part Documents and attachments that
                                have been uploaded as part.</v-col
                              >
                            </v-row>
                          </v-card-text>
                        </v-container>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </span>
            <span class="largeScreen">
              <v-item-group v-model="form.assignment_type" mandatory>
                <v-row>
                  <v-col
                    sm="12"
                    md="3"
                    lg="3"
                    v-for="assignmentType in assignmentTypes"
                    :key="assignmentType"
                    :value="assignmentType"
                  >
                    <v-item v-slot="{ active, toggle }" :value="assignmentType">
                      <v-card
                        style="border-radius: 20px"
                        :color="active ? '#F2F5FA' : 'white'"
                        class="black--text"
                        height="102"
                        @click="toggle"
                      >
                        <v-container>
                          <v-card-text class="pa-0">
                            <v-row>
                              <v-col md="9" class="pb-0"
                                ><h3>{{ assignmentType }}</h3>
                              </v-col>
                              <v-col md="3" class="pb-0" align="right">
                                <v-btn icon dark>
                                  <v-icon color="#7F56D9" v-if="active">
                                    mdi-radiobox-marked
                                  </v-icon>
                                  <v-icon color="black" v-else>
                                    {{
                                      active
                                        ? "mdi-radiobox-marked"
                                        : "mdi-radiobox-blank"
                                    }}
                                  </v-icon>
                                </v-btn></v-col
                              >
                            </v-row>
                            <v-row class="mt-0">
                              <v-col class="wrap_2"
                                >Documents and attachments that have been
                                uploaded as part Documents and attachments that
                                have been uploaded as part.</v-col
                              >
                            </v-row>
                          </v-card-text>
                        </v-container>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </span>
            <v-row class="pt-5">
              <v-col sm="6" md="6" lg="6" class="py-0" style="min-width: 250">
                <p class="text-subtitle-2 label">Assignment Title</p>
                <v-text-field
                  outlined
                  ref="assignment_title"
                  id="assignment_title"
                  tabindex="0"
                  dense
                  placeholder="Enter assignment title"
                  v-model="form.assignment_title"
                  :error-messages="errors.assignment_title"
                ></v-text-field>
              </v-col>
              <v-col sm="6" md="3" lg="3" class="py-0" style="min-width: 250">
                <!-- <p class="text-subtitle-2 label"></p>
              <v-switch v-model="form.is_modified"></v-switch> -->
                <section class="switch-container align-self-center">
                  <p class="label text-subtitle-2 mr-2">
                    &nbsp; Timed assignment
                  </p>
                  <v-switch
                    hide-details
                    class="mt-0 pa-0"
                    color="success"
                  ></v-switch>
                </section>
                <v-text-field
                  v-if="
                    roleName != 'INFAKT TEACHER' &&
                    roleName != 'ACADEMIC TEAM' &&
                    form.assignment_type != 'DOCUMENT'
                  "
                  type="number"
                  ref="duration"
                  id="duration"
                  outlined
                  dense
                  v-model="form.duration"
                  placeholder="Enter time in mins"
                  messages="This will make it time based assignment"
                  :error-messages="errors.duration"
                ></v-text-field>
                <v-text-field
                  v-else
                  disabled
                  outlined
                  ref="duration"
                  id="duration"
                  dense
                  placeholder="Enter time in mins"
                  v-model="form.duration"
                  messages="This will make it time based assignment"
                ></v-text-field>
              </v-col>
              <v-col sm="6" md="3" lg="3" class="py-0" style="min-width: 250">
                <p class="text-subtitle-2 label">Maximum marks</p>
                <v-text-field
                  v-if="form.assignment_type == 'DOCUMENT'"
                  outlined
                  ref="maximum_marks"
                  id="maximum_marks"
                  dense
                  v-model="form.maximum_marks"
                  placeholder="Maximum marks"
                  messages="Calculated on the basis of each question"
                ></v-text-field>
                <v-text-field
                  v-else
                  disabled
                  outlined
                  ref="maximum_marks"
                  id="maximum_marks"
                  dense
                  v-model="form.maximum_marks"
                  placeholder="Maximum marks"
                  messages="Calculated on the basis of each question"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="text-subtitle-2 label">TSI Instructions</p>
                <froala
                  :tag="'textarea'"
                  :config="config"
                  v-model="form.student_instructions"
                ></froala>
              </v-col>
            </v-row>
            <v-row v-if="form.assignment_type == 'DOCUMENT'">
              <v-col sm="4" class="pb-2" v-if="isModificationEnabled()">
                <input
                  type="file"
                  id="file"
                  name="file"
                  ref="file"
                  accept="application/ms-excel"
                  @change="CheckFile()"
                />
                <br />
                <v-btn
                  v-if="form.hasFile"
                  x-small
                  class="reset_image my-1"
                  color="red"
                  dark
                  @click="($refs.file.value = null), CheckFile()"
                  >Remove</v-btn
                >
                <span v-else x-small class="error--text" dark
                  >This field is required</span
                >
              </v-col>
              <v-col sm="4" class="pb-2">
                <v-tooltip right v-if="form.documentpath">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="mt-2"
                      target="_blank"
                      :href="`${mediaUrl}${form.documentpath}`"
                      >Link of Uploaded Document
                    </v-btn>
                  </template>
                  <span>Link to preview Uploaded Document</span>
                </v-tooltip>
              </v-col>
              <v-col md="12" class="pb-0" v-if="isModificationEnabled()">
                <p class="font-weight-bold">Model Answer</p>
              </v-col>
              <v-col v-if="isModificationEnabled()">
                <froala
                  :tag="'textarea'"
                  :config="config"
                  v-model="form.model_answer"
                ></froala>
              </v-col>
            </v-row>
            <!-- Classcodes -->
            <div ref="classcode_id">
              <AssignmentClasscodes
                class="mt-5"
                :form="form"
                v-if="roleName == 'TEACHER'"
                :classcodes="classcodes"
              ></AssignmentClasscodes>
            </div>
            <!-- End Classcodes -->
            <!-- If Document type Assignment -->
            <v-row v-if="form.assignment_type == 'DOCUMENT'">
              <v-col>
                <v-text-field
                  outlined
                  type="number"
                  id="no_of_questions"
                  ref="no_of_questions"
                  v-model="form.no_of_questions"
                  label="No of Questions"
                  messages="Please mention the total no of questions present in the document. Click 'ENTER' to assign marks against each question"
                  @keyup="CreateQuestions"
                  :error-messages="errors.no_of_questions"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Questions -->
            <AssignmentQuestions
              v-if="!form.is_game"
              :form="form"
              :isModificationEnabled="isModificationEnabled()"
            ></AssignmentQuestions>
            <!-- End Questions -->
            <!-- Is Game -->
            <div v-else>
              <v-text-field
                outlined
                v-model="form.game_link"
                label="Game Link"
              ></v-text-field>
              <v-text-field
                outlined
                v-model="form.game_result_link"
                label="Game Result Link"
              ></v-text-field>
            </div>
            <!-- End Is Game -->
            <br />
            <loader v-if="isSaving"></loader>
            <v-row
              v-else
              class="d-flex justify-sm-center justify-md-end justify-lg-end"
            >
              <template v-if="isDataSaved != true">
                <section class="smallScreen">
                  <v-btn class="ma-2 newButton" outlined @click="save(true)">
                    Save in Draft
                  </v-btn>
                  <v-btn
                    class="ma-2 newButton"
                    color="primary"
                    dark
                    @click="isClasscodeSelected()"
                  >
                    Create assignment
                  </v-btn>
                </section>
                <section class="midScreen">
                  <v-btn class="ma-2 newButton" text x-large> Cancel </v-btn>
                  <v-btn
                    class="ma-2 newButton"
                    outlined
                    x-large
                    @click="save(true)"
                  >
                    Save in Draft
                  </v-btn>
                  <v-btn
                    class="ma-2 newButton"
                    color="primary"
                    dark
                    x-large
                    @click="isClasscodeSelected()"
                  >
                    Create assignment
                  </v-btn>
                </section>
                <section class="largeScreen">
                  <v-btn class="ma-2 newButton" text x-large> Cancel </v-btn>
                  <v-btn
                    class="ma-2 newButton"
                    outlined
                    x-large
                    @click="save(true)"
                  >
                    Save in Draft
                  </v-btn>
                  <v-btn
                    class="ma-2 newButton"
                    color="primary"
                    dark
                    x-large
                    @click="isClasscodeSelected()"
                  >
                    Create assignment
                  </v-btn>
                </section>
              </template>
            </v-row>
            <!-- If no Classcode -->
            <v-dialog
              v-model="show_Missing_classcode_Warning"
              max-width="300px"
              persistent
            >
              <v-card elevation="10" align="center">
                <v-card-title
                  class="justify-center primary accent-1 white--text"
                >
                  No classcode has been selected. You must select atleast one
                  classcode before creating an assignment.
                </v-card-title>
                <br />
                <v-card-text>
                  <v-btn
                    color="primary"
                    @click="
                      focusDiv({ classcode_id: 'requred' }),
                        (show_Missing_classcode_Warning = false)
                    "
                  >
                    OK
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- Warning For Existing Classcode -->
            <v-dialog
              v-model="show_Existing_classcode_Warning"
              max-width="300px"
              persistent
            >
              <v-card elevation="10" align="center">
                <v-card-title
                  class="justify-center primary accent-1 white--text"
                >
                  An assignment with the same article lexile level has been
                  already created for the same classcode. Do you still want to
                  continue ?
                </v-card-title>
                <br />
                <v-card-text>
                  <v-btn
                    color="primary"
                    @click="
                      CheckHiddenLexileConfig(),
                        (show_Existing_classcode_Warning = false)
                    "
                  >
                    Yes
                  </v-btn>
                  &nbsp;
                  <v-btn
                    dark
                    color="red"
                    @click.stop="show_Existing_classcode_Warning = false"
                  >
                    No
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- If Article hidden from any Classcode -->
            <v-dialog
              v-model="show_hidden_classcode_message"
              max-width="410px"
              persistent
            >
              <v-card elevation="10" align="center">
                <v-card-title
                  class="justify-center primary accent-1 white--text"
                >
                  The linked content is hidden for some classcode. Kindly select
                  some other classcode.
                </v-card-title>
                <br />
                <v-card-text>
                  <!-- <v-btn
                  color="primary"
                  @click="
                    CheckLockedLexileConfig(),
                      (show_hidden_classcode_message = false)
                  "
                >
                  Yes
                </v-btn>
                &nbsp; -->
                  <v-btn
                    dark
                    color="primary"
                    @click.stop="
                      focusDiv({ classcode_id: 'requred' }),
                        (show_hidden_classcode_message = false)
                    "
                  >
                    OK
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- If Article Locked with different Lexile for any classcode -->
            <v-dialog
              v-model="show_incorrectly_locked_classcode_message"
              max-width="445px"
              persistent
            >
              <v-card elevation="10" align="center">
                <v-card-title
                  class="justify-center primary accent-1 white--text"
                >
                  Some classcode are locked at different lexile levels. Please
                  select some other lexile level.
                </v-card-title>
                <br />
                <v-card-text>
                  <!-- <v-btn
                  color="primary"
                  @click="
                    warningMessage(),
                      (show_incorrectly_locked_classcode_message = false)
                  "
                >
                  Yes
                </v-btn>
                &nbsp; -->
                  <v-btn
                    dark
                    color="primary"
                    @click.stop="
                      focusDiv({ content_description_id: 'requred' }),
                        (show_incorrectly_locked_classcode_message = false)
                    "
                  >
                    OK
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- Warning for Timed Assignment -->
            <v-dialog v-model="isWarning" max-width="300px" persistent>
              <v-card elevation="10" align="center">
                <v-card-title
                  class="justify-center primary accent-1 white--text"
                >
                  Timed assignment field is empty which makes the Assignment
                  'Not Timed'. Do you still want to continue ?
                </v-card-title>
                <br />
                <v-card-text>
                  <v-btn color="primary" @click.stop="save"> Yes </v-btn>
                  &nbsp;
                  <v-btn
                    dark
                    color="red"
                    @click.stop="
                      focusDiv({ duration: 'requred' }), (isWarning = false)
                    "
                  >
                    No
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- Assignment Saved Successfully Pop Up -->
            <v-dialog
              elevation="0"
              :scrim="false"
              fullscreen
              width="auto"
              v-model="isDataSaved"
            >
              <v-card
                elevation="0"
                tabindex="0"
                align="center"
                height="auto"
                class="make_transparent"
              >
                <v-container
                  class="fill-height d-flex justify-center align-center"
                >
                  <v-card-text class="d-flex justify-center no-select">
                    <v-img
                      v-if="is_Show_loading"
                      :src="require('../../assets/blue-loader.gif')"
                      max-width="86"
                    ></v-img>
                    <v-img
                      v-else
                      :src="require('../../assets/success.png')"
                      max-width="86"
                    ></v-img>
                  </v-card-text>
                </v-container>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import AssignmentClasscodes from "./assignment_classcodes.vue";
import AssignmentQuestions from "./assignment_questions_v2.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "AssignmentCreatePage",
  data() {
    return {
      nowDate: new Date().toISOString().slice(0, 10),
      config: {
        // toolbarInline: true,
        // toolbarVisibleWithoutSelection: true,
        toolbarButtons: {
          moreText: {
            buttons: [
              "insertImage",
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
            ],
          },
        },
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      form: {
        assignment_title: "",
        assignment_type: "",
        hasFile: "",
        category: "ASSIGNMENT",
        created_by_id: "",
        student_instructions: "",
        content_id: "",
        duration: null,
        documentpath: "",
        copied_from_assignment_id: "",
        user_id: "",
        maximum_marks: null,
        is_draft: 0,
        is_deleted: 0,
        is_modified: 0,
        assignment_questions: [],
        assignment_classcodes: [],
      },
      collection: {},
      content: {},
      assignment: {},
      assignmentTypes: ["OBJECTIVE", "SUBJECTIVE", "CUMULATIVE", "DOCUMENT"],
      categoryTypes: ["ASSIGNMENT", "COMPETITION"],
      variants: [],
      classcodes: [],
      articles: [],
      students: [],
      search_article_keyword: "",
      is_game: false,
      is_Show_loading: false,
      isWarning: false,
      show_Existing_classcode_Warning: false,
      show_Missing_classcode_Warning: false,
      show_incorrectly_locked_classcode_message: false,
      show_hidden_classcode_message: false,
      isSaving: false,
      isLoading: false,
      isDataSaved: false,
      isLinkArticleDialog: false,
      isSearching: false,
      isViewArticleDialog: false,
      isStudentsLoading: false,
    };
  },
  components: {
    AssignmentClasscodes,
    AssignmentQuestions,
    Loader,
  },
  mounted() {
    this.getData();
    if (this.roleName == "TEACHER") {
      this.form.company_id = this.company.id;
    }
  },
  // watch: {
  //   form.assignment_type: "Debug",
  // },
  methods: {
    Debug(e = "") {
      console.log("Run Debug");
      console.log(e);
    },
    // Fetch Data
    async getData() {
      this.isLoading = true;
      let articleId = this.$route.query.articleId;
      let collectionId = this.$route.query.collectionId;
      // If Assignment Id is passed
      let assignment;
      if (this.$route.query.copyFromAssignmentId) {
        assignment = await axios.get(
          `/assignments/${this.$route.query.copyFromAssignmentId}`
        );
        this.assignment = assignment.data.data;
        this.assignment.copied_from_assignment_id =
          this.$route.query.copyFromAssignmentId;
        articleId = this.assignment.content_id;
        collectionId = this.assignment.collection_id;
        this.copyFromExistionAssignment(this.assignment);
      }
      // If Article Id is passed
      let content;
      if (articleId) {
        content = await axios.get(`/contents/${articleId}`);
        this.content = content.data.data;
        if (this.content.content_descriptions.length > 0) {
          // If Article has Variants then Add levels of each variant in Variants[]
          this.content.content_descriptions.forEach((description) => {
            this.variants.push({
              text: description.level,
              value: description.id,
            });
          });
        }
        this.form.content_id = articleId;
      }
      // Id Collection Id is passed
      if (collectionId) {
        let collection = await axios.get(`/collections/${collectionId}`);
        this.collection = collection.data.data;
        this.form.collection_id = collectionId;
      }
      // Classcodes
      let classcodes = await axios.get("users/masters");
      classcodes = classcodes.data.classcodes;
      classcodes?.forEach((classcode) => {
        this.classcodes.push({
          id: classcode.id,
          text: classcode.classcode,
          value: classcode.id,
        });
      });
      this.isLoading = false;
    },
    // If Copied assignment then Duplicate the Assignment
    copyFromExistionAssignment(assignment) {
      delete assignment["id"];
      assignment.assignment_classcodes.forEach((ac) => delete ac.id);
      assignment.assignment_extensions.forEach((ae) => delete ae.id);
      assignment.assignment_questions.forEach((aq) => delete aq.id);
      this.form = assignment;
    },
    // Search Article for Linking
    async searchArticles() {
      this.isSearching = true;
      let query = "subject_id=&search_keyword=" + this.search_article_keyword;
      let articles = await axios.get(`/contents?${query}`);
      this.articles = articles.data.data;
      this.isSearching = false;
    },
    // Link a Specific Article
    async linkArticle(article) {
      this.form.content_id = article.id;
      let content = await axios.get(`/contents/${article.id}`);
      this.content = content.data.data;
      if (this.content.content_descriptions.length > 0) {
        // If Article has Variants then Add levels of each variant in Variants[]
        this.content.content_descriptions.forEach((description) => {
          this.variants.push({
            text: description.level,
            value: description.id,
          });
        });
      }
      this.closeLinkArticleDialog();
      this.closeViewArticleDialog();
    },
    // Close Link Article Dialog
    closeLinkArticleDialog() {
      this.isLinkArticleDialog = false;
    },
    // Close View Article Dialog
    closeViewArticleDialog() {
      this.isViewArticleDialog = false;
    },
    // Check where it can be modified
    isModificationEnabled() {
      var response = false;
      if (this.roleName == "TEACHER") {
        // For Teacher
        if (this.$route.query.copyFromAssignmentId) {
          if (this.form.is_modified == true) {
            // If Copied & Enabled Modification
            response = true;
          }
        } else {
          // If New Assignment
          response = true;
        }
      } else {
        // For Infakt Teacher
        response = true;
      }
      return response;
    },
    // Can be Competition
    CanCompetition() {
      var response = false;
      if (this.form.assignment_type == "OBJECTIVE") {
        // For OBJECTIVE
        response = true;
      } else {
        this.form.category = "ASSIGNMENT";
      }
      return response;
    },
    // Create Questions row
    CreateQuestions() {
      this.form.assignment_questions = [];
      for (let index = 0; index < this.form.no_of_questions; index++) {
        let assignmentQuestion = {
          sr_no: this.form.assignment_questions.length + 1,
          assignment_type:
            this.form.assignment_type != "CUMULATIVE"
              ? this.form.assignment_type
              : "",
          description: "",
          model_answer: "",
          correct_options: [],
          marks: "",
          negative_marks: "",
          option1: "",
          option2: "",
          option3: "",
          option4: "",
          assignment_question_options: [],
          assignment_question_correct_options: [],
        };
        this.form.assignment_questions.push(assignmentQuestion);
      }
    },
    // Check Whether atleast one classcode is selected
    isClasscodeSelected() {
      if (
        this.roleName == "TEACHER" &&
        this.form.assignment_classcodes.length == 0
      ) {
        /** If Role Is teacher But No Classcode selected then Show Pop Up */
        this.show_Missing_classcode_Warning = true;
        this.focusDiv({ classcode_id: "required" });
      } else {
        /** Move Forward Check for no of Questions Added */
        console.log("finished Classcode Check");
        console.log("Moved to Questions");
        this.isQuestionAdded();
      }
    },
    // Check Whether File is added
    CheckFile() {
      let check_uploaded_file = false;
      if (this.form.assignment_type == "DOCUMENT") {
        check_uploaded_file = true;
        if (
          this.form.copied_from_assignment_id &&
          this.form.is_modified == false
        ) {
          check_uploaded_file = false;
          this.form.hasFile = "true";
        }
      }
      if (check_uploaded_file) {
        if (this.$refs.file.files.length > 0) {
          this.form.hasFile = this.$refs.file.files.length > 0;
        } else {
          /** If No File selected then Show Pop Up */
          this.form.hasFile = "";
          this.focusDiv({ hasFile: "required" });
        }
      }
    },
    // Check Whether atleast one question is added
    isQuestionAdded() {
      if (this.form.assignment_questions.length == 0) {
        /** If No question selected then Show Pop Up */
        this.focusDiv({ assignment_questions: "required" });
      } else {
        /** Move Forward Check for Existing Assignment */
        console.log("finished Question Check");
        console.log("Moved to Existing Assignment");
        this.CheckExistAssignment();
      }
    },
    // Focus the validation error
    focusDiv(errors) {
      console.log(errors);
      // Use the ref to access the div element and focus it
      let e =
        Object.keys(errors)[0] == "maximum_marks"
          ? Object.keys(errors)[1]
          : Object.keys(errors)[0];
      switch (true) {
        case e == "content_id":
          console.log("Show the Content Linking...");
          this.$refs.linkContentBtn.$el.focus();
          this.scrollToSection("#linkContentBtn");
          break;
        case e == "assignment_type":
          console.log("Show the Assignment Type...");
          this.$refs.assignment_type.focus();
          this.scrollToSection("#assignment_type");
          break;
        case e == "content_description_id":
          console.log("Show the Lexile level...");
          this.$refs.content_description_id.focus();
          this.scrollToSection("#content_description_id");
          break;
        case e == "assignment_title":
          console.log("Show the Title...");
          this.$refs.assignment_title.focus();
          this.scrollToSection("#assignment_title");
          break;
        case e == "duration":
          console.log("Show the Duration...");
          this.$refs.duration.focus();
          this.scrollToSection("#duration");
          break;
        case e == "maximum_marks":
          console.log("Show the Maximium Marks...");
          this.$refs.maximum_marks.focus();
          this.scrollToSection("#maximum_marks");
          break;
        case e == "hasFile":
          console.log("Show the Document Upload option...");
          this.$refs.file.focus();
          this.scrollToSection("#file");
          break;
        case e == "classcode_id":
        case e.includes("assignment_classcodes"):
          console.log("Show the Classcode...");
          if (e.includes("assignment_classcodes")) {
            let srt = e.split(".").join("_");
            console.log("#" + srt);
            this.scrollToSection("#" + srt);
          } else {
            this.scrollToSection("#assignment_classcodes");
          }
          break;
        case e == "assignment_questions":
        case e.includes("assignment_questions"):
          if (e.includes("assignment_questions")) {
            console.log("Show the Marks...");
            let srt = e.split(".").join("_");
            console.log("#" + srt);
            this.scrollToSection("#" + srt);
          } else {
            this.scrollToSection("#assignment_questions");
          }
          break;
        case e == "no_of_questions":
          console.log("Show the no_of_questions...");
          this.$refs.no_of_questions.focus();
          this.scrollToSection("#no_of_questions");
          break;
        default:
          break;
      }
    },
    // Scroll back to the 1st validation error Message
    scrollToSection(selector) {
      // Example: smooth scroll to the specific section
      const element = document.querySelector(selector);
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo({
        top: middle,
        behavior: "smooth",
      });
    },
    /** Check if any assignment exist with the same Article & with the Same Variant*/
    async CheckExistAssignment() {
      let is_applicable = true;
      if (
        this.form.content_id &&
        this.form.content_description_id &&
        this.roleName == "TEACHER"
      ) {
        let query =
          "articleId=" +
          this.form.content_id +
          "&consider_all_classcode_teacher=true&variant_id=" +
          this.form.content_description_id;
        let assignments = await axios.get(`/assignments?${query}`);
        assignments = assignments.data.data;
        let ExistingAssignment = "";
        if (assignments) {
          assignments.forEach((assignment) => {
            assignment.assignment_classcodes.forEach((ac) => {
              if (ExistingAssignment == "") {
                ExistingAssignment = this.form.assignment_classcodes.find(
                  (c) => c.classcode_id == ac.classcode_id
                );
              }
            });
          });
        }
        if (ExistingAssignment) {
          is_applicable = false;
        }
      }

      if (is_applicable == true) {
        /** Move Forward Check for hidden Classcode Exists */
        console.log("finished Existing classcode Check");
        console.log("Moved to Hidden Classcode");
        this.CheckHiddenLexileConfig();
      } else {
        // Show warning Message
        this.show_Existing_classcode_Warning = true;
      }
      return is_applicable;
    },
    /** Check if any of the selected classcode is hidden for the linked content */
    CheckHiddenLexileConfig() {
      let is_hidden_classcode = "";
      if (
        this.form.assignment_classcodes.length &&
        this.content &&
        this.content.content_hidden_classcodes.length
      ) {
        this.form.assignment_classcodes.forEach((classcode) => {
          if (is_hidden_classcode == "") {
            let exist = this.content.content_hidden_classcodes.find(
              (chc) => chc.classcode_id == classcode.classcode_id
            );
            is_hidden_classcode = exist ? exist : "";
          }
        });
      }

      if (is_hidden_classcode) {
        // Show Hidden Classcode Warning Message
        console.log("SHOW HIDDEN MESSAGE");
        this.show_hidden_classcode_message = true;
      } else {
        /** Move Forward Check for Locked Lexile Added */
        console.log("finished Hidden Classcode Check");
        console.log("Moved to Locked Lexile check");
        this.CheckLockedLexileConfig();
      }
    },
    /** Check if any of the selected classcode is locked at any other lexile for the linked content */
    CheckLockedLexileConfig() {
      let is_locked_correct_lexile = true;
      if (
        this.form.content_description_id &&
        this.content &&
        this.content.content_lock_classcodes.length &&
        this.form.assignment_classcodes.length
      ) {
        let selected_lexile = this.variants.find(
          (v) => v.value == this.form.content_description_id
        );
        this.form.assignment_classcodes.forEach((classcode) => {
          let is_locks = this.content.content_lock_classcodes.filter(
            (clc) => clc.classcode_id == classcode.classcode_id
          );
          is_locks.forEach((lc) => {
            if (lc.level != selected_lexile.text) {
              is_locked_correct_lexile = false;
            }
          });
        });
      }
      if (is_locked_correct_lexile != true) {
        // Show Lock Classcode Warning Message
        this.show_incorrectly_locked_classcode_message = true;
      } else {
        /** Move Forward Check for Duration */
        console.log("finished Locked lexile Check");
        console.log("Moved to Duration Warning");
        this.warningMessage();
      }
    },
    // Warming Message for Duration
    warningMessage() {
      if (
        this.roleName == "TEACHER" &&
        this.form.assignment_type != "DOCUMENT" &&
        (this.form.duration == null || this.form.duration == "")
      ) {
        this.isWarning = true;
      } else {
        this.isWarning = false;
        this.save();
      }
    },

    // Save
    async save(is_draft = 0) {
      try {
        this.isWarning = false;
        this.is_Show_loading = true;
        this.isSaving = true;
        this.form.created_by_id = this.user.id;
        this.form.maximum_marks = 0;
        this.form.assignment_questions.forEach(
          (aQ) => (this.form.maximum_marks += parseInt(aQ.marks))
        );
        this.CheckFile();
        this.form.assignment_questions.forEach((assignment_question) => {
          // if (assignment_question.correct_options) {
          assignment_question.correct_options.forEach((qco) => {
            let assignment_question_correct_option = {
              option: qco,
            };
            assignment_question.assignment_question_correct_options.push(
              assignment_question_correct_option
            );
          });
          // }
        });
        if (is_draft == true) {
          // Save as Draft
          this.form.status = "3";
        }
        let assignment = await axios.post(`/assignments`, this.form);
        this.form = assignment.data.data;
        await this.handleFileUpload();
        this.isDataSaved = true;
        var _this = this;
        // Simulate a is_Show_loading delay of 2 seconds
        setTimeout(() => {
          // After 2 seconds, change the is_Show_loading state to false
          _this.is_Show_loading = false;
        }, 800);
        // Simulate a redirect delay of 3 seconds
        setTimeout(() => {
          // After 3 seconds, Close Dialog
          this.$router.push("/assignments");
        }, 1800);
        this.isSaving = false;
      } catch (e) {
        console.log(e);
        let errors = e.response.data.errors;
        this.focusDiv(errors);
        this.isSaving = false;
      }
    },
    // Handle File Upload
    async handleFileUpload() {
      if (this.$refs.file != null) {
        let attachment = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("assignmentid", this.form.id);
        formData.append("documentpath", attachment);
        await axios
          .post("upload_assignment_document", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
      }
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
.make_transparent {
  background-color: rgb(41 40 40 / 68%);
}

.label {
  font-family: Montserrat !important;
}
.required label::after {
  content: "*";
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.switch-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 22px;
}
/* Add styles for the focused state */
.v-btn--is-focused {
  /* Define your focused styles here */
  outline: 2px solid blue;
}
@media (min-width: 1270px) {
  /* 1270 & Above */
  .midScreen {
    display: none;
  }
  .smallScreen {
    display: none;
  }
}
@media (min-width: 800px) and (max-width: 1270px) {
  /* 800 --  1270*/
  /* 1200 --  1300*/
  .largeScreen {
    display: none;
  }
  .smallScreen {
    display: none;
  }
}
@media (max-width: 800px) {
  /* 800 & less*/
  .largeScreen {
    display: none;
  }
  .midScreen {
    display: none;
  }
}
.newButton {
  text-transform: initial !important;
  border-radius: 8px;
}
</style>
