<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <BackButton></BackButton>
          Add a Subject
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="board_id"
                v-model="form.boards"
                :items="boardItems"
                label="Location"
                multiple
                chips
                prepend-icon="mdi-cast-education"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-switch
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <input
                type="file"
                id="file_1"
                name="file_1"
                ref="file_1"
                @change="onImage1Change"
                accept="image/*"
              />
              <br />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.file_1.value = null), (image1_url = null)"
                >Remove</v-btn
              >
            </v-col>
            <v-col md="8">
              <div id="preview" v-if="image1_url">
                <label for="">Image 1 Preview</label>
                <br />
                <img
                  :src="image1_url"
                  style="height: 50%; width: 50% !important"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <input
                type="file"
                id="file_2"
                name="file_2"
                ref="file_2"
                @change="onImage2Change"
                accept="image/*"
              />
              <br />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.file_2.value = null), (image2_url = null)"
                >Remove</v-btn
              >
            </v-col>
            <v-col md="8">
              <div id="preview" v-if="image2_url">
                <label for="">Image 2 Preview</label>
                <br />
                <img
                  :src="image2_url"
                  style="height: 50%; width: 50% !important"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <input
                type="file"
                id="file_3"
                name="file_3"
                ref="file_3"
                @change="onImage3Change"
                accept="image/*"
              />
              <br />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.file_3.value = null), (image3_url = null)"
                >Remove</v-btn
              >
            </v-col>
            <v-col md="8">
              <div id="preview" v-if="image3_url">
                <label for="">Image 3 Preview</label>
                <br />
                <img
                  :src="image3_url"
                  style="height: 50%; width: 50% !important"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <input
                type="file"
                id="file_4"
                name="file_4"
                ref="file_4"
                @change="onImage4Change"
                accept="image/*"
              />
              <br />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.file_4.value = null), (image4_url = null)"
                >Remove</v-btn
              >
            </v-col>
            <v-col md="8">
              <div id="preview" v-if="image4_url">
                <label for="">Image 4 Preview</label>
                <br />
                <img
                  :src="image4_url"
                  style="height: 50%; width: 50% !important"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <input
                type="file"
                id="file_5"
                name="file_5"
                ref="file_5"
                @change="onImage5Change"
                accept="image/*"
              />
              <br />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.file_5.value = null), (image5_url = null)"
                >Remove</v-btn
              >
            </v-col>
            <v-col md="8">
              <div id="preview" v-if="image5_url">
                <label for="">Image 5 Preview</label>
                <br />
                <img
                  :src="image5_url"
                  style="height: 50%; width: 50% !important"
                />
              </div>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col>
              <loader v-if="isLoading"></loader>
              <v-btn v-else color="primary" dark @click="save">
                Save Subject
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import BackButton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "SubjectCreatePage",
  data() {
    return {
      form: {
        name: "",
        is_active: true,
        imagepath: "",
        boards: [],
        subject_boards: [],
      },
      image1_url: "",
      image2_url: "",
      image3_url: "",
      image4_url: "",
      image5_url: "",
      isLoading: false,
      boardItems: [],
    };
  },
  components: {
    BackButton,
    Loader,
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/subjects/masters");
      masters = masters.data;
      masters.boards.forEach((board) => {
        this.boardItems.push({
          id: board.id,
          text: board.name,
          value: board.id,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.boards.forEach((board_id) => {
          let subject_board = {
            board_id: board_id,
          };
          this.form.subject_boards.push(subject_board);
        });
        let subject = await axios.post("/subjects", this.form);
        this.subject = subject.data.data;
        this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/subjects");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    onImage1Change(e) {
      const file = e.target.files[0];
      this.image1_url = URL.createObjectURL(file);
    },
    onImage2Change(e) {
      const file = e.target.files[0];
      this.image2_url = URL.createObjectURL(file);
    },
    onImage3Change(e) {
      const file = e.target.files[0];
      this.image3_url = URL.createObjectURL(file);
    },
    onImage4Change(e) {
      const file = e.target.files[0];
      this.image4_url = URL.createObjectURL(file);
    },
    onImage5Change(e) {
      const file = e.target.files[0];
      this.image5_url = URL.createObjectURL(file);
    },
    async handleFileUpload() {
      this.isLoading = true;
      const subjectid = this.subject.id;
      let formData = new FormData();
      formData.append("id", subjectid);
      let imagepath_1 = this.$refs.file_1?.files[0];
      formData.append("imagepath_1", imagepath_1);
      let imagepath_2 = this.$refs.file_2?.files[0];
      formData.append("imagepath_2", imagepath_2);
      let imagepath_3 = this.$refs.file_3?.files[0];
      formData.append("imagepath_3", imagepath_3);
      let imagepath_4 = this.$refs.file_4?.files[0];
      formData.append("imagepath_4", imagepath_4);
      let imagepath_5 = this.$refs.file_5?.files[0];
      formData.append("imagepath_5", imagepath_5);
      await axios
        .post("upload_subject_imagepath", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      if (this.subject_request) {
        this.success = true;
        this.form = [];
      }
      this.isLoading = false;
    },
  },
};
</script>
