<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <v-btn class="mx-2" fab small color="primary" to="/notifications">
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add a Notification
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-textarea
                v-model="form.description"
                outlined
                label="Name"
                prepend-inner-icon="mdi-alarm-bell"
                :error-messages="errors.description"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save">
            Save Notification
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        description: "",
      },
      isLoading: false,
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        this.form.user_id = this.user.id
        let notification = await axios.post("/notifications", this.form);
        this.notification = notification.data.data;
        this.isLoading = false;
        this.$router.push("/notifications");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
