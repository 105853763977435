<template>
  <section style="background: white">
    <v-main>
      <v-container class="mt-8">
        <v-row justify="center" class="my-10">
          <span
            class="text-h2 font-weight-bold myFont"
            v-text="'Terms of Service'"
          ></span>
        </v-row>
      </v-container>

      <section class="pl-12" style="text-align: justify">
        <v-col
          md="12"
          class="my-auto mb-8 px-8"
          style="height: 600px; overflow: scroll"
        >
          <ol type="1" class="myFont mb-5" style="font-size: 25px">
            <!-- Introduction -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold pb-8">Introduction</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                Welcome to the website “<router-link to="/"
                  >www.infakt.co.in</router-link
                >” (“<b>Website</b>”) and its related mobile learning
                application (“<b>Application</b>”) (collectively referred to as
                “<b>Platform</b>”). For the purposes of these Terms of Service,
                accessing of the Platform together with any study material
                (“<b>Content</b>”) made available, uploaded or downloaded
                therefrom and shall hereinafter be collectively referred to as
                the <b>"Services"</b>. The Platform is owned by Warden Education
                Technologies Private Limited, a company incorporated under
                Companies Act, 1956, having its registered office at Warden
                House, 340, JJ Road, Byculla, Mumbai, Maharashtra – 400008.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                These Terms of Service are a legal, binding contract between
                every user (“<b>You</b>”, “<b>Your</b>”, or “<b>User</b>”) and
                Warden Education Technologies Private Limited (“<b>We</b>”,
                “<b>Us</b>”, “<b>Our</b>”, or “<b>Warden</b>”). Please read
                these terms and conditions carefully. The following Terms of
                Service govern Your use and access of the Platform and/or
                Services. By using or accessing the Platform in any way,
                including registering on the Platform, using, sharing, browsing
                and/or downloading any Services, You agree to be bound by the
                Terms of Service set forth herein. If You do not agree to these
                Terms of Service, do not access and/or use this Platform or the
                Services.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                Access to and use of password protected and/or secure areas of
                the Platform and/or use of the Services is restricted to User(s)
                with accounts only. You shall not obtain or attempt to obtain
                unauthorized access to such parts of this Platform and/or
                Services, or to any other protected information, through any
                means not intentionally made available by Us for Your specific
                use. A breach of this provision may be an offence under the
                Applicable Laws including but not limited to the Information
                Technology Act, 2000.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                We reserve the right to update these Terms of Service from time
                to time without any prior notice to the User. Please read the
                whole document carefully before using Our Platform or Our
                Services, as using them (with or without subscription
                fees/payment) will indicate Your acceptance to Our Terms of
                Service.
              </p>
            </li>
            <!-- End of Introduction -->
            <!-- Definitions & Interpretations -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">
                Definitions & Interpretations
              </h1>
              <p class="myFont mb-5" style="font-size: 25px">
                Unless otherwise defined, the definitions and provisions in
                respect of interpretation set out in Schedule I will apply to
                these Terms of Service.
              </p>
            </li>
            <!-- End of Definitions & Interpretations -->
            <!-- Eligibility -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">Eligibility</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                In order to avail the Services provided on the Platform, You
                must be legally competent, within the meaning of the Indian
                Contract Act, 1872, to enter into binding, legal contracts such
                as these Terms of Service.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                If You are a purchaser of these Services on behalf of a
                school/educational institution, You agree that the school/
                educational institution and each of its students to whom access
                to the Services shall be provided, is bound by these Terms of
                Service.
              </p>
            </li>
            <!-- End of Eligibility -->
            <!-- General Use of Services; User Restrictions -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">
                General Use of Services; User Restrictions
              </h1>
              <p class="myFont mb-5" style="font-size: 25px">
                You agree to comply with any and all the guidelines, notices,
                operating rules, policies and instructions pertaining to the use
                of the Services and/or access to the Platform, as well as any
                amendments to the aforementioned, issued by Us, from time to
                time. We reserve the right to revise these guidelines, notices,
                operating rules and policies and instructions at any time and
                You are deemed to be aware of and bound by any changes to the
                foregoing upon their publication on the Platform.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                Further, You agree and undertake <b>NOT</b> to:
              </p>
              <ol type="a" class="ml-10 myFont mb-5" style="font-size: 25px">
                <li class="mb-5">
                  Impersonate any person or entity or to falsely state or
                  otherwise misrepresent Your affiliation with any person or
                  entity;
                </li>
                <li class="mb-5">
                  Use the Platform or Services for illegal or immoral purposes;
                </li>
                <li class="mb-5">
                  Attempt to gain unauthorized access to or otherwise interfere
                  or disrupt other computer systems or networks connected to the
                  Platform or Services;
                </li>
                <li class="mb-5">
                  Post, promote or transmit through the Platform or Services any
                  Prohibited Materials;
                </li>
                <li class="mb-5">
                  Interfere with another User’s utilization and enjoyment of the
                  Platform or Services;
                </li>
                <li class="mb-5">
                  Use or upload, in any way, any software or material that
                  contains, or which You have reason to suspect that contains,
                  viruses, damaging components, malicious code or harmful
                  components which may impair or corrupt the Platform’s data or
                  damage or interfere with the operation of another User’s
                  Platform or Services; and
                </li>
                <li class="mb-5">
                  Use the Platform or Services other than in conformance with
                  the acceptable use policies of any connected computer
                  networks, any applicable Internet standards and any other
                  Applicable Laws.
                </li>
              </ol>
              <p class="myFont mb-5" style="font-size: 25px">
                We may, from time to time and without giving any reason or prior
                notice, upgrade, modify, suspend or discontinue the provision of
                or remove, whether in whole or in part, the Platform or any
                Services and shall not be liable if any such upgrade,
                modification, suspension or removal prevents You from accessing
                the Platform or any part of the Services. We reserve the right,
                but shall not be obliged to:
              </p>
              <ol type="a" class="ml-10 myFont mb-5" style="font-size: 25px">
                <li class="mb-5">
                  Monitor, screen or otherwise control any activity, content or
                  material on the Platform and/or through the Services. We may
                  in Our sole and absolute discretion, investigate any violation
                  of the terms and conditions contained herein and may take any
                  action it deems appropriate;
                </li>
                <li class="mb-5">
                  Prevent or restrict access of any User to the Platform and/or
                  the Services;
                </li>
                <li class="mb-5">
                  Report any activity it suspects to be in violation of any
                  Applicable Law, statute or regulation to the appropriate
                  authorities and to co-operate with such authorities; and/or
                </li>
                <li class="mb-5">
                  To request any information and data from You in connection
                  with Your use of the Services and/or access of the Platform at
                  any time and to exercise Our right under this paragraph if You
                  refuse to divulge such information and/or data or if You
                  provide or if We have reasonable grounds to suspect that You
                  have provided inaccurate, misleading or fraudulent information
                  and/or data.
                </li>
              </ol>
              <p class="myFont mb-5" style="font-size: 25px">
                Purchases of any Services would be subject to these Terms of
                Service. In addition to these Terms of Service, the use of
                specific aspects of the Services, or updated versions of the
                Services offered by Us or Our designated sub-contractors, may be
                subject to additional terms and conditions, which shall be
                notified to You from time to time, and such additional terms and
                conditions will apply in full force and effect. You hereby
                acknowledge and agree to be bound to the additional terms and
                conditions as notified by Us from time to time.
              </p>
            </li>
            <!-- End of General Use of Services; User Restrictions -->
            <!-- Limited License -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">Limited License</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                Subject to the terms and conditions of these Terms of Service,
                We grant the User a personal, limited, non-exclusive,
                non-transferable, non-sub-licensable, and revocable license to
                avail the Services on the Platform. The Services may not be
                reproduced, duplicated, copied, sold, resold,, or otherwise
                exploited for any commercial purpose without express written
                consent of Warden. You may not frame or utilize framing
                techniques to enclose any trademark, logo, or other proprietary
                information (including images, text, page layout, or form) of
                the Platform or of Warden and/or its affiliates without the
                express written consent of Warden. You may not use any meta tags
                or any other “hidden text” utilizing the Our name or trademarks
                without the express written consent of the Warden. Any
                unauthorized commercial use of Our Services shall attract
                immediate termination of Your relationship with Warden and
                subsequent monetary penalization as We may deem fit. Termination
                and penalisation for the breach will not alter or affect Our
                right to exercise any other legal remedies for such breach. You
                shall not interfere with or circumvent any feature of the
                Services, including any security or access control mechanism. If
                you are prohibited under Applicable Law from using the Service,
                you may not use it.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                Warden gives You a limited right and option to modify the
                Content as per Your requirements (“<b>Modified Content</b>").
                You agree and undertake that any modifications, alterations, or
                changes made to the Content provided by Us, whether intentional
                or unintentional, shall be Your sole responsibility. Warden
                disclaims any lability or responsibility for the accuracy,
                completeness, or consequences of such modifications made by You,
                in the Content.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                You further acknowledge that You are responsible for the
                accuracy, legality, quality, integrity, and intellectual
                property ownership rights of the Modified Content. You must
                ensure that the Modified Content complies with these Terms of
                Service, along with Warden’s Privacy Policy and other applicable
                policies, laws and regulations. You agree that You will not
                modify the Content on the Platform, in a manner that is contrary
                to the Terms of Service and Our Privacy Policy, or any other
                policy of Warden, as updated from time to time, or that is
                contrary to applicable laws and regulations.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                You agree to use the Platform only in compliance with these
                Terms of Service and Applicable Law, and in a manner that does
                not violate legal rights of Warden or those of any third
                party(ies).
              </p>
            </li>
            <!-- End of Limited License -->
            <!-- Feedback -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">Feedback</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                If you choose to provide input and suggestions regarding
                problems with or proposed modifications or improvements to the
                Service (“<b>Feedback</b>”), then you hereby grant us an
                unrestricted, perpetual, irrevocable, non-exclusive, fully-paid,
                royalty-free right to exploit the Feedback in any manner and for
                any purpose, including to improve the Service and create other
                products and services.
              </p>
            </li>
            <!-- End of Feedback -->
            <!-- Third Party Content -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">Third Party Content</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                Some of the information or other materials available on or
                through the Services may have been prepared by third parties not
                affiliated with Warden, including other Users. We make no
                warranties with regard to such information or other materials,
                nor do We assume any responsibility or liability for any
                decisions based upon such information. The Services may include
                links to websites and services provided by third party(ies) (“<b
                  >Third-Party Links</b
                >”). Your interaction with these Third-Party Links will be
                subject to the terms (including privacy policies) established by
                such Third-Party Links. Warden is not responsible for, and does
                not endorse or warrant in any way, any materials, information,
                goods or services available through or advertised on such
                Third-Party Links, or the privacy or other practices of the
                Third-Party Links. The inclusion of any Third-Party Links does
                not imply endorsement by Warden of those sites or any
                association with its operators.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <b
                  >WARDEN DISCLAIMS ANY AND ALL RESPONSIBILITY FOR ANY
                  DISCLOSURE OF INFORMATION OR ANY OTHER PRACTICES OF ANY
                  THIRD-PARTY. WARDEN EXPRESSLY DISCLAIMS ANY WARRANTY WITH
                  RESPECT TO YOUR PERSONAL OR OTHER INFORMATION THAT MAY BE
                  COLLECTED, PROCESSED, SHARED OR RETAINED BY ANY
                  THIRD-PARTY.</b
                >
              </p>
            </li>
            <!-- End of Third Party Content -->
            <!-- Communications -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">Communications</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                When You install Our app on Your mobile device, You agree to
                receive push notifications, which are messages an app sends You
                on Your mobile device when the app is not on. You can turn off
                notifications by visiting Your mobile device’s “settings” page.
              </p>
            </li>
            <!-- End of Communications -->
            <!-- Privacy Policy -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">Privacy Policy</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                The Platform does not disclose, share, publish or sell the
                User’s information to any third-party. You hereby acknowledge
                that when You use the Platform, We may collect certain personal
                information from You. Such collection of information shall be in
                accordance with Our
                <router-link to="/privacy-policy">Privacy Policy</router-link>
                which is incorporated into these Terms of Service by reference.
                You acknowledge and agree that Your submission of such
                information is voluntary on Your part. Disclosures of
                information to third-party(ies) are further addressed in Our
                Privacy Policy.
              </p>
            </li>
            <!-- End of Privacy Policy -->
            <!-- Disclaimer of Warranties and Liabilities -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">
                Disclaimer of Warranties and Liabilities
              </h1>
              <p class="myFont mb-5" style="font-size: 25px">
                You expressly understand and agree that, to the maximum extent
                permitted by applicable law, the Platform and Services are
                provided by Us, is on an "as is" basis without warranty of any
                kind, express, implied, statutory or otherwise, including the
                implied warranties of title, non-infringement, satisfactory
                quality or fitness for a particular purpose. We shall not be
                liable for any third-party product or services. You also
                acknowledge that Our Platform and its related functions may
                contain bugs, errors and/or other defects which We do not claim
                any liability of. Your access to Our Platform and other related
                links is at Your sole risk.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                While every effort is made by Us to keep the Platform up and
                running smoothly, We take no responsibility for, and will not be
                liable for, the Platform being temporarily unavailable due to
                technical issues beyond Our control.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                You understand and agree (on behalf of yourself and/or a child
                User) that the use of the Services may require electricity,
                internet connectivity, properly functioning device, and
                telecommunication links without which the Services will not be
                accessible, You shall bear the costs incurred to access and use
                the Services and we shall not, under any circumstances
                whatsoever, be responsible or liable for such costs. We will not
                be liable for any issues in the Services that arise directly or
                indirectly due to faulty or lack of electricity, internet
                connectivity, device and/or telecommunication links.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                Without limiting the foregoing, Warden makes no warranty that
                the Services provided:
              </p>
              <ol type="a" class="ml-10 myFont mb-5" style="font-size: 25px">
                <li class="mb-5">
                  will be timely, reliable and error/bug free;
                </li>
                <li class="mb-5">will meet the User’s requirements;</li>
                <li class="mb-5">
                  will ensure that all errors are corrected; and
                </li>
                <li class="mb-5">
                  will be compatible with all operating systems, smart phones,
                  tablets, laptops, desktops, networks, including all versions
                  of them.
                </li>
              </ol>
              <p class="myFont mb-5" style="font-size: 25px">
                You accept the risk that any information transmitted or received
                through the Services or the Platform may be accessed by
                unauthorized third parties and/or disclosed by Us or Our
                officers, employees or agents to third parties purporting to be
                You or purporting to act under Your authority. Transmissions
                over the Internet and electronic mail may be subject to
                interruption, transmission blackout, and delayed transmission
                due to Internet traffic or incorrect data transmission due to
                the public nature of the Internet.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                We do not endorse, support, represent or guarantee the
                completeness, truthfulness, accuracy, or reliability of any
                Content or communications posted via the Services or endorse any
                opinions expressed via the Services.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                You also acknowledge that You shall be solely responsible for
                any and all damage to the computer system or any data as a
                result of download of any materials provided in the Services.
                You expressly agree and acknowledge that the Services on the
                Platform are solely for educational information purposes only,
                and is in no way, a substitute for Your academic curriculum.
                Warden and its affiliates, subsidiaries, directors, agents, and
                employees and third-party(ies) associated with the Platform make
                no warranty of any kind regarding the outcome and/or results of
                any examination taken by the User.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                If You are availing Our Services for Your minor students, You
                shall be responsible for informing the parent(s)/ legal guardian
                of each of the students about these Terms of Service and shall
                obtain the consent of such parent(s)/ legal guardian in lieu of
                the same.
              </p>
            </li>
            <!-- End of Disclaimer of Warranties and Liabilities -->
            <!-- Limitation of Liability -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">
                Limitation of Liability
              </h1>
              <p class="myFont mb-5" style="font-size: 25px">
                Warden, its affiliates, officers, directors, employees, partners
                or agents, shall in no event be liable for any losses or damages
                including any indirect, incidental, special, exemplary, punitive
                or consequential damages, including lost profits, lost data,
                personal injury or property damage related to, in connection
                with, or otherwise resulting from any use of the Services.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                Warden advises its User(s) to verify every information available
                on the Platform before taking it into consideration. In the
                event of a technical issue arising due to reasons solely
                attributable to Warden, We shall strive to resolve such issue
                within a reasonable time period from the day when such issue is
                brought to Our notice. We shall not be liable for any damages,
                liability or losses arising out of: (i) Your and/or a child
                user’s use of or reliance on the Services, or Your and/or a
                child user’s inability to access or use the Services due to your
                non-compliance with these Terms of Service , the Privacy Policy,
                or any additional user terms; or (ii) any transaction or
                relationship between You and/or a child user and any third party
                service provider; or (iii) defaults of any third party service
                providers which are providing Platform related services to Us;
                or (iv) any event that is beyond Our reasonable control and
                shall include, without limitation, sabotage, fire, flood,
                explosion, pandemic, act of God, civil commotion, strikes or
                industrial action of any kind, riots, insurrection, war, acts of
                government, computer hacking, unauthorized access to Your and/or
                a child user’s or storage device, crashes, breach of security
                and encryption, power or electricity failure or unavailability
                of adequate power or electricity, or any unauthorized act of
                third party service provider engaged by Us in accordance with
                these Terms of Service and the Privacy Policy.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                We shall not be liable for any misinformation, inaccurate data,
                data theft, system damage, or any other loss occurring as a
                result of Third-Party Links. The terms and policies of Warden do
                not govern the use of Third-Party Links.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                The limitations and exclusions in this Clause 11 apply to the
                maximum extent permitted by applicable law.
              </p>
            </li>
            <!-- End of Limitation of Liability -->
            <!-- User Content -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">User Content</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                You agree that You are solely responsible for the content that
                You or Your authorized users create, transmit, display, use,
                store and/or redistribute while using Our Services. User(s) must
                understand that, if any confidential or personally identifiable
                information is used in the Services then it may be viewed, read,
                modified and/or deleted by other User(s) of the Services.
                User(s) must also agree that We have no obligation with respect
                to any User content. We shall not be responsible or liable for
                the deletion, correction, destruction, damage or loss of any
                user content. If We receive notice that any content created by a
                User while using the Services violates any law or infringes any
                third-party rights, We shall have the right to immediately
                suspend the User’s access to the Services without any prior
                notice.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                You acknowledge that You are responsible for the accuracy,
                legality, quality, integrity, and intellectual property
                ownership rights of Your content. You must ensure that Your
                content complies with these Terms of Service, along with
                Warden’s Privacy Policy and other applicable policies, laws and
                regulations. You acknowledge that We may access, use, preserve,
                or disclose Your account information and user content in
                pursuant of a court order or directions from law enforcement
                agencies. You agree that disclosure is reasonably necessary to
                (i) comply with legal process or request; (ii) enforce these
                Terms of Service; (iii) security or technical issues; (iv)
                detect or prevent fraud; and (v) protect the rights, property or
                safety of Warden, its Users, or the public as permitted by law.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                You agree that You will not upload on the Platform, any content
                that is contrary to the Terms of Service and Our Privacy Policy,
                or any other policy of Warden, as updated from time to time, or
                that is contrary to applicable laws and regulations. If any such
                content is uploaded on the Platform from Your account in any
                manner and for any reason whatsoever, You shall ensure that such
                content is deleted from the Platform and Your account
                immediately.
              </p>
            </li>
            <!-- End of User Content -->
            <!-- Registration and Payment -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">
                Registration and Payment
              </h1>
              <p class="myFont mb-5" style="font-size: 25px">
                User shall create an account with Warden in order to avail the
                Services provided on the Platform, as well as to make purchases
                of the Services (“<b>User Account</b>”). You shall be required
                to input a username and password, created sole by You, to
                establish the User Account. The User Account so created shall
                collect personally identifiable information of your authorized
                users (“<b>Personal Data</b>”) - name, email address, mobile
                number and other contact details. You also have the option of
                linking Your social media accounts to Your User Account but the
                same shall be subject to compliance with the terms and
                conditions of that particular social media company. You must
                keep Your account and registration details current and correct
                for communications related to Your purchases from the Platform.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                We may in Our sole and absolute discretion for the purposes of
                rendering the Services, request updating of the Personal Data
                provided by You, failing which we may be compelled to invalidate
                the username and/or password and in which case the Services may
                be interrupted. You agree that, We shall not be liable or
                responsible for any Losses suffered by You or arising out of or
                in connection with or by reason of such interruption. You hereby
                agree to change your password from time to time and to keep the
                username and password confidential and shall be responsible for
                the security of Your User Account and liable for any disclosure
                or use (whether such use is authorized or not) of the username
                and/or password. You are to notify Us immediately if You have
                knowledge that or have reason for suspecting that the
                confidentiality of the username and/or password has been
                compromised or if there has been any unauthorized use of the
                username and/or password or if Your Personal Data requires
                updating.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                By creating the User Account, You agree to the terms required to
                protect the confidentiality of username and password for the
                User Account and shall solely be liable for any activity
                conducted through the User Account. You agree to accept all risk
                and responsibilities for activity done under the User Account.
                You also agree to receive SMS/Emails from Warden (“<b
                  >Promotional Communications</b
                >”) regarding curriculum material and other related purchases
                made by You. You may subsequently opt out of receiving
                Promotional Communications by clicking on the appropriate
                hyperlink in any Promotional Communications sent to You by
                Warden.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                Warden reserves the right and sole discretion to refuse access
                to the Platform, terminate any User Account, remove/restrict
                content at any time, with or without notice to You, in case any
                illegal or immoral activity being conducted from Your User
                Account is brought to Our notice, or We have reason to believe
                that any information provided by You is incomplete, inaccurate
                or outdated.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                We may determine subscription plans which shall allow access to
                the Platform and usage of the Services upon payment for the same
                made by You (“<b>Subscription Fees</b>”). These subscription
                plans may be available on the Platform from time to time and We
                may at our sole discretion modify the same. You are responsible
                for any taxes, including goods and services tax, resulting from
                Your use of the Platform. All Subscription Fees are
                non-refundable.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                Failure to pay: If the User fails to pay the Subscription Fees
                pursuant to the purchase of the Services, or payment is
                cancelled for any reason whatsoever, then without prejudice to
                any other right or remedy available to Us, We shall be entitled
                to cancel the purchase or suspend delivery of the Services until
                payment is made in full.
              </p>
            </li>
            <!-- End of Registration and Payment -->
            <!-- Terms for individual Users -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">
                Terms for individual Users
              </h1>
              <p class="myFont mb-5" style="font-size: 25px">
                Whilst the Services provided by Us is for educational
                institutions, however, if you as an individual or as a minor
                also wish to use Our Services, such use shall also be made
                available to You or by Your legal guardian (if you’re a minor)
                or parent, who has agreed to these Terms of Service.
                <br />
                In the event a minor utilizes the Platform/Services, it is
                assumed that he/she has obtained the consent of the legal
                guardian or parent(s) and such use is made available by the
                legal guardian or parent(s).
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                Warden will not be responsible for any consequence that arises
                as a result of usage of Our Platform or any of Our Services by a
                minor. By using the Services, You warrant that all the data
                provided by You is accurate and complete and that, in case of a
                minor, you have obtained the consent of Your parent(s)/ legal
                guardian.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                We reserve the right to terminate Your subscription and / or
                refuse to provide You with access to the Services if it is
                discovered that You are under the age of 18 (eighteen) years and
                the consent to use the Services is not provided by Your
                parent(s)/legal guardian or any information provided by You is
                inaccurate. You acknowledge that Warden does not have the
                responsibility to ensure that You conform to the aforesaid
                eligibility criteria. It shall be Your sole responsibility to
                ensure that You meet the required qualification. Any persons
                under the age of 18 (eighteen) years should seek the consent of
                their parent(s)/legal guardians before providing any Personal
                Data about themselves or their parent(s) and other family
                members on the Platform.
              </p>
            </li>
            <!-- End of Intellectual Property Rights -->
            <!-- Intellectual Property Rights -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">
                Intellectual Property Rights
              </h1>
              <p class="myFont mb-5" style="font-size: 25px">
                All information including but not limited to Warden’s content,
                trade names, logo, images and Services are proprietary
                information (“<b>Intellectual Property</b>”) of Warden Education
                Technologies Private Limited and its affiliates, agents,
                directors and employees, and no such Intellectual Property may
                be reproduced, copied, re-published, posted, distributed or
                transmitted in any way without the prior consent of Warden.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                We respect the intellectual property rights of others, and You
                agree to protect Our Intellectual Property rights and the
                intellectual property rights of all others having rights in the
                Platform during and after the term of these Terms of Services.
                We restrict Users from uploading, posting or otherwise
                transmitting materials that violate the intellectual property
                rights of third-party(ies). No part or parts of the Platform may
                be reproduced, reverse engineered, decompiled, disassembled,
                separated, altered, distributed, republished, displayed,
                broadcast, hyperlinked, mirrored, framed, transferred or
                transmitted in any manner or by any means or stored in an
                information retrieval system or installed on any servers, system
                or equipment without Our prior written permission. Permission
                will only be granted to You to use the Services for personal and
                non-commercial uses (unless otherwise agreed by Us in writing),
                provided that You do not modify the content of the Services and
                that We retain all proprietary rights contained in the Services.
                You shall not shall download or infringe upon the Our
                proprietary information in any manner, unless explicitly
                permitted by Us in writing.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                Trademarks: The Trademarks are registered and unregistered
                trademarks of Us or third parties. Nothing on the Platform and
                in these Terms of Service shall be construed as granting, by
                implication, estoppel, or otherwise, any license or right to use
                (including as a meta tag or as a “hot” link to any other
                website) any Trademarks displayed on the Services, without Our
                written permission or any other applicable trademark owner.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                You hereby agree and declare that you shall not at any time:
              </p>
              <ol type="i" class="ml-10 myFont mb-5" style="font-size: 25px">
                <li class="mb-5">
                  Take any action which shall or may impair Our or Our service
                  provider’s right, title or interest to the said marks or
                  create any right, title or interest therein or thereto which
                  may be adverse to Us or the service providers respectively;
                </li>
                <li class="mb-5">
                  Use the said marks upon or in relation to any goods other than
                  those displayed on the Platform or for any other purpose;
                </li>
                <li class="mb-5">
                  Register deceptively similar marks in any additional class or
                  country.
                </li>
              </ol>
              <p class="myFont mb-5" style="font-size: 25px">
                In the event that We receive notification of alleged
                intellectual property rights complaints that do not comply with
                the applicable laws, We shall endeavor to promptly remove or
                disable access to the illegal infringing material. We may also
                terminate the User Accounts of the infringers.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                If You are an owner of any intellectual property, or an agent
                thereof and You believe that any content on the Platform
                infringes upon Your intellectual property rights, You may send a
                notice to Us via email at
                <a href="mailto:it@infakt.co.in">it@infakt.co.in</a>
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                References on the Platform to any names, marks, products or
                services of third-party(ies) or Third-Party Links or information
                are provided solely as a convenience to You and do not, in any
                way, constitute or imply the Our endorsement, sponsorship or
                recommendation of the third-party, the information, its product
                or services.
              </p>
            </li>
            <!-- End of Intellectual Property Rights -->
            <!-- Representations and Warranties -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">
                Representations and Warranties
              </h1>
              <p class="myFont" style="font-size: 25px">
                You represent, warrant and indemnify that You have the necessary
                right, license, authorization or permission to use the Services
                and to post, upload or publish any video, image, text, software,
                information or any content on the Platform. You hereby agree
                that by posting, uploading or publishing the same on Our
                Platform, You have authorized Us to use or reference the same
                without any restrictions.
              </p>
            </li>
            <!-- End of Representations and Warranties -->
            <!-- Termination -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">Termination</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                These Terms of Service are effective unless and until terminated
                by either You or Us. We may, in Our sole discretion, immediately
                terminate, limit or suspend Your account, delete Your
                registration, other information, and/or prohibit You from using
                or accessing the Platform without any notice, if You have
                violated any of the provisions in these Terms of Service. On
                non-payment of Subscription Fees, We reserve Our right to
                terminate Your access to the Platform.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                If You wish to terminate Your User Account, You may do so by
                following the instructions on the Platform. On termination, You
                lose the right to access or use the Platform. However, any such
                termination of the User Account shall not affect Your obligation
                to pay for Services already purchased from the Platform or
                affect any liability that may have arisen under the User Account
                prior to the date of termination.
              </p>
            </li>
            <!-- End of Termination -->
            <!-- Notices -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">Notices</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                Notices from Us: All notices or other communications given to
                You if:
              </p>
              <ol type="a" class="ml-10 myFont mb-5" style="font-size: 25px">
                <li class="mb-5">
                  Communicated through any print or electronic media as We may
                  select will be deemed to be notified to You on the date of
                  publication or broadcast; or
                </li>
                <li class="mb-5">
                  Sent by post or left at Your last known address will be deemed
                  to be received by You on the day following such posting or on
                  the day when it was so left.
                </li>
              </ol>
              <p class="myFont" style="font-size: 25px">
                Notices from You: You may only give notice to Us in writing sent
                to our designated address or e-mail address, and We shall be
                deemed to have received such notice only upon receipt. While We
                endeavour to respond promptly to notices from You, We cannot
                guarantee that We will always respond with consistent speed.
              </p>
            </li>
            <!-- End of Notices -->
            <!-- Cancellations -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">Cancellations</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                All sales made on the Platform are final and You shall not be
                entitled to cancel Your purchase once You have received
                confirmation of the same. We reserve the sole right to cancel
                any order as per Our discretion in case (i) We are unable to
                deliver the order in a satisfactory manner and/ or (ii) the User
                tries to take advantage of the system which violates the Terms
                of Service. We will ensure that any communication of
                cancellation of an order or any applicable refund will be made
                within a reasonable period of time.
              </p>
            </li>
            <!-- End of Cancellations -->
            <!-- Indemnity -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">Indemnity</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                You hereby agree that You shall, indemnify, defend, and hold
                harmless Warden, including but not limited to its affiliates,
                agents, directors, and employees from and against any and all
                losses, liabilities, claims, damages, demands, costs and
                expenses (including legal fees and disbursements in connection
                therewith and interest chargeable thereon) asserted against or
                incurred by Us that arise out of, result from, or may be payable
                by virtue of, any breach, including any unauthorized commercial
                use of the Services provided in Clause 5, or non-performance of
                any representation, warranty, covenant or agreement made or
                obligation to be performed by You pursuant to these Terms of
                Service. Further, You agree to hold Us harmless against any
                claims made by any third-party due to, or arising out of, or in
                connection with, Your use of the website, any claim that Your
                material caused damage to a third-party, Your violation of the
                terms of service, or Your violation of any rights of another,
                including any intellectual property rights.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                In no event shall Warden, its officers, directors, employees,
                agents or suppliers be liable to You, or any third-party for any
                special, incidental, indirect, consequential or punitive damages
                whatsoever, including those resulting from loss of use, data or
                profits, whether or not foreseeable or whether or not We have
                been advised of the possibility of such damages, or based on any
                theory of liability, including breach of contract or warranty,
                negligence or other tortious action, or any other claim arising
                out of or in connection with Your use of or access to the
                Platform and its Services.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                The limitations and exclusions in this Clause 20 apply to the
                maximum extent permitted by applicable law.
              </p>
            </li>
            <!-- End of Indemnity -->
            <!-- Grievances Redressal -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">
                Grievances Redressal
              </h1>
              <p class="myFont mb-5" style="font-size: 25px">
                You agree to use the Platform and any related materials or
                Services at Your sole risk and that We will have no liability to
                You for the content that may be deemed offensive, indecent, or
                objectionable. All complaints, queries and suggestions should be
                sent by email at
                <a href="mailto:it@infakt.co.in">it@infakt.co.in</a> or write to
                Us at our registered address.
              </p>
            </li>
            <!-- End of Grievances Redressal -->
            <!-- Miscellaneous -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">Miscellaneous</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.1. Governing Law, Dispute Resolution and Jurisdiction</u>:
                These Terms of Service is governed by the laws of India. Any
                claim related to any dispute arising as a result of the Platform
                or under these Terms of Service will be made before a court of
                competent jurisdiction located in Mumbai, Maharashtra. Warden
                agrees to resolve such dispute by binding to Arbitration and
                Conciliation Act, 1996. You agree that regardless of any statute
                or law to the contrary, any claim or cause of action arising out
                of or related to the Terms of Service or use of the Platform
                must be filed within a period of 60 (sixty) days after such
                claim or cause of action arose or be forever barred.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.2. Cumulative rights and remedies</u>: Unless otherwise
                provided under these Terms of Service, the provisions of these
                Terms of Service and Our rights and remedies under these Terms
                of Service are cumulative and are without prejudice and in
                addition to any rights or remedies We may have in law or in
                equity, and no exercise by Us of any one right or remedy under
                these Terms of Service, or at law or in equity, shall (save to
                the extent, if any, provided expressly in these Terms of Service
                or at law or in equity) operate so as to hinder or prevent Our
                exercise of any other such right or remedy as at law or in
                equity.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.3. No Partnership</u>: You agree that no joint venture,
                partnership, employment, or agency relationship exists between
                You or other parties and Warden as a result of these Terms of
                Service or Your access to or use of the Platform.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.4. Headings</u>: Headings used in these Terms of Service
                are for reference purposes only and in no way define or limit
                the scope of the Clauses.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.5. Severability</u>: If any provision of these Terms of
                Service is held to be unenforceable, such provision will be
                reformed only to the extent necessary to make it enforceable.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.6 Force Majeure</u>: Warden shall be under no liability
                whatsoever in the event of non-availability of any portion of
                the Platform or subscribed content occasioned by act of God,
                war, disease, revolution, riot, civil commotion, strike,
                lockout, pandemic, epidemic, lockdown, flood, fire, failure of
                any public utility, manmade disaster, infrastructure failure or
                any other cause whatsoever beyond the control of Warden.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.7 Waiver</u>: The failure of Warden to act with respect to
                a breach of these Terms of Service by You or others does not
                constitute a waiver and will not limit Our rights with respect
                to such breach or any subsequent breaches.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.8 Survival</u>: The obligations under the provisions of
                Clauses 4, 5, 6, 7, 8, 9, 10, 11, 12,14 and 15 will survive any
                expiration or termination of these Terms of Service.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.9 Confidentiality</u>: You hereby agree to keep the
                explicit terms of your engagement, if any, with Warden
                confidential at all times. You agree to treat the Services with
                the same degree of confidentiality as you would your own
                confidential information.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22. 10 Rights of third parties</u>: A person or entity who is
                not a party to these Terms of Service shall have no right under
                the Indian Contracts Act, 1872 as amended from time to time, or
                any similar legislation in any jurisdiction to enforce any term
                of these Terms of Service, regardless of whether such person or
                entity has been identified by name, as a member of a class or as
                answering a particular description. For the avoidance of doubt,
                nothing in this Clause 22.10 shall affect the rights of any
                permitted assignee or transferee of these Terms of Service.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.11 Injunctive relief</u>: We may seek immediate injunctive
                relief if We make a good faith determination that a breach or
                non-performance is such that a temporary restraining order or
                other immediate injunctive relief is the only appropriate or
                adequate remedy.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.12 Amendments</u>: We may by notice through the Platform
                or by such other method of notification as We may designate
                (which may include notification by way of e-mail), vary the
                terms and conditions of these Terms of Service, such variation
                to take effect on the date We specify through the above means.
                If You use the Platform or the Services after such date, You are
                deemed to have accepted such variation. If You do not accept the
                variation, You must stop accessing or using the Platform and the
                Services and terminate these Terms of Services in the way
                prescribed in Clause 17 (Termination). Our right to vary these
                Terms of Service in the manner aforesaid will be exercised
                without the consent of any person or entity who is / is not a
                party to these Terms of Service.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22.13 Correction of errors</u>: Any typographical, clerical
                or other error or omission in any acceptance, invoice or other
                document on Our part shall be subject to correction without any
                liability on Our part.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22. 14 Currency</u>: Money references under these Terms of
                Service shall be in Indian Rupees.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22. 15 Language</u>: In the event that these Terms of Service
                is executed or translated in any language other than English
                (“<b>Foreign Language Version</b>”), the English language
                version of these Terms of Service shall govern and shall take
                precedence over the Foreign Language Version.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22. 16 Binding and conclusive</u>: You acknowledge and agree
                that any records (including records of any telephone
                conversations relating to the Services, if any) maintained by Us
                or Our service providers relating to or in connection with the
                Platform and Services shall be binding and conclusive on You for
                all purposes whatsoever and shall be conclusive evidence of any
                information and/or data transmitted between Us and You. You
                hereby agree that all such records are admissible in evidence
                and that You shall not challenge or dispute the admissibility,
                reliability, accuracy or the authenticity of such records merely
                on the basis that such records are in electronic form or are the
                output of a computer system, and You hereby waive any of Your
                rights, if any, to so object.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                <u>22. 17 Sub-contracting and delegation</u>: We reserve the
                right to delegate or sub-contract the performance of any of Our
                functions in connection with the Platform and/or Services and
                reserve the right to use any service providers, sub-contractors
                and/or agents on such terms as We deem appropriate.
              </p>
            </li>
            <!-- End of Miscellaneous -->
          </ol>
          <!-- Grievances Redressal -->
          <!-- End of Grievances Redressal -->
          <h1 class="text-h5 font-weight-bold py-8" align="center">
            SCHEDULE I
          </h1>
          <ol type="1" class="myFont mb-5" style="font-size: 25px">
            <!-- DEFINITIONS AND INTERPRETATION -->
            <li class="mb-5">
              <h1 class="text-h5 font-weight-bold py-8">
                DEFINITIONS AND INTERPRETATION
              </h1>
              <h1 class="text-h5 font-weight-bold py-8">1.1 Definitions</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                “<b>Applicable Law</b>” means any statute, law, bye-law,
                enactment, regulations, ordinance, policy, treaty, rule,
                notification, direction, directive, guideline, requirement
                license, order, decree, judgment, or any restriction or
                condition, or any similar form of decision of , or determination
                application or execution by, or interpretation or pronouncement
                having the force of law of any governmental authority having
                jurisdiction over the matter in question, whether in effect as
                of the date of these terms of service or thereafter, in any
                jurisdiction or political sub-division, and includes any
                practice or custom under any applicable law
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                “<b>Intellectual Property</b>” means all copyright, patents,
                utility innovations, trademarks and service marks, geographical
                indications, domain names, layout design rights, registered
                designs, design rights, database rights, trade or business
                names, rights protecting trade secrets and confidential
                information, rights protecting goodwill and reputation, and all
                other similar or corresponding proprietary rights and all
                applications for the same, whether presently existing or created
                in the future, anywhere in the world, whether registered or not,
                and all benefits, privileges, rights to sue, recover damages and
                obtain relief or other remedies for any past, current or future
                infringement, misappropriation or violation of any of the
                foregoing rights.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                “<b>Losses</b>” means all penalties, losses, settlement sums,
                costs (including legal fees and expenses on a solicitor-client
                basis), charges, expenses, actions, proceedings, claims, demands
                and other liabilities, whether foreseeable or not.
              </p>
              <p class="myFont mb-5" style="font-size: 25px">
                “<b>Personal Data</b>” means data, whether true or not, that can
                be used to identify, contact or locate You. Personal Data can
                include Your name, e-mail address, billing address, shipping
                address, phone number and credit card information. “Personal
                Data” shall be deemed to include any data that You have provided
                to Us when placing a purchase, regardless of whether You have an
                account with Us.
              </p>
              <br />
              <p class="myFont py-4" style="font-size: 25px">
                “<b>Prohibited Material</b>” means any information, graphics,
                photographs, data and/or any other material that:
              </p>
              <ol type="i" class="ml-10 myFont mb-6" style="font-size: 25px">
                <li class="mb-5">
                  contains any computer virus or other invasive or damaging
                  code, program or macro;
                </li>
                <li class="mb-5">
                  infringes any third-party Intellectual Property or any other
                  proprietary rights;
                </li>
                <li class="mb-5">is defamatory, libelous or threatening;</li>
                <li class="mb-5">
                  is obscene, pornographic, indecent, counterfeited, fraudulent,
                  stolen, harmful or otherwise illegal under the applicable law;
                  and/or
                </li>
                <li class="mb-5">
                  is or may be construed as offensive and/or otherwise
                  objectionable, in Our sole opinion.
                </li>
              </ol>
              <p class="myFont mb-5" style="font-size: 25px">
                “<b>Trademarks</b>” means the trademarks, service marks, trade
                names and logos used and displayed on the Platform.
              </p>
              <h1 class="text-h5 font-weight-bold py-8">1.2 Interpretation</h1>
              <p class="myFont mb-5" style="font-size: 25px">
                Any reference in these Terms of Service to any provision of a
                statute shall be construed as a reference to that provision as
                amended, re-enacted or extended at the relevant time. In the
                Agreement, whenever the words “include”, “includes” or
                “including” are used, they will be deemed to be followed by the
                words “without limitation”. Unless expressly indicated
                otherwise, all references to a number of days mean calendar
                days, and the words “month” or “monthly” as well as all
                references to a number of month’s means calendar months. Clause
                headings are inserted for convenience only and shall not affect
                the interpretation of these Terms of Service. In the event of a
                conflict or inconsistency between any two or more provisions
                under these Terms of Service, whether such provisions are
                contained in the same or different documents, such conflict or
                inconsistency shall be resolved in favor of Us and the
                provision, which is more favorable to Us, shall prevail.
              </p>
            </li>
            <!-- End of Miscellaneous -->
          </ol>
        </v-col>
      </section>
    </v-main>
    <Foot></Foot>
  </section>
</template>

<script>
import Foot from "./home/foot.vue";
import axios from "axios";

export default {
  name: "Aboutus",
  components: {
    Foot,
  },

  data() {
    return {
      boards: [],
      grades: [],
      endGrand: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let boards = await axios.get(`/boards`);
      this.boards = boards.data.data;
      let grades = await axios.get(`/grades`);
      this.grades = grades.data.data;
      // this.endGrand = this.grades.length;
      this.isLoading = false;
    },
  },
};
// import axios from "axios";
// export default {
//   name: "AboutUs",
//   data() {
//     return {
//       form: {
//         tagline: "",
//         info: "",
//         info_1: "",
//         description: "",
//       },
//       isLoading: false,
//     };
//   },
//   mounted() {
//     this.getData();
//   },
//   methods: {
//     async getData() {
//       this.isLoading = true;
//       let form = await axios.get(`/about_us/1}`);
//       this.form = form.data.data;
//       this.isLoading = false;
//     },
//   },
// };

const { default: foot } = require("./home/foot.vue");
</script>
<style scoped>
.myFont {
  font-family: "SF PRO" !important;
}
</style>
