<template>
  <section style="background-color: #e5e5e5">
    <!-- Students -->

    <v-container id="students" v-if="studentsTrigger">
      <v-responsive class="title font-weight-light text-justify" v-if="image">
        <v-container class="mb-5">
          <v-row justify="center">
            <h3 class="font-weight-bold myFont myFont">For Students</h3>
          </v-row>
        </v-container>
      </v-responsive>
      <v-row justify="center">
        <v-col md="4">
          <v-scroll-x-transition>
            <center v-if="$vuetify.breakpoint.mdAndDown">
              <v-img
                v-show="image"
                height="330"
                width="330"
                :src="require('../../assets/advantage-student.png')"
              ></v-img>
            </center>
            <v-img
              v-else
              v-show="image"
              height="330"
              width="330"
              :src="require('../../assets/advantage-student.png')"
            ></v-img>
          </v-scroll-x-transition>
        </v-col>
        <v-col md="4" class="centerAlignContent">
          <v-slide-x-transition>
            <v-icon size="40" color="primary" v-show="ad1">mdi-brain</v-icon>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <v-card-title class="myFont" v-show="ad1">
              Strong Cognitive Development
            </v-card-title>
          </v-slide-x-transition>
          <br />
          <div>
            <v-slide-x-transition>
              <v-icon size="40" color="primary" v-show="ad2"
                >mdi-book-open-page-variant</v-icon
              >
            </v-slide-x-transition>
            <br />
            <v-slide-x-transition>
              <v-card-title class="myFont" v-show="ad2">
                Increased Reading Comprehension
              </v-card-title>
            </v-slide-x-transition>
          </div>
          <br />
          <div>
            <v-slide-x-transition>
              <v-icon size="40" color="primary" v-show="ad3"
                >mdi-globe-model</v-icon
              >
            </v-slide-x-transition>
            <br />
            <v-slide-x-transition>
              <v-card-title class="myFont" v-show="ad3">
                Globally Aware Citizens
              </v-card-title>
            </v-slide-x-transition>
          </div>
        </v-col>
        <v-col md="4" class="centerAlignContent">
          <div>
            <v-slide-x-transition>
              <v-icon size="40" color="primary" v-show="ad5">mdi-puzzle</v-icon>
            </v-slide-x-transition>
            <br />
            <v-slide-x-transition>
              <v-card-title class="myFont" v-show="ad5">
                Cross-Curricular Learning
              </v-card-title>
            </v-slide-x-transition>
          </div>
          <br />
          <div>
            <v-slide-x-transition>
              <v-icon size="40" color="primary" v-show="ad6"
                >mdi-format-letter-case</v-icon
              >
            </v-slide-x-transition>
            <br />
            <v-slide-x-transition>
              <v-card-title class="myFont" v-show="ad6">
                Advanced Vocabulary Prowess
              </v-card-title>
            </v-slide-x-transition>
          </div>
          <br />
          <div>
            <v-slide-x-transition>
              <v-icon size="40" color="primary" v-show="ad7"
                >mdi-pencil-ruler</v-icon
              >
            </v-slide-x-transition>
            <br />
            <v-slide-x-transition>
              <v-card-title class="myFont" v-show="ad7">
                Interactive & Engaging Learning
              </v-card-title>
            </v-slide-x-transition>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-slide-x-transition>
            <v-btn
              color="primary"
              rounded
              x-large
              dark
              v-show="viewMore"
              to="/benefits"
            >
              <span
                class="font-weight-bold myFont"
                style="color: white !important"
              >
                View More
              </span>
            </v-btn>
          </v-slide-x-transition>
        </v-col>
      </v-row>
      <v-icon size="25" color="primary" @click="handleScroll" class="mt-4"
        >mdi-checkbox-blank-circle</v-icon
      >&nbsp;
      <v-icon
        size="25"
        color="primary"
        @click="onclickhandleScroll"
        class="mt-4"
        >mdi-checkbox-blank-circle-outline</v-icon
      >
    </v-container>

    <!-- Teachers -->
    <div v-if="$vuetify.breakpoint.mdAndDown">
      <v-container id="teachers" v-if="teachersTrigger">
        <v-responsive class="title font-weight-light text-justify">
          <v-container>
            <v-row justify="center">
              <h3 class="font-weight-bold myFont myFont">For Teachers</h3>
            </v-row>
          </v-container>
        </v-responsive>
        <br />
        <v-row>
          <v-col md="4">
            <v-scroll-x-transition>
              <v-img
                height="288"
                width="378"
                :src="require('../../assets/advantage-teacher.png')"
              ></v-img>
            </v-scroll-x-transition>
          </v-col>
          <v-col md="4" class="centerAlignContent">
            <v-slide-x-transition>
              <v-icon size="40" color="primary" v-show="tad1"
                >mdi-newspaper</v-icon
              >
            </v-slide-x-transition>
            <v-slide-x-transition>
              <v-card-title class="myFont" v-show="tad1">
                Aligned to the New Education Policy 2020
              </v-card-title>
            </v-slide-x-transition>
            <br />
            <div>
              <v-slide-x-transition>
                <v-icon size="40" color="primary" v-show="tad2"
                  >mdi-face-man-shimmer</v-icon
                >
              </v-slide-x-transition>
              <br />
              <v-slide-x-transition>
                <v-card-title class="myFont" v-show="tad2">
                  Saves Time in Planning and Organizing Teaching Activities
                </v-card-title>
              </v-slide-x-transition>
            </div>
            <br />
            <div>
              <v-slide-x-transition>
                <v-icon size="40" color="primary" v-show="tad3"
                  >mdi-newspaper-variant-multiple-outline</v-icon
                >
              </v-slide-x-transition>
              <br />
              <v-slide-x-transition>
                <v-card-title class="myFont" v-show="tad3">
                  Plethora of Engaging and Relevant Content along with
                  Assessment Tools
                </v-card-title>
              </v-slide-x-transition>
            </div>
          </v-col>
          <v-col md="4" class="centerAlignContent">
            <div>
              <v-slide-x-transition>
                <v-icon size="40" color="primary" v-show="tad5"
                  >mdi-chart-areaspline-variant</v-icon
                >
              </v-slide-x-transition>
              <br />
              <v-slide-x-transition>
                <v-card-title class="myFont" v-show="tad5">
                  Mechanisms Designed to Measure Student Progress
                </v-card-title>
              </v-slide-x-transition>
            </div>
            <br />
            <div>
              <v-slide-x-transition>
                <v-icon size="40" color="primary" v-show="tad6"
                  >mdi-format-quote-close-outline</v-icon
                >
              </v-slide-x-transition>
              <br />
              <v-slide-x-transition>
                <v-card-title class="myFont" v-show="tad6">
                  Scope for Differentiated Instruction and Inclusive Learning
                </v-card-title>
              </v-slide-x-transition>
            </div>
            <br />
            <div>
              <v-slide-x-transition>
                <v-icon size="40" color="primary" v-show="tad7"
                  >mdi-clipboard-check</v-icon
                >
              </v-slide-x-transition>
              <br />
              <v-slide-x-transition>
                <v-card-title class="myFont" v-show="tad7">
                  Project Based Learning Assignments + Model Answers for all
                  Student Tasks
                </v-card-title>
              </v-slide-x-transition>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <v-slide-x-transition>
              <v-btn
                color="primary"
                rounded
                x-large
                dark
                to="/benefits"
                v-show="tviewMore"
              >
                <span
                  class="font-weight-bold myFont"
                  style="color: white !important"
                >
                  View More
                </span>
              </v-btn>
            </v-slide-x-transition>
          </v-col>
        </v-row>
        <v-icon size="25" color="primary" @click="handleScroll" class="mt-4"
          >mdi-checkbox-blank-circle-outline</v-icon
        >&nbsp;
        <v-icon
          size="25"
          color="primary"
          @click="onclickhandleScroll"
          class="mt-4"
          >mdi-checkbox-blank-circle</v-icon
        >
      </v-container>
    </div>
    <div v-else>
      <v-container id="teachers" v-if="teachersTrigger">
        <v-responsive class="title font-weight-light text-justify">
          <v-container>
            <v-row justify="center">
              <h3 class="font-weight-bold myFont myFont">For Teachers</h3>
            </v-row>
          </v-container>
        </v-responsive>
        <br />
        <v-row>
          <v-col md="4" class="centerAlignContent">
            <v-slide-x-transition>
              <v-icon size="40" color="primary" v-show="tad1"
                >mdi-newspaper</v-icon
              >
            </v-slide-x-transition>
            <v-slide-x-transition>
              <v-card-title class="myFont" v-show="tad1">
                Aligned to the New Education Policy 2020
              </v-card-title>
            </v-slide-x-transition>
            <br />
            <div>
              <v-slide-x-transition>
                <v-icon size="40" color="primary" v-show="tad2"
                  >mdi-face-man-shimmer</v-icon
                >
              </v-slide-x-transition>
              <br />
              <v-slide-x-transition>
                <v-card-title class="myFont" v-show="tad2">
                  Saves Time in Planning and Organizing Teaching Activities
                </v-card-title>
              </v-slide-x-transition>
            </div>
            <br />
            <div>
              <v-slide-x-transition>
                <v-icon size="40" color="primary" v-show="tad3"
                  >mdi-newspaper-variant-multiple-outline</v-icon
                >
              </v-slide-x-transition>
              <br />
              <v-slide-x-transition>
                <v-card-title class="myFont" v-show="tad3">
                  Plethora of Engaging and Relevant Content along with
                  Assessment Tools
                </v-card-title>
              </v-slide-x-transition>
            </div>
          </v-col>
          <v-col md="4" class="centerAlignContent">
            <div>
              <v-slide-x-transition>
                <v-icon size="40" color="primary" v-show="tad5"
                  >mdi-chart-areaspline-variant</v-icon
                >
              </v-slide-x-transition>
              <br />
              <v-slide-x-transition>
                <v-card-title class="myFont" v-show="tad5">
                  Mechanisms Designed to Measure Student Progress
                </v-card-title>
              </v-slide-x-transition>
            </div>
            <br />
            <div>
              <v-slide-x-transition>
                <v-icon size="40" color="primary" v-show="tad6"
                  >mdi-format-quote-close-outline</v-icon
                >
              </v-slide-x-transition>
              <br />
              <v-slide-x-transition>
                <v-card-title class="myFont" v-show="tad6">
                  Scope for Differentiated Instruction and Inclusive Learning
                </v-card-title>
              </v-slide-x-transition>
            </div>
            <br />
            <div>
              <v-slide-x-transition>
                <v-icon size="40" color="primary" v-show="tad7"
                  >mdi-clipboard-check</v-icon
                >
              </v-slide-x-transition>
              <br />
              <v-slide-x-transition>
                <v-card-title class="myFont" v-show="tad7">
                  Project Based Learning Assignments + Model Answers for all
                  Student Tasks
                </v-card-title>
              </v-slide-x-transition>
            </div>
          </v-col>
          <v-col md="4">
            <v-scroll-x-transition>
              <v-img
                height="288"
                width="378"
                :src="require('../../assets/advantage-teacher.png')"
              ></v-img>
            </v-scroll-x-transition>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <v-slide-x-transition>
              <v-btn
                color="primary"
                rounded
                x-large
                dark
                to="/benefits"
                v-show="tviewMore"
              >
                <span
                  class="font-weight-bold myFont"
                  style="color: white !important"
                >
                  View More
                </span>
              </v-btn>
            </v-slide-x-transition>
          </v-col>
        </v-row>
        <v-icon size="25" color="primary" @click="handleScroll" class="mt-4"
          >mdi-checkbox-blank-circle-outline</v-icon
        >&nbsp;
        <v-icon
          size="25"
          color="primary"
          @click="onclickhandleScroll"
          class="mt-4"
          >mdi-checkbox-blank-circle</v-icon
        >
      </v-container>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    studentsTrigger: true,
    teachersTrigger: false,
    image: false,
    ad1: false,
    ad2: false,
    ad3: false,
    ad4: false,
    ad5: false,
    ad6: false,
    ad7: false,
    ad8: false,
    viewMore: false,
    timage: false,
    tad1: false,
    tad2: false,
    tad3: false,
    tad4: false,
    tad5: false,
    tad6: false,
    tad7: false,
    tad8: false,
    tviewMore: false,
  }),
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    async handleScroll() {
      this.studentsTrigger = true;
      this.teachersTrigger = false;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.image = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.ad1 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.ad2 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.ad3 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.ad4 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.ad5 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.ad6 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.ad7 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.ad8 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.viewMore = true;
      window.removeEventListener("scroll", this.handleScroll);
    },
    async onclickhandleScroll() {
      window.removeEventListener("scroll", this.handleScroll);

      this.studentsTrigger = false;
      this.teachersTrigger = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.timage = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.tad1 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.tad2 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.tad3 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.tad4 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.tad5 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.tad6 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.tad7 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.tad8 = true;
      await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
      this.tviewMore = true;
    },
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
  },
};
</script>

<style scoped>
.myFont {
  font-family: "SF PRO" !important;
  color: black;
}
.centerAlignContent div {
  justify-content: center;
}
</style>
