<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-card-title> About Us </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-row>
                <v-col sm="4">
                  <p class="font-weight-bold">Tagline</p>
                  <v-text-field
                    v-model="form.tagline"
                    outlined
                    label="Tagline"
                    prepend-inner-icon="mdi-buffet"
                    :error-messages="errors.tagline"
                  ></v-text-field>
                </v-col>
                <v-col sm="12">
                  <p class="font-weight-bold">Info</p>
                  <froala
                    :tag="'textarea'"
                    :config="config"
                    v-model="form.info"
                  ></froala>
                </v-col>
                <v-col sm="12">
                  <p class="font-weight-bold">Info 1</p>
                  <v-text-field
                    v-model="form.info_1"
                    outlined
                    label="Info 1"
                    prepend-inner-icon="mdi-buffet"
                    :error-messages="errors.info_1"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="6">
              <v-col sm="12">
                <p class="font-weight-bold">Description</p>
                <froala
                  :tag="'textarea'"
                  :config="config"
                  v-model="form.description"
                ></froala>
              </v-col>
            </v-col>
          </v-row>

          <br />
          <loader v-if="isLoading"></loader>

          <v-btn v-else color="primary" dark @click="save">
            Update About us
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  name: "AboutUsUpdatePage",
  data() {
    return {
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      form: {
        tagline: "",
        info: "",
        info_1: "",
        description: "",
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/about_us/${this.$route.params.aboutusId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.patch(
          `/about_us/${this.$route.params.aboutusId}`,
          this.form
        );
        this.isLoading = false;
        this.$router.push(`/about-us/${this.$route.params.aboutusId}`);
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
