<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title style="min-width: 190px">
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit a new contact request
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="6" md="4" lg="4" style="min-width: fit-content">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name *"
                prepend-inner-icon="mdi-account"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="4" style="min-width: fit-content">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="4" style="min-width: fit-content">
              <v-text-field
                v-model="form.phone_no"
                outlined
                label="Phone"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>
            </v-col>
            <!-- </v-row>
          <v-row> -->
            <v-col sm="6" md="8" lg="8" style="min-width: fit-content">
              <v-text-field
                v-model="form.school_name"
                outlined
                label="Enter School name *"
                :error-messages="errors.school_name"
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="4" style="min-width: fit-content">
              <v-autocomplete
                ref="role"
                outlined
                v-model="form.role"
                :items="roleItems"
                label="Role *"
                :error-messages="errors.role"
              ></v-autocomplete>
            </v-col>
            <!-- </v-row>
          <v-row> -->
            <v-col sm="6" md="4" lg="4" style="min-width: fit-content">
              <v-text-field
                outlined
                v-model="form.city"
                label="Enter City *"
                :error-messages="errors.city"
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="4" style="min-width: fit-content">
              <v-text-field
                outlined
                v-model="form.state"
                label="Enter State *"
                :error-messages="errors.state"
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="4" style="min-width: fit-content">
              <v-text-field
                outlined
                v-model="form.pincode"
                label="Enter Pincode *"
                :error-messages="errors.pincode"
              ></v-text-field>
            </v-col>
            <!-- </v-row>
          <v-row> -->
            <v-col sm="6" md="4" lg="4" style="min-width: fit-content">
              <v-text-field
                v-model="form.interested_in"
                outlined
                label="Interested In"
                prepend-inner-icon="mdi-thumb-up"
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="4" style="min-width: fit-content">
              <v-text-field
                v-model="form.description"
                outlined
                label="Description"
                prepend-inner-icon="mdi-order-bool-descending"
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="4" style="min-width: fit-content">
              <v-text-field
                v-model="form.remarks"
                outlined
                label="Remarks"
                prepend-inner-icon="mdi-order-bool-descending-variant"
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="4" style="min-width: fit-content">
              <v-autocomplete
                ref="subject"
                v-model="form.status"
                :items="statuses"
                label="Select status"
                prepend-icon="mdi-list-status"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="update">
            Update Contact Request
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  name: "ContactRequestEditPage",
  data() {
    return {
      form: {
        name: "",
        status: "PENDING",
        is_active: true,
      },
      roleItems: [
        {
          id: 1,
          text: "Admin",
          value: "Admin",
        },
        {
          id: 2,
          text: "Teacher",
          value: "Teacher",
        },
        {
          id: 3,
          text: "Student",
          value: "Student",
        },
        {
          id: 4,
          text: "Parent",
          value: "Parent",
        },
      ],
      statuses: ["PENDING", "SETTLED"],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `/contact_requests/${this.$route.params.contactRequestId}`
      );
      this.form = form.data.data;
      this.isLoading = false;
    },
    async update() {
      try {
        this.isLoading = true;
        await axios.patch(
          `/contact_requests/${this.$route.params.contactRequestId}`,
          this.form
        );
        this.isLoading = false;
        this.$router.push("/contact-requests");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
