<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-btn text>Download sample file</v-btn>
        <v-card-title> Upload standards Data </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <input
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="application/ms-excel"
                multiple
              />
            </v-col>
            <v-col>
              <v-btn color="primary" to="/admin/standards/create"
                >Upload File</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <br />

      <v-card elevation="6">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Calories</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in desserts" :key="item.name">
                <td>{{ item.name }}</td>
                <td>{{ item.calories }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
        },
        {
          name: "Eclair",
          calories: 262,
        },
        {
          name: "Cupcake",
          calories: 305,
        },
        {
          name: "Gingerbread",
          calories: 356,
        },
        {
          name: "Jelly bean",
          calories: 375,
        },
        {
          name: "Lollipop",
          calories: 392,
        },
        {
          name: "Honeycomb",
          calories: 408,
        },
        {
          name: "Donut",
          calories: 452,
        },
        {
          name: "KitKat",
          calories: 518,
        },
      ],
    };
  },
};
</script>
