import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.purple.darken1, // #E53935
        // primary: "#0023F5", // #E53935
        secondary: colors.deepOrange.lighten4, // #E53935
        background: "#FFFFFF",
        // background: "#FCEFEF",
        // background: colors.blue.lighten5
      },
      options: {
        customProperties: true,
      },
    },
  },
});
