<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <v-btn class="mx-2" fab small color="primary" @click="$router.back()">
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          {{ form.title }}
          <v-spacer></v-spacer>
          <!-- <epaper-bookmark-dialog
            :epaper="form"
            :epaper_type="'Space'"
          ></epaper-bookmark-dialog> -->
        </v-card-title>
        <v-card-text>
          Published on :{{ form.published_date }}
          <br />
          By {{ form.creator }}
        </v-card-text>
        <v-card-text>
          <div
            v-html="form.content"
          ></div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import EpaperBookmarkDialog from "@/components/EpaperBookmarkDialog.vue";
export default {
  components: { EpaperBookmarkDialog },
  name: "SpaceArticleViewPage",
  data() {
    return {
      form: {
        name: "",
        is_active: true,
      },
      isLoading: false,
    };
  },
//   computed: {
//   formattedContent() {
//     // Assuming you want to add a class to all images
//     return this.form.content.replace(/<img/g, "<img class='custom-image-class'");
//   }
// }
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `/space_articles/${this.$route.params.spaceId}`
      );
      this.form = form.data.data;
      this.isLoading = false;
    },
  },
};
</script>
<style>
.inlinegallery-wrap {
  display:block !important;
}
img {
  max-width: -webkit-fill-available !important;
}
.vanilla-image-block {
  padding-top: 0 !important;
}
</style>
