<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 260px">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Assignment Wise Performance Report'"
          ></span>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="assignment"
            v-model="form.assignment_id"
            :items="assignmentItems"
            label="Assignment"
            clearable
            :error-messages="errors.assignment_id"
            prepend-icon="mdi-alarm-bell"
          ></v-autocomplete>
        </v-col>
        <v-col></v-col>
        <v-col class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <!--/ Filters Section -->
      <!-- Reports Section -->
      <loader v-if="isLoading"></loader>
      <div v-else>
        <v-col md="12">
          <v-card elevation="6" v-show="classcodeWiseStudents.length">
            <v-card-title style="min-width: 140px"> Classcodes </v-card-title>

            <v-card-text>
              <v-tabs
                v-if="classcodeWiseStudents"
                fixed-tabs
                background-color="primary"
                dark
                v-model="classcodeTab"
              >
                <v-tab
                  v-for="(classcode, cc) in classcodeWiseStudents"
                  :key="`classcode${cc}`"
                  :href="`#tab-${cc}`"
                >
                  {{ classcode.name }}
                </v-tab>

                <v-tabs-items v-model="classcodeTab">
                  <v-tab-item
                    v-for="(classcode, cc1) in classcodeWiseStudents"
                    :key="`classcode1${cc1}`"
                    :value="`tab-${cc1}`"
                  >
                    <v-simple-table fixed-header>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" style="min-width: 56px">
                              Sr No
                            </th>
                            <th class="text-center">Student Name</th>
                            <th class="text-center" style="min-width: 80px">
                              Score [%]
                            </th>
                            <th class="text-center" style="min-width: 90px">
                              Class Average [%]
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(student, tS) in classcode.students"
                            :key="`student${tS}`"
                          >
                            <td>{{ tS + 1 }}</td>
                            <td class="text-center">
                              <classcode-student
                                :classs="student"
                              ></classcode-student>
                            </td>
                            <td class="text-center">
                              {{ student.myAveragePercent | twoDec }} %
                            </td>
                            <td class="text-center">
                              {{ classcode.classcodeAveragePercent | twoDec }} %
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-card-text>
          </v-card>
        </v-col>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import classcodeStudent from "../dashboards/superadmin-dashboard/student_school_data/classcodeStudent.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "AssignmentWisePerformanceReport",
  components: { classcodeStudent, Loader },
  data() {
    return {
      form: {
        assignment_id: "",
      },
      isLoading: false,
      assignments: [],
      assignmentItems: [],
      classcodeTab: "",
      classcodeWiseStudents: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Email: "email",
        Gender: "gender",
        "No of Assignment Given": "totalAssignmentsCount",
        "No of Assignment Completed": "completed_count",
      },
      report_excel: [],
      title: "Assignment Wise Performance Report",
    };
  },
  mounted() {
    this.form.standard_id = this.$route.query.standard_id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      this.isLoading = true;
      console.log(this.company.id);
      let query = "company_id=" + this.company.id;
      let assignments = await axios.get(`assignments?${query}`);
      this.assignments = assignments.data.data;
      this.assignments.forEach((assignment) => {
        this.assignmentItems.push({
          id: assignment.id,
          text: assignment.assignment_title,
          value: assignment.id,
        });
      });
      this.isLoading = false;
    },
    async getData() {
      this.isLoading = true;
      try {
        let query = "assignment_id=" + this.form.assignment_id;
        let classcodeWiseStudents = await axios.get(
          `/assignment_wise_performance?${query}`
        );
        this.classcodeWiseStudents = classcodeWiseStudents.data.data;
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
