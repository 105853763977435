var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"white",attrs:{"id":"hero"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{class:{
        'mt-10 mx-6': _vm.$vuetify.breakpoint.mdAndUp,
        'ml-1': _vm.$vuetify.breakpoint.smAndDown,
      }},[_c('v-row',{class:{
          'ml-2': _vm.$vuetify.breakpoint.smAndDown,
          'mt-10 mx-6': _vm.$vuetify.breakpoint.mdAndUp,
          'ml-3': _vm.$vuetify.breakpoint.mdAndDown,
        },attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',[_vm._v(" Photo")]):_vm._e(),_c('v-col',{class:{
            'my-16': _vm.$vuetify.breakpoint.smAndDown,
            'my-16': _vm.$vuetify.breakpoint.mdAndUp,
            'mt-14': _vm.$vuetify.breakpoint.mdAndDown,
          },attrs:{"md":"5"}},[_c('h1',{class:{
              'text-h5 myFont font-weight-bold':
                _vm.$vuetify.breakpoint.smAndDown,
              'text-h4 myFont font-weight-bold mb-3':
                _vm.$vuetify.breakpoint.mdAndUp,
            }},[_vm._v(" Learning Management System to Smoothly CREATE, MANAGE & GROW Your Business ")]),_c('h1',{class:{
              'text-h4 myFont font-weight-bold':
                _vm.$vuetify.breakpoint.smAndDown,
              'text-h3 myFont font-weight-bold mb-3':
                _vm.$vuetify.breakpoint.mdAndUp,
            }}),_c('p',{class:{
              'text-h6 myFont font-weight-bold text-darken-1 mt-5':
                _vm.$vuetify.breakpoint.smAndDown,
              'text-h6 myFont font-weight-bold text-darken-1 mt-5':
                _vm.$vuetify.breakpoint.mdAndUp,
              'subtitle-2 myFont font-weight-bold  mt-3':
                _vm.$vuetify.breakpoint.mdAndDown,
            }},[_vm._v(" Your Corporate Training Just Got More Comfortable With the Pousse LMS platform, your employees can now learn anywhere, anytime, and on any device ")]),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.heroTrigger),expression:"heroTrigger"}],staticClass:"rounded-xl mt-4",attrs:{"color":"primary","dark":"","to":"/about-us"}},[_c('span',{staticClass:"font-weight-bold myFont",staticStyle:{"color":"white !important"}},[_vm._v(" View More ")])]):_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.heroTrigger),expression:"heroTrigger"}],staticClass:"rounded-xl mt-8",attrs:{"color":"primary","dark":"","large":"","to":"/about-us"}},[_c('span',{staticClass:"font-weight-bold myFont",staticStyle:{"color":"white !important"}},[_vm._v(" View More ")])])],1)],1)],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('br'):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('br'):_vm._e(),_c('br'),_c('br'),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }