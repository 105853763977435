<template>
  <div>
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: "VueTextAnnotation",
  props: ["referenceText", "annotations"],
  data() {
    return {
      selected: "",
      beforeSelectedText: null,
      afterSelectedText: null,
      text: "",
      x: 0,
      y: 0,
      highlighted: "",
    };
  },
  computed: {
    highlightableEl() {
      return this.$slots.default[0].elm;
    },
  },
  mounted() {
    window.addEventListener("mouseup", this.onMouseup);
    window.addEventListener("touchend", this.onMouseup, true);
  },
  methods: {
    test() {
      this.highlighted = this.highlight();
    },
    strip(html) {
      var doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    },
    highlight() {
      if (this.selected) {
        let text = this.strip(this.selected);

        this.$emit("highlighted", {
          selected: text,
          isExisting: false,
          beforeSelectedText:
            this.beforeSelectedText.length == 1
              ? null
              : this.beforeSelectedText,
          afterSelectedText:
            this.afterSelectedText.length == 1 ? null : this.afterSelectedText,
          // isReference: this.referenceText,
        });
      }
    },
    onMouseup() {
      try {
        const selection = window.getSelection();
        const selectionRange = selection.getRangeAt(0);

        // console.log(selection.ht);
        // console.log(selectionRange.startContainer[endOffset]);
        if (selection.toString()) {
          var para = selectionRange.startContainer.wholeText;
          console.log(para);
          var startOffset = selectionRange.startOffset;
          var endOffset = selectionRange.endOffset - 1;

          var beforeOffsetText = null;
          var afterOffsetText = null;
          for (var i = 5; i >= 1; i--) {
            if (para[startOffset - i])
              if (!beforeOffsetText) beforeOffsetText = para[startOffset - i];
              else beforeOffsetText = beforeOffsetText + para[startOffset - i];
          }
          for (var i = 1; i <= 5; i++) {
            if (para[endOffset + i])
              if (!afterOffsetText) afterOffsetText = para[endOffset + i];
              else afterOffsetText = afterOffsetText + para[endOffset + i];
          }
          this.beforeSelectedText = beforeOffsetText;
          this.afterSelectedText = afterOffsetText;
          console.log(this.beforeSelectedText);
          console.log(this.afterSelectedText.length);
          if (this.afterSelectedText == " ") console.log("1223");
          // console.log(beforeOffsetText);
          // console.log(para[startOffset]);
          // console.log(para[endOffset]);
          // console.log(afterOffsetText);
        }

        // startNode is the element that the selection starts in
        const startNode = selectionRange.startContainer.parentNode;
        // endNode is the element that the selection ends in
        const endNode = selectionRange.endContainer.parentNode;

        // console.log(startNode)
        // console.log(this.highlightableEl)
        // console.log(endNode)
        // console.log(startNode.isSameNode(this.highlightableEl))
        // console.log(startNode.isSameNode(endNode))

        // if the selected text is not part of the highlightableEl (i.e. <p>)
        // OR
        // if startNode !== endNode (i.e. the user selected multiple paragraphs)
        // Then
        // Don't show the menu (this selection is invalid)
        // if (
        //   !startNode.isSameNode(this.highlightableEl) ||
        //   !startNode.isSameNode(endNode)
        // ) {
        //   this.showMenu = false;
        //   if (selection.toString()) {
        //     this.$emit("highlighted", {
        //       selected: selection.toString(),
        //       isExisting: true,
        //     });
        //   }
        //   return;
        // }
        // Get the x, y, and width of the selection
        const { x, y, width } = selectionRange.getBoundingClientRect();
        // If width === 0 (i.e. no selection)
        // Then, hide the menu
        if (!width) {
          // this.showMenu = false;
          return;
        }
        this.x = x; // + width / 2;
        this.y = y; // + window.scrollY - 10;
        this.selected = selection.toString();
        this.highlight();
      } finally {
        return;
      }
    },
  },
};
</script>

<style scoped>
#isPasted {
  font-size: 16px !important;
}
</style>