<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Add a manual
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="4">
              <v-autocomplete
                ref="role"
                v-model="form.role"
                :items="roleItems"
                label="Role"
                prepend-icon="mdi-gender-male-female"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                outlined
                v-model="form.title"
                label="Title *"
                prepend-inner-icon="mdi-shape"
                :error-messages="errors.title"
              ></v-text-field>
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="form.description"
              ></froala>
            </v-col>
          </v-row>
          <br />
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save"> Save Manual </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "ManualCreatePage",
  components: { backbutton, Loader },
  data() {
    return {
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      form: {
        title: "",
        description: "",
        role: "",
      },
      isLoading: false,
      roleItems: [
        {
          text: "ADMIN",
          value: "ADMIN",
        },
        {
          text: "TEACHER",
          value: "TEACHER",
        },
        {
          text: "STUDENT",
          value: "STUDENT",
        },
      ],
    };
  },
  mounted() {
    this.form.role = this.$route.query.role;
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios.post(`/manuals`, this.form);
        this.isLoading = false;
        this.$router.push(`/manuals?role=${this.form.role}`);
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
