<template>
  <div>
    <v-app-bar
      app
      fixed
      color="white"
      v-if="$vuetify.breakpoint.mdAndDown"
      height="60"
    >
      <v-app-bar-nav-icon
        @click="scrollToSection('#logo')"
        @click.stop="m_drawer = !m_drawer"
      ></v-app-bar-nav-icon>
      <router-link :to="home_link">
        <v-img
          alt="P-LMS"
          cover
          :src="require('../../assets/logo1.png')"
          height="100"
        />
      </router-link>
    </v-app-bar>
    <section
      v-if="
        $route.name == 'ContentsV2Page' ||
        $route.name == 'ContentCreateV2Page' ||
        $route.name == 'ContentCreateV3Page'
      "
    >
      <v-navigation-drawer
        app
        class="dont-print-header"
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        expand-on-hover
      >
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <router-link :to="home_link">
                <v-img
                  alt="Vuetify Name"
                  class="shrink mt-1"
                  contain
                  min-width="100"
                  :src="require('../../assets/logo-i.png')"
                  width="25"
                  height="35"
              /></router-link>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{
                    user.first_name != null
                      ? user.first_name + " " + user.last_name
                      : user.email
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  roleName == "ACADEMIC TEAM"
                    ? "DTP HEAD"
                    : roleName == "INFAKT TEACHER"
                    ? "DTP"
                    : roleName == "STUDENT"
                    ? "TSI"
                    : roleName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <v-list-item to="/notifications" @click="hide_bell = true">
              <v-list-item-title>Notifications</v-list-item-title>
            </v-list-item>
            <v-list-item to="/profile" @click="hide_bell = true">
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="company.id != null" to="/get-started">
              <v-list-item-title>Help</v-list-item-title>
            </v-list-item>
            <v-list-item to="/reset-password">
              <v-list-item-title>Reset Password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
          <!-- <v-list-item :to="`/contents/index-v2`">
            <v-list-item-icon>
              <v-icon>mdi-content-cut</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contents</v-list-item-title>
          </v-list-item> -->
          <!-- <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-new-box </v-icon>
              </v-list-item-icon>
              <v-list-item-title>New Layout</v-list-item-title>
            </template>
            <v-list-item to="/contents/index-v2">
              <v-list-item-title>List</v-list-item-title>
            </v-list-item>
            <v-list-item to="/contents/create-v2">
              <v-list-item-title>Upload</v-list-item-title>
            </v-list-item>
            <v-list-item to="/publication-report-v2">
              <v-list-item-title>Publication Report</v-list-item-title>
            </v-list-item>
            <v-list-item to="/assignments/create-v2">
              <v-list-item-title>Assignment create</v-list-item-title>
            </v-list-item>
          </v-list-group> -->
          <!-- <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-newspaper-variant-multiple-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Epaper</v-list-item-title>
            </template>
            <v-list-item to="/epaper/toi">
              <v-list-item-title>TOI</v-list-item-title>
            </v-list-item>
            <v-list-item to="/epaper/et">
              <v-list-item-title>ET</v-list-item-title>
            </v-list-item>
            <v-list-item to="/epaper/space">
              <v-list-item-title>Space</v-list-item-title>
            </v-list-item>
            <v-list-item to="/epaper/live-science">
              <v-list-item-title>Live Science</v-list-item-title>
            </v-list-item>
          </v-list-group> -->
          <v-list-item :to="'/assignments'">
            <v-list-item-icon>
              <v-icon>mdi-clipboard-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Assignments</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              roleName == 'TEACHER' ||
              roleName == 'STUDENT' ||
              roleName == 'ACADEMIC TEAM' ||
              roleName == 'INFAKT TEACHER'
            "
            to="/library"
          >
            <v-list-item-icon>
              <v-icon>mdi-bookmark-box-multiple-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              roleName == "ACADEMIC TEAM" || roleName == "INFAKT TEACHER"
                ? "Archive"
                : "Library"
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              roleName == 'TEACHER' ||
              roleName == 'STUDENT' ||
              roleName == 'ACADEMIC TEAM' ||
              roleName == 'INFAKT TEACHER'
            "
            to="/library"
          >
            <v-list-item-icon>
              <v-icon>mdi-library-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>E-library</v-list-item-title>
          </v-list-item>
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-chart-bar </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Reports</v-list-item-title>
            </template>
            <v-list-item
              to="/reports/class-wise-performance-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title
                >Class Wise Performance Report</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              to="/reports/student-wise-report"
              v-if="
                roleName == 'ADMIN' ||
                roleName == 'TEACHER' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Student Wise Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/section-wise-report"
              v-if="roleName == 'TEACHER'"
            >
              <v-list-item-title>Section Wise Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/teacher-performance-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Teacher Performance Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/subject-overview-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Subject Overview Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/assignment-wise-performance-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title
                >Assignment Wise Performance Report</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              to="/reports/school-wise-performance-report"
              v-if="roleName == 'SUPER ADMIN' && company.id == null"
            >
              <v-list-item-title>School Wise Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/subject-wise-performance-report"
              v-if="roleName == 'SUPER ADMIN' && company.id == null"
            >
              <v-list-item-title>Subject Wise Task Overview </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/learning-outcome-report"
              v-if="
                roleName == 'ACADEMIC TEAM' ||
                // roleName == 'TEACHER' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Learning Outcome Report </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/publication-report"
              v-if="
                roleName == 'ACADEMIC TEAM' ||
                roleName == 'INFAKT TEACHER' ||
                roleName == 'SUPER ADMIN'
              "
            >
              <v-list-item-title>Publication Report </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/dashboard/user-timestamp"
              v-if="
                roleName == 'ACADEMIC TEAM' ||
                (roleName == 'SUPER ADMIN' && company.id == null)
              "
            >
              <v-list-item-title>User Timestamp Report </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </section>
    <section v-else>
      <v-navigation-drawer
        app
        v-if="$vuetify.breakpoint.mdAndDown"
        v-model="m_drawer"
        absolute
        class="dont-print-header"
        left
        temporary
      >
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <router-link :to="home_link">
                <v-img
                  id="logo"
                  alt="Vuetify Name"
                  class="shrink mt-1"
                  contain
                  min-width="100"
                  :src="require('../../assets/logo-i.png')"
                  width="25"
                  height="35"
              /></router-link>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{
                    user.first_name != null
                      ? user.first_name + " " + user.last_name
                      : user.email
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    roleName == "ACADEMIC TEAM"
                      ? "DTP HEAD"
                      : roleName == "INFAKT TEACHER"
                      ? "DTP"
                      : roleName == "STUDENT"
                      ? "TSI"
                      : roleName
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
            <v-list-item to="/notifications" @click="hide_bell = true">
              <v-list-item-title>Notifications</v-list-item-title>
            </v-list-item>
            <v-list-item to="/profile" @click="hide_bell = true">
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="company.id != null" to="/get-started">
              <v-list-item-title>Help</v-list-item-title>
            </v-list-item>
            <v-list-item to="/reset-password">
              <v-list-item-title>Reset Password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense>
          <!-- New Layouts -->
          <v-list-group no-action v-if="roleName == 'INFAKT TEACHER'">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-new-box </v-icon>
              </v-list-item-icon>
              <v-list-item-title>New Layout</v-list-item-title>
            </template>
            <v-list-item to="/contents/index-v2">
              <v-list-item-title>List</v-list-item-title>
            </v-list-item>
            <v-list-item to="/contents/create-v2">
              <v-list-item-title>Upload</v-list-item-title>
            </v-list-item>
            <v-list-item to="/publication-report-v2">
              <v-list-item-title>Publication Report</v-list-item-title>
            </v-list-item>
            <v-list-item to="/assignments/create-v2">
              <v-list-item-title>Assignment create</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <!-- School -->
          <v-list-item
            :to="`/Companies`"
            v-if="roleName == 'SUPER ADMIN' && company.id == null"
          >
            <v-list-item-icon>
              <v-icon>mdi-content-cut</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Schools</v-list-item-title>
          </v-list-item>
          <!-- Users -->
          <v-list-item
            :to="`/admin/users`"
            v-if="roleName == 'SUPER ADMIN' && company.id == null"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item>
          <!-- Requests -->
          <!-- <v-list-group
            no-action
            v-if="roleName == 'SUPER ADMIN' && company.id == null"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-newspaper-variant-multiple-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Requests</v-list-item-title>
            </template>
            <v-list-item to="/career-requests">
              <v-list-item-title>Career</v-list-item-title>
            </v-list-item>
            <v-list-item to="/contact-requests">
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
          </v-list-group> -->
          <!-- Masters -->
          <v-list-group
            no-action
            v-if="roleName == 'SUPER ADMIN' && company.id == null"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-newspaper-variant-multiple-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Masters</v-list-item-title>
            </template>
            <v-list-item to="/boards">
              <v-list-item-title>Boards</v-list-item-title>
            </v-list-item>
            <v-list-item to="/categories">
              <v-list-item-title>Categories</v-list-item-title>
            </v-list-item>
            <v-list-item to="/subjects">
              <v-list-item-title>Subjects</v-list-item-title>
            </v-list-item>
            <v-list-item to="/grades">
              <v-list-item-title>Grades</v-list-item-title>
            </v-list-item>
            <v-list-item to="/about-us/1">
              <v-list-item-title>About Us</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <!-- Content -->
          <v-list-item
            :to="`/contents`"
            v-if="
              roleName == 'INFAKT TEACHER' ||
              roleName == 'ACADEMIC TEAM' ||
              (roleName == 'SUPER ADMIN' && company.id == null)
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-content-cut</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contents</v-list-item-title>
          </v-list-item>
          <!-- Epaper -->
          <v-list-group no-action v-if="roleName == 'INFAKT TEACHER'">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-newspaper-variant-multiple-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Epaper</v-list-item-title>
            </template>
            <v-list-item to="/epaper/toi">
              <v-list-item-title>TOI</v-list-item-title>
            </v-list-item>
            <v-list-item to="/epaper/et">
              <v-list-item-title>ET</v-list-item-title>
            </v-list-item>
            <v-list-item to="/epaper/space">
              <v-list-item-title>Space</v-list-item-title>
            </v-list-item>
            <v-list-item to="/epaper/live-science">
              <v-list-item-title>Live Science</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <!-- Standard -->
          <v-list-item
            :to="`/standards`"
            v-if="
              roleName == 'ADMIN' ||
              (roleName == 'SUPER ADMIN' && company.id != null)
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-content-cut</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Standards</v-list-item-title>
          </v-list-item>
          <!-- Browse -->
          <v-list-item
            :to="`/articles`"
            v-if="
              roleName == 'ADMIN' ||
              roleName == 'TEACHER' ||
              roleName == 'STUDENT' ||
              (roleName == 'SUPER ADMIN' && company.id != null)
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-content-cut</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Browse</v-list-item-title>
          </v-list-item>
          <!-- Classes -->
          <v-list-item
            :to="`/teacher_classes/${user.id}`"
            v-if="roleName == 'TEACHER'"
          >
            <v-list-item-icon>
              <v-icon>mdi-clipboard-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Classes</v-list-item-title>
          </v-list-item>
          <!-- Teachers -->
          <v-list-item
            to="/teachers"
            v-if="
              roleName == 'ADMIN' ||
              (roleName == 'SUPER ADMIN' && company.id != null)
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-clipboard-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Coordinators</v-list-item-title>
          </v-list-item>
          <!-- Students -->
          <v-list-item
            to="/students"
            v-if="
              roleName == 'ADMIN' ||
              (roleName == 'SUPER ADMIN' && company.id != null)
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-clipboard-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Students</v-list-item-title>
          </v-list-item>
          <!-- Assignments -->
          <v-list-item
            :to="'/assignments'"
            v-if="
              roleName == 'TEACHER' ||
              roleName == 'STUDENT' ||
              roleName == 'INFAKT TEACHER' ||
              roleName == 'ACADEMIC TEAM'
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-clipboard-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Assignments</v-list-item-title>
          </v-list-item>
          <!-- Library -->
          <v-list-item
            v-if="
              roleName == 'TEACHER' ||
              roleName == 'STUDENT' ||
              roleName == 'ACADEMIC TEAM' ||
              roleName == 'INFAKT TEACHER'
            "
            to="/library"
          >
            <v-list-item-icon>
              <v-icon>mdi-bookmark-box-multiple-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              roleName == "ACADEMIC TEAM" || roleName == "INFAKT TEACHER"
                ? "Archive"
                : "Library"
            }}</v-list-item-title>
          </v-list-item>
          <!-- E-library -->
          <v-list-item v-if="roleName == 'INFAKT TEACHER'" to="/epaper-library">
            <v-list-item-icon>
              <v-icon>mdi-library-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>E-library</v-list-item-title>
          </v-list-item>
          <!-- EPaper Dashboard -->
          <v-list-item
            v-if="roleName == 'SUPER ADMIN' && company.id == null"
            to="/epaper/dashboard"
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Toi / ET Dashboards</v-list-item-title>
          </v-list-item>
          <!-- Help -->
          <v-list-item
            v-if="
              roleName == 'ACADEMIC TEAM' ||
              (roleName == 'SUPER ADMIN' && company.id == null)
            "
            to="/manuals"
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Help</v-list-item-title>
          </v-list-item>
          <!-- Blog -->
          <v-list-item
            v-if="
              roleName == 'ACADEMIC TEAM' ||
              (roleName == 'SUPER ADMIN' && company.id == null)
            "
            to="/blogs"
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Blog</v-list-item-title>
          </v-list-item>
          <!-- Courses -->
          <v-list-item
            v-if="
              roleName == 'ACADEMIC TEAM' ||
              roleName == 'INFAKT TEACHER' ||
              (roleName == 'SUPER ADMIN' && company.id == null)
            "
            to="/courses"
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Courses</v-list-item-title>
          </v-list-item>
          <!-- User -->
          <v-list-item
            v-if="roleName == 'ACADEMIC TEAM'"
            to="/academic-team/users"
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>User</v-list-item-title>
          </v-list-item>
          <!-- Tickets -->
          <v-list-item
            v-if="roleName == 'ACADEMIC TEAM'"
            to="/dashboards/ticket"
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tickets</v-list-item-title>
          </v-list-item>
          <!-- Reports -->
          <v-list-group
            no-action
            v-if="
              roleName == 'ADMIN' ||
              roleName == 'TEACHER' ||
              roleName == 'SUPER ADMIN' ||
              roleName == 'INFAKT TEACHER' ||
              roleName == 'ACADEMIC TEAM'
            "
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-chart-bar </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Reports</v-list-item-title>
            </template>
            <v-list-item
              to="/reports/class-wise-performance-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title
                >Class Wise Performance Report</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              to="/reports/student-wise-report"
              v-if="
                roleName == 'ADMIN' ||
                roleName == 'TEACHER' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Student Wise Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/section-wise-report"
              v-if="roleName == 'TEACHER'"
            >
              <v-list-item-title>Section Wise Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/teacher-performance-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Teacher Performance Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/subject-overview-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Subject Overview Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/assignment-wise-performance-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title
                >Assignment Wise Performance Report</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              to="/reports/school-wise-performance-report"
              v-if="roleName == 'SUPER ADMIN' && company.id == null"
            >
              <v-list-item-title>School Wise Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/subject-wise-performance-report"
              v-if="roleName == 'SUPER ADMIN' && company.id == null"
            >
              <v-list-item-title>Subject Wise Task Overview </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/learning-outcome-report"
              v-if="
                roleName == 'ACADEMIC TEAM' ||
                // roleName == 'TEACHER' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Learning Outcome Report </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/publication-report"
              v-if="
                roleName == 'ACADEMIC TEAM' ||
                roleName == 'INFAKT TEACHER' ||
                roleName == 'SUPER ADMIN'
              "
            >
              <v-list-item-title>Publication Report </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/dashboard/user-timestamp"
              v-if="
                roleName == 'ACADEMIC TEAM' ||
                (roleName == 'SUPER ADMIN' && company.id == null)
              "
            >
              <v-list-item-title>User Timestamp Report </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar
        v-else
        app
        color="white"
        class="dont-print-header"
        style="overflow-x: auto; overflow-y: hidden"
      >
        <div class="d-flex align-center">
          <router-link :to="home_link">
            <v-img
              alt="Vuetify Name"
              class="shrink mt-1"
              contain
              min-width="70"
              :src="require('../../assets/logo.png')"
              width="70"
          /></router-link>
        </div>
        &nbsp;
        <span class="mt-7" style="font-size: 12px; min-width: 165px">
          Hey,
          {{
            user.first_name != null
              ? user.first_name + " " + user.last_name
              : user.email
          }}
          [<span style="font-size: 10px">{{
            roleName == "ACADEMIC TEAM"
              ? "DTP HEAD"
              : roleName == "INFAKT TEACHER"
              ? "DTP"
              : roleName == "ADMIN"
              ? "MANAGER"
              : roleName == "TEACHER"
              ? "COORDINATOR"
              : roleName == "STUDENT"
              ? "TSI"
              : "SUPER ADMIN"
          }}</span
          >]
        </span>
        <v-spacer></v-spacer>
        <template v-if="roleName == 'SUPER ADMIN'">
          <SelectCompanyComponent class="pt-10"></SelectCompanyComponent>
        </template>
        <template
          v-if="
            roleName == 'ADMIN' ||
            (roleName == 'SUPER ADMIN' && company.id != null)
          "
        >
          <SelectBoardComponent class="pt-10"></SelectBoardComponent>
        </template>
        <v-menu offset-y v-if="roleName == 'INFAKT TEACHER'">
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              text
              v-bind="attrs"
              v-on="on"
              style="text-transform: initial !important"
            >
              New Layout
            </v-btn>
          </template> -->
          <v-list>
            <v-list-item to="/contents/index-v2">
              <v-list-item-title>List</v-list-item-title>
            </v-list-item>
            <v-list-item to="/contents/create-v2">
              <v-list-item-title>Upload</v-list-item-title>
            </v-list-item>
            <v-list-item to="/publication-report-v2">
              <v-list-item-title>Publication Report</v-list-item-title>
            </v-list-item>
            <v-list-item to="/assignments/create-v2">
              <v-list-item-title>Assignment create</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          color="black"
          text
          to="/schools"
          v-if="roleName == 'SUPER ADMIN' && company.id == null"
        >
          Companies
        </v-btn>
        <v-btn
          color="black"
          text
          to="/admin/users"
          v-if="roleName == 'SUPER ADMIN' && company.id == null"
        >
          Users
        </v-btn>
        <!-- <v-menu offset-y v-if="roleName == 'SUPER ADMIN' && company.id == null">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              text
              v-bind="attrs"
              v-on="on"
              style="text-transform: initial !important"
            >
              Requests
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/career-requests">
              <v-list-item-title>Career</v-list-item-title>
            </v-list-item>
            <v-list-item to="/contact-requests">
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <v-menu offset-y v-if="roleName == 'SUPER ADMIN' && company.id == null">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              text
              v-bind="attrs"
              v-on="on"
              style="text-transform: initial !important"
            >
              Masters
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/boards">
              <v-list-item-title>Locations</v-list-item-title>
            </v-list-item>
            <v-list-item to="/categories">
              <v-list-item-title>Categories</v-list-item-title>
            </v-list-item>
            <v-list-item to="/subjects">
              <v-list-item-title>Subjects</v-list-item-title>
            </v-list-item>
            <v-list-item to="/grades">
              <v-list-item-title>Departments</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item to="/about-us/1">
              <v-list-item-title>About Us</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
        <v-btn
          color="black"
          text
          to="/contents"
          v-if="
            roleName == 'INFAKT TEACHER' ||
            roleName == 'ACADEMIC TEAM' ||
            (roleName == 'SUPER ADMIN' && company.id == null)
          "
        >
          Contents
        </v-btn>
        <v-menu
          offset-y
          v-if="
            roleName == 'INFAKT TEACHER' ||
            (roleName == 'SUPER ADMIN' && company.id == null)
          "
        >
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              text
              v-bind="attrs"
              v-on="on"
              style="text-transform: initial !important"
            >
              EPaper
            </v-btn>
          </template> -->
          <!-- <v-list>
            <v-list-item to="/epaper/toi">
              <v-list-item-title>TOI</v-list-item-title>
            </v-list-item>
            <v-list-item to="/epaper/et">
              <v-list-item-title>ET</v-list-item-title>
            </v-list-item>
            <v-list-item to="/epaper/space">
              <v-list-item-title>Space</v-list-item-title>
            </v-list-item>
            <v-list-item to="/epaper/live-science">
              <v-list-item-title>Live Science</v-list-item-title>
            </v-list-item>
          </v-list> -->
        </v-menu>
        <v-btn
          color="black"
          text
          to="/standards"
          v-if="
            roleName == 'ADMIN' ||
            (roleName == 'SUPER ADMIN' && company.id != null)
          "
        >
          Designations
        </v-btn>
        <v-btn
          v-if="
            roleName == 'ADMIN' ||
            roleName == 'TEACHER' ||
            roleName == 'STUDENT' ||
            (roleName == 'SUPER ADMIN' && company.id != null)
          "
          color="black"
          text
          to="/articles"
          style="text-transform: initial !important"
        >
          Browse
        </v-btn>
        <!-- <v-menu
              offset-y
              v-if="
                roleName == 'ADMIN' ||
                roleName == 'TEACHER' ||
                roleName == 'STUDENT' ||
                // roleName == 'INFAKT TEACHER' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="black"
                  text
                  v-bind="attrs"
                  style="text-transform: initial !important"
                  v-on="on"
                >
                  Browse
                </v-btn>
              </template>
              <v-list>
                <v-list-item to="/articles?type=ARTICLE">
                  <v-list-item-title>Articles</v-list-item-title>
                </v-list-item>
                <v-list-item to="/articles?type=INFOGRAPHIC">
                  <v-list-item-title>Infographics</v-list-item-title>
                </v-list-item>
                <v-list-item to="/articles?type=VIDEO">
                  <v-list-item-title>Videos</v-list-item-title>
                </v-list-item>
                <v-list-item to="/infakt-collections">
                  <v-list-item-title>Infakt Collections</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
        <v-btn
          color="black"
          text
          :to="`/teacher_classes/${user.id}`"
          v-if="roleName == 'TEACHER'"
        >
          Classes
        </v-btn>
        <v-btn
          color="black"
          text
          to="/teachers"
          v-if="
            roleName == 'ADMIN' ||
            (roleName == 'SUPER ADMIN' && company.id != null)
          "
        >
          Coordinators
        </v-btn>
        <v-btn
          color="black"
          text
          to="/students"
          v-if="
            roleName == 'ADMIN' ||
            (roleName == 'SUPER ADMIN' && company.id != null)
          "
        >
          TSIs
        </v-btn>
        <v-btn
          color="black"
          text
          to="/assignments"
          v-if="
            roleName == 'TEACHER' ||
            roleName == 'STUDENT' ||
            roleName == 'INFAKT TEACHER' ||
            roleName == 'ACADEMIC TEAM'
          "
        >
          Assignments
        </v-btn>
        <!-- <v-btn
              color="black"
              text
              to="/quiz-assignments"
              v-if="roleName == 'STUDENT'"
            >
              Powerword Quiz
            </v-btn> -->
        <!-- <v-btn color="black" text to="/quiz-assignments" v-if="roleName == 'STUDENT'">
              Powerword Quiz
            </v-btn>
            <v-btn
              color="black"
              text
              to="/competitions?type=competition"
              v-if="
                roleName == 'ADMIN' ||
                roleName == 'TEACHER' ||
                roleName == 'STUDENT' ||
                roleName == 'INFAKT TEACHER' ||
                roleName == 'ACADEMIC TEAM'
              "
            >
            Competitions
          </v-btn> -->
        <v-btn
          color="black"
          text
          v-if="
            roleName == 'TEACHER' ||
            roleName == 'STUDENT' ||
            roleName == 'ACADEMIC TEAM' ||
            roleName == 'INFAKT TEACHER'
          "
          to="/library"
        >
          {{
            roleName == "ACADEMIC TEAM" || roleName == "INFAKT TEACHER"
              ? "Archive"
              : "Library"
          }}
        </v-btn>
        <v-btn
          color="black"
          text
          v-if="roleName == 'INFAKT TEACHER'"
          to="/epaper-library"
        >
          E-Library
        </v-btn>
        <!-- <v-btn
          color="black"
          text
          v-if="roleName == 'SUPER ADMIN' && company.id == null"
          to="/epaper/dashboard"
        >
          Toi / ET Dashboards
        </v-btn> -->
        <!-- <v-btn
          color="black"
          text
          v-if="
            roleName == 'ACADEMIC TEAM' ||
            (roleName == 'SUPER ADMIN' && company.id == null)
          "
          to="/manuals"
        >
          Help
        </v-btn> -->
        <!-- <v-btn
          color="black"
          text
          v-if="
            roleName == 'ACADEMIC TEAM' ||
            (roleName == 'SUPER ADMIN' && company.id == null)
          "
          to="/blogs"
        >
          Blog
        </v-btn> -->
        <!-- <v-btn
          color="black"
          text
          v-if="
            roleName == 'ACADEMIC TEAM' ||
            roleName == 'INFAKT TEACHER' ||
            (roleName == 'SUPER ADMIN' && company.id == null)
          "
          to="/courses"
        >
          Courses
        </v-btn> -->
        <v-btn
          color="black"
          text
          v-if="roleName == 'ACADEMIC TEAM'"
          to="/academic-team/users"
        >
          User
        </v-btn>
        <v-btn
          color="black"
          text
          v-if="roleName == 'ACADEMIC TEAM'"
          to="/dashboards/ticket"
        >
          Tickets
        </v-btn>
        <v-menu
          offset-y
          v-if="
            roleName == 'ADMIN' ||
            roleName == 'TEACHER' ||
            roleName == 'SUPER ADMIN' ||
            // roleName == 'INFAKT TEACHER' ||
            roleName == 'ACADEMIC TEAM'
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="black"
              style="text-transform: initial !important"
              text
              v-bind="attrs"
              v-on="on"
            >
              Reports
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item
                  to="/reports/task-wise-report"
                  v-if="roleName == 'ADMIN' || roleName == 'TEACHER'"
                >
                  <v-list-item-title>Task Wise Report</v-list-item-title>
                </v-list-item> -->
            <v-list-item
              to="/reports/class-wise-performance-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title
                >Class Wise Performance Report</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              to="/reports/student-wise-report"
              v-if="
                roleName == 'ADMIN' ||
                roleName == 'TEACHER' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Student Wise Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/section-wise-report"
              v-if="roleName == 'TEACHER'"
            >
              <v-list-item-title>Section Wise Report</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item
                  to="/reports/class-wise-performance-report"
                  v-if="roleName == 'ADMIN'"
                >
                  <v-list-item-title>Class Wise Performance Report</v-list-item-title>
                </v-list-item> -->
            <v-list-item
              to="/reports/teacher-performance-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Teacher Performance Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/subject-overview-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Subject Overview Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/assignment-wise-performance-report"
              v-if="
                roleName == 'ADMIN' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title
                >Assignment Wise Performance Report</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              to="/reports/school-wise-performance-report"
              v-if="roleName == 'SUPER ADMIN' && company.id == null"
            >
              <v-list-item-title>School Wise Report</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/subject-wise-performance-report"
              v-if="roleName == 'SUPER ADMIN' && company.id == null"
            >
              <v-list-item-title>Subject Wise Task Overview </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/learning-outcome-report"
              v-if="
                roleName == 'ACADEMIC TEAM' ||
                // roleName == 'TEACHER' ||
                (roleName == 'SUPER ADMIN' && company.id != null)
              "
            >
              <v-list-item-title>Learning Outcome Report </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/reports/publication-report"
              v-if="
                roleName == 'ACADEMIC TEAM' ||
                roleName == 'INFAKT TEACHER' ||
                roleName == 'SUPER ADMIN'
              "
            >
              <v-list-item-title>Publication Report </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/dashboard/user-timestamp"
              v-if="
                roleName == 'ACADEMIC TEAM' ||
                (roleName == 'SUPER ADMIN' && company.id == null)
              "
            >
              <v-list-item-title>User Timestamp Report </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-btn color="black" text to="/tickets"> Tickets </v-btn> -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div v-if="user.notifications.length && hide_bell == false">
              <v-badge overlap color="pink" icon="mdi-bell">
                <v-avatar color="primary" v-bind="attrs" v-on="on">
                  <v-icon dark> mdi-account-circle </v-icon>
                </v-avatar>
              </v-badge>
            </div>
            <div v-else>
              <v-avatar color="primary" v-bind="attrs" v-on="on">
                <v-icon dark> mdi-account-circle </v-icon>
              </v-avatar>
            </div>
          </template>
          <v-list>
            <v-list-item to="/notifications" @click="hide_bell = true">
              <v-list-item-title>Notifications</v-list-item-title>
            </v-list-item>
            <v-list-item to="/profile" @click="hide_bell = true">
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="company.id != null" to="/get-started">
              <v-list-item-title>Help</v-list-item-title>
            </v-list-item>
            <v-list-item to="/reset-password">
              <v-list-item-title>Reset Password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </section>
    <router-view />
  </div>
</template>

<script type="text/javascript">
import { mapActions } from "vuex";
import SelectCompanyComponent from "../../components/SelectCompanyComponent.vue";
import store from "@/store";
import SelectBoardComponent from "@/components/SelectBoardComponent.vue";

export default {
  data() {
    return {
      hide_bell: false,
      home_link: "/",
      drawer: true,
      mini: true,
      m_drawer: false,
      group: null,
    };
  },
  components: {
    SelectCompanyComponent,
    SelectBoardComponent,
  },
  watch: {
    company: "home_link_fn",
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    this.home_link_fn();
    this.userAgent = navigator.userAgent;
    // Extract browser name and version
    const match = this.userAgent.match(/(Chrome\/[\d.]+)/);
    this.browserInfo = match ? match[1] : "Unknown Browser";

    // Extract operating system
    this.osMatch = this.userAgent.match(/\(([^)]+)\)/);
    this.osInfo = this.osMatch ? this.osMatch[1] : "Unknown OS";

    console.log("Browser:", this.browserInfo);
    console.log("Operating System:", this.osInfo);
    // console.log("User Agent:", userAgent);
  },
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut().then(() => {
        window.history.pushState({}, "", "/"); // Clear historys
        store.dispatch("auth/setBoard", {});
        location.replace("http://lms.pousse.in");
      });
    },
    home_link_fn() {
      if (this.roleName) {
        switch (this.roleName) {
          case "INFAKT TEACHER":
          case "ACADEMIC TEAM":
            this.home_link = "/contents";
            break;
          case "SUPER ADMIN":
            this.home_link = this.company.id
              ? "/dashboards/admin"
              : "/dashboards/superadmin";
            break;
          case "ADMIN":
            this.home_link = "/dashboards/admin";
            break;
          case "TEACHER":
            this.home_link = "/dashboards/teacher";
            break;
          case "STUDENT":
            this.home_link = "/dashboards/student";
            break;
          case "TICKET ADMINISTRATOR":
            this.home_link = "/dashboards/ticket";
            break;
          case "IT SUPPORT":
            this.home_link = "/dashboards/ticket";
            break;

          default:
            this.home_link = "/";
            break;
        }
      }
    },
    // Scroll back to the 1st validation error Message
    scrollToSection(selector) {
      // Example: smooth scroll to the specific section
      const element = document.querySelector(selector);
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo({
        top: middle,
        behavior: "smooth",
      });
      console.log(selector);
    },
  },
};
</script>
<style scoped>
@media print {
  .dont-print-header,
  .dont-print-header * {
    visibility: hidden;
  }
}
.v-btn--router,
.v-btn__content {
  text-transform: unset !important;
}
</style>
