<template>
  <div>
    <!-- <v-app-bar app> -->

    <v-app-bar
      app
      fixed
      :color="$vuetify.theme.themes.light.background"
      v-if="$vuetify.breakpoint.mdAndDown"
      height="60"
    >
      <v-app-bar-nav-icon
        @click="scrollToSection('#logo')"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <router-link to="/">
        <v-img
          alt="P-LMS"
          contain
          :src="require('../../assets/logo.png')"
          width="100"
        />
      </router-link>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndDown"
      v-model="drawer"
      absolute
      left
      temporary
    >
      <!-- <v-list-item class="mt-6">
        <router-link to="/">
          <v-img
            alt="Infakt"
            contain
            min-width="100"
            :src="require('../../assets/logo.png')"
            width="150"
          />
        </router-link>
      </v-list-item>

      <v-divider></v-divider> -->
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-spacer></v-spacer>
          <!-- <v-list-item>
            <v-list-item-title>
              <router-link
                color="black"
                id="logo"
                text
                to="/about-us"
                class="nav-text"
              >
                About Us
              </router-link>
            </v-list-item-title>
          </v-list-item> -->

          <!-- <v-list-item>
            <v-list-item-title>
              <router-link color="black" text to="/features" class="nav-text">
                Features
              </router-link>
            </v-list-item-title>
          </v-list-item> -->

          <!-- <v-list-item>
            <v-list-item-title>
              <router-link color="black" text to="/contact-us" class="nav-text">
                Contact Us
              </router-link>
            </v-list-item-title>
          </v-list-item> -->

         
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Web View -->
    <v-app-bar v-else app :color="$vuetify.theme.themes.light.background">
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="Infakt"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="70"
            :src="require('../../assets/logo.png')"
            width="70"
          />
        </router-link>
      </div>

      <v-spacer></v-spacer>

     

      <v-btn color="primary" rounded dark to="/auth/login"> Login </v-btn>
    </v-app-bar>

  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      group: null,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    // Scroll back to the 1st validation error Message
    scrollToSection(selector) {
      // Example: smooth scroll to the specific section
      const element = document.querySelector(selector);
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo({
        top: middle,
        behavior: "smooth",
      });
      console.log(selector);
    },
  },
};
</script>
<style scoped>
.nav-text {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 20px;
}
.v-btn--router,
.v-btn__content {
  text-transform: unset !important;
}
</style>
