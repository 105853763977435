<template>
  <v-card color="brown accent-1" dark style="overflow: auto">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div style="min-width: 200px">
        <v-card-title class="text-h5"> {{ value | twoDec }}</v-card-title>

        <v-card-subtitle v-text="text"></v-card-subtitle>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["text", "value"],
};
</script>
