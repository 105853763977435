<template>
  <v-tooltip bottom v-if="tooltip">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mx-2 mb-2"
        fab
        x-small
        color="primary"
        :to="link"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
  <v-btn v-else class="mx-2 mb-2" fab x-small color="primary" :to="link">
    <v-icon dark> mdi-plus </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: ["link", "tooltip"],
};
</script>

<style></style>
