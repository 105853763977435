<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 165px">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Overall Task Completed Vs Task Assigned Subject Wise'"
          ></span>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="scholl"
            v-model="form.school_id"
            :items="schoolItems"
            clearable
            :error-messages="errors.school_id"
            label="School"
            prepend-icon="mdi-application-edit"
          ></v-autocomplete>
        </v-col>
        <v-col></v-col>
        <v-col class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <!--/ Filters Section -->
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col>
          <v-card elevation="6">
            <v-card-text>
              <v-simple-table fixed-header height="285px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="min-width: 56px">Sr No</th>
                      <th class="text-center" style="min-width: 100px">
                        School code
                      </th>
                      <th
                        class="text-center min pr-0 pl-3"
                        style="min-width: 100px"
                      >
                        Subject
                      </th>
                      <th
                        class="text-center min pr-0 pl-3"
                        style="min-width: 100px"
                      >
                        Classcode
                      </th>
                      <th
                        class="text-center min pr-0 pl-3"
                        style="min-width: 100px"
                      >
                        Submission Rate [%]
                      </th>
                      <th
                        class="text-center min pr-0 pl-3"
                        style="min-width: 100px"
                      >
                        Class Average [%]
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(subject, i) in subjectPerformance"
                      :key="`subject${i}`"
                    >
                      <td>{{ i + 1 }}</td>
                      <td class="text-center">{{ subject.school_code }}</td>
                      <td class="text-center">
                        {{ subject.subject_name }}
                      </td>
                      <td class="text-center">
                        <classcode-element
                          :classcode="subject.name"
                          :type="
                            roleName == 'STUDENT'
                              ? 'SUBJECT'
                              : roleName == 'TEACHER'
                              ? 'STANDARD_SUBJECT'
                              : ''
                          "
                        ></classcode-element>
                      </td>
                      <td class="text-center" style="min-width: 130px">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"
                              >{{
                                Math.round(
                                  (subject.total_attempted / subject.count) *
                                    100
                                )
                              }}
                              %</span
                            >
                          </template>
                          <span
                            >={{ subject.total_attempted }} [ Total Attempted ]
                            * 100/{{ subject.count }}
                            [ Total Assignment ]
                          </span>
                        </v-tooltip>
                      </td>
                      <td class="text-center">
                        {{ subject.classcodeAveragePercent | twoDec }} %
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="12" v-if="SchoolPerformanceChart.length > 1">
          <v-card elevation="6">
            <v-card-title>
              Submission Rate VS Clase Average
              <v-spacer></v-spacer>
              <view-fullscreen
                :title="`Submission Rate VS Clase Average`"
                :type="`ColumnChart`"
                :Data="SchoolPerformanceChart"
                :Options="columnchartOptions"
              ></view-fullscreen>
            </v-card-title>
            <v-card-text style="overflow: auto">
              <GChart
                type="ColumnChart"
                :data="SchoolPerformanceChart"
                :options="columnchartOptions"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      form: {},
      isLoading: false,
      schools: [],
      schoolItems: [
        {
          id: 0,
          text: "Select School",
          value: 0,
        },
      ],
      subjectPerformance: [],
      selectedStudentId: "",
      // Chart Components
      SchoolBasedOn: "1",
      SchoolPerformanceChart: [],
      columnchartOptions: {
        theme: "material",
        height: 350,
        colors: ["#007ACC", "#FFA500"],
        chartArea: { left: 50, right: 50 },
        bar: { groupWidth: "30%" },
        annotations: {
          textStyle: {
            fontSize: 10,
          },
        },
        vAxis: {
          minValue: 0,
          maxValue: 100,
          title: "In Percentage",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 40 },
          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          title: "Classcodes",
          titleTextStyle: {
            bold: true,
          },
          gridlines: {
            color: "transparent",
          },
        },
        legend: { position: "top", alignment: "center" },
      },
    };
  },
  mounted() {
    // this.getData();
    this.getMasters();
  },

  methods: {
    async getMasters() {
      let schools = await axios.get(`companies`);
      this.schools = schools.data.data;
      // ClassCodes
      this.schools.forEach((school) => {
        this.schoolItems.push({
          id: school.id,
          text: school.name,
          value: school.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      try {
        let response;
        this.form.school_id = this.form.school_id ? this.form.school_id : "";
        let query = "school_id=" + this.form.school_id;
        response = await axios.get(`/school_subject_wise_performance?${query}`);
        this.subjectPerformance = response.data.data;
        if (this.subjectPerformance) {
          // School Wise Percentage [Score / Class]
          let SchoolPerformanceChart = [
            [this.SchoolBasedOn, "Submisssion Rate [%]", "Class Average [%]"],
          ];
          this.subjectPerformance.forEach((data) => {
            let submission_rate = parseFloat(
              Math.round(
                (parseInt(data.total_attempted) / parseInt(data.count)) * 100
              ).toFixed(2)
            );
            let classPercent = parseFloat(
              data.classcodeAveragePercent != 0
                ? data.classcodeAveragePercent.toFixed(2)
                : 0
            );
            SchoolPerformanceChart.push([
              data.name,
              submission_rate,
              classPercent,
            ]);
          });
          this.SchoolPerformanceChart = SchoolPerformanceChart;
          console.log(this.SchoolPerformanceChart);
        }
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
