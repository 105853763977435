<template>
  <v-main>
    <v-container fluid>
      <SaStudentSchoolsData
        :schoolId="company.id"
        :currentType="'Student'"
        :currentSubType="user.id"
      ></SaStudentSchoolsData>
      <!-- Articles to read -->
      <br />
      <div v-if="atr.length">
        <div class="text-h5 font-weight-bold" v-text="'Articles to Read'"></div>
        <br />
        <loader v-if="isLoading"></loader>
        <v-row>
          <v-col sm="3" v-for="(article, a) in atr" :key="`article${a}`">
            <Article :article="article" :type="article.content_type"></Article>
          </v-col>
        </v-row>
      </div>
      <!-- Recent Added Section -->
      <div
        class="text-h5 font-weight-bold mt-10"
        v-text="'Recently Added'"
      ></div>
      <!-- Article -->
      <v-row v-if="articles.length || isArticlesLoading == true">
        <v-col sm="6" md="6" lg="8">
          <div
            class="text-h7 font-weight-bold mt-5"
            style="min-width: 30px"
            v-text="'Articles'"
          ></div>
        </v-col>
        <v-col sm="6" md="6" lg="4" align="right">
          <v-btn color="primary" to="/articles?type=ARTICLE">View More</v-btn>
        </v-col>
      </v-row>
      <loader v-if="isArticlesLoading"></loader>
      <v-row>
        <template v-for="(article, a) in articles">
          <v-col
            sm="3"
            :key="`article${a}`"
            v-if="article.content_descriptions.length != 0"
          >
            <Article :article="article" :type="article.content_type"></Article>
          </v-col>
        </template>
      </v-row>
      <!-- Infographic -->
      <v-row v-if="infographics.length || isInfographicsLoading == true">
        <v-col sm="6" md="6" lg="8">
          <div
            class="text-h7 font-weight-bold mt-5"
            v-text="'Infographics'"
            style="min-width: 91px"
          ></div>
        </v-col>
        <v-col sm="6" md="6" lg="4" align="right">
          <v-btn color="primary" to="/articles?type=INFOGRAPHIC">
            View More</v-btn
          >
        </v-col>
      </v-row>
      <loader v-if="isInfographicsLoading"></loader>
      <v-row>
        <template v-for="(article, a) in infographics">
          <v-col
            sm="3"
            :key="`article${a}`"
            v-if="article.content_descriptions.length != 0"
          >
            <Article :article="article" :type="article.content_type"></Article>
          </v-col>
        </template>
      </v-row>
      <!-- Videos -->
      <v-row v-if="videos.length || isVideosLoading == true">
        <v-col sm="6" md="6" lg="8">
          <div
            class="text-h7 font-weight-bold mt-5"
            style="min-width: 30px"
            v-text="'Videos'"
          ></div>
        </v-col>
        <v-col sm="6" md="6" lg="4" align="right">
          <v-btn color="primary" to="/articles?type=VIDEO">View More</v-btn>
        </v-col>
      </v-row>
      <loader v-if="isVideosLoading"></loader>
      <v-row>
        <template v-for="(article, a) in videos">
          <v-col
            sm="3"
            :key="`article${a}`"
            v-if="article.content_descriptions.length != 0"
          >
            <Article :article="article" :type="article.content_type"></Article>
          </v-col>
        </template>
      </v-row>
      <!-- Most Popular Content -->
      <div v-show="most_popular_articles.length > 0">
        <br />
        <div
          class="text-h7 font-weight-bold my-4"
          v-text="'Most Popular Content'"
        ></div>
        <loader v-if="isMostPopularsLoading"></loader>
        <v-row>
          <v-col
            sm="3"
            v-for="(article, a) in most_popular_articles"
            :key="`article${a}`"
          >
            <Article :article="article" :type="article.content_type"></Article>
          </v-col>
        </v-row>
      </div>
      <!-- Collections -->
      <template v-if="collections.length">
        <v-row v-if="collections.length || isCollectionsLoading == true">
          <v-col sm="6" md="6" lg="8">
            <div
              style="min-width: 125px"
              class="text-h7 font-weight-bold mt-3"
              v-text="'Infakt Collections'"
            ></div>
          </v-col>
          <v-col sm="6" md="6" lg="4" align="right">
            <v-btn color="primary" to="/infakt-collections">View More</v-btn>
          </v-col>
        </v-row>
        <loader v-if="isCollectionsLoading"></loader>
        <v-row>
          <v-col
            sm="3"
            v-for="(collection, a) in collections"
            :key="`collection${a}`"
          >
            <v-card elevation="6" shaped>
              <router-link
                class="routerLink"
                :to="`/library/collections/${collection.id}`"
              >
                <!-- IF Feature Image Found -->
                <div v-if="collection.featured_image_path">
                  <v-img
                    max-height="210"
                    contain
                    class="rounded-tl-xl feature"
                    :src="`${mediaUrl + collection.featured_image_path}`"
                  ></v-img>
                </div>
                <v-img
                  v-else
                  :src="require('@/assets/imgs/collection.png')"
                  height="180"
                ></v-img>
                <v-card-title>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h3
                        v-if="collection.collection_name"
                        v-bind="attrs"
                        v-on="on"
                        class="wrap"
                        v-html="collection.collection_name"
                      ></h3>
                    </template>
                    <span v-html="collection.collection_name"> </span>
                  </v-tooltip>
                </v-card-title>
              </router-link>
              <v-divider v-if="roleName != 'STUDENT'" class="mx-4"></v-divider>
              <v-card-actions v-if="roleName != 'STUDENT'" class="pt-0">
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-1"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      fab
                      x-small
                      color="primary"
                      :to="`/assignments/create?collectionId=${collection.id}`"
                    >
                      <v-icon>mdi-clipboard-text</v-icon>
                    </v-btn>
                  </template>
                  <span> Create Assignment </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="mx-1"
                      dark
                      fab
                      x-small
                      color="primary"
                      @click="shareCollection(collection.id, (isShared = true))"
                    >
                      <v-icon> mdi-share-variant-outline </v-icon>
                    </v-btn>
                  </template>
                  <span> Share Collection </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      v-bind="attrs"
                      v-on="on"
                      class="mx-1"
                      x-small
                      color="primary"
                      @click="getCollection(collection.id, (isEdit = true))"
                    >
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span> Edit Collection </span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import SaStudentSchoolsData from "./superadmin-dashboard/student_school_data";
import Article from "@/components/article.vue";
import Loader from "@/components/loader.vue";

export default {
  data() {
    return {
      isLoading: false,
      isArticlesLoading: false,
      isInfographicsLoading: false,
      isVideosLoading: false,
      isMostPopularsLoading: false,
      isATRsLoading: false,
      isCollectionsLoading: false,
      articles: [],
      infographics: [],
      videos: [],
      atr: [],
      most_popular_articles: [],
      collections: [],
    };
  },
  components: {
    SaStudentSchoolsData,
    Article,
    Loader,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get("teacherDashboard");
      this.form = form.data.data;
      // Article Section
      this.isArticlesLoading = true;
      let articles = await axios.get(
        `/contents?type=ARTICLE&content_limit_4=true`
      );
      this.articles = articles.data.data;
      this.isArticlesLoading = false;
      // End of Article Section
      // Infographic Section
      this.isInfographicsLoading = true;
      let infographics = await axios.get(
        `/contents?type=INFOGRAPHIC&content_limit_4=true`
      );
      this.infographics = infographics.data.data;
      this.isInfographicsLoading = false;
      // End of Infographic Section

      // Video Section
      this.isVideosLoading = true;
      let videos = await axios.get(`/contents?type=VIDEO&content_limit_4=true`);
      this.videos = videos.data.data;
      this.isVideosLoading = false;
      //End of Video Section

      // Most Popular Section
      this.isMostPopularsLoading = true;
      let most_popular_articles = await axios.get(`/most_popular_articles`);
      this.most_popular_articles = most_popular_articles.data.data;
      this.isMostPopularsLoading = false;
      //End of Most Popular Section
      // Articles Assigned to Read
      this.isATRsLoading = true;
      let atr = await axios.get(`/contents/assigned_to_read_articles`);
      this.atr = atr.data.data;
      this.isATRsLoading = true;
      // End of Articles Assigned to Read

      // Collection Section
      this.isCollectionsLoading = true;
      let collections = await axios.get(
        `/collections?all_collection_by_AT_IT=1&limit=4`
      );
      this.collections = collections.data.data;
      this.isCollectionsLoading = false;
      // End of Collection Section
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
