<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 181px">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Subject Overview Report'"
          ></span>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="Standards"
            v-model="form.standards"
            multiple
            small-chips
            @input="getStandardSubjects"
            :loading="isfetchingStandard"
            clearable
            @click:clear="form.standards = null"
            :items="standardItems"
            label="Standards"
            prepend-icon="mdi-alarm-bell"
          ></v-autocomplete>
          <!-- @input="search_chapters()" -->
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="classcode"
            v-model="form.subject_names"
            :items="classcodeItems"
            label="Subject"
            multiple
            small-chips
            clearable
            @click:clear="form.subject_names = null"
            :error-messages="errors[`datas.subject_names`]"
            prepend-icon="mdi-application-edit"
          ></v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="1" class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <!--/ Filters Section -->
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="6">
          <v-card elevation="6" height="435">
            <v-card-text>
              <v-simple-table fixed-header height="285px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="min-width: 56px">Sr No</th>
                      <th class="text-center" style="min-width: 100px">
                        Subject
                      </th>
                      <th class="text-center" style="min-width: 100px">
                        Classcodes
                      </th>
                      <th class="text-center" style="min-width: 140px">
                        Assignment Posted
                      </th>
                      <th class="text-center" style="min-width: 100px">
                        Submission Rate [%]
                      </th>
                      <th class="text-center" style="min-width: 100px">
                        Overall Average [%]
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(subject, i) in SubjectWiseTasks"
                      :key="`subject${i}`"
                    >
                      <td>{{ i + 1 }}</td>
                      <td class="text-center">{{ subject.subject_name }}</td>
                      <td class="text-center">{{ subject.total_classcode }}</td>
                      <td class="text-center">
                        {{ subject.totalClassAssignments }}
                      </td>
                      <td class="text-center">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"
                              >{{ subject.SubmissionRate }} %</span
                            >
                          </template>
                          <span>
                            [ Total Submission Average / Total Assignment ]
                          </span>
                        </v-tooltip>
                      </td>
                      <td class="text-center">
                        {{ subject.AveragePercentage }} %
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="6" v-if="SubjectWiseTaskPerformanceChart.length">
          <v-card elevation="6">
            <v-card-title style="min-width: 250px">
              Subject Wise Task Overview
              <v-spacer></v-spacer>
              <view-fullscreen
                :title="`Subject Wise Task Overview`"
                :type="`ColumnChart`"
                :Data="SubjectWiseTaskPerformanceChart"
                :Options="SubjectWiseColumnChartOptions"
              ></view-fullscreen>
            </v-card-title>
            <v-card-text>
              <GChart
                type="ColumnChart"
                :data="SubjectWiseTaskPerformanceChart"
                :options="SubjectWiseColumnChartOptions"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      form: {},
      isLoading: false,
      isfetchingStandard: false,
      classcodes: [],
      classcodeItems: [],
      standardItems: [],
      SubjectWiseTasks: [],
      BoardSubjectWiseTasks: [],
      selectedStudentId: "",
      schoolId: "",
      // Chart Components
      TeacherBasedOn: "1",
      SubjectWiseTaskPerformanceChart: [],
      BoardSubjectWiseTaskPerformanceChart: [],
      SubjectWiseColumnChartOptions: {
        theme: "material",
        colors: ["#007ACC", "#FFA500", "#9E9E9E"],
        height: 350,
        chartArea: { left: 50, right: 50 },
        bar: { groupWidth: "75" },
        annotations: {
          textStyle: {
            fontSize: 10,
          },
        },
        vAxis: {
          minValue: 0,
          title: "No. of Assignments",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 40 },

          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          title: "Subjects",
          titleTextStyle: {
            bold: true,
          },
          gridlines: {
            color: "transparent",
          },
        },
        legend: { position: "top", alignment: "center" },
      },
    };
  },
  mounted() {
    this.schoolId = this.company.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`users/masters?board_id=${this.board.id}`);
      masters = masters.data;
      this.classcodes = masters.classcodes;
      this.isfetchingStandard = true;
      masters.standards.forEach((standard) => {
        this.standardItems.push({
          id: standard.id,
          text: standard.name,
          value: standard.id,
        });
      });
      this.isfetchingStandard = false;
      // ClassCodes
      this.classcodes = [...new Set(this.classcodes)];
      this.classcodes.forEach((classcode) => {
        this.classcodeItems.push({
          id: classcode.id,
          text: classcode.subject_name,
          value: this.subject_name,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      this.SubjectWiseTasks = [];
      this.SubjectWiseTaskPerformanceChart = [];
      try {
        let subject_wise_task_assigned;
        this.form.subject_name = this.form.subject_name
          ? this.form.subject_name
          : "";
        this.form.board_id = this.board.id ? this.board.id : "";
        let payload = {
          datas: this.form,
        };
        subject_wise_task_assigned = await axios.post(
          `/subject_overview_report`,
          payload
        );
        // let query =
        //   "board_id=" +
        //   this.board.id +
        //   "&standards=" +
        //   this.form.standards +
        //   "&subject_names=" +
        //   this.form.subject_names;
        // subject_wise_task_assigned = await axios.get(
        //   `/subject_overview_report?${query}`
        // );
        this.SubjectWiseTasks = subject_wise_task_assigned.data.data;
        if (this.SubjectWiseTasks) {
          console.log(this.SubjectWiseTasks);
          // Subject Overview report
          let SubjectWiseTaskPerformanceChart = [
            [
              this.TeacherBasedOn,
              "Assignment Posted",
              "Submission Rate [%]",
              "Overall Average [%]",
            ],
          ];
          this.SubjectWiseTasks.forEach((data) => {
            SubjectWiseTaskPerformanceChart.push([
              data.subject_name,
              data.totalClassAssignments,
              data.SubmissionRate,
              data.AveragePercentage,
            ]);
          });
          this.SubjectWiseTaskPerformanceChart =
            SubjectWiseTaskPerformanceChart;
          console.log(this.SubjectWiseTaskPerformanceChart);
        }
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async getStandardSubjects() {
      this.classcodeItems = [];
      this.form.subject_name = "";
      if (this.form.standards.length) {
        let standardclasscodes = this.classcodes.filter((classcode) => {
          return this.form.standards.some(
            (itemB) => itemB === classcode.standard_id
          );
        });
        standardclasscodes.forEach((sc) => {
          this.classcodeItems.push({
            id: sc.id,
            text: sc.subject_name,
            value: sc.subject_name,
          });
        });
      } else {
        this.classcodes.forEach((sc) => {
          this.classcodeItems.push({
            id: sc.id,
            text: sc.subject_name,
            value: sc.subject_name,
          });
        });
      }
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
