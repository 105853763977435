<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="center">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Companies'"></span>
          <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            to="/schools/create"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col sm="2" v-for="board in school_boards" :key="board.id">
          <v-card
            elevation="5"
            color="primary accent-1"
            dark
            class="rounded-tl-xl rounded-br-xl mx-auto"
            style="min-width: 100px"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5 font-weight-bold"
                  v-text="board.name"
                ></v-card-title>

                <v-card-subtitle
                  class="text-h3 font-weight-bold"
                  v-text="board.count"
                ></v-card-subtitle>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="search_keyword"
            label="Search ... "
            prepend-inner-icon="mdi-magnify"
            @keydown.enter="getData"
            @change="page = 1"
          ></v-text-field>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="location"
            v-model="form.board_id"
            :items="boardItems"
            label="Location"
            @input="getData"
            prepend-icon="mdi-cast-education"
          ></v-autocomplete>
        </v-col>
        <v-col md="3">
          <v-pagination v-model="page" :length="paginationLength">
          </v-pagination>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 56px">Sr No</th>
                    <th class="text-center" style="min-width: 100px">
                      School Code
                    </th>
                    <th class="text-center" style="min-width: 65px">Name</th>
                    <th class="text-center" style="min-width: 65px">Email</th>
                    <th class="text-center" style="min-width: 75px">
                      Contact Person
                    </th>
                    <th class="text-center" style="min-width: 68px">Phone</th>
                    <th class="text-center" style="min-width: 77px">Address</th>
                    <!-- <th class="text-center">Is Mail Sent</th> -->
                    <th class="text-center" style="min-width: 83px">
                      Teachers Count
                    </th>
                    <th class="text-center" style="min-width: 83px">
                      Students Count
                    </th>
                    <th class="text-center" style="min-width: 83px">
                      Admin Details
                    </th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(school, i) in schools" :key="school.name">
                    <td style="min-width: 85px">
                      <v-tooltip bottom v-if="school.is_active == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>School is Active</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td class="text-center">{{ school.code }}</td>
                    <td class="text-center" style="min-width: 85px">
                      {{ school.name }}
                    </td>
                    <td class="text-center" style="min-width: 150px">
                      {{ school.email }}
                    </td>
                    <td class="text-center">{{ school.contact_person }}</td>
                    <td class="text-center" style="min-width: 115px">
                      {{ school.phone }}
                    </td>
                    <td class="text-center" style="min-width: 250px">
                      {{
                        (school.address || "") +
                        " " +
                        (school.state || "") +
                        " " +
                        (school.city || "") +
                        " " +
                        (school.pincode || "")
                      }}
                    </td>
                    <td class="text-center">{{ school.teachers_count }}</td>
                    <td class="text-center">{{ school.students_count }}</td>
                    <td class="text-center" style="min-width: 225px">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            List of Admins
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-simple-table fixed-header>
                              <template v-slot:default>
                                <tbody>
                                  <tr
                                    v-for="(user, u) in school.users"
                                    :key="`user${u}`"
                                  >
                                    <td style="min-width: 185px">
                                      {{ user.email }}
                                    </td>
                                    <td style="min-width: 115px">
                                      <v-btn
                                        class="mx-2"
                                        fab
                                        x-small
                                        color="primary"
                                        :to="`/schools/${school.id}/admin/${user.id}`"
                                      >
                                        <v-icon dark> mdi-pencil </v-icon>
                                      </v-btn>
                                      <v-tooltip
                                        bottom
                                        v-if="user.is_mail_sent == 0"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            fab
                                            x-small
                                            class="my-auto"
                                            color="error"
                                            @click="SendMail(user.id, 'USER')"
                                            :loading="sending_mail"
                                            :disabled="sending_mail"
                                          >
                                            <v-icon dark>
                                              mdi-email-fast
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                        <span
                                          >No Registration Mail Sent Yet
                                          !!</span
                                        >
                                      </v-tooltip>
                                      <v-tooltip bottom v-else>
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            class="my-auto"
                                            fab
                                            x-small
                                            color="success"
                                            @click="SendMail(user.id, 'USER')"
                                          >
                                            <v-icon dark>
                                              mdi-email-open
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                        <span
                                          >Registration Mail Already Sent.
                                        </span>
                                      </v-tooltip>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                    <td class="text-right">
                      <br />
                      <v-btn
                        class="mx-2"
                        small
                        color="primary"
                        :to="`/schools/${school.id}/admin/create`"
                      >
                        Add Admin
                      </v-btn>
                      <br /><br />
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/schools/${school.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                      <br /><br />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <!-- Mail Sent Success Dialog Box -->
      <v-dialog v-model="is_Mail_Sent" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-cloud-upload-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>
                        The registration mail has been sent successfully. Now
                        you can ask them to check their mail.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Mail_Sent = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  name: "SchoolsPage",
  data() {
    return {
      form: {
        board_id: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      schools: [],
      school_boards: [],
      boardItems: [
        {
          id: 0,
          text: "Select Location",
          value: 0,
        },
      ],
      isLoading: false,
      sending_mail: false,
      is_Mail_Sent: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      this.isLoading = true;
      let masters = await axios.get("companies/masters");
      masters = masters.data;
      // Boards
      masters.boards.forEach((board) => {
        this.boardItems.push({
          id: board.id,
          text: board.name,
          value: board.id,
        });
      });
      this.isLoading = false;
    },
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let schools = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&board_id=" +
        this.form.board_id;
      schools = await axios.get(`companies?${query}`);
      this.schools = schools.data.data;
      this.school_boards = schools.data.BoardSchoolCount;
      this.isLoading = false;
    },
    async SendMail(id, type = "SCHOOL") {
      this.sending_mail = true;
      let is_mail_sent = "";
      if (type == "SCHOOL") {
        let query = "school_id=" + id;
        is_mail_sent = await axios.get(`/companies/send_mail?${query}`);
      } else {
        let query = "user_id=" + id;
        is_mail_sent = await axios.get(`/users/send_mail?${query}`);
      }
      if (is_mail_sent) {
        this.sending_mail = false;
        this.is_Mail_Sent = true;
        this.getData();
      }
    },
  },
};
</script>
