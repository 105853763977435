<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 175px">
          <span
            class="text-h5 font-weight-bold"
            v-text="`SPACE EPAPERS (${count})`"
          ></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3">
          <v-text-field
            v-model="search_keyword"
            label="Search ... "
            prepend-inner-icon="mdi-newspaper-variant-multiple-outline"
            clearable
            hide-details
            @click:clear="(search_keyword = null), getData()"
            @keydown.enter="getData"
            @change="page = 1"
          ></v-text-field>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="word_count"
            v-model="word_count"
            :items="wordCounts"
            label="Select word count"
            prepend-icon="mdi-domain"
            @change="getData"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-menu
            v-model="date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_filter"
                label="Date Search"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @input="getData"
                @click:clear="date_filter = ''"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_filter"
              @input="getData"
              @change="date_menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="3">
          <v-pagination v-model="page" :length="paginationLength">
          </v-pagination>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left px-2" style="min-width: 70px">
                      Sr No
                    </th>
                    <th class="text-center" style="min-width: 80px">
                      Epaper ID
                    </th>
                    <th class="text-center" style="min-width: 115px">
                      Edition Name
                    </th>
                    <th class="text-center" style="min-width: 120px">
                      Published Date
                    </th>
                    <th class="text-center" style="min-width: 360px">Title</th>
                    <th class="text-center" style="min-width: 100px">
                      Word Count
                    </th>
                    <th class="text-center" style="min-width: 80px">Used In</th>
                    <th class="text-center pl-0 pr-2" style="min-width: 140px">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(spaceArticle, i) in space_articles"
                    :key="spaceArticle.name"
                  >
                    <td>{{ serialNoStarting + i + 1 }}</td>
                    <td class="text-center" style="min-width: 80px">
                      {{ spaceArticle.id }}
                    </td>
                    <td class="text-center" style="min-width: 215px">
                      {{ spaceArticle.edition_name }}
                    </td>
                    <td class="text-center" style="min-width: 150px">
                      {{ spaceArticle.published_date | MMddyyyy }}
                    </td>
                    <td class="text-center wrap">{{ spaceArticle.title }}</td>
                    <td class="text-center" style="min-width: 60px">
                      {{ spaceArticle.word_count }}
                    </td>
                    <td class="text-center" style="min-width: 50px">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            dark
                            color="primary"
                            size="25"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            v-if="spaceArticle.contents.length"
                            @click="ShowDetails(spaceArticle.id)"
                          >
                            <v-icon> mdi-check-all </v-icon>
                          </v-btn>
                        </template>
                        <span> View Contents </span>
                      </v-tooltip>
                    </td>
                    <td class="text-center" style="min-width: 140px">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            target="_blank"
                            size="25"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            color="primary"
                            :to="`/epaper/space/${spaceArticle.id}`"
                          >
                            <v-icon> mdi-eye-outline </v-icon>
                          </v-btn>
                        </template>
                        <span> View Epaper </span>
                      </v-tooltip>
                      <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            target="_blank"
                            size="25"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            color="primary"
                            :to="`/contents/create?spaceEpaperId=${spaceArticle.id}`"
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </template>
                        <span> Create Content </span>
                      </v-tooltip> -->
                      <!--
                      <epaper-bookmark-dialog
                        :epaper="spaceArticle"
                        :epaper_type="'Space'"
                      ></epaper-bookmark-dialog> -->
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="show_contents"
        transition="dialog-bottom-transition"
        max-width="1000"
      >
        <v-card elevation="2" class="rounded-tl-xl rounded-br-xl">
          <v-card-title class="justify-center primary accent-1 white--text">
            Contents linked to Epaper Id :{{ article_details.id }}
          </v-card-title>
          <v-card-text class="mt-5">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">ID</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Title</th>
                    <th class="text-left">Reading Time</th>
                    <th class="text-left">Written By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(content, i) in article_details.contents"
                    :key="content.name"
                  >
                    <td>{{ i + 1 }}</td>
                    <td class="pa-1">{{ content.id }}</td>
                    <td class="pa-1">{{ content.content_type }}</td>
                    <td class="pa-1">{{ content.content_name }}</td>
                    <td>{{ content.reading_time }} mins</td>
                    <td>
                      {{ content.written_by_name }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import EpaperBookmarkDialog from "@/components/EpaperBookmarkDialog.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { EpaperBookmarkDialog, Loader },
  name: "SpaceArticlesPage",
  data() {
    return {
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      show_contents: false,
      search_keyword: "",
      word_count: "",
      date_menu: "",
      space_articles: [],
      article_details: [],
      wordCounts: [
        { id: "0", value: "0", text: "Select Word Count" },
        { id: "100", value: "100", text: "More than 100" },
        { id: "250", value: "250", text: "More than 250" },
        { id: "350", value: "350", text: "More than 350" },
        { id: "500", value: "500", text: "More than 500" },
      ],
      date_filter: "",
      isLoading: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.date_filter = this.date_filter ? this.date_filter : "";
      let space_articles = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&word_count=" +
        this.word_count +
        "&date_filter=" +
        this.date_filter;
      space_articles = await axios.get(`space_articles?${query}`);
      this.space_articles = space_articles.data.data;
      this.count = space_articles.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
    async ShowDetails(id) {
      this.article_details = [];
      this.article_details = this.space_articles.filter(
        (space) => space.id == id
      )[0];
      if (this.article_details) {
        this.show_contents = true;
      }
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.v-pagination__item {
  min-width: none;
}
</style>
