<template>
  <v-main style="font-family: Montserrat">
    <v-container>
      <br />
      <v-row justify="start">
        <v-col>
          <h2
            style="font-family: Montserrat"
            class="font-weight-bold"
            v-text="'Publication Report'"
          ></h2>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row v-if="current_scenario == 1">
        <v-col md="6">
          <v-row ref="table_filter">
            <v-col class="py-0" sm="12" md="12">
              <p>Filter Publication</p>
            </v-col>
          </v-row>
          <v-row class="mt-0" ref="table_filter">
            <v-col sm="12" md="6">
              <v-autocomplete
                ref="board_id"
                v-model="form.publication_name"
                :items="PublicationReport"
                label="Search Publication"
                clearable
                dense
                hide-details
                outlined
                item-text="publication"
                item-value="publication"
                prepend-inner-icon="mdi-web"
              >
                <template v-slot:selection="data">
                  {{ data.item.publication }}
                </template></v-autocomplete
              >
            </v-col>
            <v-col sm="12" md="6">
              <v-btn-toggle
                mandatory
                v-model="toggle_exclusive"
                style="border-radius: 8px !important; height: 41px"
              >
                <v-btn style="text-transform: initial !important; height: 41px"
                  ><v-icon size="5" x-small dark color="green" class="mr-2">
                    mdi-circle
                  </v-icon>
                  This month
                </v-btn>
                <v-menu
                  v-model="custom_month_menu"
                  :close-on-content-click="(Debug(), false)"
                  :nudge-width="50"
                  offset-y
                  min-width="200"
                  transition="slide-y-transition"
                  bottom
                  left
                  rounded="xl"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      style="text-transform: initial !important; height: 41px"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Custom
                    </v-btn>
                  </template>
                  <v-card>
                    <v-list>
                      <v-list-item
                        v-for="(month, m) in months"
                        :key="`month${m}`"
                        style="font-family: Montserrat"
                      >
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        {{ month.text }}
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row ref="table_data">
            <v-col>
              <v-card
                elevation="6"
                style="
                  border-top-left-radius: 15px;
                  border-top-right-radius: 15px;
                  border-bottom-left-radius: 15px;
                  border-bottom-right-radius: 15px;
                "
              >
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr style="height: 30px !important">
                        <th
                          class="text-left"
                          style="
                            height: 30px !important;

                            min-width: 50px;
                            background-color: aliceblue;
                            border-top-left-radius: 15px;
                          "
                        >
                          Sr No
                        </th>
                        <th
                          class="text-center"
                          style="
                            height: 30px !important;
                            background-color: aliceblue;
                            min-width: 70px;
                          "
                        >
                          Publication Name
                        </th>
                        <th
                          class="text-center"
                          style="
                            height: 30px !important;
                            border-top-right-radius: 15px;
                            background-color: aliceblue;
                            min-width: 57px;
                          "
                        >
                          Count
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(publication, p) in PublicationReport"
                        :key="`publication${p}`"
                      >
                        <td>{{ p + 1 }}</td>
                        <td class="text-center">
                          {{ publication.publication }}
                        </td>
                        <td
                          class="text-center routerLink primary--text"
                          @click="
                            (form.publication_name = publication.publication),
                              getData()
                          "
                        >
                          {{ publication.count }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6">
          <v-row>
            <v-col><h4>Publication Wise Comparison</h4> </v-col>
            <v-col
              ><template style="min-width: fit-content">
                <div class="text-right">
                  <v-menu
                    v-model="graph_filter_menu"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                    max-width="200"
                    transition="slide-y-transition"
                    bottom
                    rounded="xl"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="white"
                        class="text--secondary"
                        v-bind="attrs"
                        v-on="on"
                        style="
                          border-radius: 8px !important;
                          text-transform: initial !important;
                        "
                      >
                        <v-icon>mdi-filter-variant</v-icon>
                        Filter
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list style="font-family: Montserrat">
                        <v-list-item
                          ><v-radio-group v-model="row" row>
                            <v-radio label="A-Z" value="radio-1"></v-radio>
                            <v-radio label="Z-A" value="radio-2"></v-radio>
                          </v-radio-group>
                        </v-list-item>
                        <hr />
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          0-10
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          10-20
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          20-30
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          30-40
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>
              </template></v-col
            >
          </v-row>
          <v-row>
            <v-col>
              <v-card
                elevation="6"
                style="
                  border-top-left-radius: 15px;
                  border-top-right-radius: 15px;
                  border-bottom-left-radius: 15px;
                  border-bottom-right-radius: 15px;
                "
              >
                <v-card-text>
                  <v-row v-if="PublicationWiseData.length">
                    <v-col style="overflow: auto">
                      <GChart
                        type="BarChart"
                        :data="PublicationWiseData"
                        :options="publicationColumnChartOptions"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!--/ Filters Section -->
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <template v-if="current_scenario == 2">
          <v-col>
            <v-card elevation="6">
              <v-card-title>
                {{ form.publication_name }}
              </v-card-title>
              <v-card-text>
                <v-simple-table fixed-header height="500px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 50px">Sr No</th>
                        <th class="text-center" style="min-width: 70px">
                          Month-Year
                        </th>
                        <th class="text-center" style="min-width: 57px">
                          Count
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(publication, p) in PublicationReport"
                        :key="`publication${p}`"
                      >
                        <td>{{ p + 1 }}</td>
                        <td class="text-center">
                          {{ publication.month | mm }}-
                          {{ publication.year }}
                        </td>
                        <td
                          class="text-center routerLink primary--text"
                          @click="
                            (form.month = publication.month),
                              (form.year = publication.year),
                              getData()
                          "
                        >
                          {{ publication.count }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        <template v-if="current_scenario == 3">
          <v-col>
            <v-card elevation="6">
              <v-card-title>
                {{ form.publication_name }} {{ form.month | mm }} -
                {{ form.year }}
              </v-card-title>
              <v-card-text>
                <v-simple-table fixed-header height="500px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 50px">Sr No</th>
                        <th class="text-center" style="min-width: 70px">
                          Date Published
                        </th>
                        <th class="text-center" style="min-width: 70px">
                          Article Title
                        </th>
                        <th class="text-center" style="min-width: 70px">
                          Article type
                        </th>
                        <th class="text-center" style="min-width: 70px">
                          Written By
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <router-link
                        v-for="(publication, p) in PublicationReport"
                        :key="`publication${p}`"
                        class="routerLink"
                        :to="`/articles/${publication.id}`"
                        tag="tr"
                      >
                        <td>{{ p + 1 }}</td>
                        <td class="text-center">
                          {{ publication.created_at | ddmmyyyy }}
                        </td>
                        <td class="text-center primary--text">
                          {{ publication.content_name }}
                        </td>
                        <td class="text-center">
                          {{ publication.content_type }}
                        </td>
                        <td class="text-center">
                          {{ publication.written_by_name }}
                        </td>
                      </router-link>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      form: {
        publication_name: "",
        month: "6",
        year: "2023",
      },
      current_scenario: "1",
      custom_month_menu: false,
      graph_filter_menu: false,
      isLoading: false,
      subjectItems: [],
      gradeItems: [],
      boardItems: [],
      PublicationReport: [],
      PublicationWiseData: [],
      months: [
        { id: "1", text: "January" },
        { id: "2", text: "February" },
        { id: "3", text: "March" },
        { id: "4", text: "April" },
        { id: "5", text: "May" },
        { id: "6", text: "June" },
        { id: "7", text: "July" },
        { id: "8", text: "August" },
        { id: "9", text: "September" },
        { id: "10", text: "October" },
        { id: "11", text: "November" },
        { id: "12", text: "December" },
      ],
      publicationColumnChartOptions: {
        width: "100%",
        height: 850,
        chartArea: { top: 50, left: 100, right: 50, bottom: 50 },
        colors: ["#007ACC"],
        bar: { groupWidth: "30%" },
        annotations: {
          textStyle: {
            fontSize: 60,
          },
        },
        animation: {
          duration: 500,
          easing: "out",
        },
        vAxis: {
          minValue: 0,
          maxValue: 30,
          title: "Publications",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 95 },
          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          title: "In Count",
          titleTextStyle: {
            bold: true,
          },
          gridlines: {
            color: "transparent",
          },
        },
        legend: { position: "top", alignment: "left" },
        // series: {
        //   0: { bar: { groupWidth: "20%", edge: "rounded" } },
        //   1: { bar: { groupWidth: "20%", edge: "rounded" } },
        //   2: { bar: { groupWidth: "20%", edge: "rounded" } },
        //   3: { bar: { groupWidth: "20%", edge: "rounded" } },
        //   4: { bar: { groupWidth: "20%", edge: "rounded" } },
        //   6: { bar: { groupWidth: "20%", edge: "rounded" } },
        // },
      },
    };
  },
  mounted() {
    // window.history.pushState(null, null, location.href);
    window.addEventListener("popstate", this.handleBackButton);
    this.getData();
  },

  methods: {
    Debug() {
      console.log("EVENT TRIGGERED");
    },
    handleBackButton() {
      // Your event handling code here
      // This code will be executed when the user clicks the browser's back button
      console.log(
        "Browser back button clicked from scenario :" + this.current_scenario
      );
      if (this.current_scenario == "3") {
        console.log("Go back to Month Year Wise i.e scenario 2");
        this.form.month = "";
        this.form.year = "";
        this.getData();
      } else if (this.current_scenario == "2") {
        console.log("Go back to Publication Wise i.e scenario 1");
        this.form.publication_name = "";
        this.getData();
      } else {
        console.log("Default case");
      }
    },
    async getData() {
      window.history.pushState(null, null, location.href);
      this.isLoading = true;
      let response;
      this.form.publication_name = this.form.publication_name
        ? this.form.publication_name
        : "";
      this.form.month = this.form.month ? this.form.month : "";
      this.form.year = this.form.year ? this.form.year : "";
      switch (true) {
        case this.form.publication_name == "":
          // Publication Wise
          console.log("Publication Wise");
          this.current_scenario = "1";
          break;
        case this.form.month == "" && this.form.year == "":
          // Month Year Wise
          console.log("Month Wise");
          this.current_scenario = "2";
          break;
        case this.form.publication_name != "" &&
          // Publication Month Year Wise
          this.form.month != "" &&
          this.form.year != "":
          console.log("Publication Month Year Wise");
          this.current_scenario = "3";
          break;
        default:
          console.log("Default case");
          break;
      }
      let query =
        "publication_name=" +
        this.form.publication_name +
        "&scenario=" +
        this.current_scenario +
        "&month=" +
        this.form.month +
        "&year=" +
        this.form.year;
      response = await axios.get(`/publication_report?${query}`);
      this.PublicationReport = response.data.data;
      console.log("current_scenario :" + this.current_scenario);
      if (this.current_scenario == 1) {
        this.PublicationWiseData = [["1", "Count"]];
        this.PublicationReport.forEach((value) => {
          this.PublicationWiseData.push([value.publication, value.count]);
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
.v-text-field {
  border-radius: 8px 8px 8px 8px !important;
}
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
