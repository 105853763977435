<template>
  <v-main>
    <v-container fluid>
      <v-card-title>
        <!-- <backbutton :link="'/library'"></backbutton> -->
        <backbutton></backbutton>
        {{ articles.collection_name }}
        <v-spacer></v-spacer>

        <v-tooltip bottom v-if="roleName == `ACADEMIC TEAM`">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              dark
              v-bind="attrs"
              v-on="on"
              fab
              small
              @click="isLinkArticleDialog = true"
              color="primary"
            >
              <v-icon>mdi-link-variant</v-icon>
            </v-btn>
          </template>
          <span> Link Article </span>
        </v-tooltip>
        <v-btn
          v-if="
            roleName != 'STUDENT' &&
            roleName != 'INFAKT TEACHER' &&
            roleName != 'ACADEMIC TEAM' &&
            user.id == articles.user_id
          "
          dark
          small
          fab
          color="primary"
          @click="isShared = true"
          ><v-icon>mdi-share-variant-outline</v-icon></v-btn
        >
      </v-card-title>
      <v-card-subtitle
        class="mx-14"
        v-if="articles.student_instructions"
        v-html="articles.student_instructions"
      >
      </v-card-subtitle>
      <br />
      <v-row justify="start">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Articles'"></span>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col
          sm="3"
          v-for="(article, a) in articles.collection_contents"
          :key="`article${a}`"
        >
          <Article
            v-if="article.content.is_Enable_bookmark"
            :article="article.content"
            :source="'collection'"
            :collection="articles"
            :type="article.content.content_type"
          ></Article>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="isShared" max-width="600px">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn fab x-small dark color="red">
          <v-icon dark v-bind="attrs" v-on="on"> mdi-close-thick </v-icon>
        </v-btn>
      </template> -->

      <v-card elevation="10">
        <v-card-title>
          <v-icon large color="red darken-2">
            mdi-share-variant-outline
          </v-icon>
          &nbsp; Please select classcode to share your collection.
        </v-card-title>
        <v-col md="12">
          <v-autocomplete
            ref="classcode"
            v-model="form.classcodes"
            :items="classcodeItems"
            label="Classcode"
            prepend-icon="mdi-database-search"
            multiple
          ></v-autocomplete>
        </v-col>
        <v-card-text>
          <v-btn dark small color="primary" @click="SaveShareCollection()"
            >Share</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Link Article -->
    <v-dialog v-model="isLinkArticleDialog" max-width="1000px" persistent>
      <v-card elevation="2">
        <v-card-title>
          Articles
          <loader v-if="isSearching" size="25" class="ml-3"></loader
        ></v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="search_article_keyword"
                label="Search ... "
                prepend-inner-icon="mdi-magnify"
                @keydown.enter="searchArticles"
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <p class="font-weight-bold">Articles List</p>
          <br />
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(article, a) in searchedArticles"
              :key="`article${a}`"
            >
              <v-expansion-panel-header>
                <v-row>
                  <v-col sm="9">
                    {{ article.content_name }}
                  </v-col>
                  <v-col sm="3">
                    <v-btn
                      small
                      dark
                      color="primary"
                      @click="linkArticle(article)"
                    >
                      Link This Article
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- <div v-html="article.original_content"></div> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-dialog v-model="isViewArticleDialog" max-width="400px" persistent>
            <v-card elevation="2" align="center">
              <v-card-title class="justify-center primary accent-1 white--text">
                Data Saved Successfully
              </v-card-title>
              <br />
              <v-card-text>
                <v-btn color="primary" @click.stop="isViewArticleDialog = false"
                  >Close</v-btn
                >
              </v-card-text>
            </v-card>
          </v-dialog>
          <br />
          <v-btn color="primary" @click.stop="isLinkArticleDialog = false">
            Close
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import Article from "@/components/article.vue";
import Backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";

export default {
  data() {
    return {
      form: {
        collection_classcodes: [],
        classcodes: [],
      },
      isLoading: false,
      articles: [],
      searchedArticles: [],
      article: {},
      assignment: [],
      country: "",
      collection: "",
      countries: [],
      collections: [],
      // classcodes: [],
      classcodeItems: [],
      search_article_keyword: "",
      isLinkArticleDialog: false,
      isViewArticleDialog: false,
      isSearching: false,
      dialog: false,
      existingDialog: false,
      success: false,
      isShared: false,
    };
  },
  components: {
    Article,
    Backbutton,
    Loader,
  },
  mounted() {
    this.getData();
    this.getMaster();
  },
  methods: {
    // Get All Articles
    async getMaster() {
      let masters = await axios.get(`/collections?user_id=${this.user.id}`);
      this.masters = masters.data.data;
      this.masters.forEach((collection) => {
        this.collections.push({
          id: collection.id,
          text: collection.collection_name,
          value: collection.id,
        });
      });
      // classcodes
      this.user.user_classcodes.forEach((userClasscode) => {
        this.classcodeItems.push({
          id: userClasscode.classcode.id,
          text: userClasscode.classcode.classcode,
          value: userClasscode.classcode.id,
        });
      });
    },
    async getData() {
      this.articles = [];
      try {
        this.isLoading = true;
        let assignment_id = this.$route.query.assignment_id
          ? this.$route.query.assignment_id
          : "";
        let articles = await axios.get(
          `/collections/${this.$route.params.collectionId}?assignment_id=${assignment_id}`
        );
        this.articles = articles.data.data;
        console.log(this.articles);
        this.isLoading = false;
      } catch (e) {
        this.$router.push(`/library`);
        this.isLoading = false;
      }
    },
    // async shareCollection(collection_id) {
    //   // this.form.collection_id = collection_id;
    //   // this.form.shared_by_id = this.user.id;
    //   this.form.classcodes.forEach((classcode_id) => {
    //     let collection_classcode = {
    //       company_id: this.company.id,
    //       shared_by_id: this.user.id,
    //       classcode_id: classcode_id,
    //       collection_id: this.articles.id,
    //     };
    //     console.log(collection_classcode + "id");
    //     this.form.collection_classcodes.push(collection_classcode);
    //   });
    // },
    async SaveShareCollection() {
      try {
        this.form.classcodes.forEach((classcode_id) => {
          let collection_classcode = {
            company_id: this.company.id,
            shared_by_id: this.user.id,
            classcode_id: classcode_id,
            collection_id: this.articles.id,
          };
          console.log(collection_classcode + "id");
          this.form.collection_classcodes.push(collection_classcode);
        });
        console.log(this.form);
        await axios.post("/collection_classcodes", this.form);
        this.isLoading = false;
        this.isShared = false;
        // this.form.classcode_id = "";
      } catch (e) {
        this.isShared = false;
      }
    },
    async searchArticles() {
      this.isSearching = true;
      let query = "subject_id=&search_keyword=" + this.search_article_keyword;
      let articles = await axios.get(`/contents?${query}`);
      this.searchedArticles = articles.data.data;
      this.isSearching = false;
    },
    async linkArticle(article) {
      let content_id = article.id;
      this.isLoading = true;

      try {
        this.existingdialog = true;
        this.existMSG = "";
        let Cdata = {
          collection_id: this.$route.params.collectionId,
          content_id: content_id,
        };
        let response = await axios.post("/collection_contents", Cdata);
        this.existMSG = response.data.msg;

        if (this.existMSG == "") {
          this.success = true;
          // this.articles.push(article);
        }
        this.existingdialog = false;
        this.getData();
      } catch (e) {
        this.existingdialog = true;
      }
      this.isLoading = false;
      this.search_article_keyword = "";
      this.searchedArticles = [];
      this.isLinkArticleDialog = false;
    },
  },
};
</script>
