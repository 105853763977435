<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Assignments'"></span>
          <addbutton
            target="_blank"
            :link="'/assignments/create'"
            v-if="
              roleName == 'TEACHER' ||
              roleName == 'INAFKT TEACHER' ||
              roleName == 'ACADEMIC TEAM'
            "
          ></addbutton>
        </v-col>
      </v-row>
      <v-row>
        <template
          ><v-col md="3">
            <v-text-field
              data-v-step="0"
              v-model="search_keyword"
              label="Search ... "
              clearable
              @click:clear="search_keyword = null"
              prepend-inner-icon="mdi-magnify"
              @keydown.enter="getData"
              @change="page = 1"
            ></v-text-field>
          </v-col>
        </template>
        <v-col
          sm="3"
          v-if="roleName != 'INFAKT TEACHER' && roleName != 'ACADEMIC TEAM'"
        >
          <v-autocomplete
            ref="classcode"
            v-model="form.classcode_id"
            :items="classcodeItems"
            :label="roleName == `TEACHER` ? 'Classcode' : 'Subject'"
            prepend-icon="mdi-application-edit"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col sm="3" class="mt-3">
          <v-btn color="primary" :disabled="validationErrors" @click="getData">
            SEARCH
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          sm="2"
          class="mt-3"
          v-if="roleName == 'INFAKT TEACHER' || roleName == 'ACADEMIC TEAM'"
        >
          <v-btn
            class="ml-9 my-auto"
            fab
            x-small
            :color="is_list == false ? 'primary' : ''"
            @click="is_list = false"
          >
            <v-icon dark> mdi-grid </v-icon>
          </v-btn>
          |
          <v-btn
            class="my-auto"
            fab
            x-small
            :color="is_list == true ? 'primary' : ''"
            @click="is_list = true"
          >
            <v-icon> mdi-format-list-numbered </v-icon>
          </v-btn>
        </v-col>
        <v-col sm="2" class="mt-3 mr-1">
          <v-autocomplete
            style="min-width: 125px"
            ref="Sort"
            v-model="sort_type"
            :items="sortItems"
            label="Sort By"
            @change="sortAssignments"
            prepend-icon="mdi-sort"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-tabs fixed-tabs background-color="primary" dark v-model="tab">
        <v-tab
          href="#tab-1"
          v-if="
            roleName == 'STUDENT' ||
            roleName == 'TEACHER' ||
            (roleName == 'ADMIN' && form.teacher_id) ||
            (roleName == 'SUPER ADMIN' && company.id != null && form.teacher_id)
          "
        >
          In Progress [{{ inProgressAssignmentCount }}]
        </v-tab>
        <v-tab
          href="#tab-2"
          v-if="
            roleName == 'STUDENT' ||
            roleName == 'TEACHER' ||
            (roleName == 'ADMIN' && form.teacher_id) ||
            (roleName == 'SUPER ADMIN' && company.id != null && form.teacher_id)
          "
        >
          {{ roleName == "STUDENT" ? "Overdue" : "Completed" }} [{{
            teacherCompletedAssignmentCount
          }}]
        </v-tab>
        <v-tab href="#tab-3" v-if="roleName == 'STUDENT'">
          Completed [{{ studentCompleteAssignmentCount }}]
        </v-tab>
        <v-tab
          href="#tab-4"
          v-if="
            roleName == 'STUDENT' ||
            roleName == 'TEACHER' ||
            (roleName == 'ADMIN' && form.teacher_id) ||
            (roleName == 'SUPER ADMIN' && company.id != null && form.teacher_id)
          "
        >
          Upcoming [{{ upcomingAssignmentCount }}]
        </v-tab>
        <v-tab
          href="#tab-5"
          v-if="
            roleName == 'INFAKT TEACHER' ||
            roleName == 'ACADEMIC TEAM' ||
            roleName == 'TEACHER'
          "
        >
          Drafts [{{ draftAssignmentCount }}]
        </v-tab>
        <!-- <v-tab href="#tab-6" v-if="roleName == 'ADMIN'">
          By Admin [{{ adminAssignmentCount }}]</v-tab
        > -->
        <v-tab
          href="#tab-7"
          v-if="roleName == 'INFAKT TEACHER' || roleName == 'ACADEMIC TEAM'"
        >
          PENDING [{{ pendingAssignmentCount }}]
        </v-tab>
        <v-tab
          href="#tab-8"
          v-if="
            roleName == 'INFAKT TEACHER' ||
            roleName == 'ACADEMIC TEAM' ||
            roleName == 'TEACHER'
          "
        >
          {{ roleName == "TEACHER" ? "INFAKT TEAM" : "APPROVED" }} [{{
            infaktTeamAssignmentCount
          }}]
        </v-tab>
        <v-tab
          href="#tab-9"
          v-if="roleName == 'INFAKT TEACHER' || roleName == 'ACADEMIC TEAM'"
        >
          REJECTED [{{ rejectedAssignmentCount }}]
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in 9" :key="i" :value="'tab-' + i">
          <v-card flat>
            <v-card-text>
              <v-row>
                <!-- Grid View -->
                <template v-if="is_list == false">
                  <template v-for="(assignment, a) in assignments">
                    <v-col
                      sm="4"
                      :key="`assignment${a}`"
                      v-if="
                        isInProgress(assignment, i) || //1st TAB
                        isTeacherCompleted(assignment, i) || //2nd TAB
                        isStudentCompleted(assignment, i) || //3rd TAB
                        isUpcoming(assignment, i) || //4th TAB
                        isDraft(assignment, i) || //5th TAB
                        isByAdmin(assignment, i) || //6th TAB
                        isPending(assignment, i) || //7th TAB
                        isInfaktTeam(assignment, i) || //8th TAB
                        isRejected(assignment, i) //9th TAB
                      "
                    >
                      <v-card
                        :img="
                          assignment.category == 'COMPETITION'
                            ? 'https://t4.ftcdn.net/jpg/00/39/74/99/360_F_39749952_HvHpkvYuo6LLlhDfbAsJ1TgUWI4TBy6J.jpg'
                            : assignment.is_game
                            ? 'https://png.pngtree.com/background/20210710/original/pngtree-reading-dream-education-training-fresh-poster-background-picture-image_1019916.jpg'
                            : 'https://t3.ftcdn.net/jpg/05/00/34/58/360_F_500345899_4OqmtspFst6SRnNQvLj7h7TfKOrBwTer.jpg'
                        "
                        elevation="10"
                        class="mx-auto"
                        :height="
                          i < 6
                            ? assignment.my_results?.length > 0
                              ? '450'
                              : '380'
                            : '300'
                        "
                      >
                        <v-card-title
                          :class="`justify-center ${
                            assignment.category == 'COMPETITION'
                              ? 'primary white--text'
                              : 'primary white--text'
                          } accent-1`"
                        >
                          {{ assignment.assignment_title | limit30 }}
                        </v-card-title>
                        <v-card-text class="pt-3">
                          <div class="row">
                            <v-col>
                              <span v-if="assignment.duration">
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      x-small
                                      color="black"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon size="20">mdi-timer-alert</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>This is a timed assignment</span>
                                </v-tooltip>
                                ({{ assignment.duration }} min)
                              </span>
                              <span v-else> (No time limit) </span>
                            </v-col>
                            <v-col align="right">
                              <p>
                                Assignment ID |
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <b
                                      v-bind="attrs"
                                      v-on="on"
                                      class="primary--text"
                                    >
                                      {{ assignment.id }}
                                    </b>
                                  </template>
                                  <span>This is the Assignment ID</span>
                                </v-tooltip>
                              </p>
                            </v-col>
                          </div>
                          <div class="row">
                            <v-col align="left" class="wrap">
                              <template
                                v-if="
                                  (roleName == 'ACADEMIC TEAM' ||
                                    roleName == 'INFAKT TEACHER') &&
                                  assignment.company_id == null
                                "
                              >
                                <b> Created By:</b>
                                <br />
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      {{
                                        assignment.created_by
                                          ? assignment.created_by.first_name +
                                            " " +
                                            assignment.created_by.last_name
                                          : ""
                                      }}
                                    </span>
                                  </template>
                                  <span>{{
                                    assignment.created_by
                                      ? assignment.created_by.first_name +
                                        " " +
                                        assignment.created_by.last_name
                                      : ""
                                  }}</span>
                                </v-tooltip>
                              </template>
                              <template
                                v-if="
                                  (roleName != 'ACADEMIC TEAM' ||
                                    roleName != 'INFAKT TEACHER') &&
                                  assignment.company_id != null
                                "
                              >
                                <b> Assigned By:</b>
                                <br />
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      {{
                                        assignment.created_by
                                          ? assignment.created_by.first_name +
                                            " " +
                                            assignment.created_by.last_name
                                          : ""
                                      }}
                                    </span>
                                  </template>
                                  <span>{{
                                    assignment.created_by
                                      ? assignment.created_by.first_name +
                                        " " +
                                        assignment.created_by.last_name
                                      : ""
                                  }}</span>
                                </v-tooltip>
                              </template>
                            </v-col>
                            <v-col align="center">
                              <b v-if="assignment.content_description"
                                >Lexile Level:</b
                              >
                              <br />
                              {{
                                assignment.content_description
                                  ? assignment.content_description.level
                                  : ""
                              }}
                            </v-col>
                            <v-col align="right">
                              <b>Type:</b>
                              <br />
                              {{ assignment.assignment_type }}
                            </v-col>
                          </div>
                          <!-- IF Assignment IN Progress Or Upcoming -->
                          <template
                            v-if="assignment.assignment_classcodes?.length > 0"
                          >
                            <v-row>
                              <v-col md="4" class="pb-0" align="left">
                                <b>Classcode:</b>
                              </v-col>
                              <v-col md="4" class="pb-0" align="center">
                                <b
                                  v-if="
                                    (isAssignmentBetween(assignment) &&
                                      (assignment.my_results == null ||
                                        assignment.my_results?.length == 0) &&
                                      i == 1) ||
                                    (isAssignmentNow(assignment) &&
                                      (assignment.my_results == null ||
                                        assignment.my_results?.length == 0) &&
                                      i == 4)
                                  "
                                  >Start Date:</b
                                >
                              </v-col>
                              <v-col md="4" class="pb-0" align="right">
                                <b>Due Date:</b>
                              </v-col>
                            </v-row>
                            <div
                              v-show="
                                assignment.assignment_classcodes?.length > 0
                              "
                              style="
                                overflow-y: auto;
                                overflow-x: hidden;
                                height: 35px;
                              "
                              class="mt-5"
                            >
                              <!-- Scrollable Div for List of Classcodes -->
                              <div
                                v-show="assignment.assignment_classcodes"
                                class="row"
                                v-for="(
                                  assignmentClasscode, ac
                                ) in assignment.assignment_classcodes"
                                :key="`assignmentClasscode${ac}`"
                              >
                                <v-col md="4" class="wrap" align="left">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <span v-bind="attrs" v-on="on">
                                        <!-- {{ assignmentClasscode.classcode.classcode }} -->
                                        <classcode-element
                                          :classcode="
                                            assignmentClasscode.classcode
                                              .classcode
                                          "
                                          :type="
                                            roleName == 'STUDENT'
                                              ? 'SUBJECT'
                                              : roleName == 'TEACHER'
                                              ? 'STANDARD_SUBJECT'
                                              : ''
                                          "
                                        ></classcode-element>
                                      </span>
                                    </template>
                                    <span>{{
                                      assignmentClasscode.classcode.classcode
                                    }}</span>
                                  </v-tooltip>
                                </v-col>
                                <div
                                  align="center"
                                  class="col-md-4"
                                  v-if="
                                    (isAssignmentBetween(assignment) &&
                                      (assignment.my_results == null ||
                                        assignment.my_results?.length == 0) &&
                                      i == 1) ||
                                    (isAssignmentNow(assignment) &&
                                      (assignment.my_results == null ||
                                        assignment.my_results?.length == 0) &&
                                      i == 4)
                                  "
                                >
                                  {{
                                    assignmentClasscode.start_date | ddmmyyyy
                                  }}
                                </div>
                                <div
                                  align="right"
                                  class="col-md-4"
                                  v-if="
                                    (isAssignmentBetween(assignment) &&
                                      (assignment.my_results == null ||
                                        assignment.my_results?.length == 0) &&
                                      i == 1) ||
                                    (isAssignmentNow(assignment) &&
                                      (assignment.my_results == null ||
                                        assignment.my_results?.length == 0) &&
                                      i == 4)
                                  "
                                >
                                  {{ assignmentClasscode.end_date | ddmmyyyy }}
                                </div>
                                <div align="right" v-else class="col-md-8">
                                  {{ assignmentClasscode.end_date | ddmmyyyy }}
                                </div>
                              </div>
                              <!-- End of Scrollable Div for List of Classcodes -->
                            </div>
                          </template>
                          <!-- IF Single Student Assignment -->
                          <template v-if="assignment.user_id">
                            <v-row v-show="assignment.user_id">
                              <v-col md="4" class="pb-0" align="left">
                                <b>TSI:</b>
                              </v-col>
                              <v-col md="4" class="pb-0" align="center">
                                <b
                                  v-if="
                                    (isAssignmentBetween(assignment) &&
                                      (assignment.my_results == null ||
                                        assignment.my_results?.length == 0) &&
                                      i == 1) ||
                                    (isAssignmentNow(assignment) &&
                                      (assignment.my_results == null ||
                                        assignment.my_results?.length == 0) &&
                                      i == 4)
                                  "
                                  >Start Date:</b
                                >
                              </v-col>
                              <v-col md="4" class="pb-0" align="right">
                                <b>Due Date:</b>
                              </v-col>
                            </v-row>
                            <v-row
                              v-show="assignment.user_id"
                              class="pa-0 mt-5"
                            >
                              <v-col md="4" class="wrap pa-0 pl-3" align="left">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      {{ assignment.user.name }}
                                    </span>
                                  </template>
                                  <span>{{ assignment.user.name }}</span>
                                </v-tooltip>
                              </v-col>
                              <v-col
                                align="center"
                                md="4"
                                class="py-0"
                                v-if="
                                  (isAssignmentBetween(assignment) &&
                                    (assignment.my_results == null ||
                                      assignment.my_results?.length == 0) &&
                                    i == 1) ||
                                  (isAssignmentNow(assignment) &&
                                    (assignment.my_results == null ||
                                      assignment.my_results?.length == 0) &&
                                    i == 4)
                                "
                              >
                                {{ assignment.start_date | ddmmyyyy }}
                              </v-col>
                              <v-col
                                align="right"
                                md="4"
                                class="py-0"
                                v-if="
                                  (isAssignmentBetween(assignment) &&
                                    (assignment.my_results == null ||
                                      assignment.my_results?.length == 0) &&
                                    i == 1) ||
                                  (isAssignmentNow(assignment) &&
                                    (assignment.my_results == null ||
                                      assignment.my_results?.length == 0) &&
                                    i == 4)
                                "
                              >
                                {{ assignment.end_date | ddmmyyyy }}
                              </v-col>
                              <v-col md="8" align="right" v-else class="py-0">
                                {{ assignment.end_date | ddmmyyyy }}
                              </v-col>
                            </v-row>
                          </template>
                          <v-row class="pt-4">
                            <v-col class="py-0">
                              <b>Max Marks:</b>
                              <br />
                              {{ assignment.maximum_marks }}
                            </v-col>
                            <v-col align="right" class="py-0">
                              <div v-if="assignment.content_id">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-if="assignment.content_description_id"
                                      target="_blank"
                                      class="my-auto"
                                      color="primary"
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      :to="`/articles/${assignment.content_id}/${assignment.content_description_id}`"
                                    >
                                      View
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      target="_blank"
                                      class="my-auto"
                                      color="primary"
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      :to="`/articles/${assignment.content_id}`"
                                    >
                                      View
                                    </v-btn>
                                  </template>
                                  <span>View linked Article</span>
                                </v-tooltip>
                              </div>
                              <div v-if="assignment.collection_id">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      target="_blank"
                                      class="my-auto"
                                      small
                                      color="primary"
                                      :to="`/library/collections/${assignment.collection_id}?assignment_id=${assignment.id}`"
                                    >
                                      View
                                    </v-btn>
                                  </template>
                                  <span>View linked Collection</span>
                                </v-tooltip>
                              </div>
                            </v-col>
                          </v-row>
                          <div class="row">
                            <v-col
                              :class="
                                roleName == 'INFAKT TEACHER' ||
                                roleName == 'ACADEMIC TEAM'
                                  ? 'pt-0'
                                  : ''
                              "
                            >
                              <!-- For Teacher -->
                              <span
                                v-if="
                                  roleName == 'TEACHER' ||
                                  roleName == 'ADMIN' ||
                                  roleName == 'INFAKT TEACHER' ||
                                  roleName == 'ACADEMIC TEAM'
                                "
                              >
                                <div class="row pb-1">
                                  <v-col
                                    v-if="
                                      roleName == 'TEACHER' &&
                                      assignment.company_id != null &&
                                      assignment.status != 3
                                    "
                                  >
                                    <v-btn
                                      v-if="assignment.category == 'ASSIGNMENT'"
                                      color="orange"
                                      dark
                                      :to="`/assignments/${assignment.id}/submissions`"
                                    >
                                      Check Submissions
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      color="orange"
                                      dark
                                      :to="`/assignments/${assignment.id}/submissions`"
                                    >
                                      View Competition Result
                                    </v-btn>
                                  </v-col>
                                  <v-col
                                    v-if="
                                      (roleName == 'INFAKT TEACHER' ||
                                        roleName == 'ACADEMIC TEAM') &&
                                      assignment.status == 2
                                    "
                                  >
                                    <v-tooltip right>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          target="_blank"
                                          size="20"
                                          v-bind="attrs"
                                          v-on="on"
                                          rounded
                                          color="primary"
                                          @click="
                                            showAssignmentRemark(assignment.id)
                                          "
                                        >
                                          <v-icon> mdi-eye-outline </v-icon>
                                          <span class="ml-1"> Remark </span>
                                        </v-btn>
                                      </template>
                                      <span>View Remark</span>
                                    </v-tooltip>
                                  </v-col>
                                  <v-col align="right">
                                    <v-btn
                                      v-if="assignment.status != 1"
                                      class="ml-10 my-auto"
                                      fab
                                      x-small
                                      color="primary"
                                      :to="`/assignments/${assignment.id}`"
                                    >
                                      <v-icon dark> mdi-pencil </v-icon>
                                    </v-btn>
                                    <!-- Delete Draft Assignment -->
                                    <v-tooltip
                                      bottom
                                      v-if="assignment.status == 3"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          icon
                                          class="my-auto"
                                          color="error"
                                          @click="Delete_Draft(assignment.id)"
                                        >
                                          <v-icon dark size="38">
                                            mdi-delete
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Delete this Draft</span>
                                    </v-tooltip>
                                  </v-col>
                                </div>
                              </span>
                              <!-- For Student -->
                              <template v-if="roleName == 'STUDENT'">
                                <!-- Result Declared Section -->
                                <div v-if="assignment.my_results?.length > 0">
                                  <!-- For Game -->
                                  <div v-if="assignment.is_game">
                                    <a
                                      color="orange"
                                      dark
                                      target="_blank"
                                      :href="assignment.game_result_link"
                                    >
                                      CHECK RESULT
                                    </a>
                                  </div>
                                  <!-- for Assignments -->
                                  <div v-else>
                                    <p
                                      v-if="
                                        assignment.my_results[0].result_declared
                                      "
                                    >
                                      Result declared:
                                      {{ assignment.my_results[0].score }} /
                                      {{ assignment.maximum_marks }}
                                    </p>
                                    <p v-else>Result not yet declared</p>
                                    <v-btn
                                      v-if="assignment.category == 'ASSIGNMENT'"
                                      color="orange"
                                      dark
                                      @click="checkResult(assignment)"
                                    >
                                      CHECK RESULT
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      color="orange"
                                      dark
                                      :to="`/assignments/${assignment.id}/submissions`"
                                    >
                                      View Competition Result
                                    </v-btn>
                                  </div>
                                </div>
                                <div v-else>
                                  <!-- Take Assignment Dialog -->
                                  <v-dialog
                                    v-model="isTakeAssignmentDialogs[a]"
                                    max-width="400"
                                    v-if="
                                      roleName == 'STUDENT' &&
                                      ((isAssignmentBetween(assignment) &&
                                        i == 1) ||
                                        assignment.my_assignment_extensions.find(
                                          (mae) =>
                                            mae.is_approved == 1 &&
                                            isDatePassed(
                                              mae.approved_extension_date
                                            )
                                        ))
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        BEGIN
                                      </v-btn>
                                    </template>
                                    <v-container>
                                      <v-card
                                        elevation="10"
                                        class="rounded-tl-xl rounded-br-xl"
                                      >
                                        <v-card-title
                                          class="justify-center primary accent-1 white--text"
                                        >
                                          <span v-if="assignment.duration">
                                            Once started, you will have
                                            {{ assignment.duration }} minutes to
                                            complete the assignment.
                                            <br />
                                            Do you wish to begin?
                                          </span>
                                          <span v-else>
                                            Do you want to begin the assignment?
                                          </span>
                                        </v-card-title>
                                        <v-card-text class="pa-0">
                                          <div class="row pt-0">
                                            <div class="col-md-6">
                                              <v-btn
                                                text
                                                x-large
                                                dark
                                                block
                                                color="success"
                                                :to="`/assignments/${assignment.id}/take-assignment`"
                                              >
                                                <div
                                                  class="text-h6 font-weight-bold"
                                                >
                                                  YES
                                                </div>
                                              </v-btn>
                                            </div>
                                            <div class="col-md-6">
                                              <v-btn
                                                dark
                                                block
                                                x-large
                                                text
                                                color="red"
                                                @click="
                                                  closeTakeAssignmentDialog(a)
                                                "
                                              >
                                                <div
                                                  class="text-h6 font-weight-bold"
                                                >
                                                  NO
                                                </div>
                                              </v-btn>
                                            </div>
                                          </div>
                                        </v-card-text>
                                      </v-card>
                                    </v-container>
                                  </v-dialog>
                                  <!-- Extension Section -->
                                  <template v-if="isAssignmentOld(assignment)">
                                    <template
                                      v-if="
                                        assignment.my_assignment_extensions
                                          ?.length == 0
                                      "
                                    >
                                      <!-- Extend Assignment Dialog -->
                                      <div
                                        v-if="isExtensionAcceptable(assignment)"
                                      >
                                        <v-btn
                                          color="orange"
                                          dark
                                          @click="RequestExtension(assignment)"
                                        >
                                          EXTEND ASSIGNMENT
                                        </v-btn>
                                      </div>
                                      <template v-else>
                                        <p
                                          class="font-weight-bold"
                                          style="color: red"
                                        >
                                          The due date to request an extension
                                          has passed.
                                        </p>
                                      </template>
                                    </template>
                                    <!-- Extension Already Sent then Show Message -->
                                    <template v-else>
                                      <p
                                        class="font-weight-bold"
                                        v-if="
                                          assignment.my_assignment_extensions.find(
                                            (mae) => mae.is_approved == 0
                                          )
                                        "
                                      >
                                        Request for extension already sent.
                                      </p>
                                      <p
                                        class="font-weight-bold"
                                        style="color: green"
                                        v-else-if="
                                          assignment.my_assignment_extensions.find(
                                            (mae) => mae.is_approved == 1
                                          )
                                        "
                                      >
                                        Request for extension approved.
                                      </p>
                                      <p
                                        class="font-weight-bold"
                                        style="color: red"
                                        v-else
                                      >
                                        Request for extension denied.
                                      </p>
                                    </template>
                                  </template>
                                </div>
                              </template>
                            </v-col>
                            <v-col
                              align="right"
                              v-if="
                                (roleName == 'TEACHER' ||
                                  roleName == 'ADMIN') &&
                                assignment.company_id == null &&
                                i == 8
                              "
                              ><v-btn
                                :to="`/assignments/create?copyFromAssignmentId=${assignment.id}`"
                                dark
                                small
                                color="primary"
                              >
                                Copy Assignment
                              </v-btn></v-col
                            >
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </template>
                </template>
                <template v-else>
                  <v-simple-table
                    fixed-header
                    height="525px"
                    style="width: -webkit-fill-available"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" style="min-width: 56px">
                            Assignment ID
                          </th>
                          <th class="text-left" style="min-width: 65px">
                            Assignment Title
                          </th>
                          <th class="text-center" style="min-width: 65px">
                            Created By
                          </th>
                          <th class="text-center" style="min-width: 80px">
                            Lexile Level
                          </th>
                          <th class="text-center" style="min-width: 80px">
                            Type
                          </th>
                          <th class="text-center" style="min-width: 85px">
                            Max Mark
                          </th>
                          <th class="text-right" style="min-width: 70px">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(assignment, a) in assignments"
                          :key="`assignment${a}`"
                        >
                          <template
                            v-if="
                              isInProgress(assignment, i) || //1st TAB
                              isTeacherCompleted(assignment, i) || //2nd TAB
                              isStudentCompleted(assignment, i) || //3rd TAB
                              isUpcoming(assignment, i) || //4th TAB
                              isDraft(assignment, i) || //5th TAB
                              isByAdmin(assignment, i) || //6th TAB
                              isPending(assignment, i) || //7th TAB
                              isInfaktTeam(assignment, i) || //8th TAB
                              isRejected(assignment, i) //9th TAB
                            "
                          >
                            <td>{{ assignment.id }}</td>
                            <td>{{ assignment.assignment_title }}</td>
                            <td class="text-center">
                              {{ assignment.created_by.name }}
                            </td>
                            <td class="text-center">
                              {{
                                assignment.created_by.first_name +
                                " " +
                                assignment.created_by.last_name
                              }}
                            </td>
                            <td class="text-center">
                              {{ assignment.assignment_type }}
                            </td>
                            <td class="text-center">
                              {{ assignment.maximum_marks }}
                            </td>
                            <td class="text-right">
                              <template v-if="assignment.content_id">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-if="assignment.content_description_id"
                                      target="_blank"
                                      class="my-auto"
                                      color="primary"
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      :to="`/articles/${assignment.content_id}/${assignment.content_description_id}`"
                                    >
                                      View
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      target="_blank"
                                      class="my-auto"
                                      color="primary"
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      :to="`/articles/${assignment.content_id}`"
                                    >
                                      View
                                    </v-btn>
                                  </template>
                                  <span>View linked Article</span>
                                </v-tooltip>
                              </template>
                              <template v-if="assignment.collection_id">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      target="_blank"
                                      class="my-auto"
                                      small
                                      color="primary"
                                      :to="`/library/collections/${assignment.collection_id}?assignment_id=${assignment.id}`"
                                    >
                                      View
                                    </v-btn>
                                  </template>
                                  <span>View linked Collection</span>
                                </v-tooltip>
                              </template>
                              <template
                                v-if="
                                  (roleName == 'INFAKT TEACHER' ||
                                    roleName == 'ACADEMIC TEAM') &&
                                  assignment.status == 2
                                "
                              >
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      target="_blank"
                                      size="20"
                                      v-bind="attrs"
                                      v-on="on"
                                      rounded
                                      color="primary"
                                      class="ma-2"
                                      @click="
                                        showAssignmentRemark(assignment.id)
                                      "
                                    >
                                      <v-icon> mdi-eye-outline </v-icon>
                                      <span class="ml-1"> Remark </span>
                                    </v-btn>
                                  </template>
                                  <span>View Remark</span>
                                </v-tooltip>
                              </template>
                              <v-btn
                                v-if="assignment.status != 1"
                                class="ml-10 my-auto"
                                fab
                                x-small
                                color="primary"
                                :to="`/assignments/${assignment.id}`"
                              >
                                <v-icon dark> mdi-pencil </v-icon>
                              </v-btn>
                              <!-- Delete Draft Assignment -->
                              <v-tooltip bottom v-if="assignment.status == 3">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    class="my-auto"
                                    color="error"
                                    @click="Delete_Draft(assignment.id)"
                                  >
                                    <v-icon dark size="38"> mdi-delete </v-icon>
                                  </v-btn>
                                </template>
                                <span>Delete this Draft</span>
                              </v-tooltip>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-row>
              <!-- Result Not Declared Dialog -->
              <v-dialog
                v-model="isResultNotDeclaredDialog"
                max-width="300px"
                persistent
              >
                <v-card elevation="2" align="right">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Result is not yet declared
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-btn
                      color="primary"
                      @click.stop="closeResultNotDeclaredDialog"
                      >Close</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- Assignment Extension Done Dialod -->
              <v-dialog
                v-model="isAssignmentExtensionDoneDialog"
                max-width="400px"
                persistent
              >
                <v-card elevation="2" align="right">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Assignment Extension Request Sent
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-btn
                      color="primary"
                      @click.stop="closeAssignmentExtensionDoneDialog"
                      >Close</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- Remark message Pop Up -->
              <v-dialog v-model="isRemarkDialog" max-width="900px" persistent>
                <v-card elevation="2" align="right">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Remark
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-row justify="center">
                      <v-col>
                        <span v-html="assignment_details.remarks"></span>
                      </v-col>
                    </v-row>
                    <v-btn
                      color="primary"
                      @click.stop="
                        (isRemarkDialog = false), (assignment_details = [])
                      "
                      >Close</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <!--Request for Extension Dialog -->
    <v-dialog v-model="isExtendAssignmentDialog" max-width="400px">
      <v-card elevation="10">
        <v-card-title class="justify-center primary accent-1 white--text">
          Request to Extend the Assignment
        </v-card-title>
        <br />
        <v-card-text>
          <v-text-field
            outlined
            class="pb=0"
            v-model="assignment_extension.extension_reason"
            label="Reason for Extension"
            prepend-inner-icon="mdi-draw-pen"
          ></v-text-field>
          <v-menu
            v-model="requestExtensionDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="assignment_extension.expected_extension_date"
                label="Requested Extension Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :error-messages="errors.expected_extension_date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="assignment_extension.expected_extension_date"
              @change="requestExtensionDateMenu = false"
              :min="nowDate"
            ></v-date-picker>
          </v-menu>
          <loader v-if="isRequestExtensionLoading"></loader>
          <v-btn
            v-else
            color="primary"
            @click="closeExtendAssignmentDialog(assignment_details)"
          >
            Request Extension
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--End of Request for Extension Dialog -->
    <!-- Delete Assignment Success Dialog Box -->
    <v-dialog v-model="is_deleted_assignment" width="600">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: green; font-size: 90px; width: 200px !important"
              >
                mdi-delete-empty
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">Hurray!</h1>
                    <br />
                    <h3>Removed Assignment successfully.</h3>
                    <br />
                    <v-btn
                      color="success"
                      @click="(is_deleted_assignment = false), $router.go(0)"
                    >
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- /Delete Bookmark Success Dialog Box -->
  </v-main>
</template>
<script>
import axios from "axios";
import moment from "moment";
import addbutton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { addbutton, Loader },
  name: "AssignmentsPage",
  data() {
    return {
      isLoading: false,
      isRequestExtensionLoading: false,
      isResultNotDeclaredDialog: false,
      isTakeAssignmentDialog: false,
      isRemarkDialog: false,
      is_deleted_assignment: false,
      isTakeAssignmentDialogs: [],
      isExtendAssignmentDialog: false,
      isAssignmentExtensionDoneDialog: false,
      is_list: false,
      nowDate: new Date().toISOString().slice(0, 10),
      tab: "tab-1",
      search_keyword: "",
      validationErrors: false,
      validationClasscodeErrors: [],
      validationSectionErrors: [],
      assignments: [],
      assignment_details: [],
      assignment_extension: {
        assignment_id: "",
        user_id: "",
        extension_reason: "",
        expected_extension_date: "",
        approved_extension_date: "",
      },
      requestExtensionDateMenu: false,
      form: {
        section_id: "",
        standard_id: "",
        classcode_id: "",
        teacher_id: "",
      },
      sort_type: "LATEST",
      sortItems: ["LATEST", "OLDEST", "TITLE"],
      classcodes: [],
      classcodeItems: [],
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  computed: {
    // Compute the count of visible assignments
    inProgressAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isInProgress(assignment, 1);
      }).length;
    },
    teacherCompletedAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isTeacherCompleted(assignment, 2);
      }).length;
    },
    studentCompleteAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isStudentCompleted(assignment, 3);
      }).length;
    },
    upcomingAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isUpcoming(assignment, 4);
      }).length;
    },
    draftAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isDraft(assignment, 5);
      }).length;
    },
    adminAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isByAdmin(assignment, 6);
      }).length;
    },
    pendingAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isPending(assignment, 7);
      }).length;
    },
    infaktTeamAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isInfaktTeam(assignment, 8);
      }).length;
    },
    rejectedAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isRejected(assignment, 9);
      }).length;
    },
  },
  mounted() {
    this.form.teacher_id = this.$route.query.teacher_id
      ? this.$route.query.teacher_id
      : "";
    this.getMasters();
    this.getData();
    if (this.$route.query.tab) this.tab = this.$route.query.tab;
    if (this.roleName == "STUDENT") {
      this.user.user_classcodes.forEach((sc) => {
        this.classcodeItems.push({
          id: sc.classcode.id,
          text: sc.classcode.subject_name,
          value: sc.classcode.id,
        });
      });
    }
  },
  methods: {
    validateSelectedItem() {
      if (this.roleName != "STUDENT") {
        this.validationErrors = true;
        if (
          this.form.standard_id != null &&
          (this.form.section_id == "" || this.form.section_id == null)
        ) {
          this.validationSectionErrors = ["Select the Section"];
        } else if (
          this.form.section_id != null &&
          (this.form.classcode_id == "" || this.form.classcode_id == null)
        ) {
          this.validationSectionErrors = [];
          this.validationClasscodeErrors = ["Select the Classcode"];
        } else {
          this.validationErrors = false;
          this.validationSectionErrors = [];
          this.validationClasscodeErrors = [];
        }
      }
    },
    async getMasters() {
      try {
        this.form.teacher_id;
        let query = "teacher_id=" + this.form.teacher_id;
        let masters = await axios.get(`users/masters?${query}`);
        masters = masters.data;
        this.classcodes = masters.classcodes;
        // ClassCodes
        this.classcodes = [...new Set(this.classcodes)];
        this.classcodes.forEach((classcode) => {
          let originalClasscode = classcode.classcode;
          let classcode_array = classcode.classcode.split("/");
          let standard_section = classcode_array[1];
          let subject_section = classcode_array[2];
          let trimmed_classcode = "";
          if (this.roleName == "STUDENT") {
            trimmed_classcode = subject_section;
          } else if (this.roleName == "TEACHER") {
            trimmed_classcode = standard_section + "/" + subject_section;
          } else {
            trimmed_classcode = originalClasscode;
          }
          this.classcodeItems.push({
            id: classcode.id,
            text: trimmed_classcode,
            value: classcode.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        this.isLoading = true;
        this.assignments = [];
        this.form.classcode_id = this.form.classcode_id
          ? this.form.classcode_id
          : "";
        this.search_keyword = this.search_keyword ? this.search_keyword : "";
        let assignments = [];
        let query =
          "classcode_id=" +
          this.form.classcode_id +
          "&search_keyword=" +
          this.search_keyword +
          "&teacher_id=" +
          this.form.teacher_id;
        assignments = await axios.get(`/assignments?${query}`);
        if (this.$route.query.type == "competition")
          this.assignments = assignments.data.data.filter(
            (d) => d.category == "COMPETITION"
          );
        else
          this.assignments = assignments.data.data.filter(
            (d) => d.category != "COMPETITION"
          );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    checkResult(assignment) {
      if (assignment.my_results[0].result_declared) {
        this.$router.push(
          `/assignments/${assignment.id}/check-result/${assignment.my_results[0].id}`
        );
      } else this.isResultNotDeclaredDialog = true;
    },
    closeResultNotDeclaredDialog() {
      this.isResultNotDeclaredDialog = false;
    },
    async closeExtendAssignmentDialog(assignment) {
      try {
        this.isRequestExtensionLoading = true;
        this.assignment_extension.assignment_id = assignment.id;
        this.assignment_extension.user_id = this.user.id;
        let assignment_extension = "";
        let response = await axios
          .post("assignment_extensions", this.assignment_extension)
          .catch(() => {
            this.isRequestExtensionLoading = false;
          });
        assignment_extension = response.data.data;
        if (assignment_extension) {
          assignment.assignment_extensions.push(assignment_extension);
          this.isExtendAssignmentDialog = false;
          this.isAssignmentExtensionDoneDialog = true;
        }
        this.isRequestExtensionLoading = false;
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
    closeAssignmentExtensionDoneDialog() {
      this.isAssignmentExtensionDoneDialog = false;
    },
    closeTakeAssignmentDialog() {
      this.isTakeAssignmentDialogs = [];
    },
    showAssignmentRemark(assignment_id) {
      this.assignment_details = [];
      this.assignment_details = this.assignments.filter(
        (assignment) => assignment.id == assignment_id
      )[0];
      if (this.assignment_details) {
        this.isRemarkDialog = true;
      }
    },
    isAssignmentNow(assignment) {
      var startDate;
      var currentDate = moment().format("YYYY-MM-DD");
      var response = false;
      // For Student
      if (this.roleName == "STUDENT") {
        if (assignment.my_assignment_classcodes?.length > 0) {
          startDate = moment(
            assignment.my_assignment_classcodes[0].start_date
          ).format("YYYY-MM-DD");
        }
        if (assignment.start_date != null) {
          startDate = moment(assignment.start_date).format("YYYY-MM-DD");
        }
        if (startDate > currentDate) response = true;
      }
      // For Teacher
      else {
        if (assignment.assignment_classcodes) {
          assignment.assignment_classcodes.forEach((aC) => {
            startDate = moment(aC.start_date).format("YYYY-MM-DD");
            if (startDate > currentDate) response = true;
          });
        }
      }
      return response;
    },
    isAssignmentBetween(assignment) {
      var currentDate = moment().format("YYYY-MM-DD");
      var response = false;
      var startDate;
      var endDate;
      // For Student
      if (this.roleName == "STUDENT") {
        if (assignment.my_assignment_classcodes?.length > 0) {
          startDate = moment(
            assignment.my_assignment_classcodes[0].start_date
          ).format("YYYY-MM-DD");
          endDate = moment(
            assignment.my_assignment_classcodes[0].end_date
          ).format("YYYY-MM-DD");
        }
        if (assignment.start_date != null && assignment.end_date != null) {
          startDate = moment(assignment.start_date).format("YYYY-MM-DD");
          endDate = moment(assignment.end_date).format("YYYY-MM-DD");
        }
        if (startDate <= currentDate && endDate >= currentDate) response = true;
      }
      // For Teacher
      else {
        if (assignment.assignment_classcodes) {
          assignment.assignment_classcodes.forEach((aC) => {
            startDate = moment(aC.start_date).format("YYYY-MM-DD");
            endDate = moment(aC.end_date).format("YYYY-MM-DD");
          });
        }
        if (assignment.start_date != null && assignment.end_date != null) {
          startDate = moment(assignment.start_date).format("YYYY-MM-DD");
          endDate = moment(assignment.end_date).format("YYYY-MM-DD");
        }
        if (startDate <= currentDate && endDate >= currentDate) response = true;
      }
      return response;
    },
    isAssignmentOld(assignment) {
      var currentDate = moment().format("YYYY-MM-DD");
      var response = false;
      var endDate;
      // For Student
      if (this.roleName == "STUDENT") {
        if (assignment.my_assignment_classcodes?.length > 0) {
          endDate = moment(
            assignment.my_assignment_classcodes[0].end_date
          ).format("YYYY-MM-DD");
        }
        if (assignment.end_date != null) {
          endDate = moment(assignment.end_date).format("YYYY-MM-DD");
        }
        if (endDate < currentDate) response = true;
      }
      // For Teacher
      else {
        if (assignment.assignment_classcodes) {
          assignment.assignment_classcodes.forEach((aC) => {
            endDate = moment(aC.end_date).format("YYYY-MM-DD");
          });
        }
        if (assignment.end_date != null) {
          endDate = moment(assignment.end_date).format("YYYY-MM-DD");
        }
        if (endDate < currentDate) response = true;
      }
      return response;
    },
    RequestExtension(assignment) {
      this.assignment_details = assignment;
      this.isExtendAssignmentDialog = true;
    },
    isDatePassed(from_Date) {
      var startDate;
      var currentDate = moment().format("YYYY-MM-DD");
      var response = false;
      // For Student
      startDate = moment(from_Date).format("YYYY-MM-DD");
      if (startDate >= currentDate) response = true;
      return response;
    },
    async Delete_Draft(id) {
      let is_deleted = await axios.delete(`/assignments/${id}`);
      if (is_deleted) {
        this.is_deleted_assignment = true;
      }
    },
    isExtensionAcceptable(assignment) {
      var currentDate = moment().format("YYYY-MM-DD");
      let response = false;
      var endDate;
      if (this.isAssignmentOld(assignment)) {
        if (assignment.my_assignment_classcodes?.length)
          endDate = assignment.my_assignment_classcodes[0].end_date;
        if (assignment.end_date) endDate = assignment.end_date;
        var extensionAcceptableDate = moment(endDate)
          .add("2", "days")
          .format("YYYY-MM-DD");
        if (extensionAcceptableDate > currentDate) response = true;
      }
      return response;
    },
    isInProgress(assignment, tabIndex) {
      const response =
        this.isAssignmentBetween(assignment) &&
        (assignment.my_results == null || assignment.my_results.length === 0) &&
        assignment.status !== 3 &&
        tabIndex === 1;
      return response;
    },
    isTeacherCompleted(assignment, tabIndex) {
      const response =
        this.isAssignmentOld(assignment) &&
        (assignment.my_results == null || assignment.my_results?.length == 0) &&
        assignment.status != 3 &&
        tabIndex === 2;
      return response;
    },
    isStudentCompleted(assignment, tabIndex) {
      const response =
        assignment.my_results != null &&
        assignment.my_results?.length != 0 &&
        assignment.status != 3 &&
        tabIndex === 3;
      return response;
    },
    isUpcoming(assignment, tabIndex) {
      const response =
        this.isAssignmentNow(assignment) &&
        (assignment.my_results == null || assignment.my_results?.length == 0) &&
        assignment.status != 3 &&
        tabIndex == 4;
      return response;
    },
    isDraft(assignment, tabIndex) {
      const response =
        (assignment.created_by.roles[0].name == "INFAKT TEACHER" ||
          assignment.created_by.roles[0].name == "ACADEMIC TEAM" ||
          assignment.created_by.roles[0].name == "TEACHER") &&
        assignment.status == 3 &&
        tabIndex == 5;
      return response;
    },
    isByAdmin(assignment, tabIndex) {
      const response =
        assignment.created_by.roles[0].name == "ADMIN" &&
        assignment.status != 3 &&
        tabIndex == 6;
      return response;
    },
    isPending(assignment, tabIndex) {
      const response =
        (assignment.created_by.roles[0].name == "INFAKT TEACHER" ||
          assignment.created_by.roles[0].name == "ACADEMIC TEAM") &&
        assignment.status == 0 &&
        tabIndex == 7;
      return response;
    },
    isInfaktTeam(assignment, tabIndex) {
      const response =
        (assignment.created_by.roles[0].name == "INFAKT TEACHER" ||
          assignment.created_by.roles[0].name == "ACADEMIC TEAM") &&
        assignment.status == 1 &&
        tabIndex == 8;
      return response;
    },
    isRejected(assignment, tabIndex) {
      const response =
        (assignment.created_by.roles[0].name == "INFAKT TEACHER" ||
          assignment.created_by.roles[0].name == "ACADEMIC TEAM") &&
        assignment.status == 2 &&
        tabIndex == 9;
      return response;
    },
    sortAssignments() {
      this.assignments.sort((a, b) => {
        if (this.sort_type == "TITLE")
          return a.assignment_title.localeCompare(b.assignment_title);
        if (this.sort_type == "LATEST") return b.id - a.id;
        if (this.sort_type == "OLDEST") return a.id - b.id;
      });
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
