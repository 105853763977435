<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 110px">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Task Wise Reports'"
          ></span>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="standard"
            v-model="form.standard_id"
            :items="standardItems"
            label="Standard"
            clearable
            :error-messages="errors.standard_id"
            @change="getStandardSections"
            prepend-icon="mdi-alarm-bell"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="section"
            v-model="form.section_id"
            @change="getSectionClasscodes"
            :items="sectionItems"
            clearable
            label="Section"
            :error-messages="errors.section_id"
            prepend-icon="mdi-account-school"
          ></v-autocomplete>
        </v-col>
        <v-col></v-col>
        <v-col class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <!--/ Filters Section -->

      <!-- Reports Section -->
      <loader v-if="isLoading"></loader>
      <div v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-actions>
              <v-spacer></v-spacer>
              <download-excel
                v-show="report_excel.length"
                :title="title"
                :data="report_excel"
                :fields="excel_fields"
                worksheet="Task Wise Report"
                name="Task-Wise-Report.xls"
              >
                <v-btn color="primary">
                  <v-icon size="20">mdi-microsoft-excel</v-icon> &nbsp;Download
                </v-btn>
              </download-excel>
            </v-card-actions>
            <v-card-text>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="min-width: 56px">Sr No</th>
                      <th class="text-center" style="min-width: 65px">Name</th>
                      <!-- <th class="text-center" style="min-width: 65px">Email</th> -->
                      <th class="text-center" style="min-width: 50px">
                        Assignment [ Submitted / Posted ]
                      </th>
                      <!-- <th class="text-center" style="min-width: 100px">
                        Assignment Posted
                      </th> -->
                      <th class="text-center" style="min-width: 80px">
                        Submission Rate [%]
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(student, i) in task_wise_overview"
                      :key="student.name"
                    >
                      <td>{{ i + 1 }}</td>
                      <td class="text-center">{{ student.name }}</td>
                      <!-- <td class="text-center">{{ student.email }}</td> -->
                      <td class="text-center">
                        {{ student.completed_count }} /
                        {{ student.totalAssignmentsCount }}
                      </td>
                      <td class="text-center" style="min-width: 130px">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"
                              >{{
                                Math.round(
                                  (student.completed_count /
                                    student.totalAssignmentsCount) *
                                    100
                                )
                              }}
                              %</span
                            >
                          </template>
                          <span
                            >{{ student.completed_count }} /
                            {{ student.totalAssignmentsCount }} = [ Total
                            Attempted / Total Assignment ]
                          </span>
                        </v-tooltip>
                      </td>
                      <!-- <td class="text-center">
                        {{ student.totalAssignmentsCount }}
                      </td> -->
                      <td style="width: 250px">
                        <v-progress-linear
                          background-color="amber"
                          color="success"
                          height="10"
                          striped
                          :value="
                            Math.round(
                              (student.completed_count /
                                student.totalAssignmentsCount) *
                                100
                            )
                          "
                        ></v-progress-linear>
                      </td>
                    </tr>
                  </tbody>
                  <!-- <tbody v-if="task_wise_overview.length">
                    <tr>
                      <td>1</td>
                      <td>Dilip Jajodia</td>
                      <td>dilip_jajodia@yahoo.com</td>
                      <td>MALE</td>
                      <td>58</td>
                      <td>56</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Vijay Kumar</td>
                      <td>vijay.k@gmail.com</td>
                      <td>MALE</td>
                      <td>58</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Rohit Wagh</td>
                      <td>w.rohit@gmail.com</td>
                      <td>MALE</td>
                      <td>58</td>
                      <td>51</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Shubham Pagare</td>
                      <td>shubham_pagare@gmail.com</td>
                      <td>MALE</td>
                      <td>58</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Sonali Gupta</td>
                      <td>sunali_gupta@gmail.com</td>
                      <td>FEMALE</td>
                      <td>58</td>
                      <td>57</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Abhishek Warma</td>
                      <td>abhishek_warma@hotmail.com</td>
                      <td>MALE</td>
                      <td>58</td>
                      <td>49</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Rajni Gosh</td>
                      <td>rajni_gosh@gmail.com</td>
                      <td>FEMALE</td>
                      <td>58</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Nikita Singh</td>
                      <td>nikita_singh@gmail.com</td>
                      <td>FEMALE</td>
                      <td>58</td>
                      <td>58</td>
                    </tr>

                  </tbody> -->
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  data() {
    return {
      form: {
        section_id: "",
        standard_id: "",
        classcode_id: "",
      },
      isLoading: false,
      students: [],
      standards: [],
      standardItems: [
        {
          id: 0,
          text: "Select Standard",
          value: 0,
        },
      ],
      sections: [],
      sectionItems: [
        {
          id: 0,
          text: "Select Section",
          value: 0,
        },
      ],
      classcodes: [],
      classcodeItems: [
        {
          id: 0,
          text: "Select Class Code",
          value: 0,
        },
      ],

      task_wise_overview: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Email: "email",
        Gender: "gender",
        "No of Assignment Given": "totalAssignmentsCount",
        "No of Assignment Completed": "completed_count",
      },
      report_excel: [],
      title: "Task Wise Report",
    };
  },
  mounted() {
    this.form.standard_id = this.$route.query.standard_id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      this.isLoading = true;
      let masters = await axios.get("users/masters");
      masters = masters.data;
      this.standards = masters.standards;
      this.sections = masters.sections;
      this.classcodes = masters.classcodes;
      // Standard
      this.standards.forEach((standard) => {
        if (this.$route.query.standard_id == standard.id) {
          this.standardItems.push({
            id: standard.id,
            text: standard.name,
            value: this.$route.query.standard_id,
          });
        }
        if (this.$route.query.standard_id != standard.id) {
          this.standardItems.push({
            id: standard.id,
            text: standard.name,
            value: standard.id,
          });
        }
      });
      // Section
      this.sections.forEach((section) => {
        this.sectionItems.push({
          id: section.id,
          text: section.name,
          value: section.id,
        });
      });
      // ClassCodes
      this.classcodes.forEach((classcode) => {
        if (this.$route.params.classcodeId == classcode.id) {
          this.classcodeItems.push({
            id: classcode.id,
            text: classcode.subject_name,
            value: this.$route.params.classcodeId,
          });
        }
        if (this.$route.params.classcodeId != classcode.id) {
          this.classcodeItems.push({
            id: classcode.id,
            text: classcode.subject_name,
            value: classcode.id,
          });
        }
      });
      this.isLoading = false;
    },
    async getData() {
      this.isLoading = true;
      try {
        this.form.standard_id = this.form.standard_id
          ? this.form.standard_id
          : "";
        this.form.section_id = this.form.section_id ? this.form.section_id : "";
        this.form.classcode_id = this.form.classcode_id
          ? this.form.classcode_id
          : "";
        let task_wise_overview = [];
        let query =
          "role_id=5" +
          "&standard_id=" +
          this.form.standard_id +
          "&section_id=" +
          this.form.section_id +
          "&classcode_id=" +
          this.form.classcode_id;
        task_wise_overview = await axios.get(`/task_wise_overview?${query}`);
        this.task_wise_overview = task_wise_overview.data.data;
        this.exportToExcel();
        this.perfo;
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async getStandardSections() {
      this.sectionItems = [];
      this.classcodeItems = [];
      this.form.classcode_id = "";
      let standardsections = this.sections.filter(
        (section) => section.standard_id == this.form.standard_id
      );
      standardsections.forEach((ss) => {
        this.sectionItems.push({
          id: ss.id,
          text: ss.name,
          value: ss.id,
        });
      });
    },
    async getSectionClasscodes() {
      this.classcodeItems = [];
      let sectionclasscodes = this.classcodes.filter(
        (classcode) => classcode.section_id == this.form.section_id
      );
      sectionclasscodes.forEach((sc) => {
        this.classcodeItems.push({
          id: sc.id,
          text: sc.subject_name,
          classcode: sc.classcode,
          value: sc.id,
        });
      });
    },
    exportToExcel() {
      this.report_excel = [];
      let sr_no = 1;
      this.task_wise_overview.forEach((student) => {
        this.report_excel.push({
          sr_no: sr_no,
          name: student.name ? student.name : "",
          email: student.email ? student.email : "",
          gender: student.gender ? "MALE" : "FEMALE",
          totalAssignmentsCount: student.totalAssignmentsCount
            ? student.totalAssignmentsCount
            : "",
          completed_count: student.completed_count
            ? student.completed_count
            : "",
        });
        sr_no++;
      });
    },
    performance_percent() {
      this.percent = Math.round(
        (this.user_assignment.score * 100) /
          this.user_assignment.assignment.maximum_marks
      );
      console.log(this.percent);
      switch (this.percent) {
        case this.percent > 33:
          this.color = "green";
          break;

        default:
          this.color = "red";
          break;
      }
      console.log(this.color);
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
