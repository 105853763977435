<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add a Company
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="School Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.code"
                outlined
                disabled
                label="School Code *"
                prepend-inner-icon="mdi-unicode"
                :error-messages="errors.code"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="
                  errors.email ? errors.email : checkUniqueError.email
                "
                @change="checkUnique('email', form.email)"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.contact_person"
                outlined
                label="Contact Person *"
                prepend-inner-icon="mdi-card-account-phone"
                :error-messages="errors.contact_person"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.phone"
                outlined
                label="Phone *"
                prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.address"
                outlined
                label="Address *"
                prepend-inner-icon="mdi-map-marker-multiple"
                :error-messages="errors.address"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.city"
                outlined
                label="City *"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.city"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.state"
                outlined
                label="State *"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.state"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.pincode"
                outlined
                label="Pincode *"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.pincode"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                v-model="form.is_mail_sent"
                inset
                :label="`Do you want to send registration Email? ${
                  form.is_mail_sent ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
            <v-col sm="4">
              <v-switch
                v-model="form.is_active"
                inset
                :label="`Is Active: ${
                  form.is_active.toString() == 'true' ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>
          <v-row>
            <v-col sm="12">
              <p class="font-weight-bold">Add Location</p>
            </v-col>
          </v-row>
          <div
            v-for="(company_board, uc) in form.company_boards"
            :key="`company_board${uc}`"
          >
            <v-row>
              <v-col md="3">
                <v-autocomplete
                  ref="board_id"
                  v-model="company_board.board_id"
                  :items="boardItems"
                  label="Select Location"
                  prepend-icon="mdi-domain"
                ></v-autocomplete
              ></v-col>

              <v-col sm="1">
                <v-dialog v-model="isDeleteBoardDialogs[uc]" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      class="ml-9"
                      small
                      dark
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark> mdi-close-thick </v-icon>
                    </v-btn>
                  </template>
                  <v-card elevation="10">
                    <v-card-title>
                      <v-icon large color="primary">
                        mdi-information-outline
                      </v-icon>
                      &nbsp; Do you want to remove the Location?
                    </v-card-title>
                    <v-card-text>
                      <v-btn
                        color="primary"
                        @click.stop="deleteBoard(company_board)"
                      >
                        Yes
                      </v-btn>
                      &nbsp;
                      <v-btn color="red" dark @click.stop="closeBoardDialogs">
                        No
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col sm="12" class="mb-5">
              <v-btn small color="primary" @click="Add_Empty_Board">
                Add Location
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save"> Save Company </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  name: "SchoolCreatePage",
  data() {
    return {
      form: {
        name: "",
        is_active: true,
        is_mail_sent: true,
        company_boards: [],
      },
      isLoading: false,
      boards: [],
      boardItems: [],
      isDeleteBoardDialogs: [],
      checkUniqueError: {
        email: "",
      },
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      this.isLoading = true;
      let masters = await axios.get("companies/masters");
      masters = masters.data;
      this.boards = masters.boards;
      // Boards
      this.boards.forEach((board) => {
        this.boardItems.push({
          id: board.id,
          text: board.name,
          value: board.id,
        });
      });
      this.isLoading = false;
    },
    async save() {
      try {
        if (this.checkUniqueError.email == "") {
          this.isLoading = true;
          await axios.post("/companies", this.form);
          this.isLoading = false;
          this.$router.push("/schools");
        }
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async checkUnique(column, text) {
      this.array = { column: column, text: text };
      let response = await axios.post(`/companies/checkUnique`, this.array);
      if (response.data != "No Duplicate Entries") {
        this.checkUniqueError.email = response.data;
      } else {
        this.checkUniqueError.email = "";
      }
    },
    Add_Empty_Board() {
      let company_board = {
        board_id: "",
      };
      this.form.company_boards.push(company_board);
    },
    deleteBoard(company_board) {
      this.form.company_boards.splice(
        this.form.company_boards.indexOf(company_board),
        1
      );
      this.closeBoardDialogs();
    },
    closeBoardDialogs() {
      this.isDeleteBoardDialogs = [];
    },
  },
};
</script>
