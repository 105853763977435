<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col sm="2">
          <span class="text-h5 font-weight-bold" v-text="'Contents'"></span>
          <!-- <addbutton :link="'/contents/create'"></addbutton> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col
          sm="3"
          v-for="type in content_types"
          :key="type.name"
          @click="getData(type.name)"
        >
          <v-card elevation="5" color="primary accent-1" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5 font-weight-bold"
                  v-text="`${type.name}`"
                ></v-card-title>

                <v-card-subtitle
                  class="text-h3 font-weight-bold"
                  v-text="type.count"
                ></v-card-subtitle>
              </div>
              <v-avatar class="ma-3" size="125" tile>
                <v-icon class="imgStyle" large dark>{{ type.icon }}</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card elevation="5" color="primary accent-1" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5 font-weight-bold"
                  v-text="`ASSIGNMENT`"
                ></v-card-title>

                <v-card-subtitle
                  class="text-h3 font-weight-bold"
                  v-text="assignments.length"
                ></v-card-subtitle>
              </div>
              <v-avatar class="ma-3" size="125" tile>
                <v-icon class="imgStyle" large dark>mdi-clipboard-text</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model="search_keyword"
            label="Search ... "
            prepend-inner-icon="mdi-magnify"
            @keydown.enter="getData"
            @change="page = 1"
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-menu
            v-model="date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_filter"
                label="Date Search"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @input="getData"
                @click:clear="date_filter = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_filter"
              @input="getData"
              @change="date_menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="4">
          <v-pagination v-model="page" :length="paginationLength">
          </v-pagination>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <v-autocomplete
            ref="subject"
            v-model="form.created_by_id"
            :items="users"
            label="Select User"
            prepend-icon="mdi-account"
            @change="getData()"
          ></v-autocomplete>
        </v-col>
        <v-col sm="4">
          <v-autocomplete
            ref="subject"
            v-model="form.is_approved"
            :items="approvedStatus"
            label="Select Approval Status"
            prepend-icon="mdi-check-decagram"
            @change="getData()"
          ></v-autocomplete>
        </v-col>
        <v-col sm="4">
          <v-autocomplete
            ref="subject"
            v-model="form.is_active"
            :items="activeStatus"
            label="Select Active Status"
            prepend-icon="mdi-toggle-switch"
            @change="getData()"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left pa-2" style="min-width: 60px">
                      Sr No
                    </th>
                    <th class="text-left pl-2" style="min-width: 45px">ID</th>
                    <th class="text-left pa-1" style="min-width: 80px">
                      Created By
                    </th>
                    <th class="text-left">Type</th>
                    <th class="text-left pa-1">Title</th>
                    <th class="text-left">Description</th>
                    <th class="text-left pa-1" style="min-width: 80px">
                      Approval Status
                    </th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(content, i) in contents" :key="content.name">
                    <td class="pa-2" style="min-width: 15px">
                      <v-icon v-if="content.is_active" small dark color="green">
                        mdi-circle
                      </v-icon>
                      {{ i + 1 }}
                    </td>
                    <td class="pa-2" style="min-width: 15px">
                      {{ content.id }}
                    </td>
                    <td class="pa-2" style="min-width: 30px">
                      {{ content.created_by ? content.created_by.name : "" }}
                    </td>
                    <td class="pa-2" style="min-width: 93px">
                      {{ content.content_type }}
                    </td>
                    <td class="pa-2" style="min-width: 93px">
                      {{ content.content_name }}
                    </td>
                    <td v-html="content.original_content" width="750"></td>
                    <td class="text-left pa-2" style="min-width: 30px">
                      <v-icon v-if="content.is_approved" color="green">
                        mdi-check-decagram
                      </v-icon>
                      <v-icon color="red" v-else> mdi-close-octagon </v-icon>
                    </td>

                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/academic-team/contents/${content.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "ContentsPage",
  data() {
    return {
      form: {
        created_by_id: "",
        is_approved: "",
        is_active: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      date_filter: "",
      contents: [],
      content_types: [],
      assignments: [],
      isLoading: false,
      date_menu: false,
      users: [
        {
          id: 0,
          text: "Select User",
          value: 0,
        },
      ],
      approvedStatus: [
        {
          id: 0,
          text: "Select Approval Status",
          value: "",
        },
        { id: "APPROVED", text: "APPROVED", value: "1" },
        { id: "PENDING", text: "PENDING", value: "0" },
      ],
      activeStatus: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.image_url = URL.createObjectURL(file);
    },
    async getMasters() {
      let masters = await axios.get("/contents/masters");
      masters = masters.data;
      masters.users.forEach((user) => {
        this.users.push({
          id: user.id,
          text: user.name,
          value: user.id,
        });
      });
    },
    async getData(type) {
      this.isLoading = true;
      type = type ? type : "";
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.date_filter = this.date_filter ? this.date_filter : "";
      let contents = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&type=" +
        type +
        "&search_keyword=" +
        this.search_keyword +
        "&created_by_id=" +
        this.form.created_by_id +
        "&is_approved=" +
        this.form.is_approved +
        "&is_active=" +
        this.form.is_active +
        "&academic_team=academic_team" +
        "&date_filter=" +
        this.date_filter;
      contents = await axios.get(`contents?${query}`);
      this.contents = contents.data.data;
      this.content_types = contents.data.content_types;
      this.assignments = contents.data.assignments;
      this.isLoading = false;
    },
  },
};
</script>
