<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6">
        <v-btn text>Download sample file</v-btn>
        <v-card-title>
          <backbutton></backbutton>
          Upload Teachers Data
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="crude_teachers.length == 0">
              <input
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="application/ms-excel"
                @input="handleFileUpload"
              />
              <!-- <v-btn
                color="primary"
                :loading="isUploading"
                :disabled="isUploading"
                >Upload File</v-btn
              > -->
            </v-col>
            <v-col v-else>
              <v-switch
                v-model="is_mail_sent"
                inset
                :label="`Do you want to send registration Email? ${
                  is_mail_sent ? 'YES' : 'NO'
                }`"
                color="green"
              ></v-switch>
              <v-btn
                color="success"
                :loading="isProcessing"
                :disabled="isProcessing"
                @click="processFile"
              >
                Upload file</v-btn
              >
              &nbsp;
              <v-btn color="error" @click="truncatePendingDocuments">
                Delete</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <span style="color: red" v-show="isError">{{ error_msg }} </span>
          </v-row>
        </v-card-text>
      </v-card>
      <br />
      <v-card elevation="6">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">First Name</th>
                <th class="text-left">Middle Name</th>
                <th class="text-left">Last Name</th>
                <th class="text-left">Email</th>
                <th class="text-left">Contact Number</th>
                <th class="text-left">Gender</th>
                <!-- <th class="text-left">Classcode 1</th>
                <th class="text-left">Classcode 2</th>
                <th class="text-left">Classcode 3</th>
                <th class="text-left">Classcode 4</th>
                <th class="text-left">Classcode 5</th>
                <th class="text-left">Classcode 6</th>
                <th class="text-left">Classcode 7</th>
                <th class="text-left">Classcode 8</th>
                <th class="text-left">Classcode 9</th>
                <th class="text-left">Classcode 10</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(crudteacher, at) in crude_teachers"
                :key="`crudteacher${at}`"
              >
                <td>{{ crudteacher.id_given_by_school }}</td>
                <td>{{ crudteacher.first_name }}</td>
                <td>{{ crudteacher.middle_name }}</td>
                <td>{{ crudteacher.last_name }}</td>
                <td>{{ crudteacher.email }}</td>
                <td>{{ crudteacher.contact_number }}</td>
                <td>{{ crudteacher.gender }}</td>
                <!-- <td>{{ crudteacher.classcode_1 }}</td>
                <td>{{ crudteacher.classcode_2 }}</td>
                <td>{{ crudteacher.classcode_3 }}</td>
                <td>{{ crudteacher.classcode_4 }}</td>
                <td>{{ crudteacher.classcode_5 }}</td>
                <td>{{ crudteacher.classcode_6 }}</td>
                <td>{{ crudteacher.classcode_7 }}</td>
                <td>{{ crudteacher.classcode_8 }}</td>
                <td>{{ crudteacher.classcode_9 }}</td>
                <td>{{ crudteacher.classcode_10 }}</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <!-- Upload Success Dialog Box -->
      <v-dialog v-model="is_Uploaded" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-cloud-upload-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>
                        The file has been uploaded successfully. Now you can
                        Preview the List & process accordingly.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Uploaded = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Process Success Dialog Box -->
      <v-dialog v-model="is_Processed" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-progress-download
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>
                        The file has been processed successfully. Now you can
                        see your teachers in Teacher's Tab.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Processed = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Truncate Success Dialog Box -->
      <v-dialog v-model="is_Truncated" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: red; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: red">Deleted!</h1>
                      <br />
                      <h3>
                        The file has been deleted successfully. Now you can
                        select a new file to upload.
                      </h3>
                      <br />
                      <v-btn color="success" @click="is_Truncated = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
export default {
  components: { backbutton },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Upload User Excel",
  },
  data() {
    return {
      crude_teachers: [],
      isLoading: false,
      is_mail_sent: false,
      isUploading: false,
      is_Uploaded: false,
      isProcessing: false,
      is_Processed: false,
      is_Truncated: false,
      isError: false,
      error_msg: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crude_teachers = await axios.get(`/crude_teachers`);
      this.crude_teachers = crude_teachers.data.data;
      this.count = crude_teachers.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isUploading = true;
      this.teachers = this.$refs.file.files[0];
      let formData = new FormData();
      this.error_msg = "";
      formData.append("teachers", this.teachers);
      formData.append("role_id", 3);
      formData.append("is_mail_sent", this.is_mail_sent);
      this.changeValue = 100;
      await axios
        .post("upload_teacher", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crude_teachers = response.data.data;
          this.is_Uploaded = true;
          // alert("Uploaded Successfully");
        })
        .catch(() => {
          console.log("FAILURE!!");
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
    },
    async processFile() {
      try {
        this.isProcessing = true;
        await axios
          .get(`process_teacher?is_mail_sent=${this.is_mail_sent}`)
          .catch(() => {
            // this.isError = true;
            // this.error_msg =
            //   "Looks like system faced an error while processing the File. Kindly check the file before processing ...";
          });
        await axios.get("truncate_teachers");
        this.crude_teachers = [];
        this.isProcessing = false;
        this.is_Processed = true;

        // alert("Processed Successfully");
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("truncate_teachers");
      // alert("Truncated Successfully");
      this.is_Truncated = true;

      this.crude_teachers = [];
    },
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
td {
  min-width: 130px !important;
}
</style>
