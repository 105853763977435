<template>
  <v-card color="primary" dark style="overflow: auto">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-h5">
          {{ totalAssignmentsCount }}
        </v-card-title>
        <v-card-subtitle>Assignment Overview</v-card-subtitle>
        <v-card-actions>
          <v-dialog v-model="isAssignmentDialog" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                v-bind="attrs"
                v-on="on"
                @click="getData"
              >
                View List
              </v-btn>
            </template>
            <v-card elevation="10">
              <v-container>
                <v-row>
                  <v-card-title style="min-width: 170px">
                    <router-link target="_blank" to="/assignments">
                      Assignments
                    </router-link>
                    ( <MyLoader v-if="isLoading"></MyLoader>
                    <span v-else>
                      {{ assignmentOverviewData.totalAssignmentsCount }}
                    </span>
                    )
                  </v-card-title>
                  <v-spacer></v-spacer>
                  <v-card-actions>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="isAssignmentDialog = false"
                          icon
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Close</span>
                    </v-tooltip>
                  </v-card-actions>
                </v-row>
                <v-row>
                  <v-card-text>
                    <v-row>
                      <v-col
                        sm="3"
                        v-for="(
                          overview, ae
                        ) in assignmentOverviewData.statusWiseAssignments"
                        :key="`overview${ae}`"
                      >
                        <SubCard
                          :text="overview.name"
                          :value="overview.count"
                        ></SubCard>
                      </v-col>
                    </v-row>
                    <br />
                    <v-tabs
                      fixed-tabs
                      background-color="primary"
                      dark
                      v-model="assignmentsTab"
                      center-active
                      centered
                      next-icon="mdi-arrow-right-bold-box-outline"
                      prev-icon="mdi-arrow-left-bold-box-outline"
                      show-arrows
                    >
                      <v-tab
                        style="min-width: 150px"
                        v-for="(
                          overview, ae
                        ) in assignmentOverviewData.statusWiseAssignments"
                        :key="`overview1${ae}`"
                        :href="`#overview${ae}`"
                      >
                        {{ overview.name }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="assignmentsTab">
                      <v-tab-item
                        v-for="(
                          overview, ae
                        ) in assignmentOverviewData.statusWiseAssignments"
                        :key="`overview${ae}`"
                        :value="`overview${ae}`"
                      >
                        <v-simple-table fixed-header height="285px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left sr_no_class">Sr No</th>
                                <th class="text-left" style="min-width: 100px">
                                  Assignment Title
                                </th>
                                <th class="text-left" style="min-width: 100px">
                                  Assignment Type
                                </th>
                                <th class="text-left created_on_date">
                                  Created On
                                </th>
                                <th
                                  style="min-width: 110px"
                                  v-if="overview.name == 'COMPLETED'"
                                  class="text-left"
                                >
                                  Attempted On
                                </th>
                                <th
                                  v-if="overview.name == 'COMPLETED'"
                                  class="text-left"
                                >
                                  Score
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value, i) in overview.values"
                                :key="`value${i}`"
                              >
                                <td>{{ i + 1 }}</td>
                                <td>
                                  <router-link
                                    v-if="
                                      value.status == 'COMPLETED' &&
                                      value.result_declared
                                    "
                                    target="_blank"
                                    :to="`/assignments/${value.assignment_id}/check-result/${value.my_results[0].id}?source=ASSIGNMENT_COUNT_POPUP`"
                                  >
                                    {{ value.assignment_title | limit }}
                                  </router-link>
                                  <template
                                    v-else-if="value.student.id == user.id"
                                  >
                                    <router-link
                                      v-if="value.status == 'IN PROGRESS'"
                                      target="_blank"
                                      :to="`/assignments?tab=tab-1`"
                                    >
                                      {{ value.assignment_title | limit }}
                                    </router-link>
                                    <router-link
                                      v-else-if="value.status == 'UPCOMING'"
                                      target="_blank"
                                      :to="`/assignments?tab=tab-4`"
                                    >
                                      {{ value.assignment_title | limit }}
                                    </router-link>
                                    <router-link
                                      v-else
                                      target="_blank"
                                      :to="`/assignments?tab=tab-2`"
                                    >
                                      {{ value.assignment_title | limit }}
                                    </router-link>
                                  </template>
                                  <template v-else>
                                    {{ value.assignment_title | limit }}
                                  </template>
                                </td>
                                <td style="min-width: 120px">
                                  {{ value.assignment_type }}
                                </td>
                                <td>{{ value.created_at | ddmmyyyy }}</td>
                                <td
                                  style="min-width: 110px"
                                  v-if="overview.name == 'COMPLETED'"
                                >
                                  {{ value.created_at | ddmmyyyy }}
                                </td>
                                <td
                                  style="min-width: 90px"
                                  v-if="overview.name == 'COMPLETED'"
                                >
                                  {{ value.percent | twoDec }} %
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                  <v-card-title style="min-width: 210px"
                    >Assignment Type Wise Overview</v-card-title
                  >
                  <v-card-text>
                    <v-tabs
                      background-color="primary"
                      dark
                      v-model="assignmentTypesTab"
                      center-active
                      centered
                      next-icon="mdi-arrow-right-bold-box-outline"
                      prev-icon="mdi-arrow-left-bold-box-outline"
                      show-arrows
                      fixed-tabs
                    >
                      <v-tab
                        style="min-width: 150px"
                        v-for="(
                          overview, ae
                        ) in assignmentOverviewData.typeWiseAssignments"
                        :key="`overview${ae}`"
                        :href="`#over${ae}`"
                      >
                        {{ overview.name }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="assignmentTypesTab">
                      <v-tab-item
                        v-for="(
                          overview, ae
                        ) in assignmentOverviewData.typeWiseAssignments"
                        :key="`overview1${ae}`"
                        :value="`over${ae}`"
                      >
                        <v-simple-table fixed-header height="285px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left sr_no_class">Sr No</th>
                                <th class="text-left" style="min-width: 100px">
                                  Assignment Title
                                </th>
                                <th class="text-left" style="min-width: 100px">
                                  Assignment Type
                                </th>
                                <th class="text-left" style="min-width: 70px">
                                  Status
                                </th>
                                <th class="text-left created_on_date">
                                  Created On
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value, i) in overview.values"
                                :key="`value${i}`"
                              >
                                <td>{{ i + 1 }}</td>
                                <td>
                                  <router-link
                                    v-if="
                                      value.status == 'COMPLETED' &&
                                      value.result_declared
                                    "
                                    target="_blank"
                                    :to="`/assignments/${value.assignment_id}/check-result/${value.my_results[0].id}?source=ASSIGNMENT_COUNT_POPUP`"
                                  >
                                    {{ value.assignment_title | limit }}
                                  </router-link>
                                  <template
                                    v-else-if="value.student.id == user.id"
                                  >
                                    <router-link
                                      v-if="value.status == 'IN PROGRESS'"
                                      target="_blank"
                                      :to="`/assignments?tab=tab-1`"
                                    >
                                      {{ value.assignment_title | limit }}
                                    </router-link>
                                    <router-link
                                      v-else-if="value.status == 'UPCOMING'"
                                      target="_blank"
                                      :to="`/assignments?tab=tab-4`"
                                    >
                                      {{ value.assignment_title | limit }}
                                    </router-link>
                                    <router-link
                                      v-else
                                      target="_blank"
                                      :to="`/assignments?tab=tab-2`"
                                    >
                                      {{ value.assignment_title | limit }}
                                    </router-link>
                                  </template>
                                  <template v-else>
                                    {{ value.assignment_title | limit }}
                                  </template>
                                </td>
                                <td style="min-width: 120px">
                                  {{ value.assignment_type }}
                                </td>
                                <td style="min-width: 115px">
                                  {{ value.status }}
                                </td>
                                <td>{{ value.created_at | ddmmyyyy }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </div>
      <v-avatar class="ma-3" size="125" tile>
        <v-icon class="imgStyle" large dark>mdi-human-male-board</v-icon>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
import SubCard from "./sub_card.vue";
import axios from "axios";

export default {
  data() {
    return {
      assignmentsTab: "",
      assignmentTypesTab: "",
      isAssignmentDialog: false,
      isLoading: false,
      assignmentTypeWiseData: [],
      assignmentOverviewData: [],
    };
  },
  props: ["assignmentOverview", "student_id"],
  components: {
    SubCard,
  },
  computed: {
    totalAssignmentsCount() {
      return this.assignmentOverview.totalAssignmentsCount;
    },
  },
  watch: {
    assignmentOverview: "getData",
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.assignmentOverviewData = [];
      let response;
      let query = "student_id=" + this.student_id;
      response = await axios.get(`/student_wise_assignment_overviews?${query}`);
      this.assignmentOverviewData = response.data.data;
      this.isLoading = false;
    },
  },
};
</script>
