<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title> Upload TOI XML Data </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <input
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="application/ms-excel"
                @input="handleFileUpload"
                multiple
              />
            </v-col>

            <v-col>
              <!-- <v-progress-circular
                v-if="isRefreshData"
                indeterminate
                color="info"
              ></v-progress-circular> -->
              <v-btn
                color="info"
                :loading="isRefreshData"
                :disabled="isRefreshData"
                @click="toiXMLEmail"
                >Fetch Mail</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <br />
      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="search_keyword"
            label="Search ... "
            prepend-inner-icon="mdi-magnify"
            @keydown.enter="getData"
            @change="page = 1"
          ></v-text-field>
        </v-col>
        <v-col md="6">
          <v-pagination v-model="page" :length="paginationLength">
          </v-pagination>
        </v-col>
      </v-row>
      <v-card elevation="6">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Sr. No.</th>
                <th class="text-left">File Name</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(toiXML, at) in toi_xmls" :key="`toiXML${at}`">
                <td>{{ at + 1 }}</td>
                <td>
                  <a :href="`${mediaUrl}${toiXML.xmlpath}`" target="_blank">
                    {{ toiXML.xmlpath }}
                  </a>
                </td>
                <td>
                  <v-btn
                    v-if="toiXML.is_process == false"
                    small
                    color="primary"
                    @click="processFile(toiXML)"
                    :loading="isProcessing"
                    :disabled="isProcessing"
                  >
                    Process File
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
    <!-- Success Message -->
    <v-dialog v-model="isSuccess" width="600">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: green; font-size: 90px; width: 200px !important"
              >
                mdi-cloud-upload-outline
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">Hurray!</h1>
                    <br />
                    <h3>
                      The file has been uploaded successfully. Now you can
                      Preview the List & process accordingly.
                    </h3>
                    <br />
                    <v-btn
                      color="success"
                      @click="(isSuccess = false), getData()"
                    >
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Process -->
    <v-dialog v-model="isProcessingData" width="600">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: green; font-size: 90px; width: 200px !important"
              >
                mdi-cloud-upload-outline
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">Hurray!</h1>
                    <br />
                    <h3>
                      The file has been processed successfully. Now you can see
                      your TOI Articles in Epaper's Tab.
                    </h3>
                    <br />
                    <v-btn
                      color="success"
                      @click="(isProcessingData = false), getData()"
                    >
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "TOIXMLUpload",
  data() {
    return {
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      rowsPerPage: 50,
      count: 0,
      search_keyword: "",
      toi_xmls: [],
      isLoading: false,
      isProcessingData: false,
      isProcessing: false,
      isSuccess: false,
      isRefreshData: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let toi_xmls = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword;
      toi_xmls = await axios.get(`toi_xml?${query}`);
      this.toi_xmls = toi_xmls.data.data;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(toi_xmls.data.count / this.rowsPerPage);
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isUploading = true;
      let toi_xml = this.$refs.file?.files[0];
      let formData = new FormData();
      formData.append("xml_path", toi_xml);
      await axios
        .post("toi_xml/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isUploading = false;
      this.isSuccess = true;
      this.getData();
    },
    async processFile(data) {
      try {
        this.isProcessing = true;
        await axios.post(`processTOIXML`, data);
        this.isProcessingData = true;
        // this.getData();
        this.isProcessing = false;
      } catch (e) {
        console.log(e);
      }
    },
    async toiXMLEmail() {
      this.isRefreshData = true;
      let toi_xmls = [];
      toi_xmls = await axios.get(`toi_xmls/emails`);
      this.toi_xmls = toi_xmls.data.data;
      this.isRefreshData = false;
      this.isSuccess = true;
    },
  },
};
</script>
