<template>
  <section class="my-5" style="font-family: Montserrat">
    <hr class="mb-5" />
    <h2
      class="mb-5"
      v-if="errors.assignment_questions || !form.assignment_questions?.length"
    >
      <!-- <b
        class="mx-10"
        ref="assignment_questions"
        id="assignment_questions"
        style="color: red"
        >Atleast one question is required</b
      > -->
    </h2>
    <!-- Questions Loop -->
    <v-card
      style="border-radius: 15px"
      class="my-5 overflow-auto"
      v-for="(assignmentQuestion, fQ) in form.assignment_questions"
      :key="`assignmentQuestion${fQ}`"
      :color="fQ < form.assignment_questions.length - 1 ? '#F2F5FA' : ''"
      :elevation="fQ < form.assignment_questions.length - 1 ? '' : '8'"
    >
      <v-card-text>
        <template v-if="fQ < form.assignment_questions.length - 1">
          <!-- Show in Card -->
          <v-row class="d-flex">
            <v-col
              id="lo_section mr-auto order-sm-1"
              style="min-width: 424px"
              sm="10"
              md="10"
              lg="10"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </v-col>
            <v-col sm="1" md="1" lg="1" align="left" class="px-0">
              <v-chip
                v-if="assignmentQuestion.question_type == 'SUBJECTIVE'"
                color="#F2F5FA"
                class="px-0"
                style="min-width: fit-content"
              >
                05 marks
              </v-chip></v-col
            >
            <v-col
              sm="1"
              md="1"
              lg="1"
              class="d-flex justify-lg-end justify-md-end order-sm-0"
            >
              <v-btn icon><v-icon size="15">mdi-pencil</v-icon> </v-btn>

              <v-dialog v-model="isDeleteQuestionDialogs[fQ]" max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on"
                    ><v-icon size="15">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>

                <v-card elevation="10" align="right">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Do you want to delete Question
                    {{ fQ + 1 }}?
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-btn
                      color="primary"
                      @click.stop="deleteQuestion(assignmentQuestion)"
                    >
                      Yes
                    </v-btn>
                    &nbsp;
                    <v-btn color="red" dark @click.stop="closeQuestionDialogs">
                      No
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row v-if="assignmentQuestion.question_type == 'OBJECTIVE'">
            <v-col>
              <v-radio-group row class="d-flex justify-space-between">
                <v-radio label="Option 1" value="radio-1" readonly></v-radio>
                <v-radio label="Option 2" value="radio-2" readonly></v-radio>
                <v-radio label="Option 2" value="radio-2" readonly></v-radio>
                <v-radio label="Option 2" value="radio-2" readonly></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </template>
        <template v-if="fQ == form.assignment_questions.length - 1">
          <!-- Edit Mode -->
          <v-row v-if="form.assignment_type == 'CUMULATIVE'">
            <v-col>
              <v-radio-group
                row
                class="d-flex justify-space-between"
                v-model="assignmentQuestion.question_type"
                mandatory
              >
                <v-radio
                  v-for="assignmentType in assignmentTypes"
                  :key="assignmentType"
                  :label="assignmentType"
                  :value="assignmentType"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row></v-row>
          <v-row></v-row>
          <!-- Question -->
          <v-row
            v-if="fQ == form.assignment_questions.length - 1"
            class="mt-0"
            :id="`assignment_questions_${fQ}_description`"
            :ref="`assignment_questions_${fQ}_description`"
          >
            <v-col
              sm="12"
              md="9"
              lg="9"
              style="min-width: fit-content"
              class=""
            >
              <p class="text-subtitle-2 label">Question</p>
              <span
                style="color: red"
                v-if="errors[`assignment_questions.${fQ}.description`]"
                >This question field is required</span
              >
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="assignmentQuestion.description"
              ></froala>
            </v-col>
            <v-col
              sm="12"
              md="3"
              lg="3"
              style="min-width: fit-content"
              class="justify-sm-start pb-0"
            >
              <p class="text-subtitle-2 label">Marks of question</p>
              <v-text-field
                outlined
                :readonly="isModificationEnabled == false ? true : false"
                v-model="assignmentQuestion.marks"
                :error-messages="
                  errors[`assignment_questions.${fQ}.marks`]
                    ? errors[`assignment_questions.${fQ}.marks`]
                    : ``
                "
                dense
                placeholder="Enter the marks"
                :id="`assignment_questions_${fQ}_marks`"
                :ref="`assignment_questions_${fQ}_marks`"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Options -->
          <template
            v-if="
              assignmentQuestion.question_type == 'OBJECTIVE' &&
              fQ == form.assignment_questions.length - 1
            "
          >
            <v-row
              v-for="(
                questionOption, qO
              ) in assignmentQuestion.assignment_question_options"
              :key="`questionOpiton${qO}`"
              class="mt-0"
              :id="`assignment_questions_${fQ}_option_${qO}`"
              :ref="`assignment_questions_${fQ}_option_${qO}`"
            >
              <v-col
                sm="12"
                md="6"
                lg="6"
                style="min-width: fit-content"
                class=""
              >
                <span
                  style="color: red"
                  v-if="errors[`assignment_questions.${fQ}.description`]"
                  >This question field is required</span
                >
                <froala
                  :tag="'textarea'"
                  :config="option_config"
                  v-model="questionOption.description"
                ></froala>
              </v-col>
              <v-col sm="2" md="1" lg="1" style="max-width: 75px">
                <v-btn small outlined color="gray">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col sm="2" md="1" lg="2">
                <div class="switch-container">
                  <p class="mb-5 mr-3" style="min-width: fit-content">TRUE</p>
                  <v-switch class="mt-0 pt-0" color="success"></v-switch>
                </div>
              </v-col>
            </v-row>
            <!-- <v-row
              class="mt-0"
              :id="`assignment_questions_${fQ}_option`"
              :ref="`assignment_questions_${fQ}_option`"
            >
              <v-col
                sm="12"
                md="6"
                lg="6"
                style="min-width: fit-content"
                class=""
              >
                <span
                  style="color: red"
                  v-if="errors[`assignment_questions.${fQ}.description`]"
                  >This question field is required</span
                >
                <froala
                  :tag="'textarea'"
                  :config="option_config"
                  v-model="assignmentQuestion.description"
                ></froala>
              </v-col>
              <v-col sm="2" md="1" lg="1" style="max-width: 75px">
                <v-btn small outlined color="gray">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col sm="2" md="1" lg="2">
                <div class="switch-container">
                  <p class="mb-5 mr-3" style="min-width: fit-content">TRUE</p>
                  <v-switch class="mt-0 pt-0" color="success"></v-switch>
                </div>
              </v-col>
            </v-row> -->
          </template>
          <!-- Model Answer -->
          <v-row
            v-if="
              (assignmentQuestion.show_model_answer ||
                assignmentQuestion.question_type == 'SUBJECTIVE') &&
              fQ == form.assignment_questions.length - 1
            "
          >
            <v-col
              sm="12"
              md="12"
              lg="12"
              style="min-width: fit-content"
              class=""
            >
              <p class="text-subtitle-2 label">Model Answer</p>
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="assignmentQuestion.description"
              ></froala>
            </v-col>
          </v-row>
          <!-- Document Validation -->
          <v-row
            v-if="
              assignmentQuestion.document_validation &&
              fQ == form.assignment_questions.length - 1
            "
          >
            <v-col
              class="py-0"
              sm="12"
              md="3"
              lg="3"
              style="min-width: fit-content"
            >
              <p class="text-subtitle-2 label">maximum number of file</p>
              <v-autocomplete
                outlined
                dense
                :items="assignmentTypes"
              ></v-autocomplete>
            </v-col>
            <v-col
              class="py-0"
              sm="12"
              md="3"
              lg="3"
              style="min-width: fit-content"
            >
              <p class="text-subtitle-2 label">maximum file size</p>
              <v-autocomplete
                outlined
                dense
                :items="assignmentTypes"
              ></v-autocomplete>
            </v-col>
            <v-col
              sm="12"
              md="3"
              lg="3"
              style="min-width: fit-content"
              class="align-self-center py-0"
            >
              <v-switch
                v-model="assignmentQuestion.show_allowed_types"
                color="success"
                label="Allow only specific file types"
              ></v-switch>
            </v-col>
            <v-col
              sm="12"
              md="3"
              lg="3"
              style="min-width: fit-content"
              class="align-self-end py-0"
            >
              <v-autocomplete
                v-if="assignmentQuestion.show_allowed_types"
                outlined
                dense
                :items="assignmentTypes"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- Buttom Section -->
          <v-row>
            <v-col align="left" xs="3" sm="6" md="6" lg="6">
              <template v-if="assignmentQuestion.question_type == 'OBJECTIVE'">
                <v-btn
                  style="text-transform: initial !important"
                  small
                  outlined
                  class="newButton"
                  color="black"
                >
                  Add options
                </v-btn>
              </template>
              <template v-else>
                <v-switch
                  style="min-width: max-content"
                  v-model="assignmentQuestion.document_validation"
                  color="success"
                  label="Upload Document"
                ></v-switch>
              </template>
            </v-col>
            <v-col
              xs="5"
              sm="6"
              md="6"
              lg="6"
              style="min-width: max-content"
              class="align-self-center px-5"
            >
              <v-row
                class="d-flex justify-sm-end justify-md-end justify-lg-end"
              >
                <v-btn icon class="align-self-center">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>

                <v-dialog
                  v-model="isDeleteQuestionDialogs[fQ]"
                  max-width="400px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="align-self-center"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>

                  <v-card elevation="10" align="right">
                    <v-card-title
                      class="justify-center primary accent-1 white--text"
                    >
                      Do you want to delete Question
                      {{ fQ + 1 }}?
                    </v-card-title>
                    <br />
                    <v-card-text>
                      <v-btn
                        color="primary"
                        @click.stop="deleteQuestion(assignmentQuestion)"
                      >
                        Yes
                      </v-btn>
                      &nbsp;
                      <v-btn
                        color="red"
                        dark
                        @click.stop="closeQuestionDialogs"
                      >
                        No
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <div
                  class="switch-container align-self-center"
                  v-if="assignmentQuestion.question_type == 'OBJECTIVE'"
                >
                  <p style="border-left: groove" class="mr-2">
                    &nbsp; Add Justification
                  </p>
                  <v-switch
                    v-model="assignmentQuestion.show_model_answer"
                    color="success"
                  ></v-switch>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
    <v-btn
      class="my-5 newButton"
      color="primary"
      @click="addEmptyQuestion"
      v-show="isModificationEnabled && this.form.assignment_type != 'DOCUMENT'"
    >
      <v-icon dark> mdi-plus </v-icon>
      Add Questions
    </v-btn>
    <hr v-show="isModificationEnabled" class="mb-5" />
  </section>
</template>

<script>
export default {
  name: "AssignmentQuestionComponent",
  props: ["form", "isModificationEnabled"],
  data() {
    return {
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        toolbarButtons: {
          moreText: {
            buttons: [
              "insertImage",
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
            ],
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: false,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      option_config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        toolbarButtons: {
          moreText: {
            buttons: [
              "insertImage",
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
            ],
          },
        },
        placeholderText: "Add Your Option Here",
        charCounterCount: false,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      isDeleteQuestionDialogs: [],

      correctOptionItems: [
        { value: 1, text: "Option 1" },
        { value: 2, text: "Option 2" },
        { value: 3, text: "Option 3" },
        { value: 4, text: "Option 4" },
        { value: 5, text: "Option 5" },
        { value: 6, text: "Option 6" },
      ],
      assignmentTypes: ["SUBJECTIVE", "OBJECTIVE"],
    };
  },
  watch: {
    form: {
      handler() {
        this.updateQuestionType();
      },
      deep: true,
    },
  },
  methods: {
    resetSrNos() {
      this.form.assignment_questions.forEach((aQ, index) => {
        aQ.sr_no = index + 1;
      });
    },
    updateQuestionType() {
      this.form.assignment_questions.forEach((_aQ) => {
        if (this.form.assignment_type == "OBJECTIVE") {
          _aQ.question_type = "OBJECTIVE";
        }
        if (this.form.assignment_type == "SUBJECTIVE") {
          _aQ.question_type = "SUBJECTIVE";
        }
        if (this.form.assignment_type == "DOCUMENT") {
          _aQ.question_type = "DOCUMENT";
        }
      });
    },
    addEmptyQuestion() {
      let assignmentQuestion = {
        sr_no: this.form.assignment_questions.length + 1,
        assignment_type:
          this.form.assignment_type != "CUMULATIVE"
            ? this.form.assignment_type
            : "",
        description: "",
        model_answer: "",
        correct_options: [],
        marks: "",
        negative_marks: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        show_model_answer: "",
        assignment_question_options: [
          {
            description: "Option 1",
          },
          {
            description: "Option 2",
          },
        ],
        assignment_question_correct_options: [],
      };
      this.form.assignment_questions.push(assignmentQuestion);
    },
    focusAssignmentQuestions() {
      this.$refs.assignment_questions_0_marks.focus();
    },
    deleteQuestion(assignmentQuestion) {
      this.form.assignment_questions.splice(
        this.form.assignment_questions.indexOf(assignmentQuestion),
        1
      );
      this.resetSrNos();
      this.closeQuestionDialogs();
    },
    closeQuestionDialogs() {
      this.isDeleteQuestionDialogs = [];
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
.newButton {
  text-transform: initial !important;
  border-radius: 8px;
}
.label {
  font-family: Montserrat !important;
}
.switch-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
