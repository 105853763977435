<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 165px">
          <span
            class="text-h5 font-weight-bold"
            v-text="
              'Overall Task Completed Vs Task Assigned School Wise Performance Report'
            "
          ></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <v-autocomplete
            ref="scholl"
            v-model="form.school_ids"
            :items="schoolItems"
            multiple
            chips
            clearable
            label="School"
            prepend-icon="mdi-application-edit"
          ></v-autocomplete>
        </v-col>
        <v-col></v-col>
        <v-col class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col>
          <v-card elevation="6">
            <v-card-text>
              <v-simple-table fixed-header height="287px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left max pr-0 pl-3"
                        style="min-width: 50px"
                      >
                        Sr No
                      </th>
                      <th
                        class="text-left min pr-0 pl-3"
                        style="min-width: 100px"
                      >
                        School code
                      </th>
                      <th
                        class="text-center min pr-0 pl-3"
                        style="min-width: 100px"
                      >
                        No Of Assignment
                      </th>
                      <th
                        class="text-center min pr-0 pl-3"
                        style="min-width: 100px"
                      >
                        Submission Rate [%]
                      </th>
                      <th
                        class="text-center min pr-0 pl-3"
                        style="min-width: 100px"
                      >
                        Class Average [%]
                      </th>
                      <th
                        class="text-center min pr-0 pl-3"
                        style="min-width: 100px"
                      >
                        Time Spent by Teacher
                      </th>
                      <th
                        class="text-center min pr-0 pl-3"
                        style="min-width: 100px"
                      >
                        Time Spent by Student
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(school, i) in schoolPerformance"
                      :key="`school${i}`"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ school.code }}</td>
                      <td class="text-center">
                        {{ school.total_assignments }}
                      </td>
                      <td class="text-center">
                        {{ school.total_submission_percent | twoDec }} %
                      </td>
                      <td class="text-center">
                        {{ school.total_average_score | twoDec }} %
                      </td>
                      <td class="text-center">
                        {{ school.totalTimeSpentByTeacher | Sec_HR }}
                      </td>
                      <td class="text-center">
                        {{ school.totalTimeSpentByStudent | Sec_HR }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="12" v-if="SchoolPerformanceChart.length > 0">
          <v-card elevation="6">
            <v-card-title>
              Submission Rate Vs Class Average
              <v-spacer></v-spacer>
              <view-fullscreen
                :title="`Submission Rate VS Clase Average`"
                :type="`ColumnChart`"
                :Data="SchoolPerformanceChart"
                :Options="columnchartOptions"
              ></view-fullscreen>
            </v-card-title>
            <v-card-text>
              <GChart
                type="ColumnChart"
                :data="SchoolPerformanceChart"
                :options="columnchartOptions"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      form: {},
      isLoading: false,
      schools: [],
      schoolItems: [
        {
          id: 0,
          text: "Select School",
          value: 0,
        },
      ],
      schoolPerformance: [],
      // Chart Components
      SchoolBasedOn: "1",
      SchoolPerformanceChart: [],
      columnchartOptions: {
        theme: "material",
        colors: ["#2196F3", "#4CAF50", "#9E9E9E"],
        chartArea: { left: 50, right: 50 },
        bar: { groupWidth: "30%" },
        annotations: {
          textStyle: {
            fontSize: 10,
          },
        },
        vAxis: {
          minValue: 0,
          maxValue: 100,
          title: "In Percentage / Count",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 40 },
          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          title: "Schools",
          titleTextStyle: {
            bold: true,
          },
          gridlines: {
            color: "transparent",
          },
        },
        legend: { position: "top", alignment: "center" },
      },
    };
  },
  mounted() {
    // this.getData();
    this.getMasters();
  },

  methods: {
    async getMasters() {
      let schools = await axios.get(`companies`);
      this.schools = schools.data.data;
      // ClassCodes
      this.schools.forEach((school) => {
        this.schoolItems.push({
          id: school.id,
          text: school.name,
          value: school.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let response;
      this.form.school_ids = this.form.school_ids ? this.form.school_ids : "";
      let query = "school_ids=" + this.form.school_ids;
      response = await axios.get(`/school_wise_performance?${query}`);
      this.schoolPerformance = response.data.data;
      console.log(this.schoolPerformance);
      if (this.schoolPerformance) {
        // School Wise Percentage [Score / Class]
        let SchoolPerformanceChart = [
          [
            this.SchoolBasedOn,
            "Assignments",
            "Submisssion Rate [%]",
            "Class Average [%]",
          ],
        ];
        this.schoolPerformance.forEach((data) => {
          let submissionPercent = parseFloat(
            data.total_submission_percent != 0
              ? data.total_submission_percent.toFixed(2)
              : 0
          );
          let totalAveragePercent = parseFloat(
            data.total_average_score != 0
              ? data.total_average_score.toFixed(2)
              : 0
          );
          SchoolPerformanceChart.push([
            data.name,
            data.total_assignments,
            submissionPercent,
            totalAveragePercent,
          ]);
        });
        this.SchoolPerformanceChart = SchoolPerformanceChart;
        console.log(this.SchoolPerformanceChart);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
