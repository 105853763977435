<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="
              roleName == 'ACADEMIC TEAM' || roleName == 'INFAKT TEACHER'
                ? 'Archive'
                : 'Library'
            "
          ></span>
        </v-col>
      </v-row>
      <br />
      <loader v-if="isLoading"></loader>
      <v-tabs fixed-tabs background-color="primary" dark v-model="tab">
        <v-tab href="#tab-1"> Saved Articles </v-tab>
        <v-tab href="#tab-3"> Saved Infographics </v-tab>
        <v-tab href="#tab-2"> Saved Videos </v-tab>
        <v-tab href="#tab-4"> My Collections </v-tab>
        <v-tab href="#tab-5" v-if="roleName == 'STUDENT'">
          Shared Collections
        </v-tab>
        <v-tab href="#tab-6" v-if="roleName == 'ACADEMIC TEAM'">
          Pending Collections
        </v-tab>
        <v-tab href="#tab-7" v-if="roleName == 'ACADEMIC TEAM'">
          Approved Collections
        </v-tab>
        <v-tab href="#tab-8" v-if="roleName == 'ACADEMIC TEAM'">
          Rejected Collections
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in 8" :key="i" :value="'tab-' + i">
          <v-card flat>
            <v-card-text>
              <v-row>
                <template v-for="(bookmark, a) in bookmarks">
                  <v-col
                    sm="4"
                    :key="`bookmark${a}`"
                    v-if="
                      bookmark.content &&
                      ((savedArticles(bookmark) && i == 1) ||
                        (savedVideo(bookmark) && i == 2) ||
                        (savedInfographics(bookmark) && i == 3))
                    "
                  >
                    <Article
                      v-if="bookmark.content"
                      :article="bookmark.content"
                      :source="'bookmark'"
                      :bookmark_id="bookmark.id"
                      :type="bookmark.content.content_type"
                    ></Article>
                  </v-col>
                </template>
                <template v-for="(collection, a) in collections">
                  <v-col sm="4" :key="`collection${a}`" v-if="i == 4">
                    <v-card elevation="6" shaped>
                      <router-link
                        class="routerLink"
                        :to="`/library/collections/${collection.id}`"
                      >
                        <!-- IF Feature Image Found -->
                        <div v-if="collection.featured_image_path">
                          <v-img
                            max-height="210"
                            cover
                            class="rounded-tl-xl feature"
                            :src="`${
                              mediaUrl + collection.featured_image_path
                            }`"
                          ></v-img>
                        </div>
                        <v-img
                          v-else
                          :src="require('@/assets/imgs/collection.png')"
                          height="180"
                        ></v-img>
                        <v-card-title>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <h3
                                v-if="collection.collection_name"
                                v-bind="attrs"
                                v-on="on"
                                class="wrap"
                                v-html="collection.collection_name"
                              ></h3>
                            </template>
                            <span v-html="collection.collection_name"> </span>
                          </v-tooltip>
                        </v-card-title>
                      </router-link>
                      <v-divider
                        v-if="roleName != 'STUDENT'"
                        class="mx-4"
                      ></v-divider>
                      <v-card-actions v-if="roleName != 'STUDENT'" class="pt-0">
                        &nbsp;&nbsp;
                        <div v-if="roleName == 'INFAKT TEACHER'">
                          <p v-if="collection.status == 0">PENDING</p>
                          <p v-if="collection.status == 1">APPROVED</p>
                          <p v-if="collection.status == 2">REJECTED</p>
                          <p v-if="collection.status == 2">
                            Remarks: <span v-html="collection.remarks"></span>
                          </p>
                        </div>
                        <v-spacer></v-spacer>
                        <!-- Create Assignment Button -->
                        <v-tooltip
                          bottom
                          v-if="
                            (roleName == `INFAKT TEACHER` &&
                              collection.status == 1) ||
                            roleName != `INFAKT TEACHER`
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mx-1"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              fab
                              x-small
                              color="primary"
                              :to="`/assignments/create?collectionId=${collection.id}`"
                            >
                              <v-icon>mdi-clipboard-text</v-icon>
                            </v-btn>
                          </template>
                          <span> Create Assignment </span>
                        </v-tooltip>
                        <!-- Share Collection Button -->
                        <v-tooltip
                          bottom
                          v-if="
                            roleName != 'INFAKT TEACHER' &&
                            roleName != 'ACADEMIC TEAM'
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="mx-1"
                              dark
                              fab
                              x-small
                              color="primary"
                              @click="
                                shareCollection(
                                  collection.id,
                                  (isShared = true)
                                )
                              "
                            >
                              <v-icon> mdi-share-variant-outline </v-icon>
                            </v-btn>
                          </template>
                          <span> Share Collection </span>
                        </v-tooltip>
                        <!-- Edit Collection Button -->
                        <v-tooltip
                          bottom
                          v-if="
                            (roleName == `INFAKT TEACHER` &&
                              collection.status == 0) ||
                            (roleName == `INFAKT TEACHER` &&
                              collection.status == 2) ||
                            roleName != `INFAKT TEACHER`
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              v-bind="attrs"
                              v-on="on"
                              class="mx-1"
                              x-small
                              color="primary"
                              @click="getCollection(collection)"
                            >
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span> Edit Collection </span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </template>
                <template v-for="(sharedCollection, a) in sharedCollections">
                  <v-col sm="4" :key="`sharedCollection${a}`" v-if="i == 5">
                    <v-card elevation="6" shaped>
                      <router-link
                        class="routerLink"
                        :to="`/library/collections/${sharedCollection.collection.id}`"
                      >
                        <!-- IF Feature Image Found -->
                        <div
                          v-if="sharedCollection.collection.featured_image_path"
                        >
                          <v-img
                            max-height="210"
                            cover
                            class="rounded-tl-xl feature"
                            :src="`${
                              mediaUrl +
                              sharedCollection.collection.featured_image_path
                            }`"
                          ></v-img>
                        </div>
                        <v-img
                          v-else
                          :src="require('@/assets/imgs/collection.png')"
                          height="180"
                        ></v-img>
                        <v-card-title>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <h3
                                v-if="
                                  sharedCollection.collection.collection_name
                                "
                                v-bind="attrs"
                                v-on="on"
                                class="wrap"
                                v-html="
                                  sharedCollection.collection.collection_name
                                "
                              ></h3>
                            </template>
                            <span
                              v-html="
                                sharedCollection.collection.collection_name
                              "
                            >
                            </span>
                          </v-tooltip>
                        </v-card-title>
                      </router-link>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-text>
                        <v-row>
                          <v-col md="9"
                            >Shared by :
                            {{ sharedCollection.shared_by.name }}</v-col
                          >
                          <v-col md="3" align="right">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-spacer></v-spacer>
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mx-2"
                                  fab
                                  x-small
                                  @click="SaveInMyCollection(sharedCollection)"
                                >
                                  <v-icon color="primary">
                                    mdi-content-save-plus-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Save in my collecton </span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                <!-- Collections Pending for Approval -->
                <template v-for="(pendingCollection, a) in pendingCollections">
                  <v-col sm="4" :key="`collection${a}`" v-if="i == 6">
                    <v-card elevation="6" shaped>
                      <router-link
                        class="routerLink"
                        :to="`/library/collections/${pendingCollection.id}`"
                      >
                        <!-- IF Feature Image Found -->
                        <div v-if="pendingCollection.featured_image_path">
                          <v-img
                            max-height="210"
                            cover
                            class="rounded-tl-xl feature"
                            :src="`${
                              mediaUrl + pendingCollection.featured_image_path
                            }`"
                          ></v-img>
                        </div>
                        <v-img
                          v-else
                          :src="require('@/assets/imgs/collection.png')"
                          height="180"
                        ></v-img>
                        <v-card-title>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <h3
                                v-if="pendingCollection.collection_name"
                                v-bind="attrs"
                                v-on="on"
                                class="wrap"
                                v-html="pendingCollection.collection_name"
                              ></h3>
                            </template>
                            <span v-html="pendingCollection.collection_name">
                            </span>
                          </v-tooltip>
                        </v-card-title>
                      </router-link>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-actions class="pt-0">
                        <template>
                          <p>
                            <b class="primary--text ml-2">
                              {{ pendingCollection.user.name }}</b
                            >
                          </p>
                        </template>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              v-bind="attrs"
                              v-on="on"
                              class="mx-1"
                              x-small
                              color="primary"
                              @click="getCollection(pendingCollection)"
                            >
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span> Edit Collection </span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </template>
                <!-- Collections Already Approved -->
                <template
                  v-for="(approvedCollection, a) in approvedCollections"
                >
                  <v-col sm="4" :key="`collection${a}`" v-if="i == 7">
                    <v-card elevation="6" shaped>
                      <router-link
                        class="routerLink"
                        :to="`/library/collections/${approvedCollection.id}`"
                      >
                        <!-- IF Feature Image Found -->
                        <div v-if="approvedCollection.featured_image_path">
                          <v-img
                            max-height="210"
                            cover
                            class="rounded-tl-xl feature"
                            :src="`${
                              mediaUrl + approvedCollection.featured_image_path
                            }`"
                          ></v-img>
                        </div>
                        <v-img
                          v-else
                          :src="require('@/assets/imgs/collection.png')"
                          height="180"
                        ></v-img>
                        <v-card-title>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <h3
                                v-if="approvedCollection.collection_name"
                                v-bind="attrs"
                                v-on="on"
                                class="wrap"
                                v-html="approvedCollection.collection_name"
                              ></h3>
                            </template>
                            <span v-html="approvedCollection.collection_name">
                            </span>
                          </v-tooltip>
                        </v-card-title>
                      </router-link>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-actions class="pt-0">
                        <template>
                          <p>
                            <b class="primary--text ml-2">
                              {{ approvedCollection.user.name }}</b
                            >
                          </p>
                        </template>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              v-bind="attrs"
                              v-on="on"
                              class="mx-1"
                              x-small
                              color="primary"
                              @click="getCollection(approvedCollection)"
                            >
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span> Edit Collection </span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </template>
                <!-- Collections Already Rejected -->
                <template
                  v-for="(rejectedCollection, a) in rejectedCollections"
                >
                  <v-col sm="4" :key="`collection${a}`" v-if="i == 8">
                    <v-card elevation="6" shaped>
                      <router-link
                        class="routerLink"
                        :to="`/library/collections/${rejectedCollection.id}`"
                      >
                        <!-- IF Feature Image Found -->
                        <div v-if="rejectedCollection.featured_image_path">
                          <v-img
                            max-height="210"
                            cover
                            class="rounded-tl-xl feature"
                            :src="`${
                              mediaUrl + rejectedCollection.featured_image_path
                            }`"
                          ></v-img>
                        </div>
                        <v-img
                          v-else
                          :src="require('@/assets/imgs/collection.png')"
                          height="180"
                        ></v-img>
                        <v-card-title>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <h3
                                v-if="rejectedCollection.collection_name"
                                v-bind="attrs"
                                v-on="on"
                                class="wrap"
                                v-html="rejectedCollection.collection_name"
                              ></h3>
                            </template>
                            <span v-html="rejectedCollection.collection_name">
                            </span>
                          </v-tooltip>
                        </v-card-title>
                      </router-link>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-actions class="pt-0">
                        <template>
                          <p>
                            <b class="primary--text ml-2">
                              {{ rejectedCollection.user.name }}</b
                            >
                          </p>
                        </template>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              v-bind="attrs"
                              v-on="on"
                              class="mx-1"
                              x-small
                              color="primary"
                              @click="getCollection(rejectedCollection)"
                            >
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span> Edit Collection </span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <v-dialog v-model="isShared" max-width="600px">
      <v-card elevation="10">
        <v-card-title>
          <v-icon large color="red darken-2">
            mdi-share-variant-outline
          </v-icon>
          &nbsp; Please select classcode to share your collection.
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-autocomplete
                ref="classcode"
                v-model="form.classcodes"
                :items="classcodeItems"
                label="Classcode"
                multiple
                prepend-icon="mdi-database-search"
                chips
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn dark small color="primary" @click="SaveShareCollection()"
                >Share</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Edit Collection -->
    <v-dialog v-model="isEdit" max-width="600px">
      <v-card elevation="10">
        <v-card-title>
          <v-icon large color="red darken-2"> mdi-pencil </v-icon>
          &nbsp; Please update your collection details.
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model="collection.collection_name"
                outlined
                label="Name"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.collection_name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <input
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="image/*"
                @change="onFileChange"
              />
              <p v-if="imageErrors" style="color: red">
                {{ imageErrors }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn dark small color="primary" @click="updateCollection()"
                >Update</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Change Collection Status  -->
    <v-dialog v-model="isChangeStatus" max-width="600px">
      <v-card elevation="10">
        <v-card-title>
          <v-icon large color="red darken-2"> mdi-pencil </v-icon>
          &nbsp; Change Collection Status.
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-autocomplete
                ref="Status"
                v-model="collection.status"
                :items="statusItems"
                label="Select Status"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="collection.status == 2">
            <v-col>
              <p class="font-weight-bold">Remark</p>
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="collection.remarks"
              ></froala>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                :loading="isStatusChanging"
                :disabled="isStatusChanging"
                dark
                small
                color="primary"
                @click="updateCollection()"
                >Update</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="success" max-width="600px">
      <v-card elevation="10">
        <v-card-title>
          <v-row>
            <v-col md="2"
              ><v-icon large color="red darken-2"> mdi-check </v-icon></v-col
            >
            <v-col md="10"
              >Shared collection Saved Successfully in your collection, You can
              check in your collection tab.</v-col
            >
          </v-row>
        </v-card-title>
      </v-card>
    </v-dialog>
    <!-- Image Warning popups -->
    <v-dialog v-model="warningImageLoader" width="600">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: red; font-size: 90px; width: 200px !important"
              >
                mdi-file-image-remove
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">OOPS!</h1>
                    <br />
                    <h3>
                      Image dimension is not matching with our standard
                      dimension. The image dimension should be multiple of 690 x
                      390
                    </h3>
                    <br />
                    <v-btn color="success" @click="warningImageLoader = false">
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Image Warning popups -->
  </v-main>
</template>
<script>
import axios from "axios";
import Article from "@/components/article.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "AssignmentsPage",
  data() {
    return {
      config: {
        toolbarButtons: [],
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Remark Here!",
        charCounterCount: false,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      form: {
        collection_classcodes: [],
        classcodes: [],
      },
      image: {
        size: "",
        height: "",
        width: "",
      },
      imageLoader: false,
      warningImageLoader: false,
      imageErrors: "",
      tab: "tab-1",
      isLoading: false,
      requestExtensionDateMenu: false,
      isShared: false,
      isEdit: false,
      isChangeStatus: false,
      isStatusChanging: false,
      success: false,
      bookmarks: [],
      collections: [],
      classcodeItems: [],
      sharedCollections: [],
      pendingCollections: [],
      approvedCollections: [],
      rejectedCollections: [],
      statusItems: [
        { id: "PENDING", text: "PENDING", value: 0 },
        { id: "APPROVED", text: "APPROVED", value: 1 },
        { id: "REJECTED", text: "REJECTED", value: 2 },
      ],
      collection: {},
    };
  },
  components: {
    Article,
    Loader,
  },
  mounted() {
    if (this.$route.query.tab) this.tab = this.$route.query.tab;
    this.getData();
    this.getMaster();
    this.myCollections();
    this.mySharedCollections();
    if (this.roleName == "ACADEMIC TEAM") {
      this.getPendingCollections();
      this.getApprovedCollections();
      this.getRejectedCollections();
    }
  },
  methods: {
    onFileChange(e) {
      this.warningImageLoader = false;
      this.file = this.$refs.file?.files[0];
      this.image.size = this.file.size;
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          let divWidth = Math.round(this.image.width / 690);
          let divHeight = Math.round(this.image.height / 390);

          let intoWidth = 690 * divWidth;
          let intoHeight = 390 * divHeight;

          if (
            intoWidth != this.image.width &&
            intoHeight != this.image.height
          ) {
            this.imageErrors =
              "Image dimension ( " +
              this.image.width +
              " x " +
              this.image.height +
              ") which is not matching with our standard dimension. The image dimension should be multiple of 690 x 390.";
            this.imageLoader = true;
            this.warningImageLoader = true;
          } else {
            this.imageLoader = false;
            this.image_url = URL.createObjectURL(this.file);
            this.imageErrors = "";
          }

          if (this.errors.length === 0) {
            this.success = "Image validated successfully";
          }
          // alert(img.width + " " + img.height);
        };
        img.src = evt.target.result;
      };
      reader.onerror = (evt) => {
        console.error(evt);
      };
      // this.image_url = URL.createObjectURL(this.file);
    },
    async getMaster() {
      // Classcodes
      this.user.user_classcodes.forEach((userClasscode) => {
        this.classcodeItems.push({
          id: userClasscode.classcode.id,
          text: userClasscode.classcode.classcode,
          value: userClasscode.classcode.id,
        });
      });
    },
    async getData() {
      try {
        this.isLoading = true;
        let bookmarks = await axios.get(`bookmarks?user_id=${this.user.id}`);
        this.bookmarks = bookmarks.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    savedArticles(bookmark) {
      if (bookmark.content.content_type == "ARTICLE") {
        return bookmark;
      }
    },
    savedVideo(bookmark) {
      if (bookmark.content.content_type == "VIDEO") {
        return bookmark;
      }
    },
    savedInfographics(bookmark) {
      if (bookmark.content.content_type == "INFOGRAPHIC") {
        return bookmark;
      }
    },
    async myCollections() {
      let collections = await axios.get(`collections?user_id=${this.user.id}`);
      this.collections = collections.data.data;
    },
    async mySharedCollections() {
      let sharedCollections = [];
      if (this.roleName == "STUDENT") {
        let query = "user_id=" + this.user.id + "&user_role=" + this.roleName;
        sharedCollections = await axios.get(`collection_classcodes?${query}`);
        this.sharedCollections = sharedCollections.data.data;
      }
    },
    async shareCollection(collection_id) {
      this.collection_id = collection_id;
      this.form.classcodes = [];
      let cc = await axios.get(
        `/collection_classcodes?collection_id=${this.collection_id}`
      );
      this.form.collection_classcodes = cc.data.data;
      if (this.form.collection_classcodes.length) {
        this.form.collection_classcodes.forEach((cc) => {
          this.form.classcodes.push(cc.classcode_id);
        });
      }
    },
    async SaveShareCollection() {
      try {
        let collection_classcodes = [];
        this.form.classcodes.forEach((classcode_id) => {
          if (classcode_id != null) {
            let collectionClasscode = this.form.collection_classcodes.find(
              (cc) => cc.classcode_id == classcode_id
            );
            if (collectionClasscode) {
              collection_classcodes.push(collectionClasscode);
            } else {
              let collection_classcode = {
                company_id: this.company.id,
                shared_by_id: this.user.id,
                classcode_id: classcode_id,
                collection_id: this.collection_id,
              };
              collection_classcodes.push(collection_classcode);
            }
          }
        });
        this.form.collection_classcodes = collection_classcodes;
        this.form.collection_id = this.collection_id;
        let response = await axios.post("/collection_classcodes", this.form);
        console.log(response.data);
        this.isShared = false;
      } catch (e) {
        this.isShared = false;
      }
    },
    async SaveInMyCollection(data) {
      try {
        let collectionData = {};
        collectionData.user_id = this.user.id;
        collectionData.collection_name = data.collection.collection_name;
        let storedSharedCollection = await axios.post(
          "/collections",
          collectionData
        );
        storedSharedCollection = storedSharedCollection.data.data;
        data.collection.collection_contents.forEach(async (cc) => {
          let cdata = {
            collection_id: storedSharedCollection.id,
            content_id: cc.content_id,
          };
          await axios.post("/collection_contents", cdata);
        });
        this.success = true;
        this.myCollections();
      } catch (e) {
        this.isShared = false;
      }
    },
    async getCollection(collection) {
      this.collection = {};
      this.collection = collection;
      if (this.collection.user_id == this.user.id) {
        // If My Collection then can edit
        this.isEdit = true;
      } else {
        // If not then Change Status
        this.isChangeStatus = true;
      }
    },
    async updateCollection() {
      this.isStatusChanging = true;
      try {
        if (this.imageLoader == false) {
          let response = await axios.patch(
            `collections/${this.collection.id}`,
            this.collection
          );
          this.collection = response.data.data;
          if (this.$refs.file && this.collection) this.handleFileUpload();
          this.myCollections();
          if (this.roleName == "ACADEMIC TEAM") {
            this.getPendingCollections();
            this.getApprovedCollections();
            this.getRejectedCollections();
          }
          this.isEdit = false;
          this.isChangeStatus = false;
        } else {
          this.warningImageLoader = true;
          this.isLoading = false;
        }
      } catch (e) {
        console.log(e);
        this.isEdit = false;
        this.isChangeStatus = false;
      }
      this.isStatusChanging = false;
    },
    async getPendingCollections() {
      let pendingCollections = await axios.get(
        `collections?is_pending_collection=1`
      );
      this.pendingCollections = pendingCollections.data.data;
    },
    async getApprovedCollections() {
      let approvedCollections = await axios.get(
        `collections?is_approved_collection=1`
      );
      this.approvedCollections = approvedCollections.data.data;
    },
    async getRejectedCollections() {
      let rejectedCollections = await axios.get(
        `collections?is_rejected_collection=1`
      );
      this.rejectedCollections = rejectedCollections.data.data;
    },
    async handleFileUpload() {
      let attachment = this.$refs.file?.files[0];
      const collection_id = this.collection.id;
      let formData = new FormData();
      formData.append("collection_id", collection_id);
      formData.append("featuredimagepath", attachment);
      await axios
        .post("upload_collection_featured_image", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
