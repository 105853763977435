<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 120px">
          <span class="text-h5 font-weight-bold" v-text="'Subjects'"></span>
          <AddButton :link="'/subjects/create'"></AddButton>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="min-width: 56px">Sr No</th>
                    <th class="text-left" style="min-width: 57px">Name</th>
                    <th class="text-left" style="min-width: 80px">is Active</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(subjects, i) in subjects" :key="subjects.name">
                    <td>{{ i + 1 }}</td>
                    <td style="min-width: 100px">{{ subjects.name }}</td>
                    <td>{{ subjects.is_active ? "TRUE" : "FALSE" }}</td>
                    <td class="text-right">
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/subjects/${subjects.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import AddButton from "@/components/addbutton.vue";
import Loader from "@/components/loader.vue";

export default {
  name: "BoardsPage",
  data() {
    return {
      subjects: [],
      isLoading: false,
    };
  },
  components: {
    AddButton,
    Loader,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let subjects = await axios.get(`subjects`);
      this.subjects = subjects.data.data;
      this.isLoading = false;
    },
  },
};
</script>
