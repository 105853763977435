<template>
  <v-row>
    <!-- Schools -->
    <v-col sm="3">
      <v-card color="primary" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5">{{ counts.schools }}</v-card-title>
            <v-card-subtitle>Companies</v-card-subtitle>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                to="/schools"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>
          <v-avatar class="ma-3" size="125" tile>
            <v-icon class="imgStyle" large dark
              >mdi-account-school-outline</v-icon
            >
          </v-avatar>
        </div>
      </v-card>
    </v-col>
    <!-- End Schools -->
    <!-- Teachers -->
    <v-col sm="3">
      <v-card color="primary" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5">{{ counts.teachers }}</v-card-title>
            <v-card-subtitle>Coordinators</v-card-subtitle>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                to="/teachers"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>
          <v-avatar class="ma-3" size="125" tile>
            <v-icon class="imgStyle" large dark>mdi-human-male-board</v-icon>
          </v-avatar>
        </div>
      </v-card>
    </v-col>
    <!-- End Teachers -->
    <!-- Students -->
    <v-col sm="3">
      <v-card color="primary" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5">{{ counts.students }}</v-card-title>
            <v-card-subtitle>TSIs</v-card-subtitle>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                to="/students"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>
          <v-avatar class="ma-3" size="125" tile>
            <v-icon class="imgStyle" large dark>mdi-human-child</v-icon>
          </v-avatar>
        </div>
      </v-card>
    </v-col>
    <!-- End Students -->
    <!-- Paid Vs Free Students -->
    <!-- <v-col sm="3">
      <v-card color="primary" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5">
              {{ pendingCareer + "/" + totalCareer }}</v-card-title
            >
            <v-card-subtitle>Career Requests</v-card-subtitle>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                to="/career-requests"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>
          <v-avatar class="ma-3" size="125" tile>
            <v-icon class="imgStyle" large dark>mdi-human-handsdown</v-icon>
          </v-avatar>
        </div>
      </v-card>
    </v-col> -->
    <!-- End Paid Vs Free Students -->
    <!-- Content Posted -->
    <!-- <v-col sm="3">
      <v-card color="primary" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5">{{ counts.contents }}</v-card-title>
            <v-card-subtitle>Content Posted</v-card-subtitle>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                to="/contents"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>
          <v-avatar class="ma-3" size="125" tile>
            <v-icon class="imgStyle" large dark>mdi-calendar-text</v-icon>
          </v-avatar>
        </div>
      </v-card>
    </v-col> -->
    <!-- End Content Posted -->
    <!-- TOI EPapers -->
    <!-- <v-col sm="3">
      <v-card color="primary" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5">{{
              counts.toi | humanizeCount
            }}</v-card-title>
            <v-card-subtitle>TOI EPapers</v-card-subtitle>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                to="/epaper/toi"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>

          <v-avatar class="ma-3" size="125" tile>
            <v-icon class="imgStyle" large dark>mdi-newspaper</v-icon>
          </v-avatar>
        </div>
      </v-card>
    </v-col> -->
    <!-- End TOI EPapers -->
    <!-- ET EPapers -->
    <!-- <v-col sm="3">
      <v-card color="primary" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5">
              {{ counts.et | humanizeCount }}</v-card-title
            >
            <v-card-subtitle>ET EPapers</v-card-subtitle>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                to="/epaper/et"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>

          <v-avatar class="ma-3" size="125" tile>
            <v-icon class="imgStyle" large dark
              >mdi-newspaper-variant-multiple</v-icon
            >
          </v-avatar>
        </div>
      </v-card>
    </v-col> -->
    <!-- End ET EPapers -->
    <!-- Request Counts -->
    <!-- <v-col sm="3">
      <v-card color="primary" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5">
              {{ pendingContact + "/" + totalContact }}</v-card-title
            >
            <v-card-subtitle>Request Counts</v-card-subtitle>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                to="/contact-requests"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>
          <v-avatar class="ma-3" size="125" tile>
            <v-icon class="imgStyle" large dark>mdi-card-account-mail</v-icon>
          </v-avatar>
        </div>
      </v-card>
    </v-col> -->
    <!-- End Request Counts -->
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "SACounts",
  data() {
    return {
      pendingCareer: 0,
      totalCareer: 0,
      pendingContact: 0,
      totalContact: 0,
      counts: {
        careerRequests: [],
        contactRequests: [],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let response = await axios.get("/superadminDashboard");
      this.counts = response.data.counts;
      if (this.counts) {
        this.pendingCareer = this.counts.careerRequests.find(
          (cR) => cR.name == "PENDING"
        ).count;
        this.totalCareer = this.counts.careerRequests.find(
          (cR) => cR.name == "TOTAL"
        ).count;
        this.pendingContact = this.counts.contactRequests.find(
          (cR) => cR.name == "PENDING"
        ).count;
        this.totalContact = this.counts.contactRequests.find(
          (cR) => cR.name == "TOTAL"
        ).count;
      }
    },
    getPendingCountRequest() {
      let PendingCount = 0;
      PendingCount = this.counts.contactRequests.find(
        (cR) => cR.name == "PENDING"
      );
      if (PendingCount) {
        PendingCount = PendingCount.count;
      }
      return PendingCount;
    },
    getPendingRequest() {
      let PendingCount = 0;
      PendingCount = this.counts.contactRequests.find(
        (cR) => cR.name == "PENDING"
      );
      if (PendingCount) {
        PendingCount = PendingCount.count;
      }
      return PendingCount;
    },
  },
};
</script>
