import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import axios from "axios";
Vue.use(VueRouter);

// Layout
import UnAuthLayout from "../views/layouts/unauth-layout.vue";
import NoLayout from "../views/layouts/no-layout.vue";
import RoleLayout from "../views/layouts/role-layout.vue";
// Static
import HomePage from "../views/home";
import AboutPage from "../views/about_us.vue";
import ContactUsPage from "../views/contact_us.vue";
import CareersPage from "../views/careers.vue";
import FeaturesPage from "../views/features.vue";
import BenefitsPage from "../views/benefits.vue";
import PrivacyPolicyPage from "../views/privacy_policy.vue";
import TermsOfUsPage from "../views/terms_of_us.vue";
// Global
import GlobalSettingsPage from "../views/global-settings.vue";
// Auth
import LoginPage from "../views/auth/login.vue";
import RoleLoginPage from "../views/auth/role-login.vue";
import ResetPasswordPage from "../views/auth/reset-password.vue";
import ProfilePage from "../views/auth/profile.vue";
// Dashboards
import SuperAdminDashboardPage from "../views/dashboards/superadmin-dashboard";
import AdminDashboardPage from "../views/dashboards/admin-dashboard.vue";
import TeacherDashboardPage from "../views/dashboards/teacher-dashboard.vue";
import InfaktTeacherDashboardPage from "../views/dashboards/infakt-teacher-dashboard.vue";
import StudentDashboardPage from "../views/dashboards/student-dashboard.vue";
import TicketDashboardPage from "../views/dashboards/ticket-dashboard.vue";
import UserTimestampDashboardPage from "../views/dashboards/user-timestamp-dashboard.vue";
import UserTimestampLogPage from "../views/dashboards/user-timestamp-logs.vue";
// ------------------------------------------------------------------------------------------
// Super Admin
// Schools
import SchoolsPage from "../views/schools";
import SchoolCreatePage from "../views/schools/create.vue";
import SchoolIdPage from "../views/schools/id.vue";
import SchoolAdminPage from "../views/schools/adminCreate.vue";
import SchoolAdminIdPage from "../views/schools/adminId.vue";
// Users
import UsersPage from "../views/users";
import UserCreatePage from "../views/users/create.vue";
import UserIdPage from "../views/users/id.vue";
// Career Requests
import CareerRequestsPage from "../views/career-requests";
import CareerRequestCreatePage from "../views/career-requests/create.vue";
import CareerRequestIdPage from "../views/career-requests/id.vue";
// Contact Requests
import ContactRequestsPage from "../views/contact-requests";
import ContactRequestCreatePage from "../views/contact-requests/create.vue";
import ContactRequestIdPage from "../views/contact-requests/id.vue";
// Grades
import GradesPage from "../views/grades";
import GradeCreatePage from "../views/grades/create.vue";
import GradeIdPage from "../views/grades/id.vue";
// Boards
import BoardsPage from "../views/boards";
import BoardCreatePage from "../views/boards/create.vue";
import BoardIdPage from "../views/boards/id.vue";
// Categories
import CategoriesPage from "../views/categories";
import CategoryCreatePage from "../views/categories/create.vue";
import CategoryIdPage from "../views/categories/id.vue";
// Subejcts
import SubjectsPage from "../views/subjects";
import SubjectCreatePage from "../views/subjects/create.vue";
import SubjectIdPage from "../views/subjects/id.vue";
// Contents
import ContentsV2Page from "../views/contents/index-v2.vue";
import ContentsPage from "../views/contents";
import ContentCreateV3Page from "../views/contents/create-v3.vue";
import ContentCreateV2Page from "../views/contents/create-v2.vue";
import ContentCreatePage from "../views/contents/create.vue";
import ContentIdPage from "../views/contents/id.vue";
// XMLs
import XMLSTOIPage from "../views/xmls/toi_upload";
import XMLSETPage from "../views/xmls/et_upload";
import XmlsSpacePage from "../views/xmls/space_upload";
import XmlsLiveSciencePage from "../views/xmls/live_science_upload";
// Epaper
import EpaperTOIPage from "../views/epaper/toi";
import EpaperTOIIdPage from "../views/epaper/toi/id.vue";
import EpaperSpacePage from "../views/epaper/space";
import EpaperSpaceIdPage from "../views/epaper/space/id.vue";
import EpaperLiveSciencePage from "../views/epaper/live-science";
import EpaperLiveScienceIdPage from "../views/epaper/live-science/id.vue";
import EpaperETPage from "../views/epaper/et";
import EpaperETIdPage from "../views/epaper/et/id.vue";
// Epaper Dashboard
import EpaperDashboardPage from "../views/epaper/dashboard.vue";
// End Super Admin
// ------------------------------------------------------------------------------------------
// Teachers
import TeachersPage from "../views/teachers";
import TeachersCreatePage from "../views/teachers/create.vue";
import TeachersUploadPage from "../views/teachers/upload.vue";
import TeachersEditPage from "../views/teachers/id.vue";
// User ClassCode
import TeacherClassesPage from "../views/teachers/classes";
import ClassDashboardPage from "../views/dashboards/class-dashboard.vue";
import ClassDashboardV2Page from "../views/dashboards/class-dashboard-v2.vue";
import DayPage from "../views/dashboards/day.vue";
// Students
import StudentsPage from "../views/students";
import StudentsCreatePage from "../views/students/create.vue";
import StudentsUploadPage from "../views/students/upload.vue";
import StudentsEditPage from "../views/students/id.vue";
// Standards
import StandardsPage from "../views/standards";
import StandardsCreatePage from "../views/standards/create.vue";
import StandardsUploadPage from "../views/standards/upload.vue";
import StandardsEditPage from "../views/standards/id.vue";
// Standard Section
import StandardSectionsPage from "../views/standards/sections/index.vue";
import StandardSectionsCreatePage from "../views/standards/sections/create.vue";
import StandardSectionsUploadPage from "../views/standards/sections/upload.vue";
import StandardSectionsEditPage from "../views/standards/sections/id.vue";
// Classes
import StandardSectionClassesPage from "../views/standards/sections/classes/index.vue";
import StandardSectionClassesCreatePage from "../views/standards/sections/classes/create.vue";
import StandardSectionClassesUploadPage from "../views/standards/sections/classes/upload.vue";
import StandardSectionClassesViewTeacherPage from "../views/teachers/index.vue";
import StandardSectionClassesViewStudentPage from "../views/students/index.vue";
import StandardSectionClassesEditPage from "../views/standards/sections/classes/id.vue";
// Assignments
import AssignmentsPage from "../views/assignments";
import AssignmentsNewPage from "../views/assignments/new-index.vue";
import AssignmentsV2Page from "../views/assignments/index-v2.vue";
import AssignmentsV3Page from "../views/assignments/index-v3.vue";
import AssignmentCreatePage from "../views/assignments/create";
import AssignmentCreateV2Page from "../views/assignments/create-v2";
import AssignmentIdPage from "../views/assignments/id";
import AssignmentTakePage from "../views/assignments/take-assignment";
import AssignmentResultPage from "../views/assignments/check-result";
import AssignmentSubmissionsPage from "../views/assignments/submissions";
// Articles
// import ArticlesPage from "../views/articles";
import ArticleIdPage from "../views/articles/id.vue";
import ArticleIdV2Page from "../views/articles/id-v2.vue";
import ArticlesSearchPage from "../views/articles/search.vue";
import BrowsePage from "../views/articles/new-index.vue";
import ArticleVariantIdPage from "../views/articles/id.vue";
import ArticleAssignmentsPage from "../views/articles/article_assignments.vue";
import ArticlePersonalizedAssignmentCreatePage from "../views/articles/personalized-assignments.vue";
import ArticlePersonalizedAssignmentEditPage from "../views/articles/edit-personalized-assignments.vue";
// Libraries
import LibraryPage from "../views/libraries";
import CollectionIdPage from "../views/libraries/id.vue";
import CollectionAssignmentsPage from "../views/libraries/collection_assignments.vue";

// Epaper Library
import EpaperLibraryPage from "../views/libraries/epaper-index.vue";
import EpaperLibraryIdPage from "../views/libraries/epaper-id.vue";

// Notifications
import NotificationsPage from "../views/notifications";
import NotificationsCreatePage from "../views/notifications/create.vue";
import NotificationsEditPage from "../views/notifications/id.vue";

// Reports
import TaskWiseReportsPage from "../views/reports/task-wise-report.vue";
import StudentWiseReportsPage from "../views/reports/student-wise-report.vue";
import ClassWisePerformanceReportsPage from "../views/reports/class-wise-performance-report.vue";
import TeacherPerformanceReportsPage from "../views/reports/teacher-performance-report.vue";
import SubjectOverviewReportsPage from "../views/reports/subject-overview-report.vue";
import AssignmentWiseReportsPage from "../views/reports/assignment-wise-performance-report.vue";
import SchoolWiseReportsPage from "../views/reports/school-wise-performance-report.vue";
import SchoolSubjectWiseReportsPage from "../views/reports/subject-wise-performance-report.vue";
import LearningOutcomeReportsPage from "../views/reports/learning-outcome-report.vue";
import SectionWiseReport from "../views/reports/section-wise-report.vue";
import PublicationReportsPage from "../views/reports/publication-report.vue";
import PublicationReportsV2Page from "../views/reports/publication-report-v2.vue";
// Exceeded Device Logs
import ExceededDeviceLogPage from "../views/dashboards/exceeded-device-log.vue";

import AcademicTeamContent from "../views/academic-teams/index.vue";
import AcademicTeamIdContent from "../views/academic-teams/id.vue";
import AcademicTeamApprovals from "../views/academic-teams/approval-index.vue";
import AcademicTeamUsers from "../views/academic-teams/user-index.vue";
import AcademicTeamIdUsers from "../views/academic-teams/user-id.vue";

// Manuals
import ManualsPage from "../views/manuals";
import ManualsCreatePage from "../views/manuals/create.vue";
import ManualsEditPage from "../views/manuals/id.vue";

import ManualDetailsPage from "../views/manuals/manual_details";
import ManualDetailsCreatePage from "../views/manuals/manual_details/create.vue";
import ManualDetailsEditPage from "../views/manuals/manual_details/id.vue";

// Blog
import BlogsPage from "../views/blogs";
import BlogsCreatePage from "../views/blogs/create.vue";
import BlogsEditPage from "../views/blogs/id.vue";
// Blog Details
import BlogDetailsPage from "../views/blog-details";
import BlogDetailsCreatePage from "../views/blog-details/create.vue";
import BlogDetailsEditPage from "../views/blog-details/id.vue";
// Course
import CoursesPage from "../views/courses";
import CoursesCreatePage from "../views/courses/create.vue";
import CoursesEditPage from "../views/courses/id.vue";
// LessonPlan
import LessonPlansPage from "../views/lps";
import LessonPlansCreatePage from "../views/lps/create.vue";
import LessonPlansEditPage from "../views/lps/id.vue";

import QuizAssignmentsPage from "../views/assignments/quiz-index";
// About us
import AboutUs from "../views/about-us/index.vue";

// Get Started
import GetStarted from "../views/get-started";

import InfaktCollectionsPage from "../views/libraries/infakt-collections.vue";
import TeacherHiddenClasscodesPage from "../views/contents/teacher-hidden-classcode.vue";
import TeacherLockedLexilesPage from "../views/contents/teacher-locked-lexile.vue";
const routes = [
  // UnAuthLayout
  {
    path: "",
    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      {
        path: "/",
        name: "HomePage",
        component: HomePage,
        meta: { shouldCache: false },
      },
      {
        path: "/about-us",
        name: "AboutPage",
        component: AboutPage,
      },
      {
        path: "/contact-us",
        name: "ContactUsPage",
        component: ContactUsPage,
      },
      {
        path: "/careers",
        name: "CareersPage",
        component: CareersPage,
      },
      {
        path: "/features",
        name: "FeaturesPage",
        component: FeaturesPage,
      },
      {
        path: "/benefits",
        name: "BenefitsPage",
        component: BenefitsPage,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicyPage",
        component: PrivacyPolicyPage,
      },
      {
        path: "/terms-of-us",
        name: "TermsOfUsPage",
        component: TermsOfUsPage,
      },
    ],
  },
  //End UnAuthLayout

  // NoLayout
  {
    path: "",
    name: "NoLayout",
    component: NoLayout,
    children: [
      {
        path: "/auth/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/superadmin/login",
        name: "SuperAdminLoginPage",
        component: RoleLoginPage,
      },
      {
        path: "/admin/login",
        name: "AdminLoginPage",
        component: RoleLoginPage,
      },
      {
        path: "/teacher/login",
        name: "TeacherLoginPage",
        component: RoleLoginPage,
      },
      {
        path: "/student/login",
        name: "StudentLoginPage",
        component: RoleLoginPage,
      },
      {
        path: "/infakt_teacher/login",
        name: "InfaktTeacherLoginPage",
        component: RoleLoginPage,
      },
      {
        path: "/reset-password",
        name: "ResetPasswordPage",
        component: ResetPasswordPage,
      },
    ],
  },
  // End NoLayout

  // RoleLayout
  {
    path: "",
    name: "RoleLayout",
    component: RoleLayout,
    children: [
      {
        path: "/global-settings",
        name: "GlobalSettingsPage",
        component: GlobalSettingsPage,
      },
      {
        path: "/profile",
        name: "ProfilePage",
        component: ProfilePage,
      },
      {
        path: "/dashboards/superadmin",
        name: "SuperAdminDashboardPage",
        component: SuperAdminDashboardPage,
        meta: { shouldCache: false },
      },
      {
        path: "/dashboards/admin",
        name: "AdminDashboardPage",
        component: AdminDashboardPage,
        meta: { shouldCache: false },
      },
      {
        path: "/dashboards/teacher",
        name: "TeacherDashboardPage",
        component: TeacherDashboardPage,
        meta: { shouldCache: false },
      },
      {
        path: "/dashboards/infakt_teacher",
        name: "InfaktTeacherDashboardPage",
        component: InfaktTeacherDashboardPage,
        meta: { shouldCache: false },
      },
      {
        path: "/dashboards/student",
        name: "StudentDashboardPage",
        component: StudentDashboardPage,
        meta: { shouldCache: false },
      },
      {
        path: "/dashboards/ticket",
        name: "TicketDashboardPage",
        component: TicketDashboardPage,
        meta: { shouldCache: false },
      },
      // Super Admin
      {
        path: "/exceeded-device-log",
        name: "ExceededDeviceLogPage",
        component: ExceededDeviceLogPage,
        meta: { shouldCache: false },
      },
      {
        path: "/schools",
        name: "SchoolsPage",
        component: SchoolsPage,
      },
      {
        path: "/schools/create",
        name: "SchoolCreatePage",
        component: SchoolCreatePage,
      },
      {
        path: "/schools/:schoolId",
        name: "SchoolIdPage",
        component: SchoolIdPage,
      },
      {
        path: "/schools/:schoolId/admin/create",
        name: "SchoolAdminPage",
        component: SchoolAdminPage,
      },
      {
        path: "/schools/:schoolId/admin/:userId",
        name: "SchoolAdminIdPage",
        component: SchoolAdminIdPage,
      },
      {
        path: "/admin/users",
        name: "UsersPage",
        component: UsersPage,
      },
      {
        path: "/admin/users/create",
        name: "UserCreatePage",
        component: UserCreatePage,
      },
      {
        path: "/admin/users/:userId",
        name: "UserIdPage",
        component: UserIdPage,
      },
      {
        path: "/career-requests",
        name: "CareerRequestsPage",
        component: CareerRequestsPage,
      },
      {
        path: "/career-requests/create",
        name: "CareerRequestCreatePage",
        component: CareerRequestCreatePage,
      },
      {
        path: "/career-requests/:careerRequestId",
        name: "CareerRequestIdPage",
        component: CareerRequestIdPage,
      },
      {
        path: "/contact-requests",
        name: "ContactRequestsPage",
        component: ContactRequestsPage,
      },
      {
        path: "/contact-requests/create",
        name: " ContactRequestCreatePage",
        component: ContactRequestCreatePage,
      },
      {
        path: "/contact-requests/:contactRequestId",
        name: " ContactRequestIdPage",
        component: ContactRequestIdPage,
      },
      {
        path: "/grades",
        name: "GradesPage",
        component: GradesPage,
      },
      {
        path: "/grades/create",
        name: "GradeCreatePage",
        component: GradeCreatePage,
      },
      {
        path: "/grades/:gradeId",
        name: "GradeIdPage",
        component: GradeIdPage,
      },
      {
        path: "/categories",
        name: "CategoriesPage",
        component: CategoriesPage,
      },
      {
        path: "/categories/create",
        name: "CategoryCreatePage",
        component: CategoryCreatePage,
      },
      {
        path: "/categories/:categoryId",
        name: "CategoryIdPage",
        component: CategoryIdPage,
      },
      {
        path: "/boards",
        name: "BoardsPage",
        component: BoardsPage,
      },
      {
        path: "/boards/create",
        name: "BoardCreatePage",
        component: BoardCreatePage,
      },
      {
        path: "/boards/:boardId",
        name: "BoardIdPage",
        component: BoardIdPage,
      },
      {
        path: "/subjects",
        name: "SubjectsPage",
        component: SubjectsPage,
      },
      {
        path: "/subjects/create",
        name: "SubjectCreatePage",
        component: SubjectCreatePage,
      },
      {
        path: "/subjects/:subjectId",
        name: "SubjectIdPage",
        component: SubjectIdPage,
      },
      {
        path: "/contents",
        name: "ContentsPage",
        component: ContentsPage,
      },
      {
        path: "/contents/index-v2",
        name: "ContentsV2Page",
        component: ContentsV2Page,
      },
      {
        path: "/contents/create",
        name: "ContentCreatePage",
        component: ContentCreatePage,
      },
      {
        path: "/contents/create-V2",
        name: "ContentCreateV2Page",
        component: ContentCreateV2Page,
      },
      {
        path: "/contents/create-V3",
        name: "ContentCreateV3Page",
        component: ContentCreateV3Page,
      },
      {
        path: "/teacher-hidden-classcode",
        name: "TeacherHiddenClasscodesPage",
        component: TeacherHiddenClasscodesPage,
      },
      {
        path: "/teacher-locked-lexile",
        name: "TeacherLockedLexilesPage",
        component: TeacherLockedLexilesPage,
      },
      {
        path: "/contents/:contentId",
        name: "ContentIdPage",
        component: ContentIdPage,
      },
      {
        path: "/xmls/toi",
        name: "XMLSTOIPage",
        component: XMLSTOIPage,
      },
      {
        path: "/xmls/et",
        name: "XMLSETPage",
        component: XMLSETPage,
      },
      {
        path: "/xmls/space",
        name: "XmlsSpacePage",
        component: XmlsSpacePage,
      },
      {
        path: "/xmls/live-science",
        name: "XmlsLiveSciencePage",
        component: XmlsLiveSciencePage,
      },
      {
        path: "/epaper/toi",
        name: "EpaperTOIPage",
        component: EpaperTOIPage,
      },
      {
        path: "/epaper/toi/:toiId",
        name: "EpaperTOIIdPage",
        component: EpaperTOIIdPage,
      },
      {
        path: "/epaper/space",
        name: "EpaperSpacePage",
        component: EpaperSpacePage,
      },
      {
        path: "/epaper/space/:spaceId",
        name: "EpaperSpaceIdPage",
        component: EpaperSpaceIdPage,
      },
      {
        path: "/epaper/live-science",
        name: "EpaperLiveSciencePage",
        component: EpaperLiveSciencePage,
      },
      {
        path: "/epaper/live-science/:liveScienceId",
        name: "EpaperLiveScienceIdPage",
        component: EpaperLiveScienceIdPage,
      },
      {
        path: "/epaper/et",
        name: "EpaperETPage",
        component: EpaperETPage,
      },
      {
        path: "/epaper/et/:etId",
        name: "EpaperETIdPage",
        component: EpaperETIdPage,
      },
      {
        path: "/epaper/dashboard",
        name: "EpaperDashboardPage",
        component: EpaperDashboardPage,
      },
      {
        path: "/dashboard/user-timestamp",
        name: "UserTimestampDashboardPage",
        component: UserTimestampDashboardPage,
      },
      {
        path: "/dashboard/user-timestamp/logs",
        name: "UserTimestampLogPage",
        component: UserTimestampLogPage,
      },
      // Teachers
      {
        path: "/teachers",
        name: "TeachersPage",
        component: TeachersPage,
      },
      {
        path: "/teachers/create",
        name: "TeachersCreatePage",
        component: TeachersCreatePage,
      },
      {
        path: "/teachers/upload",
        name: "TeachersUploadPage",
        component: TeachersUploadPage,
      },
      {
        path: "/teachers/:teacherId",
        name: "TeachersEditPage",
        component: TeachersEditPage,
      },
      {
        path: "/teacher_classes/:teacherId",
        name: "MyClassesPage",
        component: TeacherClassesPage,
        meta: { shouldCache: false },
      },
      {
        path: "/class_dashboard/:classId",
        name: "ClassDashboardPage",
        component: ClassDashboardPage,
      },
      {
        path: "/class_dashboard-v2/:classId",
        name: "ClassDashboardV2Page",
        component: ClassDashboardV2Page,
      },
      {
        path: "/days",
        name: "DayPage",
        component: DayPage,
      },
      // Students
      {
        path: "/students",
        name: "StudentsPage",
        component: StudentsPage,
      },
      {
        path: "/students/create",
        name: "StudentsCreatePage",
        component: StudentsCreatePage,
      },
      {
        path: "/students/upload",
        name: "StudentsUploadPage",
        component: StudentsUploadPage,
      },
      {
        path: "/students/:studentId",
        name: "StudentsEditPage",
        component: StudentsEditPage,
      },
      // Standards
      {
        path: "/standards",
        name: "StandardsPage",
        component: StandardsPage,
      },
      {
        path: "/standards/create",
        name: "StandardsCreatePage",
        component: StandardsCreatePage,
      },
      {
        path: "/standards/upload",
        name: "StandardsUploadPage",
        component: StandardsUploadPage,
      },
      {
        path: "/standards/:standardId",
        name: "StandardsEditPage",
        component: StandardsEditPage,
      },
      // Standard Sections
      {
        path: "/standards/:standardId/sections",
        name: "StandardSectionsPage",
        component: StandardSectionsPage,
      },
      {
        path: "/standards/:standardId/sections/create",
        name: "StandardSectionsCreatePage",
        component: StandardSectionsCreatePage,
      },
      {
        path: "/standards/:standardId/sections/upload",
        name: "StandardSectionsUploadPage",
        component: StandardSectionsUploadPage,
      },
      {
        path: "/standards/:standardId/sections/:sectionId",
        name: "StandardSectionsEditPage",
        component: StandardSectionsEditPage,
      },
      // Section Classes
      {
        path: "/standards/:standardId/sections/:sectionId/classes",
        name: "StandardSectionClassesPage",
        component: StandardSectionClassesPage,
      },
      {
        path: "/standards/:standardId/sections/:sectionId/classes/create",
        name: "StandardSectionClassesCreatePage",
        component: StandardSectionClassesCreatePage,
      },
      {
        path: "/standards/:standardId/sections/:sectionId/classes/upload",
        name: "StandardSectionClassesUploadPage",
        component: StandardSectionClassesUploadPage,
      },
      {
        path: "/standards/:standardId/sections/:sectionId/classes/:classcodeId/view-teachers",
        name: "StandardSectionClassesViewTeacherPage",
        component: StandardSectionClassesViewTeacherPage,
      },
      {
        path: "/standards/:standardId/sections/:sectionId/classes/:classcodeId/view-students",
        name: "StandardSectionClassesViewStudentPage",
        component: StandardSectionClassesViewStudentPage,
      },
      {
        path: "/standards/:standardId/sections/:sectionId/classes/:classcodeId",
        name: "StandardSectionClassesEditPage",
        component: StandardSectionClassesEditPage,
      },
      // Assignments
      {
        path: "/assignments",
        name: "AssignmentsV3Page",
        component: AssignmentsV3Page,
      },
      {
        path: "/assignments/new-index",
        name: "AssignmentsNewPage",
        component: AssignmentsNewPage,
      },
      {
        path: "/assignments/index-v2",
        name: "AssignmentsV2Page",
        component: AssignmentsV2Page,
      },
      {
        path: "/assignments/create",
        name: "AssignmentCreatePage",
        component: AssignmentCreatePage,
      },
      {
        path: "/assignments/create-v2",
        name: "AssignmentCreateV2Page",
        component: AssignmentCreateV2Page,
      },
      {
        path: "/assignments/:assignmentId",
        name: "AssignmentIdPage",
        component: AssignmentIdPage,
      },
      {
        path: "/assignments/:assignmentId/check-result/:userAssignmentId",
        name: "AssignmentResultPage",
        component: AssignmentResultPage,
      },
      {
        path: "/assignments/:assignmentId/take-assignment",
        name: "AssignmentTakePage",
        component: AssignmentTakePage,
      },

      {
        path: "/assignments/:assignmentId/submissions",
        name: "AssignmentSubmissionsPage",
        component: AssignmentSubmissionsPage,
      },
      {
        path: "/articles/:articleId/assignments",
        name: "ArticleAssignmentsPage",
        component: ArticleAssignmentsPage,
      },
      {
        path: "/articles/:articleId/personalized-assignments",
        name: "ArticlePersonalizedAssignmentCreatePage",
        component: ArticlePersonalizedAssignmentCreatePage,
      },
      {
        path: "/personalized-assignments/:assignmentId",
        name: "ArticlePersonalizedAssignmentEditPage",
        component: ArticlePersonalizedAssignmentEditPage,
      },
      {
        path: "/competitions",
        name: "AssignmentsPage",
        component: AssignmentsPage,
      },
      // Articles
      {
        path: "/articles",
        name: "Browse Page",
        component: BrowsePage,
      },
      {
        path: "/articles/search",
        name: "ArticlesSearchPage",
        component: ArticlesSearchPage,
      },
      {
        path: "/articles/new-index",
        name: "BrowsePage",
        component: BrowsePage,
      },
      {
        path: "/articles/:articleId",
        name: "ArticleIdPage",
        component: ArticleIdPage,
      },
      {
        path: "/articles-v2/:articleId",
        name: "ArticleIdV2Page",
        component: ArticleIdV2Page,
      },
      {
        path: "/articles/:articleId/:variantId",
        name: "ArticleVariantIdPage",
        component: ArticleVariantIdPage,
      },
      // Library
      {
        path: "/library",
        name: "LibraryPage",
        component: LibraryPage,
      },
      // Epaper Library
      {
        path: "/epaper-library",
        name: "EpaperLibraryPage",
        component: EpaperLibraryPage,
      },
      {
        path: "/collection-epapers/:epaperCollectionId",
        name: "EpaperLibraryIdPage",
        component: EpaperLibraryIdPage,
      },
      // Collection Based Assignment Listing
      {
        path: "/collections/:collectionId/assignments",
        name: "CollectionAssignmentsPage",
        component: CollectionAssignmentsPage,
      },
      // Collection (Call All Articles Via Collection)
      {
        path: "/library/collections/:collectionId",
        name: "CollectionIdPage",
        component: CollectionIdPage,
      },
      // Infakt Collections (Call All Articles Via Collection)
      {
        path: "/infakt-collections",
        name: "InfaktCollectionsPage",
        component: InfaktCollectionsPage,
      },
      // Notifications
      {
        path: "/notifications",
        name: "NotificationsPage",
        component: NotificationsPage,
      },
      {
        path: "/notifications/create",
        name: "NotificationsCreatePage",
        component: NotificationsCreatePage,
      },
      {
        path: "/notifications/:notificationId",
        name: "NotificationsEditPage",
        component: NotificationsEditPage,
      },
      // Manuals
      {
        path: "/manuals",
        name: "ManualsPage",
        component: ManualsPage,
      },
      {
        path: "/manuals/create",
        name: "ManualsCreatePage",
        component: ManualsCreatePage,
      },
      {
        path: "/manuals/:manualId",
        name: "ManualsEditPage",
        component: ManualsEditPage,
      },
      // Manual Details
      {
        path: "/manuals/:manualId/manual_details",
        name: "ManualDetailsPage",
        component: ManualDetailsPage,
      },
      {
        path: "/manuals/:manualId/manual_details/create",
        name: "ManualDetailsCreatePage",
        component: ManualDetailsCreatePage,
      },
      {
        path: "/manuals/:manualId/manual_details/:manualDetailId",
        name: "ManualDetailsEditPage",
        component: ManualDetailsEditPage,
      },
      // Blogs
      {
        path: "/blogs",
        name: "BlogsPage",
        component: BlogsPage,
      },
      {
        path: "/blogs/create",
        name: "BlogsCreatePage",
        component: BlogsCreatePage,
      },
      {
        path: "/blogs/:blogId",
        name: "BlogsEditPage",
        component: BlogsEditPage,
      },
      // Blog Details
      {
        path: "/blogs/:blogId/blog-details",
        name: "BlogDetailsPage",
        component: BlogDetailsPage,
      },
      {
        path: "/blogs/:blogId/blog-details/create",
        name: "BlogDetailsCreatePage",
        component: BlogDetailsCreatePage,
      },
      {
        path: "/blogs/:blogId/blog-details/:blogDetailId",
        name: "BlogDetailsEditPage",
        component: BlogDetailsEditPage,
      },
      // Courses
      {
        path: "/courses",
        name: "CoursesPage",
        component: CoursesPage,
      },
      {
        path: "/courses/create",
        name: "CoursesCreatePage",
        component: CoursesCreatePage,
      },
      {
        path: "/courses/:courseId",
        name: "CoursesEditPage",
        component: CoursesEditPage,
      },
      // Lp
      {
        path: "/courses/:courseId/lps",
        name: "LessonPlansPage",
        component: LessonPlansPage,
      },
      {
        path: "/courses/:courseId/lps/create",
        name: "LessonPlansCreatePage",
        component: LessonPlansCreatePage,
      },
      {
        path: "/courses/:courseId/lps/:lpId",
        name: "LessonPlansEditPage",
        component: LessonPlansEditPage,
      },
      // About US
      {
        path: "/about-us/:aboutusId",
        name: "AboutUs",
        component: AboutUs,
      },
      // Reports
      {
        path: "/reports/task-wise-report",
        name: "TaskWiseReportsPage",
        component: TaskWiseReportsPage,
      },
      {
        path: "/reports/student-wise-report",
        name: "StudentWiseReportsPage",
        component: StudentWiseReportsPage,
      },
      {
        path: "/reports/class-wise-performance-report",
        name: "ClassWisePerformanceReportsPage",
        component: ClassWisePerformanceReportsPage,
      },
      {
        path: "/reports/teacher-performance-report",
        name: "TeacherPerformanceReportsPage",
        component: TeacherPerformanceReportsPage,
      },
      {
        path: "/reports/subject-overview-report",
        name: "SubjectOverviewReportsPage",
        component: SubjectOverviewReportsPage,
      },
      {
        path: "/reports/assignment-wise-performance-report",
        name: "AssignmentWiseReportsPage",
        component: AssignmentWiseReportsPage,
      },
      {
        path: "/reports/school-wise-performance-report",
        name: "SchoolWiseReportsPage",
        component: SchoolWiseReportsPage,
      },
      {
        path: "/reports/subject-wise-performance-report",
        name: "SchoolSubjectWiseReportsPage",
        component: SchoolSubjectWiseReportsPage,
      },
      {
        path: "/reports/section-wise-report",
        name: "SectionWiseReport",
        component: SectionWiseReport,
      },
      {
        path: "/reports/learning-outcome-report",
        name: "LearningOutcomeReportsPage",
        component: LearningOutcomeReportsPage,
      },
      {
        path: "/reports/publication-report",
        name: "PublicationReportsPage",
        component: PublicationReportsPage,
      },
      {
        path: "/reports/publication-report-v2",
        name: "PublicationReportsV2Page",
        component: PublicationReportsV2Page,
      },
      {
        path: "/academic-team",
        name: "AcademicTeamContent",
        component: AcademicTeamContent,
      },
      {
        path: "/academic-team/contents/:ATcontantId",
        name: "AcademicTeamIdContent",
        component: AcademicTeamIdContent,
      },
      {
        path: "/academic-team/approvals",
        name: "AcademicTeamApprovals",
        component: AcademicTeamApprovals,
      },
      {
        path: "/academic-team/users",
        name: "AcademicTeamUsers",
        component: AcademicTeamUsers,
      },
      {
        path: "/academic-team/users/:atuserId",
        name: "AcademicTeamIdUsers",
        component: AcademicTeamIdUsers,
      },
      {
        path: "/get-started",
        name: "GetStarted",
        component: GetStarted,
      },
      {
        path: "/quiz-assignments",
        name: "QuizAssignmentsPage",
        component: QuizAssignmentsPage,
      },
    ],
    beforeEnter: (to, from, next) => {
      // Code to track time spent (similar to what was shown in the previous response)
      const startTime = new Date();

      // Pass the start time to the component as a prop
      to.params.startTime = startTime;

      next();
    },
  },
  // End RoleLayout
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (store.getters["auth/authenticated"] && from.name) {
    to.params.startTime = new Date();
  }

  if (store.getters["auth/authenticated"]) {
    // Open the dashboard wrt to the role if another role dashboard is open.
    let currentUserRole = store.getters["auth/user"].roles[0];
    if (
      (to.path === "/dashboards/superadmin" &&
        currentUserRole.name != "SUPER ADMIN") ||
      (to.path === "/dashboards/admin" &&
        currentUserRole.name != "ADMIN" &&
        currentUserRole.name != "SUPER ADMIN") ||
      (to.path === "/dashboards/teacher" &&
        currentUserRole.name != "TEACHER") ||
      (to.path === "/dashboards/student" && currentUserRole.name != "STUDENT")
    ) {
      if (currentUserRole.name == "SUPER ADMIN") {
        let company_id = store.getters["auth/company"].id;
        if (company_id) {
          return next({
            path: "/dashboards/admin",
          });
        } else {
          return next({
            path: "/dashboards/superadmin",
          });
        }
      } else if (currentUserRole.name == "ADMIN") {
        console.log("S3");
        return next({
          path: "/dashboards/admin",
        });
      } else if (currentUserRole.name == "TEACHER") {
        console.log("S4");
        return next({
          path: "/dashboards/teacher",
        });
      } else if (
        currentUserRole.name == "INFAKT TEACHER" ||
        currentUserRole.name == "ACADEMIC TEAM"
      ) {
        console.log("S5");
        return next({
          path: "/contents",
        });
      } else if (currentUserRole.name == "STUDENT") {
        console.log("S8");
        return next({
          path: "/dashboards/student",
        });
      }
    }
  }

  if (
    to.path === "/auth/login" ||
    to.path === "/superadmin/login" ||
    to.path === "/admin/login" ||
    to.path === "/teacher/login" ||
    to.path === "/infakt_teacher/login" ||
    to.path === "/student/login"
  ) {
    if (store.getters["auth/authenticated"]) {
      // Redirect to reset password page if not reseted the password
      let isPasswordReseted = store.getters["auth/user"].is_password_reseted;
      if (isPasswordReseted == 0) {
        return next({
          path: "/reset-password",
        });
      }
      // Open the dashboard wrt to the role
      let currentUserRole = store.getters["auth/user"].roles[0];
      if (currentUserRole.name == "SUPER ADMIN") {
        let company_id = store.getters["auth/company"].id;
        if (company_id) {
          return next({
            path: "/dashboards/admin",
          });
        } else {
          return next({
            path: "/dashboards/superadmin",
          });
        }
      } else if (currentUserRole.name == "ADMIN") {
        console.log("S3");
        return next({
          path: "/dashboards/admin",
        });
      } else if (currentUserRole.name == "TEACHER") {
        console.log("S4");
        return next({
          path: "/dashboards/teacher",
        });
      } else if (
        currentUserRole.name == "INFAKT TEACHER" ||
        currentUserRole.name == "ACADEMIC TEAM"
      ) {
        console.log("S5");
        return next({
          path: "/contents",
        });
      } else if (currentUserRole.name == "TICKET ADMINISTRATOR") {
        console.log("S6");
        return next({
          path: "/dashboards/ticket",
        });
      } else if (currentUserRole.name == "IT SUPPORT") {
        console.log("S7");
        return next({
          path: "/dashboards/ticket",
        });
      } else if (currentUserRole.name == "STUDENT") {
        console.log("S8");
        return next({
          path: "/dashboards/student",
        });
      } else {
        console.log("S9");
        return next({
          path: "/",
        });
      }
    }
    console.log("S10");
    next();
  } else if (
    to.path === "/" ||
    to.path === "/careers" ||
    to.path === "/about-us" ||
    to.path === "/features" ||
    to.path == "/contact-us" ||
    to.path == "/benefits" ||
    to.path == "/privacy-policy" ||
    to.path == "/terms-of-us"
  ) {
    console.log("S11");
    return next();
  } else if (!store.getters["auth/authenticated"]) {
    console.log("S12");
    return next({
      path: "/auth/login",
    });
  }
  // if (to.path != "/global-settings") {
  //   // Open the dashboard wrt to the role
  //   let currentUserRole = store.getters["auth/user"].roles[0];
  //   if (currentUserRole.name == "SUPER ADMIN") {
  //     if (Object.keys(store.getters["auth/company"]).length == 0) {
  //       return next({
  //         path: "/global-settings",
  //       });
  //     }
  //   }
  // }
  next();
});
router.afterEach(async (to, from) => {
  if (store.getters["auth/authenticated"] && from.name) {
    let endTime = new Date();
    let startTime = from.params.startTime;
    let timeSpentInSeconds = "0";
    if (startTime) {
      timeSpentInSeconds = (endTime - startTime) / 1000;
    }
    if (timeSpentInSeconds > 1200) {
      timeSpentInSeconds = 1200;
    }
    console.log(
      `Time spent on "${to.name}" route: ${timeSpentInSeconds} seconds`
    );
    let form = {
      from_path: from.path,
      from_name: from.name,
      user_id: store.getters["auth/user"].id,
      total_time_spent: timeSpentInSeconds,
    };
    await axios.post(`/user_timestamps`, form);
  }
  // // Remove initial loading
  // const appLoading = document.getElementById("loading-bg");
  // if (appLoading) {
  //   appLoading.style.display = "none";
  // }
});

export default router;
