<template>
  <loader v-if="is_Loading"></loader>
  <section v-else>
    <v-row>
      <!-- Total Students -->
      <v-col sm="4">
        <v-card color="brown accent-1" dark style="overflow: auto">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5">{{ data.students }}</v-card-title>
              <v-card-subtitle style="min-width: 199px"
                >Total TSIs</v-card-subtitle
              >
              <v-card-actions>
                <v-btn
                  class="ml-2 mt-5"
                  outlined
                  rounded
                  small
                  :to="`/students?teacher_id=${teacher_id}`"
                >
                  View List
                </v-btn>
              </v-card-actions>
            </div>
            <v-avatar class="ma-3" size="125" tile>
              <v-icon class="imgStyle" large dark>mdi-account-school</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <!-- End Total Students -->
      <!-- Total Assignments Posted -->
      <v-col sm="4">
        <v-card color="brown accent-1" dark style="overflow: auto">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5">{{
                data.assignments
              }}</v-card-title>
              <v-card-subtitle style="min-width: 199px; overflow: auto"
                >Total Assignments Posted</v-card-subtitle
              >
              <v-card-actions>
                <v-btn
                  class="ml-2 mt-5"
                  outlined
                  rounded
                  small
                  :to="`/assignments?tab=tab-3&teacher_id=${teacher_id}`"
                >
                  View List
                </v-btn>
              </v-card-actions>
            </div>
            <v-avatar class="ma-3" size="125" tile>
              <v-icon class="imgStyle" large dark>mdi-human-male-board</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <!-- End Total Teachers -->
      <!-- Total Classcodes -->
      <v-col sm="4">
        <v-card color="brown accent-1" dark style="overflow: auto">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5"
                >{{ data.classcodes }}
              </v-card-title>
              <v-card-subtitle style="min-width: 199px"
                >Total Classcodes</v-card-subtitle
              >
              <v-card-actions>
                <v-btn
                  class="ml-2 mt-5"
                  outlined
                  rounded
                  small
                  :to="`/teacher_classes/${teacher_id}`"
                >
                  View List
                </v-btn>
              </v-card-actions>
            </div>
            <v-avatar class="ma-3" size="125" tile>
              <v-icon class="imgStyle" large dark>mdi-google-classroom</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <!-- End Total Classcodes -->
    </v-row>
    <br />
    <v-card>
      <v-card-title style="min-width: 150px"> Classcodes </v-card-title>
      <v-card-text>
        <v-tabs
          v-if="data.assignmentOverview"
          fixed-tabs
          background-color="primary"
          dark
          v-model="classcodeTab"
        >
          <v-tab
            v-for="(classcode, cc) in data.assignmentOverview
              .classcodeWiseStudents"
            :key="`classcode${cc}`"
            :href="`#tab-${cc}`"
          >
            {{ trim_classcode(classcode.name) }}
            [{{ classcode.students.length }}]
          </v-tab>
          <v-tabs-items v-model="classcodeTab">
            <v-tab-item
              v-for="(classcode, cc1) in data.assignmentOverview
                .classcodeWiseStudents"
              :key="`classcode1${cc1}`"
              :value="`tab-${cc1}`"
            >
              <v-simple-table fixed-header height="285px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center sr_no_class">Sr No</th>
                      <th class="text-center">Student Name</th>
                      <th class="text-center" style="min-width: 75px">Score</th>
                      <th class="text-center" style="min-width: 75px">
                        Class Average
                      </th>
                      <th class="text-center" style="min-width: 75px">
                        Submission Rate
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(student, tS) in classcode.students"
                      :key="`student${tS}`"
                    >
                      <td class="text-center">{{ tS + 1 }}</td>
                      <td class="text-center">
                        <ClasscodeStudent
                          :classs="student"
                          :classcode_id="classcode.id"
                        ></ClasscodeStudent>
                      </td>
                      <td class="text-center" style="min-width: 100px">
                        {{ student.myAveragePercent | twoDec }} %
                      </td>
                      <td class="text-center" style="min-width: 100px">
                        {{ classcode.classcodeAveragePercent | twoDec }} %
                      </td>
                      <td class="text-center" style="min-width: 130px">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"
                              >{{
                                parseFloat(
                                  (student.total_attempted / student.count) *
                                    100
                                ).toFixed(2)
                              }}
                              %</span
                            >
                          </template>
                          <span
                            >[ OverAll Total Attempted / OverAll Total
                            Assignment ]
                          </span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card-text>
    </v-card>
  </section>
</template>
<script>
import axios from "axios";
import ClasscodeStudent from "../student_school_data/classcodeStudent.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "SATeacherSchool",
  data() {
    return {
      classcodeTab: "",
      teacher_id: "",
      is_Loading: false,
      assignmentOverview: {},
      data: {},
    };
  },
  props: ["schoolId", "teacherId"],
  watch: {
    teacherId: "getData",
  },
  components: {
    ClasscodeStudent,
    Loader,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      if (this.teacherId) {
        this.teacher_id = this.teacherId;
      } else {
        this.teacher_id = this.user.id;
      }
      this.is_Loading = true;
      let response;
      let requirements = ["classcodeWiseStudents"];
      let query =
        "companyId=" +
        this.schoolId +
        "&teacherId=" +
        this.teacher_id +
        "&requirements=" +
        requirements;
      response = await axios.get(`/teacher_wise_overview?${query}`);
      response = response.data;
      this.data = response;
      console.log(this.data);
      this.is_Loading = false;
    },
    async getStudentWiseData(student_id, classcode_id) {
      this.is_Loading = true;
      let response;
      let query = "student_id=" + student_id + "&classcode_id=" + classcode_id;
      response = await axios.get(`/test_student_wise?${query}`);
      response = response.data;
      console.log(response);
      this.is_Loading = false;
    },
    trim_classcode(classcode) {
      let originalClasscode = classcode;
      let classcode_array = classcode.split("/");
      let standard_section = classcode_array[1];
      let subject_section = classcode_array[2];
      let trimmed_classcode = "";
      if (this.roleName == "STUDENT") {
        trimmed_classcode = subject_section;
      } else if (this.roleName == "TEACHER") {
        trimmed_classcode = standard_section + "/" + subject_section;
      } else {
        trimmed_classcode = originalClasscode;
      }
      return trimmed_classcode;
    },
  },
};
</script>
