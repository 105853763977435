<template>
  <v-main v-if="!isauthenticated">Not Authenticated</v-main>
  <!-- <section style="background: white"> -->
  <section style="background: white" v-else>
    <v-main>
      <v-container
        :class="{
          'mt-8': $vuetify.breakpoint.mdAndUp,
          'mt-3': $vuetify.breakpoint.smAndDown,
        }"
      >
        <!-- <v-container class="mt-8"> -->
        <v-row
          justify="center"
          :class="{
            'my-16': $vuetify.breakpoint.mdAndUp,
            'mt-2 mb-4': $vuetify.breakpoint.smAndDown,
          }"
        >
          <!-- <v-row justify="center" class="my-16"> -->
          <span
            :class="{
              'text-h2 font-weight-bold myFont': $vuetify.breakpoint.mdAndUp,
              'display-2 font-weight-bold myFont':
                $vuetify.breakpoint.smAndDown,
            }"
            v-text="'About Us'"
          ></span>
          <!-- <span
            class="text-h2 font-weight-bold myFont"
            v-text="'About Us'"
          ></span> -->
        </v-row>
      </v-container>

      <section class="mb-16" v-if="$vuetify.breakpoint.mdAndDown">
        <v-row>
          <v-col md="6">
            <v-row>
              <!-- <v-col md="3"></v-col> -->
              <v-col
                md="1"
                align="center"
                :class="{
                  'ml-5 mb-4': $vuetify.breakpoint.smAndDown,
                }"
              >
                <v-img
                  height="70  `"
                  width="90"
                  src="../assets/images/aboutus/Vision.png"
                >
                </v-img>
              </v-col>

              <v-col
                md="6"
                :class="{
                  'mt-7  font-weight-bold myFont':
                    $vuetify.breakpoint.mdAndDown,
                  'mt-7  font-weight-bold myFont':
                    $vuetify.breakpoint.smAndDown,
                }"
                style="margin-left: -80px; font-size: 25px"
              >
                <h1>Our Vision</h1>
              </v-col>
            </v-row>
            <hr style="margin-left: 70px; width: 65%" />
            <v-row class="mt-2" justify="center">
              <!-- <v-col md="3"></v-col> -->
              <v-col md="9" class="mx-6">
                <p class="myFont body-1">
                  Is to bring about a systemic shift in rote education by
                  focusing on learning to learn.
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-row>
              <!-- <v-col md="3"></v-col> -->
              <v-col
                md="1"
                align="center"
                :class="{
                  'ml-2 mb-4': $vuetify.breakpoint.smAndDown,
                }"
              >
                <v-img
                  height="70  `"
                  width="90"
                  src="../assets/images/aboutus/mission.png"
                >
                </v-img>
              </v-col>

              <v-col
                md="6"
                :class="{
                  'mt-7  font-weight-bold myFont':
                    $vuetify.breakpoint.mdAndDown,
                  'mt-7  font-weight-bold myFont':
                    $vuetify.breakpoint.smAndDown,
                }"
                style="margin-left: -90px; font-size: 25px"
              >
                <h1>Our Mission</h1>
              </v-col>
            </v-row>
            <hr style="margin-left: 80px; width: 65%" />
            <v-row class="mt-2">
              <!-- <v-col md="3"></v-col> -->
              <v-col md="9" class="mx-6">
                <p class="myFont body-1">
                  We want to boost cognitive development by connecting school
                  learning to life and the real world
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row> -->
        <!-- <v-col md="6">
              <v-row class="mb-2">
                <v-col md="1"></v-col>
                <v-col md="2">
                  <v-img
                    height="90"
                    width="110"
                    src="../assets/images/aboutus/mission.png"
                  >
                  </v-img>
                </v-col>

                <v-col
                  md="9"
                  align="left"
                  class="mt-9 text-h4 font-weight-bold myFont"
                >
                  <h1>Our Mission</h1>
                </v-col>
              </v-row>
              <hr style="margin-left: 40px; width: 60%" />
              <v-row class="mt-2">
                <v-col md="1"></v-col>
                <v-col md="11">
                  <p class="myFont text-h5 mb-2">
                    We want to boost cognitive development by
                  </p>
                  <p class="myFont text-h5 mb-2">
                    connecting school learning to life and the
                  </p>
                  <p class="myFont text-h5 mb-2">real world</p>
                </v-col>
              </v-row>
            </v-col> -->
        <!-- </v-row> -->
      </section>
      <section class="mb-16" v-else>
        <v-row>
          <v-col md="6">
            <v-row class="mb-2">
              <v-col md="3"></v-col>
              <v-col md="2">
                <v-img
                  height="95  `"
                  width="110"
                  src="../assets/images/aboutus/Vision.png"
                >
                </v-img>
              </v-col>

              <v-col
                md="6"
                align="left"
                class="mt-9 text-h4 font-weight-bold myFont"
              >
                <h1>Our Vision</h1>
              </v-col>
            </v-row>
            <hr style="margin-left: 200px; width: 60%" />
            <v-row class="mt-2">
              <v-col md="3"></v-col>
              <v-col md="9">
                <p class="myFont text-h5 mb-2">
                  <!-- Bring about a systemic shift in education by -->
                  Is to bring about a systemic shift in rote education
                </p>
                <p class="myFont text-h5 mb-2">
                  by focusing on learning to learn.
                </p>
                <!-- <p class="myFont text-h5 mb-2">teaching to test</p> -->
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6">
            <v-row class="mb-2">
              <v-col md="1"></v-col>
              <v-col md="2">
                <v-img
                  height="90"
                  width="110"
                  src="../assets/images/aboutus/mission.png"
                >
                </v-img>
              </v-col>

              <v-col
                md="9"
                align="left"
                class="mt-9 text-h4 font-weight-bold myFont"
              >
                <h1>Our Mission</h1>
              </v-col>
            </v-row>
            <hr style="margin-left: 40px; width: 60%" />
            <v-row class="mt-2">
              <v-col md="1"></v-col>
              <v-col md="11">
                <p class="myFont text-h5 mb-2">
                  We want to boost cognitive development by
                </p>
                <p class="myFont text-h5 mb-2">
                  connecting school learning to life and the
                </p>
                <p class="myFont text-h5 mb-2">real world</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>

      <section :style="{ background: $vuetify.theme.themes.light.background }">
        <v-container>
          <v-col v-if="boards">
            <center>
              <span v-for="board in boards" :key="board.name">
                <v-btn
                  color="primary"
                  :class="{
                    'mx-12': $vuetify.breakpoint.mdAndUp,
                    'mx-2 mb-2': $vuetify.breakpoint.smAndDown,
                  }"
                  rounded
                  large
                  dark
                  style="cursor: default"
                  >{{ board.name }}</v-btn
                >
              </span>
            </center>
          </v-col>
          <v-col>
            <center>
              <v-btn
                v-if="$vuetify.breakpoint.mdAndDown"
                color="#FFD954"
                :class="{
                  'mx-2 mb-2': $vuetify.breakpoint.smAndDown,
                }"
                rounded
                large
                dark
                style="cursor: default"
              >
                <span style="color: black"
                  >Grade
                  <!-- {{
                    grades[0].name + " - " + grades[grades.length - 1].name
                  }} -->
                  6 - 12
                </span>
              </v-btn>
              <v-btn
                v-else
                color="#FFD954"
                class="mx-11"
                rounded
                x-large
                dark
                style="cursor: default"
              >
                <span style="color: black"
                  >Grade
                  <!-- {{
                    grades[0].name + " - " + grades[grades.length - 1].name
                  }} -->
                  6 - 12
                </span>
              </v-btn>
            </center>
          </v-col>
        </v-container>
      </section>

      <section class="grey lighten-4">
        <v-row
          :class="{
            'py-16': $vuetify.breakpoint.mdAndUp,
            'py-7': $vuetify.breakpoint.smAndDown,
          }"
        >
          <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>
          <v-col
            md="5"
            :class="{
              'pl-16 my-auto': $vuetify.breakpoint.mdAndUp,
              'pl-9 my-auto': $vuetify.breakpoint.smAndDown,
            }"
          >
            <!-- <v-col md="5" class="pl-16 my-auto"> -->
            <h1
              :class="{
                'text-h3 font-weight-bold myFont mb-2':
                  $vuetify.breakpoint.mdAndUp,
                'text-h4 font-weight-bold myFont mb-2':
                  $vuetify.breakpoint.smAndDown,
              }"
            >
              <!-- <h1 class="text-h3 font-weight-bold myFont mb-2"> -->
              Real-World Content Made Classroom Ready
            </h1>
            <!-- <h1
              v-if="$vuetify.breakpoint.mdAndUp"
              class="text-h3 font-weight-bold myFont mb-5"
            >
              Classroom Ready
            </h1> -->
            <p
              v-if="$vuetify.breakpoint.mdAndDown"
              class="mt-2 myFont"
              style="direction: ltl; font-size: 20px"
            >
              We adapt news content from trusted publishers and provide it to
              students for learning relevant and relatable material. We take
              real-world content and make it classroom-ready for students in
              school. Each text is published at multiple reading levels, so that
              content is accessible to every learner.
            </p>
            <p
              v-else
              class="mt-2 myFont"
              style="direction: ltl; font-size: 25px"
            >
              We adapt news content from trusted publishers and provide it to
              students for learning relevant and relatable material. We take
              real-world content and make it classroom-ready for students in
              school. Each text is published at multiple reading levels, so that
              content is accessible to every learner.
            </p>
          </v-col>
          <v-col md="6" align="center" v-if="$vuetify.breakpoint.mdAndDown">
            <v-img
              height="300"
              width="350"
              src="../assets/images/aboutus/about-2.png"
            ></v-img>
          </v-col>
          <v-col md="6" align="center" v-else>
            <v-img
              height="350"
              width="393"
              src="../assets/images/aboutus/about-2.png"
            ></v-img>
          </v-col>
        </v-row>
      </section>

      <section
        :class="{
          'pl-4': $vuetify.breakpoint.smAndDown,
        }"
        v-if="$vuetify.breakpoint.mdAndDown"
      >
        <v-row class="py-16">
          <v-col md="6" class="my-auto">
            <h1
              :class="{
                'text-h4 font-weight-bold myFont mb-2':
                  $vuetify.breakpoint.smAndDown,
              }"
            >
              <!-- <h1 class="text-h3 font-weight-bold myFont mb-2"> -->
              Personalized Content for Holistic Learning
            </h1>
            <!-- <h1 class="text-h3 font-weight-bold myFont mb-8"></h1> -->
            <p class="mt-2 myFont" style="font-size: 20px">
              Content is personalized to student interests, aligned for
              instruction, and integrated with activities and reporting that
              creates a holistic environment for learning. Students will find
              articles, videos and infographics that will compliment school
              learning as content is aligned to their curriculum and learning
              outcomes. Our platform will drive student reading engagment, make
              for more informed learners and build the skills necessary to
              succeed in this rapidly changing world.
            </p>
          </v-col>
          <v-col md="6" align="center">
            <v-img
              height="350"
              width="400"
              src="../assets/images/aboutus/about-1.png"
            >
            </v-img>
          </v-col>
        </v-row>
      </section>
      <section class="pl-12" v-else>
        <v-row class="py-16">
          <v-col md="6" align="center">
            <v-img
              height="500"
              width="535"
              src="../assets/images/aboutus/about-1.png"
            >
            </v-img>
          </v-col>
          <v-col md="6" class="my-auto">
            <h1 class="text-h3 font-weight-bold myFont mb-2">
              Personalized Content for Holistic
            </h1>
            <h1 class="text-h3 font-weight-bold myFont mb-8">Learning</h1>
            <p class="mt-2 myFont" style="font-size: 25px">
              Content is personalized to student interests, aligned for
              instruction, and integrated with activities and reporting that
              creates a holistic environment for learning. Students shall find
              articles, videos and infographics that shall compliment school
              learning as <b>content is aligned to their curriculum and </b>
              <b>learning outcomes</b>. Our platform will drive student reading
              engagment, make for more informed learners and build the
              skills necessary to succeed in this rapidly changing world.
            </p>
          </v-col>
        </v-row>
      </section>

      <section class="grey lighten-4">
        <v-row
          :class="{
            'py-16': $vuetify.breakpoint.mdAndUp,
            'py-7': $vuetify.breakpoint.smAndDown,
          }"
        >
          <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>
          <v-col
            md="6"
            :class="{
              'pl-16 my-auto': $vuetify.breakpoint.mdAndUp,
              'pl-10 my-auto': $vuetify.breakpoint.smAndDown,
            }"
          >
            <!-- <v-col md="6" class="pl-16 my-auto"> -->
            <h1
              :class="{
                'text-h3 font-weight-bold myFont mb-2':
                  $vuetify.breakpoint.mdAndUp,
                'text-h4 font-weight-bold myFont mb-2':
                  $vuetify.breakpoint.smAndDown,
              }"
            >
              <!-- <h1 class="text-h3 font-weight-bold myFont mb-2"> -->
              One-Stop Solution for Up-to-Date Educational Content
            </h1>
            <!-- <h1
              v-if="$vuetify.breakpoint.mdAndUp"
              class="text-h3 font-weight-bold myFont mb-5"
            >
              for Up-to-Date Educational Content
            </h1> -->
            <p
              v-if="$vuetify.breakpoint.mdAndDown"
              class="mt-2 myFont"
              style="direction: ltl; font-size: 20px"
            >
              Textbooks are aligned to academic standards but do not address the
              different learning needs within a single classroom and fail to
              promote inclusiveness or engage students. The Internet, on the
              other hand, provides a wealth of engaging content but most of it
              is unvetted, non-conformist to learning outcomes, and rarely at a
              reading level that is conducive for each learner. Infakt is a one
              stop solution to address this problem.
            </p>
            <p
              v-else
              class="mt-2 myFont"
              style="direction: ltl; font-size: 25px"
            >
              Textbooks are aligned to academic standards but do not address the
              different learning needs within a single classroom and fail to
              promote inclusiveness or engage students. The Internet, on the
              other hand, provides a wealth of engaging content but most of it
              is unvetted, non-conformist to learning outcomes, and rarely at a
              reading level that is conducive for each learner. Infakt is a one
              stop solution to address this problem.
            </p>
          </v-col>
          <v-col
            md="5"
            align="center"
            class="px-8"
            v-if="$vuetify.breakpoint.mdAndDown"
          >
            <v-img
              height="350"
              width="350"
              src="../assets/images/aboutus/about-3.png"
            ></v-img>
          </v-col>
          <v-col md="5" align="center" class="pr-12" v-else>
            <v-img
              height="400"
              width="393"
              src="../assets/images/aboutus/about-3.png"
            ></v-img>
          </v-col>
        </v-row>
        <br />
      </section>
    </v-main>
    <Foot></Foot>
  </section>
</template>

<script>
import Foot from "../views/home/foot.vue";
import axios from "axios";

export default {
  name: "Aboutus",
  components: {
    Foot,
  },

  data() {
    return {
      boards: [],
      endGrand: "",
      isauthenticated: true,
    };
  },
  mounted() {
    this.getData();

    // let password = prompt("Enter passcode");
    // if (password == "Infakt2023") {
    //   this.isauthenticated = true;
    // } else {
    //   alert("Wrong Password");
    // }
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let boards = await axios.get(`/boards`);
      this.boards = boards.data.data;
      this.isLoading = false;
    },
  },
};
// import axios from "axios";
// export default {
//   name: "AboutUs",
//   data() {
//     return {
//       form: {
//         tagline: "",
//         info: "",
//         info_1: "",
//         description: "",
//       },
//       isLoading: false,
//     };
//   },
//   mounted() {
//     this.getData();
//   },
//   methods: {
//     async getData() {
//       this.isLoading = true;
//       let form = await axios.get(`/about_us/1}`);
//       this.form = form.data.data;
//       this.isLoading = false;
//     },
//   },
// };

const { default: foot } = require("./home/foot.vue");
</script>
<style scoped>
.myFont {
  font-family: "SF PRO" !important;
}
.min-button::before {
  display: none;
}
.v-btn--router,
.v-btn__content {
  text-transform: unset !important;
}
</style>
