<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col sm="2" style="min-width: 125px">
          <span class="text-h5 font-weight-bold" v-text="'Tickets'"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3">
          <v-card
            elevation="5"
            color="primary accent-1"
            dark
            @click="(form.status = 0), getData()"
            style="min-width: 100px"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  style="min-width: 150px"
                  class="text-h6 font-weight-bold pr-0"
                  v-text="`PENDING`"
                ></v-card-title>

                <v-card-subtitle
                  class="text-h3 font-weight-bold"
                  v-text="pending_tickets.length"
                ></v-card-subtitle>
              </div>
              <v-avatar class="ma-3" size="125" tile>
                <v-icon class="imgStyle" large dark
                  >mdi-receipt-text-clock</v-icon
                >
              </v-avatar>
            </div>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card
            elevation="5"
            color="primary accent-1"
            dark
            @click="(form.status = 1), getData()"
            style="min-width: 100px"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  style="min-width: 150px"
                  class="text-h6 font-weight-bold pr-0"
                  v-text="`COMPLETED`"
                ></v-card-title>

                <v-card-subtitle
                  class="text-h3 font-weight-bold"
                  v-text="completed_tickets.length"
                ></v-card-subtitle>
              </div>
              <v-avatar class="ma-3" size="125" tile>
                <v-icon class="imgStyle" large dark
                  >mdi-receipt-text-clock</v-icon
                >
              </v-avatar>
            </div>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card
            elevation="5"
            color="primary accent-1"
            dark
            @click="(form.status = null), getData()"
            style="min-width: 100px"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  style="min-width: 150px"
                  class="text-h6 font-weight-bold pr-0"
                  v-text="`TOTAL`"
                ></v-card-title>

                <v-card-subtitle
                  class="text-h3 font-weight-bold"
                  v-text="tickets.length"
                ></v-card-subtitle>
              </div>
              <v-avatar class="ma-3" size="125" tile>
                <v-icon class="imgStyle" large dark
                  >mdi-receipt-text-clock</v-icon
                >
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3">
          <v-text-field
            v-model="form.search_keyword"
            label="Search ... "
            prepend-inner-icon="mdi-magnify"
            @keydown.enter="getData()"
            @change="page = 1"
            clearable
            @click:clear="(form.search_keyword = null), getData()"
          ></v-text-field>
        </v-col>
        <v-col sm="3">
          <v-menu
            v-model="date_menu"
            :close-on-ticket-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.date_filter"
                label="Date Search"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @input="getData()"
                @click:clear="form.date_filter = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.date_filter"
              @input="getData()"
              @change="date_menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="Category"
            v-model="form.category"
            :items="categoryItems"
            label="Select Category"
            @change="getData()"
            clearable
            @click:clear="(form.category = null), getData()"
          ></v-autocomplete>
        </v-col>
        <v-col md="3">
          <v-pagination v-model="page" :length="paginationLength">
          </v-pagination>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" style="min-width: 100px">Sr No</th>
                    <th class="text-center" style="min-width: 100px">ID</th>
                    <th class="text-center" style="min-width: 150px">Title</th>
                    <th class="text-center" style="min-width: 100px">Date</th>
                    <th class="text-center" style="min-width: 100px">Role</th>
                    <th class="text-center" style="min-width: 150px">
                      Created By
                    </th>
                    <th class="text-center" style="min-width: 200px">Email</th>
                    <th class="text-center" style="min-width: 100px">School</th>
                    <th
                      class="text-center"
                      style="min-width: 200px"
                      v-if="roleName == 'TICKET ADMINISTRATOR'"
                    >
                      Type
                    </th>
                    <th class="text-center" style="min-width: 200px">
                      Category
                    </th>
                    <th class="text-center" style="min-width: 200px">Status</th>
                    <th class="text-center" style="min-width: 100px">Logs</th>
                    <th class="text-right" style="min-width: 65px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ticket, i) in tickets" :key="`ticket_${i}`">
                    <td>
                      <v-tooltip bottom v-if="ticket.status == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Ticket is completed</span>
                      </v-tooltip>
                      &nbsp;{{ i + 1 }}
                    </td>
                    <td class="text-center">
                      {{ ticket.id }}
                    </td>
                    <td class="text-center">
                      {{ ticket.title }}
                    </td>
                    <td class="text-center">
                      {{ ticket.created_at | in_days }}
                      {{ ticket.created_at | HM }}
                    </td>
                    <td class="text-center">
                      {{ ticket.user.roles[0].name }}
                    </td>
                    <td class="text-center">
                      {{ ticket.user.first_name + " " + ticket.user.last_name }}
                    </td>
                    <td class="text-center">
                      {{ ticket.user.email }}
                    </td>
                    <td class="text-center">
                      {{ ticket.user.companies[0].name }}
                    </td>
                    <td
                      class="text-center"
                      v-if="roleName == 'TICKET ADMINISTRATOR'"
                    >
                      <v-autocomplete
                        ref="subject"
                        v-model="ticket.type"
                        :items="typeItems"
                        label="Select Type"
                        prepend-icon="mdi-domain"
                        @change="update(ticket)"
                      ></v-autocomplete>
                    </td>
                    <td class="text-center">
                      <v-autocomplete
                        ref="subject"
                        v-model="ticket.category"
                        :items="categoryItems"
                        label="Select Category"
                        prepend-icon="mdi-domain"
                        @change="update(ticket)"
                      ></v-autocomplete>
                    </td>
                    <td class="text-center">
                      <v-autocomplete
                        ref="status"
                        v-model="ticket.status"
                        :items="statusItems"
                        label="Select Status"
                        prepend-icon="mdi-domain"
                        @change="update(ticket)"
                      ></v-autocomplete>
                    </td>
                    <td class="text-center">
                      {{ ticket.ticket_details.length }}
                    </td>
                    <td class="text-right">
                      <v-tooltip bottom v-if="ticket.is_new == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-badge overlap color="pink" icon="mdi-bell">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="mx-2"
                              fab
                              x-small
                              color="primary"
                              @click="show_details(ticket)"
                            >
                              <v-icon dark> mdi-eye </v-icon>
                            </v-btn>
                          </v-badge>
                        </template>
                        <span>New Message</span>
                      </v-tooltip>
                      <v-btn
                        v-else
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        @click="show_details(ticket)"
                      >
                        <v-icon dark> mdi-eye </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Existing Ticket Listing Dialog Box -->
    <v-dialog
      v-model="show_ticket_details"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card style="background: rgb(252, 239, 239)">
        <v-toolbar color="white">
          <v-btn
            icon
            @click="(selectedTicket = {}), (show_ticket_details = false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <h2>{{ selectedTicket.title }}</h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid class="my-6">
          <template>
            <v-row justify="center">
              <v-container fluid class="pa-5">
                <v-card class="pa-5" elevation="6">
                  <div
                    style="
                      max-height: 550px;
                      overflow-y: auto;
                      overflow-x: hidden;
                    "
                    class="chat-body"
                    id="chat-body"
                  >
                    <template
                      v-for="(
                        ticket_detail, tD
                      ) in selectedTicket.ticket_details"
                    >
                      <v-row :key="`ticket_detail_${tD}`">
                        <template v-if="ticket_detail.user_id == user.id">
                          <!-- Self  -->
                          <v-col>
                            <v-card
                              color="primary"
                              class="rounded-br-0"
                              dark
                              max-width="550px"
                              style="
                                float: right;
                                align-self: flex-end;
                                border-radius: 8px;
                                word-wrap: break-word;
                              "
                            >
                              <v-card-text
                                class="pa-3 white--text"
                                v-html="ticket_detail.description"
                              >
                              </v-card-text>
                              <v-card-text class="pa-2">
                                <span style="text-align: start">
                                  <v-icon v-if="ticket_detail.is_seen"
                                    >mdi-check-all</v-icon
                                  >
                                  <v-icon v-else>mdi-check</v-icon>
                                </span>
                                <span style="text-align: end">
                                  {{ ticket_detail.created_at | in_days }}
                                  {{ ticket_detail.created_at | HM }}
                                </span>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </template>
                        <template v-else>
                          <!-- Other -->
                          <v-col>
                            <v-card
                              color="blue-grey darken-1"
                              class="rounded-bl-0"
                              dark
                              max-width="550px"
                              style="
                                float: left;
                                align-self: flex-start;
                                border-radius: 8px;
                                word-wrap: break-word;
                              "
                            >
                              <v-card-text
                                class="pa-3 white--text"
                                v-html="ticket_detail.description"
                              >
                              </v-card-text>
                              <v-card-text style="text-align: end" class="pa-2">
                                {{ ticket_detail.created_at | in_days }}
                                {{ ticket_detail.created_at | HM }}
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                  </div>
                  <v-row class="mt-3">
                    <v-col sm="11">
                      <froala
                        :tag="'textarea'"
                        :config="config"
                        v-model="message"
                      ></froala>
                    </v-col>
                    <v-col
                      ><v-btn
                        icon
                        :loading="isSending"
                        :disabled="isSending"
                        color="primary"
                        @click="sendMessage(selectedTicket)"
                        ><v-icon size="40">mdi-send</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-container>
            </v-row>
          </template>
        </v-container>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import Loader from "@/components/loader.vue";
export default {
  components: { Loader },
  name: "TicketsPage",
  data() {
    return {
      form: {
        status: "",
        type: "",
        category: "",
      },
      tickets: [],
      ticket_detail: {
        ticket_id: "",
        description: "",
      },
      message: "",
      selectedTicket: {},
      pending_tickets: [],
      completed_tickets: [],
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      statusItems: [
        { id: "PENDING", text: "PENDING", value: 0 },
        { id: "COMPLETED", text: "COMPLETED", value: 1 },
      ],
      typeItems: [
        { text: "IT SUPPORT", value: "IT SUPPORT" },
        { text: "ACADEMIC HEAD", value: "ACADEMIC TEAM" },
      ],
      categoryItems: ["ARTICLE", "ASSIGNMENT"],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      isLoading: false,
      isSending: false,
      date_menu: false,
      show_ticket_details: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.form.status = this.form.status ? this.form.status : "";
      this.form.category = this.form.category ? this.form.category : "";
      this.form.search_keyword = this.form.search_keyword
        ? this.form.search_keyword
        : "";
      this.form.date_filter = this.form.date_filter
        ? this.form.date_filter
        : "";

      if (this.roleName == "IT SUPPORT") {
        this.form.type = "IT SUPPORT";
      }
      if (this.roleName == "ACADEMIC TEAM") {
        this.form.type = "ACADEMIC TEAM";
      }
      let tickets = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&type=" +
        this.form.type +
        "&category=" +
        this.form.category +
        "&status=" +
        this.form.status +
        "&search_keyword=" +
        this.form.search_keyword +
        "&date_filter=" +
        this.form.date_filter;
      tickets = await axios.get(`tickets?${query}`);
      this.tickets = tickets.data.data;
      this.pending_tickets = this.tickets.filter((t) => t.status == false);
      this.completed_tickets = this.tickets.filter((t) => t.status == true);
      this.isLoading = false;
    },
    async update(ticket) {
      try {
        this.isLoading = true;
        await axios.patch(`/tickets/${ticket.id}`, ticket);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async show_details(ticket) {
      try {
        this.isLoading = true;
        this.selectedTicket = ticket;
        this.selectedTicket.ticket_details.forEach(async (st) => {
          if (st.is_seen == false) {
            st.is_seen = true;
            await axios.patch(`/ticket_details/${st.id}`, st);
          }
        });
        this.selectedTicket.is_new = false;
        this.update(ticket);
        this.show_ticket_details = true;
        console.log(this.selectedTicket);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async sendMessage(ticket) {
      try {
        this.isSending = true;
        this.ticket_detail.ticket_id = ticket.id;
        this.ticket_detail.user_id = this.user.id;
        this.ticket_detail.description = this.message;
        let response = await axios.post("/ticket_details", this.ticket_detail);
        if (response) {
          let detail = response.data.data;
          ticket.ticket_details.push(detail);
          this.isSending = false;
          this.message = "";
        }
        this.$refs.chatBody.scrollTop = this.$refs.chatBody.scrollHeight;
      } catch (e) {
        console.log(e);
        this.isSending = false;
      }
    },
  },
};
</script>
