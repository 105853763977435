<template>
  <section>
    <div
      class="text-h5 font-weight-bold"
      style="min-width: 150px"
      v-text="'In Progress Assignments'"
    ></div>
    <br />
    <v-simple-table fixed-header height="285px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left sr_no_class">Sr No</th>
            <th class="text-center" style="min-width: 60px">Title</th>
            <th class="text-center" style="min-width: 60px">Type</th>
            <th class="text-center" style="min-width: 76px">Teacher</th>
            <th class="text-center" style="min-width: 75px">Start</th>
            <th class="text-center" style="min-width: 55px">Due</th>
          </tr>
        </thead>
        <tbody>
          <router-link
            class="routerLink"
            to="/assignments?tab=tab-1"
            tag="tr"
            v-for="(
              uAssignment, uA
            ) in assignmentOverview.statusWiseAssignments.filter(
              (sWA) => sWA.name == 'IN PROGRESS'
            )[0]?.values"
            :key="`assignment${uA}`"
          >
            <td class="text-left">{{ uA + 1 }}</td>
            <td class="text-center" style="min-width: 150px">
              {{ uAssignment.assignment_title | limit }}
            </td>
            <td class="text-center td_assignment_type">
              {{ uAssignment.assignment_type | capitalize }}
            </td>
            <td class="text-center" style="min-width: 165px">
              {{ uAssignment.created_by_teacher.name }}
            </td>
            <td class="text-center" style="min-width: 105px">
              {{ uAssignment.start_date | ddmmyyyy }}
            </td>
            <td class="text-center" style="min-width: 105px">
              {{ uAssignment.end_date | ddmmyyyy }}
            </td>
          </router-link>
        </tbody>
      </template>
    </v-simple-table>
  </section>
</template>

<script>
export default {
  props: ["assignmentOverview"],
};
</script>
<style scoped>
.max {
  min-width: 62px !important;
}
.min {
  min-width: 56px !important;
}
</style>
