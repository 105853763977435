<template>
  <v-main>
    <v-container>
      <v-card elevation="10">
        <v-card-title>Please Select the School</v-card-title>

        <v-card-text>
          <!-- <SelectCompanyComponent></SelectCompanyComponent> -->
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import SelectCompanyComponent from "../components/SelectCompanyComponent.vue";
export default {
  components: {
    SelectCompanyComponent,
  },
};
</script>