<template>
  <v-main>
    <v-container ref="title">
      <loader v-if="isLoading"></loader>
      <!-- Article Card -->
      <v-card v-if="form.content_id" elevation="6">
        <v-card-title>
          <v-row>
            <v-col>Article Details</v-col>
            <v-spacer></v-spacer>
            <v-col align="right" md="1" v-if="form.content_type == 'ARTICLE'">
              <v-btn icon color="primary" @click="isHelpDialog = true">
                <v-icon size="20">mdi-help</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text> {{ form.content_name }} </v-card-text>
      </v-card>
      <!-- Link Content Card -->
      <v-card v-if="!form.content_id" elevation="6">
        <v-card-title>
          <v-btn small dark color="primary" @click="isLinkArticleDialog = true">
            Link Content
          </v-btn>
          &nbsp;
          <span v-if="errors.content_id" style="color: red"
            >An article must be linked</span
          >
        </v-card-title>
        <v-dialog v-model="isLinkArticleDialog" max-width="1000px" persistent>
          <v-card elevation="2">
            <v-card-title>
              Articles
              <loader v-if="isSearching" size="25" class="ml-3"></loader
            ></v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="search_article_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="searchArticles"
                  ></v-text-field>
                </v-col>
              </v-row>
              <br />
              <p class="font-weight-bold">Articles List</p>
              <br />
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(article, a) in articles"
                  :key="`article${a}`"
                >
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col sm="9">
                        {{ article.content_name }}
                      </v-col>
                      <v-col sm="3">
                        <v-btn
                          small
                          dark
                          color="primary"
                          @click="linkArticle(article)"
                        >
                          Link This Article
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-html="article.easy_content"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-dialog
                v-model="isViewArticleDialog"
                max-width="400px"
                persistent
              >
                <v-card elevation="2" align="center">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Data Saved Successfully
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-btn color="primary" @click.stop="closeViewArticleDialog"
                      >Close</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-dialog>
              <br />
              <v-btn color="primary" @click.stop="closeLinkArticleDialog">
                Close
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
      <br />
      <v-card elevation="6">
        <v-card-title> Create a Personalized Assignment </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="6">
              <v-autocomplete
                solo
                ref="classcode_id"
                v-model="form.classcode_id"
                :items="classcodes"
                label="Select classcode"
                clearable
                append-icon="mdi-application-edit"
                :error-messages="errors.classcode_id"
                @change="getStudents()"
              ></v-autocomplete>
            </v-col>
            <v-col sm="3">
              <v-menu
                v-model="form.start_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    v-model="form.start_date"
                    ref="start_date"
                    label="Start Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    :error-messages="errors.start_date"
                  ></v-text-field>
                </template>

                <v-date-picker
                  :id="`start_date`"
                  v-model="form.start_date"
                  @change="(form.start_date_menu = false), (form.end_date = '')"
                  :min="nowDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="3">
              <v-menu
                v-model="form.end_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    ref="end_date"
                    v-model="form.end_date"
                    label="End Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    :error-messages="errors.end_date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :id="`end_date`"
                  :disabled="form.start_date == null"
                  v-model="form.end_date"
                  @change="form.end_date_menu = false"
                  :min="form.start_date"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row class="mt-4" v-if="form.variants.length">
        <v-col
          v-for="(variant, cD) in form.variants.filter(
            (description) => description.infakt_assignments.length > 0
          )"
          :key="`article${cD}`"
        >
          <!-- <v-card elevation="6" height="640"> -->
          <v-card elevation="6" height="440">
            <v-card-title
              class="justify-center primary accent-1 white--text pa-1"
            >
              {{ variant.level }}
            </v-card-title>
            <v-card-text>
              <v-autocomplete
                solo
                class="mt-4 mb-2"
                hide-details
                v-model="variant.selected_assignment_id"
                @input="setSelectedVariantAssignment(variant)"
                :items="variant.variant_assignments"
                label="Select Assignment"
                clearable
                append-icon="mdi-clipboard-text"
              >
                <template
                  v-slot:append-outer
                  v-if="variant.selected_assignment_id"
                >
                  <v-slide-x-reverse-transition mode="out-in">
                    <v-icon
                      @click="ShowDetails(variant.selected_assignment_id)"
                    >
                      mdi-eye</v-icon
                    >
                  </v-slide-x-reverse-transition>
                </template></v-autocomplete
              >
              <section style="overflow: auto; height: 310px">
                <template v-if="variant.selected_assignment_id">
                  <v-checkbox
                    :class="`${
                      isStudentDisabled(student, variant) ? '' : 'bold-label'
                    }`"
                    v-for="(student, s) in variant.selected_assignment
                      .allStudents"
                    :key="`student_${cD}_${s}`"
                    :label="student.name"
                    :value="
                      variant.selected_assignment.selected_students.some(
                        (itemB) => itemB == student.id
                      )
                        ? true
                        : false
                    "
                    hide-details
                    @change="
                      handleCheckboxChange(student, variant.selected_assignment)
                    "
                    :disabled="isStudentDisabled(student, variant)"
                  >
                  </v-checkbox>
                </template>
                <template v-else>
                  <v-checkbox
                    v-for="(student, sd) in students"
                    :key="`student_${sd}_${cD}`"
                    disabled
                    hide-details
                    :label="student.name"
                  ></v-checkbox>
                </template>
              </section>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3">
          <v-btn
            :loading="isSaving"
            :disabled="isSaving"
            color="primary"
            dark
            x-large
            @click="save()"
          >
            Create Assignment
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!-- Assignment Details Dialog Box -->
    <assignment-preview
      v-model="show_details"
      @changeStatus="changeStatus"
      :assignment_details="assignment_details"
      :show_details="show_details"
    ></assignment-preview>
    <!-- Help Dialog Box -->
    <v-dialog v-model="isHelpDialog" max-width="470px">
      <v-card elevation="2" class="rounded-tl-xl rounded-br-xl no-select">
        <v-container>
          <v-card-title>
            <v-icon large color="green darken-2"> mdi-help </v-icon>
            Standard Lexile Measurement Chart
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="success--text text-center">Grade</th>
                      <th class="success--text text-center">Basic</th>
                      <th class="success--text text-center">Proficient</th>
                      <th class="success--text text-center">Advanced</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">6</td>
                      <td class="text-center">500L - 799L</td>
                      <td class="text-center">800L - 1050L</td>
                      <td class="text-center">above 1050L</td>
                    </tr>
                    <tr>
                      <td class="text-center">7</td>
                      <td class="text-center">550L - 849L</td>
                      <td class="text-center">850L - 1100L</td>
                      <td class="text-center">above 1100L</td>
                    </tr>
                    <tr>
                      <td class="text-center">8</td>
                      <td class="text-center">600L - 899L</td>
                      <td class="text-center">900L - 1150L</td>
                      <td class="text-center">above 1150L</td>
                    </tr>
                    <tr>
                      <td class="text-center">9</td>
                      <td class="text-center">650L - 999L</td>
                      <td class="text-center">1000L - 1200L</td>
                      <td class="text-center">above 1200L</td>
                    </tr>
                    <tr>
                      <td class="text-center">10</td>
                      <td class="text-center">700L - 1024L</td>
                      <td class="text-center">1025L - 1250L</td>
                      <td class="text-center">above 1250L</td>
                    </tr>
                    <tr>
                      <td class="text-center">11 & 12</td>
                      <td class="text-center">800L - 1049L</td>
                      <td class="text-center">1050L - 1300L</td>
                      <td class="text-center">above 1300L</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="ma-5"
                @click.stop="isHelpDialog = false"
                >Close</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- /Help Dialog Box -->
    <!-- Remaining Students  Dialog Box -->
    <v-dialog v-model="isRemainingStudentWarning" max-width="550">
      <v-card elevation="2" class="rounded-tl-xl rounded-br-xl no-select">
        <v-container>
          <v-row align="center">
            <v-col>
              <v-card-title>
                <v-row>
                  <v-col sm="2"
                    ><v-icon size="70" color="red"
                      >mdi-alert-outline</v-icon
                    ></v-col
                  >
                  <v-col>
                    Oops! It appears there
                    {{ remainingStudents.length > 1 ? "are" : "is" }} still
                    {{ remainingStudents.length }} student{{
                      remainingStudents.length > 1 ? "s" : ""
                    }}
                    who haven't been assigned any lexile level.
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col>
                    <v-simple-table fixed-header>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">Student</th>
                            <th class="text-center">Level</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="isAssigning">
                            <tr>
                              <td>
                                <MyLoader></MyLoader>
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr
                              v-for="(student, s) in DemoStudents"
                              :key="`student_${s}`"
                            >
                              <MyLoader v-if="isAssigning"></MyLoader>
                              <td class="text-center">{{ student.name }}</td>
                              <td class="text-center">
                                <v-radio-group
                                  row
                                  v-model="student.selected_variant_id"
                                  class="ma-0"
                                  hide-details=""
                                  @change="
                                    setAssignment(
                                      student.id,
                                      student.selected_variant_id
                                    )
                                  "
                                >
                                  <v-radio
                                    v-for="(variant, v) in form.variants.filter(
                                      (v) =>
                                        v.infakt_assignments.length > 0 &&
                                        v.selected_assignment
                                    )"
                                    :key="`variant_${v}`"
                                    :label="`${variant.level}`"
                                    :value="`${variant.id}`"
                                  ></v-radio>
                                </v-radio-group>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-actions>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    class="ma-5"
                    @click.stop="
                      (isRemainingStudentWarning = false), getRemaining()
                    "
                    >Close</v-btn
                  >
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- /Help Dialog Box -->
  </v-main>
</template>
<script>
import axios from "axios";
import Loader from "@/components/loader.vue";
import AssignmentPreview from "@/components/assignment-preview.vue";
export default {
  name: "PersonalizedAssignmentCreatePage",
  data() {
    return {
      nowDate: new Date().toISOString().slice(0, 10),
      form: {
        classcode_id: "",
        start_date: "",
        end_date: "",
        category: "PERSONALIZED ASSIGNMENT",
        content_id: "",
        variants: {},
      },
      search_keyword: "",
      assignments_library: [],
      assignment_details: {},
      classcodes: [],
      articles: [],
      final: [],
      students: [],
      remainingStudents: [],
      DemoStudents: [],
      search_article_keyword: "",
      isAssigning: false,
      isSaving: false,
      isLoading: false,
      isDataSaved: false,
      show_details: false,
      isLinkArticleDialog: false,
      isSearching: false,
      isViewArticleDialog: false,
      isHelpDialog: false,
      isStudentsLoading: false,
      isRemainingStudentWarning: false,
      selectedStudents: {},
    };
  },
  components: {
    Loader,
    AssignmentPreview,
  },
  computed: {
    filteredLists() {
      const selectedStudents = this.students.map(() => ({}));
      this.students.forEach((student) => {
        selectedStudents.forEach((list, listIndex) => {
          if (!selectedStudents[listIndex][student.id]) {
            list[student.id] = student;
          }
        });
      });
      return selectedStudents;
    },
  },
  mounted() {
    this.form.article;
    this.getData();
    if (this.roleName == "TEACHER") {
      this.form.company_id = this.company.id;
    }
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let articleId = this.$route.params.articleId;
      // If Article Id is passed
      let content;
      if (articleId) {
        content = await axios.get(`/contents/${articleId}`);
        this.form.content_id = content.data.data.id;
        this.form.content_type = content.data.data.content_type;
        this.form.content_name = content.data.data.content_name;
        this.form.variants = content.data.data.content_descriptions;
        this.form.variants.forEach((variant) => {
          variant.variant_assignments = [];
          variant.infakt_assignments.forEach((assignment) => {
            variant.variant_assignments.push({
              id: assignment.id,
              text: assignment.assignment_title,
              value: assignment.id,
              selected_students: [],
              allStudents: [],
            });
          });
        });
      }
      console.log(this.form);
      // Classcodes
      let classcodes = await axios.get("users/masters");
      classcodes = classcodes.data.classcodes;
      classcodes?.forEach((classcode) => {
        this.classcodes.push({
          id: classcode.id,
          text: classcode.classcode,
          value: classcode.id,
        });
      });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isSaving = true;
        this.isRemainingStudentWarning = false;
        if (this.remainingStudents.length > 0) {
          this.checkRemainingStudents();
        } else {
          this.form.created_by_id = this.user.id;
          let assignment = await axios.post(
            `/personalized_assignments`,
            this.form
          );
          this.form = assignment.data.data;
          this.$router.push(`/assignments/new-index`);
          this.isDataSaved = true;
        }
        this.isSaving = false;
      } catch (e) {
        console.log(e);
        let errors = e.response.data.errors;
        this.focusDiv(errors);
        this.isSaving = false;
      }
    },
    async searchArticles() {
      this.isSearching = true;
      let query = "subject_id=&search_keyword=" + this.search_article_keyword;
      let articles = await axios.get(`/search_contents?${query}`);
      this.articles = articles.data.data;
      this.isSearching = false;
    },
    async linkArticle(article) {
      this.form.content_id = article.id;
      let content = await axios.get(`/contents/${article.id}`);
      this.form.content_id = content.data.data.id;
      this.form.content_type = content.data.data.content_type;
      this.form.content_name = content.data.data.content_name;
      this.form.variants = content.data.data.content_descriptions;
      if (this.form.variants.length > 0) {
        // If Article has Variants then Add levels of each variant in Variants[]
        this.form.variants.forEach((variant) => {
          variant.variant_assignments = [];
          variant.infakt_assignments.forEach((assignment) => {
            this.selectedStudents.push(assignment.id);
            variant.variant_assignments.push({
              id: assignment.id,
              text: assignment.assignment_title,
              value: assignment.id,
              selected_students: [],
              allStudents: [],
            });
          });
        });
      }
      this.closeLinkArticleDialog();
      this.closeViewArticleDialog();
    },
    closeLinkArticleDialog() {
      this.isLinkArticleDialog = false;
    },
    closeViewArticleDialog() {
      this.isViewArticleDialog = false;
    },
    async getStudents() {
      this.isLoading = true;
      this.students = [];
      if (this.form.classcode_id) {
        let students = [];
        let query = "role_id=5" + "&classcode_id=" + this.form.classcode_id;
        students = await axios.get(`/users?${query}`);
        let sortedStudents = students.data.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        this.students = sortedStudents;
        this.remainingStudents = sortedStudents;
        this.student_count = students.data.count;
        this.form.variants.forEach((variant) => {
          variant.variant_assignments.forEach((vA) => {
            vA.allStudents = this.students;
          });
        });
      }
      this.isLoading = false;
    },
    async ShowDetails(id) {
      this.assignment_details = {};
      let assignment = await axios.get(`/assignments/${id}`);
      this.assignment_details = assignment.data.data;
      if (this.assignment_details) {
        this.show_details = true;
      }
    },
    setSelectedVariantAssignment(variant) {
      let selected_assignment = variant.variant_assignments.find(
        (vA) => vA.id == variant.selected_assignment_id
      );
      variant.selected_assignment = selected_assignment;
    },
    focusDiv(errors) {
      console.log("Focus");
      Object.keys(errors).forEach((e) => {
        switch (e) {
          case "classcode_id":
            this.$refs.classcode_id.focus();
            break;
          case "start_date":
            this.$refs.start_date.focus();
            break;
          case "end_date":
            this.$refs.title.focus();
            break;
          default:
            break;
        }
      });
    },
    isStudentDisabled(student, variant) {
      let isAvailableStudent = this.remainingStudents.find(
        (vSelectedStudent) => vSelectedStudent.id == student.id // Check for already selected student in any other variants
      );
      let isMySelectedStudent = false;
      if (variant.selected_assignment) {
        isMySelectedStudent =
          variant?.selected_assignment.selected_students.some(
            (vSelectedStudent) => vSelectedStudent == student.id // Check for already selected student in any other variants
          );
      }
      console.log(
        "Student " +
          student.id +
          " is present in variant " +
          variant.level +
          " = " +
          isMySelectedStudent +
          " || Is in Avaible= " +
          (isAvailableStudent ? "true" : "false")
      );
      if (isAvailableStudent || isMySelectedStudent) {
        return false;
      } else {
        return true;
      }
    },
    handleCheckboxChange(selected_student, selected_assignment) {
      let existing = selected_assignment.selected_students.some(
        (itemB) => itemB === selected_student.id
      );
      if (!existing) {
        // Push the student in Selected Students List
        selected_assignment.selected_students.push(selected_student.id);
        // Remove the student from Remaining Students List
        this.remainingStudents = this.remainingStudents.filter((itemA) => {
          return !selected_assignment.selected_students.some(
            (itemB) => itemB === itemA.id
          );
        });
      } else {
        // Remove the student from Selected Students List
        selected_assignment.selected_students =
          selected_assignment.selected_students.filter((itemA) => {
            return itemA != selected_student.id;
          });
        // Add the Student Again in the Remaining Students List
        this.remainingStudents.push(selected_student);
      }
    },
    searchStudent(value, variant) {
      // let abc = variant.selected_assignment.allStudents;
      if (value) {
        // Search Students from Remaining Students using string
        variant.selected_assignment.allStudents =
          variant.selected_assignment.allStudents.filter((itemA) => {
            return itemA.name == value;
          });
      } else {
        variant.selected_assignment.allStudents = this.students;
      }
    },
    isSelected(selected_student, selected_assignment) {
      console.log(
        selected_assignment.selected_students.some(
          (itemB) => itemB === selected_student.id
        )
      );
      let is_selected = selected_assignment.selected_students.some(
        (itemB) => itemB === selected_student.id
      );
      return is_selected;
      // return selected_assignment.selected_students.some(
      //   (itemB) => itemB === selected_student.id
      // );
    },
    setAssignment(student_id, variant_id) {
      this.isAssigning = true;
      let selected_student = this.students.find((s) => s.id == student_id);
      let variant = this.form.variants.find((v) => v.id == variant_id);

      if (variant) {
        variant.selected_assignment.selected_students.push(selected_student.id);
        // Remove the student from Remaining Students List
        this.remainingStudents = this.remainingStudents.filter((itemA) => {
          return itemA.id != selected_student.id;
        });
        let other_variants = this.form.variants.filter(
          (v) => v.id != variant_id
        );
        other_variants.forEach((oV) => {
          if (oV.selected_assignment) {
            oV.selected_assignment.selected_students =
              oV.selected_assignment.selected_students.filter(
                (vSelectedStudent) => {
                  return vSelectedStudent != selected_student.id; // Check for already selected student in any other variants
                }
              );
          }
        });
        console.log(other_variants);
      }
      if (this.remainingStudents.length == 0) {
        this.isRemainingStudentWarning = false;
      }
      this.isAssigning = false;
    },
    checkRemainingStudents() {
      this.DemoStudents = this.remainingStudents;
      this.DemoStudents.forEach((s) => {
        s.selected_variant_id = null;
      });
      this.DemoStudents.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      console.log(this.DemoStudents);
      this.isRemainingStudentWarning = true;
    },
    changeStatus() {
      this.show_details = false;
      console.log(this.show_details);
    },
  },
};
</script>
<style scoped>
.required label::after {
  content: "*";
}
.bold-label {
  font-weight: bold; /* CSS to make the label text bold */
}
</style>
