<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Add a TSI
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                outlined
                v-model="form.first_name"
                label="First Name *"
                :error-messages="errors.first_name"
                prepend-inner-icon="mdi-account-tie"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                outlined
                v-model="form.last_name"
                label="Last Name *"
                :error-messages="errors.last_name"
                prepend-inner-icon="mdi-account-tie"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                outlined
                v-model="form.id_given_by_school"
                label="Student ID"
                prepend-inner-icon="mdi-account-lock"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field
                outlined
                v-model="form.email"
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <!-- <v-col sm="4">
              <v-text-field
                outlined
                v-model="form.password"
                label="Password"
                prepend-inner-icon="mdi-lock"
              ></v-text-field>
            </v-col> -->
            <v-col sm="4">
              <v-text-field
                outlined
                v-model="form.contact_number"
                label="Contact Number"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                ref="gender"
                v-model="form.gender"
                :items="genderItems"
                label="gender"
                prepend-icon="mdi-gender-male-female"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <strong>Profile Image</strong><br />
              <input
                label
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="image/*"
              />
            </v-col>
            <v-col sm="4">
              <v-switch
                v-model="form.active"
                inset
                :label="`Is Active: ${form.active.toString().toUpperCase()}`"
              ></v-switch>
            </v-col>
            <v-col sm="4">
              <v-switch
                v-model="form.is_mail_sent"
                inset
                :label="`Do you want to send registration Email? ${
                  form.is_mail_sent ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <v-autocomplete
                ref="board_id"
                v-model="form.board_id"
                :items="boardItems"
                label="Select Location *"
                chips
                @change="getBoardClasscodes"
                :error-messages="errors.board_id"
                prepend-icon="mdi-domain"
              ></v-autocomplete>
            </v-col>
            <v-col sm="12">
              <v-autocomplete
                ref="classcode"
                v-model="form.classcodes"
                :items="classcodeItems"
                label="Select Designation"
                prepend-icon="mdi-database-search"
                multiple
                chips
              ></v-autocomplete>
            </v-col>
          </v-row>
          <br />
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save">
            Save Student
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { backbutton, Loader },
  data() {
    return {
      form: {
        first_name: "",
        role_id: 5,
        last_name: "",
        id_given_by_school: "",
        email: "",
        password: "",
        contact_number: "",
        board_id: "",
        gender: "1",
        active: true,
        is_deleted: false,
        is_mail_sent: false,
        joining_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),

        user_classcodes: [],
        classcodes: [],
      },
      isLoading: false,
      classcodes: [],
      classcodeItems: [],
      boards: [],
      boardItems: [],
      genderItems: [
        {
          text: "MALE",
          value: "1",
        },
        {
          text: "FEMALE",
          value: "0",
        },
      ],
    };
  },
  mounted() {
    this.getMasters();
    this.form.board_id = this.board.id;
  },
  methods: {
    async getMasters() {
      this.isLoading = true;
      let masters = await axios.get("users/masters");
      masters = masters.data;
      this.classcodes = masters.classcodes;
      this.boards = masters.boards;
      // ClassCodes
      // this.classcodes.forEach((classcode) => {
      //   this.classcodeItems.push({
      //     id: classcode.id,
      //     text: classcode.classcode,
      //     value: classcode.id,
      //   });
      // });
      // Boards
      this.boards.forEach((board) => {
        this.boardItems.push({
          id: board.id,
          text: board.name,
          value: board.id,
        });
      });
      this.isLoading = false;
      this.getBoardClasscodes();
    },
    async getBoardClasscodes() {
      this.classcodeItems = [];
      this.form.user_classcodes = [];
      let boardClasscodes = this.classcodes.filter(
        (classcode) => classcode.board_id == this.form.board_id
      );
      boardClasscodes.forEach((classcode) => {
        this.classcodeItems.push({
          id: classcode.id,
          text: classcode.classcode,
          value: classcode.id,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.classcodes.forEach((classcode_id) => {
          let standard_id = this.classcodes.find(
            (c) => c.id == classcode_id
          ).standard_id;
          let section_id = this.classcodes.find(
            (c) => c.id == classcode_id
          ).section_id;
          let user_classcode = {
            company_id: this.company.id,
            standard_id: standard_id,
            section_id: section_id,
            classcode_id: classcode_id,
          };
          this.form.user_classcodes.push(user_classcode);
        });
        this.form.user_classcodes = [...new Set(this.form.user_classcodes)];

        this.form.name = this.form.first_name + " " + this.form.last_name;
        let student = await axios.post(`/users`, this.form);
        this.student = student.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.push(`/students`);
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      if (this.$refs.file != null) {
        let attachment = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("userid", this.student.id);
        formData.append("imagepath", attachment);
        await axios
          .post("upload_user_image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
      }
    },
  },
};
</script>
