<template>
  <section>
    <v-card>
      <v-card-title> Content Count Based On </v-card-title>
      <v-card-text>
        <v-radio-group v-model="contentBasedOn" row @change="getData">
          <v-radio label="Grade" value="1"></v-radio>
          <v-radio label="Board" value="2"></v-radio>
          <v-radio label="School" value="3"></v-radio>
          <v-radio label="Subject" value="4"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div v-if="chartData.length != 0">
      <GChart
        type="ColumnChart"
        :data="chartData"
        :options="columnchartOptions"
      />
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "SAContent",
  data() {
    return {
      contentBasedOn: "1",
      chartData: [],
      columnchartOptions: {
        annotations: {
          textStyle: {
            fontSize: 10,
          },
        },
        vAxis: {
          minValue: 0,
          gridlines: {
            color: "transparent",
          },
          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          gridlines: {
            color: "transparent",
          },
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let response = await axios.get(
        `content_based_counts?type=${this.contentBasedOn}`
      );
      response = response.data.data;
      let chartData = [[this.contentBasedOn, "Content"]];
      response.contentBasedCount.forEach((data) => {
        chartData.push([data.name, data.count]);
      });
      this.chartData = chartData;
    },
  },
};
</script>
