<template>
  <v-btn v-if="link" class="mx-2" fab x-small color="primary" :to="link">
    <v-icon dark> mdi-arrow-left </v-icon>
  </v-btn>
  <v-btn v-else class="mx-2" fab x-small color="primary" @click="goBack()">
    <!-- @click="$router.back()" -->
    <!-- @click="$router.go(-1)" -->
    <!-- @click="window.history.back()" -->
    <v-icon dark> mdi-arrow-left </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: ["link"],
  mounted() {
    window.addEventListener("popstate", this.handlePopstate);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handlePopstate);
  },
  methods: {
    handlePopstate(event) {
      event.preventDefault();
      this.goBack();
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
