<template>
  <div>
    <v-row class="my-3">
      <v-col style="min-width: 250" class="py-0" lg="2">
        <h2 ref="assignment_questions" id="assignment_questions">Questions</h2>
      </v-col>
      <v-col class="py-0" style="min-width: 250">
        <b
          style="color: red"
          v-if="
            errors.assignment_questions || !form.assignment_questions?.length
          "
        >
          <span class="smallScreen">Atleast one question is required</span>
          <span class="midScreen">Atleast one question is required</span>
          <h2 class="largeScreen">Atleast one question is required</h2>
        </b>
      </v-col>
    </v-row>
    <section
      v-for="(assignmentQuestion, fQ) in form.assignment_questions"
      :key="`assignmentQuestion${fQ}`"
    >
      <section v-if="form.assignment_type == 'DOCUMENT'">
        <v-row>
          <v-col sm="1" md="1" lg="1"
            ><h3>Q{{ fQ + 1 }}:</h3>
          </v-col>
          <v-col class="pb-0" sm="3" md="3" lg="3">
            <v-text-field
              outlined
              :readonly="isModificationEnabled == false ? true : false"
              v-model="assignmentQuestion.marks"
              :error-messages="
                errors[`assignment_questions.${fQ}.marks`]
                  ? errors[`assignment_questions.${fQ}.marks`]
                  : ``
              "
              label="Marks"
              :id="`assignment_questions_${fQ}_marks`"
              :ref="`assignment_questions_${fQ}_marks`"
            ></v-text-field>
          </v-col>
        </v-row>
      </section>
      <section v-else>
        <v-row>
          <v-col sm="1" md="1" lg="1"
            ><h3>Q{{ fQ + 1 }}:</h3>
          </v-col>
          <v-col class="pb-0" sm="4" md="4" lg="4" style="min-width: 250">
            <v-text-field
              outlined
              :readonly="isModificationEnabled == false ? true : false"
              v-model="assignmentQuestion.marks"
              :error-messages="
                errors[`assignment_questions.${fQ}.marks`]
                  ? errors[`assignment_questions.${fQ}.marks`]
                  : ``
              "
              label="Marks"
              :id="`assignment_questions_${fQ}_marks`"
              :ref="`assignment_questions_${fQ}_marks`"
            ></v-text-field>
          </v-col>
          <v-col sm="4" md="4" lg="4" style="min-width: 250">
            <v-autocomplete
              :readonly="
                form.assignment_type == 'CUMULATIVE'
                  ? isModificationEnabled == false
                    ? true
                    : false
                  : true
              "
              :error-messages="
                errors[`assignment_questions.${fQ}.question_type`]
                  ? `The question type field is required.`
                  : ``
              "
              :id="`assignment_questions_${fQ}_question_type`"
              :ref="`assignment_questions_${fQ}_question_type`"
              v-model="assignmentQuestion.question_type"
              :items="assignmentTypes"
              label="Question Type"
              prepend-icon="mdi-shape"
            ></v-autocomplete>
          </v-col>
          <v-col sm="3" md="3" lg="3" align="right">
            <v-dialog
              v-if="isModificationEnabled"
              v-model="isDeleteQuestionDialogs[fQ]"
              max-width="400px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small dark v-bind="attrs" v-on="on"
                  >Delete Question
                  <v-icon color="red"> mdi-close-thick </v-icon></v-btn
                >
              </template>

              <v-card elevation="10" align="right">
                <v-card-title
                  class="justify-center primary accent-1 white--text"
                >
                  Do you want to delete Question
                  {{ fQ + 1 }}?
                </v-card-title>
                <br />
                <v-card-text>
                  <v-btn
                    color="primary"
                    @click.stop="deleteQuestion(assignmentQuestion)"
                  >
                    Yes
                  </v-btn>
                  &nbsp;
                  <v-btn color="red" dark @click.stop="closeQuestionDialogs">
                    No
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row
          class="mt-0"
          :id="`assignment_questions_${fQ}_description`"
          :ref="`assignment_questions_${fQ}_description`"
        >
          <v-col md="12">
            <p class="font-weight-bold">Question</p>
            <span
              style="color: red"
              v-if="errors[`assignment_questions.${fQ}.description`]"
              >This question field is required</span
            >
            <section v-show="isModificationEnabled">
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="assignmentQuestion.description"
              ></froala>
            </section>
            <p
              v-if="isModificationEnabled == false"
              v-html="assignmentQuestion.description"
            ></p>
          </v-col>
        </v-row>
        <v-row
          v-if="
            form.assignment_type == 'OBJECTIVE' ||
            (form.assignment_type == 'CUMULATIVE' &&
              assignmentQuestion.question_type == 'OBJECTIVE')
          "
        >
          <v-col sm="6" md="6" lg="6" style="min-width: 250">
            <v-text-field
              outlined
              :readonly="isModificationEnabled == false ? true : false"
              v-model="assignmentQuestion.option1"
              :id="`assignment_questions_${fQ}_option1`"
              :ref="`assignment_questions_${fQ}_option1`"
              label="Option 1 (required)"
              :error-messages="
                errors[`assignment_questions.${fQ}.option1`]
                  ? `The option 1 field is required.`
                  : ``
              "
            ></v-text-field>
          </v-col>
          <v-col sm="6" md="6" lg="6" style="min-width: 250">
            <v-text-field
              outlined
              :readonly="isModificationEnabled == false ? true : false"
              v-model="assignmentQuestion.option2"
              :id="`assignment_questions_${fQ}_option2`"
              :ref="`assignment_questions_${fQ}_option2`"
              label="Option 2 (required)"
              :error-messages="
                errors[`assignment_questions.${fQ}.option2`]
                  ? `The option 2 field is required.`
                  : ``
              "
            ></v-text-field>
          </v-col>
          <v-col sm="6" md="6" lg="6" style="min-width: 250">
            <v-text-field
              outlined
              :id="`assignment_questions_${fQ}_option3`"
              :ref="`assignment_questions_${fQ}_option3`"
              :readonly="isModificationEnabled == false ? true : false"
              v-model="assignmentQuestion.option3"
              label="Option 3 (optional)"
            ></v-text-field>
          </v-col>
          <v-col sm="6" md="6" lg="6" style="min-width: 250">
            <v-text-field
              outlined
              :id="`assignment_questions_${fQ}_option4`"
              :ref="`assignment_questions_${fQ}_option4`"
              :readonly="isModificationEnabled == false ? true : false"
              v-model="assignmentQuestion.option4"
              label="Option 4 (optional)"
            ></v-text-field>
          </v-col>
          <v-col sm="6" md="6" lg="6" style="min-width: 250">
            <v-text-field
              outlined
              :id="`assignment_questions_${fQ}_option5`"
              :ref="`assignment_questions_${fQ}_option5`"
              :readonly="isModificationEnabled == false ? true : false"
              v-model="assignmentQuestion.option5"
              label="Option 5 (optional)"
            ></v-text-field>
          </v-col>
          <v-col sm="6" md="6" lg="6" style="min-width: 250">
            <v-text-field
              outlined
              :id="`assignment_questions_${fQ}_option6`"
              :ref="`assignment_questions_${fQ}_option6`"
              :readonly="isModificationEnabled == false ? true : false"
              v-model="assignmentQuestion.option6"
              label="Option 6 (optional)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            sm="6"
            v-if="
              form.assignment_type == 'OBJECTIVE' ||
              (form.assignment_type == 'CUMULATIVE' &&
                assignmentQuestion.question_type == 'OBJECTIVE')
            "
          >
            <!-- <v-text-field
              outlined
              v-model="assignmentQuestion.correct_options"
              label="Correct Option no (eg. 1, 2, 3, 4)"
            ></v-text-field> -->
            <v-autocomplete
              :id="`assignment_questions_${fQ}_correct_options`"
              :ref="`assignment_questions_${fQ}_correct_options`"
              :readonly="isModificationEnabled == false ? true : false"
              v-model="assignmentQuestion.correct_options"
              :items="correctOptionItems"
              multiple
              chips
              outlined
              label="Correct Option no (eg. 1, 2, 3, 4)"
              :error-messages="
                errors[`assignment_questions.${fQ}.correct_options`]
                  ? `The Correct Options field is required.`
                  : ``
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" class="pb-0">
            <p class="font-weight-bold">Model Answer</p>
            <section v-show="isModificationEnabled">
              <froala
                :id="`assignment_questions_${fQ}_model_answer`"
                :ref="`assignment_questions_${fQ}_model_answer`"
                :tag="'textarea'"
                :config="config"
                v-model="assignmentQuestion.model_answer"
              ></froala>
            </section>
            <p
              v-if="isModificationEnabled == false"
              v-html="assignmentQuestion.model_answer"
            ></p>
          </v-col>
        </v-row>
      </section>
      <v-divider class="my-5"></v-divider>
    </section>
    <v-btn
      small
      class="mb-4"
      color="primary"
      @click="addEmptyQuestion"
      v-show="isModificationEnabled && this.form.assignment_type != 'DOCUMENT'"
    >
      Add Question
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
    <v-divider v-show="isModificationEnabled"></v-divider>
    <br />
  </div>
</template>

<script>
export default {
  name: "AssignmentQuestionComponent",
  props: ["form", "isModificationEnabled"],
  data() {
    return {
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      isDeleteQuestionDialogs: [],

      correctOptionItems: [
        { value: 1, text: "Option 1" },
        { value: 2, text: "Option 2" },
        { value: 3, text: "Option 3" },
        { value: 4, text: "Option 4" },
        { value: 5, text: "Option 5" },
        { value: 6, text: "Option 6" },
      ],
      assignmentTypes: ["SUBJECTIVE", "OBJECTIVE"],
    };
  },
  watch: {
    form: {
      handler(value) {
        this.updateQuestionType();
      },
      deep: true,
    },
  },
  methods: {
    resetSrNos() {
      this.form.assignment_questions.forEach((aQ, index) => {
        aQ.sr_no = index + 1;
      });
    },
    updateQuestionType() {
      this.form.assignment_questions.forEach((_aQ) => {
        if (this.form.assignment_type == "OBJECTIVE") {
          _aQ.question_type = "OBJECTIVE";
        }
        if (this.form.assignment_type == "SUBJECTIVE") {
          _aQ.question_type = "SUBJECTIVE";
        }
        if (this.form.assignment_type == "DOCUMENT") {
          _aQ.question_type = "DOCUMENT";
        }
      });
    },
    addEmptyQuestion() {
      let assignmentQuestion = {
        sr_no: this.form.assignment_questions.length + 1,
        assignment_type:
          this.form.assignment_type != "CUMULATIVE"
            ? this.form.assignment_type
            : "",
        description: "",
        model_answer: "",
        correct_options: [],
        marks: "",
        negative_marks: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        assignment_question_options: [],
        assignment_question_correct_options: [],
      };
      this.form.assignment_questions.push(assignmentQuestion);
    },
    focusAssignmentQuestions() {
      this.$refs.assignment_questions_0_marks.focus();
    },
    deleteQuestion(assignmentQuestion) {
      this.form.assignment_questions.splice(
        this.form.assignment_questions.indexOf(assignmentQuestion),
        1
      );
      this.resetSrNos();
      this.closeQuestionDialogs();
    },
    closeQuestionDialogs() {
      this.isDeleteQuestionDialogs = [];
    },
  },
};
</script>
