<template>
  <v-main v-if="!isauthenticated">Not Authenticated</v-main>
  <v-main v-else>
    <Carousel></Carousel>

    <Advantage></Advantage>

    <Features></Features>

    <Subjects></Subjects>
    <BrandPartners></BrandPartners>
    <Foot></Foot>
  </v-main>
</template>

<script>
import Carousel from "./carousel.vue";
import Advantage from "./advantage.vue";
import Features from "./features.vue";
import Subjects from "./subjects.vue";
import Stats from "./stats.vue";
import BrandPartners from "./brandPartners.vue";
import Foot from "./foot.vue";

export default {
  name: "App",
  data() {
    return {
      isauthenticated: true,
    };
  },
  components: {
    Carousel,
    Advantage,
    Features,
    Subjects,
    Stats,
    BrandPartners,
    Foot,
  },
  mounted() {
    //   let password = prompt('Enter passcode');
    //   if(password == 'Infakt2023') {
    //     this.isauthenticated = true
    //   } else {
    //     alert('Wrong Password')
    //   }
  },
};
</script>

<style scoped>
.myFont {
  font-family: "SF PRO" !important;
  color: black;
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    selector {
      property: value;
    }
  }
}
/* Test website on real Safari 11+ */

/* Safari 10.1 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    selector {
      property: value;
    }
  }
}
</style>
