<template>
  <v-main>
    <v-container>
      <v-card
        v-if="
          !form.toi_article_id &&
          !form.et_article_id &&
          !form.space_article_id &&
          !form.live_science_article_id
        "
        class="my-5"
      >
        <!-- <v-card-title>
          <v-btn small dark color="primary" @click="isLinkEpaperDialog = true">
            Link Epaper
          </v-btn>
        </v-card-title> -->
        <v-dialog v-model="isLinkEpaperDialog" max-width="1000px" persistent>
          <v-card elevation="2">
            <v-card-title>
              Epapers
              <loader v-if="isSearching" size="25" class="ml-3"></loader>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="search_article_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    @keydown.enter="searchEpapers"
                  ></v-text-field>
                </v-col>
              </v-row>
              <br />
              <p class="font-weight-bold">Epapers List</p>
              <br />
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(article, a) in articles"
                  :key="`article${a}`"
                >
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col sm="2">
                        {{ article.id }}
                      </v-col>
                      <v-col sm="7">
                        {{ article.headline ?? article.title }}
                      </v-col>
                      <v-col sm="3">
                        <v-btn
                          small
                          dark
                          color="primary"
                          @click="linkEpaper(article)"
                        >
                          Link This Epaper
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-html="article.content"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-dialog
                v-model="isViewEpaperDialog"
                max-width="400px"
                persistent
              >
                <v-card elevation="2" align="center">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Data Saved Successfully
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-btn color="primary" @click.stop="closeViewEpaperDialog"
                      >Close</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-dialog>
              <br />
              <v-btn color="primary" @click.stop="closeLinkEpaperDialog">
                Close
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
      <v-card v-else class="my-5">
        <v-card-title>Article Details</v-card-title>
        <v-card-text> {{ article.headline ?? article.title }} </v-card-text>
      </v-card>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Add Content
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="subject"
                v-model="form.content_type"
                :items="contentTypes"
                chips
                label="Select Content Type"
                prepend-icon="mdi-domain"
                :error-messages="errors.content_type"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="category"
                v-model="form.categories"
                :items="categoryItems"
                label="Category *"
                multiple
                chips
                prepend-icon="mdi-shape"
                :error-messages="errors.content_categories"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                v-model="form.content_name"
                outlined
                label="Title *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.content_name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.reading_time"
                outlined
                label="Reading time (in mins)"
                prepend-inner-icon="mdi-clock-outline"
                :error-messages="errors.reading_time"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.written_by_name"
                outlined
                label="Written By"
                prepend-inner-icon="mdi-notebook-edit-outline"
                :error-messages="errors.written_by_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.publication"
                outlined
                label="Publication"
                prepend-inner-icon="mdi-typewriter"
                :error-messages="errors.publication"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Keywords"
                v-model="form.keywords"
                @keyup="form.keywords = form.keywords.toUpperCase()"
                outlined
                prepend-inner-icon="mdi-tag-search"
                messages="Please mention the tags in comma seperated value e.g English, Evs, Maths. No Special character allowed other than (,) & (-)"
                :error-messages="errors.keywords"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Info Box Section -->
          <v-expansion-panels class="mb-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                Info Box
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <template>
                  <v-tabs
                    v-model="tab"
                    fixed-tabs
                    class="mt-5"
                    background-color="blue-grey lighten-4"
                    color="primary"
                  >
                    <v-tab
                      v-for="(board, b) in form.content_info_boards"
                      :key="`board${b}`"
                    >
                      {{ board.text }}
                    </v-tab>
                  </v-tabs>
                </template>
                <v-tabs-items v-model="tab">
                  <!-- Boards Tab -->
                  <v-tab-item
                    v-for="(contentInfoBoard, b) in form.content_info_boards"
                    :key="`board${b}`"
                  >
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col sm="6">
                            <v-autocomplete
                              ref="grade"
                              v-model="contentInfoBoard.grades"
                              :items="gradeItems"
                              label="Department"
                              multiple
                              chips
                              prepend-icon="mdi-star"
                            ></v-autocomplete>
                          </v-col>
                          <v-col sm="6">
                            <v-autocomplete
                              ref="subject"
                              v-model="contentInfoBoard.subjects"
                              :items="subjectItems"
                              label="Subject"
                              prepend-icon="mdi-application-edit"
                              multiple
                              chips
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col sm="12">
                            <p class="font-weight-bold">Learning Outcome</p>
                            <froala
                              :tag="'textarea'"
                              :config="config"
                              v-model="contentInfoBoard.learning_outcome"
                            ></froala>
                          </v-col>
                        </v-row>
                        <!-- Start for Info Board Details -->
                        <div
                          v-for="(
                            content_info_board_detail, cibd
                          ) in contentInfoBoard.content_info_board_details"
                          :key="`content_info_board_detail${cibd}`"
                          class="mt-5"
                        >
                          <v-row>
                            <v-col sm="11">
                              <b>Detail {{ cibd + 1 }}</b>
                            </v-col>
                            <v-col sm="1">
                              <v-dialog
                                v-model="isDeleteDetailDialogs[cibd]"
                                max-width="600px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    fab
                                    class="ml-9"
                                    small
                                    dark
                                    color="red"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon dark> mdi-close-thick </v-icon>
                                  </v-btn>
                                </template>
                                <v-card elevation="10">
                                  <v-card-title>
                                    <v-icon large color="primary">
                                      mdi-information-outline
                                    </v-icon>
                                    &nbsp; Do you want to remove the Detail?
                                  </v-card-title>
                                  <v-card-text>
                                    <v-btn
                                      color="primary"
                                      @click.stop="
                                        deleteDetail(
                                          contentInfoBoard,
                                          content_info_board_detail
                                        )
                                      "
                                    >
                                      Yes
                                    </v-btn>
                                    &nbsp;
                                    <v-btn
                                      color="red"
                                      dark
                                      @click.stop="closeDetailDialogs"
                                    >
                                      No
                                    </v-btn>
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col sm="4">
                              <v-autocomplete
                                ref="grade"
                                v-model="content_info_board_detail.grade"
                                :items="contentInfoBoard.grades"
                                label="Grade"
                                chips
                                prepend-icon="mdi-star"
                              ></v-autocomplete>
                            </v-col>
                            <v-col sm="4">
                              <v-autocomplete
                                ref="subject"
                                v-model="content_info_board_detail.subject"
                                :items="contentInfoBoard.subjects"
                                label="Subject"
                                prepend-icon="mdi-application-edit"
                                chips
                              ></v-autocomplete>
                            </v-col>
                            <v-col sm="4">
                              <v-text-field
                                label="Chapter"
                                v-model="content_info_board_detail.chapter"
                                outlined
                                prepend-inner-icon="mdi-alphabetical-variant"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col sm="12">
                              <p class="font-weight-bold">Learning Outcome</p>
                              <span
                                style="color: red"
                                v-if="
                                  errors[
                                    `content_info_board_detail.${cibd}.learning_outcome`
                                  ]
                                "
                                >This description is required</span
                              >
                              <froala
                                :tag="'textarea'"
                                :config="config"
                                v-model="
                                  content_info_board_detail.learning_outcome
                                "
                              ></froala>
                            </v-col>
                          </v-row>
                          <v-divider class="my-5"></v-divider>
                        </div>
                        <v-row>
                          <v-col sm="12" class="mt-5">
                            <v-btn
                              small
                              color="primary"
                              @click="Add_Empty_Detail(contentInfoBoard)"
                            >
                              Add Detail
                              <v-icon dark> mdi-plus </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <!-- End of Info Board Details -->
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Info Box Section -->
          <!-- Intended For Section -->
          <v-expansion-panels class="my-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                Intended For
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col sm="3">
                    <v-autocomplete
                      ref="board_id"
                      v-model="form.boards"
                      :items="boardItems"
                      label="Location"
                      multiple
                      chips
                      prepend-icon="mdi-cast-education"
                    ></v-autocomplete>
                  </v-col>
                  <!-- <v-col sm="3">
                    <v-autocomplete
                      ref="school_type"
                      disabled
                      v-model="form.for_school_type"
                      :items="schoolTypeItems"
                      label="Company Type"
                      chips
                      prepend-icon="mdi-currency-usd-off"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="3">
                    <v-autocomplete
                      ref="specific_to"
                      disabled
                      v-model="form.specific_to"
                      :items="specificItems"
                      label="Specific To"
                      chips
                      prepend-icon="mdi-electron-framework"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="3">
                    <v-autocomplete
                      ref="school_id"
                      v-model="form.schools"
                      :items="schoolItems"
                      label="Company"
                      multiple
                      disabled
                      chips
                      prepend-icon="mdi-bus-school"
                      :error-messages="errors.content_schools"
                    ></v-autocomplete>
                  </v-col> -->
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Intended For Section -->
          <!-- Original Content Section -->
          <v-expansion-panels class="my-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                <b>ORIGINAL CONTENT</b>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col sm="12" class="mt-5">
                    <p class="font-weight-bold">Original Content</p>
                    <br />
                    <froala
                      :tag="'textarea'"
                      :config="config"
                      v-model="form.original_content"
                    ></froala>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Original Content Section -->
          <!-- Variants Section -->
          <v-expansion-panels class="my-5">
            <v-expansion-panel>
              <v-expansion-panel-header color="blue-grey lighten-4">
                Variants
                <b
                  class="mx-10"
                  style="color: red"
                  v-if="
                    errors.content_descriptions ||
                    !form.content_descriptions.length
                  "
                  >Atleast one variant is required</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(content_description, uc) in form.content_descriptions"
                  :key="`content_description${uc}`"
                  class="mt-5"
                >
                  <v-row>
                    <v-col sm="11">
                      <p>Variant {{ uc + 1 }}</p>
                    </v-col>
                    <v-col sm="1">
                      <v-dialog
                        v-model="isDeleteDescriptionDialogs[uc]"
                        max-width="600px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            class="ml-9"
                            small
                            dark
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon dark> mdi-close-thick </v-icon>
                          </v-btn>
                        </template>
                        <v-card elevation="10">
                          <v-card-title>
                            <v-icon large color="primary">
                              mdi-information-outline
                            </v-icon>
                            &nbsp; Do you want to remove the variant?
                          </v-card-title>
                          <v-card-text>
                            <v-btn
                              color="primary"
                              @click.stop="
                                deleteDescription(content_description)
                              "
                            >
                              Yes
                            </v-btn>
                            &nbsp;
                            <v-btn
                              color="red"
                              dark
                              @click.stop="closeDescriptionDialogs"
                            >
                              No
                            </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3">
                      <v-text-field
                        v-model="content_description.level"
                        outlined
                        label="Lexile"
                        type="number"
                        prepend-inner-icon="mdi-sort-alphabetical-variant"
                        :error-messages="
                          errors[`content_descriptions.${uc}.level`]
                            ? `The level field is required.`
                            : ``
                        "
                      ></v-text-field
                    ></v-col>
                    <v-col md="9">
                      <v-text-field
                        v-model="content_description.title"
                        outlined
                        label="Title"
                        prepend-inner-icon="mdi-alphabetical-variant"
                        :error-messages="
                          errors[`content_descriptions.${uc}.title`]
                            ? `The title field is required.`
                            : ``
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <p class="font-weight-bold">Description</p>
                      <span
                        style="color: red"
                        v-if="errors[`content_descriptions.${uc}.description`]"
                        >This description is required</span
                      >
                      <froala
                        :tag="'textarea'"
                        :config="config"
                        v-model="content_description.description"
                      ></froala>
                    </v-col>
                  </v-row>
                  <v-divider class="my-5"></v-divider>
                </div>
                <v-row>
                  <v-col sm="12" class="mt-5">
                    <v-btn small color="primary" @click="Add_Empty_Description">
                      Add Variant
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--/ Variants Section -->
          <v-row class="my-5">
            <v-col md="4">
              <input
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="image/*"
                @change="onFileChange"
              />
              <br />
              <p style="color: red">
                {{ imageErrors }}
              </p>
              <br />

              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.file.value = null), (image_url = null)"
                >Remove</v-btn
              >

              <div id="preview" v-if="image_url" class="mt-5">
                <label for="">Card Image Preview</label>
                <br />
                <img
                  :src="image_url"
                  style="height: 30%; width: 30% !important"
                />
              </div>
            </v-col>
            <v-col sm="4">
              <v-switch
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-col v-else>
            <v-btn color="primary" dark @click="save((type = 'draft'))">
              Save in Draft
            </v-btn>
            &nbsp; &nbsp;
            <v-btn color="primary" dark @click="save((type = 'approval'))">
              Send to Approval
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-container>
    <!-- Image Warning popups -->
    <v-dialog v-model="warningImageLoader" width="600">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: red; font-size: 90px; width: 200px !important"
              >
                mdi-file-image-remove
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">OOPS!</h1>
                    <br />
                    <h3>
                      Image dimension is not matching with our standard
                      dimension. The image dimension should be multiple of 690 x
                      390
                    </h3>
                    <br />
                    <v-btn color="success" @click="warningImageLoader = false">
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Image Warning popups -->
  </v-main>
</template>

<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";

export default {
  components: { backbutton, Loader },
  name: "ContentAddPage",
  data() {
    return {
      image: {
        size: "",
        height: "",
        width: "",
      },
      imageLoader: false,
      warningImageLoader: false,
      imageErrors: "",
      config: {
        // toolbarButtons: [
        //   "bold",
        //   "italic",
        //   "underline",
        //   "|",
        //   "align",
        //   "orderList",
        //   "|",
        //   "insertLink",
        //   "insertImage",
        //   "insertVideo",
        //   "insertTable",
        //   "|",
        //   "clearFormatting",
        // ],
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      form: {
        name: "",
        created_by_id: "",
        toi_article_id: "",
        et_article_id: "",
        space_article_id: "",
        live_science_article_id: "",
        is_active: true,
        specific_to: "PUBLIC",
        keywords: "",
        subjects: [],
        content_subjects: [],
        content_medias: [],
        content_descriptions: [],
        categories: [],
        content_categories: [],
        grades: [],
        content_grades: [],
        boards: [],
        content_boards: [],
        info_boards: [],
        content_info_boards: [],
        schools: [],
        content_schools: [],
      },
      contentTypes: ["ARTICLE", "INFOGRAPHIC", "VIDEO"],
      schoolTypeItems: ["FREE", "PAID"],
      specificItems: ["PUBLIC", "SCHOOL"],
      subjects: [],
      subjectItems: [],
      categoryItems: [],
      gradeItems: [],
      boardItems: [],
      schoolItems: [],
      article: [],
      isDeleteDescriptionDialogs: [],
      isDeleteDetailDialogs: [],
      isLoading: false,
      isSearching: false,
      isLinkEpaperDialog: false,
      isViewEpaperDialog: false,
      search_article_keyword: "",
      articles: [],
      tab: "tab-1",
      tabs: [],
      image_url: "",
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {
            displaySize: true,
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
      },
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    onFileChange(e) {
      this.warningImageLoader = false;
      this.file = this.$refs.file?.files[0];
      this.image.size = this.file.size;
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          let divWidth = Math.round(this.image.width / 690);
          let divHeight = Math.round(this.image.height / 390);

          let intoWidth = 690 * divWidth;
          let intoHeight = 390 * divHeight;

          if (
            intoWidth != this.image.width &&
            intoHeight != this.image.height
          ) {
            this.imageErrors =
              "Image dimension ( " +
              this.image.width +
              " x " +
              this.image.height +
              ") which is not matching with our standard dimension. The image dimension should be multiple of 690 x 390.";
            this.imageLoader = true;
            this.warningImageLoader = true;
          } else {
            this.imageLoader = false;
            this.image_url = URL.createObjectURL(this.file);
            this.imageErrors = "";
          }

          if (this.errors.length === 0) {
            this.success = "Image validated successfully";
          }
          // alert(img.width + " " + img.height);
        };
        img.src = evt.target.result;
      };

      reader.onerror = (evt) => {
        console.error(evt);
      };
      // this.image_url = URL.createObjectURL(this.file);
    },
    async getMasters() {
      if (this.$route.query.toiEpaperId) {
        let toiEpaperId = this.$route.query.toiEpaperId;
        let toi_article = await axios.get(`/toi_articles/${toiEpaperId}`);
        this.article = toi_article.data.data;
        this.form.toi_article_id = toiEpaperId;
      }
      if (this.$route.query.etEpaperId) {
        let etEpaperId = this.$route.query.etEpaperId;
        let et_article = await axios.get(`/et_articles/${etEpaperId}`);
        this.article = et_article.data.data;
        this.form.et_article_id = etEpaperId;
      }
      if (this.$route.query.spaceEpaperId) {
        let spaceEpaperId = this.$route.query.spaceEpaperId;
        let space_article = await axios.get(`/space_articles/${spaceEpaperId}`);
        this.article = space_article.data.data;
        this.form.space_article_id = spaceEpaperId;
      }
      if (this.$route.query.live_scienceEpaperId) {
        let live_scienceEpaperId = this.$route.query.live_scienceEpaperId;
        let live_science_article = await axios.get(
          `/live_science_articles/${live_scienceEpaperId}`
        );
        this.article = live_science_article.data.data;
        this.form.live_science_article_id = live_scienceEpaperId;
      }
      if (this.$route.query.etEpaperId) {
        let etEpaperId = this.$route.query.etEpaperId;
        let et_article = await axios.get(`/et_articles/${etEpaperId}`);
        this.article = et_article.data.data;
        this.form.et_article_id = etEpaperId;
      }
      if (this.article) {
        this.form.content_name = this.article.headline
          ? this.article.headline
          : this.article.title;
        this.form.original_content = this.article.content;
      }
      let masters = await axios.get("/contents/masters");
      masters = masters.data;
      masters.schools.forEach((school) => {
        this.schoolItems.push({
          id: school.id,
          text: school.name,
          value: school.id,
        });
      });
      masters.categories.forEach((category) => {
        this.categoryItems.push({
          id: category.id,
          text: category.name,
          value: category.id,
        });
      });
      masters.subjects.forEach((subject) => {
        this.subjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.name,
        });
      });
      masters.boards.forEach((board) => {
        this.boardItems.push({
          id: board.id,
          text: board.name,
          value: board.id,
          learning_outcome: "",
          grades: [],
          subjects: [],
        });
        this.form.content_info_boards.push({
          board_id: board.id,
          text: board.name,
          content_info_board_details: [],
        });
      });
      masters.grades.forEach((grade) => {
        this.gradeItems.push({
          id: grade.id,
          text: grade.name,
          value: grade.name,
        });
      });
    },
    async save(type) {
      try {
        this.isLoading = true;
        if (this.imageLoader == false) {
          // this.boardItems.forEach((board) => {
          //   if (board.learning_outcome) {
          //     let content_info_board_grades = [];
          //     board.grades.forEach((grade_id) => {
          //       let content_info_board_grade = {
          //         grade_id: grade_id,
          //       };
          //       content_info_board_grades.push(content_info_board_grade);
          //     });
          //     let content_info_board_subjects = [];
          //     board.subjects.forEach((subject_id) => {
          //       let content_info_board_subject = {
          //         subject_id: subject_id,
          //       };
          //       content_info_board_subjects.push(content_info_board_subject);
          //     });
          //     let content_info_board = {
          //       board_id: board.id,
          //       learning_outcome: board.learning_outcome,
          //       content_info_board_grades: content_info_board_grades,
          //       content_info_board_subjects: content_info_board_subjects,
          //     };
          //     this.form.content_info_boards.push(content_info_board);
          //   }
          // });
          this.form.created_by_id = this.user.id;
          // this.form.grades.forEach((grade_id) => {
          //   let content_grade = {
          //     grade_id: grade_id,
          //   };
          //   this.form.content_grades.push(content_grade);
          // });
          this.form.categories.forEach((category_id) => {
            let content_category = {
              category_id: category_id,
            };
            this.form.content_categories.push(content_category);
          });

          this.form.boards.forEach((board_id) => {
            let content_board = {
              board_id: board_id,
            };
            this.form.content_boards.push(content_board);
          });
          // this.form.info_boards.forEach((board_id) => {
          //   let content_info_board = {
          //     board_id: board_id,
          //   };
          //   this.form.content_info_boards.push(content_info_board);
          // });
          this.form.schools.forEach((company_id) => {
            let content_school = {
              company_id: company_id,
            };
            this.form.content_schools.push(content_school);
          });
          this.form.subjects.forEach((subject_id) => {
            let content_subject = {
              subject_id: subject_id,
            };
            this.form.content_subjects.push(content_subject);
          });
          if (type == "draft") {
            this.form.is_draft = true;
          }
          if (type == "approval") {
            this.form.is_draft = false;
          }
          this.form.content_categories = [
            ...new Set(this.form.content_categories),
          ];
          this.form.content_boards = [...new Set(this.form.content_boards)];
          this.form.content_schools = [...new Set(this.form.content_schools)];
          this.form.content_subjects = [...new Set(this.form.content_subjects)];

          let content = await axios.post(`/contents`, this.form);
          this.content = content.data.data;
          this.handleFileUpload();
          this.isLoading = false;
          this.$router.push("/contents");
        } else {
          this.warningImageLoader = true;
          this.isLoading = false;
        }
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },

    Add_Empty_Description() {
      let content_description = {
        level: "",
        title: "",
        description: "",
      };
      this.form.content_descriptions.push(content_description);
    },
    deleteDescription(content_description) {
      this.form.content_descriptions.splice(
        this.form.content_descriptions.indexOf(content_description),
        1
      );
      this.closeDescriptionDialogs();
    },
    closeDescriptionDialogs() {
      this.isDeleteDescriptionDialogs = [];
    },
    Add_Empty_Detail(parent) {
      let content_info_board_detail = {
        grade_id: "",
        subject_id: "",
        chapter: "",
        learning_outcome: "",
      };
      parent.content_info_board_details.push(content_info_board_detail);
    },
    deleteDetail(parent, content_info_board_detail) {
      parent.content_info_board_details.splice(
        parent.content_info_board_details.indexOf(content_info_board_detail),
        1
      );
      this.closeDetailDialogs();
    },
    closeDetailDialogs() {
      this.isDeleteDetailDialogs = [];
    },
    async handleFileUpload() {
      this.isLoading = true;
      let attachment = this.$refs.file?.files[0];
      const contentid = this.content.id;
      let formData = new FormData();
      formData.append("contentid", contentid);
      formData.append("featuredimagepath", attachment);
      await axios
        .post("upload_content_featured_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      if (this.career_request) {
        this.success = true;
        this.form = [];
      }
      this.isLoading = false;
      // if (this.career_request) {
      //   this.success = true;
      //   this.form = [];
      // }
      // this.getData();
    },
    async searchEpapers() {
      this.isSearching = true;
      let query = "search_keyword=" + this.search_article_keyword;
      let articles = await axios.get(
        `/contents/search_mother_articles?${query}`
      );
      this.articles = articles.data.data;
      this.isSearching = false;
    },
    async linkEpaper(a) {
      if (a.toi_xml_id) {
        //TOI
        this.form.toi_article_id = a.id;
      } else if (a.space_xml_id) {
        //Space
        this.form.space_article_id = a.id;
      } else if (a.live_science_xml_id) {
        //Live Science
        this.form.live_science_article_id = a.id;
      } else {
        //ET
        this.form.et_article_id = a.id;
      }
      this.article = this.articles.find((article) => article.id == a.id);
      this.form.content_name = this.article.headline
        ? this.article.headline
        : this.article.title;
      this.form.original_content = this.article.content;
      this.closeLinkEpaperDialog();
      this.closeViewEpaperDialog();
    },
    closeLinkEpaperDialog() {
      this.isLinkEpaperDialog = false;
    },
    closeViewEpaperDialog() {
      this.isViewEpaperDialog = false;
    },
  },
};
</script>
<style scoped>
.custom-selected-item-class {
  color: white !important;
}
</style>
