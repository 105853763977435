<template>
  <span>
    <!-- Bookmark Pop UP Dialog -->
    <v-dialog max-width="350" v-model="isEpaperBookmarkDialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          v-on="on"
          v-if="roleName != 'ADMIN'"
        >
          <v-icon size="34">mdi-bookmark-box-multiple</v-icon>
        </v-btn>
      </template>
      <v-card class="rounded-tl-xl rounded-br-xl">
        <v-card>
          <v-card-title class="justify-center primary accent-1 white--text"
            >What would you like to do?
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item-group color="primary">
                <!-- Add Collection -->
                <v-dialog v-model="dialog" width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      class="mx-auto my-auto"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="dialog = true"
                    >
                      <v-list-item-icon>
                        <v-icon large color="red darken-2 mx-2">
                          mdi-plus-box-multiple
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="font-weight-bold"
                            >Create New Collection</span
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-card class="rounded-tl-xl rounded-br-xl">
                    <v-container>
                      <v-row align="center">
                        <v-col sm="3">
                          <v-img
                            :src="require('../assets/imgs/add_files.png')"
                            width="200"
                          ></v-img>
                        </v-col>
                        <v-col sm="9">
                          <v-card-text>
                            <v-row>
                              <v-col>
                                <h3 align="center">
                                  Please name this collection
                                </h3>
                                <br />
                                <v-text-field
                                  v-model="form.collection_name"
                                  outlined
                                  label="Collection Name"
                                  prepend-inner-icon="mdi-plus-box-multiple"
                                  :error-messages="errors.collection_name"
                                ></v-text-field>
                                <p v-if="msg" style="color: red">
                                  {{ msg }}
                                </p>
                                <loader v-if="isLoading"></loader>
                                <v-btn
                                  v-else
                                  color="primary"
                                  dark
                                  @click="saveEpaperCollection(epaper.id)"
                                >
                                  <!-- @click="$emit('saveEpaperCollection', epaper.id)" -->
                                  Save Collection
                                </v-btn>
                                &nbsp;
                                <v-btn color="danger" @click="dialog = false">
                                  Cancel
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
                <!-- Added in Existing Collection -->

                <v-dialog v-model="existingDialog" width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      class="mx-auto my-auto"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="openAddExistingCollectionDialog"
                    >
                      <v-list-item-icon>
                        <v-icon large color="blue darken-2">
                          mdi-animation
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="justify-center mx-auto my-auto"
                        >
                          <span class="font-weight-bold"
                            >Add to an existing collection</span
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <v-card class="rounded-tl-xl rounded-br-xl">
                    <v-container>
                      <v-row align="center">
                        <v-col sm="3">
                          <v-img
                            :src="require('../assets/imgs/add_existing.png')"
                            width="200"
                          ></v-img>
                        </v-col>
                        <v-col sm="9">
                          <v-card-title style="color: red">
                            Attention ! </v-card-title
                          ><v-card-text>
                            <v-row>
                              <v-col>
                                <h3 align="center">
                                  Please choose an existing collection
                                </h3>
                                <br />
                                <v-autocomplete
                                  ref="epaper_collection"
                                  v-model="epaper_collection"
                                  :items="epaper_collections"
                                  label="Collections"
                                  prepend-icon="mdi-animation"
                                ></v-autocomplete>
                                <p v-if="existMSG" style="color: red">
                                  {{ existMSG }}
                                </p>
                                <loader v-if="isLoading"></loader>

                                <v-btn
                                  v-else
                                  color="primary"
                                  dark
                                  @click="saveExistingCollection(epaper.id)"
                                >
                                  <!-- @click="
                                  $emit('saveExistingCollection', epaper.id)
                                " -->
                                  Save Collection
                                </v-btn>

                                &nbsp;
                                <v-btn
                                  color="danger"
                                  @click="existingDialog = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>

                <!-- Bookmark -->
                <v-list-item
                  class="mx-auto my-auto"
                  @click="saveToBookmark(epaper.id)"
                >
                  <v-list-item-icon>
                    <v-icon large color="green darken-2">
                      mdi-book-open-page-variant
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      icon
                      class="justify-center mx-auto my-auto"
                      color="primary"
                    >
                      <span class="font-weight-bold">Add to my library</span>
                    </v-list-item-title>
                    <p v-if="bookmarMSG" style="color: red">
                      {{ bookmarMSG }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- Success Dialog Box -->
    <v-dialog v-model="success" width="600">
      <v-card class="rounded-tl-xl rounded-br-xl">
        <v-container>
          <v-row align="center">
            <v-col sm="3">
              <v-img
                :src="require('../assets/imgs/success.png')"
                width="200"
              ></v-img>
            </v-col>
            <v-col sm="9">
              <v-card-title style="color: green"> Success !</v-card-title
              ><v-card-text>
                <v-row>
                  <v-col>
                    <h3>This has been successfully added in your library.</h3>
                    <br />
                    <v-btn color="danger" @click="closedModal"> OK </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import axios from "axios";
import loader from "./loader.vue";

export default {
  components: { loader },
  data() {
    return {
      form: {
        collection_name: "",
      },
      dialog: false,
      msg: "",
      isLoading: false,
      existingDialog: false,
      epaper_collection: "",
      existMSG: "",
      bookmarMSG: "",
      epaper_collections: [],
      success: false,
      isInfoBoxDialog: false,
      isEpaperBookmarkDialog: false,
    };
  },
  props: ["epaper", "epaper_type"],
  methods: {
    async getMasters() {
      let masters = await axios.get(
        `epaper_collections?user_id=${this.user.id}`
      );
      masters = masters.data.data;
      // this.subjects = masters.subjects;
      masters.forEach((epaper_collection) => {
        this.epaper_collections.push({
          id: epaper_collection.id,
          text: epaper_collection.collection_name,
          value: epaper_collection.id,
        });
      });
    },
    openAddExistingCollectionDialog() {
      this.existingDialog = true;
      this.getMasters();
    },
    // Added New Collection
    async saveEpaperCollection(epaper_id) {
      this.isLoading = true;
      try {
        this.msg = "";
        this.dialog = true;
        this.form.user_id = this.user.id;

        let epaper_collection = await axios.post(
          "/epaper_collections",
          this.form
        );
        this.existingCollection = epaper_collection.data.data;
        this.msg = epaper_collection.data.msg;
        if (epaper_collection.data.success == true) {
          let data = {
            epaper_collection_id: this.existingCollection.id,
          };
          if (this.epaper_type == "TOI") {
            data["toi_article_id"] = epaper_id;
          }
          if (this.epaper_type == "ET") {
            data["et_article_id"] = epaper_id;
          }

          let collection_epaper = await axios.post("/collection_epapers", data);
          this.collection_epaper = collection_epaper.data.data;
          if (this.collection_epaper) {
            this.success = true;
          }
        }
        this.form.collection_name = "";
        this.dialog = false;
      } catch (e) {
        this.dialog = true;
      }
      this.isLoading = false;
    },
    // Added Existing Collection
    async saveExistingCollection(epaper_id) {
      this.isLoading = true;

      try {
        this.existingdialog = true;
        this.existMSG = "";
        let Cdata = {
          epaper_collection_id: this.epaper_collection,
          epaper_id: epaper_id,
        };

        if (this.epaper_type == "TOI") {
          Cdata["toi_article_id"] = epaper_id;
        }
        if (this.epaper_type == "ET") {
          Cdata["et_article_id"] = epaper_id;
        }

        let collection_epaper = await axios.post("/collection_epapers", Cdata);
        this.collection_epaper = collection_epaper.data.data;

        this.existMSG = collection_epaper.data.msg;

        if (this.existMSG == "") {
          this.success = true;
        }
        this.existingdialog = false;
      } catch (e) {
        this.existingdialog = true;
      }
      this.isLoading = false;
    },
    // Added Bookmarks
    async saveToBookmark(epaper_id) {
      try {
        this.bookmarMSG = "";
        let data = {
          company_id: this.company.id,
          user_id: this.user.id,
        };
        if (this.epaper_type == "TOI") {
          data["toi_article_id"] = epaper_id;
        }
        if (this.epaper_type == "ET") {
          data["et_article_id"] = epaper_id;
        }

        let bookmark = await axios.post("/epaper_bookmarks", data);
        this.bookmark = bookmark.data.data;
        this.bookmarMSG = bookmark.data.msg;
        if (this.bookmarMSG == "") {
          this.success = true;
        }
        this.form.collection_name = "";
      } catch (e) {
        this.success = false;
      }
    },
    closedModal() {
      this.success = false;
      this.dialog = false;
      this.existingDialog = false;
      this.isEpaperBookmarkDialog = false;
    },
  },
};
</script>

<style></style>
