<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col sm="2" style="min-width: 125px">
          <span class="text-h5 font-weight-bold" v-text="'Contents'"></span>
          <addbutton
            v-if="roleName == `INFAKT TEACHER`"
            :link="'/contents/create'"
          ></addbutton>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="routerLink"
          sm="4"
          v-for="content_type in content_types"
          :key="content_type.id"
          @click="getSelectedType(content_type.name)"
        >
          <!-- @click="getData('1', content_type.name)" -->
          <v-card
            elevation="5"
            color="primary accent-1"
            dark
            style="min-width: 100px"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  style="min-width: 150px"
                  class="text-h6 font-weight-bold pr-0"
                >
                  {{ content_type.name }}</v-card-title
                >

                <v-card-subtitle class="text-h3 font-weight-bold">
                  {{ content_type.count }}</v-card-subtitle
                >
              </div>
              <v-avatar class="ma-3" size="125" tile>
                <v-icon class="imgStyle" large dark>{{
                  content_type.icon
                }}</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <router-link to="assignments">
            <v-card elevation="5" color="primary accent-1" dark>
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    style="min-width: 150px"
                    class="text-h6 font-weight-bold pr-0"
                  >
                    CONTENT BASED ASSIGNMENT</v-card-title
                  >

                  <v-card-subtitle class="text-h3 font-weight-bold">
                    {{
                      assignments.filter(
                        (a) =>
                          a.company_id == null &&
                          a.content_id != null &&
                          (a.status != 3 || a.created_by_id == user.id)
                      ).length
                    }}</v-card-subtitle
                  >
                </div>
                <v-avatar class="ma-3" size="125" tile>
                  <v-icon class="imgStyle" large dark
                    >mdi-clipboard-text</v-icon
                  >
                </v-avatar>
              </div>
            </v-card>
          </router-link>
        </v-col>
        <v-col sm="6">
          <router-link to="assignments">
            <v-card elevation="5" color="primary accent-1" dark>
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    style="min-width: 150px"
                    class="text-h6 font-weight-bold pr-0"
                  >
                    COLLECTION BASED ASSIGNMENT</v-card-title
                  >

                  <v-card-subtitle class="text-h3 font-weight-bold">
                    {{
                      assignments.filter(
                        (a) =>
                          a.company_id == null &&
                          a.collection_id != null &&
                          (a.status != 3 || a.created_by_id == user.id)
                      ).length
                    }}</v-card-subtitle
                  >
                </div>
                <v-avatar class="ma-3" size="125" tile>
                  <v-icon class="imgStyle" large dark
                    >mdi-clipboard-text</v-icon
                  >
                </v-avatar>
              </div>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
      <v-card elevation="6" shaped class="rounded-tl-xl my-5">
        <v-row class="pa-5">
          <v-col md="2">
            <v-text-field
              v-model="form.search_keyword"
              label="Search ... "
              prepend-inner-icon="mdi-magnify"
              clearable
              @click:clear="(form.search_keyword = null), (page = 1), getData()"
              @keydown.enter="(page = 1), getData()"
              @change="page = 1"
            ></v-text-field>
          </v-col>
          <v-col sm="2">
            <v-menu
              v-model="date_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.date_filter"
                  label="Date Search"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @input="(page = 1), getData()"
                  @click:clear="form.date_filter = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.date_filter"
                @input="(page = 1), getData()"
                @change="form.date_menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col sm="2">
            <v-autocomplete
              ref="Status"
              v-model="form.is_approved"
              :items="statusItems"
              label="Select Status"
              clearable
              @click:clear="(form.is_approved = null), (page = 1), getData()"
              @change="(page = 1), getData()"
            ></v-autocomplete>
          </v-col>
          <v-col sm="2">
            <v-autocomplete
              ref="Type"
              v-model="form.selected_type"
              :items="contentTypeItems"
              label="Select Type"
              clearable
              @click:clear="(form.selected_type = null), (page = 1), getData()"
              @change="(page = 1), getData()"
            ></v-autocomplete>
          </v-col>
          <v-col sm="2" v-if="roleName == 'ACADEMIC TEAM'">
            <v-autocomplete
              ref="User"
              v-model="form.created_by_id"
              :items="users"
              label="Select User"
              prepend-icon="mdi-account"
              clearable
              @click:clear="(form.created_by_id = null), (page = 1), getData()"
              @change="(page = 1), getData()"
            ></v-autocomplete>
          </v-col>
          <v-col sm="2" v-if="roleName == 'ACADEMIC TEAM'">
            <v-autocomplete
              ref="subject"
              v-model="form.is_active"
              :items="activeStatus"
              label="Select Active Status"
              prepend-icon="mdi-toggle-switch"
              clearable
              @click:clear="(form.is_active = null), (page = 1), getData()"
              @change="(page = 1), getData()"
            ></v-autocomplete>
          </v-col>
          <v-col sm="2" v-if="roleName == 'ACADEMIC TEAM'">
            <v-switch
              v-model="form.is_strictly"
              inset
              @change="(page = 1), getData()"
              :label="`Strictly: ${form.is_strictly ? 'YES' : 'NO'}`"
            ></v-switch>
          </v-col>
          <v-col sm="2" v-if="roleName == 'ACADEMIC TEAM'">
            <v-autocomplete
              ref="subject"
              v-model="form.board_ids"
              :items="boards"
              label="Select Boards"
              prepend-inner-icon="mdi-domain"
              clearable
              multiple
              small-chips
              @click:clear="(form.board_ids = null), (page = 1), getData()"
              @change="(page = 1), getData()"
            ></v-autocomplete>
          </v-col>
          <v-col sm="2" v-if="roleName == 'ACADEMIC TEAM'">
            <v-autocomplete
              ref="subjects"
              v-model="form.subject"
              :items="allsubjectItems"
              label="Subjects"
              clearable
              @click:clear="(form.subject = null), (page = 1), getData()"
              @input="(page = 1), getData()"
              prepend-inner-icon="mdi-application-edit"
            ></v-autocomplete>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="3">
            <v-pagination v-model="page" :length="paginationLength">
            </v-pagination>
          </v-col>
        </v-row>
      </v-card>
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left px-2" style="min-width: 85px">
                      Sr No
                    </th>
                    <th class="text-left pl-0 pr-2" style="min-width: 45px">
                      ID
                    </th>
                    <th
                      class="text-left pl-0 pr-2"
                      style="min-width: 80px"
                      v-if="roleName == 'ACADEMIC TEAM'"
                    >
                      Created By
                    </th>

                    <th class="text-left pl-0 pr-2">Type</th>
                    <th class="text-left pl-0 pr-2" style="min-width: 45px">
                      Title
                    </th>
                    <th
                      class="text-left pl-0 pr-2"
                      style="min-width: 85px"
                      v-if="roleName != 'ACADEMIC TEAM'"
                    >
                      Reading Time
                    </th>
                    <th class="text-left pl-0 pr-2" style="min-width: 70px">
                      Written By
                    </th>
                    <th class="text-left pl-0 pr-2" style="min-width: 45px">
                      Status
                    </th>
                    <th class="text-left pl-0 pr-2" style="min-width: 45px">
                      TTS
                    </th>
                    <th class="text-left pl-0 pr-2" style="min-width: 75px">
                      Remarks
                    </th>
                    <th class="text-left pl-0 pr-2" style="min-width: 10px">
                      V/A
                    </th>

                    <th
                      class="text-left"
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                    >
                      Assignments
                    </th>
                    <th
                      class="text-right"
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(content, i) in contents" :key="content.name">
                    <td>
                      <v-tooltip bottom v-if="content.is_active == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Content is Active</span>
                      </v-tooltip>
                      &nbsp;{{ serialNoStarting + i + 1 }}
                    </td>
                    <td class="pl-0 pr-2" style="min-width: 15px">
                      {{ content.id }}
                    </td>
                    <td
                      v-if="roleName == 'ACADEMIC TEAM'"
                      class="pl-0 pr-2"
                      style="min-width: 30px"
                    >
                      {{ content.created_by ? content.created_by.name : "" }}
                    </td>
                    <td class="pa-0" style="min-width: 93px">
                      {{ content.content_type }}
                    </td>
                    <!-- v-if="roleName != 'ACADEMIC TEAM'" -->
                    <td class="pl-0 pr-2" style="min-width: 90px">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            target="_blank"
                            size="25"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            color="primary"
                            :to="`/articles/${content.id}`"
                          >
                            <v-icon> mdi-eye-outline </v-icon>
                          </v-btn>
                        </template>
                        <span>View {{ content.content_type }} Preview</span>
                      </v-tooltip>
                      &nbsp;
                      {{ content.content_name }}
                    </td>
                    <td
                      class="pl-0 pr-2"
                      style="min-width: 15x"
                      v-if="roleName != 'ACADEMIC TEAM'"
                    >
                      {{
                        content.reading_time
                          ? content.reading_time + " mins"
                          : ""
                      }}
                    </td>
                    <td class="pl-0 pr-2">
                      {{ content.written_by_name }}
                    </td>
                    <td class="text-left pl-0 pr-2" style="min-width: 30px">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="content.is_draft == true"
                            color="blue"
                            id="Is Draft"
                          >
                            mdi-file
                          </v-icon>
                        </template>
                        <span>Is Draft</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="
                              content.is_draft == false &&
                              content.is_approved == false
                            "
                            id="Pending for Approval"
                            color="yellow"
                          >
                            mdi-progress-clock
                          </v-icon>
                        </template>
                        <span>Pending for Approval</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-if="content.is_approved == true"
                            id="Approved"
                            color="green"
                          >
                            mdi-check-decagram
                          </v-icon>
                        </template>
                        <span>Approved</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                            id="Rejected"
                            v-if="content.is_approved == 2"
                          >
                            mdi-close-octagon
                          </v-icon>
                        </template>
                        <span>Rejected</span>
                      </v-tooltip>
                    </td>
                    <td class="pl-0 pr-2">
                      <v-icon
                        v-if="
                          content.tts_audio_path == null ||
                          content.tts_audio_path == ''
                        "
                        color="red"
                      >
                        mdi-close-octagon
                      </v-icon>
                      <v-icon v-else color="green"> mdi-check-decagram </v-icon>
                    </td>
                    <td
                      class="text-left pl-0 pr-2"
                      v-html="content.remarks"
                    ></td>
                    <td class="text-left pl-0 pr-2" style="min-width: 65px">
                      {{ content.content_descriptions.length }} /
                      {{
                        roleName == "ACADEMIC TEAM"
                          ? content.assignments.filter(
                              (a) => a.company_id == null
                            ).length
                          : content.my_assignments.length
                      }}
                    </td>
                    <td
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                    >
                      <!-- v-if="content.is_approved == true" -->
                      <v-btn
                        small
                        class="primary"
                        :to="`/articles/${content.id}/assignments`"
                        >Assignments</v-btn
                      >
                      <v-btn
                        small
                        class="primary"
                        @click="generateAudio(content)"
                        >Generate Audio</v-btn
                      >
                      <!-- v-if="content.is_approved == true" -->
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/assignments/create?articleId=${content.id}`"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </td>
                    <td
                      class="text-right"
                      v-if="
                        roleName == 'ACADEMIC TEAM' ||
                        roleName == 'INFAKT TEACHER'
                      "
                    >
                      <v-btn
                        class="mx-2"
                        v-if="
                          roleName == `ACADEMIC TEAM` || //If role is Academic team then they can only edit pending for Approval Content
                          content.is_draft == true || //Rest can only edit rejected or Drafted Content
                          content.is_approved == 2
                        "
                        fab
                        x-small
                        color="primary"
                        :to="`/contents/${content.id}`"
                      >
                        <!-- <v-btn
                        class="mx-2"
                        v-if="
                          (roleName == `ACADEMIC TEAM` && //If role is Academic team then they can only edit pending for Approval Content
                            content.is_approved == false) ||
                          content.is_draft == true || //Rest can only edit rejected or Drafted Content
                          content.is_approved == 2
                        "
                        fab
                        x-small
                        color="primary"
                        :to="`/contents/${content.id}`"
                      > -->
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                      <bookmark-dialog
                        :article="content"
                        v-if="
                          content.is_approved == true //Rest can only create collection on approved Content
                        "
                      ></bookmark-dialog>
                    </td>
                    <!-- roleName == `ACADEMIC TEAM` || //If role is Academic team then they can create collection on all Content -->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="is_audio_generating" width="600" persistent>
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: orange; font-size: 90px; width: 200px !important"
              >
                mdi-cloud-upload-outline
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: orange">Uploading...</h1>
                    <br />
                    <h3>The Audio is gettings generated. Please wait...</h3>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_audio_generated" width="600" persistent>
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: green; font-size: 90px; width: 200px !important"
              >
                mdi-cloud-upload-outline
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">Hurray!</h1>
                    <br />
                    <h3>Audio Generated</h3>
                    <br />
                    <v-btn color="success" @click="is_audio_generated = false">
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import addbutton from "@/components/addbutton.vue";
import BookmarkDialog from "@/components/BookmarkDialog.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { addbutton, BookmarkDialog, Loader },
  name: "ContentsPage",
  data() {
    return {
      form: {
        search_keyword: "",
        date_filter: "",
        is_approved: "",
        created_by_id: "",
        is_active: "",
        selected_type: "",
        board_ids: "",
        subject: "",
        is_strictly: 0,
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      RowsPerPage: 20,
      count: 0,
      contents: [],
      content_types: [],
      assignments: [],
      users: [],
      boards: [],
      allsubjectItems: [],

      statusItems: [
        { id: "PENDING", text: "PENDING", value: "0" },
        { id: "APPROVED", text: "APPROVED", value: "1" },
        { id: "REJECTED", text: "REJECTED", value: "2" },
      ],
      contentTypeItems: ["ARTICLE", "INFOGRAPHIC", "VIDEO"],
      activeStatus: [
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      isLoading: false,
      date_menu: false,
      is_audio_generating: false,
      is_audio_generated: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    if (this.roleName == "INFAKT TEACHER") {
      this.statusItems.unshift({ id: "DRAFT", text: "DRAFT", value: "3" });
    }
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/contents/masters");
      masters = masters.data;
      masters.users.forEach((user) => {
        this.users.push({
          id: user.id,
          text: user.name,
          value: user.id,
        });
      });
      masters.boards.forEach((board) => {
        this.boards.push({
          id: board.id,
          text: board.name,
          value: board.id,
        });
      });
      masters.subjects.forEach((subject) => {
        this.allsubjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.name,
        });
      });
    },
    getSelectedType(type) {
      this.form.selected_type = type;
      this.page = 1;
      this.getData();
    },
    async getData() {
      this.isLoading = true;
      this.form.selected_type = this.form.selected_type
        ? this.form.selected_type
        : "";
      this.form.search_keyword = this.form.search_keyword
        ? this.form.search_keyword
        : "";
      this.form.date_filter = this.form.date_filter
        ? this.form.date_filter
        : "";
      this.form.is_approved = this.form.is_approved
        ? this.form.is_approved
        : "";
      this.form.created_by_id = this.form.created_by_id
        ? this.form.created_by_id
        : "";
      this.form.is_active = this.form.is_active ? this.form.is_active : "";
      this.form.is_strictly = this.form.is_strictly
        ? this.form.is_strictly
        : "";
      this.form.subject = this.form.subject
        ? this.form.subject
        : "";
      let contents = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.RowsPerPage +
        "&type=" +
        this.form.selected_type +
        "&is_active=" +
        this.form.is_active +
        "&board=" +
        this.form.board_ids +
        "&subject=" +
        this.form.subject +
        "&is_strictly=" +
        this.form.is_strictly +
        "&created_by_id=" +
        this.form.created_by_id +
        "&is_approved=" +
        this.form.is_approved +
        "&search_keyword=" +
        this.form.search_keyword +
        "&date_filter=" +
        this.form.date_filter;
      contents = await axios.get(`infakt_contents?${query}`);
      this.contents = contents.data.data;
      this.count = contents.data.count;
      this.serialNoStarting = (this.page - 1) * this.RowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.RowsPerPage);
      this.content_types = contents.data.content_types;

      let assignments = await axios.get(`/assignments?${query}`);
      this.assignments = assignments.data.data;
      this.isLoading = false;
    },
    async generateAudio(content) {
      console.log(content.tts_audio_path);
      if (content.tts_audio_path != null && content.tts_audio_path != "") {
        let r = confirm(
          "The audio is already generated. Do you want to generate again"
        );
        if (r) {
          this.is_audio_generating = true;
          this.is_audio_generated = false;
          await axios.get(
            `/convertMaxDescriptionTextToSpeech?content_id=${content.id}&force=true`
          );
          this.is_audio_generating = false;
          this.is_audio_generated = true;
          this.getData();
        }
      } else {
        this.is_audio_generating = true;
        this.is_audio_generated = false;
        await axios.get(
          `/convertMaxDescriptionTextToSpeech?content_id=${content.id}&force=true`
        );
        this.is_audio_generating = false;
        this.is_audio_generated = true;
        this.getData();
      }
    },
  },
};
</script>
