<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="'INFAKT COLLECTIONS'"
          ></span>
        </v-col>
      </v-row>
      <loader v-if="isCollectionsLoading"></loader>
      <v-row v-else>
        <v-col
          sm="3"
          v-for="(collection, a) in collections"
          :key="`collection${a}`"
        >
          <v-card elevation="6" shaped>
            <router-link
              class="routerLink"
              :to="`/library/collections/${collection.id}`"
            >
              <v-img
                v-if="collection.featured_image_path"
                :src="`${mediaUrl + collection.featured_image_path}`"
                max-height="210"
                cover
                class="rounded-tl-xl feature"
              ></v-img>
              <v-img
                v-else
                :src="require('@/assets/imgs/collection.png')"
                max-height="210"
                cover
                class="rounded-tl-xl feature"
              ></v-img>
              <!-- src="https://cdn.vuetifyjs.com/images/cards/cooking.png" -->

              <v-card-title>
                <!-- {{ collection.collection_name }} -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <h3
                      v-if="collection.collection_name"
                      v-bind="attrs"
                      v-on="on"
                      class="wrap"
                      v-html="collection.collection_name"
                    ></h3>
                  </template>
                  <span v-html="collection.collection_name"> </span>
                </v-tooltip>
              </v-card-title>
            </router-link>
            <v-divider v-if="roleName != 'STUDENT'" class="mx-4"></v-divider>
            <v-card-actions v-if="roleName != 'STUDENT'" class="pt-0">
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    fab
                    x-small
                    color="primary"
                    @click="ShowColleciton(collection)"
                  >
                    <!-- :to="`/assignments/create?collectionId=${collection.id}`" -->
                    <v-icon>mdi-clipboard-text</v-icon>
                  </v-btn>
                </template>
                <span> Create Assignment </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Collection Assignment Dialog -->
    <v-dialog v-model="isCollectionAssigmentDialog" width="380">
      <v-card class="rounded-tl-xl rounded-br-xl">
        <v-card-title class="justify-center primary accent-1 white--text"
          >What would you like to do?
        </v-card-title>
        <v-card-text>
          <v-list rounded>
            <v-list-item-group color="primary">
              <v-list-item
                :to="`/assignments/create?collectionId=${collection_details.id}`"
              >
                <v-list-item-icon>
                  <v-icon large color="green darken-2">
                    mdi-clipboard-file
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-bold">Create New Assignment</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :to="`/collections/${collection_details.id}/assignments`"
              >
                <v-list-item-icon>
                  <v-icon large color="blue darken-2">
                    mdi-file-document-minus-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-bold"
                      >Choose Existing Assignment</span
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Collection Assignment Dialog -->
  </v-main>
</template>

<script>
import axios from "axios";
import Loader from "@/components/loader.vue";

export default {
  components: { Loader },
  name: "InfaktCollectionsPage",
  data() {
    return {
      isCollectionsLoading: false,
      collections: [],
      collection_details: {},
      isCollectionAssigmentDialog: false,
      tab: "tab-1",
    };
  },
  mounted() {
    this.getData();
    if (this.$route.query.tab) this.tab = this.$route.query.tab;
  },
  methods: {
    async getData() {
      try {
        this.isCollectionsLoading = true;
        let collections = await axios.get(
          `/collections?all_collection_by_AT_IT=1`
        );
        this.collections = collections.data.data;
        this.isCollectionsLoading = false;
      } catch (e) {
        this.isCollectionsLoading = false;
      }
    },
    ShowColleciton(collection) {
      this.isCollectionAssigmentDialog = true;
      this.collection_details = collection;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
