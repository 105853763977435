<template>
  <v-main>
    <v-container fluid>
      <SATeacherSchool
        :schoolId="company.id"
        :currentType="'Teacher'"
        :currentSubType="user.id"
      ></SATeacherSchool>

      <!-- Recent Added Section -->
      <div
        class="text-h5 font-weight-bold mt-10"
        v-text="'Recently Added'"
      ></div>
      <!-- Article -->
      <v-row v-if="articles.length || isArticlesLoading == true">
        <v-col sm="6" md="6" lg="8">
          <div
            class="text-h7 font-weight-bold mt-5"
            style="min-width: 30px"
            v-text="'Articles'"
          ></div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col sm="6" md="6" lg="4" align="right">
          <v-btn color="primary" to="/articles?type=ARTICLE"> View More</v-btn>
        </v-col>
      </v-row>
      <loader v-if="isArticlesLoading"></loader>
      <v-row>
        <template v-for="(article, a) in articles">
          <v-col
            sm="3"
            :key="`article${a}`"
            v-if="article.content_descriptions.length != 0"
          >
            <Article :article="article" :type="article.content_type"></Article>
          </v-col>
        </template>
      </v-row>
      <!-- Infographics -->
      <v-row v-if="infographics.length || isInfographicsLoading == true">
        <v-col sm="6" md="6" lg="8">
          <div
            class="text-h7 font-weight-bold mt-5"
            v-text="'Infographics'"
            style="min-width: 91px"
          ></div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col sm="6" md="6" lg="4" align="right">
          <v-btn color="primary" to="/articles?type=INFOGRAPHIC">
            View More</v-btn
          >
        </v-col>
      </v-row>
      <loader v-if="isInfographicsLoading"></loader>
      <v-row>
        <template v-for="(article, a) in infographics">
          <v-col
            sm="3"
            :key="`article${a}`"
            v-if="article.content_descriptions.length != 0"
          >
            <Article :article="article" :type="article.content_type"></Article>
          </v-col>
        </template>
      </v-row>
      <!-- Videos -->
      <v-row v-if="videos.length || isVideosLoading == true">
        <v-col sm="6" md="6" lg="8">
          <div
            class="text-h7 font-weight-bold mt-5"
            style="min-width: 30px"
            v-text="'Videos'"
          ></div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col sm="6" md="6" lg="4" align="right">
          <v-btn color="primary" to="/articles?type=VIDEO"> View More</v-btn>
        </v-col>
      </v-row>
      <loader v-if="isVideosLoading"></loader>
      <v-row>
        <template v-for="(article, a) in videos">
          <v-col
            sm="3"
            :key="`article${a}`"
            v-if="article.content_descriptions.length != 0"
          >
            <Article :article="article" :type="article.content_type"></Article>
          </v-col>
        </template>
      </v-row>
      <br />
      <div
        v-if="most_popular_articles.length || isMostPopularsLoading == true"
        class="text-h7 font-weight-bold my-4"
        v-text="'Most Popular Contents'"
      ></div>
      <loader v-if="isMostPopularsLoading"></loader>
      <v-row>
        <v-col
          sm="3"
          v-for="(article, a) in most_popular_articles"
          :key="`article${a}`"
        >
          <Article :article="article" :type="article.content_type"></Article>
        </v-col>
      </v-row>
      <!-- Collections -->
      <template v-if="collections.length">
        <!-- <div class="text-h5 font-weight-bold my-4" v-text="'Collections'"></div> -->
        <v-row v-if="collections.length || isCollectionsLoading == true">
          <v-col sm="6" md="6" lg="8">
            <div
              style="min-width: 125px"
              class="text-h7 font-weight-bold mt-3"
              v-text="'Infakt Collections'"
            ></div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col sm="6" md="6" lg="4" align="right">
            <v-btn color="primary" to="/infakt-collections"> View More</v-btn>
          </v-col>
        </v-row>
        <loader v-if="isCollectionsLoading"></loader>
        <v-row>
          <v-col
            sm="3"
            v-for="(collection, a) in collections"
            :key="`collection${a}`"
          >
            <v-card elevation="6" shaped>
              <router-link
                class="routerLink"
                :to="`/library/collections/${collection.id}`"
              >
                <!-- IF Feature Image Found -->
                <div v-if="collection.featured_image_path">
                  <v-img
                    max-height="210"
                    cover
                    class="rounded-tl-xl feature"
                    :src="`${mediaUrl + collection.featured_image_path}`"
                  ></v-img>
                </div>
                <v-img
                  v-else
                  :src="require('@/assets/imgs/collection.png')"
                  height="180"
                ></v-img>
                <v-card-title>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h3
                        v-if="collection.collection_name"
                        v-bind="attrs"
                        v-on="on"
                        class="wrap"
                        v-html="collection.collection_name"
                      ></h3>
                    </template>
                    <span v-html="collection.collection_name"> </span>
                  </v-tooltip>
                </v-card-title>
              </router-link>
              <v-divider v-if="roleName != 'STUDENT'" class="mx-4"></v-divider>

              <v-card-actions v-if="roleName != 'STUDENT'" class="pt-0">
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-1"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      fab
                      x-small
                      color="primary"
                      @click="ShowColleciton(collection)"
                    >
                      <v-icon>mdi-clipboard-text</v-icon>
                    </v-btn>
                  </template>
                  <span> Create Assignment </span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <br />
      <!-- Competition wise Assignmnets -->
      <!-- <div
        class="text-h5 font-weight-bold my-4"
        v-if="competitionAssignments.length"
        v-text="'Competition Assignmnets'"
      ></div>
     <loader  v-if="isLoading">
        </loader
      <v-row>
        <v-col
          sm="3"
          v-for="(assignment, a) in competitionAssignments"
          :key="`ca${a}`"
        >
          <v-card
            elevation="10"
            class="mx-auto"
            :color="
              assignment.category == 'COMPETITION' ? 'orange lighten-5' : ''
            "
            :height="'380'"
          >
            <v-card-title
              :class="`justify-center ${
                assignment.category == 'COMPETITION'
                  ? 'orange lighten-2'
                  : 'primary white--text'
              } accent-1`"
            >
              {{ assignment.assignment_title | limit30 }}
            </v-card-title>
            <v-card-text class="pt-3">
              <div class="row">
                <v-col>
                  <span v-if="assignment.duration">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          x-small
                          color="black"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon size="20">mdi-timer-alert</v-icon>
                        </v-btn>
                      </template>
                      <span>This is a timed assignment</span>
                    </v-tooltip>
                    ({{ assignment.duration }} min)
                  </span>
                  <span v-else> (No time limit) </span>
                </v-col>
              </div>

              <div class="row">
                <v-col align="left"
                  ><b>
                    {{ assignment.company_id != null ? "Assigned" : "Created" }}
                    By:</b
                  >
                  <br />
                  {{
                    assignment.created_by
                      ? assignment.created_by.first_name +
                        " " +
                        assignment.created_by.last_name
                      : ""
                  }}</v-col
                >
                <v-col align="center">
                  <b v-if="assignment.content_description">Lexile Level:</b>
                  <br />
                  {{
                    assignment.content_description
                      ? assignment.content_description.level
                      : ""
                  }}
                </v-col>
                <v-col align="right">
                  <b>Type:</b>
                  <br />
                  {{ assignment.assignment_type }}
                </v-col>
              </div>

              <div
                class="row"
                v-show="assignment.assignment_classcodes?.length > 0"
              >
                <v-col md="4" class="pb-0" align="left">
                  <b>Classcode:</b>
                </v-col>
                <v-col md="4" class="pb-0" align="center">
                  <b>Start Date:</b>
                </v-col>
                <v-col md="4" class="pb-0" align="right">
                  <b>Due Date:</b>
                </v-col>
              </div>
              <div
                v-show="assignment.assignment_classcodes?.length > 0"
                style="overflow-y: auto; overflow-x: hidden; height: 35px"
                class="mt-5"
              >
                /** Scrollable Div for List of Classcodes */
                <div
                  v-show="assignment.assignment_classcodes"
                  class="row"
                  v-for="(
                    assigmentClasscode, ac
                  ) in assignment.assignment_classcodes"
                  :key="`assigmentClasscode${ac}`"
                >
                  <v-col md="4" class="wrap" align="left">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ assigmentClasscode.classcode.classcode }}
                        </span>
                      </template>
                      <span>{{ assigmentClasscode.classcode.classcode }}</span>
                    </v-tooltip>
                  </v-col>
                  <div align="center" class="col-md-4">
                    {{ assigmentClasscode.start_date | ddmmyyyy }}
                  </div>

                  <div align="right" class="col-md-8">
                    {{ assigmentClasscode.end_date | ddmmyyyy }}
                  </div>
                </div>
                /** End of Scrollable Div for List of Classcodes */
              </div>
              <div class="row">
                <v-col>
                  <b>Max Marks:</b>
                  <br />
                  {{ assignment.maximum_marks }}
                </v-col>

                <v-col align="right">
                  <div v-if="assignment.content_id">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="assignment.content_description_id"
                          target="_blank"
                          class="my-auto"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          small
                          :to="`/articles/${assignment.content_id}/${assignment.content_description_id}`"
                        >
                          View
                        </v-btn>
                        <v-btn
                          v-else
                          target="_blank"
                          class="my-auto"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          small
                          :to="`/articles/${assignment.content_id}`"
                        >
                          View
                        </v-btn>
                      </template>
                      <span>View linked Article</span>
                    </v-tooltip>
                  </div>
                  <div v-if="assignment.collection_id">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          target="_blank"
                          class="my-auto"
                          small
                          color="primary"
                          :to="`/library/collections/${assignment.collection_id}?assignment_id=${assignment.id}`"
                        >
                          View
                        </v-btn>
                      </template>
                      <span>View linked Collection</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </div>
              /** For Teacher */
              <div class="row">
                <v-col
                  :class="
                    roleName == 'INFAKT TEACHER' || roleName == 'ACADEMIC TEAM'
                      ? 'pt-0'
                      : ''
                  "
                >
                  <span
                    v-if="
                      roleName == 'TEACHER' ||
                      roleName == 'ADMIN' ||
                      roleName == 'INFAKT TEACHER' ||
                      roleName == 'ACADEMIC TEAM'
                    "
                  >
                    <div class="row pb-1">
                      <v-col>
                        <v-btn
                          color="orange"
                          dark
                          :to="`/assignments/${assignment.id}/submissions`"
                        >
                          View Competition Result
                        </v-btn>
                      </v-col>
                    </div>
                  </span>
                </v-col>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
      <!-- Collection Assignment Dialog -->
      <v-dialog v-model="isCollectionAssigmentDialog" width="380">
        <v-card class="rounded-tl-xl rounded-br-xl">
          <v-card-title class="justify-center primary accent-1 white--text"
            >What would you like to do?
          </v-card-title>
          <v-card-text>
            <v-list rounded>
              <v-list-item-group color="primary">
                <v-list-item
                  :to="`/assignments/create?collectionId=${collection_details.id}`"
                >
                  <v-list-item-icon>
                    <v-icon large color="green darken-2">
                      mdi-clipboard-file
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="font-weight-bold"
                        >Create New Assignment</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  :to="`/collections/${collection_details.id}/assignments`"
                >
                  <v-list-item-icon>
                    <v-icon large color="blue darken-2">
                      mdi-file-document-minus-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="font-weight-bold"
                        >Choose Existing Assignment</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- End Collection Assignment Dialog -->
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import SATeacherSchool from "./superadmin-dashboard/teacher_school_data";
import Article from "@/components/article.vue";
import loader from "@/components/loader.vue";

export default {
  data() {
    return {
      isLoading: false,
      isArticlesLoading: false,
      isInfographicsLoading: false,
      isVideosLoading: false,
      isMostPopularsLoading: false,
      isCollectionsLoading: false,
      isCollectionAssigmentDialog: false,
      form: {},
      articles: [],
      infographics: [],
      videos: [],
      most_popular_articles: [],
      collections: [],
      competitionAssignments: [],
      collection_details: {},
      teacherId: "",
    };
  },
  components: {
    SATeacherSchool,
    Article,
    loader,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let teacherId = "";
      if (this.roleName == "TEACHER") {
        teacherId = this.user.id;
      }
      let form = await axios.get(`teacherDashboard?teacherId=${teacherId}`);
      this.form = form.data.data;
      this.competitionAssignments = form.data.data.competitionWiseAssignments;
      // Article Section
      this.isArticlesLoading = true;
      let articles = await axios.get(
        `/user_contents?type=ARTICLE&content_limit_4=true&source=DASHBOARD`
      );
      this.articles = articles.data.data;
      this.isArticlesLoading = false;
      // End Of Article Section

      // Infographic Section
      this.isInfographicsLoading = true;
      let infographics = await axios.get(
        `/user_contents?type=INFOGRAPHIC&content_limit_4=true&source=DASHBOARD`
      );
      this.infographics = infographics.data.data;
      this.isInfographicsLoading = false;
      //End of Infographic Section

      // Video Section
      this.isVideosLoading = true;
      let videos = await axios.get(
        `/user_contents?type=VIDEO&content_limit_4=true&source=DASHBOARD`
      );
      this.videos = videos.data.data;
      this.isVideosLoading = false;
      //End of Video Section

      // Most Popular Section
      this.isMostPopularsLoading = true;
      let most_popular_articles = await axios.get(`/most_popular_articles`);
      this.most_popular_articles = most_popular_articles.data.data;
      this.isMostPopularsLoading = false;
      //End of Most Popular Section

      // Collection Section
      this.isCollectionsLoading = true;
      let collections = await axios.get(
        `/collections?all_collection_by_AT_IT=1&limit=4`
      );
      this.collections = collections.data.data;
      this.isCollectionsLoading = false;
      // End of Collection Section
      this.isLoading = false;
    },
    ShowColleciton(collection) {
      this.isCollectionAssigmentDialog = true;
      this.collection_details = collection;
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
