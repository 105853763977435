<template>
  <v-main>
    <v-container
      ><!-- Recently Onboarded Quiz Assignments -->
      <br />
      <v-row justify="start">
        <v-col>
          <span
            class="text-h5 font-weight-bold"
            v-text="'Quiz Assignments'"
          ></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-tabs fixed-tabs background-color="primary" dark v-model="tab">
            <v-tab> Pending </v-tab>
            <v-tab> Completed </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat :isLoading="isLoading">
                <v-container>
                  <v-card-text>
                    <!-- <v-simple-table fixed-header>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Sr No</th>
                            <th class="text-left">Description</th>
                            <th class="text-left">Date</th>
                            <th class="text-left">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(quiz_assignment, i) in pending_quizzes"
                            :key="quiz_assignment.name"
                          >
                            <td>
                              {{ i + 1 }}
                            </td>
                            <td>{{ quiz_assignment.assignment_title }}</td>
                            <td>{{ quiz_assignment.created_at | in_days }}</td>
                            <td class="text-centre">
                              <v-tooltip
                                right
                                v-if="quiz_assignment.my_results.length"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :to="`/assignments/${quiz_assignment.id}/check-result/${quiz_assignment.my_results[0].id}`"
                                    color="green"
                                    dark
                                  >
                                    {{ quiz_assignment.my_results[0].score }}
                                    /{{ quiz_assignment.maximum_marks }}
                                  </v-btn>
                                </template>
                                <span>View Scores</span>
                              </v-tooltip>
                              <v-tooltip right v-else>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :to="`/assignments/${quiz_assignment.id}/take-assignment`"
                                    color="primary"
                                    dark
                                  >
                                    BEGIN
                                  </v-btn>
                                </template>
                                <span>Begin Test</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table> -->
                    <v-row>
                      <template v-for="quiz_assignment in pending_quizzes">
                        <v-col md="4" :key="quiz_assignment.name">
                          <v-card elevation="10" class="mx-auto">
                            <v-img
                              height="250px"
                              src="../../assets/banner-quiz.jpg"
                            >
                              <v-card-title
                                class="justify-center deep-purple accent-2 pa-2 yellow--text"
                              >
                                {{ quiz_assignment.assignment_title }}
                              </v-card-title>
                              <v-card-text class="py-16"> </v-card-text>
                              <v-card-actions class="pt-7">
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      :to="`/assignments/${quiz_assignment.id}/take-assignment`"
                                      color="yellow accent-4"
                                      class="indigo--text rounded-pill"
                                    >
                                      <h3>BEGIN</h3>
                                    </v-btn>
                                  </template>
                                  <span>Begin Test</span>
                                </v-tooltip>
                              </v-card-actions>
                            </v-img>
                          </v-card>
                        </v-col>
                      </template>
                    </v-row>
                  </v-card-text>
                </v-container>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat :isLoading="isLoading" elevation="6">
                <v-card-text>
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left sr_no_class">Sr No</th>
                          <th class="text-left">Description</th>
                          <th class="text-left">Date</th>
                          <th class="text-left">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(quiz_assignment, i) in completed_quizzes"
                          :key="quiz_assignment.name"
                        >
                          <td>
                            <v-tooltip
                              bottom
                              v-if="quiz_assignment.is_read == 1"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  dark
                                  color="green"
                                >
                                  mdi-circle
                                </v-icon>
                              </template>
                              <span>Notification already seen.</span>
                            </v-tooltip>
                            &nbsp;
                            {{ i + 1 }}
                          </td>
                          <td>{{ quiz_assignment.assignment_title }}</td>
                          <td>{{ quiz_assignment.created_at | in_days }}</td>
                          <td class="text-centre">
                            <v-tooltip
                              right
                              v-if="quiz_assignment.my_results.length"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  :to="`/assignments/${quiz_assignment.id}/check-result/${quiz_assignment.my_results[0].id}`"
                                  color="green"
                                  dark
                                >
                                  {{ quiz_assignment.my_results[0].score }} /{{
                                    quiz_assignment.maximum_marks
                                  }}
                                </v-btn>
                              </template>
                              <span>View Scores</span>
                            </v-tooltip>
                            <v-tooltip right v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  :to="`/assignments/${quiz_assignment.id}/take-assignment`"
                                  color="primary"
                                  dark
                                >
                                  BEGIN
                                </v-btn>
                              </template>
                              <span>Begin Test</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <!-- <v-col md="12">
          <v-card :isLoading="isLoading" elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Description</th>
                    <th class="text-left">Date</th>
                    <th class="text-left">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(quiz_assignment, i) in quiz_assignments"
                    :key="quiz_assignment.name"
                  >
                    <td>
                      <v-tooltip bottom v-if="quiz_assignment.is_read == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="green"
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>Notification already seen.</span>
                      </v-tooltip>
                      &nbsp;
                      {{ i + 1 }}
                    </td>
                    <td>{{ quiz_assignment.assignment_title }}</td>
                    <td>{{ quiz_assignment.created_at | in_days }}</td>
                    <td class="text-centre">
                      <v-tooltip right v-if="quiz_assignment.my_results.length">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :to="`/assignments/${quiz_assignment.id}/check-result/${quiz_assignment.my_results[0].id}`"
                            color="green"
                            dark
                          >
                            {{ quiz_assignment.my_results[0].score }} /{{
                              quiz_assignment.maximum_marks
                            }}
                          </v-btn>
                        </template>
                        <span>View Scores</span>
                      </v-tooltip>
                      <v-tooltip right v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :to="`/assignments/${quiz_assignment.id}/take-assignment`"
                            color="primary"
                            dark
                          >
                            BEGIN
                          </v-btn>
                        </template>
                        <span>Begin Test</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col> -->
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      tab: "",
      quiz_assignments: [],
      completed_quizzes: [],
      pending_quizzes: [],
      delete_quiz_assignment: false,
      is_deleted_quiz_assignment: false,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let quiz_assignments = await axios.get(`quiz_assignments`);
      this.quiz_assignments = quiz_assignments.data.data;
      this.quiz_assignments.forEach((quiz) => {
        if (quiz.my_results.length) {
          this.completed_quizzes.push(quiz);
        } else {
          this.pending_quizzes.push(quiz);
        }
      });
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
