<template>
  <section class="features white">
    <div class="py-6"></div>

    <v-container class="text-center mb-12">
      <!-- <h2
        :class="{
          'text-h3 font-weight-bold mb-3 myFont': $vuetify.breakpoint.smAndDown,
          'display-2 font-weight-bold mb-3 myFont': $vuetify.breakpoint.mdAndUp,
        }"
      > -->
      <h1
        class="font-weight-bold mb-3 myFont"
        style="font-size: 45px"
        v-if="$vuetify.breakpoint.mdAndDown"
      >
        Powerful Features <br />
      </h1>
      <h2 class="display-2 font-weight-bold mb-3 myFont" v-else>
        Powerful Features <br />
      </h2>
    </v-container>

    <v-row justify="center" v-if="$vuetify.breakpoint.mdAndDown">
      <!-- <v-carousel hide-delimiters> -->
      <v-carousel hide-delimiters height="162px" style="width: 450px">
        <!-- dashboard -->
        <v-carousel-item
          key="1"
          :src="require('../../assets/videos/new-dashboard.png')"
          style="
            margin-left: 48px;
            margin-right: 48px;
            height: 162px;
            width: 339px;
          "
        ></v-carousel-item>
        <!-- broswe -->
        <v-carousel-item
          key="2"
          :src="require('../../assets/videos/3-1.png')"
          style="
            margin-left: 48px;
            margin-right: 48px;
            height: 162px;
            width: 339px;
          "
        ></v-carousel-item>
        <!-- student article -->
        <v-carousel-item
          key="3"
          :src="require('../../assets/videos/new-student-article-3.png')"
          style="
            margin-left: 48px;
            margin-right: 52px;
            height: 162px;
            width: 339px;
          "
        ></v-carousel-item>
        <!-- Teacher article -->
        <v-carousel-item
          key="3"
          :src="require('../../assets/videos/new-teacher-article-1.png')"
          style="
            margin-left: 48px;
            margin-right: 48px;
            height: 162px;
            width: 339px;
          "
        ></v-carousel-item>
        <!-- assignment -->
        <v-carousel-item
          key="4"
          :src="require('../../assets/videos/new-assignment-2.png')"
          style="
            margin-left: 48px;
            margin-right: 48px;
            height: 162px;
            width: 339px;
          "
        ></v-carousel-item>
        <!-- annotation -->
        <v-carousel-item
          key="5"
          :src="require('../../assets/videos/new-annotation-2.png')"
          style="
            margin-left: 48px;
            margin-right: 48px;
            height: 162px;
            width: 339px;
          "
        ></v-carousel-item>
        <!-- comparison -->
        <v-carousel-item
          key="6"
          :src="require('../../assets/videos/new-comparison-1.png')"
          style="
            margin-left: 48px;
            margin-right: 48px;
            height: 162px;
            width: 339px;
          "
        ></v-carousel-item>
        <!-- detail report -->
        <v-carousel-item
          key="6"
          :src="require('../../assets/videos/new-detail-report.png')"
          style="
            margin-left: 48px;
            margin-right: 48px;
            height: 162px;
            width: 339px;
          "
        ></v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row justify="center" v-else>
      <!-- <v-carousel hide-delimiters> -->
      <v-carousel hide-delimiters height="550px" style="width: 1250px">
        <!-- dashboard -->
        <v-carousel-item
          key="1"
          :src="require('../../assets/videos/new-dashboard.png')"
          style="margin-left: 48px; margin-right: 48px"
        ></v-carousel-item>
        <!-- broswe -->
        <v-carousel-item
          key="2"
          :src="require('../../assets/videos/3-1.png')"
          style="margin-left: 48px; margin-right: 48px"
        ></v-carousel-item>
        <!-- student article -->
        <v-carousel-item
          key="3"
          :src="require('../../assets/videos/new-student-article-3.png')"
          style="margin-left: 48px; margin-right: 52px"
        ></v-carousel-item>
        <!-- Teacher article -->
        <v-carousel-item
          key="3"
          :src="require('../../assets/videos/new-teacher-article-1.png')"
          style="margin-left: 48px; margin-right: 48px"
        ></v-carousel-item>
        <!-- assignment -->
        <v-carousel-item
          key="4"
          :src="require('../../assets/videos/new-assignment-2.png')"
          style="margin-left: 48px; margin-right: 48px"
        ></v-carousel-item>
        <!-- annotation -->
        <v-carousel-item
          key="5"
          :src="require('../../assets/videos/new-annotation-2.png')"
          style="margin-left: 48px; margin-right: 48px"
        ></v-carousel-item>
        <!-- comparison -->
        <v-carousel-item
          key="6"
          :src="require('../../assets/videos/new-comparison-1.png')"
          style="margin-left: 48px; margin-right: 48px"
        ></v-carousel-item>
        <!-- detail report -->
        <v-carousel-item
          key="6"
          :src="require('../../assets/videos/new-detail-report.png')"
          style="margin-left: 48px; margin-right: 48px"
        ></v-carousel-item>
      </v-carousel>
    </v-row>

    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.myFont {
  font-family: "SF PRO" !important;
  color: black;
}
</style>
