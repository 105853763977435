<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 130px">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Class Wise Performance Report'"
          ></span>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="standard"
            v-model="form.standard_id"
            :items="standardItems"
            label="Standard"
            :error-messages="errors.standard_id"
            clearable
            @change="getStandardSections"
            prepend-icon="mdi-alarm-bell"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="section"
            v-model="form.section_id"
            @change="getSectionClasscodes"
            :items="sectionItems"
            clearable
            :error-messages="errors.section_id"
            label="Section"
            prepend-icon="mdi-account-school"
          ></v-autocomplete>
        </v-col>
        <v-col></v-col>
        <v-col class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <!--/ Filters Section -->

      <!-- Reports Section -->
      <loader v-if="isLoading"></loader>
      <div v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-actions>
              <v-spacer></v-spacer>
              <download-excel
                v-show="report_excel.length"
                :title="title"
                :data="report_excel"
                :fields="excel_fields"
                worksheet="Subject Wise Report"
                name="Subject-Wise-Report.xls"
              >
                <v-btn color="primary">
                  <v-icon size="20">mdi-microsoft-excel</v-icon> &nbsp;Download
                </v-btn>
              </download-excel>
            </v-card-actions>
            <v-card-text>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="min-width: 56px">Sr No</th>
                      <th class="text-center" style="min-width: 65px">Name</th>
                      <th
                        style="min-width: 150px"
                        class="text-center"
                        v-for="(classcode, c) in classcodes"
                        :key="`classcode_${c}`"
                      >
                        <classcode-element
                          v-if="classcode.name"
                          :classcode="classcode.name"
                          :type="
                            roleName == 'STUDENT'
                              ? 'SUBJECT'
                              : roleName == 'TEACHER'
                              ? 'STANDARD_SUBJECT'
                              : ''
                          "
                        ></classcode-element>
                        <br />
                        Score [%]
                      </th>
                      <th class="text-center" style="min-width: 75px">
                        Submission Rate [%]
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(student, i) in class_wise_performance_report"
                      :key="student.name"
                    >
                      <td>{{ i + 1 }}</td>
                      <td class="text-center" style="min-width: 105px">
                        {{ student.name }}
                      </td>
                      <td
                        class="text-center"
                        v-for="(classcode, c) in student.classcodeWiseOverview"
                        :key="`classcode_${c}`"
                      >
                        {{ classcode.myAveragePercent | twoDec }} %
                      </td>
                      <td class="text-center">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"
                              >{{
                                student.submission_rate_percent | twoDec
                              }}
                              %</span
                            >
                          </template>
                          <span> [ Total Attempted / Total Assignment ] </span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  name: "ClassWisePerformanceReport",
  components: { loader },
  data() {
    return {
      form: {
        section_id: "",
        standard_id: "",
        classcode_id: "",
      },
      isLoading: false,
      students: [],
      standards: [],
      standardItems: [],
      sections: [],
      sectionItems: [],
      classcodes: [],
      classcodeItems: [],

      class_wise_performance_report: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
      },
      report_excel: [],
      title: "Subject Wise Report",
    };
  },
  mounted() {
    this.form.standard_id = this.$route.query.standard_id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      this.isLoading = true;
      let masters = await axios.get("users/masters");
      masters = masters.data;
      this.standards = masters.standards;
      this.sections = masters.sections;
      // this.classcodes = masters.classcodes;
      // Standard
      this.standards.forEach((standard) => {
        if (this.$route.query.standard_id == standard.id) {
          this.standardItems.push({
            id: standard.id,
            text: standard.name,
            value: this.$route.query.standard_id,
          });
        }
        if (this.$route.query.standard_id != standard.id) {
          this.standardItems.push({
            id: standard.id,
            text: standard.name,
            value: standard.id,
          });
        }
      });
      // Section
      this.sections.forEach((section) => {
        this.sectionItems.push({
          id: section.id,
          text: section.name,
          value: section.id,
        });
      });
      // ClassCodes
      this.classcodes.forEach((classcode) => {
        if (this.$route.params.classcodeId == classcode.id) {
          this.classcodeItems.push({
            id: classcode.id,
            text: classcode.subject_name,
            value: this.$route.params.classcodeId,
          });
        }
        if (this.$route.params.classcodeId != classcode.id) {
          this.classcodeItems.push({
            id: classcode.id,
            text: classcode.subject_name,
            value: classcode.id,
          });
        }
      });
      this.isLoading = false;
    },
    async getData() {
      this.isLoading = true;
      this.classcodes = [];
      this.class_wise_performance_report = [];
      try {
        this.form.standard_id = this.form.standard_id
          ? this.form.standard_id
          : "";
        this.form.section_id = this.form.section_id ? this.form.section_id : "";
        this.form.classcode_id = this.form.classcode_id
          ? this.form.classcode_id
          : "";
        let class_wise_performance_report = [];
        let query =
          "role_id=5" +
          "&standard_id=" +
          this.form.standard_id +
          "&section_id=" +
          this.form.section_id +
          "&classcode_id=" +
          this.form.classcode_id;
        class_wise_performance_report = await axios.get(
          `/class_wise_performance_report?${query}`
        );
        this.class_wise_performance_report =
          class_wise_performance_report.data.data;
        this.classcodes =
          this.class_wise_performance_report[0].classcodeWiseOverview;
        this.class_wise_performance_report.forEach((student) => {
          let submission_rate = 0;
          let classcount = student.classcodeWiseOverview.length;
          student.classcodeWiseOverview.forEach((classcode) => {
            submission_rate += classcode.completed_average_percent;
          });
          student.submission_rate_percent =
            submission_rate != 0 ? submission_rate / classcount : 0;
        });
        this.exportToExcel();
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async getStandardSections() {
      this.sectionItems = [];
      this.classcodeItems = [];
      this.form.classcode_id = "";
      let standardsections = this.sections.filter(
        (section) => section.standard_id == this.form.standard_id
      );
      standardsections.forEach((ss) => {
        this.sectionItems.push({
          id: ss.id,
          text: ss.name,
          value: ss.id,
        });
      });
    },
    async getSectionClasscodes() {
      this.classcodeItems = [];
      let sectionclasscodes = this.classcodes.filter(
        (classcode) => classcode.section_id == this.form.section_id
      );
      sectionclasscodes.forEach((sc) => {
        this.classcodeItems.push({
          id: sc.id,
          text: sc.subject_name,
          classcode: sc.classcode,
          value: sc.id,
        });
      });
      console.log(this.classcodeItems);
    },
    exportToExcel() {
      this.report_excel = [];
      let sr_no = 1;

      this.class_wise_performance_report[0].classcodeWiseOverview.forEach(
        (classcode) => {
          this.excel_fields[`${classcode.name}`] = `${classcode.name}`;
        }
      );
      this.excel_fields["Submission Rate [%]"] = "submission_rate";
      console.log(this.excel_fields);
      this.class_wise_performance_report.forEach((student) => {
        let student_report = {
          sr_no: sr_no,
          name: student.name ? student.name : "",
        };
        student.classcodeWiseOverview.forEach((classcode) => {
          student_report[`${classcode.name}`] =
            classcode.myAveragePercent + " %";
        });

        student_report["submission_rate"] =
          student.submission_rate_percent.toFixed() + " %";
        this.report_excel.push(student_report);
        sr_no++;
      });
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
