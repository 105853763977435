<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Add a manual detail
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <v-text-field
                outlined
                v-model="form.detail_title"
                label="Title *"
                prepend-inner-icon="mdi-shape"
                :error-messages="errors.detail_title"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="form.detail_description"
              ></froala>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col sm="12">
              <input type="file" id="file" name="file" ref="file" />
            </v-col>
          </v-row> -->
          <br />
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save">
            Save Manual Details</v-btn
          >
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "ManualCreatePage",
  components: { backbutton, Loader },
  data() {
    return {
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      form: {
        title: "",
        description: "",
        manual_id: "",
      },
      manual_detail: {},
      isLoading: false,
    };
  },
  mounted() {
    this.form.manual_id = this.$route.params.manualId;
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        let manual_detail = await axios.post(`/manual_details`, this.form);
        this.manual_detail = manual_detail.data.data;
        // this.handleFileUpload();
        this.isLoading = false;
        this.$router.push(
          `/manuals/${this.$route.params.manualId}/manual_details`
        );
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      let attachment = this.$refs.file?.files[0];
      const manual_detailId = this.manual_detail.id;
      let formData = new FormData();
      formData.append("id", manual_detailId);
      formData.append("detail_image_path", attachment);
      await axios
        .post("upload_manual_detail_imagepath", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      if (this.career_request) {
        this.success = true;
        this.form = [];
      }
      this.isLoading = false;
    },
  },
};
</script>
