<template>
  <div>
    <v-card elevation="6" shaped>
      <router-link
        class="routerLink"
        :target="platform == 'PC' ? `_blank` : ''"
        :to="`/articles/${article.id}`"
      >
        <!-- IF Feature Image Found -->
        <div v-if="article.featured_image_path">
          <v-img
            max-height="210"
            cover
            class="rounded-tl-xl feature"
            :src="`${mediaUrl + article.featured_image_path}`"
          ></v-img>
        </div>
        <!-- IF Feature Image NOT Found THEN Check For Subject IMage -->
        <div
          v-else-if="
            !article.featured_image_path && article.subject_image != ''
          "
        >
          <v-img
            max-height="210"
            cover
            class="rounded-tl-xl subject"
            :src="`${mediaUrl + article.subject_image}`"
          ></v-img>
        </div>
        <!-- IF BOTH Feature Image AND Subject Image NOT Found THEN Show Default Image -->
        <div v-else>
          <v-img
            v-if="type == 'ARTICLE'"
            class="rounded-tl-xl feature"
            max-height="210"
            cover
            :src="require('../assets/imgs/content-1.png')"
          ></v-img>
          <v-img
            v-if="type == 'INFOGRAPHIC'"
            class="rounded-tl-xl feature"
            max-height="210"
            cover
            :src="require('../assets/imgs/infography-1.png')"
          ></v-img>
          <v-img
            v-if="type == 'VIDEO'"
            class="rounded-tl-xl feature"
            max-height="210"
            cover
            :src="require('../assets/imgs/video-1.png')"
          ></v-img>
        </div>
      </router-link>
      <v-card-text class="pb-0">
        <router-link
          class="routerLink"
          target="_blank"
          :to="`/articles/${article.id}`"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <h3
                v-if="article.content_descriptions[0].title"
                v-bind="attrs"
                v-on="on"
                class="wrap"
                v-html="
                  article.content_descriptions
                    ? article.content_descriptions[0].title
                    : article.content_name
                "
              ></h3>
              <br v-else />
            </template>
            <span
              v-html="
                article.content_descriptions
                  ? article.content_descriptions[0].title
                  : article.content_name
              "
            >
            </span>
          </v-tooltip>
        </router-link>
        <div class="mt-1 mb-1 wrap">
          <span v-if="article.content_type != 'VIDEO'">
            {{ article.written_by_name || "--" }}
            |
            {{ article.reading_time || "--" }} mins read
          </span>
          <span v-else>
            {{ article.reading_time || "--" }} mins viewing time
          </span>
        </div>
        <div class="wrap">
          {{ article.publication || "--" }} | Adapted by Pousse Team
        </div>
        <div class="wrap">
          <b>Posted On: </b>{{ article.created_at | ddmmyyyy }} |
          <!-- Filtered Content reads are the Content read for Most Read Article Logic
          i.e content read for the last week & by the Students of that school -->
          <template v-if="article.filtered_content_reads">
            {{ article.filtered_content_reads.length }}</template
          >
          <template v-else>{{ article.content_reads.length }}</template>
          <span v-if="article.content_type == 'VIDEO'"> views </span>
          <span v-else> reads </span>
        </div>
        <!-- <span class="smallScreen"> Small < 800 </span>
        <span class="midScreen"> 800 < MID > 1250 </span>
        <span class="largeScreen"> 1300 < Larger </span> -->
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="pt-0 mr-5">
        &nbsp;&nbsp;
        <p class="largeScreen">
          {{ article.content_type | capitalize }}
          <b class="primary--text"> {{ article.id }}</b>
        </p>
        <p class="midScreen" style="font-size: smaller">
          <span class="wrap">{{ article.content_type | capitalize }}</span>
          <b class="primary--text"> {{ article.id }}</b>
        </p>
        <p class="smallScreen" style="font-size: x-small">
          <span class="wrap">{{ article.content_type | capitalize }}</span>
          <b class="primary--text"> {{ article.id }}</b>
        </p>
        <v-spacer></v-spacer>
        <!-- Delete Article from Collection Button -->
        <v-tooltip bottom v-if="CanDelete()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="my-auto"
              color="error"
              @click="Delete_Collection_Article(article.id)"
            >
              <v-icon dark size="38"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Delete this Article from Collection</span>
        </v-tooltip>
        <!-- /Delete Article from Collection Button -->

        <!-- Add Bookmark Component -->
        <bookmark-dialog
          v-if="source != 'bookmark' && article.is_Enable_bookmark != false"
          :article="article"
        ></bookmark-dialog>
        <!-- /Add Bookmark Component -->

        <!-- Delete Bookmark Button -->
        <v-tooltip bottom v-if="source == 'bookmark'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="my-auto"
              color="error"
              @click="Delete_Bookmark(bookmark_id)"
            >
              <v-icon dark size="38"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Delete this Bookmark</span>
        </v-tooltip>
        <!-- /Delete Bookmark Button -->
        <!-- Delete Bookmark & Delete Article from Colleciton Success Dialog Box -->
        <v-dialog v-model="is_deleted_bookmark" width="600">
          <v-card shaped>
            <v-container>
              <v-row align="center">
                <v-col sm="3" align="center">
                  <v-icon
                    large
                    style="
                      color: green;
                      font-size: 90px;
                      width: 200px !important;
                    "
                  >
                    mdi-delete-empty
                  </v-icon>
                </v-col>
                <v-col sm="9" align="center">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <h1 style="color: green">Hurray!</h1>
                        <br />
                        <h3>Removed Article successfully.</h3>
                        <br />
                        <v-btn
                          color="success"
                          @click="(is_deleted_bookmark = false), $router.go(0)"
                        >
                          OK
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <!-- /Delete Bookmark Success Dialog Box -->
        <!-- Only one Article Left Dialog Box -->
        <v-dialog v-model="isOnlyArticleLeftDialog" width="600">
          <v-card shaped>
            <v-container>
              <v-row align="center">
                <v-col sm="3" align="center">
                  <v-icon
                    large
                    style="color: red; font-size: 90px; width: 200px !important"
                  >
                    mdi-alert-outline
                  </v-icon>
                </v-col>
                <v-col sm="9" align="center">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <h1 style="color: red">Oops!</h1>
                        <br />
                        <h3>
                          It seems you're attempting to remove the last
                          remaining article from this collection. At least one
                          article must be present in the collection.
                        </h3>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <!-- /Delete Bookmark Success Dialog Box -->
        <!-- Info Button -->
        <v-btn
          icon
          color="primary"
          class="ml-0"
          @click="isInfoBoxDialog = true"
        >
          <v-img
            height="35"
            width="10"
            :src="require('../assets/logo-i.png')"
            contain
          ></v-img>
        </v-btn>
        <!-- /Info Button -->
        <!-- Info Book Pop UP  -->
        <v-dialog v-model="isInfoBoxDialog" max-width="400px">
          <v-card elevation="2" class="rounded-tl-xl rounded-br-xl no-select">
            <v-card-title class="justify-center primary accent-1 white--text">
              Info Box
            </v-card-title>
            <v-card-text>
              <v-row v-if="isInfoBoardPresent()">
                <v-tabs
                  background-color="blue-grey lighten-4"
                  class="mt-5"
                  color="primary"
                  fixed-tabs
                  height="35"
                  v-model="boardTab"
                >
                  <!-- Board Tabs -->
                  <v-tab
                    v-for="info_board in article.content_info_boards?.filter(
                      (cib) => cib.content_info_board_details.length
                    )"
                    :key="`info_board_${info_board.id}`"
                  >
                    {{ info_board.board.name }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="boardTab">
                  <!-- Board Tab Content -->
                  <v-tab-item
                    v-for="info_board in article.content_info_boards?.filter(
                      (cib) => cib.content_info_board_details.length
                    )"
                    :key="`info_board_${info_board.id}`"
                  >
                    <v-card flat>
                      <v-card-text>
                        <v-tabs
                          center-active
                          color="primary"
                          height="25"
                          next-icon="mdi-arrow-right-bold-box-outline"
                          prev-icon="mdi-arrow-left-bold-box-outline"
                          fixed-tabs
                        >
                          <!-- Grade Tabs -->
                          <v-tab
                            v-for="grade in info_board.grade_details.filter(
                              (g) => g.subjects.length
                            )"
                            :key="`grade_${info_board.id}_${grade.grade}`"
                          >
                            {{ grade.grade }}
                          </v-tab>
                          <!-- Grade Tab Content -->
                          <v-tab-item
                            v-for="grade in info_board.grade_details.filter(
                              (g) => g.subjects.length
                            )"
                            :key="`grade_${info_board.id}_${grade.grade}`"
                          >
                            <v-card flat style="min-width: 345px">
                              <v-card-text>
                                <v-tabs
                                  background-color="blue-grey lighten-5"
                                  center-active
                                  height="35"
                                  next-icon="mdi-arrow-right-bold-box-outline"
                                  prev-icon="mdi-arrow-left-bold-box-outline"
                                  fixed-tabs
                                >
                                  <!-- Subject Tabs -->
                                  <v-tab
                                    v-for="subject in grade.subjects"
                                    :key="`subject_${grade.grade}_${subject.id}`"
                                    style="min-width: 170"
                                  >
                                    {{ subject.subject }}
                                    <v-icon class="ml-1">{{
                                      subjectIcon(subject.subject)
                                    }}</v-icon>
                                  </v-tab>
                                  <!-- Subject Tab Content -->
                                  <v-tab-item
                                    v-for="subject in grade.subjects"
                                    :key="`subject_${grade.grade}_${subject.id}`"
                                    class="mt-3"
                                  >
                                    <v-row v-if="subject.chapter">
                                      <v-col>
                                        <b>Chapter:</b>
                                        <div v-html="subject.chapter"></div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col>
                                        <b>Learning Outcome:</b>
                                        <div
                                          v-html="subject.learning_outcome"
                                        ></div>
                                      </v-col>
                                    </v-row>
                                  </v-tab-item>
                                </v-tabs>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                        </v-tabs>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-row>
              <v-row v-else>
                <v-col class="mt-5">
                  <h1 style="color: red">Oops!</h1>
                  <br />
                  <h3>
                    Sorry, Looks like there is no info added for this Article.
                  </h3>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- /Info Book Pop UP  -->

        <!-- Article Assign to Read Button -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="orange"
              v-bind="attrs"
              v-on="on"
              v-if="roleName == 'STUDENT' && article.assign_to_read == true"
            >
              <v-icon size="38">mdi-read</v-icon>
            </v-btn>
          </template>
          <span>This article has been assigned for you to read.</span>
        </v-tooltip>
        <!-- /Article Assign to Read Button -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import BookmarkDialog from "./BookmarkDialog.vue";
export default {
  components: { BookmarkDialog },
  data() {
    return {
      form: {
        subject_id: "",
      },
      dialog: false,
      delete_bookmark: false,
      is_deleted_bookmark: false,
      isOnlyArticleLeftDialog: false,
      isInfoBoxDialog: false,
      tabs: [],
      boardTab: null,
    };
  },
  props: ["article", "source", "type", "bookmark_id", "collection"],
  methods: {
    async Delete_Bookmark(id) {
      this.delete_bookmark = true;
      let is_deleted = await axios.delete(`/bookmarks/${id}`);
      if (is_deleted) {
        this.delete_bookmark = false;
        this.is_deleted_bookmark = true;
      }
    },
    async Delete_Collection_Article(id) {
      if (
        this.collection.collection_contents.length > 1 ||
        this.roleName == "STUDENT"
      ) {
        this.delete_collection_article = true;
        this.is_deleted_bookmark = false;
        let cc = this.collection.collection_contents.find(
          (cc) => cc.content_id == id
        );

        let is_deleted = await axios.delete(`/collection_contents/${cc.id}`);
        if (is_deleted) {
          this.delete_collection_article = false;
          this.is_deleted_bookmark = true;
        }
      } else {
        this.isOnlyArticleLeftDialog = true;
      }
    },
    CanDelete() {
      var response = false;
      if (
        this.source == "collection" && // if Source is Collection
        this.collection && // Collection data is present
        (this.user.id == this.collection.user_id || // User can delete Article from His Collection
          this.roleName == "ACADEMIC TEAM") && // OR User Role is Academic Team
        ((this.roleName == "INFAKT TEACHER" && this.collection.status != 1) || // If User Role is Infakt Teacher then Only Not Approved Collection's Article can be deleted
          this.roleName == "ACADEMIC TEAM" || // OR User Role is Academic Team
          this.roleName == "TEACHER" || // OR User Role is Academic Team
          this.roleName == "STUDENT") // OR User Role is Academic Team
      ) {
        response = true;
      }
      return response;
    },
    isInfoBoardPresent() {
      if (this.article.filtered_content_info_boards) {
        this.article.content_info_boards =
          this.article.filtered_content_info_boards;
      }
      let info_board = this.article.content_info_boards?.filter(
        (cib) => cib.content_info_board_details.length > 0
      );
      return info_board.length;
    },
    subjectIcon(subject) {
      let icon = "";
      switch (subject) {
        case "English":
          // icon = "game-icons:materials-science";
          icon = "mdi-book-alphabet";
          break;
        case "History":
          icon = "mdi-pillar";
          break;
        case "Geography":
          icon = "mdi-globe-model";
          break;
        case "Science":
          icon = "mdi-flask-outline";
          break;
        case "Civics":
          icon = "mdi-mother-heart";
          break;
        case "Biology":
          icon = "mdi-bacteria-outline";
          break;
        case "Physics":
          icon = "mdi-rocket-launch-outline";
          break;
        case "Chemistry":
          icon = "mdi-test-tube";
          break;
        case "Economics":
          icon = "mdi-cash-100";
          break;
        case "Humanities":
          icon = "mdi-meditation";
          break;
        case "Hindi":
          icon = "mdi-abugida-devanagari";
          break;
        case "Social Studies":
          icon = "mdi-handshake-outline";
          break;
        case "Commerce":
          icon = "mdi-cash-sync";
          break;
        default:
          break;
      }
      return icon;
    },
  },
};
</script>
<style scoped>
.v-image__image {
  margin-top: 21px;
}
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media (min-width: 1270px) {
  /* 1270 & Above */
  .midScreen {
    display: none;
  }
  .smallScreen {
    display: none;
  }
}
@media (min-width: 800px) and (max-width: 1270px) {
  /* 800 --  1270*/
  /* 1200 --  1300*/
  .largeScreen {
    display: none;
  }
  .smallScreen {
    display: none;
  }
}
@media (max-width: 800px) {
  /* 800 & less*/
  .largeScreen {
    display: none;
  }
  .midScreen {
    display: none;
  }
}
</style>
