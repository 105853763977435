<template>
  <v-main>
    <v-col></v-col>
    <v-col></v-col>
    <v-col></v-col>
    <v-row>
      <v-col md="1"></v-col>
      <v-col md="11">
        <span class="text-h3 font-weight-bold">
          <span class="primaryColor">Available Jobs </span>
        </span>
        <br />
        <span class="text-h4 font-weight-bold">We have a diverse team </span>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col md="3">
        <v-card-text>
          <v-row>
            <template>
              <v-col sm="12">
                <v-card elevation="20" shaped>
                  <v-col>
                    <v-card-title class="justify-center"
                      >PRODUCT HEAD &nbsp;
                      <v-icon large style="color: blue !important">
                        mdi-arrow-top-right-thin-circle-outline
                      </v-icon>
                    </v-card-title>
                  </v-col>
                  <v-divider class="mx-4"></v-divider>
                  <v-col style="background-color: blue">
                    <v-card-title class="justify-center" style="color: white"
                      >Monitor student development with the help of our
                      reports.</v-card-title
                    >
                  </v-col>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-col>
      <v-col md="3">
        <v-card-text>
          <v-row>
            <template>
              <v-col sm="12">
                <v-card elevation="20" shaped>
                  <v-col>
                    <v-card-title class="justify-center"
                      >TECH HEAD &nbsp;
                      <v-icon large style="color: blue !important">
                        mdi-arrow-top-right-thin-circle-outline
                      </v-icon>
                    </v-card-title>
                  </v-col>
                  <v-divider class="mx-4"></v-divider>
                  <v-col style="background-color: blue">
                    <v-card-title class="justify-center" style="color: white"
                      >Monitor student development with the help of our
                      reports.</v-card-title
                    >
                  </v-col>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-col>
      <v-col md="3">
        <v-card-text>
          <v-row>
            <template>
              <v-col sm="12">
                <v-card elevation="20" shaped>
                  <v-col>
                    <v-card-title class="justify-center"
                      >CONSULTANTS &nbsp;
                      <v-icon large style="color: blue !important">
                        mdi-arrow-top-right-thin-circle-outline
                      </v-icon>
                    </v-card-title>
                  </v-col>
                  <v-divider class="mx-4"></v-divider>
                  <v-col style="background-color: blue">
                    <v-card-title class="justify-center" style="color: white"
                      >Monitor student development with the help of our
                      reports.</v-card-title
                    >
                  </v-col>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col md="3">
        <v-card-text>
          <v-row>
            <template>
              <v-col sm="12">
                <v-card elevation="20" shaped>
                  <v-col>
                    <v-card-title class="justify-center"
                      >PRODUCT HEAD &nbsp;
                      <v-icon large style="color: blue !important">
                        mdi-arrow-top-right-thin-circle-outline
                      </v-icon>
                    </v-card-title>
                  </v-col>
                  <v-divider class="mx-4"></v-divider>
                  <v-col style="background-color: blue">
                    <v-card-title class="justify-center" style="color: white"
                      >Monitor student development with the help of our
                      reports.</v-card-title
                    >
                  </v-col>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-col>
      <v-col md="3">
        <v-card-text>
          <v-row>
            <template>
              <v-col sm="12">
                <v-card elevation="20" shaped>
                  <v-col>
                    <v-card-title class="justify-center"
                      >TECH HEAD &nbsp;
                      <v-icon large style="color: blue !important">
                        mdi-arrow-top-right-thin-circle-outline
                      </v-icon>
                    </v-card-title>
                  </v-col>
                  <v-divider class="mx-4"></v-divider>
                  <v-col style="background-color: blue">
                    <v-card-title class="justify-center" style="color: white"
                      >Monitor student development with the help of our
                      reports.</v-card-title
                    >
                  </v-col>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-col>
      <v-col md="3">
        <v-card-text>
          <v-row>
            <template>
              <v-col sm="12">
                <v-card elevation="20" shaped>
                  <v-col>
                    <v-card-title class="justify-center"
                      >CONSULTANTS &nbsp;
                      <v-icon large style="color: blue !important">
                        mdi-arrow-top-right-thin-circle-outline
                      </v-icon>
                    </v-card-title>
                  </v-col>
                  <v-divider class="mx-4"></v-divider>
                  <v-col style="background-color: blue">
                    <v-card-title class="justify-center" style="color: white"
                      >Monitor student development with the help of our
                      reports.</v-card-title
                    >
                  </v-col>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
    <!-- Form -->
    <v-row>
      <v-col md="1"></v-col>
      <v-col md="10">
        <!-- <v-container> -->
        <v-card elevation="20" shaped>
          <v-card-title class="text-h2 font-weight-bold" style="padding: 50px">
            <!-- <v-icon large color="red darken-2"> mdi-chat-question </v-icon> -->
            &nbsp; <span>Fill out the below form </span>&nbsp;<span
              class="primaryColor"
              >to reach out</span
            >
          </v-card-title>
          <v-row>
            <v-col md="5" style="padding-left: 50px">
              <v-card-title class="font-weight-bold">
                <v-icon large style="color: blue !important">
                  mdi-map-marker
                </v-icon>
                &nbsp;
                <address>
                  <span style="font-size: xx-large">Address</span> <br />
                  340 JJ road, Warden house, Byculla, <br />
                  Mumbai 400008. <br />
                </address>
              </v-card-title>
              <v-card-title class="font-weight-bold">
                <v-icon large style="color: blue !important">
                  mdi-phone
                </v-icon>
                &nbsp;
                <address>
                  <span style="font-size: xx-large">Phone</span> <br />
                  +91-9324961199
                </address>
              </v-card-title>
              <v-card-title class="font-weight-bold">
                <v-icon large style="color: blue !important">
                  mdi-email
                </v-icon>
                &nbsp;
                <address>
                  <span style="font-size: xx-large">Email</span> <br />
                  onboardme@infakt.co.in
                </address>
              </v-card-title>
            </v-col>
            <v-col md="7" style="padding-right: 50px">
              <v-col>
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      outlined
                      v-model="form.name"
                      label="Enter your name"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      outlined
                      v-model="form.email"
                      label="Enter your email address"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      outlined
                      v-model="form.phone_no"
                      label="Enter your contact number"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      outlined
                      v-model="form.interested_in"
                      label="Enter your interested in"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-textarea
                  outlined
                  v-model="form.description"
                  label=""
                ></v-textarea>
                <v-row>
                  <v-col md="4">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      ref="file"
                      accept="application/ms-excel"
                      multiple
                    />
                  </v-col>
                  <v-col md="2">
                    <v-btn color="primary" dark @click="save"> Send </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
          <v-card-text> </v-card-text>
        </v-card>
        <!-- </v-container> -->
      </v-col>
      <v-col md="1"></v-col>
    </v-row>
    <br />
    <!-- Footer -->
    <v-footer dark padless>
      <v-card class="flex" flat style="background-color: #e88774 !important">
        <v-card-title class="teal" style="background-color: blue !important">
          <strong class="subheading"
            >Get connected with us on social networks!</strong
          >

          <v-spacer></v-spacer>

          <v-btn v-for="icon in icons" :key="icon" class="mx-4" dark icon>
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>Infakt</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <!-- Success Dialog Box -->
    <v-dialog v-model="success" width="600">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: green; font-size: 90px; width: 200px !important"
              >
                mdi-check-outline
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">Thank You!</h1>
                    <br />
                    <h3>We will get back to you as soon as possible.</h3>
                    <br />
                    <v-btn
                      color="success"
                      @click="(success = false), $router.go(0)"
                    >
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
      form: {
        name: "",
        email: "",
        description: "",
        phone_no: "",
        interested_in: "",
        attachment: "",
      },
      success: false,
      isLoading: false,
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        let career_request = await axios.post(`/career_requests`, this.form);
        this.career_request = career_request.data.data;
        this.handleFileUpload();
        this.isLoading = false;
        this.$router.push(`/careers`);
      } catch (e) {
        this.isLoading = false;
      }
    },

    async handleFileUpload() {
      this.isLoading = true;
      let attachment = this.$refs.file?.files[0];
      const careerid = this.career_request.id;
      let formData = new FormData();
      formData.append("id", careerid);
      formData.append("attachment", attachment);
      await axios
        .post("upload_career_attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      if (this.career_request) {
        this.success = true;
        this.form = [];
      }
      this.isLoading = false;
      // if (this.career_request) {
      //   this.success = true;
      //   this.form = [];
      // }
      // this.getData();
    },
  },
};
</script>
