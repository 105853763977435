export default {
    namespaced: true,
    state: {
      baseURL: null,
      mediaURL: null,
      rowsPerPage: 200,
      GOOGLE_APPLICATION_CREDENTIALS: "/Users/jhanu/Downloads/infakt-website-312eeb3943c2.json",
    },
    getters: {
      getBaseUrl(state) {
        return state.baseURL
      },
      getMediaUrl(state) {
        return state.mediaURL
      },
      getRowsPerPage(state) {
        return state.rowsPerPage
      },
      getGOOGLE_APPLICATION_CREDENTIALS(state) {
        return state.GOOGLE_APPLICATION_CREDENTIALS;
      },
    },
    mutations: {
      SET_BASE_URL(state, data) {
        state.baseURL = data
      },
      SET_MEDIA_URL(state, data) {
        state.mediaURL = data
      },
    },
    actions: {
      setBaseUrl({ commit }, url) {
        commit('SET_BASE_URL', url)
      },
      setMediaUrl({ commit }, url) {
        commit('SET_MEDIA_URL', url)
      }
    }
  }