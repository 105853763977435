<template>
  <v-main>
    <v-container>
      <loader v-if="isLoading"></loader>
      <v-card elevation="6" style="overflow: auto">
        <v-card-title style="overflow: auto; min-width: max-content">
          <backbutton v-if="!source"></backbutton>
          {{ user_assignment.assignment.assignment_title }}
          <span
            v-if="
              user_assignment.assignment.assignment_type != 'DOCUMENT' &&
              user_assignment.assignment.duration
            "
          >
            ({{ user_assignment.assignment.duration }}
            mins)
          </span>
        </v-card-title>
        <v-card-text style="min-width: max-content">
          <p>
            Assignment Type: {{ user_assignment.assignment.assignment_type }}
          </p>
          <p class="font-weight-bold mb-0"><u>TSI Instructions:</u></p>
          <p v-html="user_assignment.assignment.student_instructions"></p>
        </v-card-text>
      </v-card>
      <br />
      <!-- Analysis -->
      <v-card elevation="6">
        <v-card-text>
          <v-row style="overflow: auto">
            <v-col sm="3" md="3" lg="3" style="min-width: 150">
              <h4>
                Name: {{ user_assignment.user.first_name }}
                {{ user_assignment.user.last_name }}
              </h4>
              <h4>Board: {{ user_assignment.user?.board?.name }}</h4>
            </v-col>
            <v-col
              sm="6"
              md="6"
              lg="6"
              align="center"
              style="min-width: max-content"
            >
              <h1>
                <span class="font-weight-black">Score: </span>
                <span class="green--text">{{ user_assignment.score }}</span> /
                <span class="red--text">{{
                  user_assignment.assignment.maximum_marks
                }}</span>
              </h1>
            </v-col>
            <v-col sm="3" md="3" lg="3" align="right" style="min-width: 140">
              <h4>
                Submitted On: {{ user_assignment.submission_date | ddmmyyyy }}
                <br />
                {{ user_assignment.submission_date | HM }}
              </h4>
            </v-col>
          </v-row>
          <v-divider class="my-3"> </v-divider>
          <v-row style="overflow: auto">
            <v-col align="center">
              <div
                :class="`${color} darken-2 v-card--reveal text-h2 white--text text-center`"
                style="height: 100%; min-width: 260px"
              >
                {{
                  parseFloat(
                    (user_assignment.score * 100) /
                      user_assignment.assignment.maximum_marks
                  ).toFixed(2)
                }}
                % Scored
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="2" md="2" lg="2" style="overflow: auto">
              <p class="font-weight-bold" color="" style="min-width: 100">
                Total Questions: {{ user_assignment.total_questions }}
              </p>
            </v-col>
            <v-col
              sm="12"
              md="10"
              lg="8"
              v-if="user_assignment.assignment.assignment_type == 'OBJECTIVE'"
            >
              <v-row>
                <v-col sm="4" md="4" lg="4">
                  <v-row>
                    <v-col sm="9" md="8" lg="8">
                      <v-textarea
                        style="font-weight: bold"
                        label=" Questions Answered:"
                        auto-grow
                        hide-details
                        outlined
                        dense
                        disabled
                        :value="user_assignment.answered_questions"
                        rows="1"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col sm="3" md="4" lg="4" align="center">
                      <span style="font-weight: bold" class="mt-5"> = </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col sm="4" md="4" lg="4">
                  <v-row>
                    <v-col sm="9" md="8" lg="10">
                      <v-textarea
                        style="font-weight: bold"
                        label=" Rigth Answers:"
                        outlined
                        hide-details
                        disabled
                        dense
                        auto-grow
                        :value="user_assignment.right_questions"
                        rows="1"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col sm="3" md="4" lg="2" align="center">
                      <span class="mt-5" style="font-weight: bold"> +</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col sm="4" md="4" lg="4"
                  ><v-textarea
                    style="font-weight: bold"
                    label=" Wrong Answers:"
                    auto-grow
                    outlined
                    hide-details
                    dense
                    disabled
                    :value="user_assignment.wrong_questions"
                    rows="1"
                    row-height="15"
                  ></v-textarea
                ></v-col>
              </v-row>
            </v-col>
            <v-col md="10" v-else>
              <h2 v-if="user_assignment.feedback">
                <v-textarea
                  style="font-weight: bold"
                  disabled
                  label="Feedback"
                  outlined
                  dense
                  auto-grow
                  rows="2"
                  :value="user_assignment.feedback"
                ></v-textarea>
              </h2>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- End Analysis -->
      <br />
      <!-- Questions Listing -->
      <v-card elevation="6" color="blue-grey lighten-5" style="overflow: auto">
        <v-card-text>
          <!-- Document Type Section-->
          <template
            v-if="user_assignment.assignment.assignment_type == 'DOCUMENT'"
          >
            <v-row>
              <v-col class="py-0">
                <p class="font-weight-bold" style="min-width: 260">
                  Please download the the answers uploaded by the TSI
                </p>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col md="2">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mt-2"
                      v-bind="attrs"
                      small
                      v-on="on"
                      target="_blank"
                      :href="`${mediaUrl}${user_assignment.documentpath}`"
                    >
                      Download Answers
                    </v-btn>
                  </template>
                  <span>Download Answers</span>
                </v-tooltip>
              </v-col>
              <v-col md="3">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="orange"
                      dark
                      class="mt-2"
                      v-bind="attrs"
                      small
                      v-on="on"
                      target="_blank"
                      :href="`${mediaUrl}${user_assignment.assignment.documentpath}`"
                    >
                      View Question Document
                    </v-btn>
                  </template>
                  <span>View Question Document</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <!-- Extension file MP3 -->
                <template
                  v-if="
                    audio_format.includes(
                      getExtension(user_assignment.documentpath)
                    )
                  "
                >
                  <audio
                    ref="audioElm"
                    :src="`${mediaUrl + user_assignment.documentpath}`"
                    controls
                  ></audio>
                </template>
                <!-- Extension file is Docs / Video -->
                <template
                  v-else-if="
                    doc_format.includes(
                      getExtension(user_assignment.documentpath)
                    ) ||
                    video_format.includes(
                      getExtension(user_assignment.documentpath)
                    )
                  "
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mt-2"
                        v-bind="attrs"
                        small
                        v-on="on"
                        @click="showDocumentPreview = true"
                      >
                        Preview
                        {{ getExtension(user_assignment.documentpath) }}
                        Document
                      </v-btn>
                    </template>
                    <span> Preview the Uploaded Document</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  Unfortunately, a preview is unavailable. Please proceed to
                  download the document.
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <!-- Model Answer -->
                <v-card>
                  <v-col
                    sm="12"
                    class="mb-0"
                    v-if="user_assignment.assignment.model_answer"
                  >
                    <b>Model Answer:</b>
                    <h4
                      class="font-weight-bold mb-0 pb-0"
                      v-html="user_assignment.assignment.model_answer"
                    ></h4>
                  </v-col>
                </v-card>
                <!-- End Model Answer -->
              </v-col>
            </v-row>
          </template>
          <!-- End Document Type Section-->

          <v-row>
            <v-col sm="12">
              <!-- If single file is uploaded in SUBJECTIVE -->
              <template
                v-if="
                  user_assignment.documentpath &&
                  user_assignment.assignment.assignment_type == 'SUBJECTIVE'
                "
              >
                <v-row>
                  <v-col class="py-0">
                    <p class="font-weight-bold">
                      Please download the the answers uploaded by the student
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col md="2">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mt-2"
                          v-bind="attrs"
                          small
                          v-on="on"
                          target="_blank"
                          :href="`${mediaUrl}${user_assignment.documentpath}`"
                        >
                          Download Answers
                        </v-btn>
                      </template>
                      <span>Download Answers</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <!-- Extension file MP3 -->
                    <template
                      v-if="
                        audio_format.includes(
                          getExtension(user_assignment.documentpath)
                        )
                      "
                    >
                      <audio
                        class="mb-5"
                        ref="audioElm"
                        :src="`${mediaUrl + user_assignment.documentpath}`"
                        controls
                      ></audio>
                    </template>
                    <!-- Extension file is Docs / Video-->
                    <template
                      v-else-if="
                        doc_format.includes(
                          getExtension(user_assignment.documentpath)
                        ) ||
                        video_format.includes(
                          getExtension(user_assignment.documentpath)
                        )
                      "
                    >
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="mt-2"
                            v-bind="attrs"
                            small
                            v-on="on"
                            @click="showDocumentPreview = true"
                          >
                            Preview
                            {{ getExtension(user_assignment.documentpath) }}
                            Document
                          </v-btn>
                        </template>
                        <span> Preview the Uploaded Document</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      Unfortunately, a preview is unavailable. Please proceed to
                      download the document.
                    </template>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </template>

              <p
                class="font-weight-bold mb-5"
                v-if="roleName == 'TEACHER'"
                style="min-width: max-content"
              >
                Assessment
              </p>
              <!-- Assessment -->
              <v-row
                style="overflow: auto"
                v-for="(
                  userAssignmentSelectedAnswer, fQ
                ) in user_assignment.user_assignment_selected_answers"
                :key="`userAssignmentSelectedAnswer${fQ}`"
              >
                <v-col sm="12" md="12" lg="12" xl="12" style="min-width: 165">
                  <v-card>
                    <v-col sm="12">
                      <b>Q{{ fQ + 1 }}:</b>
                      <h3
                        style="overflow: auto"
                        class="font-weight-bold mb-0 pb-0"
                        v-html="userAssignmentSelectedAnswer.question"
                      ></h3>
                      (<v-icon size="20" color="green">mdi-finance</v-icon>
                      {{
                        userAssignmentSelectedAnswer.marks == 1
                          ? userAssignmentSelectedAnswer.marks + ` Mark`
                          : userAssignmentSelectedAnswer.marks + ` Marks`
                      }})
                      <span
                        v-if="
                          userAssignmentSelectedAnswer.question_type ==
                          'OBJECTIVE'
                        "
                      >
                        [
                        {{
                          userAssignmentSelectedAnswer.correct_options.length >
                          1
                            ? "Multiple Choice"
                            : "Single Choice"
                        }}
                        ]
                      </span>
                      <div
                        v-if="
                          userAssignmentSelectedAnswer.type == 'TRUE_FALSE' &&
                          userAssignmentSelectedAnswer.is_correct == false
                        "
                      >
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              dark
                              class="mt-2"
                              v-bind="attrs"
                              small
                              v-on="on"
                              @click="
                                OpenCorrectMeaning(
                                  userAssignmentSelectedAnswer.content_metadata
                                )
                              "
                            >
                              Correct meaning
                            </v-btn>
                          </template>
                          <span>Click to View the Correct meaning</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <!-- Objective -->
                    <v-col
                      sm="12"
                      class="mb-0"
                      v-if="
                        userAssignmentSelectedAnswer.question_type ==
                        'OBJECTIVE'
                      "
                    >
                      <b>A{{ fQ + 1 }}:</b>
                      <div
                        v-if="
                          userAssignmentSelectedAnswer.correct_options.length >
                          1
                        "
                      >
                        <!-- Multiple Choice -->
                        <v-checkbox
                          v-if="userAssignmentSelectedAnswer.option1"
                          v-model="
                            userAssignmentSelectedAnswer.selected_options
                          "
                          value="1"
                          disabled
                        >
                          <template v-slot:label>
                            <strong
                              :class="
                                userAssignmentSelectedAnswer.selected_options.includes(
                                  '1'
                                )
                                  ? userAssignmentSelectedAnswer.correct_options.includes(
                                      1
                                    )
                                    ? 'success--text'
                                    : 'error--text'
                                  : userAssignmentSelectedAnswer.correct_options.includes(
                                      1
                                    )
                                  ? 'success--text' // 'warning--text' // Below ''  is there
                                  : 'error--text'
                              "
                            >
                              {{ userAssignmentSelectedAnswer.option1 }}
                            </strong>
                          </template>
                        </v-checkbox>
                        <v-checkbox
                          v-if="userAssignmentSelectedAnswer.option2"
                          v-model="
                            userAssignmentSelectedAnswer.selected_options
                          "
                          value="2"
                          disabled
                        >
                          <template v-slot:label>
                            <strong
                              :class="
                                userAssignmentSelectedAnswer.selected_options.includes(
                                  '2'
                                )
                                  ? userAssignmentSelectedAnswer.correct_options.includes(
                                      2
                                    )
                                    ? 'success--text'
                                    : 'error--text'
                                  : userAssignmentSelectedAnswer.correct_options.includes(
                                      2
                                    )
                                  ? 'success--text' // 'warning--text' // Below ''  is there
                                  : 'error--text'
                              "
                            >
                              {{ userAssignmentSelectedAnswer.option2 }}
                            </strong>
                          </template>
                        </v-checkbox>
                        <v-checkbox
                          v-if="userAssignmentSelectedAnswer.option3"
                          v-model="
                            userAssignmentSelectedAnswer.selected_options
                          "
                          value="3"
                          disabled
                        >
                          <template v-slot:label>
                            <strong
                              :class="
                                userAssignmentSelectedAnswer.selected_options.includes(
                                  '3'
                                )
                                  ? userAssignmentSelectedAnswer.correct_options.includes(
                                      3
                                    )
                                    ? 'success--text'
                                    : 'error--text'
                                  : userAssignmentSelectedAnswer.correct_options.includes(
                                      3
                                    )
                                  ? 'success--text' // 'warning--text' // Below ''  is there
                                  : 'error--text'
                              "
                            >
                              {{ userAssignmentSelectedAnswer.option3 }}
                            </strong>
                          </template>
                        </v-checkbox>
                        <v-checkbox
                          v-if="userAssignmentSelectedAnswer.option4"
                          v-model="
                            userAssignmentSelectedAnswer.selected_options
                          "
                          value="4"
                          disabled
                        >
                          <template v-slot:label>
                            <strong
                              :class="
                                userAssignmentSelectedAnswer.selected_options.includes(
                                  '4'
                                )
                                  ? userAssignmentSelectedAnswer.correct_options.includes(
                                      4
                                    )
                                    ? 'success--text'
                                    : 'error--text'
                                  : userAssignmentSelectedAnswer.correct_options.includes(
                                      4
                                    )
                                  ? 'success--text' // 'warning--text' // Below ''  is there
                                  : 'error--text'
                              "
                            >
                              {{ userAssignmentSelectedAnswer.option4 }}
                            </strong>
                          </template>
                        </v-checkbox>
                        <v-checkbox
                          v-if="userAssignmentSelectedAnswer.option5"
                          v-model="
                            userAssignmentSelectedAnswer.selected_options
                          "
                          value="5"
                          disabled
                        >
                          <template v-slot:label>
                            <strong
                              :class="
                                userAssignmentSelectedAnswer.selected_options.includes(
                                  '5'
                                )
                                  ? userAssignmentSelectedAnswer.correct_options.includes(
                                      5
                                    )
                                    ? 'success--text'
                                    : 'error--text'
                                  : userAssignmentSelectedAnswer.correct_options.includes(
                                      5
                                    )
                                  ? 'success--text' // 'warning--text' // Below ''  is there
                                  : 'error--text'
                              "
                            >
                              {{ userAssignmentSelectedAnswer.option5 }}
                            </strong>
                          </template>
                        </v-checkbox>
                        <v-checkbox
                          v-if="userAssignmentSelectedAnswer.option6"
                          v-model="
                            userAssignmentSelectedAnswer.selected_options
                          "
                          value="6"
                          disabled
                        >
                          <template v-slot:label>
                            <strong
                              :class="
                                userAssignmentSelectedAnswer.selected_options.includes(
                                  '6'
                                )
                                  ? userAssignmentSelectedAnswer.correct_options.includes(
                                      6
                                    )
                                    ? 'success--text'
                                    : 'error--text'
                                  : userAssignmentSelectedAnswer.correct_options.includes(
                                      6
                                    )
                                  ? 'success--text' // 'warning--text' // Below ''  is there
                                  : 'error--text'
                              "
                            >
                              {{ userAssignmentSelectedAnswer.option6 }}
                            </strong>
                          </template>
                        </v-checkbox>
                      </div>
                      <div v-else>
                        <!-- Single Choice -->
                        <v-radio-group
                          v-model="
                            userAssignmentSelectedAnswer.selected_options
                          "
                          column
                        >
                          <v-radio
                            v-if="userAssignmentSelectedAnswer.option1"
                            :label="userAssignmentSelectedAnswer.option1"
                            disabled
                            value="1"
                          >
                            <template v-slot:label>
                              <strong
                                :class="
                                  userAssignmentSelectedAnswer.selected_options.includes(
                                    '1'
                                  )
                                    ? userAssignmentSelectedAnswer.correct_options.includes(
                                        1
                                      )
                                      ? 'success--text'
                                      : 'error--text'
                                    : userAssignmentSelectedAnswer.correct_options.includes(
                                        1
                                      )
                                    ? 'success--text' // 'warning--text' // Below ''  is there
                                    : 'error--text'
                                "
                              >
                                {{ userAssignmentSelectedAnswer.option1 }}
                              </strong>
                            </template>
                          </v-radio>
                          <v-radio
                            v-if="userAssignmentSelectedAnswer.option2"
                            :label="`${userAssignmentSelectedAnswer.option2}`"
                            value="2"
                            disabled
                          >
                            <template v-slot:label>
                              <strong
                                :class="
                                  userAssignmentSelectedAnswer.selected_options.includes(
                                    '2'
                                  )
                                    ? userAssignmentSelectedAnswer.correct_options.includes(
                                        2
                                      )
                                      ? 'success--text'
                                      : 'error--text'
                                    : userAssignmentSelectedAnswer.correct_options.includes(
                                        2
                                      )
                                    ? 'success--text' // 'warning--text' // Below ''  is there
                                    : 'error--text'
                                "
                              >
                                {{ userAssignmentSelectedAnswer.option2 }}
                              </strong>
                            </template>
                          </v-radio>
                          <v-radio
                            v-if="userAssignmentSelectedAnswer.option3"
                            :label="userAssignmentSelectedAnswer.option3"
                            value="3"
                            disabled
                          >
                            <template v-slot:label>
                              <strong
                                :class="
                                  userAssignmentSelectedAnswer.selected_options.includes(
                                    '3'
                                  )
                                    ? userAssignmentSelectedAnswer.correct_options.includes(
                                        3
                                      )
                                      ? 'success--text'
                                      : 'error--text'
                                    : userAssignmentSelectedAnswer.correct_options.includes(
                                        3
                                      )
                                    ? 'success--text' // 'warning--text' // Below ''  is there
                                    : 'error--text'
                                "
                              >
                                {{ userAssignmentSelectedAnswer.option3 }}
                              </strong>
                            </template>
                          </v-radio>
                          <v-radio
                            v-if="userAssignmentSelectedAnswer.option4"
                            :label="userAssignmentSelectedAnswer.option4"
                            value="4"
                            disabled
                          >
                            <template v-slot:label>
                              <strong
                                :class="
                                  userAssignmentSelectedAnswer.selected_options.includes(
                                    '4'
                                  )
                                    ? userAssignmentSelectedAnswer.correct_options.includes(
                                        4
                                      )
                                      ? 'success--text'
                                      : 'error--text'
                                    : userAssignmentSelectedAnswer.correct_options.includes(
                                        4
                                      )
                                    ? 'success--text' // 'warning--text' // Below ''  is there
                                    : 'error--text'
                                "
                              >
                                {{ userAssignmentSelectedAnswer.option4 }}
                              </strong>
                            </template>
                          </v-radio>
                          <v-radio
                            v-if="userAssignmentSelectedAnswer.option5"
                            :label="userAssignmentSelectedAnswer.option5"
                            value="5"
                            disabled
                          >
                            <template v-slot:label>
                              <strong
                                :class="
                                  userAssignmentSelectedAnswer.selected_options.includes(
                                    '5'
                                  )
                                    ? userAssignmentSelectedAnswer.correct_options.includes(
                                        5
                                      )
                                      ? 'success--text'
                                      : 'error--text'
                                    : userAssignmentSelectedAnswer.correct_options.includes(
                                        5
                                      )
                                    ? 'success--text' // 'warning--text' // Below ''  is there
                                    : 'error--text'
                                "
                              >
                                {{ userAssignmentSelectedAnswer.option5 }}
                              </strong>
                            </template>
                          </v-radio>
                          <v-radio
                            v-if="userAssignmentSelectedAnswer.option6"
                            :label="userAssignmentSelectedAnswer.option6"
                            value="6"
                            disabled
                          >
                            <template v-slot:label>
                              <strong
                                :class="
                                  userAssignmentSelectedAnswer.selected_options.includes(
                                    '6'
                                  )
                                    ? userAssignmentSelectedAnswer.correct_options.includes(
                                        6
                                      )
                                      ? 'success--text'
                                      : 'error--text'
                                    : userAssignmentSelectedAnswer.correct_options.includes(
                                        6
                                      )
                                    ? 'success--text' // 'warning--text' // Below ''  is there
                                    : 'error--text'
                                "
                              >
                                {{ userAssignmentSelectedAnswer.option6 }}
                              </strong>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </v-col>
                    <!-- Subjective -->

                    <!-- If individual questions are answered -->
                    <v-col
                      sm="12"
                      v-if="
                        userAssignmentSelectedAnswer.question_type ==
                        'SUBJECTIVE'
                      "
                    >
                      <b>A{{ fQ + 1 }}:</b>
                      <p
                        style="overflow: auto"
                        v-html="userAssignmentSelectedAnswer.description"
                        v-if="userAssignmentSelectedAnswer.description"
                      ></p>
                      <!-- <a
                        v-if="userAssignmentSelectedAnswer.documentpath"
                        target="_blank"
                        :href="`${mediaUrl}${userAssignmentSelectedAnswer.documentpath}`"
                        >Download Answer</a
                      > -->
                      <v-tooltip
                        bottom
                        v-if="userAssignmentSelectedAnswer.documentpath"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="ml-5"
                            v-bind="attrs"
                            small
                            v-on="on"
                            target="_blank"
                            :href="`${mediaUrl}${userAssignmentSelectedAnswer.documentpath}`"
                          >
                            Download Answers
                          </v-btn>
                        </template>
                        <span>Download Answers</span>
                      </v-tooltip>
                    </v-col>
                    <!-- Model Answer -->
                    <v-col
                      sm="12"
                      class="mb-0"
                      v-if="userAssignmentSelectedAnswer.model_answer"
                    >
                      <v-card color="green lighten-5">
                        <v-col sm="12" class="mb-0">
                          <b
                            v-if="
                              userAssignmentSelectedAnswer.question_type ==
                              'SUBJECTIVE'
                            "
                            >Model Answer:</b
                          >
                          <b v-else>Justification:</b>
                          <h4
                            style="overflow: auto"
                            class="font-weight-bold mb-0 pb-0"
                            v-html="userAssignmentSelectedAnswer.model_answer"
                          ></h4>
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-card>
                </v-col>

                <!-- Give Marks of Individual Questions Only For Teachers & Assessed -->
                <v-row
                  class="mx-1"
                  style="min-width: 160"
                  v-if="
                    user_assignment.result_declared == false &&
                    roleName == 'TEACHER'
                  "
                >
                  <v-col :sm="5">
                    <v-text-field
                      type="number"
                      :rules="[
                        (v) =>
                          v <= userAssignmentSelectedAnswer.marks ||
                          'Mark Obtained cannot be greater than total marks',
                      ]"
                      outlined
                      v-model="userAssignmentSelectedAnswer.marks_obtained"
                      label="Marks Given"
                      :suffix="' / ' + userAssignmentSelectedAnswer.marks"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="7">
                    <v-text-field
                      outlined
                      v-model="userAssignmentSelectedAnswer.feedback"
                      :label="`Feedback for Q${fQ + 1}`"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- End Give Marks of Individual Questions -->

                <v-col sm="12" md="12" lg="12" xl="12" style="min-width: 165">
                  <p class="font-weight-bold">
                    Marks Obtained:
                    {{ userAssignmentSelectedAnswer.marks_obtained || 0 }}
                  </p>
                  <p class="font-weight-bold">
                    Feedback:
                    {{ userAssignmentSelectedAnswer.feedback }}
                  </p>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <!-- End Assessment -->
              <!-- Overall Feedback -->
              <v-row v-if="roleName == 'TEACHER'">
                <v-col sm="6">
                  <v-text-field
                    outlined
                    :disabled="user_assignment.result_declared ? true : false"
                    v-model="user_assignment.feedback"
                    label="Overall Assignment Feedback"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <!-- <p class="font-weight-bold">Assessment</p> -->
                  <v-row>
                    <v-col sm="6">
                      <span
                        v-if="
                          user_assignment.assignment.assignment_type ==
                            'DOCUMENT' && roleName == 'TEACHER'
                        "
                      >
                        <v-text-field
                          type="number"
                          outlined
                          v-model="user_assignment.score"
                          label="Total Score"
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <b> Total Score : </b>
                        {{ user_assignment.score }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- End Overall Feedback -->
            </v-col>
          </v-row>
          <v-row v-if="user_assignment.result_declared == false">
            <v-col sm="12">
              <loader v-if="isLoading"></loader>
              <!-- Complete Assessment Dialog -->
              <v-dialog
                v-else
                v-model="isAssessmentDoneDialog"
                max-width="400px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Complete Assessment
                  </v-btn>
                </template>

                <v-card elevation="10" align="center">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Do you want to submit the assessment?
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-btn color="green" @click.stop="saveAssessment" dark>
                      Yes
                    </v-btn>
                    &nbsp;
                    <v-btn
                      dark
                      color="red"
                      @click.stop="closeAssessmentDoneDialog"
                    >
                      No
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- End Complete Assessment Dialog -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- Questions Listing -->
      <!-- Correct Meaning Dialog Box -->
      <v-dialog v-model="show_correct_meaning" width="600">
        <v-card>
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-check
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">
                        {{
                          content_metadata_details.selected_text | capitalize
                        }}
                      </h1>
                      <br />
                      <h3>{{ content_metadata_details.meaning }}</h3>
                      <br />
                      <v-btn
                        color="success"
                        @click="
                          (show_correct_meaning = false),
                            (content_metadata_details = {})
                        "
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- /Delete Bookmark Success Dialog Box -->
    </v-container>
    <!-- Show Document Preview Dialog Box -->
    <v-dialog
      v-model="showDocumentPreview"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card elevation="12" style="background: rgb(252, 239, 239)">
        <v-toolbar color="white">
          <v-btn icon @click="showDocumentPreview = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Preview</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid>
          <v-card elevation="12" class="mt-4 mb-4">
            <v-row align="center">
              <div style="flex: 1 1 auto"></div>
              <v-col sm="12">
                <v-card-text>
                  <v-row class="text-h6 pa-5" justify="center">
                    <div style="overflow: auto" align="center">
                      <VueDocPreview
                        v-if="
                          doc_format.includes(
                            getExtension(user_assignment.documentpath)
                          )
                        "
                        style="width: 1600px; min-height: 650px"
                        :value="`${mediaUrl + user_assignment.documentpath}`"
                        type="office"
                      />
                      <!-- Extension file MP4 -->
                      <template
                        v-if="
                          video_format.includes(
                            getExtension(user_assignment.documentpath)
                          )
                        "
                      >
                        <video
                          class="mb-5"
                          style="width: 1000px"
                          ref="audioElm"
                          :src="`${mediaUrl + user_assignment.documentpath}`"
                          controls
                        ></video>
                        <!-- :src="`https://az2-medias.s3.ap-south-1.amazonaws.com/infakt/editor-medias/file1692011732.mp4`" -->
                      </template>
                    </div>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";

export default {
  components: { backbutton, Loader },
  name: "CheckResultPage",
  data() {
    return {
      isLoading: false,
      show_correct_meaning: false,
      content_metadata_details: {},
      isAssessmentDoneDialog: false,
      showDocumentPreview: false,
      percent: 0,
      color: "red",
      user_assignment: {
        assignment: {},
        user: {},
      },
      source: "",
      doc_format: ["DOCX", "XLS", "PPT"],
      video_format: ["MP4", "M4V"],
      audio_format: ["MP3"],
    };
  },
  mounted() {
    this.getData();
    this.source = this.$route.query.source;
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let user_assignment = await axios.get(
        `/user_assignments/${this.$route.params.userAssignmentId}`
      );
      this.user_assignment = user_assignment.data.data;
      this.user_assignment.user_assignment_selected_answers.forEach((uASA) => {
        if (uASA.question_type != "OBJECTIVE") {
          uASA.marks_obtained =
            uASA.marks_obtained != "0" ? uASA.marks_obtained : "";
        }
        if (uASA.correct_options.length == 1) {
          // If Single Choice then Make it String
          uASA.selected_options = String(uASA.selected_options);
        } else {
          // If Multiple Choice then Make each element to String
          uASA.selected_options = uASA.selected_options.map(String);
        }
      });
      this.performance_percent();
      this.isLoading = false;
    },
    async saveAssessment() {
      // this.user_assignment.result_declared = true;
      this.isAssessmentDoneDialog = false;
      if (this.user_assignment.assignment.assignment_type != "DOCUMENT")
        this.user_assignment.score = 0;
      this.user_assignment.right_questions = 0;
      this.user_assignment.wrong_questions = 0;
      this.user_assignment.user_assignment_selected_answers.forEach((uASA) => {
        uASA.marks_obtained = parseFloat(uASA.marks_obtained);
        this.user_assignment.score += parseFloat(uASA.marks_obtained);
        if (uASA.correct_options.length == 1) {
          uASA.selected_options = [parseFloat(uASA.selected_options)];
        }
        if (uASA.marks_obtained > 0) {
          this.user_assignment.right_questions++;
        } else {
          this.user_assignment.wrong_questions++;
        }
      });
      // }

      let response = await axios.post(
        "/user_assignments",
        this.user_assignment
      );
      if (response) {
        this.user_assignment.result_declared = true;
        let response = await axios.post(
          "/user_assignments",
          this.user_assignment
        );
        if (response) {
          console.log("Success");
        }
      }

      this.$router.push(
        `/assignments/${this.user_assignment.assignment.id}/submissions`
      );
    },
    closeAssessmentDoneDialog() {
      this.isAssessmentDoneDialog = false;
    },
    OpenCorrectMeaning(content_metadata) {
      this.show_correct_meaning = true;
      this.content_metadata_details = content_metadata;
    },
    getExtension(path) {
      if (path) {
        let path_array = path.split("/");
        let image_name = path_array[3];
        let extension = image_name?.split(".")[1].toUpperCase();
        console.log(path);
        return extension;
      }
    },
    performance_percent() {
      this.percent = parseInt(
        Math.round(
          (this.user_assignment.score * 100) /
            this.user_assignment.assignment.maximum_marks
        )
      );
      switch (true) {
        case this.percent >= 80:
          this.color = "blue";
          // this.color = "#0000FF";
          break;
        case this.percent >= 60 && this.percent < 80:
          this.color = "green";
          // this.color = "#008000";
          break;
        case this.percent >= 40 && this.percent < 60:
          this.color = "yellow";
          // this.color = "#FFA500";
          break;
        case this.percent > 40:
          this.color = "red";
          // this.color = "#FF0000";
          break;

        default:
          // Failed
          this.color = "red";
          break;
      }
      console.log(this.color);
    },
  },
};
</script>
<style scoped>
.col {
  flex-basis: auto !important;
}
</style>
<style>
img {
  max-width: -webkit-fill-available !important;
}
</style>
