import { render, staticRenderFns } from "./contact_us.vue?vue&type=template&id=10ec164e&scoped=true&"
import script from "./contact_us.vue?vue&type=script&lang=js&"
export * from "./contact_us.vue?vue&type=script&lang=js&"
import style0 from "./contact_us.vue?vue&type=style&index=0&id=10ec164e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10ec164e",
  null
  
)

export default component.exports