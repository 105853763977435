<template>
  <span v-if="standard_section">
    <span v-if="type == 'SUBJECT'"> {{ subject_section }}</span>
    <span v-else-if="type == 'STANDARD_SUBJECT'">
      <b class="font-weight-black primary--text">{{ standard_section }}</b
      >/{{ subject_section }}</span
    >
    <span v-else>
      <template>
        {{ board_element }}/<b class="font-weight-black primary--text">{{
          standard_section
        }}</b
        >/{{ subject_section }}/{{ id_section }}
      </template>
    </span>
  </span>
  <span v-else>{{ classcode }}</span>
</template>
<script>
export default {
  name: "ClasscodeElement",
  data() {
    return {
      originalClasscode: "",
      board_element: "",
      standard_section: "",
      subject_section: "",
    };
  },
  props: ["classcode", "type"],
  mounted() {
    this.originalClasscode = this.classcode;
    if (this.classcode) {
      let classcode_array = this.UIClasscode(this.classcode);
      this.board_element = classcode_array[0];
      this.standard_section = classcode_array[1];
      this.subject_section = classcode_array[2];
      this.id_section = classcode_array[3];
    }
  },
  methods: {
    UIClasscode(classcode) {
      let classcode_array = classcode.split("/");
      return classcode_array;
    },
  },
};
</script>
