<template>
  <span>
    <!-- Full Screen Chart Dialog Box -->
    <v-dialog v-model="show_fullscreen">
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip left v-bind="attrs" v-on="on">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="viewFullscreen(title, type, Data, Options)"
              icon
            >
              <v-icon>mdi-fullscreen</v-icon>
            </v-btn>
          </template>
          <span>View Fullscreen</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-container>
          <v-row>
            <v-card-title class="text-center"
              >{{ FullScreenChart.title }}
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="show_fullscreen = false"
                    icon
                  >
                    <v-icon>mdi-fullscreen-exit</v-icon>
                  </v-btn>
                </template>
                <span>Exit Fullscreen</span>
              </v-tooltip>
            </v-card-actions>
          </v-row>
          <v-row>
            <v-col>
              <v-card-text style="overflow: auto">
                <v-row>
                  <v-col>
                    <GChart
                      :type="FullScreenChart.type"
                      :data="FullScreenChart.Data"
                      :options="FullScreenChart.Options"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
export default {
  name: "ViewFullscreen",
  data() {
    return {
      show_fullscreen: false,
      FullScreenChart: {
        type: "",
        Data: [],
        Options: {
          theme: "material",
          width: "100%",
          height: 350,
          chartArea: { left: 100, right: 50 },
          colors: ["#007ACC", "#FFA500"],
          bar: { groupWidth: "60" },
          annotations: {
            textStyle: {
              fontSize: 10,
            },
          },
          vAxis: {
            minValue: 0,
            maxValue: 100,
            title: "In Percentage",
            titleTextStyle: {
              bold: true,
            },
            gridlines: { color: "#333", minSpacing: 75 },
            textStyle: {
              fontSize: 10,
            },
          },
          hAxis: {
            title: "Classcodes",
            titleTextStyle: {
              bold: true,
            },
            gridlines: {
              color: "transparent",
            },
          },
          legend: { position: "top", alignment: "left" },
        },
      },
      board_element: "",
      standard_section: "",
      subject_section: "",
    };
  },
  props: ["title", "type", "Data", "Options"],
  //   mounted() {
  //   },
  methods: {
    viewFullscreen(title, type, chart, options) {
      this.show_fullscreen = true;
      this.FullScreenChart.title = title;
      this.FullScreenChart.type = type;
      this.FullScreenChart.Data = chart;
      this.FullScreenChart.Options = options;
      this.FullScreenChart.Options.theme = "material";
      // if (parseInt(chart.length - 1) > 6) {
      this.FullScreenChart.Options.width =
        parseInt(chart.length - 1) > 6 ? parseInt(chart.length - 1) * 200 : 0;
      this.FullScreenChart.Options.bar.groupWidth =
        parseInt(chart.length - 1) > 6 ? "30%" : "65";
      // }
      console.log(this.FullScreenChart);
    },
  },
};
</script>
