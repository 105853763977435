<template>
  <section id="subjects" class="white lighten-3">
    <div class="py-12"></div>

    <v-container fluid class="text-center">
      <h2 class="display-2 font-weight-bold mb-3 myFont">Brand Partners</h2>

      <br />
      <v-row justify="center" v-if="$vuetify.breakpoint.mdAndDown">
        <v-col md="4" style="height: 200px; width: 400px">
          <img
            class="partner"
            style="height: 157px; width: 378px"
            :src="require('../../assets/partners-logo/the-guardian copy.png')"
            alt=""
          />
        </v-col>
        <v-col md="4" style="height: 200px; width: 400px">
          <img
            class="partner"
            style="height: 157px; width: 378px"
            :src="require('../../assets/partners-logo/the-scientist-logo.png')"
            alt=""
          />
        </v-col>
        <v-col md="4" style="height: 280px; width: 400px">
          <img
            style="height: 250px; width: 378px"
            class="partner"
            :src="
              require('../../assets/partners-logo/The_Times_Group_logo.png')
            "
            alt=""
          />
        </v-col>
      </v-row>
      <v-row justify="center" v-else>
        <v-col md="4" style="height: 200px; width: 400px">
          <img
            class="partner"
            style="height: inherit; width: initial"
            :src="require('../../assets/partners-logo/the-guardian copy.png')"
            alt=""
          />
        </v-col>
        <v-col md="4" style="height: 200px; width: 400px">
          <img
            class="partner"
            style="height: inherit; width: initial"
            :src="require('../../assets/partners-logo/the-scientist-logo.png')"
            alt=""
          />
        </v-col>
        <v-col md="4" style="height: 280px; width: 400px">
          <img
            style="height: inherit; width: initial"
            class="partner"
            :src="
              require('../../assets/partners-logo/The_Times_Group_logo.png')
            "
            alt=""
          />
        </v-col>
      </v-row>
      <v-col v-if="$vuetify.breakpoint.mdAndDown">
        <v-row justify="center" v-if="showMoreBrandPartners == true">
          <v-col md="4" style="height: 150px; width: 400px">
            <img
              style="height: 157px; width: 378px"
              class="partner"
              :src="require('../../assets/partners-logo/Project_Syndicate.png')"
              alt=""
            />
          </v-col>
          <v-col md="4" style="height: 170px; width: 400px">
            <img
              style="height: 157px; width: 378px"
              class="partner"
              :src="require('../../assets/partners-logo/makematic-logo.jpeg')"
              alt=""
            />
          </v-col>
          <v-col md="4" class="mt-5" style="height: 140px; width: 400px">
            <img
              style="height: 157px; width: 378px"
              class="partner"
              :src="
                require('../../assets/partners-logo/The_Conversation_logo.png')
              "
              alt=""
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col v-else>
        <v-row justify="center" v-if="showMoreBrandPartners == true">
          <v-col md="4" style="height: 150px; width: 400px">
            <img
              style="height: inherit; width: initial"
              class="partner"
              :src="require('../../assets/partners-logo/Project_Syndicate.png')"
              alt=""
            />
          </v-col>
          <v-col md="4" style="height: 170px; width: 400px">
            <img
              style="height: inherit; width: initial"
              class="partner"
              :src="require('../../assets/partners-logo/makematic-logo.jpeg')"
              alt=""
            />
          </v-col>
          <v-col md="4" class="mt-5" style="height: 140px; width: 400px">
            <img
              style="height: inherit; width: initial"
              class="partner"
              :src="
                require('../../assets/partners-logo/The_Conversation_logo.png')
              "
              alt=""
            />
          </v-col>
        </v-row>
      </v-col>

      <v-icon
        size="30"
        color="primary"
        @click="showMoreBrandPartners = true"
        v-if="showMoreBrandPartners == false"
        >mdi-chevron-down-circle-outline</v-icon
      >
      <v-icon
        size="30"
        color="primary"
        @click="showMoreBrandPartners = false"
        v-if="showMoreBrandPartners == true"
        >mdi-chevron-up-circle-outline</v-icon
      >
    </v-container>

    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      showMoreBrandPartners: false,
      subjectsTrigger: false,
      feature: [
        {
          icon: "mdi-account-group-outline",
          title: "Data Analytics On Student Progress",
          text: "Monitor student development with the help of our reports. Get a detailed breakdown on subject wise grading and progress charts.",
        },
        {
          icon: "mdi-update",
          title: "Accessibility For All",
          text: "Articles are published at multiple reading levels, thus allowing for an entire class to read and discuss the same content, while permitting individual students to access material at their individual reading level.",
        },
        {
          icon: "mdi-shield-outline",
          title: "Assessment Mechanisms",
          text: "We provide interactive assignments but also give teachers the flexibility to give their own assessments with various parameters in mind.",
        },
        {
          icon: "mdi-book-alphabet",
          title: "Integrated Dictionary",
          text: "Improve student articulation and vocabulary. Power words foster language development for all our students.",
        },
        {
          icon: "mdi-cellphone-sound",
          title: "Student Mobile App",
          text: "Students can access engaging content and their homework even if they don’t have a computer.",
        },
        {
          icon: "mdi-text-long",
          title: "Multiple Topics",
          text: "Our platform covers a wide range of topics across diverse cultures and opinions. Globalise your pupils with information relevant to their growth.",
        },
      ],
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async handleScroll() {
      const subjects = document.querySelector("#subjects");
      if (this.isInViewport(subjects)) {
        await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
        this.subjectsTrigger = true;
      }
    },
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) + 500 &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
  },
};
</script>

<style scoped>
.myFont {
  font-family: "SF PRO" !important;
  color: black;
}
.partner {
  padding: 50px;
  height: 500;
}
</style>
