<template>
  <v-main>
    <v-container>
      <loader v-if="isLoading"></loader>
      <v-row>
        <v-col md="11">
          <v-card elevation="6" style="overflow: auto">
            <v-card-title style="overflow: auto; min-width: max-content">
              {{ assignment.assignment_title }}&nbsp;
              <span
                v-if="
                  assignment.assignment_type != 'DOCUMENT' &&
                  assignment.duration
                "
              >
                ({{ assignment.duration ? assignment.duration + "mins" : "" }})
              </span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text style="min-width: max-content">
              <p v-if="assignment.category == 'ASSIGNMENT'">
                {{ assignment.assignment_type }} TYPE
              </p>
              <p class="font-weight-bold mb-0"><u>Student Instructions:</u></p>
              <p v-html="assignment.student_instructions"></p>
              Best of Luck!
            </v-card-text>
          </v-card>
          <br />
          <v-card v-if="assignment.is_game">
            <iframe
              style="max-width: 100%"
              :src="assignment.game_link"
              width="100%"
              height="600"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </v-card>
          <v-card elevation="6" style="overflow: auto">
            <v-card-text>
              <!-- <iframe style="max-width:100%" src="https://wordwall.net/play/17692/689/331" width="100%" height="600" frameborder="0" allowfullscreen></iframe> -->
              <!-- Document -->
              <v-row v-if="assignment.assignment_type == 'DOCUMENT'">
                <v-col sm="12" md="12" lg="12" style="overflow: auto">
                  <p class="font-weight-bold" style="min-width: 260">
                    Please download the below assignment. Take the assignment
                    and upload the assignment back here.
                  </p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="orange"
                        dark
                        class="mt-2"
                        v-bind="attrs"
                        small
                        v-on="on"
                        target="_blank"
                        :href="`${mediaUrl}${assignment.documentpath}`"
                      >
                        View Question Document
                      </v-btn>
                    </template>
                    <span>View Question Document</span>
                  </v-tooltip>
                  <br /><br />
                  <p class="font-weight-bold" style="min-width: 75">
                    Upload your answer
                  </p>
                  <input type="file" id="file" name="file" ref="file" />
                  <!-- accept="application/ms-excel" -->
                </v-col>
              </v-row>
              <!-- Subjective Complete Answer -->
              <v-row v-if="assignment.assignment_type == 'SUBJECTIVE'">
                <v-col sm="12" md="12" lg="12" style="overflow: auto">
                  <p class="font-weight-bold" style="min-width: 75">
                    Upload your complete answer
                  </p>
                  <input type="file" id="file" name="file" ref="file" />
                  <a
                    v-if="user_assignment.documentpath"
                    target="_blank"
                    :href="`${mediaUrl}${user_assignment.documentpath}`"
                    >Check your answer</a
                  >
                </v-col>
              </v-row>
              <br />
              <!-- Questions -->
              <v-row
                style="overflow: auto"
                v-for="(
                  userAssignmentSelectedAnswer, fQ
                ) in user_assignment.user_assignment_selected_answers"
                :key="`userAssignmentSelectedAnswer${fQ}`"
              >
                <v-col sm="12" md="12" lg="12" style="min-width: 210px">
                  <b>Q{{ fQ + 1 }}:</b>
                  <h3
                    style="overflow: auto"
                    class="font-weight-bold mb-0 pb-0"
                    v-html="userAssignmentSelectedAnswer.question"
                  ></h3>
                  (<v-icon size="20" color="green">mdi-finance</v-icon>
                  {{
                    userAssignmentSelectedAnswer.marks == 1
                      ? userAssignmentSelectedAnswer.marks + ` Mark`
                      : userAssignmentSelectedAnswer.marks + ` Marks`
                  }})
                  <span
                    v-if="
                      userAssignmentSelectedAnswer.question_type == 'OBJECTIVE'
                    "
                  >
                    [
                    {{
                      userAssignmentSelectedAnswer.correct_options.length > 1
                        ? "Multiple Choice"
                        : "Single Choice"
                    }}
                    ]
                  </span>
                </v-col>
                <!-- Objective -->
                <v-col
                  sm="12"
                  md="12"
                  lg="12"
                  style="min-width: 250"
                  class="mb-0"
                  v-if="
                    userAssignmentSelectedAnswer.question_type == 'OBJECTIVE'
                  "
                >
                  <br />
                  <div
                    v-if="
                      userAssignmentSelectedAnswer.correct_options.length > 1
                    "
                  >
                    <!-- Multiple Choice -->
                    <v-checkbox
                      v-model="userAssignmentSelectedAnswer.selected_options"
                      v-if="userAssignmentSelectedAnswer.option1"
                      :label="userAssignmentSelectedAnswer.option1"
                      value="1"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="userAssignmentSelectedAnswer.selected_options"
                      v-if="userAssignmentSelectedAnswer.option2"
                      :label="userAssignmentSelectedAnswer.option2"
                      value="2"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="userAssignmentSelectedAnswer.selected_options"
                      v-if="userAssignmentSelectedAnswer.option3"
                      :label="userAssignmentSelectedAnswer.option3"
                      value="3"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="userAssignmentSelectedAnswer.selected_options"
                      v-if="userAssignmentSelectedAnswer.option4"
                      :label="userAssignmentSelectedAnswer.option4"
                      value="4"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="userAssignmentSelectedAnswer.selected_options"
                      v-if="userAssignmentSelectedAnswer.option5"
                      :label="userAssignmentSelectedAnswer.option5"
                      value="5"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="userAssignmentSelectedAnswer.selected_options"
                      v-if="userAssignmentSelectedAnswer.option6"
                      :label="userAssignmentSelectedAnswer.option6"
                      value="6"
                    ></v-checkbox>
                  </div>
                  <div v-else>
                    <!-- Single Choice -->
                    <v-radio-group
                      v-model="userAssignmentSelectedAnswer.selected_options"
                      column
                    >
                      <v-radio
                        v-if="userAssignmentSelectedAnswer.option1"
                        :label="userAssignmentSelectedAnswer.option1"
                        value="1"
                      ></v-radio>
                      <v-radio
                        v-if="userAssignmentSelectedAnswer.option2"
                        :label="userAssignmentSelectedAnswer.option2"
                        value="2"
                      ></v-radio>
                      <v-radio
                        v-if="userAssignmentSelectedAnswer.option3"
                        :label="userAssignmentSelectedAnswer.option3"
                        value="3"
                      ></v-radio>
                      <v-radio
                        v-if="userAssignmentSelectedAnswer.option4"
                        :label="userAssignmentSelectedAnswer.option4"
                        value="4"
                      ></v-radio>
                      <v-radio
                        v-if="userAssignmentSelectedAnswer.option5"
                        :label="userAssignmentSelectedAnswer.option5"
                        value="5"
                      ></v-radio>
                      <v-radio
                        v-if="userAssignmentSelectedAnswer.option6"
                        :label="userAssignmentSelectedAnswer.option6"
                        value="6"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <v-divider></v-divider>
                </v-col>
                <!-- Subjective -->
                <v-col
                  sm="12"
                  class="mb-0"
                  v-if="
                    userAssignmentSelectedAnswer.question_type == 'SUBJECTIVE'
                  "
                >
                  Your Answer:
                  <froala
                    :tag="'textarea'"
                    :config="config"
                    v-model="userAssignmentSelectedAnswer.description"
                  ></froala>

                  <br />
                  <p class="font-weight-bold">
                    -- or -- <br />
                    Upload your answer
                  </p>
                  <input
                    type="file"
                    :ref="`uASADoc${userAssignmentSelectedAnswer.assignment_question_id}`"
                  />
                  <a
                    v-if="userAssignmentSelectedAnswer.documentpath"
                    target="_blank"
                    :href="`${mediaUrl}${userAssignmentSelectedAnswer.documentpath}`"
                    >Check your answer</a
                  >
                  <br /><br />
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <!-- End Questions -->
              <v-row>
                <v-col>
                  <loader v-if="isLoading"></loader>
                  <v-dialog
                    v-else
                    v-model="isSaveUserAssignmentDialog"
                    max-width="450px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on">
                        Submit
                        {{
                          assignment.category == "POWERWORD QUIZ"
                            ? "Answers"
                            : "Assignment"
                        }}
                      </v-btn>
                    </template>

                    <v-card elevation="10" align="center">
                      <v-card-title
                        class="justify-center primary accent-1 white--text"
                      >
                        Do you want to submit the
                        {{
                          assignment.category == "POWERWORD QUIZ"
                            ? "answers"
                            : "assignment"
                        }}? Once submitted, you can't undo it.
                      </v-card-title>
                      <br />
                      <v-card-text>
                        <v-btn color="primary" @click.stop="saveUserAssignment">
                          Yes
                        </v-btn>
                        &nbsp;
                        <v-btn
                          dark
                          color="red"
                          @click.stop="closeSaveUserAssignmentDialog"
                        >
                          No
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <!-- User Assignment Saved Dialog -->
              <v-dialog
                v-model="isUserAssignmentSavedDialog"
                max-width="300px"
                persistent
              >
                <v-card elevation="2" aligm="right">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Your
                    {{
                      assignment.category == "POWERWORD QUIZ"
                        ? "answers"
                        : "assignment"
                    }}
                    has been submitted.
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-btn color="primary" @click.stop="closeSavedDialog">
                      Close
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="1">
          <h6>
            <clock
              v-if="assignment.duration"
              :startTime="startTime"
              :plannedTime="assignment.duration"
              @finishTest="finishTest"
              @savePartial="savePartial"
            ></clock>
          </h6>
        </v-col>
      </v-row>
    </v-container>
    <!-- Result Not Declared Dialog -->
    <v-dialog v-model="isAlreadyPerformed" max-width="300px" persistent>
      <v-card elevation="2" align="center">
        <v-card-title class="justify-center primary accent-1 white--text">
          Looks like you have already performed this assignment.
        </v-card-title>
        <br />
        <v-card-text>
          <v-btn color="primary" @click.stop="closeAlreadyPerformedDialog"
            >Close</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Clock from "@/components/clock.vue";
import Loader from "@/components/loader.vue";

export default {
  name: "TakeAssignmentPage",
  data() {
    return {
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      isLoading: false,
      isSaveUserAssignmentDialog: false,
      isUserAssignmentSavedDialog: false,
      isAlreadyPerformed: false,
      assignment: {},
      user_assignment: {
        user_assignment_selected_answers: [],
      },
      userAssignmentSelectedAnswerDocuments: [],
      plannedMinutes: 90,
      duration: moment(),
      startTime: null,
    };
  },
  components: { Clock, Loader },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      // Assignment
      let assignment = await axios.get(
        `/assignments/${this.$route.params.assignmentId}`
      );
      this.assignment = assignment.data.data;
      // User Assignment
      let userAssignments = await axios.get(
        `/user_assignments?user_id=${this.user.id}&assignment_id=${this.$route.params.assignmentId}`
      );
      userAssignments = userAssignments.data.data;
      if (userAssignments.length > 0) {
        //Already Performed
        if (this.assignment.duration) {
          //Timed Assignment
          this.user_assignment = userAssignments[0];
          let startTime = moment(this.user_assignment.submission_date);
          let currentTime = moment();
          this.startTime =
            parseInt(this.assignment.duration) * 60 -
            parseInt(currentTime.diff(startTime, "seconds"));

          if (this.startTime <= 0) {
            // Already Submitted
            this.isAlreadyPerformed = true;
            setTimeout(() => {
              // After 2 seconds, Close Dialog & redirect to Assignments page
              this.isAlreadyPerformed = false;
              this.$router.push("/assignments");
            }, 2000);
          }
          console.log(this.startTime);
        } else {
          // Not a Timed Assign
          this.isAlreadyPerformed = true;
          setTimeout(() => {
            // After 2 seconds, Close Dialog & redirect to Assignments page
            this.isAlreadyPerformed = false;
            this.$router.push("/assignments");
          }, 2000);
        }
      } else {
        //first time
        this.startTime = parseInt(this.assignment.duration) * 60;
        this.makeUserAssignment();
      }
      this.isLoading = false;
    },
    closeAlreadyPerformedDialog() {
      this.$router.push("/assignments");
    },
    makeUserAssignment() {
      let user_assignment = {
        user_id: this.user.id,
        assignment_id: this.assignment.id,
        submission_date: moment().format("YYYY-MM-DD H:m:s"),
        score: 0,
        total_questions: 0,
        answered_questions: 0,
        right_questions: 0,
        wrong_questions: 0,
        result_declared: false,
        // start_time: moment().format("YYYY-MM-DD H:m:s"),
        user_assignment_selected_answers: [],
      };
      this.assignment.assignment_questions.forEach((assignmentQuestion) => {
        user_assignment.user_assignment_selected_answers.push({
          user_id: this.user.id,
          company_id: this.company.id,
          assignment_id: this.assignment.id,
          assignment_question_id: assignmentQuestion.id,
          question_type: assignmentQuestion.question_type,
          selected_options: [],
          is_correct: false,
          marks_obtained: 0,
          description: "",
          question: assignmentQuestion.description,
          option1: assignmentQuestion.option1,
          option2: assignmentQuestion.option2,
          option3: assignmentQuestion.option3,
          option4: assignmentQuestion.option4,
          option5: assignmentQuestion.option5,
          option6: assignmentQuestion.option6,
          marks: assignmentQuestion.marks,
          correct_options: assignmentQuestion.correct_options,
          correct_option_sr_no: assignmentQuestion.correct_option_sr_no,
          model_answer: assignmentQuestion.model_answer,
          type: assignmentQuestion.type,
          content_metadata_id: assignmentQuestion.content_metadata_id,
        });
      });
      this.user_assignment = user_assignment;
    },
    async saveUserAssignment(status) {
      this.isSaveUserAssignmentDialog = false;
      this.isLoading = true;
      this.user_assignment.score = 0;
      this.user_assignment.total_questions = 0;
      this.user_assignment.answered_questions = 0;
      this.user_assignment.right_questions = 0;
      this.user_assignment.wrong_questions = 0;
      // Time Taken

      // For Objective Question
      if (this.assignment.assignment_type == "OBJECTIVE")
        this.user_assignment.result_declared = true;
      else this.user_assignment.result_declared = false;

      if (this.assignment.assignment_type != "DOCUMENT") {
        this.user_assignment.user_assignment_selected_answers.forEach(
          (uASA) => {
            this.user_assignment.total_questions++;
            if (uASA.question_type == "OBJECTIVE") {
              var correctOptionsSorted = uASA.correct_options.slice().sort();
              console.log();
              if (Object.keys(uASA.selected_options).length == 1) {
                uASA.selected_options = [parseFloat(uASA.selected_options)];
              }
              console.log(uASA);
              var selectedOptionsSorted = uASA.selected_options.slice().sort();
              selectedOptionsSorted = selectedOptionsSorted.map((o) =>
                parseInt(o)
              );
              if (
                correctOptionsSorted.length === uASA.selected_options.length &&
                correctOptionsSorted
                  .slice()
                  .sort()
                  .every(function (value, index) {
                    return value === selectedOptionsSorted[index];
                  })
              ) {
                uASA.is_correct = true;
                uASA.marks_obtained = uASA.marks;
                this.user_assignment.score += parseFloat(uASA.marks);
                this.user_assignment.answered_questions++;
                this.user_assignment.right_questions++;
              } else {
                uASA.is_correct = false;
                uASA.marks_obtained = 0;
                this.user_assignment.answered_questions++;
                this.user_assignment.wrong_questions++;
              }

              // uASA.marks_obtained = 0;
              // uASA.correct_options.forEach((_correctOption) => {
              //   uASA.selected_options.forEach((_selectedOption) => {
              //     if (_selectedOption == _correctOption) {
              //       uASA.marks_obtained += parseFloat(
              //         uASA.marks / uASA.correct_options.length
              //       );
              //     }
              //   });
              // });

              // if (uASA.selected_option_sr_no == uASA.correct_option_sr_no) {
              //   uASA.is_correct = true;
              //   uASA.marks_obtained = uASA.marks;
              //   this.user_assignment.score += parseFloat(uASA.marks);
              //   this.user_assignment.answered_questions++;
              //   this.user_assignment.right_questions++;
              // } else if (uASA.selected_option_sr_no != "") {

              // }
            } else if (uASA.question_type == "SUBJECTIVE") {
              this.user_assignment.answered_questions++;
            }
          }
        );
      }
      if (this.assignment.assignment_type == "CUMULATIVE") {
        let objective_question =
          this.user_assignment.user_assignment_selected_answers.filter(
            (d) => d.question_type == "OBJECTIVE"
          );
        if (objective_question.length == this.user_assignment.total_questions) {
          this.user_assignment.result_declared = true;
        }
      }

      // // For Subjective Question
      // if (this.assignment.assignment_type == "SUBJECTIVE") {
      //   this.user_assignment.result_declared = false;
      //   this.user_assignment.user_assignment_selected_answers.forEach(
      //     (uASA) => {
      //       this.user_assignment.total_questions++;
      //       if (uASA.description != "") {
      //         this.user_assignment.answered_questions++;
      //       }
      //     }
      //   );
      // }
      let user_assignment = await axios
        .post("/user_assignments", this.user_assignment)
        .then(async () => {
          this.user_assignment = user_assignment.data.data;
          // Save User Assignment Documents
          await this.handleFileUpload();
          // Save User Assignment Selected Answer Documents
          await this.handleSelectedAnswerFilesUpload();
        })
        .catch(() => {
          this.$router.push("/assignments");
        });
      this.isLoading = false;
      if (status != "Partial") this.isUserAssignmentSavedDialog = true;
    },
    async handleFileUpload() {
      if (this.$refs.file != null) {
        let attachment = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("id", this.user_assignment.id);
        formData.append("documentpath", attachment);
        await axios
          .post("upload_user_assignment_documentpath", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
      }
    },
    async handleSelectedAnswerFilesUpload() {
      for (const assignmentQuestion of this.assignment.assignment_questions) {
        let uASA = this.user_assignment.user_assignment_selected_answers.find(
          (uASA) => uASA.assignment_question_id == assignmentQuestion.id
        );
        if (uASA) {
          if (this.$refs[`uASADoc${assignmentQuestion.id}`] != null) {
            let attachment =
              this.$refs[`uASADoc${assignmentQuestion.id}`][0].files[0];
            let formData = new FormData();
            formData.append("id", uASA.id);
            formData.append("documentpath", attachment);
            await axios
              .post("upload_uasa_documentpath", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .catch(function () {
                console.log("FAILURE!!");
              });
          }
        }
      }
    },
    closeSavedDialog() {
      this.isUserAssignmentSavedDialog = false;
      if (this.assignment.category == "POWERWORD QUIZ")
        this.$router.push("/quiz-assignments");
      else this.$router.push("/assignments");
    },
    closeSaveUserAssignmentDialog() {
      this.isSaveUserAssignmentDialog = false;
    },
    finishTest() {
      this.saveUserAssignment();
    },
    savePartial(status = "Partial") {
      this.saveUserAssignment(status);
    },
  },
};
</script>

<style scoped>
.col {
  flex-basis: auto !important;
}
</style>
<style>
.v-application p {
  margin-bottom: 0px !important;
}
img {
  max-width: -webkit-fill-available !important;
}
</style>
