<template>
  <section style="background: white">
    <v-main>
      <v-container class="mt-8">
        <v-row justify="center" class="my-10">
          <span
            class="text-h2 font-weight-bold myFont"
            v-text="'Privacy Policy'"
          ></span>
        </v-row>
      </v-container>

      <section class="pl-12" style="text-align: justify">
        <v-col
          md="12"
          class="my-auto mb-8 px-8"
          style="height: 600px; overflow: scroll"
        >
          <!-- Main Info -->
          <p class="myFont mb-5" style="font-size: 25px" justfy="center">
            Warden Education Technologies Private Limited (“Company,” “Warden”,
            “we”, “us”, or “our”) is committed to protecting your privacy. We
            have prepared this Privacy Policy to describe to you our practices
            regarding the personal information we collect, use, and share in
            connection with the Company website, mobile app, and other software
            provided on or in connection with our services, as described in our
            Terms of Service available at www.infakt.co.in (collectively, the
            <strong>“Service”</strong> ).
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            By using the Services, application, website or any of our offerings,
            you confirm that you understand and consent to this Privacy Policy,
            together with any documents that may be expressly referred to and
            are incorporated by reference. You will be deemed to be a “User” who
            is legally bound by this Policy.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            If you use the services on behalf of an educational institution
            (such as your employer), you represent that you are authorised by
            such educational institution to accept this Privacy Policy on such
            educational institution’s behalf, and consent on behalf of such
            educational institution to our collection, use and disclosure of
            such educational institution’s information as described in this
            privacy policy.
          </p>

          <!-- COLLECTION OF INFORMATION -->
          <h1 class="text-h5 font-weight-bold py-8">
            COLLECTION OF INFORMATION
          </h1>

          <p class="myFont mb-5" style="font-size: 25px">
            As a visitor, you can browse our website, application or any of our
            offerings (hereinafter collectively referred to as
            <strong>“Application”</strong>) to explore more about us without
            providing any of your personal information. We automatically collect
            certain data about your interaction with our Applications such as
            pages visited and average time spent on the website, your internet
            protocol address, the unique identifier of your device and device
            information such as operating system, operating system version,
            browser plugins, crashes, system activity, hardware settings, date
            and time of your request to analyse ongoing trends and aid us in
            improving our customer experience.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            Once you register as a user on our Application or show interest in
            procuring information about our Services, we collect your personal
            information including but not limited to:
          </p>
          <ol type="a" class="ml-10 myFont mb-5" style="font-size: 25px">
            <li class="mb-5">
              Your name, e-mail address, phone number, display picture,
              username, password, residential address and other demographic
              information;
            </li>
            <li class="mb-5">
              Your gender, date of birth, class, subject and syllabus details;
            </li>
            <li class="mb-5">
              Information provided to us through our Services, interaction with
              our customer service, participation in surveys or marketing
              promotions;
            </li>
            <li class="mb-5">
              Reviews or ratings, syllabus and course preferences, account
              settings, (including preferences set in the " Account " section of
              our Application); and
            </li>
            <li class="mb-5">
              Banking details and billing information including Goods and
              Service Tax number, Permanent Account Number, debit card number,
              credit card number and billing address.
            </li>
          </ol>

          <p class="myFont mb-5" style="font-size: 25px">
            We also automatically collect some non-personal information like
            computer, device and browsing information viz. identification
            numbers of devices, I.P address, operating system of your device,
            browser type, referring/ exit pages and URLs, domain names, landing
            pages, pages viewed, platform types, number of clicks and other
            similar information when you access our Application or as a result
            of your interaction with the Services.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            We may also collect information from you if you communicate with us
            over the phone or via email. This information may include personal
            information. This information may be matched with and stored in
            connection with personal information provided or collected in
            connection with the Application.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            We and our authorized business partners may also collect information
            made publicly available through other third-party platforms, through
            online or offline databases that are otherwise legitimately obtained
            and are subject to the third party's terms of use and/or privacy
            statement.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            We also request for permissions while linking your Infakt account
            with your social media accounts (such as Twitter, Instagram or
            Facebook), and in the event that you have granted the permission to
            link such accounts, information shall be collected directly from
            your social media accounts.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            We only collect and use such information from you that we consider
            necessary for achieving an efficient and safe experience that is
            customized to your needs and expectations which in turn helps us to
            offer tutorials and works that are most likely to meet your needs
            and demands.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            Company shall use technologies such as cookies, clear gifs, log
            files, and flash cookies for several purposes, including to help
            understand how you interact with our Applications and Services to
            provide a better experience.
          </p>
          <!-- End of COLLECTION OF INFORMATION -->
          <!-- DATA USAGE -->
          <h1 class="text-h5 font-weight-bold py-8">DATA USAGE</h1>
          <p class="myFont mb-5" style="font-size: 25px">
            We process your personal information to run our business, provide
            the Services, personalize your experience on the Services, and
            improve the Services from time to time. Specifically, we use your
            personal information to:
          </p>
          <ol type="a" class="ml-10 myFont mb-5" style="font-size: 25px">
            <li class="mb-5">Verify your identity;</li>
            <li class="mb-5">
              Enhance your experience, analyse your interaction with the
              Services and issue any corrective measures if required;
            </li>
            <li class="mb-5">
              For any legal and regulatory/ audit purposes (for example, in
              response to subpoenas, court orders, and law enforcement or
              governmental requests or investigations), and to protect our legal
              rights or the rights of others (for example, by working to reduce
              the risk of fraud or misuse of the Service);
            </li>
            <li class="mb-5">Respond to your queries about the Services;</li>
            <li class="mb-5">
              Deliver to you any notices, alerts, or communications relevant to
              your use of the Services;
            </li>
            <li class="mb-5">
              Customise/ personalise the works being offered;
            </li>
            <li class="mb-5">
              To monitor, analyze, and describe usage patterns and performance
              of the Service, including aggregate metrics such as total number
              of visitors, traffic, and demographic patterns;
            </li>
            <li class="mb-5">
              To conduct system administration and system troubleshooting and to
              diagnose or fix technology problems
            </li>
            <li class="mb-5">
              Aggregate the anonymised information from registered users to
              perform market research, project planning, product development,
              troubleshoot problems, analyse user behaviour, marketing purposes,
              and promotions for the Company and its authorized business
              partners;
            </li>
            <li class="mb-5">For internal record-keeping purposes; and</li>
            <li class="mb-5">
              To enforce this Policy and/or the terms of service or any other
              related policy.
            </li>
          </ol>
          <p class="myFont mb-5" style="font-size: 25px">
            If you are a vendor or employee of the Company, we use your personal
            information for the purpose of making payments, payrolling and
            deducting taxes.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            If we receive formal written complaints, we shall contact the User
            who made the complaint to solve the problem at the earliest. We
            shall work with the appropriate regulatory authorities, including
            local data protection authorities, to resolve any complaints
            regarding the transfer of personal data that we cannot resolve with
            our User/s directly.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            We have physical, electronic, and procedural safeguards that comply
            with the laws prevalent in India to protect personal information
            about you. We seek to ensure compliance with the requirements laid
            down under the Information Technology Act, 2000 and Rules made
            thereunder to ensure the protection and preservation of your
            privacy.
          </p>
          <!-- End of DATA USAGE -->
          <!-- MINOR’S PRIVACY POLICY -->
          <h1 class="text-h5 font-weight-bold py-8">MINOR’S PRIVACY POLICY</h1>
          <p class="myFont mb-5" style="font-size: 25px">
            As a minor if you wish to use our products or Services, such use
            shall be made available to you by your legal guardian or parents,
            who has agreed to these terms. The Company will not be responsible
            for any consequences that arise as a result of misuse of any kind of
            our Application or any of our products or Services that may occur by
            virtue of any person including a minor registering for the
            Services/products provided. Any portion of the information
            containing personal data relating to minors provided by you shall be
            deemed to be given with the consent of the minor's legal guardian.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            We encourage parents and teachers to supervise the minor or
            student’s use of the Application. We identify and use your IP
            address to help diagnose problems with our server and to administer
            our website and Application. We further use your IP address to
            protect ourselves from fraud and to help identify you and to gather
            broad demographic information.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            We may collect our services usage information in order to improve
            functions or user interface but will only use this information in an
            anonymized fashion and not in association with your name, email, or
            other personally identifying information.
          </p>
          <!-- End of MINOR’S PRIVACY POLICY -->
          <!-- DATA RETENTION -->
          <h1 class="text-h5 font-weight-bold py-8">DATA RETENTION</h1>
          <p class="myFont mb-5" style="font-size: 25px">
            We will retain your personal data only for as long as necessary to
            fulfil our purposes provided herein or for quality assurance
            purposes, or to defend future legal claims, unless the law permits
            or requires a longer period of retention.
          </p>
          <!-- End of DATA RETENTION -->
          <!-- COOKIES -->
          <h1 class="text-h5 font-weight-bold py-8">COOKIES</h1>
          <p class="myFont mb-5" style="font-size: 25px">
            A cookie is a very small text document, which often includes an
            anonymous unique identifier. These are sent to your browser from the
            websites that you visit and are stored on your device's internal
            memory. We use cookies on our Application which help us to provide
            information which is targeted to your interests, keep track of your
            preferences, evaluate the effectiveness of our Application, analyse
            trends, identify logged in or registered users and to administer the
            Application.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            We further use cookies to determine which parts of our Application
            are most visited and what difficulties our visitors may experience
            in accessing our Application.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            Your use of our Application or Services with a browser that is
            configured to accept cookies constitutes acceptance of ours and any
            third-party cookies.
          </p>
          <!-- End of COOKIES -->
          <!-- DATA PROTECTION AND SECURITY -->
          <h1 class="text-h5 font-weight-bold py-8">
            DATA PROTECTION AND SECURITY
          </h1>
          <p class="myFont mb-5" style="font-size: 25px">
            We employ many different technological and procedural security
            measures like encryption, passwords, as well as some physical
            security measures, which are reasonably designed to help protect
            your personal information from and against any loss or misuse and
            unauthorized access or disclosure by individuals inside and outside
            the company. However, no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security. You therefore agree that any
            security breaches beyond the control of our reasonable security
            procedures are at your sole risk. Company is not responsible for the
            unauthorized use of your information or for any lost, stolen,
            compromised passwords, or for any activity on your account via
            unauthorized password activity.
          </p>
          <!-- End of DATA PROTECTION AND SECURITY -->
          <!-- DISCLOSURE OF PERSONAL INFORMATION -->
          <h1 class="text-h5 font-weight-bold py-8">
            DISCLOSURE OF PERSONAL INFORMATION
          </h1>
          <p class="myFont mb-5" style="font-size: 25px">
            We use other companies, agents, or contractors ("<strong
              >Service Providers</strong
            >") to perform services on our behalf or to assist us with the
            provision of Services to you. We engage Service Providers to provide
            marketing, advertising, communications, infrastructure, and IT
            services, to personalize and optimize our Services, to process
            credit card transactions or other payment methods, to provide
            customer service, to collect debts, to analyze and enhance data
            (including data about users’ interactions with our Service), and to
            process and administer consumer surveys. In the course of providing
            such services, these Service Providers may have access to your
            personal information or other information. We do not authorize them
            to use or disclose your personal information except in connection
            with providing their services.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            We may disclose your personal information if we believe that such
            action is necessary to:
          </p>
          <ol type="a" class="ml-10 myFont mb-5" style="font-size: 25px">
            <li class="mb-5">
              Comply with any legal process served on us or affiliated parties
              or other legal requirement of any governmental authority;
            </li>
            <li class="mb-5">
              Protect the safety of users of our site, us, or third parties;
            </li>
            <li class="mb-5">
              Mitigate our liability in an actual or potential lawsuit;
            </li>
            <li class="mb-5">
              Help investigate or prevent unauthorized transactions or other
              illegal activities;
            </li>
            <li class="mb-5">
              Protect and defend our rights or property, our website, the users
              of our website, and our affiliated parties
            </li>
          </ol>
          <p class="myFont mb-5" style="font-size: 25px">
            Following are some of the ways that your personal information may be
            disclosed:
          </p>
          <ol type="a" class="ml-10 myFont mb-5" style="font-size: 25px">
            <li class="mb-5">
              <b>Legal obligation:</b> We can disclose any personal information
              provided by you to law enforcement and other government officials
              as we believe appropriate or necessary to comply with our legal
              obligations or for investigation of fraud or any other activity
              which may expose us or you to legal liability.
            </li>
            <li class="mb-5">
              <b>Information disclosed to third party service providers:</b> We
              may contract with various third parties for the provision and
              maintenance our website, application, services and our business
              operations, and we may need to share your personal information and
              data generated by cookies with these vendors and service agencies.
              We do not authorize them to use or disclose your personal
              information except in connection with providing their services.
            </li>
            <li class="mb-5">
              <b>Information shared with our business partners:</b> We may share
              personal information with our business partners that are committed
              to serving your online needs and related services for purposes
              defined under this privacy policy or in the course of making
              transactions specifically requested by you.
            </li>
            <li class="mb-5">
              <b>Links to external service providers:</b> Our website and
              application may contain hyperlinks to other platforms which may
              collect personally identifiable information about you. We have no
              control over any websites or resources which are provided by
              companies or persons other than us and are not responsible for the
              privacy practices or the content of those external platforms and
              your use of any such other website or application is subject to
              the terms of use and privacy policy located on such external
              website or application. We recommend that you check the external
              platform’s privacy policies to see how the operators of such
              websites will utilize your personal information.
            </li>
            <li class="mb-5">
              <b>Internal departments:</b> We may share personal information
              with the internal investigation department of the Company or
              agencies appointed by us for investigation purposes. Also, if you
              are an employee, vendor/supplier of the Company, your personal
              information may be shared internally with the relevant departments
              on a need-to-know basis.
            </li>
          </ol>
          <!-- End of DISCLOSURE OF PERSONAL INFORMATION -->
          <!-- YOUR CONSENT -->
          <h1 class="text-h5 font-weight-bold py-8">YOUR CONSENT</h1>
          <p class="myFont mb-5" style="font-size: 25px">
            By using our Application, you are expressly consenting to our
            collection, processing, storing, disclosing and handling of your
            information as set forth in this Policy now and as amended by us.
            Processing, your information in any way, including, but not limited
            to, collecting, storing, deleting, using, combining, sharing,
            transferring and disclosing information, all of which activities
            will take place in India. If you reside outside India your
            information will be transferred, processed and stored in accordance
            with the applicable data protection laws of India.
          </p>
          <!-- End of YOUR CONSENT -->
          <!-- YOUR RIGHTS IN RELATION TO PERSONAL INFORMATION COLLECTED BY US -->
          <h1 class="text-h5 font-weight-bold py-8">
            YOUR RIGHTS IN RELATION TO PERSONAL INFORMATION COLLECTED BY US
          </h1>
          <p class="myFont mb-5" style="font-size: 25px">
            We would like you to be completely aware of your data protection
            rights. You have the right to withdraw your consent at any time in
            writing by sending an e-mail to us at
            <a href="mailto:it@infakt.co.in">it@infakt.co.in</a>, in accordance
            with the terms of this privacy policy. However, please note that
            withdrawal of consent will not be retrospective in nature and shall
            be applicable prospectively.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            If a user or customer makes a request to access, review, modify or
            correct any personal information or withdraw its consent to provide
            personal information, the Company will consider each such request in
            accordance with all applicable data protection laws and regulations.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            However, you agree and acknowledge that your right to access, modify
            and/or withdrawing your consent to provide personal information as
            mentioned above may be denied or limited by us, as may be required
            under any applicable law, law enforcement requests or under any
            judicial proceedings.
          </p>
          <!-- End of YOUR RIGHTS IN RELATION TO PERSONAL INFORMATION COLLECTED BY US -->
          <!-- MARKETING AND PROMOTION -->
          <h1 class="text-h5 font-weight-bold py-8">MARKETING AND PROMOTION</h1>
          <p class="myFont mb-5" style="font-size: 25px">
            We may send periodic promotional or informational emails to you. You
            may opt to discontinue such communications by following the opt-out
            instructions contained in the email.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            We may require reasonable amount of time to process any opt-out
            requests. If you opt-out of receiving emails about recommendations
            we think may interest you, we may still send emails on a
            case-to-case basis.
          </p>
          <!-- End of MARKETING AND PROMOTION -->
          <!-- PRIVACY POLICY UPDATES -->
          <h1 class="text-h5 font-weight-bold py-8">PRIVACY POLICY UPDATES</h1>
          <p class="myFont mb-5" style="font-size: 25px">
            This policy is current as of the effective date set forth above.
            Company may in its sole and absolute discretion modify the policy at
            any time and in any manner without any prior notification to you.
            Any change or revision will be posted on our Application. Thus, you
            are advised to review this page periodically for any changes. By
            continuing to use or access our Application after the updates to
            this Policy, you are consenting to the changes.
          </p>
          <!-- End of PRIVACY POLICY UPDATES -->
          <!-- DISCLAIMER -->
          <h1 class="text-h5 font-weight-bold py-8">DISCLAIMER</h1>
          <p class="myFont mb-5" style="font-size: 25px">
            We cannot ensure that all of your private communications and other
            personal information will never be disclosed in ways not otherwise
            described in this privacy policy. Therefore, although we are
            committed to protecting your privacy, we do not promise, and you
            should not expect, that your personal information will always remain
            private.
          </p>
          <p class="myFont mb-5" style="font-size: 25px">
            The Company does not warrant or assure that the information provided
            on the website to be accurate and error-free. In case of any
            discrepancies, users are advised to verify the information by
            getting in touch with us. Information provided on our website might
            get updated or changed from time to time, in such case it shall be
            the duty of the user to verify the contents of the website.
          </p>
          <!-- End of DISCLAIMER -->
          <!-- QUESTIONS AND SUGGESTIONS AND GRIEVANCE OFFICER -->
          <h1 class="text-h5 font-weight-bold py-8">
            QUESTIONS AND SUGGESTIONS AND GRIEVANCE OFFICER
          </h1>
          <p class="myFont mb-5" style="font-size: 25px">
            If you have any questions or comments about the privacy policy,
            please write to our Grievance Officer at
            <a href="mailto:it@infakt.co.in">it@infakt.co.in</a>.
          </p>
          <!-- End of QUESTIONS AND SUGGESTIONS AND GRIEVANCE OFFICER -->
          <!-- TERMS OF USE -->
          <h1 class="text-h5 font-weight-bold py-8">TERMS OF USE</h1>
          <p class="myFont mb-5" style="font-size: 25px">
            Please also visit our Terms of Use section which establishes the
            use, disclaimers, and limitations of liability governing the use of
            our Application. You can access the same by clicking here:
            <router-link to="terms-of-us">TERMS OF USE</router-link>.
          </p>
          <!-- End of TERMS OF USE -->
        </v-col>
      </section>
    </v-main>
    <Foot></Foot>
  </section>
</template>

<script>
import Foot from "../views/home/foot.vue";
import axios from "axios";

export default {
  name: "Aboutus",
  components: {
    Foot,
  },

  data() {
    return {
      boards: [],
      grades: [],
      endGrand: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let boards = await axios.get(`/boards`);
      this.boards = boards.data.data;
      let grades = await axios.get(`/grades`);
      this.grades = grades.data.data;
      // this.endGrand = this.grades.length;
      this.isLoading = false;
    },
  },
};
// import axios from "axios";
// export default {
//   name: "AboutUs",
//   data() {
//     return {
//       form: {
//         tagline: "",
//         info: "",
//         info_1: "",
//         description: "",
//       },
//       isLoading: false,
//     };
//   },
//   mounted() {
//     this.getData();
//   },
//   methods: {
//     async getData() {
//       this.isLoading = true;
//       let form = await axios.get(`/about_us/1}`);
//       this.form = form.data.data;
//       this.isLoading = false;
//     },
//   },
// };

const { default: foot } = require("./home/foot.vue");
</script>
<style scoped>
.myFont {
  font-family: "SF PRO" !important;
}
</style>
