<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col>
          <span class="text-h5 font-weight-bold" v-text="'Assignments'"></span>
          <addbutton
            target="_blank"
            :link="'/assignments/create'"
            v-if="roleName != 'STUDENT'"
          ></addbutton>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3" sm="4">
          <v-text-field
            v-model="search_keyword"
            label="Search ... "
            clearable
            @click:clear="search_keyword = null"
            prepend-inner-icon="mdi-magnify"
            @keydown.enter="getData"
            @change="page = 1"
          ></v-text-field>
        </v-col>
        <v-col
          md="3"
          sm="4"
          v-if="roleName != 'INFAKT TEACHER' && roleName != 'ACADEMIC TEAM'"
        >
          <v-autocomplete
            ref="classcode"
            v-model="form.classcode_id"
            :items="classcodeItems"
            :label="roleName == `TEACHER` ? 'Classcode' : 'Subject'"
            prepend-icon="mdi-application-edit"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col md="3" sm="4" class="mt-3">
          <v-btn color="primary" :disabled="validationErrors" @click="getData">
            SEARCH
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          sm="2"
          class="mt-3"
          v-if="roleName == 'INFAKT TEACHER' || roleName == 'ACADEMIC TEAM'"
        >
          <v-btn
            class="ml-9 my-auto"
            fab
            x-small
            :color="is_list == false ? 'primary' : ''"
            @click="is_list = false"
          >
            <v-icon dark> mdi-grid </v-icon>
          </v-btn>
          |
          <v-btn
            class="my-auto"
            fab
            x-small
            :color="is_list == true ? 'primary' : ''"
            @click="is_list = true"
          >
            <v-icon> mdi-format-list-numbered </v-icon>
          </v-btn>
        </v-col>
        <v-col sm="2" class="mr-1">
          <v-autocomplete
            style="min-width: 125px"
            hide-details
            ref="Sort"
            v-model="sort_type"
            :items="sortItems"
            label="Sort By"
            @change="sortAssignments"
            prepend-icon="mdi-sort"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <loader v-if="isLoading"></loader>
      <v-tabs fixed-tabs background-color="primary" dark v-model="tab">
        <v-tab
          @click="AddItems('IN PROGRESS')"
          href="#tab-1"
          v-if="roleName == 'STUDENT' || IsTeacherView()"
        >
          In Progress [{{ inProgressAssignmentCount }}]
        </v-tab>
        <v-tab href="#tab-2" v-if="roleName == 'STUDENT' || IsTeacherView()">
          {{ roleName == "STUDENT" ? "Overdue" : "Completed" }} [{{
            teacherCompletedAssignmentCount
          }}]
        </v-tab>
        <v-tab href="#tab-3" v-if="roleName == 'STUDENT'">
          Completed [{{ studentCompleteAssignmentCount }}]
        </v-tab>
        <v-tab href="#tab-4" v-if="roleName == 'STUDENT' || IsTeacherView()">
          Upcoming [{{ upcomingAssignmentCount }}]
        </v-tab>
        <v-tab
          href="#tab-5"
          v-if="
            roleName == 'INFAKT TEACHER' ||
            roleName == 'ACADEMIC TEAM' ||
            roleName == 'TEACHER'
          "
        >
          Drafts [{{ draftAssignmentCount }}]
        </v-tab>
        <v-tab
          href="#tab-7"
          v-if="roleName == 'INFAKT TEACHER' || roleName == 'ACADEMIC TEAM'"
        >
          PENDING [{{ pendingAssignmentCount }}]
        </v-tab>
        <v-tab
          @click="AddItems('INFAKT TEAM')"
          href="#tab-8"
          v-if="
            roleName == 'INFAKT TEACHER' ||
            roleName == 'ACADEMIC TEAM' ||
            roleName == 'TEACHER'
          "
        >
          {{ roleName == "TEACHER" ? "INFAKT TEAM" : "APPROVED" }} [{{
            infaktTeamAssignmentCount
          }}]
        </v-tab>
        <v-tab
          href="#tab-9"
          v-if="roleName == 'INFAKT TEACHER' || roleName == 'ACADEMIC TEAM'"
        >
          REJECTED [{{ rejectedAssignmentCount }}]
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in 9" :key="i" :value="'tab-' + i">
          <v-card flat>
            <v-card-text>
              <v-row>
                <!-- Grid View -->
                <template v-if="is_list == false && i == 8">
                  <template v-for="(assignment, a) in InfaktTeamAssignments">
                    <v-col sm="4" :key="`assignment${a}`">
                      <v-card
                        :img="
                          assignment.category == 'COMPETITION'
                            ? 'https://t4.ftcdn.net/jpg/00/39/74/99/360_F_39749952_HvHpkvYuo6LLlhDfbAsJ1TgUWI4TBy6J.jpg'
                            : assignment.is_game
                            ? 'https://png.pngtree.com/background/20210710/original/pngtree-reading-dream-education-training-fresh-poster-background-picture-image_1019916.jpg'
                            : 'https://t3.ftcdn.net/jpg/05/00/34/58/360_F_500345899_4OqmtspFst6SRnNQvLj7h7TfKOrBwTer.jpg'
                        "
                        elevation="10"
                        class="mx-auto rounded-lg"
                        :height="
                          IsTeacherView() ||
                          roleName === 'INFAKT TEACHER' ||
                          roleName === 'ACADEMIC TEAM'
                            ? '400'
                            : '390'
                        "
                      >
                        <!-- IF Content Linked & Feature Image Found -->
                        <template
                          v-if="
                            assignment.content_id &&
                            assignment.only_content?.featured_image_path
                          "
                        >
                          <v-img
                            elevation="10"
                            style="margin-top: -4px"
                            max-height="210"
                            contain
                            class="feature"
                            :src="`${
                              mediaUrl +
                              assignment.only_content.featured_image_path
                            }`"
                          ></v-img>
                        </template>
                        <!-- IF Collection Linked & Feature Image Found -->
                        <template
                          v-else-if="
                            assignment.collection_id &&
                            assignment.collection?.image_path
                          "
                        >
                          <v-img
                            style="margin-top: -4px"
                            max-height="210"
                            contain
                            class="feature"
                            :src="`${
                              mediaUrl + assignment.collection.image_path
                            }`"
                          ></v-img>
                        </template>
                        <!-- IF Nothing Linked -->
                        <template v-else></template>
                        <v-card-title
                          class="justify-center grey lighten-2 primary--text pa-1 accent-1 rounded-lg"
                        >
                          <v-row>
                            <v-col
                              lg="10"
                              md="10"
                              sm="8"
                              xs="10"
                              align="center"
                              class="wrap pr-0 col-xs-10"
                              style="width: 150"
                            >
                              <v-tooltip
                                bottom
                                v-if="
                                  assignment.category ==
                                    'PERSONALIZED ASSIGNMENT' &&
                                  roleName == 'TEACHER'
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="
                                      `#` +
                                      assignment.unique_string.split('#')[1]
                                    "
                                    fab
                                    x-small
                                    style="
                                      height: 20px;
                                      margin-bottom: 5px;
                                      width: 20px;
                                    "
                                  >
                                    <v-icon size="15">mdi-account</v-icon>
                                  </v-btn>
                                </template>
                                <span>This is a Personalized Assignment</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">{{
                                    assignment.assignment_title
                                  }}</span>
                                </template>
                                <span>{{ assignment.assignment_title }}</span>
                              </v-tooltip>
                              <b></b>
                            </v-col>
                            <v-col align="right" class="pl-0">
                              <v-btn
                                icon
                                small
                                color="primary"
                                v-if="assignment.only_content"
                                @click="ShowLearningOutcome(assignment)"
                              >
                                <v-img
                                  height="25"
                                  width="10"
                                  :src="require('../../assets/logo-i.png')"
                                  contain
                                ></v-img>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-text class="pt-3">
                          <v-row>
                            <v-col
                              md="4"
                              sm="4"
                              lg="4"
                              class="wrap"
                              :style="`${
                                //If Multiple Classes then add Custom CSS
                                assignment.assignment_classcodes?.length > 1
                                  ? 'margin-right: initial;'
                                  : ''
                              }`"
                              v-if="
                                assignment.assignment_classcodes?.length > 0
                              "
                            >
                              <!-- For More than 1 Class -->
                              <template
                                v-if="
                                  isMultipleClasscode(assignment).length > 1
                                "
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      <b>
                                        <span
                                          class="smallScreen"
                                          style="font-size: x-small"
                                        >
                                          Multiple Classes
                                        </span>
                                        <span
                                          class="midScreen"
                                          style="font-size: smaller"
                                        >
                                          Multiple Classes
                                        </span>
                                        <span class="largeScreen">
                                          Multiple Classes
                                        </span>
                                      </b>
                                    </span>
                                  </template>
                                  <span>
                                    <template
                                      v-for="(
                                        assignmentClasscode, ac
                                      ) in isMultipleClasscode(assignment)"
                                    >
                                      <span :key="`assignmentClasscode_s${ac}`">
                                        {{
                                          trim_classcode(
                                            assignmentClasscode.classcode
                                              .classcode
                                          )
                                        }}</span
                                      >
                                      <br
                                        :key="`assignmentClasscode_SB${ac}`"
                                      />
                                    </template>
                                  </span>
                                </v-tooltip>
                              </template>
                              <!-- For More than 1 Class but only one Class to be Displayed-->
                              <template
                                v-else-if="
                                  isMultipleClasscode(assignment).length == 1
                                "
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      <b :key="`assignmentClasscode_s`">
                                        {{
                                          trim_classcode(
                                            isMultipleClasscode(assignment)[0]
                                              .classcode.classcode
                                          )
                                        }}</b
                                      >
                                    </span>
                                  </template>
                                  <span>{{
                                    isMultipleClasscode(assignment)[0].classcode
                                      .classcode
                                  }}</span>
                                </v-tooltip>
                              </template>
                              <!-- For Single Class -->
                              <template v-else>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      <b :key="`assignmentClasscode_s`">
                                        {{
                                          trim_classcode(
                                            assignment.assignment_classcodes[0]
                                              .classcode.classcode
                                          )
                                        }}</b
                                      >
                                    </span>
                                  </template>
                                  <span>{{
                                    assignment.assignment_classcodes[0]
                                      .classcode.classcode
                                  }}</span>
                                </v-tooltip>
                              </template>
                            </v-col>
                            <v-col
                              md="4"
                              sm="4"
                              lg="4"
                              align="center"
                              style="min-width: fit-content"
                            >
                              <b>
                                <span class="smallScreen">
                                  {{ assignment.assignment_type | limit3 }}
                                  <!-- SM -->
                                </span>
                                <span class="midScreen">
                                  <!-- MD -->
                                  {{ assignment.assignment_type | limit3 }}
                                </span>
                                <span class="largeScreen">
                                  <!-- LG -->
                                  {{ assignment.assignment_type }}
                                </span>
                              </b>
                            </v-col>
                            <v-col align="right">
                              <v-tooltip
                                right
                                class="ml-3"
                                v-if="assignment.duration"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    x-small
                                    color="error"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon size="20">mdi-alarm</v-icon>
                                  </v-btn>
                                </template>
                                <span
                                  >This is a timed assignment [
                                  {{ assignment.duration }} mins ]</span
                                >
                              </v-tooltip>
                              <b v-if="assignment.content_description">
                                <span
                                  class="smallScreen"
                                  style="font-size: x-small"
                                >
                                  {{
                                    assignment.content_description
                                      ? assignment.content_description.level
                                      : ""
                                  }}L
                                </span>
                                <span
                                  class="midScreen"
                                  style="font-size: smaller"
                                >
                                  {{
                                    assignment.content_description
                                      ? assignment.content_description.level
                                      : ""
                                  }}L
                                </span>
                                <span class="largeScreen">
                                  {{
                                    assignment.content_description
                                      ? assignment.content_description.level
                                      : ""
                                  }}L
                                </span>
                              </b>
                            </v-col>
                          </v-row>
                          <!-- Date Section -->
                          <v-row>
                            <v-col md="4" sm="4" xs="4">
                              <template>
                                <template
                                  v-if="
                                    assignment.assignment_classcodes?.length > 0
                                  "
                                >
                                  <v-row>
                                    <v-col md="4" sm="4" class="pl-2 pa-0">
                                      <v-icon class="smallScreen" size="20">
                                        mdi-calendar-month
                                      </v-icon>
                                      <v-icon class="midScreen" size="25">
                                        mdi-calendar-month
                                      </v-icon>
                                      <v-icon class="largeScreen" size="35">
                                        mdi-calendar-month
                                      </v-icon>
                                    </v-col>
                                    <v-col
                                      md="8"
                                      class="pl-1 pa-0"
                                      style="min-width: max-content"
                                    >
                                      <h5 style="height: 14px">Start Date</h5>
                                      <!-- For Multiple Class -->
                                      <template
                                        v-if="
                                          IsMultipleDate(
                                            assignment,
                                            'START_DATE'
                                          ) > 1
                                        "
                                      >
                                        <h5>
                                          <v-tooltip bottom>
                                            <template
                                              v-slot:activator="{ on, attrs }"
                                            >
                                              <span v-bind="attrs" v-on="on">
                                                <b class="primary--text"
                                                  >Varies</b
                                                >
                                              </span>
                                            </template>
                                            <span>
                                              <template
                                                v-for="(
                                                  assignmentClasscode, aaC
                                                ) in assignment.assignment_classcodes"
                                              >
                                                <!-- <classcode-element
                                                  :key="`assignmentClasscode${aaC}`"
                                                  :classcode="
                                                    assignmentClasscode
                                                      .classcode.classcode
                                                  "
                                                  :type="
                                                    roleName == 'STUDENT'
                                                      ? 'SUBJECT'
                                                      : roleName == 'TEACHER'
                                                      ? 'STANDARD_SUBJECT'
                                                      : ''
                                                  "
                                                ></classcode-element> -->
                                                <span
                                                  :key="`assignmentClasscode_s${aaC}`"
                                                >
                                                  {{
                                                    trim_classcode(
                                                      assignmentClasscode
                                                        .classcode.classcode
                                                    )
                                                  }}</span
                                                >
                                                :
                                                {{
                                                  assignmentClasscode.start_date
                                                    | ddmmyyyy
                                                }}
                                                <br
                                                  :key="`assignmentClasscode${aaC}`"
                                                />
                                              </template>
                                            </span>
                                          </v-tooltip>
                                        </h5>
                                      </template>
                                      <!-- For More than 1 but Only 1 visible -->
                                      <template
                                        v-else-if="
                                          IsMultipleDate(
                                            assignment,
                                            'START_DATE'
                                          ) == 1
                                        "
                                      >
                                        <h5>
                                          {{
                                            isMultipleClasscode(assignment)[0]
                                              .start_date | ddmmyyyy
                                          }}
                                        </h5>
                                      </template>
                                      <!-- For Single Class -->
                                      <template v-else>
                                        <h5>
                                          {{
                                            isMultipleClasscode(assignment)[0]
                                              .start_date | ddmmyyyy
                                          }}
                                        </h5>
                                      </template>
                                    </v-col>
                                  </v-row>
                                </template>
                              </template>
                            </v-col>
                            <v-col md="4" sm="4" xs="4">
                              <template
                                v-if="
                                  assignment.assignment_classcodes?.length > 0
                                "
                              >
                                <v-row>
                                  <v-col md="4" class="pl-2 pa-0">
                                    <v-icon class="smallScreen" size="20">
                                      mdi-calendar-clock
                                    </v-icon>
                                    <v-icon class="midScreen" size="25">
                                      mdi-calendar-clock
                                    </v-icon>
                                    <v-icon class="largeScreen" size="35">
                                      mdi-calendar-clock
                                    </v-icon>
                                  </v-col>
                                  <v-col
                                    md="8"
                                    class="pl-1 pa-0"
                                    style="min-width: max-content"
                                  >
                                    <!-- <h5 style="height: 14px"> -->
                                    <h5
                                      class="smallScreen"
                                      style="height: 14px; margin-left: 6px"
                                    >
                                      Due Date
                                    </h5>
                                    <h5
                                      class="midScreen"
                                      style="height: 14px; margin-left: 6px"
                                    >
                                      Due Date
                                    </h5>
                                    <h5
                                      class="largeScreen"
                                      style="height: 14px"
                                    >
                                      Due Date
                                    </h5>
                                    <!-- For Multiple Class -->
                                    <template
                                      v-if="
                                        IsMultipleDate(assignment, 'END_DATE') >
                                        1
                                      "
                                    >
                                      <h5>
                                        <v-tooltip bottom>
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <span v-bind="attrs" v-on="on">
                                              <b class="primary--text">
                                                <span
                                                  class="smallScreen"
                                                  style="
                                                    height: 14px;
                                                    margin-left: 6px;
                                                  "
                                                >
                                                  Varies
                                                </span>
                                                <span
                                                  class="midScreen"
                                                  style="
                                                    height: 14px;
                                                    margin-left: 6px;
                                                  "
                                                >
                                                  Varies
                                                </span>
                                                <span
                                                  class="largeScreen"
                                                  style="height: 14px"
                                                >
                                                  Varies
                                                </span>
                                              </b>
                                            </span>
                                          </template>
                                          <span>
                                            <template
                                              v-for="(
                                                assignmentClasscode, aC
                                              ) in isMultipleClasscode(
                                                assignment
                                              )"
                                            >
                                              <span
                                                :key="`assignmentClasscode_s${aC}`"
                                              >
                                                {{
                                                  trim_classcode(
                                                    assignmentClasscode
                                                      .classcode.classcode
                                                  )
                                                }}</span
                                              >
                                              :
                                              {{
                                                assignmentClasscode.end_date
                                                  | ddmmyyyy
                                              }}
                                              <br
                                                :key="`assignment_Classcode_Br${aC}`"
                                              />
                                            </template>
                                          </span>
                                        </v-tooltip>
                                      </h5>
                                    </template>
                                    <!-- For More than 1 but Only 1 visible -->
                                    <template
                                      v-else-if="
                                        IsMultipleDate(
                                          assignment,
                                          'END_DATE'
                                        ) == 1
                                      "
                                    >
                                      <h5>
                                        {{
                                          isMultipleClasscode(assignment)[0]
                                            .end_date | ddmmyyyy
                                        }}
                                      </h5>
                                    </template>
                                    <!-- For Single Class -->
                                    <template v-else>
                                      <h5>
                                        {{
                                          assignment.assignment_classcodes[0]
                                            .end_date | ddmmyyyy
                                        }}
                                      </h5>
                                    </template>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-col>
                            <v-col
                              md="4"
                              sm="4"
                              xs="4"
                              align="right"
                              class="pt-0"
                              style="min-width: auto"
                            >
                              <b>
                                <span class="smallScreen">
                                  {{ assignment.maximum_marks }} Mks
                                </span>
                                <span class="midScreen">
                                  <!-- MD -->
                                  {{ assignment.maximum_marks }} Mks
                                </span>
                                <span class="largeScreen">
                                  {{ assignment.maximum_marks }} Marks
                                </span>
                              </b>
                            </v-col>
                          </v-row>
                          <!-- End for Date Section -->
                          <!-- Button Section -->
                          <v-row>
                            <v-col>
                              <!-- If linked to Content -->
                              <template v-if="assignment.content_id">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      elevation="8"
                                      v-bind="attrs"
                                      v-on="on"
                                      :class="`${
                                        IsTeacherView() ||
                                        roleName === 'INFAKT TEACHER' ||
                                        roleName === 'ACADEMIC TEAM'
                                          ? 'mb-2'
                                          : ''
                                      } rounded-lg`"
                                      :x-small="
                                        IsTeacherView() ||
                                        roleName === 'INFAKT TEACHER' ||
                                        roleName === 'ACADEMIC TEAM'
                                      "
                                      align="center"
                                      color="orange"
                                      dark
                                      target="_blank"
                                      style="width: inherit"
                                      :to="`/articles/${assignment.content_id}/${assignment.content_description_id}`"
                                    >
                                      <span class="smallScreen">
                                        <v-icon size="15" dark>
                                          mdi-script-text
                                        </v-icon>
                                      </span>
                                      <span class="midScreen">
                                        <v-icon size="15" dark>
                                          mdi-script-text
                                        </v-icon>
                                      </span>
                                      <span class="largeScreen">
                                        view Article
                                      </span></v-btn
                                    >
                                  </template>
                                  <span>View Linked Article</span>
                                </v-tooltip>
                              </template>
                              <!-- If linked to Collection -->
                              <template v-if="assignment.collection_id">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      elevation="8"
                                      v-bind="attrs"
                                      v-on="on"
                                      :class="`${
                                        IsTeacherView() ||
                                        roleName === 'INFAKT TEACHER' ||
                                        roleName === 'ACADEMIC TEAM'
                                          ? 'mb-2'
                                          : ''
                                      } rounded-lg`"
                                      :x-small="
                                        IsTeacherView() ||
                                        roleName === 'INFAKT TEACHER' ||
                                        roleName === 'ACADEMIC TEAM'
                                      "
                                      align="center"
                                      color="orange"
                                      dark
                                      target="_blank"
                                      style="width: inherit"
                                      :to="`/library/collections/${assignment.collection_id}?assignment_id=${assignment.id}`"
                                      ><span class="smallScreen">
                                        <v-icon size="15" dark>
                                          mdi-table-eye
                                        </v-icon>
                                      </span>
                                      <span class="midScreen">
                                        <v-icon size="15" dark>
                                          mdi-table-eye
                                        </v-icon>
                                      </span>
                                      <span class="largeScreen">
                                        view Collection
                                      </span></v-btn
                                    >
                                  </template>
                                  <span>View Linked Collection</span>
                                </v-tooltip>
                              </template>
                              <template
                                v-if="
                                  roleName === 'INFAKT TEACHER' ||
                                  roleName === 'ACADEMIC TEAM' ||
                                  IsTeacherView()
                                "
                              >
                                <br />
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      color="orange"
                                      align="center"
                                      elevation="8"
                                      class="rounded-lg"
                                      dark
                                      x-small
                                      @click="ShowDetails(assignment.id)"
                                      style="width: inherit"
                                      ><span class="smallScreen">
                                        <v-icon size="15" dark>
                                          mdi-file-eye
                                        </v-icon>
                                      </span>
                                      <span class="midScreen">
                                        <v-icon size="15" dark>
                                          mdi-file-eye
                                        </v-icon>
                                      </span>
                                      <span class="largeScreen">
                                        View Assignment
                                      </span></v-btn
                                    >
                                  </template>
                                  <span>Preview Assignment</span>
                                </v-tooltip>
                              </template>
                            </v-col>
                            <!-- If Teacher -->
                            <template v-if="IsTeacherView()">
                              <!-- Teacher Made Assignment -->
                              <template
                                v-if="
                                  assignment.company_id != null &&
                                  assignment.status != 3
                                "
                              >
                                <!-- If  Personalized Assignment -->
                                <template
                                  v-if="
                                    assignment.category ==
                                    'PERSONALIZED ASSIGNMENT'
                                  "
                                >
                                  <!-- Edit Button -->
                                  <v-col
                                    v-if="!isTeacherCompleted(assignment, i)"
                                  >
                                    <v-tooltip left>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          :loading="
                                            isLoadingEditPersonalizedAssignment
                                          "
                                          v-bind="attrs"
                                          v-on="on"
                                          class="smallScreen rounded-lg py-6"
                                          color="primary"
                                          dark
                                          elevation="8"
                                          small
                                          @click="
                                            ShowPersonalizedAssignmentDetails(
                                              assignment.id
                                            )
                                          "
                                          align="center"
                                          style="width: inherit"
                                        >
                                          <v-icon dark size="25">
                                            mdi-pencil
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Edit Student Mapping</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          :loading="
                                            isLoadingEditPersonalizedAssignment
                                          "
                                          v-bind="attrs"
                                          v-on="on"
                                          class="midScreen rounded-lg py-6"
                                          elevation="8"
                                          color="primary"
                                          small
                                          dark
                                          @click="
                                            ShowPersonalizedAssignmentDetails(
                                              assignment.id
                                            )
                                          "
                                          align="center"
                                          style="width: inherit"
                                        >
                                          <v-icon
                                            dark
                                            class="midScreen"
                                            size="25"
                                          >
                                            mdi-pencil
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Edit Student Mapping</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          :loading="
                                            isLoadingEditPersonalizedAssignment
                                          "
                                          elevation="8"
                                          v-bind="attrs"
                                          v-on="on"
                                          class="largeScreen rounded-lg py-6"
                                          color="primary"
                                          large
                                          dark
                                          @click="
                                            ShowPersonalizedAssignmentDetails(
                                              assignment.id
                                            )
                                          "
                                          align="center"
                                        >
                                          <v-icon size="30" dark>
                                            mdi-pencil
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Edit Student Mapping</span>
                                    </v-tooltip>
                                  </v-col>
                                  <!-- Check Submission Button -->
                                  <v-col>
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          align="center"
                                          color="orange"
                                          class="rounded-lg py-6"
                                          dark
                                          elevation="8"
                                          :to="`/assignments/${assignment.id}/submissions`"
                                          style="width: inherit"
                                        >
                                          <v-icon
                                            dark
                                            v-if="
                                              !isTeacherCompleted(assignment, i)
                                            "
                                          >
                                            mdi-file-document-check-outline
                                          </v-icon>
                                          <template v-else>
                                            <span class="smallScreen">
                                              <v-icon dark>
                                                mdi-file-document-check-outline
                                              </v-icon>
                                            </span>
                                            <span class="midScreen">
                                              <v-icon dark>
                                                mdi-file-document-check-outline
                                              </v-icon>
                                            </span>
                                            <span class="largeScreen">
                                              Check
                                              <br />
                                              Submissions
                                            </span>
                                          </template>
                                        </v-btn>
                                      </template>
                                      <span>Check Submissions</span>
                                    </v-tooltip>
                                  </v-col>
                                </template>
                                <!-- If Assigment -->
                                <template v-else>
                                  <v-col>
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          elevation="8"
                                          v-bind="attrs"
                                          v-on="on"
                                          v-if="
                                            assignment.category == 'ASSIGNMENT'
                                          "
                                          color="orange"
                                          class="rounded-lg py-6"
                                          dark
                                          :to="`/assignments/${assignment.id}/submissions`"
                                          style="
                                            width: inherit;
                                            /* height: -webkit-fill-available; */
                                          "
                                          align="center"
                                        >
                                          <span class="smallScreen">
                                            <v-icon dark>
                                              mdi-file-document-check-outline
                                            </v-icon>
                                          </span>
                                          <span class="midScreen">
                                            <v-icon dark>
                                              mdi-file-document-check-outline
                                            </v-icon>
                                          </span>
                                          <span class="largeScreen">
                                            Check
                                            <br />
                                            Submissions
                                          </span>
                                        </v-btn>
                                      </template>
                                      <span>Check Submissions</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          elevation="8"
                                          v-bind="attrs"
                                          v-on="on"
                                          v-if="
                                            assignment.category == 'COMPETITION'
                                          "
                                          color="orange"
                                          dark
                                          :to="`/assignments/${assignment.id}/submissions`"
                                        >
                                          Check Result
                                        </v-btn>
                                      </template>
                                      <span>View Competition Result</span>
                                    </v-tooltip>
                                  </v-col>
                                </template>
                              </template>
                              <!-- Infakt Made Assignment -->
                              <template v-if="assignment.company_id == null">
                                <v-col>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        elevation="8"
                                        v-bind="attrs"
                                        v-on="on"
                                        v-if="
                                          assignment.category == 'ASSIGNMENT'
                                        "
                                        color="primary"
                                        class="rounded-lg py-6"
                                        dark
                                        :to="`/assignments/create?copyFromAssignmentId=${assignment.id}`"
                                        style="
                                          width: inherit;
                                          /* height: -webkit-fill-available; */
                                        "
                                        align="center"
                                        ><span class="smallScreen">
                                          <v-icon dark>
                                            mdi-content-copy
                                          </v-icon>
                                        </span>
                                        <span class="midScreen">
                                          <v-icon dark>
                                            mdi-content-copy
                                          </v-icon>
                                        </span>
                                        <span class="largeScreen">
                                          Copy
                                          <br />
                                          Assignment
                                        </span>
                                      </v-btn>
                                    </template>
                                    <span>Copy Assignment</span>
                                  </v-tooltip>
                                </v-col>
                              </template>
                            </template>
                            <!-- If Student -->
                            <template v-if="roleName == 'STUDENT'">
                              <v-col>
                                <!-- Result Declared Section -->
                                <template
                                  v-if="assignment.my_results?.length > 0"
                                >
                                  <!-- For Game -->
                                  <template v-if="assignment.is_game">
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          target="_blank"
                                          v-if="
                                            assignment.category ==
                                              'ASSIGNMENT' ||
                                            assignment.category ==
                                              'PERSONALIZED ASSIGNMENT'
                                          "
                                          v-bind="attrs"
                                          v-on="on"
                                          elevation="8"
                                          color="orange"
                                          class="rounded-lg py-4"
                                          dark
                                          :href="assignment.game_result_link"
                                          style="
                                            width: inherit;
                                            /* height: -webkit-fill-available; */
                                          "
                                          align="center"
                                        >
                                          <span class="smallScreen">
                                            <v-icon class="mr-2"
                                              >mdi-receipt-text-check
                                            </v-icon>
                                          </span>
                                          <span class="midScreen">
                                            <v-icon class="mr-2"
                                              >mdi-receipt-text-check
                                            </v-icon>
                                          </span>
                                          <span class="largeScreen">
                                            Check
                                            <br />
                                            RESULT
                                          </span>
                                        </v-btn>
                                      </template>
                                      <span>Check Result</span>
                                    </v-tooltip>
                                  </template>
                                  <!-- For Assignments -->
                                  <template v-else>
                                    <!-- For Assignment -->
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          v-if="
                                            assignment.category ==
                                              'ASSIGNMENT' ||
                                            assignment.category ==
                                              'PERSONALIZED ASSIGNMENT'
                                          "
                                          v-bind="attrs"
                                          v-on="on"
                                          elevation="8"
                                          color="orange"
                                          class="rounded-lg py-4"
                                          dark
                                          @click="checkResult(assignment)"
                                          style="
                                            width: inherit;
                                            /* height: -webkit-fill-available; */
                                          "
                                          align="center"
                                        >
                                          <span class="smallScreen">
                                            <v-icon class="mr-2"
                                              >mdi-receipt-text-check
                                            </v-icon>
                                          </span>
                                          <span class="midScreen">
                                            <v-icon class="mr-2"
                                              >mdi-receipt-text-check
                                            </v-icon>
                                          </span>
                                          <span class="largeScreen">
                                            Check
                                            <br />
                                            RESULT
                                          </span>
                                        </v-btn>
                                      </template>
                                      <span>Check Result</span>
                                    </v-tooltip>
                                    <!-- For Competition -->
                                    <v-btn
                                      v-if="
                                        assignment.category == 'COMPETITION'
                                      "
                                      color="orange"
                                      dark
                                      :to="`/assignments/${assignment.id}/submissions`"
                                    >
                                      View Competition Result
                                    </v-btn>
                                  </template>
                                </template>
                                <template v-else>
                                  <!-- Take Assignment Dialog -->
                                  <v-dialog
                                    v-model="isTakeAssignmentDialogs[a]"
                                    max-width="400"
                                    v-if="
                                      roleName == 'STUDENT' &&
                                      ((isAssignmentBetween(assignment) &&
                                        i == 1) ||
                                        assignment.my_assignment_extensions.find(
                                          (mae) =>
                                            mae.is_approved == 1 &&
                                            isDatePassed(
                                              mae.approved_extension_date
                                            )
                                        ))
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-if="
                                          assignment.category == 'ASSIGNMENT' ||
                                          assignment.category ==
                                            'PERSONALIZED ASSIGNMENT'
                                        "
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="8"
                                        class="rounded-lg py-4"
                                        dark
                                        style="
                                          width: inherit;
                                          /* height: -webkit-fill-available; */
                                        "
                                        align="center"
                                      >
                                        BEGIN
                                      </v-btn>
                                    </template>
                                    <v-container>
                                      <v-card
                                        elevation="10"
                                        class="rounded-tl-xl rounded-br-xl"
                                      >
                                        <v-card-title
                                          class="justify-center primary accent-1 white--text"
                                        >
                                          <span v-if="assignment.duration">
                                            Once started, you will have
                                            {{ assignment.duration }} minutes to
                                            complete the assignment.
                                            <br />
                                            Do you wish to begin?
                                          </span>
                                          <span v-else>
                                            Do you want to begin the assignment?
                                          </span>
                                        </v-card-title>
                                        <v-card-text class="pa-0">
                                          <div class="row pt-0">
                                            <div class="col-md-6">
                                              <v-btn
                                                text
                                                x-large
                                                dark
                                                block
                                                color="success"
                                                :to="`/assignments/${assignment.id}/take-assignment`"
                                              >
                                                <div
                                                  class="text-h6 font-weight-bold"
                                                >
                                                  YES
                                                </div>
                                              </v-btn>
                                            </div>
                                            <div class="col-md-6">
                                              <v-btn
                                                dark
                                                block
                                                x-large
                                                text
                                                color="red"
                                                @click="
                                                  closeTakeAssignmentDialog(a)
                                                "
                                              >
                                                <div
                                                  class="text-h6 font-weight-bold"
                                                >
                                                  NO
                                                </div>
                                              </v-btn>
                                            </div>
                                          </div>
                                        </v-card-text>
                                      </v-card>
                                    </v-container>
                                  </v-dialog>
                                  <!-- Extension Section -->
                                  <template v-if="isAssignmentOld(assignment)">
                                    <template
                                      v-if="
                                        assignment.my_assignment_extensions
                                          ?.length == 0
                                      "
                                    >
                                      <!-- Extend Assignment Dialog -->
                                      <template
                                        v-if="isExtensionAcceptable(assignment)"
                                      >
                                        <v-tooltip top>
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-btn
                                              v-bind="attrs"
                                              v-on="on"
                                              dark
                                              color="orange"
                                              elevation="8"
                                              class="rounded-lg py-4"
                                              align="center"
                                              style="width: inherit"
                                              @click="
                                                RequestExtension(assignment)
                                              "
                                            >
                                              <span class="smallScreen">
                                                <v-icon dark>
                                                  mdi-clipboard-text-clock-outline
                                                </v-icon>
                                              </span>
                                              <span class="midScreen">
                                                <v-icon dark>
                                                  mdi-clipboard-text-clock-outline
                                                </v-icon>
                                              </span>
                                              <span class="largeScreen">
                                                EXTEND
                                                <br />
                                                ASSIGNMENT
                                              </span>
                                            </v-btn>
                                          </template>
                                          <span>Request for Extension</span>
                                        </v-tooltip>
                                      </template>
                                      <!-- Due Date to request Passed -->
                                      <template v-else>
                                        <p
                                          class="font-weight-bold"
                                          style="
                                            color: red;
                                            min-width: fit-content;
                                          "
                                        >
                                          <v-btn
                                            elevation="8"
                                            dark
                                            align="center"
                                            class="smallScreen rounded-lg py-4"
                                            style="width: inherit"
                                            color="error"
                                            @click="
                                              getStatusBasedDialog(
                                                'DUE DATE PASSED'
                                              )
                                            "
                                          >
                                            <v-icon dark>
                                              mdi-alert-outline
                                            </v-icon>
                                          </v-btn>
                                          <v-btn
                                            elevation="8"
                                            dark
                                            align="center"
                                            class="midScreen rounded-lg py-4"
                                            style="width: inherit"
                                            color="error"
                                            @click="
                                              getStatusBasedDialog(
                                                'DUE DATE PASSED'
                                              )
                                            "
                                          >
                                            <v-icon dark>
                                              mdi-alert-outline
                                            </v-icon>
                                          </v-btn>
                                          <span
                                            class="largeScreen"
                                            style="font-size: smaller"
                                          >
                                            Due date to request an extension has
                                            passed.
                                          </span>
                                        </p>
                                      </template>
                                    </template>
                                    <!-- Extension Already Sent then Show Message -->
                                    <template v-else>
                                      <!-- Request Pending -->
                                      <p
                                        class="font-weight-bold"
                                        style="min-width: fit-content"
                                        v-if="
                                          assignment.my_assignment_extensions.find(
                                            (mae) => mae.is_approved == 0
                                          )
                                        "
                                      >
                                        <v-btn
                                          elevation="8"
                                          dark
                                          align="center"
                                          class="smallScreen rounded-lg py-4"
                                          style="width: inherit"
                                          color="gray"
                                          @click="
                                            getStatusBasedDialog('REQUEST SENT')
                                          "
                                        >
                                          <v-icon dark>
                                            mdi-clock-outline
                                          </v-icon>
                                        </v-btn>
                                        <v-btn
                                          elevation="8"
                                          dark
                                          align="center"
                                          class="midScreen rounded-lg py-4"
                                          style="width: inherit"
                                          color="gray"
                                          @click="
                                            getStatusBasedDialog('REQUEST SENT')
                                          "
                                        >
                                          <v-icon dark>
                                            mdi-clock-outline
                                          </v-icon>
                                        </v-btn>
                                        <span class="largeScreen">
                                          Request for extension already sent.
                                        </span>
                                      </p>
                                      <!-- Request Approved But Extension Date Passed-->
                                      <template
                                        v-if="
                                          isAprrovedExtensionDatePassed(
                                            assignment
                                          )
                                        "
                                      >
                                        <p
                                          class="font-weight-bold"
                                          style="color: red"
                                        >
                                          <v-btn
                                            elevation="8"
                                            dark
                                            align="center"
                                            class="smallScreen rounded-lg py-4"
                                            style="width: inherit"
                                            color="error"
                                            @click="
                                              getStatusBasedDialog(
                                                'APPROVED EXTENSION DUE DATE PASSED'
                                              )
                                            "
                                          >
                                            <v-icon dark>
                                              mdi-alert-outline
                                            </v-icon>
                                          </v-btn>
                                          <v-btn
                                            elevation="8"
                                            dark
                                            align="center"
                                            class="midScreen rounded-lg py-4"
                                            style="width: inherit"
                                            color="error"
                                            @click="
                                              getStatusBasedDialog(
                                                'APPROVED EXTENSION DUE DATE PASSED'
                                              )
                                            "
                                          >
                                            <v-icon dark>
                                              mdi-alert-outline
                                            </v-icon>
                                          </v-btn>
                                          <span class="largeScreen">
                                            Approved Extension Due date has
                                            passed.
                                          </span>
                                        </p>
                                      </template>
                                      <!-- Request Denied -->
                                      <p
                                        class="font-weight-bold"
                                        style="color: red"
                                        v-if="
                                          assignment.my_assignment_extensions.find(
                                            (mae) => mae.is_approved == 2
                                          )
                                        "
                                      >
                                        <v-btn
                                          elevation="8"
                                          dark
                                          align="center"
                                          class="smallScreen rounded-lg py-4"
                                          style="width: inherit"
                                          color="error"
                                          @click="
                                            getStatusBasedDialog(
                                              'REQUEST DENIED'
                                            )
                                          "
                                        >
                                          <v-icon dark>
                                            mdi-alert-outline
                                          </v-icon>
                                        </v-btn>
                                        <v-btn
                                          elevation="8"
                                          dark
                                          align="center"
                                          class="midScreen rounded-lg py-4"
                                          style="width: inherit"
                                          color="error"
                                          @click="
                                            getStatusBasedDialog(
                                              'REQUEST DENIED'
                                            )
                                          "
                                        >
                                          <v-icon dark>
                                            mdi-alert-outline
                                          </v-icon>
                                        </v-btn>
                                        <span class="largeScreen">
                                          Request for extension denied.
                                        </span>
                                      </p>
                                    </template>
                                  </template>
                                </template>
                              </v-col>
                            </template>
                            <!-- Not Approved -->
                            <template
                              v-if="
                                roleName == 'ACADEMIC TEAM' ||
                                assignment.status != 1
                              "
                            >
                              <v-col>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      class="smallScreen rounded-lg py-6"
                                      elevation="8"
                                      color="primary"
                                      small
                                      dark
                                      :to="`/assignments/${assignment.id}`"
                                      align="center"
                                      style="width: inherit"
                                    >
                                      <v-icon
                                        dark
                                        class="smallScreen"
                                        size="25"
                                      >
                                        mdi-pencil
                                      </v-icon>
                                    </v-btn></template
                                  >
                                  <span>Edit Assignment</span>
                                </v-tooltip>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      class="midScreen rounded-lg py-6"
                                      elevation="8"
                                      color="primary"
                                      small
                                      dark
                                      :to="`/assignments/${assignment.id}`"
                                      align="center"
                                      style="width: inherit"
                                    >
                                      <v-icon dark class="midScreen" size="25">
                                        mdi-pencil
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Edit Assignment</span>
                                </v-tooltip>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      class="largeScreen rounded-lg py-6"
                                      elevation="8"
                                      color="primary"
                                      large
                                      dark
                                      :to="`/assignments/${assignment.id}`"
                                      align="center"
                                    >
                                      <v-icon
                                        size="30"
                                        dark
                                        class="largeScreen"
                                      >
                                        mdi-pencil
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Edit Assignment</span>
                                </v-tooltip>
                              </v-col>
                              <v-col
                                v-if="
                                  (roleName == 'INFAKT TEACHER' ||
                                    roleName == 'ACADEMIC TEAM') &&
                                  assignment.status == 2
                                "
                              >
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      class="smallScreen rounded-lg py-6"
                                      elevation="8"
                                      color="primary"
                                      small
                                      dark
                                      target="_blank"
                                      @click="
                                        showAssignmentRemark(assignment.id)
                                      "
                                      align="center"
                                      style="width: inherit"
                                    >
                                      <v-icon> mdi-eye-outline </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>View Remark</span>
                                </v-tooltip>
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      class="midScreen rounded-lg py-6"
                                      elevation="8"
                                      color="primary"
                                      small
                                      dark
                                      target="_blank"
                                      @click="
                                        showAssignmentRemark(assignment.id)
                                      "
                                      align="center"
                                      style="width: inherit"
                                    >
                                      <v-icon> mdi-eye-outline </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>View Remark</span>
                                </v-tooltip>
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      class="largeScreen rounded-lg py-6"
                                      elevation="8"
                                      color="primary"
                                      large
                                      dark
                                      target="_blank"
                                      @click="
                                        showAssignmentRemark(assignment.id)
                                      "
                                      align="center"
                                    >
                                      <v-icon> mdi-eye-outline </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>View Remark</span>
                                </v-tooltip>
                              </v-col>
                              <v-col v-if="assignment.status == 3">
                                <!-- Delete Draft Assignment -->
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      elevation="8"
                                      dark
                                      align="center"
                                      class="rounded-lg py-6"
                                      style="
                                        width: inherit;
                                        /* height: -webkit-fill-available; */
                                      "
                                      color="error"
                                      @click="Delete_Draft(assignment.id)"
                                    >
                                      <v-icon dark> mdi-delete </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Delete this Draft</span>
                                </v-tooltip>
                              </v-col>
                            </template>
                          </v-row>
                          <!-- End for Button Section -->
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </template>
                </template>
                <template v-else>
                  <v-simple-table
                    fixed-header
                    height="525px"
                    style="width: -webkit-fill-available"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" style="min-width: 98px">
                            Assignment ID
                          </th>
                          <th class="text-left" style="min-width: 135px">
                            Assignment Title
                          </th>
                          <th class="text-center" style="min-width: 100px">
                            Created By
                          </th>
                          <th class="text-center" style="min-width: 80px">
                            Lexile Level
                          </th>
                          <th class="text-center" style="min-width: 120px">
                            Type
                          </th>
                          <th class="text-center" style="min-width: 85px">
                            Max Mark
                          </th>
                          <th class="text-right" style="min-width: 70px">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(assignment, a) in assignments"
                          :key="`assignment${a}`"
                        >
                          <template
                            v-if="
                              isInProgress(assignment, i) || //1st TAB
                              isTeacherCompleted(assignment, i) || //2nd TAB
                              isStudentCompleted(assignment, i) || //3rd TAB
                              isUpcoming(assignment, i) || //4th TAB
                              isDraft(assignment, i) || //5th TAB
                              isByAdmin(assignment, i) || //6th TAB
                              isPending(assignment, i) || //7th TAB
                              isInfaktTeam(assignment, i) || //8th TAB
                              isRejected(assignment, i) //9th TAB
                            "
                          >
                            <td>{{ assignment.id }}</td>
                            <td>{{ assignment.assignment_title }}</td>
                            <td class="text-center">
                              {{ assignment.created_by.name }}
                            </td>
                            <td class="text-center">
                              {{ assignment.content_description.level }}
                            </td>
                            <td class="text-center">
                              {{ assignment.assignment_type }}
                            </td>
                            <td class="text-center">
                              {{ assignment.maximum_marks }}
                            </td>
                            <td class="text-right">
                              <template v-if="assignment.content_id">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-if="assignment.content_description_id"
                                      target="_blank"
                                      class="my-auto"
                                      color="primary"
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      :to="`/articles/${assignment.content_id}/${assignment.content_description_id}`"
                                    >
                                      View
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      target="_blank"
                                      class="my-auto"
                                      color="primary"
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                      :to="`/articles/${assignment.content_id}`"
                                    >
                                      View
                                    </v-btn>
                                  </template>
                                  <span>View linked Article</span>
                                </v-tooltip>
                              </template>
                              <template v-if="assignment.collection_id">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      target="_blank"
                                      class="my-auto"
                                      small
                                      color="primary"
                                      :to="`/library/collections/${assignment.collection_id}?assignment_id=${assignment.id}`"
                                    >
                                      View
                                    </v-btn>
                                  </template>
                                  <span>View linked Collection</span>
                                </v-tooltip>
                              </template>
                              <template
                                v-if="
                                  (roleName == 'INFAKT TEACHER' ||
                                    roleName == 'ACADEMIC TEAM') &&
                                  assignment.status == 2
                                "
                              >
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      target="_blank"
                                      size="20"
                                      v-bind="attrs"
                                      v-on="on"
                                      rounded
                                      color="primary"
                                      class="ma-2"
                                      @click="
                                        showAssignmentRemark(assignment.id)
                                      "
                                    >
                                      <v-icon> mdi-eye-outline </v-icon>
                                      <span class="ml-1"> Remark </span>
                                    </v-btn>
                                  </template>
                                  <span>View Remark</span>
                                </v-tooltip>
                              </template>
                              <v-btn
                                v-if="assignment.status != 1"
                                class="ml-2 my-auto"
                                fab
                                x-small
                                color="primary"
                                :to="`/assignments/${assignment.id}`"
                              >
                                <v-icon dark> mdi-pencil </v-icon>
                              </v-btn>
                              <!-- Delete Draft Assignment -->
                              <v-tooltip bottom v-if="assignment.status == 3">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    class="my-auto"
                                    color="error"
                                    @click="Delete_Draft(assignment.id)"
                                  >
                                    <v-icon dark size="38"> mdi-delete </v-icon>
                                  </v-btn>
                                </template>
                                <span>Delete this Draft</span>
                              </v-tooltip>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-row>
              <!-- Result Not Declared Dialog -->
              <v-dialog
                v-model="isResultNotDeclaredDialog"
                max-width="300px"
                persistent
              >
                <v-card elevation="2" align="right">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Result is not yet declared
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-btn
                      color="primary"
                      @click.stop="closeResultNotDeclaredDialog"
                      >Close</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- Assignment Extension Done Dialod -->
              <v-dialog
                v-model="isAssignmentExtensionDoneDialog"
                max-width="400px"
                persistent
              >
                <v-card elevation="2" align="right">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Assignment Extension Request Sent
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-btn
                      color="primary"
                      @click.stop="closeAssignmentExtensionDoneDialog"
                      >Close</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- Remark message Pop Up -->
              <v-dialog v-model="isRemarkDialog" max-width="900px" persistent>
                <v-card elevation="2" align="right">
                  <v-card-title
                    class="justify-center primary accent-1 white--text"
                  >
                    Remark
                  </v-card-title>
                  <br />
                  <v-card-text>
                    <v-row justify="center">
                      <v-col>
                        <span v-html="assignment_details.remarks"></span>
                      </v-col>
                    </v-row>
                    <v-btn
                      color="primary"
                      @click.stop="
                        (isRemarkDialog = false), (assignment_details = [])
                      "
                      >Close</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <!-- Status based Assignment Message Dialog -->
    <v-dialog v-model="isStatusbasedDialog" max-width="300px">
      <v-card elevation="2">
        <v-card-title class="justify-center primary accent-1 white--text">
          {{ msg }}
        </v-card-title>
        <v-card-text align="right" class="py-2 pr-2">
          <v-btn
            color="primary"
            @click.stop="(isStatusbasedDialog = false), (msg = '')"
            >Close</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--End of Status based Assignment Message Dialog -->
    <!--Request for Extension Dialog -->
    <v-dialog v-model="isExtendAssignmentDialog" max-width="400px">
      <v-card elevation="10">
        <v-card-title class="justify-center primary accent-1 white--text">
          Request to Extend the Assignment
        </v-card-title>
        <br />
        <v-card-text>
          <v-text-field
            outlined
            class="pb=0"
            v-model="assignment_extension.extension_reason"
            label="Reason for Extension"
            prepend-inner-icon="mdi-draw-pen"
          ></v-text-field>
          <v-menu
            v-model="requestExtensionDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="assignment_extension.expected_extension_date"
                label="Requested Extension Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :error-messages="errors.expected_extension_date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="assignment_extension.expected_extension_date"
              @change="requestExtensionDateMenu = false"
              :min="nowDate"
            ></v-date-picker>
          </v-menu>
          <loader v-if="isRequestExtensionLoading"></loader>
          <v-btn
            v-else
            color="primary"
            @click="closeExtendAssignmentDialog(assignment_details)"
          >
            Request Extension
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--End of Request for Extension Dialog -->
    <!-- Delete Assignment Success Dialog Box -->
    <v-dialog v-model="is_deleted_assignment" width="600">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col sm="3" align="center">
              <v-icon
                large
                style="color: green; font-size: 90px; width: 200px !important"
              >
                mdi-delete-empty
              </v-icon>
            </v-col>
            <v-col sm="9" align="center">
              <v-card-text>
                <v-row>
                  <v-col>
                    <h1 style="color: green">Hurray!</h1>
                    <br />
                    <h3>Removed Assignment successfully.</h3>
                    <br />
                    <v-btn
                      color="success"
                      @click="(is_deleted_assignment = false), $router.go(0)"
                    >
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- /Delete Bookmark Success Dialog Box -->
    <!-- Single Article Info Box-->
    <v-dialog v-model="isShowInfoBox" max-width="400px">
      <v-card elevation="2" class="rounded-tl-xl rounded-br-xl">
        <v-card-title class="justify-center primary accent-1 white--text">
          Info Box
        </v-card-title>
        <v-card-text>
          <v-row>
            <template>
              <v-tabs
                height="35"
                fixed-tabs
                v-model="infoTab"
                class="mt-5"
                background-color="blue-grey lighten-4"
                color="primary"
              >
                <v-tab
                  v-for="(info_board, ib) in contentInfoBoards"
                  :key="`info_board${ib}`"
                >
                  {{ info_board.board.name }}
                </v-tab>
              </v-tabs>
            </template>
            <v-tabs-items v-model="infoTab">
              <!-- Board Tab -->
              <v-tab-item
                v-for="(info_board, ib) in contentInfoBoards"
                :key="`info_board${ib}`"
              >
                <v-card flat>
                  <v-card-text>
                    <template
                      v-if="info_board.content_info_board_details.length"
                    >
                      <v-tabs
                        height="25"
                        center-active
                        next-icon="mdi-arrow-right-bold-box-outline"
                        prev-icon="mdi-arrow-left-bold-box-outline"
                        fixed-tabs
                        v-model="innerTab1"
                        color="primary"
                      >
                        <v-tab
                          v-for="(grade, g) in info_board.grade_details"
                          :key="`grade${g}`"
                        >
                          {{ grade.grade }}
                        </v-tab>
                        <v-tab-item
                          v-for="(grade, g) in info_board.grade_details"
                          :key="`grade${g}`"
                        >
                          <v-card flat style="min-width: 345px">
                            <v-card-text>
                              <template v-if="grade.subjects">
                                <v-tabs
                                  height="35"
                                  center-active
                                  next-icon="mdi-arrow-right-bold-box-outline"
                                  prev-icon="mdi-arrow-left-bold-box-outline"
                                  fixed-tabs
                                  show-arrows
                                  v-model="innerTab2"
                                  background-color="blue-grey lighten-5"
                                >
                                  <v-tab
                                    v-for="(subject, s) in grade.subjects"
                                    :key="`subject${s}`"
                                    style="min-width: 170"
                                  >
                                    {{ subject.subject }}
                                    <v-icon class="ml-1">{{
                                      subjectIcon(subject.subject)
                                    }}</v-icon>
                                  </v-tab>
                                  <v-tab-item
                                    v-for="(subject, s) in grade.subjects"
                                    :key="`subject${s}`"
                                    class="mt-3"
                                  >
                                    <v-row v-if="subject.chapter">
                                      <v-col>
                                        <b>Chapter:</b>
                                        <div v-html="subject.chapter"></div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col>
                                        <b>Learning Outcome:</b>
                                        <div
                                          v-html="subject.learning_outcome"
                                        ></div>
                                      </v-col>
                                    </v-row>
                                  </v-tab-item>
                                </v-tabs>
                              </template>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </template>
                    <!-- Board Tab -->
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Assignment Details Dialog Box -->
    <assignment-preview
      v-model="show_details"
      @changeStatus="changeStatus"
      :assignment_details="assignment_details"
      :show_details="show_details"
    ></assignment-preview>
    <!-- Edit Personalized Assignment Dialog Box -->
    <v-dialog
      v-model="show_pA"
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
    >
      <v-card style="background: rgb(252, 239, 239)">
        <v-toolbar color="white">
          <v-btn icon @click="show_pA = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Edit Student Mapping</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid>
          <v-card class="my-4" v-if="pA.assignment_classcodes">
            <v-row align="center">
              <div style="flex: 1 1 auto"></div>
              <v-col sm="12">
                <v-card-text>
                  <v-row>
                    <v-col md="8">
                      Classcode :
                      <h1>
                        {{ pA.assignment_classcodes[0].classcode.classcode }}
                      </h1>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="6">
                      Start date :
                      <h1>
                        {{ pA.start_date }}
                      </h1>
                    </v-col>
                    <v-col md="6">
                      End Date :
                      <h1>
                        {{ pA.end_date }}
                      </h1>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="mt-9 mb-4" color="grey lighten-3">
            <v-card-text>
              <v-simple-table
                fixed-header
                :height="`${students.length ? '390px' : ''}`"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Sr No</th>
                      <th class="text-left">Student</th>
                      <th class="text-left">Submission Status</th>
                      <th class="text-left">Lexile</th>
                      <th class="text-left">Assignment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(student, s) in students" :key="`student${s}`">
                      <td>
                        {{ s + 1 }}
                      </td>
                      <td v-text="student.name"></td>
                      <td>
                        {{ student.submission_status }}
                      </td>

                      <td>
                        <v-autocomplete
                          class="mt-4 mb-2"
                          dense
                          :disabled="student.submission_status != 'PENDING'"
                          v-model="student.selected_variant_id"
                          @input="getVariantAssignments(student)"
                          :items="student.variants"
                          :error-messages="
                            errors[`students.${s}.selected_variant_id`]
                          "
                          label="Select Lexile"
                          clearable
                        >
                        </v-autocomplete>
                      </td>
                      <td class="text-right">
                        <div
                          class="text-center"
                          v-if="student.selected_variant"
                        >
                          <v-autocomplete
                            :ref="`students.${s}.selected_assignment_id`"
                            class="mt-4 mb-2"
                            dense
                            :loading="isloadingVariantAssignments"
                            :disabled="student.submission_status != 'PENDING'"
                            v-model="student.selected_assignment_id"
                            :items="student.variant_assignments"
                            label="Select Assignment"
                            clearable
                            :error-messages="
                              errors[`students.${s}.selected_assignment_id`]
                            "
                            append-icon="mdi-clipboard-text"
                          >
                            <template
                              v-slot:append-outer
                              v-if="student.selected_assignment_id"
                            >
                              <v-slide-x-reverse-transition mode="out-in">
                                <v-icon
                                  @click="
                                    ShowDetails(student.selected_assignment_id)
                                  "
                                >
                                  mdi-eye</v-icon
                                >
                              </v-slide-x-reverse-transition>
                            </template>
                          </v-autocomplete>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-row>
            <v-spacer></v-spacer>
            <v-col sm="3" align="right">
              <v-btn
                :loading="isSavingPA"
                :disabled="isSavingPA"
                color="primary"
                dark
                x-large
                @click="save_personalized_assignemnt()"
              >
                Update Student Mapping
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-main>
</template>
<script>
import axios from "axios";
import moment from "moment";
import addbutton from "@/components/addbutton.vue";
import assignmentPreview from "@/components/assignment-preview.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { assignmentPreview, addbutton, Loader },
  name: "AssignmentsPage",
  data() {
    return {
      isLoading: false,
      isRequestExtensionLoading: false,
      isLoadingEditPersonalizedAssignment: false,
      isResultNotDeclaredDialog: false,
      isTakeAssignmentDialog: false,
      isRemarkDialog: false,
      isShowInfoBox: false,
      is_deleted_assignment: false,
      isTakeAssignmentDialogs: [],
      isExtendAssignmentDialog: false,
      isAssignmentExtensionDoneDialog: false,
      isStatusbasedDialog: false,
      is_list: false,
      isloadingVariantAssignments: false,
      show_details: false,
      show_pA: false,
      nowDate: new Date().toISOString().slice(0, 10),
      msg: "",
      tab: "tab-1",
      infoTab: "tab-1",
      innerTab1: "innertab-1",
      tabs: [],
      innerTab2: "",
      search_keyword: "",
      validationErrors: false,
      validationClasscodeErrors: [],
      validationSectionErrors: [],
      assignments: [],
      contentInfoBoards: [],
      assignment_details: [],
      pA: {},
      personalizedAssignmentForm: {},
      InProgressAssignments: [],
      InfaktTeamAssignments: [],
      assignment_extension: {
        assignment_id: "",
        user_id: "",
        extension_reason: "",
        expected_extension_date: "",
        approved_extension_date: "",
      },
      requestExtensionDateMenu: false,
      form: {
        section_id: "",
        standard_id: "",
        classcode_id: "",
        teacher_id: "",
      },
      sort_type: "LATEST",
      sortItems: ["LATEST", "OLDEST", "TITLE"],
      classcodes: [],
      students: [],
      classcodeItems: [],
      isInfoBoxDialog: false,
      isSavingPA: false,
      article_detail: {},
      ArticleName: "",
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  computed: {
    // Compute the count of visible assignments
    inProgressAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isInProgress(assignment, 1);
      }).length;
    },
    teacherCompletedAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isTeacherCompleted(assignment, 2);
      }).length;
    },
    studentCompleteAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isStudentCompleted(assignment, 3);
      }).length;
    },
    upcomingAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isUpcoming(assignment, 4);
      }).length;
    },
    draftAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isDraft(assignment, 5);
      }).length;
    },
    adminAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isByAdmin(assignment, 6);
      }).length;
    },
    pendingAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isPending(assignment, 7);
      }).length;
    },
    infaktTeamAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isInfaktTeam(assignment, 8);
      }).length;
    },
    rejectedAssignmentCount() {
      return this.assignments.filter((assignment) => {
        return this.isRejected(assignment, 9);
      }).length;
    },
  },
  mounted() {
    this.form.teacher_id = this.$route.query.teacher_id
      ? this.$route.query.teacher_id
      : "";
    this.getMasters();
    this.getData();
    if (this.$route.query.tab) this.tab = this.$route.query.tab;
    if (this.roleName == "STUDENT") {
      this.user.user_classcodes.forEach((sc) => {
        this.classcodeItems.push({
          id: sc.classcode.id,
          text: sc.classcode.subject_name,
          value: sc.classcode.id,
        });
      });
    }
  },
  methods: {
    Hello() {
      console.log("Assignment Variable Changed");
    },
    IsTeacherView() {
      let response = false;
      if (
        this.roleName === "TEACHER" ||
        (this.roleName == "ADMIN" && this.form.teacher_id) ||
        (this.roleName == "SUPER ADMIN" &&
          this.company.id != null &&
          this.form.teacher_id)
      ) {
        response = true;
      }
      return response;
    },
    validateSelectedItem() {
      if (this.roleName != "STUDENT") {
        this.validationErrors = true;
        if (
          this.form.standard_id != null &&
          (this.form.section_id == "" || this.form.section_id == null)
        ) {
          this.validationSectionErrors = ["Select the Section"];
        } else if (
          this.form.section_id != null &&
          (this.form.classcode_id == "" || this.form.classcode_id == null)
        ) {
          this.validationSectionErrors = [];
          this.validationClasscodeErrors = ["Select the Classcode"];
        } else {
          this.validationErrors = false;
          this.validationSectionErrors = [];
          this.validationClasscodeErrors = [];
        }
      }
    },
    async getMasters() {
      try {
        this.form.teacher_id;
        let query = "teacher_id=" + this.form.teacher_id;
        let masters = await axios.get(`users/masters?${query}`);
        masters = masters.data;
        this.classcodes = masters.classcodes;
        // ClassCodes
        this.classcodes = [...new Set(this.classcodes)];
        this.classcodes.forEach((classcode) => {
          let originalClasscode = classcode.classcode;
          let classcode_array = classcode.classcode.split("/");
          let standard_section = classcode_array[1];
          let subject_section = classcode_array[2];
          let trimmed_classcode = "";
          if (this.roleName == "STUDENT") {
            trimmed_classcode = subject_section;
          } else if (this.roleName == "TEACHER") {
            trimmed_classcode = standard_section + "/" + subject_section;
          } else {
            trimmed_classcode = originalClasscode;
          }
          this.classcodeItems.push({
            id: classcode.id,
            text: trimmed_classcode,
            value: classcode.id,
          });
        });
        const subjectSet = [];
        this.classcodes.forEach((subjectObj) => {
          subjectSet.push(subjectObj.subject_name);
        });
        this.subject_names = [...new Set(subjectSet)];
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        this.isLoading = true;
        this.assignments = [];
        this.form.classcode_id = this.form.classcode_id
          ? this.form.classcode_id
          : "";
        this.search_keyword = this.search_keyword ? this.search_keyword : "";
        let assignments = [];
        let query =
          "classcode_id=" +
          this.form.classcode_id +
          "&search_keyword=" +
          this.search_keyword +
          "&teacher_id=" +
          this.form.teacher_id;
        assignments = await axios.get(`/assignments?${query}`);
        if (this.$route.query.type == "competition")
          this.assignments = assignments.data.data.filter(
            (d) => d.category == "COMPETITION"
          );
        else
          this.assignments = assignments.data.data.filter(
            (d) => d.category != "COMPETITION"
          );

        this.FilterAssignmentBasedOnTab();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    FilterAssignmentBasedOnTab() {
      this.assignments.forEach((assignment) => {
        if (
          this.isInProgress(assignment) &&
          this.InProgressAssignments.length < 9
        )
          this.InProgressAssignments.push(assignment);
        if (
          this.isInfaktTeam(assignment) &&
          this.InfaktTeamAssignments.length < 9
        )
          this.InfaktTeamAssignments.push(assignment);
      });
      console.log(this.InfaktTeamAssignments);
      console.log(this.InProgressAssignments);
    },
    AddItems(type) {
      let current_lenght = 0;
      let new_lenght = 0;
      switch (type) {
        case "INFAKT TEAM":
          current_lenght = this.InfaktTeamAssignments.length;
          this.InfaktTeamAssignments = [];
          new_lenght = current_lenght + 9;
          this.assignments.forEach((assignment) => {
            if (
              this.isInfaktTeam(assignment) &&
              this.InfaktTeamAssignments.length < new_lenght
            )
              this.InfaktTeamAssignments.push(assignment);
          });
          console.log("InfaktTeamAssignments");
          console.log(this.InfaktTeamAssignments);
          break;
        case "IN PROGRESS":
          current_lenght = this.InProgressAssignments.length;
          this.InProgressAssignments = [];
          new_lenght = current_lenght + 10;
          this.assignments.forEach((assignment) => {
            if (
              this.isInProgress(assignment) &&
              this.InProgressAssignments.length < new_lenght
            )
              this.InProgressAssignments.push(assignment);
          });
          console.log("InProgressAssignments");
          console.log(this.InProgressAssignments);
          break;

        default:
          break;
      }
    },
    checkResult(assignment) {
      if (assignment.my_results[0].result_declared) {
        this.$router.push(
          `/assignments/${assignment.id}/check-result/${assignment.my_results[0].id}`
        );
      } else this.isResultNotDeclaredDialog = true;
    },
    closeResultNotDeclaredDialog() {
      this.isResultNotDeclaredDialog = false;
    },
    async closeExtendAssignmentDialog(assignment) {
      try {
        this.isRequestExtensionLoading = true;
        this.assignment_extension.assignment_id = assignment.id;
        this.assignment_extension.user_id = this.user.id;
        let assignment_extension = "";
        let response = await axios
          .post("assignment_extensions", this.assignment_extension)
          .catch(() => {
            this.isRequestExtensionLoading = false;
          });
        assignment_extension = response.data.data;
        if (assignment_extension) {
          assignment.assignment_extensions.push(assignment_extension);
          this.isExtendAssignmentDialog = false;
          this.isAssignmentExtensionDoneDialog = true;
        }
        this.isRequestExtensionLoading = false;
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
    closeAssignmentExtensionDoneDialog() {
      this.isAssignmentExtensionDoneDialog = false;
    },
    closeTakeAssignmentDialog() {
      this.isTakeAssignmentDialogs = [];
    },
    showAssignmentRemark(assignment_id) {
      this.assignment_details = [];
      this.assignment_details = this.assignments.filter(
        (assignment) => assignment.id == assignment_id
      )[0];
      if (this.assignment_details) {
        this.isRemarkDialog = true;
      }
    },
    isAssignmentNow(assignment) {
      var startDate;
      var currentDate = moment().format("YYYY-MM-DD");
      var response = false;
      // For Student
      if (this.roleName == "STUDENT") {
        let classcodes = this.isMultipleClasscode(assignment);
        if (classcodes.length > 0) {
          classcodes.forEach((aC) => {
            startDate = moment(aC.start_date).format("YYYY-MM-DD");
            if (startDate > currentDate) response = true;
          });
        }
        if (assignment.start_date != null) {
          startDate = moment(assignment.start_date).format("YYYY-MM-DD");
          if (startDate > currentDate) response = true;
        }
      }
      // For Teacher
      else {
        if (assignment.assignment_classcodes) {
          assignment.assignment_classcodes.forEach((aC) => {
            startDate = moment(aC.start_date).format("YYYY-MM-DD");
            if (startDate > currentDate) response = true;
          });
        }
      }
      return response;
    },
    isAssignmentBetween(assignment) {
      var currentDate = moment().format("YYYY-MM-DD");
      var response = false;
      var startDate;
      var endDate;
      // For Student
      if (this.roleName == "STUDENT") {
        let classcodes = this.isMultipleClasscode(assignment);
        if (classcodes?.length > 0) {
          classcodes.forEach((aC) => {
            startDate = moment(aC.start_date).format("YYYY-MM-DD");
            endDate = moment(aC.end_date).format("YYYY-MM-DD");
            if (startDate <= currentDate && endDate >= currentDate)
              response = true;
          });
        }

        if (assignment.start_date != null && assignment.end_date != null) {
          startDate = moment(assignment.start_date).format("YYYY-MM-DD");
          endDate = moment(assignment.end_date).format("YYYY-MM-DD");
          if (startDate <= currentDate && endDate >= currentDate)
            response = true;
        }
      }
      // For Teacher
      else {
        if (assignment.assignment_classcodes) {
          assignment.assignment_classcodes.forEach((aC) => {
            startDate = moment(aC.start_date).format("YYYY-MM-DD");
            endDate = moment(aC.end_date).format("YYYY-MM-DD");
            if (startDate <= currentDate && endDate >= currentDate)
              response = true;
          });
        }
        if (assignment.start_date != null && assignment.end_date != null) {
          startDate = moment(assignment.start_date).format("YYYY-MM-DD");
          endDate = moment(assignment.end_date).format("YYYY-MM-DD");
          if (startDate <= currentDate && endDate >= currentDate)
            response = true;
        }
      }
      return response;
    },
    isAssignmentOld(assignment) {
      var currentDate = moment().format("YYYY-MM-DD");
      var response = false;
      var endDate;
      // For Student
      if (this.roleName == "STUDENT") {
        let classcodes = this.isMultipleClasscode(assignment);
        if (classcodes?.length > 0) {
          classcodes.forEach((aC) => {
            endDate = moment(aC.end_date).format("YYYY-MM-DD");
            if (endDate < currentDate) response = true;
          });
        }
        if (assignment.end_date != null) {
          endDate = moment(assignment.end_date).format("YYYY-MM-DD");
          if (endDate < currentDate) response = true;
        }
      }
      // For Teacher
      else {
        if (assignment.assignment_classcodes) {
          assignment.assignment_classcodes.forEach((aC) => {
            endDate = moment(aC.end_date).format("YYYY-MM-DD");
            if (endDate < currentDate) response = true;
          });
        }
        if (assignment.end_date != null) {
          endDate = moment(assignment.end_date).format("YYYY-MM-DD");
          if (endDate < currentDate) response = true;
        }
      }
      return response;
    },
    RequestExtension(assignment) {
      this.assignment_details = assignment;
      this.isExtendAssignmentDialog = true;
    },
    isDatePassed(from_Date) {
      var startDate;
      var currentDate = moment().format("YYYY-MM-DD");
      var response = false;
      // For Student
      startDate = moment(from_Date).format("YYYY-MM-DD");
      if (startDate >= currentDate) response = true;
      return response;
    },
    async Delete_Draft(id) {
      let is_deleted = await axios.delete(`/assignments/${id}`);
      if (is_deleted) {
        this.is_deleted_assignment = true;
      }
    },
    isExtensionAcceptable(assignment) {
      var currentDate = moment().format("YYYY-MM-DD");
      let response = false;
      var endDate;
      if (this.isAssignmentOld(assignment)) {
        let classcodes = this.isMultipleClasscode(assignment);
        if (classcodes?.length > 0) {
          classcodes.forEach((aC) => {
            endDate = aC.end_date;
            var extensionAcceptableDate = moment(endDate)
              .add("2", "days")
              .format("YYYY-MM-DD");
            if (extensionAcceptableDate > currentDate) response = true;
          });
        }
        if (assignment.end_date) {
          endDate = assignment.end_date;
          var extensionAcceptableDate = moment(endDate)
            .add("2", "days")
            .format("YYYY-MM-DD");
          if (extensionAcceptableDate > currentDate) response = true;
        }
      }
      return response;
    },
    isInProgress(assignment) {
      const response =
        this.isAssignmentBetween(assignment) &&
        (assignment.my_results == null || assignment.my_results.length === 0) &&
        assignment.status !== 3;
      return response;
    },
    isTeacherCompleted(assignment, tabIndex) {
      const response =
        this.isAssignmentOld(assignment) &&
        (assignment.my_results == null || assignment.my_results?.length == 0) &&
        assignment.status != 3 &&
        tabIndex === 2;
      return response;
    },
    isStudentCompleted(assignment, tabIndex) {
      const response =
        assignment.my_results != null &&
        assignment.my_results?.length != 0 &&
        assignment.status != 3 &&
        tabIndex === 3;
      return response;
    },
    isUpcoming(assignment, tabIndex) {
      const response =
        this.isAssignmentNow(assignment) &&
        (assignment.my_results == null || assignment.my_results?.length == 0) &&
        assignment.status != 3 &&
        tabIndex == 4;
      return response;
    },
    isDraft(assignment, tabIndex) {
      const response =
        (assignment.created_by.roles[0].name == "INFAKT TEACHER" ||
          assignment.created_by.roles[0].name == "ACADEMIC TEAM" ||
          assignment.created_by.roles[0].name == "TEACHER") &&
        assignment.status == 3 &&
        tabIndex == 5;
      return response;
    },
    isByAdmin(assignment, tabIndex) {
      const response =
        assignment.created_by.roles[0].name == "ADMIN" &&
        assignment.status != 3 &&
        tabIndex == 6;
      return response;
    },
    isPending(assignment, tabIndex) {
      const response =
        (assignment.created_by.roles[0].name == "INFAKT TEACHER" ||
          assignment.created_by.roles[0].name == "ACADEMIC TEAM") &&
        assignment.status == 0 &&
        tabIndex == 7;
      return response;
    },
    isInfaktTeam(assignment) {
      const response =
        (assignment.created_by.roles[0].name == "INFAKT TEACHER" ||
          assignment.created_by.roles[0].name == "ACADEMIC TEAM") &&
        assignment.status == 1;
      return response;
    },
    isRejected(assignment, tabIndex) {
      const response =
        (assignment.created_by.roles[0].name == "INFAKT TEACHER" ||
          assignment.created_by.roles[0].name == "ACADEMIC TEAM") &&
        assignment.status == 2 &&
        tabIndex == 9;
      return response;
    },
    sortAssignments() {
      this.assignments.sort((a, b) => {
        if (this.sort_type == "TITLE")
          return a.assignment_title.localeCompare(b.assignment_title);
        if (this.sort_type == "LATEST") return b.id - a.id;
        if (this.sort_type == "OLDEST") return a.id - b.id;
      });
    },
    ShowLearningOutcome(assignment) {
      this.isShowInfoBox = false;
      let article = assignment.only_content;
      if (article.filtered_content_info_boards) {
        article.content_info_boards = article.filtered_content_info_boards;
      }
      this.contentInfoBoards = [];
      let TeacherBoards = [];
      let StudentBoards = [];
      let AllBoards = [];
      for (const info_board of article.content_info_boards) {
        if (info_board.content_info_board_details.length > 0) {
          if (
            this.IsTeacherView() &&
            this.company.company_boards.some(function (board) {
              return board.board_id === info_board.board.id;
            })
          ) {
            // For Teacher View
            info_board.grade_details = [];
            const gradeWise = [];
            for (const detail of info_board.content_info_board_details) {
              const gradeIndex = gradeWise.findIndex(
                (item) => item.grade === detail.grade
              );
              if (gradeIndex !== -1) {
                gradeWise[gradeIndex].count++;
                gradeWise[gradeIndex].subjects.push(detail);
              } else {
                gradeWise.push({
                  grade: detail.grade,
                  subjects: [detail],
                  count: 1,
                });
              }
            }
            info_board.grade_details = gradeWise;
            TeacherBoards.push(info_board);
          } else if (
            this.roleName == "STUDENT" &&
            info_board.board.id == this.user.board_id
          ) {
            // For Students
            info_board.grade_details = [];
            const gradeWise = [];
            for (const detail of info_board.content_info_board_details) {
              const gradeIndex = gradeWise.findIndex(
                (item) => item.grade === detail.grade
              );
              if (gradeIndex !== -1) {
                gradeWise[gradeIndex].count++;
                gradeWise[gradeIndex].subjects.push(detail);
              } else {
                gradeWise.push({
                  grade: detail.grade,
                  subjects: [detail],
                  count: 1,
                });
              }
            }
            info_board.grade_details = gradeWise;
            StudentBoards.push(info_board);
          } else {
            // Rest All
            info_board.grade_details = [];
            const gradeWise = [];
            for (const detail of info_board.content_info_board_details) {
              const gradeIndex = gradeWise.findIndex(
                (item) => item.grade === detail.grade
              );
              if (gradeIndex !== -1) {
                gradeWise[gradeIndex].count++;
                gradeWise[gradeIndex].subjects.push(detail);
              } else {
                gradeWise.push({
                  grade: detail.grade,
                  subjects: [detail],
                  count: 1,
                });
              }
            }
            info_board.grade_details = gradeWise;
            AllBoards.push(info_board);
          }
        }
      }
      if (this.IsTeacherView()) {
        this.contentInfoBoards = TeacherBoards;
      } else if (this.roleName == "STUDENT") {
        this.contentInfoBoards = StudentBoards;
      } else {
        this.contentInfoBoards = AllBoards;
      }
      this.isShowInfoBox = true;
    },
    async ShowDetails(id) {
      this.assignment_details = [];
      let assignment = await axios.get(`/assignments/${id}`);
      this.assignment_details = assignment.data.data;
      if (this.assignment_details) {
        this.show_details = true;
      }
    },
    async ShowPersonalizedAssignmentDetails(id) {
      this.isLoadingEditPersonalizedAssignment = true;
      this.pA = [];
      let assignment = await axios.get(`/assignments/${id}`);
      this.pA = assignment.data.data;
      if (this.pA) {
        this.students = [];
        if (this.pA.assignment_classcodes[0].classcode_id) {
          // fetch Linked Assignments using the unique string
          let unique_string = encodeURIComponent(
            this.pA.unique_string.split("!")[1]
          );
          let l_query = "unique_string=" + unique_string;
          let linked_assignments = await axios.get(
            `/get_linked_assignments?${l_query}`
          );
          this.linked_assignments = linked_assignments.data.data;

          let students = [];
          this.pA.start_date = this.pA.assignment_classcodes[0].start_date;
          this.pA.start_date = this.pA.assignment_classcodes[0].start_date;
          this.pA.end_date = this.pA.assignment_classcodes[0].end_date;

          this.pA.classcode_id = this.pA.assignment_classcodes[0].classcode_id;
          let query = "role_id=5" + "&classcode_id=" + this.pA.classcode_id;
          students = await axios.get(`/users?${query}`);
          let sortedStudents = students.data.data.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          this.students = sortedStudents;

          this.variants = this.pA.content.content_descriptions;
          this.students.forEach((student) => {
            student.variants = [];
            student.selected_variant = "";
            student.submission_status = "PENDING";
            this.variants.forEach((variant) => {
              variant.variant_assignments = [];
              variant.infakt_assignments.forEach((assignment) => {
                variant.variant_assignments.push({
                  id: assignment.id,
                  text: assignment.assignment_title,
                  value: assignment.id,
                });
                let ABC = this.linked_assignments.find(
                  (a) => a.copied_from_assignment_id == assignment.id
                );
                if (ABC) {
                  let is_mapped_to_other_variant =
                    ABC.assignment_classcodes[0].students.find(
                      (s) => s.id == student.id
                    );
                  if (is_mapped_to_other_variant) {
                    // Other Variant Selected Student
                    student.submission_status =
                      is_mapped_to_other_variant.submission_status;
                    student.selected_variant_id =
                      assignment.content_description_id;
                    student.selected_assignment_id = assignment.id;
                    this.getVariantAssignments(student);
                  }
                }
              });

              let dummy_variant = {
                id: variant.id,
                text: variant.level,
                value: variant.id,
              };
              student.variants.push(dummy_variant);
            });
          });
        }
        this.show_pA = true;
      }
      this.isLoadingEditPersonalizedAssignment = false;
    },
    IsMultipleDate(assignment, type) {
      let start_date_array = [];
      let end_date_array = [];
      let classcodes = this.isMultipleClasscode(assignment);
      classcodes.forEach((ac) => {
        if (!start_date_array.includes(ac.start_date))
          start_date_array.push(ac.start_date);
        if (!end_date_array.includes(ac.end_date))
          end_date_array.push(ac.end_date);
      });
      if (type == "START_DATE") {
        return start_date_array.length;
      } else {
        return end_date_array.length;
      }
    },
    subjectIcon(subject) {
      let icon = "";
      switch (subject) {
        case "English":
          // icon = "game-icons:materials-science";
          icon = "mdi-book-alphabet";
          break;
        case "History":
          icon = "mdi-pillar";
          break;
        case "Geography":
          icon = "mdi-globe-model";
          break;
        case "Science":
          icon = "mdi-flask-outline";
          break;
        case "Civics":
          icon = "mdi-scale-balance";
          break;
        case "Biology":
          icon = "mdi-bacteria-outline";
          break;
        case "Physics":
          icon = "mdi-rocket-launch-outline";
          break;
        case "Chemistry":
          icon = "mdi-test-tube";
          break;
        case "Economics":
          icon = "mdi-cash-100";
          break;
        case "Humanities":
          icon = "mdi-meditation";
          break;
        case "Hindi":
          icon = "mdi-abugida-devanagari";
          break;
        case "Social Studies":
          icon = "mdi-handshake-outline";
          break;
        case "Commerce":
          icon = "mdi-cash-sync";
          break;
        default:
          break;
      }
      return icon;
    },
    isAprrovedExtensionDatePassed(assignment) {
      var currentDate = moment().format("YYYY-MM-DD");
      let response = false;
      var endDate;
      if (assignment.my_assignment_extensions[0].is_approved == true) {
        endDate =
          assignment.my_assignment_extensions[0].approved_extension_date;
        if (endDate < currentDate) response = true;
      }
      return response;
    },
    isMultipleClasscode(assignment) {
      let response = assignment.assignment_classcodes;
      if (
        assignment.assignment_classcodes.length > 1 &&
        this.roleName == "STUDENT"
      ) {
        let visibleClasscode = assignment.assignment_classcodes.filter((aC) => {
          return this.user.user_classcodes.some(
            (uC) => uC.classcode_id === aC.classcode_id
          );
        });
        response = visibleClasscode;
      }
      return response;
    },
    trim_classcode(classcode) {
      let originalClasscode = classcode;
      let classcode_array = classcode.split("/");
      let standard_section = classcode_array[1];
      let subject_section = classcode_array[2];
      let trimmed_classcode = "";
      if (this.roleName == "STUDENT") {
        trimmed_classcode = subject_section;
      } else if (this.roleName == "TEACHER") {
        trimmed_classcode = standard_section + "/" + subject_section;
      } else {
        trimmed_classcode = originalClasscode;
      }
      return trimmed_classcode;
    },
    getVariantAssignments(student) {
      this.isloadingVariantAssignments = true;
      let variant = this.variants.find(
        (v) => v.id == student.selected_variant_id
      );
      var _this = this;
      if (variant) {
        _this.s = this.students.find((s) => s.id == student.id);
        _this.s.selected_variant = variant;
        _this.s.variant_assignments = variant.variant_assignments;
      }
      this.isloadingVariantAssignments = false;
    },
    async save_personalized_assignemnt() {
      this.isSavingPA = true;
      try {
        this.personalizedAssignmentForm = {
          unique_string: this.pA.unique_string,
          classcode_id: this.pA.classcode_id,
          start_date: this.pA.start_date,
          end_date: this.pA.end_date,
          students: this.students,
        };
        await axios
          .post(
            `/edit_personalized_assignments`,
            this.personalizedAssignmentForm
          )
          .then(this.getData());
        this.show_pA = false;
        this.isSavingPA = false;
        console.log(this.personalizedAssignmentForm);
      } catch (e) {
        console.log(e);
        let errors = e.response.data.errors;
        this.focusDiv(errors);
        this.isSavingPA = false;
      }
    },
    focusDiv(errors) {
      console.log("Focus");
      Object.keys(errors).forEach((e) => {
        let l = e.split(".");
        console.log(e.split(".").length);
        switch (true) {
          case e == "classcode_id":
            this.$refs.classcode_id.focus();
            break;
          case e == "start_date":
            this.$refs.start_date.focus();
            break;
          case l.length != 0:
            // let str = String(l);
            console.log(l.join("."));
            // this.$refs.str.focus();

            break;

          default:
            break;
        }
      });
    },
    getStatusBasedDialog(type) {
      this.isStatusbasedDialog = true;
      this.msg = "";
      switch (type) {
        case "DUE DATE PASSED":
          this.msg = "The deadline to request an extension has passed.";
          break;
        case "APPROVED EXTENSION DUE DATE PASSED":
          this.msg = "The due date for the approved extension has expired.";
          break;
        case "REQUEST DENIED":
          this.msg = "The request for an extension has been declined.";
          break;
        case "REQUEST SENT":
          this.msg = "The request for an extension has already been submitted.";
          break;
        default:
          break;
      }
      return this.msg;
    },
    changeStatus() {
      this.show_details = false;
      console.log(this.show_details);
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
img {
  max-width: -webkit-fill-available !important;
}
@media (min-width: 1310px) {
  /* 1300 & Above */
  .midScreen {
    display: none;
  }
  .smallScreen {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  /* 1200 --  1300*/
  .largeScreen {
    display: none;
  }
  .smallScreen {
    display: none;
  }
}
@media (max-width: 1200px) {
  /* 1200 & less*/
  .largeScreen {
    display: none;
  }
  .midScreen {
    display: none;
  }
}
</style>
