<template>
  <section id="subjects" class="grey lighten-3">
    <div class="py-12"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3 myFont">Topics Covered</h2>
    </v-container>
    <!-- <v-slide-y-transition class="mx-10"> -->
    <section v-if="$vuetify.breakpoint.mdAndDown">
      <v-row >
        <v-col sm="6" class="pr-0" align="center" style="margin-left: 40px">
          <v-img
            height="117"
            width="86"
            :src="require('../../assets/science.png')"
          ></v-img>
        </v-col>
        <v-col sm="6" align="left" style="margin-left: -40px">
          <h1 class="text-h4 myFont font-weight-bold mt-12 wrap">Science</h1>
        </v-col>
      </v-row>
      <v-row
        ><v-col
          sm="6"
          class="pr-0 mt-3"
          align="center"
          style="margin-left: 40px"
        >
          <v-img
            height="92"
            width="88"
            :src="require('../../assets/geography.png')"
          ></v-img>
        </v-col>
        <v-col sm="6" align="left" style="margin-left: -40px">
          <h1 class="text-h4 myFont font-weight-bold mt-10 wrap">Geography</h1>
        </v-col>
      </v-row>
      <v-row
        ><v-col
          sm="6"
          class="pr-0 mt-3"
          align="center"
          style="margin-left: 40px"
        >
          <v-img
            height="92"
            width="88"
            :src="require('../../assets/history.png')"
          ></v-img>
        </v-col>
        <v-col sm="6" align="left" style="margin-left: -40px">
          <h1 class="text-h4 myFont font-weight-bold mt-10 wrap">History</h1>
        </v-col>
      </v-row>
      <v-row
        ><v-col
          sm="6"
          class="pr-0 mt-3"
          align="center"
          style="margin-left: 40px"
        >
          <v-img
            height="92"
            width="88"
            :src="require('../../assets/commerce.png')"
          ></v-img>
        </v-col>
        <v-col sm="6" align="left" style="margin-left: -40px">
          <h1 class="text-h4 myFont font-weight-bold mt-10 wrap">Language</h1>
        </v-col>
      </v-row>
      <v-row
        ><v-col
          sm="6"
          class="pr-0 mt-3"
          align="center"
          style="margin-left: 40px"
        >
          <v-img
            height="92"
            width="88"
            :src="require('../../assets/economics.png')"
          ></v-img>
        </v-col>
        <v-col sm="6" align="left" style="margin-left: -40px">
          <h1 class="text-h4 myFont font-weight-bold mt-10 wrap">Economics</h1>
        </v-col>
      </v-row>
      <v-row
        ><v-col
          sm="6"
          class="pr-0 mt-3"
          align="center"
          style="margin-left: 40px"
        >
          <v-img
            height="92"
            width="95"
            :src="require('../../assets/civics.png')"
          ></v-img>
        </v-col>
        <v-col sm="6" align="left" style="margin-left: -40px">
          <h1 class="text-h4 myFont font-weight-bold mt-10 wrap">Civics</h1>
        </v-col>
      </v-row>
    </section>

    <section class="mx-12" v-else>
      <v-row justify="center" class="ml-12 pb-0">
        <v-col md="4">
          <v-row>
            <v-col md="4" class="pr-0" align="right">
              <v-img
                height="117"
                width="86"
                :src="require('../../assets/science.png')"
              ></v-img>
            </v-col>
            <v-col md="8" class="pl-4" align="left">
              <h1 class="text-h4 myFont font-weight-bold my-12">Science</h1>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="4">
          <v-row>
            <v-col md="4" class="pr-0 mt-8" align="right">
              <v-img
                height="92"
                width="88"
                :src="require('../../assets/geography.png')"
              ></v-img>
            </v-col>
            <v-col md="8" class="pl-4 mt-2" align="left">
              <h1 class="text-h4 myFont font-weight-bold my-12">Geography</h1>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="4">
          <v-row>
            <v-col md="4" class="pr-0 mt-10" align="right">
              <v-img
                height="92"
                width="88"
                :src="require('../../assets/history.png')"
              ></v-img>
            </v-col>
            <v-col md="8" class="pl-4 mt-5" align="left">
              <h1 class="text-h4 myFont font-weight-bold my-12">History</h1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" class="ml-12" style="margin: -20px">
        <v-col md="4">
          <v-row>
            <v-col md="4" class="pr-0 mt-6" align="right">
              <v-img
                height="92"
                width="88"
                :src="require('../../assets/language.png')"
              ></v-img>
            </v-col>
            <v-col md="8" class="pl-4" align="left">
              <h1 class="text-h4 myFont font-weight-bold my-12">Language</h1>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="4">
          <v-row>
            <v-col md="4" class="pr-0 mt-8" align="right">
              <v-img
                height="92"
                width="88"
                :src="require('../../assets/economics.png')"
              ></v-img>
            </v-col>
            <v-col md="8" class="pl-4 mt-2" align="left">
              <h1 class="text-h4 myFont font-weight-bold my-12">Economics</h1>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="4">
          <v-row>
            <v-col md="4" class="pr-0 mt-10" align="right">
              <v-img
                height="92"
                width="95"
                :src="require('../../assets/civics.png')"
              ></v-img>
            </v-col>
            <v-col md="8" class="pl-4 mt-2" align="left">
              <h1 class="text-h4 myFont font-weight-bold my-12">Civics</h1>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>

    <!-- </v-slide-y-transition> -->

    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      subjectsTrigger: false,
      feature: [
        {
          icon: "mdi-account-group-outline",
          title: "Data Analytics On Student Progress",
          text: "Monitor student development with the help of our reports. Get a detailed breakdown on subject wise grading and progress charts.",
        },
        {
          icon: "mdi-update",
          title: "Accessibility For All",
          text: "Articles are published at multiple reading levels, thus allowing for an entire class to read and discuss the same content, while permitting individual students to access material at their individual reading level.",
        },
        {
          icon: "mdi-shield-outline",
          title: "Assessment Mechanisms",
          text: "We provide interactive assignments but also give teachers the flexibility to give their own assessments with various parameters in mind.",
        },
        {
          icon: "mdi-book-alphabet",
          title: "Integrated Dictionary",
          text: "Improve student articulation and vocabulary. Power words foster language development for all our students.",
        },
        {
          icon: "mdi-cellphone-sound",
          title: "Student Mobile App",
          text: "Students can access engaging content and their homework even if they don’t have a computer.",
        },
        {
          icon: "mdi-text-long",
          title: "Multiple Topics",
          text: "Our platform covers a wide range of topics across diverse cultures and opinions. Globalise your pupils with information relevant to their growth.",
        },
      ],
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async handleScroll() {
      const subjects = document.querySelector("#subjects");
      if (this.isInViewport(subjects)) {
        await new Promise((resolve) => setTimeout(resolve, 500)); // 3 sec
        this.subjectsTrigger = true;
      }
    },
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) + 500 &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
  },
};
</script>

<style scoped>
.myFont {
  font-family: "SF PRO" !important;
  color: black;
}
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
