<template>
  <v-main>
    <v-container>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 181px">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Section Wise Reports'"
          ></span>
        </v-col>
      </v-row>
      <!-- Filters Section -->
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            ref="standard"
            v-model="form.standard_id"
            :items="standardItems"
            label="Standard"
            clearable
            :error-messages="errors.standard_id"
            @change="getStandardSections"
            prepend-icon="mdi-alarm-bell"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="classcode"
            v-model="form.subject_name"
            :items="classcodeItems"
            label="Subject"
            clearable
            prepend-icon="mdi-application-edit"
          ></v-autocomplete>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            ref="section"
            v-model="form.section_ids"
            :items="sectionItems"
            clearable
            multiple
            small-chips
            label="Sections"
            :error-messages="errors.section_id"
            prepend-icon="mdi-account-school"
          ></v-autocomplete>
        </v-col>

        <v-col></v-col>
        <v-col class="mt-3">
          <v-btn color="primary" @click="getData"> SEARCH </v-btn>
        </v-col>
      </v-row>
      <!--/ Filters Section -->
      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col>
          <v-card elevation="6">
            <v-card-text>
              <v-simple-table fixed-header height="285px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="min-width: 56px">Sr No</th>
                      <th class="text-center" style="min-width: 100px">
                        Section
                      </th>
                      <th class="text-center" style="min-width: 140px">
                        Assignment Posted
                      </th>
                      <th class="text-center" style="min-width: 130px">
                        Submission Rate
                      </th>
                      <th class="text-center" style="min-width: 80px">
                        Class Average
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(section, i) in sectionWiseReport"
                      :key="`section${i}`"
                    >
                      <td>{{ i + 1 }}</td>
                      <td class="text-center">{{ section.section_name }}</td>
                      <td class="text-center">
                        {{ section.assignment_posted }}
                      </td>
                      <td class="text-center" style="min-width: 130px">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"
                              >{{ section.totalSubmissionAverage }} %</span
                            >
                          </template>
                          <span>
                            [ Total Submission Average / Total Assignment ]
                          </span>
                        </v-tooltip>
                      </td>
                      <td class="text-center">
                        {{ section.classcodeAveragePercent | twoDec }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="12" v-if="AssignmentWiseSectionReport.length">
          <v-card elevation="6">
            <v-card-title style="min-width: 250px">
              Assignment Wise Comparison
              <v-spacer></v-spacer>
              <view-fullscreen
                v-if="AssignmentWiseSectionComparisonChart.length"
                :title="`Assignment Wise Comparison`"
                :type="`ColumnChart`"
                :Data="AssignmentWiseSectionComparisonChart"
                :Options="AssignmentWiseColumnChartOptions"
              ></view-fullscreen>
            </v-card-title>
            <v-card-title>
              <v-row>
                <v-col md="10">
                  <v-autocomplete
                    ref="scholl"
                    v-model="assignment_ids"
                    :items="assignmentItems"
                    multiple
                    chips
                    clearable
                    maxValue="4"
                    @click:clear="AssignmentWiseSectionComparisonChart = []"
                    @input="validateSelectedItem"
                    :error-messages="validationErrors"
                    label="Assignment"
                    prepend-icon="mdi-application-edit"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    :disabled="validationErrors.length ? true : false"
                    @click="getAssignmentWiseSection"
                  >
                    FILTER
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text v-if="AssignmentWiseSectionComparisonChart.length">
              <GChart
                type="ColumnChart"
                :data="AssignmentWiseSectionComparisonChart"
                :options="AssignmentWiseColumnChartOptions"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      form: {},
      isLoading: false,
      standards: [],
      standardItems: [],
      sections: [],
      sectionItems: [],
      classcodes: [],
      classcodeItems: [],
      sectionWiseReport: [],
      assignmentItems: [],
      assignment_ids: [],
      validationErrors: [],

      selectedStudentId: "",
      schoolId: "",
      // Chart Components
      TeacherBasedOn: "1",
      AssignmentWiseSectionReport: [],
      AssignmentWiseSectionComparisonChart: [],
      AssignmentWiseColumnChartOptions: {
        theme: "material",
        height: "500",
        colors: ["#007ACC", "#FFA500"],
        chartArea: { left: 50, right: 50 },
        bar: { groupWidth: "30%" },
        annotations: {
          textStyle: {
            fontSize: 10,
          },
        },
        vAxis: {
          minValue: 0,
          title: "in Percentage",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 40 },

          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          title: "Assignments",
          titleTextStyle: {
            bold: true,
          },
          gridlines: {
            color: "transparent",
          },
        },
        legend: { position: "top", alignment: "center" },
      },
    };
  },
  mounted() {
    this.schoolId = this.company.id;
    this.getMasters();
  },

  methods: {
    validateSelectedItem() {
      if (this.assignment_ids && this.assignment_ids.length > 5) {
        this.validationErrors = [
          "Selected value exceeds the maximum allowed value.",
        ];
      } else {
        this.validationErrors = [];
      }
    },
    async getStandardSections() {
      this.sectionItems = [];
      this.form.classcode_id = "";
      let standardsections = this.sections.filter(
        (section) => section.standard_id == this.form.standard_id
      );
      standardsections.forEach((ss) => {
        this.sectionItems.push({
          id: ss.id,
          text: ss.name,
          value: ss.id,
        });
      });
    },
    async getMasters() {
      let masters = await axios.get(`users/masters`);
      masters = masters.data;

      this.standards = masters.standards;
      this.sections = masters.sections;
      this.classcodes = masters.classcodes;
      // Standard
      const arrayColumn = (array, column) => {
        return array.map((item) => item[column]);
      };
      let standardIds = arrayColumn(
        this.user.user_classcodes,
        "standard_id"
      ).filter((value, index, array) => {
        return array.indexOf(value) === index;
      });

      this.standards.forEach((standard) => {
        let standard_id = standard.id;
        let is_applicable = standardIds.find((item) => item === standard_id);
        if (is_applicable) {
          this.standardItems.push({
            id: standard.id,
            text: standard.name,
            value: standard.id,
          });
        }
      });
      // Section
      this.sections.forEach((section) => {
        this.sectionItems.push({
          id: section.id,
          text: section.name,
          value: section.id,
        });
      });
      // ClassCodes
      this.classcodes = [...new Set(this.classcodes)];
      this.classcodes.forEach((classcode) => {
        this.classcodeItems.push({
          id: classcode.id,
          text: classcode.subject_name,
          value: classcode.subject_name,
        });
      });
    },
    async getData() {
      this.assignmentItems = [];
      this.assignment_ids = [];
      this.AssignmentWiseSectionComparisonChart = [];
      this.isLoading = true;
      try {
        let sectionWiseReport;
        this.form.standard_id = this.form.standard_id
          ? this.form.standard_id
          : "";
        this.form.section_ids = this.form.section_ids
          ? this.form.section_ids
          : "";
        this.form.subject_name = this.form.subject_name
          ? this.form.subject_name
          : "";
        this.teacher_id = this.user.id;
        let requirements = ["assignmentWiseStudents", "classcodeWiseStudents"];
        let query =
          "companyId=" +
          this.company.id +
          "&teacherId=" +
          this.teacher_id +
          "&standard_id=" +
          this.form.standard_id +
          "&section_ids=" +
          this.form.section_ids +
          "&subject_name=" +
          this.form.subject_name +
          "&requirements=" +
          requirements;
        sectionWiseReport = await axios.get(`/section_wise_report?${query}`);
        this.sectionWiseReport =
          sectionWiseReport.data.data.section_wise_report;
        this.AssignmentWiseSectionReport =
          sectionWiseReport.data.data.assignment_wise_section_report;
        if (this.AssignmentWiseSectionReport) {
          this.AssignmentWiseSectionReport.forEach((data) => {
            let assignment = {
              text: data.assignment_title,
              id: data.assignment_id,
              value: data.assignment_id,
            };
            this.assignmentItems.push(assignment);
          });
          // this.getAssignmentWiseSection();
        }
      } catch (error) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    getAssignmentWiseSection() {
      // Assignment Wise Section  [Section A /Section B]
      let AssignmentWiseSectionChart = [[this.TeacherBasedOn]];

      // Fetching the largest Section Count
      let maxSections = 0;
      this.AssignmentWiseSectionReport.forEach((data) => {
        maxSections =
          maxSections < data.sections.length
            ? data.sections.length
            : maxSections;
        let assignment = {
          text: data.assignment_title,
          id: data.assignment_id,
          value: data.assignment_id,
        };
        this.assignmentItems.push(assignment);
      });

      // Fill the Title For all the sections
      this.AssignmentWiseSectionReport.forEach((data) => {
        for (let index = 0; index < maxSections; index++) {
          // For Each Section
          if (data.sections[index]) {
            let section_name = data.sections[index].name;
            if (
              !AssignmentWiseSectionChart[0].find(
                (item) => item === section_name
              )
            ) {
              // If Not Already Exist then Add
              AssignmentWiseSectionChart[0].push(section_name);
            }
          }
        }
      });
      this.AssignmentWiseSectionComparisonChart = AssignmentWiseSectionChart;

      // Data Fill in Chart Array
      this.assignment_ids = this.assignment_ids ? this.assignment_ids : "";
      this.AssignmentWiseSectionReport.forEach((data) => {
        let assignment_id = data.assignment_id;
        if (this.assignment_ids.length) {
          let is_applicable = this.assignment_ids.find(
            (item) => item === assignment_id
          );
          if (is_applicable) {
            let assignment = [data.assignment_title];
            /**
             * Add Dummy Data for each Section.
             * ie If any Section dont have any data
             * then it shouldn't Break the graph
             *  */
            for (
              let index = 1;
              index < this.AssignmentWiseSectionComparisonChart[0].length;
              index++
            ) {
              assignment.push(0);
            }
            for (let index = 0; index < maxSections; index++) {
              // For Each Section
              let classcodeAveragePercent = 0;
              if (data.sections[index]) {
                let section_name = data.sections[index].name;
                classcodeAveragePercent =
                  data.sections[index].classcodeAveragePercent != 0
                    ? parseFloat(
                        data.sections[index].classcodeAveragePercent.toFixed(2)
                      )
                    : 0;

                let assignmentSectionIndex =
                  AssignmentWiseSectionChart[0].findIndex(
                    (item) => item === section_name
                  );
                assignment[assignmentSectionIndex] = classcodeAveragePercent;
              }
            }
            AssignmentWiseSectionChart.push(assignment);
          }
        } else {
          let assignment = [data.assignment_title];
          for (let index = 0; index < maxSections; index++) {
            let classcodeAveragePercent = 0;
            if (data.sections[index]) {
              if (
                !AssignmentWiseSectionChart[0].find(
                  (item) => item === data.sections[index].name
                )
              ) {
                AssignmentWiseSectionChart[0].push(data.sections[index].name);
              }
              classcodeAveragePercent =
                data.sections[index].classcodeAveragePercent != 0
                  ? parseFloat(
                      data.sections[index].classcodeAveragePercent.toFixed(2)
                    )
                  : 0;
            }
            assignment.push(classcodeAveragePercent);
          }
          AssignmentWiseSectionChart.push(assignment);
        }
      });
      this.AssignmentWiseSectionComparisonChart = AssignmentWiseSectionChart;

      // Trimming the Chart Data to Latest 5
      if (this.AssignmentWiseSectionComparisonChart.length > 6) {
        let startIndex =
          this.AssignmentWiseSectionComparisonChart.length - 5 - 1;
        this.AssignmentWiseSectionComparisonChart.splice(1, startIndex);
      }
      this.AssignmentWiseColumnChartOptions.width =
        parseInt(this.AssignmentWiseSectionComparisonChart.length - 1) > 6
          ? parseInt(this.AssignmentWiseSectionComparisonChart.length - 1) * 200
          : "100%";
      this.AssignmentWiseColumnChartOptions.bar.groupWidth =
        parseInt(this.AssignmentWiseSectionComparisonChart.length - 1) > 6
          ? "30%"
          : "65";
    },
  },
};
</script>
<style scoped>
.wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
