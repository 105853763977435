<template>
  <v-main>
    <v-container fluid>
      <br />
      <v-row justify="start">
        <v-col style="min-width: 130px">
          <span
            class="text-h5 font-weight-bold"
            v-text="'Career Requests'"
          ></span>
          <v-btn
            class="mx-2 mb-2"
            fab
            x-small
            color="primary"
            to="/career-requests/create"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="search_keyword"
            label="Search ... "
            prepend-inner-icon="mdi-magnify"
            clearable
            @click:clear="(search_keyword = null), (page = 1), getData()"
            @keydown.enter="getData"
            @change="page = 1"
          ></v-text-field>
        </v-col>
        <v-col md="6">
          <v-pagination v-model="page" :length="paginationLength">
          </v-pagination>
        </v-col>
      </v-row>

      <loader v-if="isLoading"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left sr_no_class">Sr No</th>
                    <th class="text-left" style="min-width: 65px">Date</th>
                    <th class="text-left" style="min-width: 65px">Name</th>
                    <th class="text-left" style="min-width: 65px">Email</th>
                    <th class="text-left" style="min-width: 95px">
                      Description
                    </th>
                    <th class="text-left" style="min-width: 70px">Status</th>
                    <th class="text-right" style="min-width: 70px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(careerRequest, i) in careerRequests"
                    :key="careerRequest.name"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>{{ careerRequest.created_at }}</td>
                    <td style="min-width: 185px">{{ careerRequest.name }}</td>
                    <td style="min-width: 245px">{{ careerRequest.email }}</td>
                    <td style="min-width: 150px">
                      {{ careerRequest.description }}
                    </td>
                    <td style="min-width: 100px">{{ careerRequest.status }}</td>
                    <td class="text-right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="careerRequest.attachment"
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2"
                            fab
                            x-small
                            target="_blank"
                            :href="`${mediaUrl + careerRequest.attachment}`"
                          >
                            <v-icon dark color="info"> mdi-eye </v-icon>
                          </v-btn>
                        </template>
                        <span>View attachment. </span>
                      </v-tooltip>

                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/career-requests/${careerRequest.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";
export default {
  components: { loader },
  name: "CareerRequestsPage",
  data() {
    return {
      page: 1,
      RowsPerPage: 20,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      careerRequests: [],
      isLoading: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let careerRequests = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.RowsPerPage +
        "&search_keyword=" +
        this.search_keyword;
      careerRequests = await axios.get(`career_requests?${query}`);
      this.careerRequests = careerRequests.data.data;
      this.count = careerRequests.data.count;
      this.serialNoStarting = (this.page - 1) * this.RowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.RowsPerPage);
      this.isLoading = false;
    },
  },
};
</script>
