<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          Update User
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="subject"
                v-model="form.role_id"
                :items="roleItems"
                label="Select role"
                prepend-icon="mdi-domain"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.first_name"
                outlined
                label="First Name *"
                prepend-inner-icon="mdi-account"
                :error-messages="errors.first_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.last_name"
                outlined
                label="Last Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.last_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.phone"
                outlined
                label="Phone"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4" class="pb-0">
              <v-switch
                v-model="form.active"
                inset
                :label="`Is Active: ${form.active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                ref="subject"
                v-model="form.subjects"
                :items="subjectItems"
                label="Subject"
                prepend-icon="mdi-database-search"
                multiple
                chips
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn v-else color="primary" dark @click="save"> Update User </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserEditPage",
  data() {
    return {
      form: {
        name: "",
        role_id: 4,
        active: true,
      },
      roleItems: [],
      subjects: [],
      subjectItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/users/masters");
      masters = masters.data;
      masters.roles.forEach((role) => {
        if (role.name == "INFAKT TEACHER")
          this.roleItems.push({
            id: role.id,
            text: role.name,
            value: role.id,
          });
      });
      masters.subjects.forEach((subject) => {
        this.subjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/users/${this.$route.params.atuserId}`);
      this.form = form.data.data;
      this.form.role_id = this.form.roles[0].id;
      let subjects = [];
      this.form.user_subjects.forEach((subject) => {
        subjects.push(subject.subject_id);
      });
      this.form.subjects = subjects;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        let user_subjects = [];
        this.form.subjects.forEach((subject_id) => {
          let userSubject = this.form.user_subjects.find(
            (uc) => uc.subject_id == subject_id
          );
          if (userSubject) {
            user_subjects.push(userSubject);
          } else {
            let user_subject = {
              company_id: this.company.id,
              subject_id: subject_id,
            };
            user_subjects.push(user_subject);
          }
        });
        this.form.user_subjects = user_subjects;
        this.form.name = this.form.first_name + " " + this.form.last_name;
        await axios.post(`/users`, this.form);
        this.isLoading = false;
        this.$router.push("/admin/users");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
