<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          {{ roleName == "TEACHER" ? "Update Departments" : "Edit Coordinator" }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                :disabled="roleName != 'TEACHER' ? false : true"
                outlined
                v-model="form.first_name"
                label="First Name *"
                prepend-inner-icon="mdi-account-tie"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                :disabled="roleName != 'TEACHER' ? false : true"
                outlined
                v-model="form.last_name"
                label="Last Name *"
                prepend-inner-icon="mdi-account-tie"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                :disabled="roleName != 'TEACHER' ? false : true"
                outlined
                v-model="form.id_given_by_school"
                label="Teacher ID"
                prepend-inner-icon="mdi-account-lock"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field
                :disabled="roleName != 'TEACHER' ? false : true"
                outlined
                v-model="form.email"
                label="Email *"
                prepend-inner-icon="mdi-at"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                :disabled="roleName != 'TEACHER' ? false : true"
                outlined
                v-model="form.contact_number"
                label="Contact Number"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                :disabled="roleName != 'TEACHER' ? false : true"
                ref="gender"
                v-model="form.gender"
                :items="genderItems"
                label="gender"
                prepend-icon="mdi-gender-male-female"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <strong>Profile Image</strong><br />
              <input
                label
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="image/*"
              />
            </v-col>
            <v-col sm="4">
              <v-switch
                :disabled="roleName != 'TEACHER' ? false : true"
                v-model="form.active"
                inset
                :label="`Is Active: ${form.active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <v-autocomplete
                ref="classcode"
                v-model="form.classcodes"
                :items="classcodeItems"
                label="Departments"
                prepend-icon="mdi-database-search"
                multiple
                chips
              ></v-autocomplete>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="update">
            Update Coordinator
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { backbutton, Loader },
  data() {
    return {
      form: {},
      user_classcodes: [
        {
          classcode_id: "",
        },
      ],
      isLoading: false,
      classcodes: [],
      classcodeItems: [],
      genderItems: [
        {
          text: "MALE",
          value: "1",
        },
        {
          text: "FEMALE",
          value: "0",
        },
      ],
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      this.isLoading = true;
      let masters = await axios.get("users/masters?search_all=true");
      masters = masters.data;
      this.classcodes = masters.classcodes;
      // ClassCodes
      this.classcodes.forEach((classcode) => {
        this.classcodeItems.push({
          id: classcode.id,
          text: classcode.classcode,
          value: classcode.id,
        });
      });
      this.isLoading = false;
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/users/${this.$route.params.teacherId}`);
      this.form = form.data.data;
      let classcodes = [];
      this.form.user_classcodes.forEach((classcode) => {
        classcodes.push(classcode.classcode_id);
      });
      classcodes = [...new Set(classcodes)];
      this.form.classcodes = classcodes;
      this.isLoading = false;
    },
    async update() {
      try {
        this.isLoading = true;
        let user_classcodes = [];
        this.form.classcodes.forEach((classcode_id) => {
          let userClasscode = this.form.user_classcodes.find(
            (uc) => uc.classcode_id == classcode_id
          );
          if (userClasscode) {
            user_classcodes.push(userClasscode);
          } else {
            let standard_id = this.classcodes.find(
              (c) => c.id == classcode_id
            ).standard_id;
            let section_id = this.classcodes.find(
              (c) => c.id == classcode_id
            ).section_id;
            let user_classcode = {
              company_id: this.company.id,
              standard_id: standard_id,
              section_id: section_id,
              classcode_id: classcode_id,
            };
            user_classcodes.push(user_classcode);
          }
        });
        user_classcodes = [...new Set(user_classcodes)];
        this.form.user_classcodes = user_classcodes;
        this.form.name = this.form.first_name + " " + this.form.last_name;
        let form = await axios.post(`/users`, this.form);
        this.form = form.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/teachers");
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      if (this.$refs.file != null) {
        let attachment = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("userid", this.form.id);
        formData.append("imagepath", attachment);
        await axios
          .post("upload_user_image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function () {
            console.log("FAILURE!!");
          });
      }
    },
  },
};
</script>
