<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          My Profile
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                disabled
                outlined
                v-model="form.first_name"
                label="First Name *"
                prepend-inner-icon="mdi-account-tie"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                disabled
                outlined
                v-model="form.last_name"
                label="Last Name *"
                prepend-inner-icon="mdi-account-tie"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                disabled
                outlined
                v-model="form.id_given_by_school"
                :label="IdLabel()"
                prepend-inner-icon="mdi-account-lock"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-text-field
                disabled
                outlined
                v-model="form.email"
                label="Email *"
                prepend-inner-icon="mdi-at"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <!-- <v-col sm="4">
                <v-text-field
                  disabled
                  outlined
                  v-model="form.password"
                  label="Password"
                  prepend-inner-icon="mdi-lock"
                ></v-text-field>
              </v-col> -->
            <v-col sm="4">
              <v-text-field
                disabled
                outlined
                v-model="form.contact_number"
                label="Contact Number"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                disabled
                ref="gender"
                v-model="form.gender"
                :items="genderItems"
                label="gender"
                prepend-icon="mdi-gender-male-female"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="form.companies.length">
            <v-col md="12">
              <v-text-field
                disabled
                outlined
                v-model="form.companies[0].name"
                label="School Name"
                prepend-inner-icon="mdi-school-outline"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-switch
                disabled
                v-model="form.active"
                inset
                :label="`Is Active: ${form.active ? 'TRUE' : 'FALSE'}`"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
export default {
  components: { backbutton },
  data() {
    return {
      form: {},
      user_classcodes: [
        {
          classcode_id: "",
        },
      ],
      isLoading: false,
      classcodes: [],
      classcodeItems: [],
      genderItems: [
        {
          text: "MALE",
          value: "1",
        },
        {
          text: "FEMALE",
          value: "0",
        },
      ],
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      this.isLoading = true;
      let masters = await axios.get("users/masters");
      masters = masters.data;
      this.classcodes = masters.classcodes;
      // ClassCodes
      this.classcodes.forEach((classcode) => {
        this.classcodeItems.push({
          id: classcode.id,
          text: classcode.classcode,
          value: classcode.id,
        });
      });
      this.isLoading = false;
    },
    async getData() {
      this.isLoading = true;
      this.form = this.user;
      this.isLoading = false;
    },
    IdLabel() {
      let role =
        this.roleName == "ACADEMIC TEAM" ? "ACADEMIC HEAD" : this.roleName;
      let role_array = role.split(" ");

      let role_string = "";
      role_array.forEach((ra) => {
        role_string +=
          ra.charAt(0).toUpperCase() + ra.slice(1).toLowerCase() + " ";
      });
      role_string += "ID";
      // let role_string =
      //   role.charAt(0).toUpperCase() +
      //   role.slice(1).toLowerCase();
      return role_string;
    },
  },
};
</script>
