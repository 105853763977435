<template>
  <v-main>
    <v-container>
      <v-row justify="start" class="mt-5">
        <v-col>
          <backbutton></backbutton>
          <span class="text-h5 font-weight-bold" v-text="title"> </span>
        </v-col>
      </v-row>
      <loader v-if="isMasterLoading" indeterminate color="primary"></loader>
      <v-row v-else>
        <v-col md="12">
          <v-card elevation="6">
            <v-card-title>
              <h3 class="ma-4 ml-0">
                <classcode-element
                  :classcode="classcode.classcode"
                  :type="
                    roleName == 'STUDENT'
                      ? 'SUBJECT'
                      : roleName == 'TEACHER'
                      ? 'STANDARD_SUBJECT'
                      : ''
                  "
                ></classcode-element>
                <v-autocomplete
                  ref="Sections"
                  :items="ViewItems"
                  v-model="viewType"
                  label="Select View"
                ></v-autocomplete>
              </h3>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <template v-if="viewType == 'Students'">
        <v-row v-if="students.length">
          <v-col md="12">
            <!-- Students -->
            <v-card elevation="6">
              <v-card-title>
                <p class="text-h4">Students</p>
                <v-spacer></v-spacer>
                <v-btn
                  class="ml-9 my-auto"
                  fab
                  x-small
                  :color="is_list == false ? 'primary' : ''"
                  @click="is_list = false"
                >
                  <v-icon dark> mdi-grid </v-icon>
                </v-btn>
                |
                <v-btn
                  class="my-auto"
                  fab
                  x-small
                  :color="is_list == true ? 'primary' : ''"
                  @click="is_list = true"
                >
                  <v-icon> mdi-format-list-numbered </v-icon>
                </v-btn>
              </v-card-title>
              <hr />
              <v-card-text>
                <div v-if="is_list == false">
                  <v-row>
                    <v-col
                      sm="3"
                      v-for="student in students"
                      :key="student.name"
                    >
                      <v-card elevation="6" shaped>
                        <a
                          @click="ShowDetails(student.id)"
                          style="text-decoration: none; color: black"
                        >
                          <v-col>
                            <v-img
                              v-if="student.gender == true"
                              height="200"
                              contain
                              :src="require('@/assets/imgs/student-boy.png')"
                            ></v-img>
                            <v-img
                              v-if="student.gender == false"
                              height="200"
                              contain
                              :src="require('@/assets/imgs/student-girl.png')"
                            ></v-img>
                          </v-col>

                          <v-card-title>
                            <div class="wrap">
                              <a style="text-decoration: none; color: black">
                                {{ student.name }}
                              </a>
                            </div>
                          </v-card-title>

                          <v-card-text>
                            <div class="wrap">{{ student.email }}</div>
                            <div class="wrap">
                              {{ student.board ? student.board.name : "" }}
                            </div>
                          </v-card-text>
                        </a>

                        <v-divider class="mx-4"></v-divider>

                        <v-card-text>
                          <v-chip-group
                            active-class="deep-purple accent-4 white--text"
                            column
                          >
                            <div v-if="student.gender == true">
                              <v-chip dark color="blue lighten-2">
                                <v-icon> mdi-gender-male </v-icon>
                                MALE</v-chip
                              >
                            </div>
                            <div v-else>
                              <v-chip dark color="red lighten-2">
                                <v-icon> mdi-gender-female </v-icon>
                                FEMALE</v-chip
                              >
                            </div>
                            <!-- <v-chip>
                      <v-icon> mdi-cast-education </v-icon>
                      {{ student.board ? student.board.name : "" }}</v-chip
                    > -->
                            <div v-if="roleName == 'ADMIN'">
                              <v-tooltip
                                bottom
                                v-if="student.is_mail_sent == 0"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    fab
                                    x-small
                                    class="my-auto"
                                    color="error"
                                    @click="SendMail(student.id)"
                                    :loading="sending_mail"
                                    :disabled="sending_mail"
                                  >
                                    <v-icon dark> mdi-email-fast </v-icon>
                                  </v-btn>
                                </template>
                                <span>No Registration Mail Sent Yet !!</span>
                              </v-tooltip>
                              <v-tooltip bottom v-else>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    class="my-auto"
                                    fab
                                    x-small
                                    color="success"
                                    @click="SendMail(student.id)"
                                    :loading="sending_mail"
                                    :disabled="sending_mail"
                                  >
                                    <v-icon dark> mdi-email-open </v-icon>
                                  </v-btn>
                                </template>
                                <span>Registration Mail Already Sent. </span>
                              </v-tooltip>
                              <v-btn
                                class="ml-2 my-auto"
                                fab
                                x-small
                                color="primary"
                                :to="`/students/${student.id}`"
                              >
                                <v-icon dark> mdi-pencil </v-icon>
                              </v-btn>
                            </div>
                            <v-spacer></v-spacer>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  dark
                                  :color="`${student.active ? 'green' : 'red'}`"
                                >
                                  mdi-circle
                                </v-icon>
                              </template>
                              <span>{{
                                student.active ? "Active" : "Inactive"
                              }}</span>
                            </v-tooltip>
                          </v-chip-group>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <!-- <div > -->
                <v-col md="12" v-else>
                  <v-card elevation="6">
                    <v-simple-table fixed-header>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left sr_no_class">Sr No</th>
                            <th class="text-center" style="min-width: 65px">
                              Name
                            </th>
                            <th class="text-center" style="min-width: 65px">
                              Email
                            </th>
                            <th class="text-center" style="min-width: 80px">
                              Board
                            </th>
                            <th class="text-center" style="min-width: 80px">
                              Gender
                            </th>
                            <th class="text-center" style="min-width: 80px">
                              is Active
                            </th>
                            <th
                              class="text-center"
                              v-if="roleName == 'ADMIN'"
                              style="min-width: 80px"
                            >
                              is Mail Sent
                            </th>
                            <th
                              class="text-right"
                              v-if="roleName == 'ADMIN'"
                              style="min-width: 70px"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(student, i) in students"
                            :key="student.name"
                          >
                            <td>{{ i + 1 }}</td>
                            <td
                              class="text-center"
                              style="min-width: 105px"
                              @click="ShowDetails(student.id)"
                            >
                              <a style="text-decoration: none; color: black"
                                >{{ student.name }}
                              </a>
                            </td>
                            <td
                              class="text-center"
                              style="min-width: 150px"
                              @click="ShowDetails(student.id)"
                            >
                              <a style="text-decoration: none; color: black">
                                {{ student.email }}
                              </a>
                            </td>
                            <td class="text-center">
                              {{ student.board ? student.board.name : "" }}
                            </td>
                            <td class="text-center">
                              {{ student.gender == true ? "MALE" : "FEMALE" }}
                            </td>
                            <td class="text-center">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    dark
                                    :color="`${
                                      student.active ? 'green' : 'red'
                                    }`"
                                  >
                                    mdi-circle
                                  </v-icon>
                                </template>
                                <span>{{
                                  student.active ? "Active" : "Inactive"
                                }}</span>
                              </v-tooltip>
                            </td>
                            <td class="text-center" v-if="roleName == 'ADMIN'">
                              <v-tooltip
                                bottom
                                v-if="student.is_mail_sent == 0"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    fab
                                    x-small
                                    class="my-auto"
                                    color="error"
                                    @click="SendMail(student.id)"
                                    :loading="sending_mail"
                                    :disabled="sending_mail"
                                  >
                                    <v-icon dark> mdi-email-fast </v-icon>
                                  </v-btn>
                                </template>
                                <span>No Registration Mail Sent Yet !!</span>
                              </v-tooltip>
                              <v-tooltip bottom v-else>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    class="my-auto"
                                    fab
                                    x-small
                                    color="success"
                                    @click="SendMail(student.id)"
                                    :loading="sending_mail"
                                    :disabled="sending_mail"
                                  >
                                    <v-icon dark> mdi-email-open </v-icon>
                                  </v-btn>
                                </template>
                                <span>Registration Mail Already Sent. </span>
                              </v-tooltip>
                            </td>

                            <td class="text-right" v-if="roleName == 'ADMIN'">
                              <v-btn
                                class="mx-2"
                                fab
                                x-small
                                color="primary"
                                :to="`/students/${student.id}`"
                              >
                                <v-icon dark> mdi-pencil </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-card-text>
            </v-card>
            <!-- End of Students -->
          </v-col>
        </v-row>
      </template>
      <template v-if="viewType == 'Courses'">
        <v-row v-if="courses">
          <v-col md="12">
            <!-- Courses -->
            <v-card elevation="6" height="260" color="blue-grey lighten-5">
              <v-card-title>
                <p class="text-h4">Courses</p>
              </v-card-title>
              <hr />
              <v-card-text>
                <v-row v-for="(course, c) in courses" :key="`course_${c}`">
                  <v-col md="6">
                    <v-row>
                      <v-col md="6">
                        <v-card @click="selected_course = course">
                          <v-card-title style="justify-content: space-between">
                            {{ course.title }} &nbsp;
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-card-title>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    md="6"
                    style="border-left-width: thick"
                    v-html="course.description"
                  >
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- Lesson Plan -->
              <v-expand-transition>
                <v-card
                  v-if="selected_course"
                  color="blue-grey lighten-5"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-title>
                    <p class="text-h4 text--primary">Lesson Plans</p>
                    <v-spacer></v-spacer>
                    <v-card-actions class="pt-0">
                      <v-btn
                        dark
                        color="teal accent-4"
                        @click="selected_course = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card-title>
                  <hr />
                  <v-card-text class="pb-0">
                    <v-row>
                      <v-col md="6">
                        <div
                          style="
                            max-height: 165;
                            overflow-y: auto;
                            overflow-x: hidden;
                          "
                        >
                          <v-row>
                            <v-col
                              md="6"
                              v-for="(
                                lesson_plan, lp
                              ) in selected_course.approved_lps"
                              :key="`lesson_plan_${lp}`"
                            >
                              <v-card
                                @click="selected_lesson_plan = lesson_plan"
                              >
                                <v-card-title>
                                  {{ lesson_plan.title }}
                                </v-card-title>
                              </v-card>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col md="6">
                        <template v-if="selected_lesson_plan">
                          <div style="max-height: 100; overflow: auto">
                            <span v-html="selected_lesson_plan.description">
                            </span>
                          </div>
                          <v-btn color="white" class="mt-3" @click="getDays()">
                            Get Suggested Days</v-btn
                          >
                        </template>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
              <!-- End of Lesson Plan -->
              <!-- Lesson Plan Days-->
              <v-expand-transition>
                <v-card
                  v-if="isGettingDay"
                  color="blue-grey lighten-5"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%"
                >
                  <v-card-title>
                    <p class="text-h4 text--primary">Suggested Days</p>
                    <v-spacer></v-spacer>
                    <v-card-actions class="pt-0">
                      <v-btn
                        dark
                        color="teal accent-4"
                        @click="isGettingDay = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card-title>
                  <hr />
                  <v-card-text class="pb-0">
                    <loader
                      v-if="isDaysLoading"
                      indeterminate
                      color="primary"
                    ></loader>
                    <v-row v-else>
                      <v-col
                        md="3"
                        v-for="(day, lpD) in selected_lesson_plan.lp_days"
                        :key="`day_${lpD}`"
                      >
                        <v-card @click="reveal = true">
                          <router-link
                            class="routerLink"
                            target="_blank"
                            :to="`/days?dayID=${day.id}&classID=${classcode.id}`"
                          >
                            <v-card-title class="black--text">
                              {{ day.title }}
                              <v-spacer></v-spacer>
                              <v-icon color="green"> mdi-check-circle </v-icon>
                            </v-card-title>
                          </router-link>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
              <!-- End of Lesson Plan Days -->
            </v-card>
            <!-- End of Courses -->
          </v-col>
        </v-row>
      </template>
      <template v-if="viewType == 'Reports'">
        <v-row v-if="students.length">
          <v-col md="12">
            <v-card elevation="6">
              <v-card-title>
                <p class="text-h4">Reports</p>
                <v-spacer></v-spacer>
              </v-card-title>
              <hr />
              <v-card-text>
                <v-row>
                  <v-col md="6">
                    <v-card elevation="6">
                      <v-card-title>
                        Overall Class Performance
                        <v-spacer></v-spacer>
                        <view-fullscreen
                          v-if="student_performance_overview_data.length > 0"
                          :title="`Overall Class Performace`"
                          :type="`PieChart`"
                          :Data="student_performance_overview_data"
                          :Options="pieChartOptions"
                        ></view-fullscreen>
                      </v-card-title>
                      <v-card-text>
                        <GChart
                          v-if="student_performance_overview_data.length > 0"
                          type="PieChart"
                          :data="student_performance_overview_data"
                          :options="pieChartOptions"
                        />
                        <loader v-else indeterminate color="primary"></loader>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="6">
                    <v-card elevation="6">
                      <v-card-title>
                        Assignment Wise Class Performance
                      </v-card-title>
                      <v-card-title>
                        <v-autocomplete
                          ref="assignment"
                          v-model="assignment_id"
                          :items="assignmentItems"
                          label="Assignment"
                          prepend-icon="mdi-clipboard-text"
                          @change="getAssignmentPerformanceOverview"
                        ></v-autocomplete>
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col>
                            <GChart
                              v-if="
                                assignment_performance_overview_data.length > 0
                              "
                              type="ColumnChart"
                              :data="assignment_performance_overview_data"
                              :options="columnChartOptions"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="12">
                    <v-card elevation="6">
                      <v-card-title>
                        Assignment Wise Class Average Comparison
                        <v-spacer></v-spacer>
                        <view-fullscreen
                          v-if="AssignmentWiseClassAverageChartData.length > 0"
                          :title="`Assignment Wise Class Average Comparison`"
                          :type="`ColumnChart`"
                          :Data="AssignmentWiseClassAverageChartData"
                          :Options="
                            AssignmentWiseClassAverageColumnChartOptions
                          "
                        ></view-fullscreen>
                      </v-card-title>
                      <v-card-title>
                        <v-row>
                          <v-col md="10">
                            <v-autocomplete
                              ref="scholl"
                              v-model="assignment_ids"
                              :items="assignmentItems"
                              multiple
                              chips
                              clearable
                              maxValue="4"
                              @input="validateSelectedItem"
                              :error-messages="validationErrors"
                              @click:clear="
                                AssignmentWiseClassAverageChartData = []
                              "
                              label="Assignment"
                              prepend-icon="mdi-application-edit"
                            ></v-autocomplete>
                          </v-col>
                          <v-col>
                            <v-btn
                              color="primary"
                              :disabled="validationErrors.length ? true : false"
                              @click="getAssignmentWiseClassAverageComparison"
                            >
                              FILTER
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col style="overflow: auto">
                            <loader
                              v-if="
                                isAssignmentWiseClassAverageComparisonLoading
                              "
                              indeterminate
                              color="primary"
                            ></loader>
                            <GChart
                              v-if="
                                AssignmentWiseClassAverageChartData.length > 0
                              "
                              type="ColumnChart"
                              :data="AssignmentWiseClassAverageChartData"
                              :options="
                                AssignmentWiseClassAverageColumnChartOptions
                              "
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="12">
                    <v-card elevation="6">
                      <v-card-title>
                        TSI Wise Performance Overview
                        <template>
                          <template>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span class="caption ml-5">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="#FF0000"
                                    size="10"
                                    >mdi-circle</v-icon
                                  >
                                  < 40
                                </span>
                              </template>
                              <span>Less Than 40 </span>
                            </v-tooltip>
                          </template>
                          <template class="ml-5">
                            <v-tooltip bottom class="ml-5">
                              <template v-slot:activator="{ on, attrs }">
                                <span class="caption ml-5">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="#FFA500"
                                    size="10"
                                    >mdi-circle</v-icon
                                  >
                                  41 - 60
                                </span>
                              </template>
                              <span>Somewhere between 41 - 60</span>
                            </v-tooltip></template
                          >
                          <template class="ml-5">
                            <v-tooltip bottom class="ml-5">
                              <template v-slot:activator="{ on, attrs }">
                                <span class="caption ml-5">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="#008000"
                                    size="10"
                                    >mdi-circle</v-icon
                                  >
                                  61 - 80
                                </span>
                              </template>
                              <span>Somewhere between 61 - 80</span>
                            </v-tooltip></template
                          >
                          <template class="ml-5">
                            <v-tooltip bottom class="ml-5">
                              <template v-slot:activator="{ on, attrs }">
                                <span class="caption ml-5">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="#0000FF"
                                    size="10"
                                    >mdi-circle</v-icon
                                  >
                                  > 80
                                </span>
                              </template>
                              <span>More Than 80 </span>
                            </v-tooltip>
                          </template>
                        </template>
                        <v-spacer></v-spacer>
                        <view-fullscreen
                          v-if="StudentWisePerformanceChartData.length > 0"
                          :title="`Student Wise Performance Overview`"
                          :type="`ScatterChart`"
                          :Data="StudentWisePerformanceChartData"
                          :Options="StudentWiseColumnChartOptions"
                        ></view-fullscreen>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <GChart
                              v-if="StudentWisePerformanceChartData.length > 0"
                              type="ScatterChart"
                              :data="StudentWisePerformanceChartData"
                              :options="StudentWiseColumnChartOptions"
                            />
                            <loader
                              v-else
                              indeterminate
                              color="primary"
                            ></loader>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-container>
    <!-- User Details Dialog Box -->
    <v-dialog
      v-model="show_details"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card style="background: rgb(252, 239, 239)">
        <v-toolbar color="white">
          <v-btn icon @click="(show_details = false), (user_details = [])">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Student Info</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid>
          <v-card class="mt-4 mb-4">
            <v-row align="center">
              <div style="flex: 1 1 auto"></div>
              <v-col sm="12">
                <v-card-text>
                  <v-row>
                    <v-col md="8">
                      Name :
                      <h1>
                        <v-icon> mdi-human-male-board </v-icon>
                        {{ user_details.name }}
                      </h1>
                    </v-col>
                    <v-col md="4">
                      Student Id :
                      <h3>
                        <v-icon>mdi-card-account-details </v-icon>
                        {{ user_details.id_given_by_school }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="8">
                      Email :
                      <h1>
                        <v-icon> mdi-email </v-icon>
                        {{ user_details.email }}
                      </h1>
                    </v-col>
                    <v-col md="4" v-if="roleName == 'ADMIN'">
                      Registration Mail Sent :
                      <h3>
                        <v-tooltip bottom v-if="user_details.is_mail_sent == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              x-small
                              class="my-auto"
                              color="error"
                              @click="SendMail(user_details.id)"
                              :loading="sending_mail"
                              :disabled="sending_mail"
                            >
                              <v-icon dark> mdi-email-fast </v-icon>
                            </v-btn>
                          </template>
                          <span>No Registration Mail Sent Yet !!</span>
                        </v-tooltip>
                        <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              class="my-auto"
                              fab
                              x-small
                              color="success"
                              @click="SendMail(user_details.id)"
                              :loading="sending_mail"
                              :disabled="sending_mail"
                            >
                              <v-icon dark> mdi-email-open </v-icon>
                            </v-btn>
                          </template>
                          <span>Registration Mail Already Sent. </span>
                        </v-tooltip>
                        {{ user_details.is_mail_sent ? "Yes" : "NO" }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="8">
                      Gender :
                      <div v-if="user_details.gender == true">
                        <h1>
                          <v-icon color="blue lighten-2">
                            mdi-gender-male
                          </v-icon>
                          MALE
                        </h1>
                      </div>
                      <div v-else>
                        <h1>
                          <v-icon color="red lighten-2">
                            mdi-gender-female
                          </v-icon>
                          FEMALE
                        </h1>
                      </div>
                    </v-col>
                    <v-col md="4">
                      Contact No :
                      <h3>
                        <v-icon> mdi-phone </v-icon>
                        {{ user_details.contact_number }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="8">
                      Board :
                      <h1>
                        <v-icon color="blue lighten-2">
                          mdi-cast-education
                        </v-icon>
                        {{ user_details.board ? user_details.board.name : "" }}
                      </h1>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="mt-9 mb-4">
            <v-card-title> Classes </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  sm="3"
                  v-for="classcode in user_details.classcodes"
                  :key="classcode.subject_name"
                >
                  <v-card shaped elevation="6">
                    <v-col>
                      <v-img
                        height="210"
                        :src="require('@/assets/imgs/classroom.png')"
                      ></v-img>
                    </v-col>

                    <v-card-title class="justify-center"
                      >{{ classcode.subject_name }}
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col style="text-align: center"
                          ><classcode-element
                            :classcode="classcode.classcode"
                            :type="
                              roleName == 'STUDENT'
                                ? 'SUBJECT'
                                : roleName == 'TEACHER'
                                ? 'STANDARD_SUBJECT'
                                : ''
                            "
                          ></classcode-element
                        ></v-col>
                      </v-row>
                      <v-row>
                        <v-col md="6"
                          ><router-link
                            v-if="roleName == 'ADMIN'"
                            :to="`/standards/${classcode.standard_id}/sections/${classcode.section_id}/classes/${classcode.id}/view-teachers`"
                            style="text-decoration: none"
                            >View Teacher</router-link
                          ></v-col
                        >
                      </v-row>
                    </v-card-text>
                    <v-divider class="mx-4"></v-divider>

                    <v-card-text>
                      <v-chip-group
                        active-class="deep-purple accent-4 white--text"
                        column
                      >
                        <v-chip>{{
                          classcode.is_optional ? "Optional" : "Mandatory"
                        }}</v-chip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              dark
                              :color="`${
                                classcode.is_active ? 'green' : 'red'
                              }`"
                            >
                              mdi-circle
                            </v-icon>
                          </template>
                          <span>{{
                            classcode.is_active ? "Active" : "Inactive"
                          }}</span>
                        </v-tooltip>
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";
import Backbutton from "@/components/backbutton.vue";
export default {
  components: { loader, Backbutton },
  data() {
    return {
      isLoading: false,
      isMasterLoading: false,
      isDaysLoading: false,
      isAssignmentWiseClassAverageComparisonLoading: false,
      graphType: false,
      isGettingDay: false,
      is_list: false,
      reveal: false,
      assignment_id: "",
      viewType: "",
      assignment_ids: [],
      assignments: [],
      classcode: {},
      validationErrors: [],
      assignmentItems: [
        {
          id: 0,
          text: "Select Assignment",
          value: 0,
        },
      ],
      ViewItems: [
        "Courses",
        "Reports",
        "Students",
      ],
      students: [],
      courses: [],
      PieTypeOverview: [],
      AssignmentTypeData: [],
      type_overview_data: [],
      student_performance_overview_data: [],
      StudentWisePerformanceChartData: [],
      AssignmentWiseClassAverageChartData: [],
      assignment_performance_overview_data: [],
      pieChartOptions: {
        is3D: true,
        width: "100%",
        height: 400,
        chartArea: { width: "80%", height: "80%" },
      },
      columnChartOptions: {
        colors: ["#007ACC"],
        width: "100%",
        height: 300,

        bar: { groupWidth: "30%" },
        chartArea: { width: "80%", height: "80%" },
        legend: { position: "top", alignment: "center" },
        vAxis: {
          minValue: 0,
          maxValue: 50,
          title: "No Of Students",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 25 },
          textStyle: {
            fontSize: 1,
          },
        },
        hAxis: {
          slantedText: false,
          maxTextLines: 1,
          title: "In Percentage",
          titleTextStyle: {
            bold: true,
          },
          textStyle: {
            fontSize: 9,
          },
        },
      },
      title: "Class Dashboard",
      AssignmentBasedOn: "1",
      selected_course: "",
      selected_lesson_plan: "",
      show_details: false,
      sending_mail: false,
      is_Mail_Sent: false,
      user_details: [],
    };
  },
  mounted() {
    if (this.roleName == "TEACHER") {
      this.teacher_id = this.user.id;
    } else {
      this.teacher_id = this.$route.query.teacherId;
    }
    // this.getData();
    this.getMaster();
  },
  methods: {
    async getMaster() {
      this.isMasterLoading = true;
      let classcode = await axios.get(
        `/classcodes/${this.$route.params.classId}`
      );
      this.classcode = classcode.data.data;
      if (this.classcode) {
        this.getCourses();
      }
      let assignments = [];
      let query =
        "teacher_id=" +
        this.teacher_id +
        "&classcode_id=" +
        this.$route.params.classId;
      assignments = await axios.get(`/assignments?${query}`);
      this.assignments = assignments.data.data;
      this.assignments.forEach((assignment) => {
        this.assignmentItems.push({
          id: assignment.id,
          text: assignment.assignment_title,
          value: assignment.id,
        });
      });
      this.assignment_count = assignments.data.count;
      this.getStudentData();
      this.viewType = "Students";
      this.getData();
      this.isMasterLoading = false;
    },
    async getData() {
      // if (this.teacherId) {
      //   this.teacher_id = this.teacherId;
      // } else {
      //   this.teacher_id = this.user.id;
      // }
      this.is_Loading = true;
      let response;
      let requirements = [
        "assignmentWiseStudents",
        "classcodeWiseStudents",
        "typeWiseAssignments",
        "tierWiseOverview",
      ];
      let query =
        "companyId=" +
        this.user.companies[0].id +
        "&teacherId=" +
        this.teacher_id +
        "&classcode_id=" +
        this.$route.params.classId +
        "&requirements=" +
        requirements;
      response = await axios.get(`/teacher_wise_overview?${query}`);
      this.TierWiseOverview =
        response.data.assignmentOverview.tierWiseOverview[0];
      this.student_performance_overview_data = [
        ["Student", "Value"],
        ["> 90% ", parseInt(this.TierWiseOverview.greaterThan90)],
        ["80% - 90%", parseInt(this.TierWiseOverview.between80A90)],
        ["70% - 80%", parseInt(this.TierWiseOverview.between70A80)],
        ["60% - 70%", parseInt(this.TierWiseOverview.between60A70)],
        ["50% - 60%", parseInt(this.TierWiseOverview.between50A60)],
        ["< 50%", parseInt(this.TierWiseOverview.below50)],
      ];
      // Student Wise Performance
      this.StudentPerformanceData =
        response.data.assignmentOverview.classcodeWiseStudents[0];

      this.StudentWiseColumnChartOptions = {
        width: "100%",
        theme: "material",

        height: 400,
        chartArea: { left: 50, right: 50 },
        vAxis: {
          minValue: 0,
          maxValue: 100,
          title: "In Percentage",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 25 },
          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          title: "Students",
          titleTextStyle: {
            bold: true,
          },
          gridlines: {
            color: "transparent",
          },
          textPosition: "none",
          textStyle: "none",
        },
        legend: { position: "top", alignment: "center" },
      };
      this.StudentWisePerformanceChartData = [
        ["Student", "Score [%]", { role: "style" }],
      ];
      this.StudentPerformanceData.students.forEach((data) => {
        // this.students.push(data);
        let myPercent = parseFloat(
          data.myAveragePercent > 0 ? data.myAveragePercent.toFixed(2) : 0
        );
        let color = "";
        switch (true) {
          case myPercent >= 80:
            color = "#0000FF";
            break;
          case myPercent >= 60 && myPercent < 80:
            color = "#008000";
            break;
          case myPercent >= 40 && myPercent < 60:
            color = "#FFA500";
            break;
          case myPercent > 40:
            color = "#FF0000";
            break;

          default:
            color = "#FF0000";
            break;
        }

        this.StudentWisePerformanceChartData.push([
          data.name,
          myPercent,
          color,
        ]);
      });

      // Assignment Wise Class Average Comparison
      this.AssignmentWiseClassAverageData =
        response.data.assignmentOverview.assignmentWiseStudents;
      this.assignment_id = this.assignmentItems[this.assignmentItems.length];

      this.getAssignmentPerformanceOverview();
      this.is_Loading = false;
    },
    validateSelectedItem() {
      if (this.assignment_ids && this.assignment_ids.length > 5) {
        this.validationErrors = [
          "Selected value exceeds the maximum allowed value.",
        ];
      } else {
        this.validationErrors = [];
      }
    },
    async getStudentData() {
      this.isLoading = true;
      let students = [];
      let query = "role_id=5" + "&classcode_id=" + this.$route.params.classId;
      students = await axios.get(`/users?${query}`);
      this.students = students.data.data;
      this.student_count = students.data.count;
      this.isLoading = false;
      this.viewType = "Students";
    },
    async ShowDetails(id) {
      this.user_details = [];
      this.user_details = this.students.filter(
        (student) => student.id == id
      )[0];
      if (this.user_details) {
        this.show_details = true;
      }
    },
    async getAssignmentPerformanceOverview() {
      this.isLoading = true;
      this.assignment_performance_overview_data = [];
      this.assignment_id = this.assignment_id ? this.assignment_id : "";
      let assignment_performance_overview = [];
      let query =
        "teacher_id=" +
        this.teacher_id +
        "&classcode_id=" +
        this.$route.params.classId +
        "&assignment_id=" +
        this.assignment_id;
      assignment_performance_overview = await axios.get(
        `/assignments/assignment_wise_performance_overview?${query}`
      );
      this.assignment_performance_overview =
        assignment_performance_overview.data.data;
      if (this.assignment_performance_overview) {
        this.assignment_performance_overview_data = [
          ["Grades", "No Of Students"],
          [
            "> 90%",
            parseFloat(this.assignment_performance_overview.greaterThan90),
          ],
          [
            "80% - 90%",
            parseFloat(this.assignment_performance_overview.between80A90),
          ],
          [
            "70% - 80%",
            parseFloat(this.assignment_performance_overview.between70A80),
          ],
          [
            "60% - 70%",
            parseFloat(this.assignment_performance_overview.between60A70),
          ],
          [
            "50% - 60%",
            parseFloat(this.assignment_performance_overview.between50A60),
          ],
          ["< 50%", parseFloat(this.assignment_performance_overview.below50)],
        ];
      }
      this.assignment_count = assignment_performance_overview.data.count;
      this.isLoading = false;
    },
    /** !!!! Not in USE  !!!!! */
    async getStudentPerformanceOverview() {
      this.isLoading = true;
      this.student_performance_overview_data = [];
      let student_performance_overview = [];
      let query = "classcode_id=" + this.$route.params.classId;
      student_performance_overview = await axios.get(
        `/assignments/student_wise_performance_overview?${query}`
      );
      this.student_performance_overview =
        student_performance_overview.data.data;
      this.student_performance_overview_data = [
        ["Student", "Value"],
        ["> 90", parseFloat(this.student_performance_overview.greaterThan90)],
        ["80-90", parseFloat(this.student_performance_overview.between80A90)],
        ["70-80", parseFloat(this.student_performance_overview.between70A80)],
        ["60-70", parseFloat(this.student_performance_overview.between60A70)],
        ["50-60", parseFloat(this.student_performance_overview.between50A60)],
        ["< 50", parseFloat(this.student_performance_overview.below50)],
      ];
      this.student_count = student_performance_overview.data.count;
      this.isLoading = false;
    },
    async SendMail(id) {
      this.sending_mail = true;
      let is_mail_sent = [];
      if (id) {
        let query = "role_id=5" + "&user_id=" + id;
        is_mail_sent = await axios.get(`/users/send_mail?${query}`);
      } else {
        let query = "role_id=5";
        is_mail_sent = await axios.get(`/users/send_mail_all?${query}`);
      }
      if (is_mail_sent) {
        this.sending_mail = false;
        this.is_Mail_Sent = true;
        this.getData();
      }
    },
    getAssignmentWiseClassAverageComparison() {
      this.isAssignmentWiseClassAverageComparisonLoading = true;
      this.AssignmentWiseClassAverageColumnChartOptions = {
        theme: "material",
        chartArea: { left: 100, right: 50 },
        bar: { groupWidth: "75" },
        focusTarget: "category",
        height: 500,
        colors: ["#FFA500"],

        annotations: {
          textStyle: {
            fontSize: 10,
          },
        },
        vAxis: {
          minValue: 0,
          maxValue: 100,
          title: "In Percentage",
          titleTextStyle: {
            bold: true,
          },
          gridlines: { color: "#333", minSpacing: 75 },
          textStyle: {
            fontSize: 10,
          },
        },
        hAxis: {
          slantedText: false,
          maxTextLines: 1,
          showTextEvery: 1,
          title: "Assignments",
          titleTextStyle: {
            bold: true,
          },
          explorer: { axis: "horizontal" },
          gridlines: { color: "#333", minSpacing: "25%" },
        },
        legend: { position: "top", alignment: "center" },
      };
      this.AssignmentWiseClassAverageChartData = [
        [this.AssignmentBasedOn, "Class Average [%]"],
      ];
      this.assignment_ids = this.assignment_ids ? this.assignment_ids : "";
      this.AssignmentWiseClassAverageData.forEach((value) => {
        let assignment_id = value.assignment_id;
        let is_applicable = this.assignment_ids.find(
          (item) => item === assignment_id
        );
        if (this.assignment_ids.length) {
          if (is_applicable) {
            let classPercent = parseFloat(
              value.classcodeAveragePercent > 0
                ? value.classcodeAveragePercent.toFixed(2)
                : 0
            );
            this.AssignmentWiseClassAverageChartData.push([
              value.assignment_title,
              classPercent,
            ]);
          }
        } else {
          let classPercent = parseFloat(
            value.classcodeAveragePercent > 0
              ? value.classcodeAveragePercent.toFixed(2)
              : 0
          );
          this.AssignmentWiseClassAverageChartData.push([
            value.assignment_title,
            classPercent,
          ]);
        }
      });
      // Trimming the Chart Data to Latest 5
      if (this.AssignmentWiseClassAverageChartData.length > 6) {
        let startIndex =
          this.AssignmentWiseClassAverageChartData.length - 5 - 1;
        this.AssignmentWiseClassAverageChartData.splice(1, startIndex);
      }

      this.AssignmentWiseClassAverageColumnChartOptions.width =
        parseInt(this.AssignmentWiseClassAverageChartData.length - 1) > 6
          ? parseInt(this.AssignmentWiseClassAverageChartData.length - 1) * 200
          : "100%";
      this.AssignmentWiseClassAverageColumnChartOptions.bar.groupWidth =
        parseInt(this.AssignmentWiseClassAverageChartData.length - 1) > 6
          ? "30%"
          : "65";

      this.isAssignmentWiseClassAverageComparisonLoading = false;
    },
    async getCourses() {
      let courses = [];
      let query =
        "board_id=" +
        this.classcode.board_id +
        "&subject=" +
        this.classcode.subject_name +
        "&standard=" +
        this.classcode.standard.name;
      courses = await axios.get(`/courses?${query}`);
      this.courses = courses.data.data;
    },
    async getDays() {
      this.isGettingDay = true;
      this.isDaysLoading = true;
      let lp = await axios.get(`/lps/${this.selected_lesson_plan.id}`);
      this.selected_lesson_plan = lp.data.data;
      console.log(this.selected_lesson_plan);
      this.isDaysLoading = false;
    },
  },
};
</script>
<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
