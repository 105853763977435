<template>
  <v-main>
    <v-container>
      <v-card elevation="6">
        <v-card-title>
          <backbutton></backbutton>
          Edit Course
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <v-text-field
                v-model="form.title"
                outlined
                label="Title *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.title"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="board"
                v-model="form.board_id"
                :items="boardItems"
                label="Board"
                chips
                :error-messages="errors.board_id"
                prepend-icon="mdi-cast-education"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="standard"
                v-model="form.standard"
                :items="standardItems"
                label="Standard"
                chips
                :error-messages="errors.standard"
                prepend-icon="mdi-star"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="subject"
                v-model="form.subject"
                :items="subjectItems"
                label="Subject"
                :error-messages="errors.subject"
                prepend-icon="mdi-application-edit"
                chips
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col>
              <p class="font-weight-bold">Learning Outcome</p>
              <froala
                :tag="'textarea'"
                :config="config"
                v-model="form.description"
              ></froala>
            </v-col>
          </v-row>
          <loader v-if="isLoading"></loader>
          <v-btn v-else color="primary" dark @click="save">
            Update Course
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
import Loader from "@/components/loader.vue";
export default {
  components: { backbutton, Loader },
  name: "CourseEditPage",
  data() {
    return {
      standardItems: [],
      subjectItems: [],
      boardItems: [],
      form: {
        title: "",
        standard: "",
        subject: "",
        board_id: "",
      },
      config: {
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Add Your Content Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/courses/masters");
      masters = masters.data;
      masters.subjects.forEach((subject) => {
        this.subjectItems.push({
          id: subject.id,
          text: subject.name,
          value: subject.name,
        });
      });
      masters.boards.forEach((board) => {
        this.boardItems.push({
          id: board.id,
          text: board.name,
          value: board.id,
        });
      });
      masters.grades.forEach((grade) => {
        this.standardItems.push({
          id: grade.id,
          text: grade.name,
          value: grade.name,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/courses/${this.$route.params.courseId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.patch(`/courses/${this.$route.params.courseId}`, this.form);
        this.isLoading = false;
        this.$router.push("/courses");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
