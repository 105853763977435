<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col>
        <v-img
          :src="require('../../assets/images/login_image.png')"
          width="600"
        ></v-img>
      </v-col>
      <v-col>
        <router-link to="/">
          <v-img
            class="mx-auto"
            :src="require('../../assets/logo.png')"
            width="200"
          ></v-img>
        </router-link>

        <br />
        <div
          class="text-h4 font-weight-bold text-center"
          v-text="'RESET PASSWORD'"
        ></div>
        <br />
        <br />
        <v-card elevation="2" class="mx-auto">
          <v-card-text>
            <v-row>
              <v-col md="2"><p class="font-weight-bold">Email:</p></v-col>
              <v-col md="10">
                <p class="font-weight-bold">
                  {{ user.email }}
                </p>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col md="2"><p class="font-weight-bold">Role:</p></v-col>
              <v-col md="10">
                <p class="font-weight-bold">
                  {{ roleName == "ACADEMIC TEAM" ? "ACADEMIC HEAD" : roleName }}
                </p>
              </v-col>
            </v-row> -->
            <br />
            <v-text-field
              v-model="form.password"
              outlined
              type="password"
              label="New Password"
              prepend-inner-icon="mdi-lock"
              :error-messages="errors.password"
            ></v-text-field>
            <v-text-field
              v-model="form.password_confirmation"
              outlined
              type="password"
              label="Confirm Password"
              @keypress.enter="resetPassword"
              prepend-inner-icon="mdi-lock"
            ></v-text-field>
            <loader v-if="isLoading"></loader>
            <v-btn v-else color="primary" dark @click="resetPassword">
              Reset Password
            </v-btn>
            <!-- Password Reset Success Message -->
            <v-dialog v-model="isPasswordReseted" max-width="600" persistent>
              <v-card elevation="2" class="rounded-tl-xl rounded-br-xl">
                <v-container>
                  <v-row align="center">
                    <v-col sm="5">
                      <v-img
                        class="rounded-tl-xl feature"
                        height="210"
                        :src="require('../../assets/imgs/password.png')"
                      ></v-img>
                    </v-col>
                    <v-col sm="7">
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <h3>Password Successfully Reset</h3>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-spacer></v-spacer>
                      <v-card-text>
                        <v-btn color="primary" @click.stop="closeDialog"
                          >Close</v-btn
                        >
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import loader from "@/components/loader.vue";

export default {
  components: { loader },
  name: "ResetPasswordPage",
  data() {
    return {
      isPasswordReseted: false,
      form: {
        email: "",
        password: "",
        password_confirmation: "",
      },
      isLoading: false,
    };
  },
  mounted() {
    this.form.email = this.user.email;
  },
  methods: {
    async resetPassword() {
      try {
        this.isLoading = true;
        await axios.post("/reset_password", this.form);
        let user = this.user;
        user.is_password_reseted = 1;
        await axios.patch(`/users/${user.id}`, user);
        this.isLoading = false;
        this.isPasswordReseted = true;
      } catch {
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.isPasswordReseted = false;
      this.$router.push("/auth/login");
    },
  },
};
</script>
