<template>
  <section>
    <div data-v-step="0">
      <!-- <v-btn
        fab
        color="success"
        @click="(is_create_new_layout = true), getExistingTicket()"
      >
        <v-icon size="38">mdi-chat-question-outline</v-icon>
      </v-btn> -->
      <v-speed-dial
        fixed
        v-model="fab"
        bottom
        right
        direction="top"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="success"
            dark
            small
            fab
            @click="(is_create_new_layout = true), getExistingTicket()"
          >
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-chat-question-outline</v-icon>
          </v-btn>
        </template>
      </v-speed-dial>
      <!-- <v-btn
        fab
        dark
        small
        color="green"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="indigo"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="red"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn> -->
    </div>

    <!-- Ticket Dialog -->
    <v-dialog v-model="is_ticket_show" width="450">
      <v-card
        v-show="is_ticket_show == true"
        width="450"
        elevation="2"
        shaped
        class="bottom-right-dialog rounded-tl-xl rounded-br-xl"
      >
        <v-card-title class="justify-center success white--text">
          <h2>
            Hi there
            <v-icon class="ml-3" color="amber lighten-4">mdi-hand-wave</v-icon>
          </h2>
          <v-card-subtitle>
            Welcome to the Infakt Portal. We're delighted to assist you. Whether
            you're interested in checking the status of an existing ticket or
            creating a new one, we're here to support you.
          </v-card-subtitle>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-row>
              <v-col align="center">
                <v-btn
                  color="success"
                  @click="
                    (is_ticket_show = false),
                      getExistingTicket(),
                      (is_existing_show = true)
                  "
                >
                  <v-icon class="mr-3">mdi-arrange-send-to-back</v-icon>
                  Existing Chats
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <v-btn
                  color="success"
                  @click="
                    (is_ticket_show = false),
                      (is_create_new_layout = true),
                      getExistingTicket()
                  "
                >
                  <v-icon class="mr-3">mdi-send</v-icon>
                  New Conversation
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col align="center">
                <v-btn
                  color="success"
                  @click="(is_ticket_show = false), (is_create_new = true)"
                >
                  <v-icon class="mr-3">mdi-send</v-icon>
                  New Conversation
                </v-btn>
              </v-col>
            </v-row> -->
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Create Ticket Dialog Box -->
    <v-dialog v-model="is_create_new" persistent>
      <v-card
        style="background: rgb(252, 239, 239)"
        max-width="450"
        class="bottom-right-dialog rounded-tl-xl rounded-br-xl"
      >
        <v-toolbar color="success" dark>
          <v-btn icon @click="is_create_new = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Create a new Ticket</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row align="center">
          <div style="flex: 1 1 auto"></div>
          <v-col sm="12">
            <v-card-text>
              <v-row>
                <v-col sm="12">
                  <!-- <p class="font-weight-bold">Description</p> -->
                  <froala
                    :tag="'textarea'"
                    :config="config"
                    v-model="ticket.description"
                  ></froala>
                </v-col>
              </v-row>
              <MyLoader v-if="isLoading"></MyLoader>
              <v-btn v-else color="primary" class="mt-5" dark @click="save">
                Create Ticket
              </v-btn>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- Create Ticket New Layout Dialog Box -->
    <v-dialog v-model="fab">
      <v-card
        color="white"
        style="background: rgb(252, 239, 239)"
        :max-width="$vuetify.breakpoint.smAndDown ? `90%` : `450`"
        class="bottom-right-dialog rounded-tl-xl rounded-br-xl"
      >
        <!-- max-height="550" -->
        <!-- <v-toolbar color="success" dark>
          <v-btn
            icon
            @click="(is_create_new_layout = false), (is_ticket_show = true)"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title>Ticket</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar> -->
        <v-card-title class="justify-center success white--text">
          <h2>
            Hi there
            <v-icon class="ml-3" color="amber lighten-4">mdi-hand-wave</v-icon>
          </h2>
          <v-card-subtitle>
            Welcome to the Infakt Portal. We're delighted to assist you.
          </v-card-subtitle>
        </v-card-title>
        <v-row align="center">
          <v-col sm="12">
            <v-card-text>
              <div
                style="max-height: 300px; overflow-y: auto; overflow-x: hidden"
              >
                <template v-for="(ticket, t) in tickets">
                  <div :key="`ticket_${t}`">
                    <b>{{ ticket.title }}</b>
                    <br />
                    <template
                      v-for="(ticket_detail, tD) in ticket.ticket_details"
                    >
                      <v-row :key="`ticket_detail_${tD}`">
                        <template v-if="ticket_detail.user_id == user.id">
                          <!-- Self  -->
                          <v-col>
                            <v-card
                              color="primary"
                              class="rounded-br-0 rounded-xxl"
                              dark
                              max-width="550px"
                              style="
                                float: right;
                                align-self: flex-end;
                                border-radius: 20px;
                                word-wrap: break-word;
                              "
                            >
                              <v-card-text
                                class="pa-3 white--text"
                                v-html="ticket_detail.description"
                              >
                              </v-card-text>
                              <v-card-text class="pa-2">
                                <span style="text-align: start">
                                  <v-icon v-if="ticket_detail.is_seen"
                                    >mdi-check-all</v-icon
                                  >
                                  <v-icon v-else>mdi-check</v-icon>
                                </span>
                                <span style="text-align: end">
                                  {{ ticket_detail.created_at | in_days }}
                                  {{ ticket_detail.created_at | HM }}
                                </span>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </template>
                        <template v-else>
                          <!-- Other -->
                          <v-col>
                            <v-card
                              color="blue-grey darken-1"
                              class="rounded-bl-0"
                              dark
                              max-width="550px"
                              style="
                                float: left;
                                align-self: flex-start;
                                border-radius: 20px;
                                word-wrap: break-word;
                              "
                            >
                              <v-card-text
                                class="pa-3 white--text"
                                v-html="ticket_detail.description"
                              >
                              </v-card-text>
                              <v-card-text style="text-align: end" class="pa-2">
                                {{ ticket_detail.created_at | in_days }}
                                {{ ticket_detail.created_at | HM }}
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </template>
                      </v-row>
                    </template>
                    <template v-if="ticket.status == true">
                      <br />
                      This ticket has been marked as closed on
                      {{ ticket.updated_at | ddmmyyyy }}
                      <v-divider></v-divider>
                    </template>
                  </div>
                </template>
              </div>
              <v-row class="mt-3">
                <v-col sm="10" md="10" lg="10" style="min-width: 270px">
                  <!-- <p class="font-weight-bold">Description</p> -->
                  <froala
                    :tag="'textarea'"
                    :config="config"
                    v-model="ticket.description"
                  ></froala>
                </v-col>
                <v-col>
                  <v-btn
                    icon
                    :loading="isSending"
                    :disabled="isSending"
                    color="primary"
                    @click="sendTicketMessage()"
                    ><v-icon size="40">mdi-send</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Existing Ticket Listing Dialog Box -->
    <v-dialog v-model="is_existing_show" persistent>
      <v-card
        shaped
        style="background: rgb(252, 239, 239)"
        class="bottom-right-dialog rounded-tl-xl rounded-br-xl"
        max-width="450"
      >
        <v-toolbar dark color="success">
          <v-btn
            icon
            @click="(is_existing_show = false), (is_ticket_show = true)"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title> <h2>Tickets</h2> </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid class="my-6">
          <template>
            <MyLoader v-if="isLoading"></MyLoader>
            <v-row v-else justify="center">
              <v-expansion-panels inset focusable>
                <v-expansion-panel
                  v-for="(ticket, t) in tickets"
                  :key="`ticket_${t}`"
                >
                  <v-expansion-panel-header>
                    <h2>
                      {{ ticket.title }}
                      <v-badge
                        overlap
                        v-if="
                          ticket.is_new == true &&
                          (ticket.user_id != user.id ||
                            ticket.ticket_details.length > 1)
                        "
                        color="pink"
                      >
                      </v-badge></h2
                  ></v-expansion-panel-header>
                  <v-expansion-panel-content class="my-6">
                    <div
                      style="
                        max-height: 350px;
                        overflow-y: auto;
                        overflow-x: hidden;
                      "
                      draggable="true"
                      class="chat-body"
                      id="chat-body"
                    >
                      <template
                        v-for="(ticket_detail, tD) in ticket.ticket_details"
                      >
                        <v-row :key="`ticket_detail_${tD}`">
                          <template v-if="ticket_detail.user_id == user.id">
                            <!-- Self  -->
                            <v-col>
                              <v-card
                                color="primary"
                                class="rounded-br-0"
                                dark
                                max-width="550px"
                                style="
                                  float: right;
                                  align-self: flex-end;
                                  border-radius: 8px;
                                  word-wrap: break-word;
                                "
                              >
                                <v-card-actions
                                  class="pa-3"
                                  v-html="ticket_detail.description"
                                >
                                </v-card-actions>
                                <v-card-text class="pa-2">
                                  <span style="text-align: start">
                                    <v-icon v-if="ticket_detail.is_seen"
                                      >mdi-check-all</v-icon
                                    >
                                    <v-icon v-else>mdi-check</v-icon>
                                  </span>
                                  <span style="text-align: end">
                                    {{ ticket_detail.created_at | in_days }}
                                    {{ ticket_detail.created_at | HM }}
                                  </span>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </template>
                          <template v-else>
                            <!-- Other -->
                            <v-col>
                              <v-card
                                color="blue-grey darken-1"
                                class="rounded-bl-0"
                                dark
                                max-width="550px"
                                style="
                                  float: left;
                                  align-self: flex-start;
                                  border-radius: 8px;
                                  word-wrap: break-word;
                                "
                              >
                                <v-card-actions
                                  class="pa-3"
                                  v-html="ticket_detail.description"
                                >
                                </v-card-actions>
                                <v-card-text
                                  style="text-align: end"
                                  class="pa-2"
                                >
                                  {{ ticket_detail.created_at | in_days }}
                                  {{ ticket_detail.created_at | HM }}
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </template>
                        </v-row>
                      </template>
                    </div>
                    <v-row class="mt-3">
                      <v-col sm="10">
                        <froala
                          :tag="'textarea'"
                          :config="config"
                          v-model="message"
                        ></froala>
                      </v-col>
                      <v-col
                        ><v-btn
                          icon
                          :loading="isSending"
                          :disabled="isSending"
                          color="primary"
                          @click="sendMessage(ticket)"
                          ><v-icon size="40">mdi-send</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </template>
        </v-container>
      </v-card>
    </v-dialog>
    <v-tour
      name="myTour"
      :steps="steps"
      :options="myOptions"
      :callbacks="myCallbacks"
    ></v-tour>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "TicketButton",
  data() {
    return {
      fab: false,
      ticket: {
        title: "",
        description: "",
      },
      ticket_detail: {
        ticket_id: "",
        description: "",
      },
      config: {
        toolbarButtons: ["insertImage", "insertVideo"],
        events: {
          initialized: function () {
            console.log("initialized");
          },
        },
        placeholderText: "Type Your Message Here!",
        charCounterCount: true,
        imageUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoUploadURL: "https://api.plms.aaibuzz.com/api/upload_editor_image",
        videoMaxSize: 100 * 1024 * 1024,
        wordDeniedAttrs: ["style"],
        wordPasteModal: false,
        wordPasteKeepFormatting: false,
        key: "SDB17hD8E7D5D5F3C3G3c1tpyajvH3gjD7nfuF2C1G1C1A10C1D7A1A5F5==",
      },
      message: "",
      tickets: [],
      myCallbacks: {
        onStop: this.StopTour,
      },
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Skip tour",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Okay",
        },
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `<span style="font-size:12">Please raise a ticket, if you have any queries</span>`,
          params: {
            placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false,
          },
        },
      ],
      focusedIndex: null,
      isLoading: false,
      isSending: false,
      is_ticket_show: false,
      is_create_new: false,
      is_create_new_layout: false,
      is_existing_show: false,
    };
  },
  mounted() {
    if (this.user.is_close_ticket_tour != true) {
      this.$tours["myTour"].start();
    }
  },
  methods: {
    // scrollToBottom() {
    //   this.$refs.chatContainer.scrollTop =
    //     this.$refs.chatContainer.scrollHeight;
    // },
    async StopTour() {
      try {
        this.user_data = {
          is_close_ticket_tour: true,
          name: this.user.name,
          email: this.user.email,
        };
        await axios.patch(`/users/${this.user.id}`, this.user_data);
      } catch (error) {
        console.log(error);
      }
    },
    async save() {
      try {
        this.isLoading = true;
        this.ticket.user_id = this.user.id;
        this.ticket.company_id = this.company.id;
        await axios.post("/tickets", this.ticket);
        this.isLoading = false;
        this.is_create_new = false;
        this.ticket = {};
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async getExistingTicket() {
      try {
        this.isLoading = true;
        let query = "user_id=" + this.user.id;
        let tickets = await axios.get(`/tickets?${query}`);
        this.tickets = tickets.data.data;
        this.isLoading = false;
        this.ticket = {};
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async sendMessage(ticket) {
      try {
        this.isSending = true;
        this.ticket_detail.ticket_id = ticket.id;
        this.ticket_detail.user_id = this.user.id;
        this.ticket_detail.description = this.message;
        let response = await axios.post("/ticket_details", this.ticket_detail);
        if (response) {
          let detail = response.data.data;
          ticket.ticket_details.push(detail);
          this.isSending = false;
          this.message = "";
        }
      } catch (e) {
        console.log(e);
        this.isSending = false;
      }
    },
    async sendTicketMessage() {
      try {
        this.isSending = true;
        this.ticket.user_id = this.user.id;
        this.ticket.company_id = this.company.id;
        await axios.post("/tickets", this.ticket);
        this.getExistingTicket();
        this.isSending = false;
        this.ticket.description = "";
        console.log(this.ticket.description);
      } catch (e) {
        console.log(e);
        this.isSending = false;
      }
    },
  },
};
</script>

<style scoped>
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.bottom-right-dialog {
  position: fixed;
  bottom: 80px;
  right: 20px;
}
</style>
