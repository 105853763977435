<template>
  <v-main>
    <v-container fluid>
      <SaCounts></SaCounts>
      <br />
      <!-- <SaContent></SaContent>
      <br /> -->
      <!-- <SaTopSchools></SaTopSchools>
      <br /> -->
      <v-divider></v-divider>
      <br />
      <!-- <div class="text-h5 font-weight-bold" v-text="'School Wise Data'"></div>
      <v-row>
        <v-col sm="3">
          <v-autocomplete
            v-model="selectedSchoolId"
            :items="schools"
            label="Select School"
            prepend-icon="mdi-calendar-month-outline"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <br />
      <SaSingleSchoolData
        v-if="selectedSchoolId"
        :schoolId="selectedSchoolId"
      ></SaSingleSchoolData> -->
    </v-container>
  </v-main>
</template>
  
<script>
import axios from "axios";
import SaCounts from "./counts.vue";
import SaContent from "./content.vue";
import SaTopSchools from "./top_schools.vue";
import SaSingleSchoolData from "./single_school_data";

export default {
  name: "SuperAdminDashboard",
  data() {
    return {
      selectedSchoolId: "",
      schools: [],
    };
  },
  components: {
    SaCounts,
    SaContent,
    SaTopSchools,
    SaSingleSchoolData,
  },
  props: ["SaSingleSchoolData"],
  watch: {
    currentSchool: "getSubTypes",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let masters = await axios.get("masters");
      masters = masters.data;
      this.schools = [];
      masters.schools.forEach((school) => {
        this.schools.push({
          text: school.name,
          value: school.id,
        });
      });
    },
  },
};
</script>
  
  <style>
.imgStyle {
  font-size: 120px !important;
}
</style>